import React, {useEffect, useState } from 'react';
import { CssBaseline, Container, Backdrop,
    Typography,
    Box,
    List,
    ListItem,
    Button,
    TextField,
    Grid,
    IconButton,
    CircularProgress
} from '@mui/material';
import Wallet from "./Wallet";
import fetchData from "../../utility/endpointFetch";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh"

import TopAppBar from '../TopAppBar'
import Footer from '../Footer'

import { useTheme } from '@mui/material/styles'

const PortfolioTracking = ( { userObject, insideDashboard=false } ) => {

    const theme = useTheme()

    const [isLoading,setIsLoading] = useState(true)
    const [walletsLoading,setWalletsLoading] = useState(false)
    const [wallets, setWallets] = useState([]);
    const [walletName, setWalletName] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [restoreModalOpen, setRestoreModalOpen] = useState(false);
    const [userData, setUserData] = useState(userObject);
    const [allCoinsList, setAllCoinsList] = useState([]); // List of all coins
    const [portfolioTotal, setPortfolioTotal] = useState(0);
    const [walletTotals, setWalletTotals] = useState({});
    const [deletedWallets, setDeletedWallets] = useState([]);
    const [addNewWalletError,setAddNewWalletError] = useState(false)

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    useEffect(() => {
        //console.log("top of useEffect for the PortfolioTracking Component")
        //console.log("userObject is:")
        //console.dir(userObject)
        let names = userData['wallet_names'];
        let tempWallets = [];
        for(let name of names){
            tempWallets.push(name)
        }
        setWallets(tempWallets);
        setDeletedWallets(userData['deleted_wallet'])

        //console.log("wallets should now be:")
        //console.dir(tempWallets)
        //console.log("deleted_wallet should now be: ")
        //console.dir(userData['deleted_wallet'])
        {/* removing this as is was taking 20+ seconds to load
        replacing with the "single coin select" used within the watchlist feature
        const fetchParams = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }
        const errorMessage = 'Error saving your token.  Resubmit.  If still an error please contact support.'
        ;(async () => {
            try {
                const coinMarketResponse = await fetchData('api/v1/coin/coinmarket/?limit=3000&ordering=name', errorMessage, fetchParams).promise
                //console.log("coinMarketResponse is: ")
                //console.dir(coinMarketResponse)
                const list = []
                for(let coin of coinMarketResponse.results){
                    list.push({ 'label': coin['name'], 'id': coin['coin']['id'], 'price': coin['current_price'], 'icon': coin['images']['small'] })
                }
                if(list.length){
                    setAllCoinsList(list)
                }
                //console.log("allCoinsList list is:")
                //console.dir(list)
                setIsLoading(false)
            } catch (error) {
                console.log("something is broke getting coinmarket data within portfolio tracking: %s", error)
            } 
        })()
        */}
        setIsLoading(false)
    }, []);

    useEffect(() => {
        let pTotal = 0;
        for(let [k, v] of Object.entries(walletTotals)){
            if(v){
                pTotal += v
            }
        }
        setPortfolioTotal(pTotal)
    }, [walletTotals]);

    const handleWalletNameChange = (event) => {
        setAddNewWalletError(false)
        setWalletName(event.target.value);
    };

    // Handle opening modal
    const handleClickOpen = () => {
        setModalOpen(true);
    };

    // Handle closing modal (Add Wallet)
    const handleClose = () => {
        setModalOpen(false);
    };

    // Handle opening modal
    const handleRestoreClickOpen = () => {
        setRestoreModalOpen(true);
    };

    // Handle closing modal (Add Wallet)
    const handleRestoreClose = () => {
        setRestoreModalOpen(false);
    };

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }        

    // Add a wallet.
    const addWallet = () => {
        setWalletsLoading(true)
        setAddNewWalletError(false)
        //console.log("top of addWallet")
        //console.dir(userData)
        //console.log("walletName is: "+ walletName)
        //console.log("userData.wallet_names.includes(walletName) is: " + userData.wallet_names.includes(walletName))
        //console.log("userData.deleted_wallet.includes(walletName) is: " + userData.deleted_wallet.includes(walletName))
        if ((!userData.wallet_names.includes(walletName)) && (!userData.deleted_wallet.includes(walletName))) {
            let tempData = {...userData};
            let names = tempData['wallet_names']
            tempData['wallet_names'] = (names !== null && names.length > 0) ? [...names, walletName] : [walletName];
            //console.log("tempData to send to endpoint in addWallet is:")
            //console.dir(tempData)
            const putAddFetchParams = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(tempData)
            }
            var errorMessage = 'Error saving your new wallet.  Resubmit.  If still an error please contact support.'
            ;(async () => {
                try {
                    const addPutWalletesponse = await fetchData('api/v1/users/userdata/', errorMessage, putAddFetchParams).promise
                    //console.log("addPutWalletesponse is:")
                    //console.dir(addPutWalletesponse)

                    await delay(1000)

                    const getAddFetchParams = {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    }
                    errorMessage = 'Error getting your new wallet.  Try again!  If still an error please contact support.'
                    ;(async () => {
                        try {
                            const addGetWalletesponse = await fetchData('api/v1/users/userdata/', errorMessage, getAddFetchParams).promise
                            //console.log("addGetWalletesponse is:")
                            //console.dir(addGetWalletesponse)                        
                            setUserData(addGetWalletesponse);
                            setWallets(addGetWalletesponse['wallet_names'])

                        } catch (error) {
                            console.log("something is broke getting userdata after addwallet: %s", error)
                        }
                    })()
                    console.log("wallets.length is: "+wallets.length)
                    //if(!wallets.length){
                    //    setWallets([walletName]);  // check if no wallets yet
                    //}else{
                    
                    //}
                } catch (error) {
                    console.log("something is broke putting/getting userdata after addwallet: %s", error)
                }                
                setWalletsLoading(false)
            })()
            handleClose();
        } else {
            console.log("do not add as it's a duplicate somewhere")
            setAddNewWalletError(true)
        }
        
    }

    // Removes the wallet from the frontend list
    const removeWallet = (walletName) => {
        setWalletsLoading(true)
        //console.log("top of removeWallet: " + walletName)
        //console.log("current local userData is:")
        //console.dir(userData)
        let tempData = {...userData};
        tempData['wallet_names'] = tempData['wallet_names'].filter(removeThisWallet => walletName !== removeThisWallet)
        tempData['deleted_wallet'] = [ ...tempData['deleted_wallet'], walletName ]

        //console.log("after tempData['wallet_names'] is:")
        //console.dir(tempData['wallet_names'])
        //console.log("after tempData['deleted_wallet'] is:")
        //console.dir(tempData['deleted_wallet'])
        //console.log("userData to send to endpoint within removeWallet is: ")
        //console.dir(tempData)
        const putRemovefetchParams = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(tempData)
        }
        var errorMessage = 'Error restoring the wallet.  Resubmit.  If still an error please contact support.'
        ;(async () => {
            try {
                const putRemoveWalletResponse = await fetchData('api/v1/users/userdata/', errorMessage, putRemovefetchParams).promise
                //console.log("putRemoveWalletResponse is: ")
                //console.dir(putRemoveWalletResponse)
                await delay(1000)

                const getRemoveFetchParams = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }
                errorMessage = 'Error getting your all wallet(s) while removing a wallet.  Try again!  If still an error please contact support.'
                ;(async () => {
                    try {
                        const getRemoveWalletResponse = await fetchData('api/v1/users/userdata/', errorMessage, getRemoveFetchParams).promise
                        //console.log("getRemoveWalletResponse is:")
                        //console.dir(getRemoveWalletResponse)                        
                        setUserData(getRemoveWalletResponse);
                        setWallets(getRemoveWalletResponse['wallet_names'])
                        setDeletedWallets(getRemoveWalletResponse['deleted_wallet'])
                        //console.log("setWallets should now be:")
                        //console.dir(getRemoveWalletResponse['wallet_names'])
                        //console.log("setDeletedWallets is now:")
                        //console.dir(getRemoveWalletResponse['deleted_wallet'])                    

                    } catch (error) {
                        console.log("something is broke getting userdata after removeWallet: %s", error)
                    }
                })()
            } catch (error) {
                console.log("something is broke putting userdata in removeWallet: %s", error)
            }
            setWalletsLoading(false)
        })()
    }

    const restoreWallet = (walletName) => {
        setWalletsLoading(true)
        //console.log("top of restoreWallet")
        //console.dir(userData)
        let tempData = {...userData};
        let names = tempData['wallet_names']
        tempData['wallet_names'] = (names !== null) ? [...names, walletName] : [walletName];
        //console.log("new wallet_names is:")
        //console.dir(tempData['wallet_names'])
        let newDeleted = []
        for(let d of tempData['deleted_wallet']){
            if(d === walletName){
                continue;
            }
            newDeleted.push(d);
        }
        //console.log("in restoreWallet newDeleted is:")
        //console.dir(newDeleted)
        setDeletedWallets(newDeleted)
        tempData['deleted_wallet'] = newDeleted
        const putRestoreFetchParams = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(tempData)
        }
        var errorMessage = 'Error restoring the wallet.  Resubmit.  If still an error please contact support.'
        ;(async () => {
            try {
                const putRestoreWalletResponse = await fetchData('api/v1/users/userdata/', errorMessage, putRestoreFetchParams).promise
                //console.log("putRestoreWalletResponse is")
                //console.log(putRestoreWalletResponse)

                await delay(1000)

                const getRestoreFetchParams = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }
                errorMessage = 'Error getting your new wallet.  Try again!  If still an error please contact support.'
                ;(async () => {
                    try {
                        const getRestoreWalletesponse = await fetchData('api/v1/users/userdata/', errorMessage, getRestoreFetchParams).promise
                        //console.log("getRestoreWalletesponse is:")
                        //console.dir(getRestoreWalletesponse)                        
                        setUserData(getRestoreWalletesponse);
                        setWallets(getRestoreWalletesponse['wallet_names'])
                        setDeletedWallets(getRestoreWalletesponse['deleted_wallet'])
                        ////console.dir(getRestoreWalletesponse)
                        //console.log("setWallets should now be:")
                        //console.dir(getRestoreWalletesponse['wallet_names'])
                        //console.log("setDeletedWallets is now:")
                        //console.dir(getRestoreWalletesponse['deleted_wallet'])                    

                    } catch (error) {
                        console.log("something is broke getting userdata after removeWallet: %s", error)
                    }
                })()
            } catch (error) {
                console.log("something is broke putting userdata in restoreWallet: %s", error)
            }           
            setWalletsLoading(false)
        })()
    }

    const displayRestoreButton = () => {
        //console.log("top of displayRestoreButton")
        //console.dir(userData['deleted_wallet'])
        if (userData['deleted_wallet'] === undefined) {
            return null
        } else if ( userData['deleted_wallet'] || userData['deleted_wallet'].length) {
            return ( 
                <Button 
                    variant="contained" 
                    color='secondary'
                    onClick={handleRestoreClickOpen} 
                    sx={{alignSelf: 'center', borderRadius: 2, ml: 2}}
                    startIcon={<RefreshIcon />}
                >
                    Restore Wallet
                </Button>
            )
        } else {
            return null
        }
    }

    const displayAddButton = () => {
        if (wallets.length < 5) {
            return (
                <Button 
                    variant="contained" 
                    onClick={handleClickOpen} 
                    sx={{alignSelf: 'center', borderRadius: 2}}
                    startIcon={<AddIcon  />}
                >
                    Add Wallet { wallets.length >= 5 ? '(Max 5)' : '' }
                </Button>
            )
        } else {
            return null
        }
    }

    if (isLoading) return <CircularProgress />
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            { insideDashboard ? null : <TopAppBar userObject={userObject} selectedPage='pt' />}
            <Container maxWidth="true" sx={{marginTop: insideDashboard ? '10px': '80px'}}>
                <Container maxWidth="xl">        
                    <Box sx={{justifyContent: 'center', width: '100%'}}>
                        <Typography align='center' variant="h2" sx={{ mt: 1, mb: .5, color: theme.palette.primary.main }}>
                            My Portfolio
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant="h4" sx={{fontSize: 20, fontWeight: 'bold', mr: 1, textAlign: 'center'}}>
                                {"Total Value: "}
                            </Typography>
                            <Typography sx={{fontSize: 18, }}>
                                {currencyFormat(portfolioTotal)}
                            </Typography>
                        </Box>
                        { walletsLoading ? <CircularProgress /> :
                        <List sx={{width: '100%'}}>
                            {
                                wallets.map((item, index) => {
                                    return (
                                        <Wallet 
                                            wallet={item} 
                                            userData={userData} 
                                            walletTotals={walletTotals} 
                                            setWalletTotals={setWalletTotals} 
                                            removeWallet={removeWallet} 
                                            key={"wallets"+index} 
                                        />
                                    )
                                })
                            }
                            {
                                modalOpen &&
                                    <ListItem sx={{flexDirection: 'column', display: 'flex', width: '100%', marginTop: 5}} disablePadding>
                                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between',  borderRadius: 5, border: '1px dashed grey', width: '90%', p: 5,}}>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Typography display="inline" sx={{fontSize: 16, fontWeight: 500}}>
                                                    Create a new wallet.
                                                </Typography>
                                                { addNewWalletError ? 
                                                <Typography display="inline" sx={{fontSize: 16, fontWeight: 500, color: theme.palette.error.main}}>
                                                    There is already a wallet with that name.  Please choose another.
                                                </Typography>       
                                                : null }                                         
                                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                    <IconButton onClick={() => addWallet()}  aria-label="add-wallet" color="primary">
                                                        <CheckIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => setModalOpen(!modalOpen)} aria-label="delete" color="primary">
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>

                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label="Wallet Name"
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                                value={walletName}
                                                onChange={handleWalletNameChange}
                                            />
                                        </Box>
                                    </ListItem>
                            }
                            {
                                restoreModalOpen &&
                                <ListItem sx={{flexDirection: 'column', display: 'flex', width: '100%', marginTop: 5}} disablePadding>
                                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between',  borderRadius: 5, border: '1px dashed grey', width: '90%', p: 5,}}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                            }}>
                                            <Typography sx={{fontSize: 16, fontWeight: 500}}>
                                                Restore Deleted Wallets
                                            </Typography>
                                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <IconButton onClick={handleRestoreClose} aria-label="delete" color="primary">
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>

                                        <Grid justify={'center'}>
                                            {
                                                deletedWallets.map((item, index) => {
                                                    return (
                                                        <Button key={"deletewalletbutton"+index} variant="contained" onClick={() => restoreWallet(item)} sx={{alignSelf: 'center', borderRadius: 2, mr: 2}}>
                                                            {item}
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                </ListItem>
                            }
                            {
                                !modalOpen && !restoreModalOpen &&
                                (
                                    <ListItem sx={{ borderRadius: 5, width: '100%', flex: 1,  mt: 2, justifyContent: 'center' }} disablePadding>
                                        {displayAddButton()}
                                        {displayRestoreButton()}
                                    </ListItem>
                                )
                            }
                        </List>
                        }
                    </Box>
                </Container>
                { insideDashboard ? null : <Footer />}
          </Container>
      </Box>                    
    )
}

export default PortfolioTracking;