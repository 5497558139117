import React, { useEffect } from 'react'
import { Grid, Pagination, Stack, Typography, Divider } from '@mui/material'

import { useTheme } from '@mui/material/styles'

import VIPTwitterPost from './VIPTwitterPost'
import VIPTelegramPost from './VIPTelegramPost'
import VIPDiscordPost from './VIPDiscordPost'
import VIPRedditPost from './VIPRedditPost'
import VIPMainTabsFullPostScreener from './VIPMainTabsFullPostScreener'

const VIPMainTabsFullPost = ( { vipScreenerName, setVIPScreenerName, vipListings, vipPageCount, responseCount, vipPage, changePage, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, showOfficialTweets, changeInVIPCoins } ) => {

  //console.log("......top of VIPMainTabsFullPost")
  //console.dir(vipListings)
  //console.log("vipListings[0] is")
  //console.dir(vipListings[0])

  const theme = useTheme()

  //need to get the first twitter account in the result set and set that account
  
  useEffect(() => {
      if (vipListings.length > 0) {
          //twitterItem.coins[0],twitterItem.twitter_display_name
          changeDateInChart(vipListings[0].created)
          changeAtFollowedTab(vipListings[0].twitter_display_name)
          showOfficialTweets(vipListings[0].twitter_display_name, vipListings[0].coins[0])
      }
  }, [vipListings])

  return (
    <Grid container sx={{minHeight: '500px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
      <Grid item xs={12}>
          <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" >
            <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around">
              <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.secondary.main, paddingLeft: '20px', marginTop: '20px'}}>{responseCount}</Typography>
              <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.secondary.main, marginTop: '20px', marginLeft: '3px'}}> Full VIP Results</Typography>
            </Stack>
            <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.warning.main, marginTop: '20px', marginLeft: '30px'}}>Select Tweet to Display related Market Data</Typography>
            <VIPMainTabsFullPostScreener changeInVIPCoins={changeInVIPCoins}/>
          </Stack>
      </Grid>
      {vipListings.map( (listing, index) => {
        if (listing !== null) {
          if (listing.hasOwnProperty('twitter_display_name')) return <VIPTwitterPost key={"vipmaintabsfullpost"+listing.display_name+index} listing={listing} setCoinInChart={setCoinInChart} changeAtFollowedTab={changeAtFollowedTab} changeAtMentionedTab={changeAtMentionedTab} changeHashTab={changeHashTab} changeCoinInTab={changeCoinInTab} changeDateInChart={changeDateInChart} showOfficialTweets={showOfficialTweets} />
          else if (listing.hasOwnProperty('telegram_group')) return <VIPTelegramPost key={'viptelegram'+index} telegramItem={listing} />
          else if (listing.hasOwnProperty('discord_server')) return <VIPDiscordPost key={'vipdiscord'+index} discordItem={listing} />
          else if (listing.hasOwnProperty('subreddit')) return <VIPRedditPost key={'vipreddit'+index} redditItem={listing} />
          else return null //not sure if these are the only 5 types needed
        }
      })}
      <Grid item xs={12}>
        <Divider sx={{marginBottom: '15px'}}/>
        <Stack direction="row" display="flex" alignItems="center">
          <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
          <Pagination shape="rounded" count={vipPageCount} page={vipPage} onChange={changePage}/>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default VIPMainTabsFullPost