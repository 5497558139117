import React, {useState,useEffect} from 'react'
import { CircularProgress, Typography, Grid, Box, Stack, Pagination } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import fetchData from '../../utility/endpointFetch'

import ResearchTwitterResultItem from './ResearchTwitterResultItem'

const ResearchTwitterResults = ({ tweetCoin='', search = '' }) => {
    //console.log("top of ResearchTwitterResults and tweetCoin is")
    //console.dir(tweetCoin)
    const theme = useTheme()

    const [isLoading,setIsLoading] = useState(true)
    const [twitterCoinPage,setTwitterCoinPage] = useState(1)
    const [twitterCoinPageCount,setTwitterCoinPageCount] = useState(0)
    const [twitterCoinResult,setTwitterCoinResult] = useState([])
    const [responseCount,setResponseCount] = useState(0)

    const getTwitterCoinResults = () => {
      setIsLoading(true)
      
      const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
      }
      ;(async () => {
        if (tweetCoin !== '') 
        var twitterCoinUrl = `api/v1/feed/alltweets/?coins=${tweetCoin}&page_size=100page=${twitterCoinPage}`
        //console.log(twitterCoinUrl)
        //removing so no call before a coin is set from the search results....the first coin is pulled from the results and then set here.
        //isLoading will remain true until the coin is set and the api call returned...after the search results are pulled to trigger this process
        //if (tweetCoin === '') 
        //var twitterCoinUrl = `api/v1/feed/twitter/?vip=true&page=${twitterCoinPage}`
        try {
          const twitterCoinResponse = await fetchData(twitterCoinUrl, "could not receive all twitter coin data", fetchParams).promise
          //console.log("twitterCoinResponse getting twitter coin data from api for researcher3")
          ////console.log('twitterCoinUrl used: ' + twitterCoinUrl)
          console.dir(twitterCoinResponse)

          setResponseCount(twitterCoinResponse.count)

          if (twitterCoinResponse.count > 100) {
            setTwitterCoinPageCount(Math.ceil(twitterCoinResponse.count/100))
          } else {
            setTwitterCoinPageCount(1)
          }

          setTwitterCoinResult(twitterCoinResponse.results)

          setIsLoading(false)
        } catch (error) {
          console.log("something is broke getting all twitter coin result in research3: " + error)
        }
      })()      
    }

    useEffect(() => {
      if (tweetCoin.length > 0) {
        getTwitterCoinResults()
      } else {
          setTwitterCoinResult([])
      }
    }, [tweetCoin, twitterCoinPage])

    const changePage =  (event, page) => {
      //console.log("top of changePage")
      //console.dir(page)
      setTwitterCoinPage(page)
    }    

    if (isLoading) return <CircularProgress />
    if (twitterCoinResult && twitterCoinResult.length > 0) {
        return (
            <Box sx={{width: '100%', height: '100%', overflow: 'auto',}}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Stack direction="row" display="flex" alignItems="center" sx={{marginTop: '5px', marginLeft: '10px'}}>
                            <Typography display="inline" sx={{color: theme.palette.secondary.main}}>tweets regarding coin: </Typography>
                            <Typography display="inline" sx={{color: theme.palette.secondary.main, fontWeight: 700, marginLeft: '5px'}}>{tweetCoin} </Typography>
                            <Typography display="inline" sx={{color: theme.palette.secondary.main, marginLeft: '5px'}}>(total {responseCount} / page {twitterCoinPage})</Typography> 
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        {twitterCoinResult.map( (tweet, index) => <ResearchTwitterResultItem key={tweet.id} tweet={tweet} search={search}/>)}
                    </Grid>            
                    <Grid item xs={12}>
                      <Stack direction="row" display="flex" alignItems="center">
                          <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
                          <Pagination sx={{marginTop: '10px'}} shape="rounded" count={twitterCoinPageCount} page={twitterCoinPage} onChange={changePage}/>
                      </Stack>                    
                    </Grid>    
                </Grid>
            </Box>
        )
    } else {
        return (
            <Box sx={{width: '100%', height: '100%', overflow: 'auto',}}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography display="inline" sx={{marginTop: '5px', color: theme.palette.secondary.main, paddingLeft: '10px', paddingTop: '10px'}}>tweet results for: </Typography> 
                        <Typography display="inline" sx={{color: theme.palette.primary.main, fontWeight: 700, marginLeft: '5px'}}>{tweetCoin} (0)</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{justifyContent: 'center', paddingLeft: '10px', paddingTop: '15px'}}>
                            <Typography display="inline" sx={{color: theme.palette.primary.main}}>Select a filtered coin to view their tweets from the system</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default ResearchTwitterResults