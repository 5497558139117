import React, { useState } from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import { Visibility, VisibilityOff, OpenInNew, ThumbUpAltSharp } from '@mui/icons-material'
import {
  Avatar,
  Backdrop,
  Button,
  Typography,
  TextField,
  Link,
  Grid,
  Container,
  Modal,
  Dialog,
  Fade,
  Box,
  InputAdornment,
  IconButton
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Logo from '../Header/Logo'

import fetchData from '../../utility/endpointFetch'
import hofs from '../../utility/hofs'

import { parse, isValid } from 'psl'
const userNameErrorMessage = 'User name is required and needs to be unique within our system.  If you have one then that username is already taken'
const emailErrorMessageBasic = 'Email address is required'
const emailErrorTLD = 'The email domain you have inserted is not valid by the comprehensive list we have.  Please check it.  If you feel it is not in error continue'
const emailVerifierErrorMessageBasic = 'Email address and email address verifier must match'
const passwordErrorMessage = 'Account password is required and must have a password score of at least \'good\' according to the strength meter'

const RegisterUser = ( { referral, setRegistrationStep } ) => {
  
  const theme = useTheme();

  function makeRandomPassword(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // const [phoneNumberError, setPhoneNumberError] = useState()
  // const [industryError, setIndustryError] = useState(false)
  // const [companyNameError, setCompanyNameError] = useState(false)

  const [userName, setUserName] = useState()
  const [userNameError, setUserNameError] = useState(false)
  const [referralCode,setReferralCode] = useState( referral ? referral : 'none')
  const [referralCodeError,setReferralCodeError] = useState(false)
  const [referralCodeErrorMessage,setReferralCodeErrorMessage] = useState('There is an error in your referral code')
  const [firstName, setFirstName] = useState()
  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState(emailErrorMessageBasic)
  const [emailVerifier, setEmailVerifier] = useState()
  const [emailVerifierError, setEmailVerifierError] = useState(false)
  const [emailVerifierErrorMessage, setEmailVerifierErrorMessage] = useState('Email address and email address verifier must match')
  const [password, setPassword] = useState(makeRandomPassword(15))
  const [passwordError, setPasswordError] = useState(false)
  const [agreement, setAgreement] = useState(false)
  const [agreementErrorMessage, setAgreementErrorMessage] = useState(
    'If you wish to view our terms click on blue button above to view the terms.  You need to agree with our terms to continue registration'
  )

  const [registrationError, setRegistrationError] = useState(false)
  const [registrationErrorMessage, setRegistrationErrorMessage] = useState('')
  const [passwordVerifier, setPasswordVerifier] = useState()
  const [passwordVerifierError, setPasswordVerifierError] = useState()
  const [passwordVerifierErrorMessage, setPasswordVerifierErrorMessage] = useState('')
  const [passwordScore, setPasswordScore] = useState(0);
  const [modalOpen, setModalOpen] = useState(true)
  const [modalErrorOpen, setModalErrorOpen] = useState(false)

  const [valuesPassword, setValuesPassword] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  })

  const [valuesPasswordVerifier, setValuesPasswordVerifier] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPasswordVerifier: false
  })

  const handleClickShowPassword = () =>
    setValuesPassword({ ...valuesPassword, showPassword: !valuesPassword.showPassword })

  const handleClickShowPasswordVerifier = () =>
    setValuesPasswordVerifier({
      ...valuesPasswordVerifier,
      showPasswordVerifier: !valuesPasswordVerifier.showPasswordVerifier
    })

  const handleMouseDownPassword = e => e.preventDefault()
  const handleMouseDownPasswordVerifier = e => e.preventDefault()
  const handleUserNameChange = e => setUserName(e.target.value)
  const handleReferralCodeChange = e => setReferralCode(e.target.value)
  const handleEmailChange = e => setEmail(e.target.value)
  const handleEmailVerifierChange = e => setEmailVerifier(e.target.value)
  const handlePasswordChange = e => setPassword(e.target.value)
  const handlePasswordVerifierChange = e => setPasswordVerifier(e.target.value)
  const handleAgreementChange = e => {
    setAgreement(true)
    setModalOpen(false)
  }

  const handleRegistrationErrorClose = e => {
    modalErrorOpen(false)
  }

  //const handleHowYouHearAboutUs = e => setHowYouHearAboutUs(e.target.value)

  const validateEmailTLD = () => {
    //get just the domain part of the email
    console.log("top of validateEmailTLD and email is:")
    console.dir(email)
    let full_email = email.split("@")  // is there only one @?
    var parsed_domain = parse(full_email[1])

    //email_domain.tld should have tld
    var validTLD = isValid(full_email[1])

    if (!validTLD) {
      //this is not a valid tld
      setEmailError(true)
      setEmailErrorMessage(emailErrorTLD)
    } else {

    }
  }

  const handleRegisterOneUserSubmit = e => {
    e.preventDefault()
    let errorCount = 0

    //console.log("top of handleRegisterOneUserSubmit")

    // first is to check if all required forms are filled
    if (hofs.isPopulated(userName)) {
      setUserNameError(false)
    } else {
      errorCount++
      setUserNameError(true)
    }

    /*
    if (passwordScore < 3) {
      //we want a stronger password than that
      errorCount++
      setPasswordError(true)
    } else {
      if (hofs.isPopulated(password)) {
        setPasswordError(false)
      } else {
        errorCount++
        setPasswordError(true)
      }
    }

    
    if (hofs.isPopulated(firstName)) {
      setFirstNameError(false)
    } else {
      errorCount++
      setFirstNameError(true)
    }

    if (hofs.isPopulated(lastName)) {
      setLastNameError(false)
    } else {
      errorCount++
      setLastNameError(true)
    }
    */

    if (hofs.isPopulated(email)) {
      if (hofs.validateEmail(email)) {
        setEmailError(false)
        setEmailErrorMessage('')
      } else {
        setEmailError(false)
        setEmailError(true)
      }
      if (hofs.isPopulated(emailVerifier)) {
        if (emailVerifier === email) {
          setEmailVerifierErrorMessage('')
          setEmailVerifierError(false)
        } else {
          errorCount++
          setEmailVerifierError(true)
          //only one message so far...no need to change it
        }
      } else {
        errorCount++
        setEmailVerifierError(true)
      }
    } else {
      errorCount++
      setEmailError(true)
    }

    /*
    if (hofs.isPopulated(passwordVerifier)) {
      if (passwordVerifier === password) {
        setPasswordVerifierError(false)
        setPasswordVerifierErrorMessage('')
      } else {
        errorCount++
        setPasswordVerifierError(true)
        setPasswordVerifierErrorMessage(
          'Your password verifier must match the password inserted above.  Please check and make sure both match'
        )
      }
    } else {
      errorCount++
      setPasswordVerifierError(true)
      setPasswordVerifierErrorMessage(
        'You must insert a password verifier that matches your password to complete submission'
      )
    }
    */

    if (agreement === false) {
      //console.log("agreement is false")
      setModalOpen(true)
      errorCount++
    }

    // need to check if email is unique with backend
    if (errorCount === 0) {
      // there are no errors...send the data to backend to complete registration

      //console.log("there are no errors in form...show verify email modal")


      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: userName,
          email: email,
          password: password,
          referred_code: referralCode
        })
      }
      //console.log("registration parameters are:" )
      //console.dir(fetchParams)
      const errorMessage = 'Error creating your account.  Please contact support to do so.'
      ;(async () => {
        const response = await fetchData('api/v1/users/register/', errorMessage, fetchParams).promise

        //console.log("registration response is:")
        //console.dir(response)
        setRegistrationError(response.error)
        if (response.success && response.success == 'User created.') {
          // user created
          //console.log("setting registration step to 1")
          setRegistrationStep(1)
          const fetchParams = {
            method: 'POST',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'email': email })
          }
    
          ;(async () => {
            const response = await fetchData('api/v1/users/forgotpassword/', 'Error reseting your password.', fetchParams).promise
            //console.log("response is: ")
            //console.dir(response)
          })()

        } else {
          //there is an error....what to do?
          if (response.error && response.error.username) {
            //console.log("error.username exists")
            setUserNameError(true)
            setRegistrationErrorMessage(response.error.username)
          }
          if (response.error && response.error.email) {
            setEmailError(true)
            setEmailErrorMessage(response.error.email)
            setRegistrationErrorMessage(response.error.email)
          }
          if (response.error && response.error.password) {
            setPasswordError(true)
            setRegistrationErrorMessage(response.error.password)
          }
          //console.log("registration error is: " + response.error_message)
        }
      })()
    }
  }

  const handleModalClose = ( event, reason ) => {
    if (reason !== 'backdropclick') {
      //console.log("reason not backdropclick")
      //agreement has been agreed to
      setAgreement(true)
      setModalOpen(false)
    } else {
      //backdrop clicked....only close on button click
      console.log("reason was not backdropclick")

      //alternatively....check that close button clicked...maybe if event is not "agree button click"
    }
  }

  const termsClick = () => {
    window.open(
      'https://def-logix.com/legal-2',
      '_blank',
      'location=yes,height=700,width=800,scrollbars=yes,status=yes'
    )
  }

  //theme.palette.background.default

  return (
    <>
    <Container component='main' maxWidth='sm' sx={{marginTop: '50px', justifyContent: 'center'}}>
      <div sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Logo width={50} />
        <Typography variant='h3' sx={{paddingBottom: '25px', marginTop: '15px'}}>
          Sign up
        </Typography>

        <form sx={{ width: '100%', marginTop: theme.spacing(5.5) }} noValidate onSubmit={handleRegisterOneUserSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name='username'
                required
                fullWidth={true}
                value={userName}
                id='userName'
                label='User Name - Display Name'
                autoComplete='User Name'
                onChange={handleUserNameChange}
                helperText={userNameError ? userNameErrorMessage : null}
                error={userNameError}
                inputProps={{ maxLength: 50 }}
                variant="filled"
                focused
                sx={{
                '& .MuifilledInput-root': {
                  backgroundColor: 'black'
                },
                '& input:-internal-autofill-selected': {
                  backgroundColor: 'black'
                } 
              }}
              />
            </Grid>
            {/*
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='firstName'
                label='First Name'
                name='first_name'
                autoComplete='first name'
                onChange={handleFirstNameChange}
                helperText={lastNameError ? lastNameErrorMessage : null}
                error={lastNameError}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='lastName'
                label='Last Name'
                name='last_name'
                autoComplete='last name'
                onChange={handleLastNameChange}
                helperText={lastNameError ? lastNameErrorMessage : null}
                error={lastNameError}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='howyouhearaboutus'
                name='howyouhearaboutus'
                label='How Did You Hear About Us'
                value={howYouHearAboutUs}
                onChange={handleHowYouHearAboutUs}
                variant='outlined'
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
            </Grid>*/}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                onChange={handleEmailChange}
                helperText={emailError ? emailErrorMessage : null}
                onBlur={validateEmailTLD}
                error={emailError}
                inputProps={{ maxLength: 255 }}
                variant="filled"
                focused                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='email_verifier'
                label='Email Address Verifier'
                name='email_verifier'
                autoComplete='email'
                onChange={handleEmailVerifierChange}
                helperText={emailVerifierError ? emailVerifierErrorMessage : null}
                error={emailVerifierError}
                inputProps={{ maxLength: 255 }}
                variant="filled"
                focused
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='referralCode'
                required
                fullWidth={true}
                value={referralCode}
                id='referralCode'
                label='Referral Code From Another User?'
                autoComplete='none'
                onChange={handleReferralCodeChange}
                helperText={referralCodeError ? referralCodeErrorMessage : null}
                error={referralCodeError}
                inputProps={{ maxLength: 50 }}
                variant="filled"
                focused
                sx={{
                '& .MuifilledInput-root': {
                  backgroundColor: 'black'
                },
                '& input:-internal-autofill-selected': {
                  backgroundColor: 'black'
                } 
              }}
              />
            </Grid>            
            {/*<Grid item xs={12}>
              <TextField
                type={valuesPassword.showPassword ? 'text' : 'password'}
                required
                fullWidth
                id='password'
                label='Password'
                name='password'
                autoComplete='password'
                onChange={handlePasswordChange}
                helperText={passwordError ? passwordErrorMessage : null}
                error={passwordError}
                inputProps={{ maxLength: 100 }}
                sx={{'& .MuiFilledInput-root': { backgroundColor: theme.palette.primary.main}}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {valuesPassword.showPassword ? <Visibility sx={{ color: theme.palette.primary.dark }} /> : <VisibilityOff sx={{ color: theme.palette.primary.dark }} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                variant="filled"
                focused                
              />
              <PasswordStrengthBar
                password={password}
                onChangeScore={(score,feedback) => {
                    setPasswordScore(score)
                  }
                } />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type={valuesPasswordVerifier.showPasswordVerifier ? 'text' : 'password'}
                required
                fullWidth
                id='password_verifier'
                label='Password Verifier'
                name='password_verifier'
                autoComplete='password'
                onChange={handlePasswordVerifierChange}
                helperText={passwordVerifierError ? passwordVerifierErrorMessage : null}
                error={passwordVerifierError}
                inputProps={{ maxLength: 100 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password verifier visibility'
                        onClick={handleClickShowPasswordVerifier}
                        onMouseDown={handleMouseDownPasswordVerifier}
                      >
                        {valuesPasswordVerifier.showPasswordVerifier ? <Visibility sx={{ color: theme.palette.primary.dark }} /> : <VisibilityOff sx={{ color: theme.palette.primary.dark }} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                variant="filled"
                focused                
              />
              </Grid>*/}



          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={{ margin: theme.spacing(3, 0, 2) }}
            // disabled={checkingForDuplicateEmail}
          >
            Click to sign up
          </Button>
          <Grid container justify='flex-end'>
            <Grid item>
              <Link href='/login' variant='body2'>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Modal
        aria-labelledby='spring-modal-title-agreement'
        aria-describedby='spring-modal-description-agreement'
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
        open={modalOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        disableEscapeKeyDown={true}
      >
        <Fade in={modalOpen}>
          <Box sx={{ position: 'absolute', width: 600, backgroundColor: theme.palette.background.default,  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)'}}>
            <h2 id='spring-modal-title'>Alpha Centauri Block Chain App Registration </h2>
            <p id='spring-modal-description' style={{ fontSize: '1rem' }}>
              Please agree to our license agreement and end user policy
              <br /><br />
              <Grid container>
                <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                  <Typography variant="subtitle2">{agreementErrorMessage}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button startIcon={<OpenInNew />} variant='outlined' onClick={termsClick} color='primary'>
                    Click to view the end user license agreement and and privacy policy
                  </Button>
                </Grid>

                <Grid item xs={12} style={{paddingTop: '25px'}}>
                  <Button startIcon={<ThumbUpAltSharp />} variant='contained' onClick={handleAgreementChange} color='primary'>
                    Click To Agree To Our Terms
                  </Button>
                </Grid>
              </Grid>
            </p>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby='spring-modal-registration-error'
        aria-describedby='spring-modal-registration-error-description'
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
        open={modalErrorOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        disableEscapeKeyDown={true}
      >
        <Fade in={modalErrorOpen}>
          <Box sx={{ position: 'absolute', width: 600, backgroundColor: '#fff',  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)'}}>
            <h2 id='spring-modal-title'>Alpha Centauri Block Chain App Registration </h2>
            <p id='spring-modal-description' style={{ fontSize: '1rem' }}>
              There was an error submitting your registration details
              <br /><br />
              <Grid container>
                <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                  <Typography variant="subtitle2">{registrationErrorMessage}</Typography>
                </Grid>
                <Grid item xs={12} style={{paddingTop: '25px'}}>
                  <Button startIcon={<ThumbUpAltSharp />} variant='contained' onClick={handleRegistrationErrorClose} color='primary'>
                    Click to try your registration again
                  </Button>
                </Grid>
              </Grid>
            </p>
          </Box>
        </Fade>
      </Modal>
    </Container>
    </>
  )
}

export default RegisterUser
