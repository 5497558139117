import * as React from 'react';
import {
  Typography,
  Grid,
  Box,
  Stack
} from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import TokenWizardHelperText from './TokenWizardHelperText';
import { useTheme } from '@mui/material/styles'
import { displayUploadedFileORIMageURL } from '../../utility/objHelper'
import fetchData from '../../utility/endpointFetch'

const TokenAllocationGraphPage = ({
    pageData,
    setPageData,
    showError,
    coin,
    wizardForCoinAlreadyExists
}) => {
    console.log("top of TokenAllocationGraphPage and pageData is")
    console.dir(pageData)
    console.log("wizardForCoinAlreadyExists is: " + wizardForCoinAlreadyExists)
    const theme = useTheme()

    const handleTAGImageAttach = (file) => { // Handle adding Token Allocation image file
        //console.log("top of handleTAGImageAttach and files is:")
        
        if (file.length === 1) {
            /* decided to upload at end instead of here when we receive it
            let formData = new FormData()
            formData.append("token_alloc_img",  file[0])
            formData.append('coin', coin)

            const fetchParams = {
                method: 'POST',
                //dataType: 'json',
                headers: {
                    //'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW' //need the "boundary" to stop getting this: "Multipart form parse error - Invalid boundary in multipart: None"
                },
                body: formData
            }

            let wizardURL = `api/v1/research/postwizard/?token_alloc_img=true&coin=${coin}`
            let errorMessage = 'Error saving your token information.  Resubmit.  If still an error please contact support.'
        
            ;(async () => {
                try {
                    const tagResponse = await fetchData(wizardURL, errorMessage, fetchParams).promise
                    console.log("tagResponse from api/v1/research/postwizard/?token_alloc_img=true is:")
                    console.dir(tagResponse)
                    if ((tagResponse.hasOwnProperty('success')) || tagResponse === true) {

                    } else {
                        throw 'field not excepted?'
                    }
                } catch (error) {
                    console.log("something is broke submitting token_alloc_img: " + error)
                }
            })()
            */
            setPageData({
                ...pageData,
                tagFile: file,
            })
        } else {
            console.log("no file submitted to upload")
        }
    }

    const removeImageHandler = (currentState) => {
        console.log("top of removeImageHandler in TokenAllocationGraphPage and currentState.target.checked is:")
        console.dir(currentState.target.checked)
        console.log("so setting removeTAGFile to " + currentState.target.checked)
        setPageData({
            ...pageData,
            removeTAGFile: currentState.target.checked
        })        
    }    

    var instructionText = 'Take a screenshot of the Token Allocation Graph and upload it to the box below.'
    if (Array.isArray(pageData.tagFile)) 
        instructionText = 'There is already a token allocation graph attached.  If you wish overwrite this one with a new one drag the screenshot of the new Token Allocation Graph and upload it to the box below.'
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
            <Typography variant="h3" sx={{color: theme.palette.primary.main}}>Enter Token Allocation Graph</Typography>
            <Stack direction="row" display='flex' alignItems='center'>
                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500] }}>
                    {instructionText}
                </Typography>
                <TokenWizardHelperText 
                    title={'Token Allocation Graph'} 
                    text={'The token allocation graph show how tokens are allocated between the different groups. Do NOT enter graphic images as it will reduce your account features.\nNote: The Details page of the project will show this info once uploaded.'}
                />
            </Stack>            
            <Grid container sx={{marginTop: '20px'}} spacing={5}>    
                <Grid item xs={12} alignItems={'center'} justifyContent={'center'}>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Stack display='flex' direction='row' alignItems='center' justifyContent='center' sx={{flex: 1, maxWidth: 800, margin: '25px'}}>
                            <DropzoneArea
                                //initialFiles={pageData.tagFile}
                                onChange={(file) => handleTAGImageAttach(file)}
                                filesLimit={1}
                                dropzoneText="Optionally Upload Token Allocation Graph Image by clicking or dragging the file here"
                                showPreviews={false}
                                clearOnUnmount={false}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={false}
                            />

                            <Stack display='flex' direction='row' justifyContent='center' sx={{margin: '25px', mb: 5}}>
                                {displayUploadedFileORIMageURL(pageData.tagFile, removeImageHandler, pageData.removeTAGFile)}
                            </Stack>
                        </Stack>
                        
                        {
                            showError && (
                                <Typography align='center' variant="h3" sx={{ mt: 1, color: theme.palette.error.main }}>
                                    Please fill in all fields before continuing.
                                </Typography>
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
    
}

export default TokenAllocationGraphPage;