import React from 'react'

import { Typography, Button } from '@mui/material';

const PortfolioChooseCoinItem = ( { coin, setCoinInWallet} ) => {
    //console.log("top of PortfolioChoicesCoinItem")
    //console.log("coin is: ")
    //console.dir(coin)
    //console.log("coin.coin.id is: " + coin.coin.id)
    ///console.log("coin.id is: " + coin.id)
    var coinLabel = coin.coin + " ("+ String(coin.coin_symbol).toUpperCase()+")"
    var coinObject = {id: coin.coin, name: coin.coin}
  
    return (
        <Button
            variant={'text'}
            sx={{cursor: 'pointer', padding: '5px', mt: '5px', borderBottom: 1, borderColor: 'primary.main'}}
            onClick={(e) => setCoinInWallet(coinObject)}
        >
            <Typography sx={{color: '#fff'}}>
                {coinLabel}
            </Typography>
        </Button>
    )
  

}

export default PortfolioChooseCoinItem