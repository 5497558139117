import React from 'react'
import { Typography, Stack, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { TiChevronRight } from 'react-icons/ti'

const HomeFeedTwitterUserDisplayItem = ({twitterUser, addUserToWhiteListHandler, getTwitterUserTweets}) => {

    const theme = useTheme()

    return (
        <Stack direction="row" sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between', paddingRight: '10px', paddingLeft: '10px' }} >
            <Typography onClick={() => getTwitterUserTweets(twitterUser.username)} sx={{cursor: 'pointer'}}>{twitterUser.display_name}</Typography>
            <IconButton size="small" onClick={()=>addUserToWhiteListHandler(twitterUser.username)} ><TiChevronRight color={theme.palette.success.dark} /></IconButton>
        </Stack>    
    )
}

export default HomeFeedTwitterUserDisplayItem