import { CircularProgress, Typography, Grid, Stack, Avatar, Chip, Box, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Twitter } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { IconCertificate } from '@tabler/icons'

import fetchData from '../../utility/endpointFetch'

const VIPSocialStats = ({ atFollowedTabValue='' }) => {
    //console.log("top of VIPSocialStats")
    // console.dir("atFollowedTabValue is: " + atFollowedTabValue)

    const theme = useTheme()

    const [isLoading,setIsLoading] = useState(true)
    const [twitterUserDetails,setTwitterUserDetails] = useState({})

    useEffect(() => {
        if (atFollowedTabValue && atFollowedTabValue.length > 0) {
          setIsLoading(true)
          const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
          }
          ;(async () => {
            try {
              const twitterUserResponse = await fetchData(`api/v1/feed/usertwitter/?username=${atFollowedTabValue}`, "could not receive twitter data within VIPSocialStats", fetchParams).promise
              //console.log("twitterUserResponse getting twitter data within VIPSocialStats")
              //console.dir(twitterUserResponse)
    
              if (twitterUserResponse && twitterUserResponse.count === 1) {
                setTwitterUserDetails(twitterUserResponse.results[0])
                //console.log("setting twitterUserResponse to:")
                //console.dir(twitterUserResponse.results[0])
              } else {
                //console.log("error: could not receive twitter data within VIPSocialStat: ")
                //console.dir(twitterUserResponse)
              }
    
            } catch (error) {
              console.log("something is broke getting twitter data within VIPSocialStats: " + error)
            }
          })()
        } else {
            setTwitterUserDetails({})
        }

        setIsLoading(false)
    
    }, [atFollowedTabValue])

    const displayAvatar = () => {
        if (twitterUserDetails && twitterUserDetails.avatar_url === '') {
          return (
            <a target="_blank" rel="noreferrer" href={twitterUserDetails.url}>
              <Twitter color={theme.palette.primary.main} size={27} sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px',}} />
            </a>
          )
        } else {
          return (
            <a target="_blank" rel="noreferrer" href={twitterUserDetails.url}>
              <Avatar alt={twitterUserDetails.username} src={twitterUserDetails.avatar_url} sx={{ width: 24, height: 24, marginRight: '3px' }}/>
            </a>
          )
        }
    }

    const displayOfficial = () => {
      if (twitterUserDetails && twitterUserDetails.is_influencer === false) {
        //this is an official account...show badge
        return (
          <IconButton 
            aria-label="Official Twitter Account" sx={{}}
            component="a"
            target="_blank"
            rel="noreferrer" 
            href={twitterUserDetails.url}            
          >
            <IconCertificate width="32" height="32" color={theme.palette.grey[500]} name={twitterUserDetails.username} sx={{paddingLeft: '10px', color: theme.palette.grey[500]}} />
          </IconButton>
        )   
      }
    }
    /*
    const displayOfficial2 = () => {
      if (twitterUserDetails.is_influencer === false) {
        //this is an official account...show badge
        return (
          <Chip
            label=''
            component="a"
            target="_blank"
            rel="noreferrer" 
            href={twitterUserDetails.url}
            variant="outlined"
            sx={styles}
            color="primary"
            icon={<IconCertificate width="32" height="32" name={twitterUserDetails.name} style={{paddingLeft: '10px' }} />}
            clickable
          />        
        )    
      }
    }
    */

    const styles = {
        margin: '10px',
        fontSize: "2em",
        paddingLeft: "5px",
      }    

    if (isLoading) return <CircularProgress />
    if (atFollowedTabValue && atFollowedTabValue.length === 0) {
        return (
          <Stack direction="column" display="flex" alignItems="center">
            <Typography variant="h3" sx={{marginLeft: '15px', marginRight: '10px', marginTop: '25px'}}>click on a tweet username to view their stats</Typography>
          </Stack>
        )
    } else {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Stack display="flex" direction="row" alignItems="center" justifyContent="flex-start">
                        {displayAvatar()}
                        <Typography 
                          variant="h3" 
                          sx={{marginLeft: '15px', marginRight: '10px', textDecoration: "none"}}
                          component="a"
                          target="_blank"
                          rel="noreferrer" 
                          href={twitterUserDetails.url}
                        >{twitterUserDetails.username}</Typography>
                        {displayOfficial()}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" sx={{paddingBottom: '10px',paddingTop: '20px', }}>
                        <Box sx={{ width: '40%' }}>
                            <Typography variant="body1" sx={{ color: '#555', textAlign: 'left', fontWeight: 600 }}>Followers</Typography>
                        </Box>
                        <Box sx={{ width: '60%', textAlign: "right" }}>
                            <Typography sx={{color: theme.palette.primary.main, fontWeight: 600 }} variant="body1">{Number(twitterUserDetails.followers).toLocaleString()}</Typography>
                        </Box>
                    </Stack>                
                </Grid>
            </Grid>
        )
    }
}

export default VIPSocialStats