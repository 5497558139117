import React, { useState } from 'react'
import { Button, TextField, Typography, Grid, IconButton, InputAdornment, Avatar, Container } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import PasswordStrengthBar from 'react-password-strength-bar'
import { useSearchParams } from "react-router-dom"

import hofs from '../../utility/hofs'
import PasswordResetSuccess from './PasswordResetSuccess'
import fetchData from '../../utility/endpointFetch'
import Logo from '../Header/Logo'

const PasswordResetForm = () => {
  console.log("top of PasswordResetForm")
  const theme = useTheme()

  let [searchParams, setSearchParams] = useSearchParams()
  let reset_token = searchParams.get("token")
  console.log("reset_token is: " + reset_token)

  const [password, setPassword] = useState()
  const [passwordScore, setPasswordScore] = useState()
  const [passwordError, setPasswordError] = useState(false)
  const [passwordVerifier, setPasswordVerifier] = useState()
  const [passwordVerifierError, setPasswordVerifierError] = useState(false)
  const [displayResetMessage, setDisplayResetMessage] = useState(false)
  const [passwordVerifierErrorMessage, setPasswordVerifierErrorMessage] = useState('')
  const [displayResetError,setDisplayResetError] = useState(false)

  const passwordErrorMessage = 'Account password is required and must have a password score of at least \'good\' according to the strength meter'

  const [valuesPassword, setValuesPassword] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  })

  const [valuesPasswordVerifier, setValuesPasswordVerifier] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPasswordVerifier: false,
  })

  const handleClickShowPassword = () =>
    setValuesPassword({ ...valuesPassword, showPassword: !valuesPassword.showPassword })

  const handleClickShowPasswordVerifier = () =>
    setValuesPasswordVerifier({
      ...valuesPasswordVerifier,
      showPasswordVerifier: !valuesPasswordVerifier.showPasswordVerifier
    })

  const handleMouseDownPassword = e => e.preventDefault()
  const handleMouseDownPasswordVerifier = e => e.preventDefault()

  const handlePasswordChange = e => {
    setDisplayResetError(false)
    setPasswordError(false)
    setPassword(e.target.value)
  }

  const handlePasswordVerifierChange = e => {
    setDisplayResetError(false)
    setPasswordVerifierError(false)
    setPasswordVerifier(e.target.value)
  }

  const handlePasswordSubmit = e => {
    e.preventDefault()

    // first is to check if password is filled in
    const isPasswordPopulated = hofs.isPopulated(password)
    setPasswordError(!isPasswordPopulated)

    const isPasswordStrong = passwordScore > 2 ? true : false

    const isPasswordVerifierPopulated = hofs.isPopulated(passwordVerifier)
    const doPasswordsMatch = passwordVerifier === password
    setPasswordVerifierError(!isPasswordVerifierPopulated || !doPasswordsMatch)
    if (!doPasswordsMatch)
      setPasswordVerifierErrorMessage(
        'The password verifier does not match the password above.  Please check both and retry.'
      )

    if ([isPasswordPopulated, isPasswordVerifierPopulated, doPasswordsMatch, isPasswordStrong].every(b => b)) {
      // there are no errors...send the data to backend to complete registration
      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'password': password,
          'token': reset_token
        })
      }

      ;(async () => {
        const response = await fetchData('api/v1/users/forgotpassword/confirm/', 'Error reseting your password.', fetchParams).promise
        console.log("response from forgotpassword/confirm")
        console.dir(response)
        if (response.status === 'OK') {
          // password sent and updated
          setDisplayResetMessage(true)
        } else {
          //console.log('password reset response was corrupted or something')
          // lets still do the same
          setDisplayResetError(true)
        }
      })()
    } else {
      //console.log('there were errors in the password reset submission')
    }
  }

  return (
    <Container component='main' maxWidth='xs' sx={{marginTop: '50px', justifyContent: 'center'}}>
      <Logo width={50} />
      {displayResetError ? <Typography color={theme.palette.error.dark} variant='h4'>We could not reset your password.  Please try again.</Typography> : null}
      {displayResetMessage ? <PasswordResetSuccess /> :
        <form sx={{ width: '100%', marginTop: theme.spacing(1) }} noValidate onSubmit={handlePasswordSubmit}>
          <Typography sx={{marginTop: '15px'}}>To set you set your password and complete site registrion of to reset your password with our site insert your new password below.</Typography>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type={valuesPassword.showPassword ? 'text' : 'password'}
              id='password'
              autoComplete='current-password'
              error={passwordError}
              helperText={passwordError ? passwordErrorMessage : null}
              onChange={handlePasswordChange}
              inputProps={{ maxLength: 100 }}
              InputProps={{
                endAdornment:
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {valuesPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            <PasswordStrengthBar
              password={password}
              onChangeScore={(score,feedback) => {
                  setPasswordScore(score)
                }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password_verifier'
              label='Password Verifier'
              type={valuesPasswordVerifier.showPasswordVerifier ? 'text' : 'password'}
              id='password_verifier'
              autoComplete='current-password'
              error={passwordVerifierError}
              onChange={handlePasswordVerifierChange}
              helperText={passwordVerifierError ? passwordVerifierErrorMessage : null}
              inputProps={{ maxLength: 100 }}
              InputProps={{
                endAdornment:
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password verifier visibility'
                      onClick={handleClickShowPasswordVerifier}
                      onMouseDown={handleMouseDownPasswordVerifier}
                    >
                      {valuesPasswordVerifier.showPasswordVerifier ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={{ margin: theme.spacing(3, 0, 2) }}
          >
            Reset Password Or Complete Registration
          </Button>
        </form>
      }
    </Container>
  )
}

export default PasswordResetForm
