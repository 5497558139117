import React, { useEffect, useState } from 'react'
import { Box, Typography, Stack, Tooltip, IconButton, Divider, CircularProgress, Button } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import ScreenerListLive from './ScreenerListLive'
import ScreenerListDeletedItem from './ScreenerListDeletedItem' 
import ScreenerListAdd from './ScreenerListAdd'
import fetchData from '../../utility/endpointFetch'
import { checkArraysEqual } from '../../utility/objHelper';

import { RiVipCrownFill } from 'react-icons/ri'
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles'

const ScreenerList = ({ changeScreener, selectedScreener}) => {

    const theme = useTheme()

    console.log("top of ScreenerList and selectedScreener is:")
    console.dir(selectedScreener)

    const [isLoading,setIsLoading] = useState(true)
    const [localUserData,setLocalUserData] = useState({})
    const [localScreenerList,setLocalScreenerList] = useState([])
    const [localDeletedScreenerList,setLocalDeletedScreenerList] = useState([])
    const [vipScreenerName,setVIPScreenerName] = useState('')
    //const [screenerListObject,setScreenerListObject] = useState({}) do not see where it's used...
    const [errorPopupOpen, setErrorPopupOpen] = React.useState(false);
    const [errorPopupMessage, setErrorPopupMessage] = React.useState('');

    const handleCloseErrorPopup = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorPopupOpen(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleCloseErrorPopup}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseErrorPopup}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const getAllScreeners = () => {
        //console.log("top of getAllScreeners")
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }
        ;(async () => {
            const getCurrentScreenersResponse = await fetchData(`api/v1/users/screener/`, "could not get all screeners in all vip coins list", fetchParams).promise
            //console.log("getCurrentScreenersResponse getting user data in all vip coins list")
            //console.dir(getCurrentScreenersResponse)
            return getCurrentScreenersResponse.results
        })() 
    }

    const setNewCoinsUserObject = (newCoins) => {
        //console.log("TOP OF setNewCoinsUserObject and newCoins is:")
        //console.dir(newCoins)
        const fetchSetUserCoinsParams = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    'coin': newCoins
                }
            )
        }
        const errorMessage = 'Error setting new coins for vip filter.  Resubmit.  If still an error please contact support.'
        ;(async () => {
            try {
                const setUserCoinsResponse = await fetchData('api/v1/users/userdata/', errorMessage, fetchSetUserCoinsParams).promise
                //console.log("setUserCoinsResponse is:")
                //console.dir(setUserCoinsResponse)
                getNewUserObject()
            } catch (error) {
                console.log("something is broke setting new coins within setNewCounsUserObject", error)
            }                
        })()  

    }

    const getNewUserObject = () => {
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },            
        }
        ;(async () => {
            try {
                const getUserObject = await fetchData(`api/v1/users/userdata/`, "could not get new user object", fetchParams).promise
                //console.log("getUserObject in ScreenerList is")
                //console.dir(getUserObject)
                setLocalUserData(getUserObject)
                setLocalScreenerList(getUserObject.screener_name)
                setLocalDeletedScreenerList(getUserObject.deleted_screener)

                {/*
                no longer need this if userObject.coin is empty...all coins is assumed

                if (getUserObject.coin.length === 0) {
                    //there is no current watchlist set for vip....set one if available
                    if (getUserObject.screener_name.length === 1) {
                        //console.log("there is only one screener...set it's coins in coin")
                        //this is the first screener for this user....set it as the VIP coin filter by
                        //populating userObject.coin
                        //as there is only one screener...set that one screeners coins as vip coin filter in userObject.coin
                        let currentScreeners = getAllScreeners()
                        let newVIPCoins = currentScreeners.map( (screenerCoin,index) => { return screenerCoin.coin_id} )
                        //console.log("Only one newVIPCoins is:")
                        //console.dir(newVIPCoins)
                        setNewCoinsUserObject(newVIPCoins)                     

                    } else if (getUserObject.screener_name.length === 0) {
                        //console.log("there no screeners...cannot set coins in coin")
                        //do not do anything as nothing to set coin to
                    } else {
                        //there's more than on screener while coin is empty....just choose the first one and set it.
                        //console.log("there is more than one screener...set first screener coins in coin")
                        let currentScreeners = getAllScreeners()
                        let firstScreenerName = currentScreeners[0]['screener_name']
                        //console.log("firstScreenerName is: " + firstScreenerName)
                        let newVIPCoins = currentScreeners.map( (screenerCoin,index) => { 
                            if (screenerCoin.screener_name === firstScreenerName)
                                return screenerCoin.coin_id
                        } )
                        //console.log("firstScreenerName newVIPCoins is:")
                        //console.dir(newVIPCoins)
                        setNewCoinsUserObject(newVIPCoins)                                             
                    }
                } else {
                    console.log('ALREADY SOMETHING IN getUserObject.coin in ScreenerList')
                    //console.dir(getUserObject)
                }
                */}

                return getUserObject
                
            } catch (error) {
                console.log("something is broke getting new userObject: ", error)
            }

        })()        
    }

    const checkVIPArraysEqual = (screenerCoins,vipCoins=[], screenerName) => {
        //screener is the "whole coin with loaded data" and cannot compare in that state
        //console.log("top of checkVIPArraysEqual and screenerName is:")
        //console.dir(screenerName)
        //console.log("vipCoins is:")
        //console.dir(vipCoins)
        if (checkArraysEqual(screenerCoins,vipCoins)) {
        //if (Array.isArray(reducedScreener) && Array.isArray(localCoin) && reducedScreener.length === localCoin.length && reducedScreener.every((val, index) => localCoin.includes(val))) {
            //console.log("setting vipScreenerName to: " + screenerName)
            setVIPScreenerName(screenerName)
            return true
        } else {
            //console.log("does not match")
            return false
        }            
    }    

    useEffect(() => {
        //console.log("top of ScreenerList useEffect")
        setIsLoading(true)

        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },            
        }

        ;(async () => {
            //need to set which is the currently chosen 
            //let newUserData = await getNewUserObject().promise
            
            const getUserObject = await fetchData(`api/v1/users/userdata/`, "could not get new user object", fetchParams).promise
            console.log("getUserObject in ScreenerList is")
            console.dir(getUserObject)
            setLocalUserData(getUserObject)
            setLocalScreenerList(getUserObject.screener_name)
            setLocalDeletedScreenerList(getUserObject.deleted_screener)
            setIsLoading(false)

            if (getUserObject.coin.length > 0) {
                //only need to check for a vip list match if there are any coins in .coin
                getUserObject.screener_name.map( currentScreenerName => {
                    if (currentScreenerName !== 'All Coins') {
                        ;(async () => {
                            const getCurrentScreenerNameCoinsResponse = await fetchData(`api/v1/users/screener/?screener_name=${currentScreenerName}`, "could not get screener coins to check if vip in screenerlist", fetchParams).promise
                            console.log("getCurrentScreenerNameCoinsResponse getting coins of specific screener")
                            console.dir(getCurrentScreenerNameCoinsResponse)

                            let reducedScreener =  getCurrentScreenerNameCoinsResponse.results.map(thisScreener => thisScreener.coin_id)

                            checkVIPArraysEqual(reducedScreener,getUserObject.coin,currentScreenerName) 
                        })()

                    } else {
                        //no need to check all coins as that cannot be 
                        console.log("skipping all coins")
                    }
                })

            } else {
                //there are no vip coins so cannot be a selected vip list
                //so as this is the initial useeffect call at the beginning of the page only leave vipScreenerName as ''
                console.log("nothing in .coin to check if vip")
            }

            /* not sure what this section is actually doing with the data outside of this loop
            it creates objectScreener, allScreenersArray and selectedScreener but does nothing with them outside of this section of code
            ;(async () => {
                const getCurrentScreenersResponse = await fetchData(`api/v1/users/screener/`, "could not get all screeners in screeners list onLoad useEffect", fetchParams).promise
                //console.log("getCurrentScreenersResponse getting user data in screeners list onLoad useEffect")
                //console.dir(getCurrentScreenersResponse)
                let objectScreeners = {} //why this needs to be an object instead of array??? 
                getCurrentScreenersResponse.results.map( coin => {
                    if (!objectScreeners.hasOwnProperty(coin.screener_name)) {
                        objectScreeners[coin.screener_name] = []
                    }
                    objectScreeners[coin.screener_name].push(coin)
                })
                //console.log("objectScreeners in useEffect is:")
                //console.dir(objectScreeners)        
                //setScreenerListObject(objectScreeners)

                let allScreenersArray = Object.entries(objectScreeners)
                //console.log("-----allScreenersArray is: ")
                //console.dir(allScreenersArray)
                let selectedScreener = allScreenersArray.find((screener, index) => checkVIPArraysEqual(screener[1],screener[0],getUserObject.coin))    
                //console.log("selectedScreener in useEffect is: ")
                //console.dir(selectedScreener)    
                setIsLoading(false)           
            })() 
            */
        })() 
        
    }, [])

    const removeScreener = (screenerToRemove) => {
        console.log("top of removeScreener: ")
        console.dir(screenerToRemove)
        console.dir(localUserData['deleted_screener'])
        if (screenerToRemove !== vipScreenerName && screenerToRemove !== 'Favorites' && screenerToRemove !== 'All') {
        
            let newScreenerList = localScreenerList.filter( screener => screener !== screenerToRemove)
            console.log("newScreenerList is:")
            console.dir(newScreenerList)
            let deletedScreeners = [ ...localUserData.deleted_screener, screenerToRemove ]
            //let newUserObject = {...localUserData, "screener_name": newScreenerList, "deleted_screener": deletedScreeners}

            let tempData = {...localUserData};
            tempData['screener_name'] = newScreenerList
            tempData['deleted_screener'] = deletedScreeners
            const fetchRemoveScreenerParams = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(tempData)
            }
            const errorMessage = 'Error removing this screener.  Resubmit.  If still an error please contact support.'
            ;(async () => {
                try {
                    const removeScreenerResponse = await fetchData('api/v1/users/userdata/', errorMessage, fetchRemoveScreenerParams).promise
                    console.log("removeScreenerResponse is:")
                    console.dir(removeScreenerResponse)
                    console.log("fetchRemoveScreenerParams is: ")
                    console.dir(fetchRemoveScreenerParams)
                    getNewUserObject()
                    //instead of changing to "" (default 'all coins'), change to favorites as that is always there
                    changeScreener('Favorites')                
                } catch (error) {
                    console.log("something is broke removing screener", error)
                }                
            })()        
        } else {
            console.log('cannot remove the current vip watchlist')
        }
    }

    const AddNewScreener = (newScreenerName) => {
        console.log("top of AddNewScreener")
        console.dir(localUserData)

        //need to check if screener already exists before adding
        //check screener_name and deleted_screener
        let checkingInCurrent = localUserData.screener_name.filter( screener => screener === newScreenerName)
        let checkingInDeleted = localUserData.deleted_screener.filter( screener => screener === newScreenerName)

        if ((checkingInCurrent.length === 0) && (checkingInDeleted.length === 0)) {

            let newScreenerList = [ ...localScreenerList, newScreenerName]
            let newUserObject = {...localUserData, "screener_name": newScreenerList}
            setLocalScreenerList(newScreenerList)
            
            //console.log("localUserData after add new screener name")
            //console.dir(newUserObject)
            const fetchParams = {
                method: 'PUT',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    newUserObject
                )            
            }
            ;(async () => {
                try {
                    let createNewScreenerURL = 'api/v1/users/userdata/'
                    const createNewScreenerResponse = await fetchData(createNewScreenerURL, "could not add new screener", fetchParams).promise
                    console.log("createNewScreenerResponse creating new screener")
                    console.dir(createNewScreenerResponse)
                    console.log("createNewScreenerURL is:" + createNewScreenerURL)
                    console.log("fetchParams is:")
                    console.dir(fetchParams)

                    console.log("newScreenerList after setting is:")
                    console.dir(newScreenerList)

                    getNewUserObject()
                    changeScreener(newScreenerName)
                
                } catch (error) {
                    console.log("something is broke adding new screener", error)
                }
            })()
        } else {
            setErrorPopupMessage("screener name already exists")
            setErrorPopupOpen(true)
        }
    }

    const restoreScreener = (screenerToRestore) => {
        //console.log("top of restoreScreener")
        //console.dir(localUserData)
        let tempUserObject = {...localUserData};
        tempUserObject['screener_name'] = [...localUserData['screener_name'] , screenerToRestore ]
        //console.log("localUserData['deleted_screener'].length is: " + localUserData['deleted_screener'].length)
        if (localUserData['deleted_screener'].length > 1) {
            tempUserObject['deleted_screener'] = localUserData['deleted_screener'].filter( toRestore => screenerToRestore !== toRestore)
        } else {
            //removing the only one so...
            tempUserObject['deleted_screener'] = []
        }
        //console.log("tempUserObject is:")
        //console.dir(tempUserObject)
        const fetchParams = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(tempUserObject)
        }
        const errorMessage = 'Error restoring the wallet.  Resubmit.  If still an error please contact support.'
        ;(async () => {
            const restoreScreenerResponse = await fetchData('api/v1/users/userdata/', errorMessage, fetchParams).promise
            //console.log("restoreScreenerResponse is")
            //console.dir(restoreScreenerResponse)
            getNewUserObject()
            changeScreener(screenerToRestore)
        })()
    }

    const setVIPCoinsFromScreener = (selectedScreenerForVIP) => {
        console.log("top of setVIPCoinsFromScreener and selectedScreenerForVIP is: " + selectedScreenerForVIP)
        if (selectedScreenerForVIP !== '') {
            const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                'Content-Type': 'application/json'
                },
            }
            ;(async () => {
                try {
                    setVIPScreenerName(selectedScreenerForVIP)
                    if (selectedScreenerForVIP === 'All Coins') {
                        //no need to get the current list of coins when this screener has all coins...but userObject.coin is set to empty
                        setNewCoinsUserObject([])
                    } else {
                        var getScreenerCoinsURL = `api/v1/users/screener/?screener_name=${selectedScreenerForVIP}`
                        const getScreenerCoinsresponse = await fetchData(getScreenerCoinsURL, "could not receive screener coin data", fetchParams).promise
                        console.log("getScreenerCoinsresponse getting screener coins data")
                        console.log("getScreenerCoinsURL is: " + getScreenerCoinsURL)
                        console.dir(getScreenerCoinsresponse)
                        if (getScreenerCoinsresponse.count > 0) {
                            //get all coin id within this result into one array to add to coinmarket chart url selection
                
                            let coinsInList = getScreenerCoinsresponse.results.map( (coinDetails) => { return coinDetails.coin_id } )
                            console.log("coinsInList before duplicates removed is: ")
                            console.dir(coinsInList)
                            setNewCoinsUserObject(coinsInList)
                        } else {
                            //nothing in this screener list...set nothing
                            console.log("There was nothing in the screener so cannot set")
                        }
                        setIsLoading(false)
                    }
        
                } catch (error) {
                    //console.log("something is broke getting screener coins in MyCurrencies: " + error )
                }
            })() 
        }       
    }

    if (isLoading) return <CircularProgress />
    return (
        <Box sx={{width: "100%"}}>
            <Snackbar
                open={errorPopupOpen}
                autoHideDuration={10000}
                onClose={handleCloseErrorPopup}
                action={action}
            >
                <Alert severity={'error'}>{`Error: ${errorPopupMessage}`}</Alert>
            </Snackbar>
            <Stack direction="column" display="flex" alignItems="flex-start" sx={{width: "90%"}}>
                <Box sx={{width: '100%'}}>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" sx={{width: '100%'}}>
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                            <Typography variant="h4" sx={{ width: '100%', marginBottom: '10px'}}>Watchlists</Typography>
                            <Tooltip  title={"Here you can create multiple Watchlists to track coins of interest. VIP notifies you of events matching coins in your currently selected Watchlist."} >
                                <IconButton size="small" sx={{position: 'relative', bottom: '5px'}}>
                                    <InfoIcon sx={{fontSize: '1.2rem', color: theme.palette.primary.main}} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-end" sx={{marginBottom: '8px'}}>
                            <RiVipCrownFill />
                            <Tooltip  title={"The selected list will be the list used for coins to filter by within the VIP feature.  If no list is chosen all coins will be used "} >
                                <IconButton size="small" >
                                    <InfoIcon sx={{fontSize: '1.2rem', color: theme.palette.primary.main}} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </Box>
                <Divider sx={{width: '100%', color: theme.palette.divider}} />
                <ScreenerListLive
                    liveScreenerList={localScreenerList}
                    selectedScreener={selectedScreener} 
                    changeScreener={changeScreener} 
                    removeScreener={removeScreener} 
                    vipScreenerName={vipScreenerName} 
                    setVIPCoinsFromScreener={setVIPCoinsFromScreener} 
                    localUserData={localUserData}
                />

                {localScreenerList.length === 0 ? <Typography>There are no current screeners. Add one</Typography> : null}
                <ScreenerListAdd AddNewScreener={AddNewScreener} />
                <Typography variant="h4" sx={{borderBottom: 1, borderColor: theme.palette.grey[800], width: '100%', marginTop: '15px', marginBottom: '10px'}}>Deleted Screeners</Typography>
                {localDeletedScreenerList.map( (removedScreener, index ) => <ScreenerListDeletedItem deletedScreener={removedScreener} restoreScreener={restoreScreener} />)}
            </Stack>
        </Box>        
    )
}

export default ScreenerList