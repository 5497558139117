import React, { useEffect, useState } from 'react'
import { Box, Typography, Stack, Divider, CircularProgress, Tab, FormGroup, FormControlLabel, Checkbox  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'

import { useTheme } from '@mui/material/styles'

import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'

import CustomTweetFeedsList from './CustomTweetFeedsList'
import CustomTweetFeedSearchBox from './CustomTweetFeedSearchBox'
import CustomFeedManager from '../TweetDisplay/CustomFeedManager'
import fetchData from '../../utility/endpointFetch'

const CustomTweetFeedsDisplay = ( {panelID, deleteBox, boxHeight, rowHeight, boxWidth, selectedTab='1', changeTabInLayout, userObject} ) => {
    console.log("top of CustomTweetFeedsDisplay and userObject is:")
    console.dir(userObject)

    const theme = useTheme()

    const itemsPerResultPage = 50

    const [isLoading,setIsLoading] = useState(true)
    const [oneResults,setOneresults] = useState([])
    const [oneFeedPage,setOneFeedPage] = useState(1)
    const [oneFeedCount,setOneFeedCount] = useState(0)
    const [twoResults,setTworesults] = useState([])
    const [twoFeedPage,setTwoFeedPage] = useState(1)
    const [twoFeedCount,setTwoFeedCount] = useState(0)
    const [threeResults,setThreeresults] = useState([])
    const [threeFeedPage,setThreeFeedPage] = useState(1)
    const [threeFeedCount,setThreeFeedCount] = useState(0)
    const [tabValue,setTabValue] = useState(selectedTab)
    const [currentOpenTab,setCurrentOpenTab] = useState('one')

    const [filteringByKeywords, setFilteringByKeywords] = useState({
        'One': false,
        'Two': false,
        'Three': false
    })    

    function getCustomTweetFeedResults(type, useFilterByKeyword) {
        //console.log("top of getCustomTweetFeedResults and type is: ")
        //console.dir(type)
        //if (typeof type === 'string') 
        //    console.log("type is a string") 
        //else console.log("type is not a string")
        //console.log('useFilterByKeyword is:')
        //console.dir(useFilterByKeyword)
        setIsLoading(true)
        {/*
            ?search= (search through text)
            ?ordering= (order by created, polarity, subjectivity, retweets, favorites, social_engagement, body_date (use - to reverse order))
            ?<field>= (filter by twitter_user__name, twitter_user__is_influencer, twitter_user__followers, created__gte, created__lte, coins, vip, retweets, favorites, display_name)
            ?limit= (number of items returned)
            ?offset= (offset the list of items from the first item)    
    
        */}
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },
        }
        let customTweetURL = ''
        let filterByKeyword = ''
        if (useFilterByKeyword === true) filterByKeyword = '&has_keyword=true'
        var offset= 0 
        var noTwitterToFollow = true
        switch (type) {
            case '1': 
                console.log("userObject.twitter_feed_one is:")
                console.dir(userObject.twitter_feed_one)
                if (userObject.twitter_feed_one.length > 0 ) {
                    offset = (oneFeedPage -1 ) * itemsPerResultPage //limit per page
                    customTweetURL = `api/v1/feed/alltweets/?twitter_display_name=${userObject.twitter_feed_one.toString()}&limit=${itemsPerResultPage}&offset=${offset}${filterByKeyword}`
                    noTwitterToFollow = false
                } 
                break
            case '2':
                console.log("userObject.twitter_feed_two is:")
                console.dir(userObject.twitter_feed_two)                   
                if (userObject.twitter_feed_two.length > 0 ) {
                    offset = (twoFeedPage -1 ) * itemsPerResultPage //limit per page
                    customTweetURL = `api/v1/feed/alltweets/?twitter_display_name=${userObject.twitter_feed_two.toString()}&&limit=${itemsPerResultPage}&offset=${offset}${filterByKeyword}`
                    noTwitterToFollow = false
                }                    
                break
            case '3':
                console.log("userObject.twitter_feed_three is:")
                console.dir(userObject.twitter_feed_three)                    
                if (userObject.twitter_feed_three.length > 0 ) {
                    offset = (threeFeedPage -1 ) * itemsPerResultPage //limit per page
                    customTweetURL = `api/v1/feed/alltweets/?twitter_display_name=${userObject.twitter_feed_three.toString()}&&limit=${itemsPerResultPage}&offset=${offset}${filterByKeyword}`
                    noTwitterToFollow = false
                }                       
                break
            default:
                console.log("type not passed to getFeedResults and type id: " + type)
                //set result to nothing and return empty
                break
        }
        if (noTwitterToFollow) {
            //there are no twitter_display_name to follow for this feed
            console.log("there are no twitter_display_name to follow for this list")
            switch (type) {
                case '1': 
                    setOneresults([])
                    break
                case '2':
                    setTworesults([])
                    break
                case '3':
                    setThreeresults([])
                    break
                default:
                    console.log("type not passed to getFeedResults and type id: " + type)
                    break
            }
            setIsLoading(false)
        } else {
            ;(async () => {
                try {
                    const customTwitterResponse = await fetchData(customTweetURL, "could not receive twitter result in customTweetFeeds", fetchParams).promise
                    console.log("customTwitterResponse is:")
                    console.dir(customTwitterResponse)
                    console.log("customTweetURL is: ")
                    console.dir(customTweetURL)
                    switch (type) {
                        case '1': 
                            setOneresults(customTwitterResponse['results'])
                            break
                        case '2':
                            setTworesults(customTwitterResponse['results'])
                            break
                        case '3':
                            setThreeresults(customTwitterResponse['results'])
                            break
                        default:
                            console.log("type not passed to getFeedResults and type id: " + type)
                            break
                    }
                    setIsLoading(false)

                } catch (error) {
                    console.log("something is broke getting twitter result in customTweetFeeds: " + error)
                }
            })()
        }
    }

    const searchCustom = (search,useKeywordFilter=false) => {
        setIsLoading(true)
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },
        }
        var offset= 0 
        let customTwitterSearchUrl = ''
        let filterByKeyword = ''
        if (useKeywordFilter === true) filterByKeyword = '&has_keyword=true'
        switch (tabValue) {
            case '1':
                offset = (oneFeedPage -1 ) * itemsPerResultPage //limit per page
                
                customTwitterSearchUrl = `api/v1/feed/alltweets/?twitter_display_name=${userObject.twitter_feed_one.toString()}&search=${search}&limit=${itemsPerResultPage}&offset=${offset}${filterByKeyword}`
                break
            case '2':
                offset = (twoFeedPage -1 ) * itemsPerResultPage //limit per page
                if (filteringByKeywords['Two'] === true) filterByKeyword = '&has_keyword=true'                
                customTwitterSearchUrl = `api/v1/feed/alltweets/?twitter_display_name=${userObject.twitter_feed_two.toString()}search=${search}&limit=${itemsPerResultPage}&offset=${offset}${filterByKeyword}`
                break
            case '3':
                offset = (threeFeedPage -1 ) * itemsPerResultPage //limit per page
                if (filteringByKeywords['Three'] === true) filterByKeyword = '&has_keyword=true'
                customTwitterSearchUrl = `api/v1/feed/alltweets/?twitter_display_name=${userObject.twitter_feed_three.toString()}search=${search}&limit=${itemsPerResultPage}&offset=${offset}${filterByKeyword}`
                break
            default:
                console.log("tabValue not passed to getFeedResults and tabValue id: " + tabValue)
                //set result to nothing and return empty
                break
        }

        ;(async () => {
            try {
                const customTwitterSearchResponse = await fetchData(customTwitterSearchUrl, "could not receive twitter search result in customTweetFeeds", fetchParams).promise
                console.log("customTwitterResponse is:")
                console.dir(customTwitterSearchResponse)
                console.log("customTwitterSearchUrl is: ")
                console.dir(customTwitterSearchUrl)
                switch (tabValue) {
                    case '1': 
                        setOneresults(customTwitterSearchResponse['results'])
                        setOneFeedCount(customTwitterSearchResponse['count'])
                        break
                    case '2':
                        setTworesults(customTwitterSearchResponse['results'])
                        setTwoFeedCount(customTwitterSearchResponse['count'])
                        break
                    case '3':
                        setThreeresults(customTwitterSearchResponse['results'])
                        setThreeFeedCount(customTwitterSearchResponse['count'])
                        break
                    default:
                        console.log("tabValue not passed to getFeedResults and tabValue id: " + tabValue)
                        break
                }
                setIsLoading(false)                
            } catch (error) {
                console.log("something is broke getting twitter search result in customTweetFeeds: " + error)
            }
        })()
    }    

    const searchFeed = (searchTerm) => {
        //console.log("top of searchFeed and searchTerm is: " + searchTerm)
        //console.log("tabValue is: " + tabValue)
        searchCustom(searchTerm)

    }

    useEffect(() => {
        //console.log("top of useEffect to get homefeed")
        setIsLoading(true)
        let filterByKeyword = false
        switch (tabValue) {
            case '1':
                filterByKeyword = filteringByKeywords['One']
                break
            case '2':
                filterByKeyword = filteringByKeywords['Two']
                break
            case '3':
                filterByKeyword = filteringByKeywords['Three']
                break
            default:
                console.log('useEffect change  tabValue,oneFeedPage,twoFeedPage,threeFeedPage and tabValue did not match: ' + tabValue)
        }           
        getCustomTweetFeedResults(tabValue, filterByKeyword)
    
    }, [tabValue,oneFeedPage,twoFeedPage,threeFeedPage])    

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
        switch (newValue) {
            case '1':
                changeTabInLayout('1',panelID)
                setCurrentOpenTab('one')
                break
            case '2':
                changeTabInLayout('2',panelID)
                setCurrentOpenTab('two')
                break
            case '3':
                changeTabInLayout('3',panelID)
                setCurrentOpenTab('three')
                break
            default:
                console.log('handleTabChange newValue is and did not match: ' + newValue)

        }        
        
    }      

    const filterByKeywords = (col) => {
        //console.log("top of filterByKeywords and col is: " + col)
        switch(col){
            case 'One':
                getCustomTweetFeedResults('1',!filteringByKeywords['One'])
                setFilteringByKeywords({...filteringByKeywords, One: !filteringByKeywords['One']})
                getCustomTweetFeedResults('1')
                break;
            case 'Two':
                getCustomTweetFeedResults('1',!filteringByKeywords['Two'])
                setFilteringByKeywords({...filteringByKeywords, Two: !filteringByKeywords['Two']})
                getCustomTweetFeedResults('2')
                break;
            case 'Three':
                getCustomTweetFeedResults('1',!filteringByKeywords['Three'])
                setFilteringByKeywords({...filteringByKeywords, Three: !filteringByKeywords['Three']})
                getCustomTweetFeedResults('3')
                break;
            default:
                break;
        }
    }    

    const changeFeedPage = (page,feedNumber) => {
        switch (feedNumber) {
            case '1':
                setOneFeedPage(page)
                break
            case '2':
                setTwoFeedPage(page)
                break
            case '3':
                setThreeFeedPage(page)
                break
            default:
                console.log('changeFeedPage sent page: ' + page)

        }
    }

    const clearSearchResults = (clearType) => {
        //clear the search results by triggering a full api call for this tab
        //console.log("top of clearSearchResults and type is: " + clearType)
        let filterByKeyword = false
        switch (clearType.toString()) {
            case '1':
                filterByKeyword = filteringByKeywords['One']
                break
            case '2':
                filterByKeyword = filteringByKeywords['Two']
                break
            case '3':
                filterByKeyword = filteringByKeywords['Three']
                break
            default:
                console.log('clearSearchResults clearType.toString() is and did not match: ' + clearType.toString())
        }                   
        getCustomTweetFeedResults(clearType.toString(), filterByKeyword)
    }    

    const refreshFeed = (column) => {
        switch(column){
            case "One":
                getCustomTweetFeedResults('1', filteringByKeywords['One'] ? true : false);
                break;
            case "Two":
                getCustomTweetFeedResults('2', filteringByKeywords['Two'] ? true : false);
                break;
            case "Three":
                getCustomTweetFeedResults('3', filteringByKeywords['Three'] ? true : false);
                break;
            default:
                break;
        }
    }    

    const removeCustomTweetFeedBox = () => {
        //console.log("top of removeCustomTweetFeedBox and panelID is: " + panelID)
        deleteBox(panelID)
    }    

    const maxBoxHeight = rowHeight * boxHeight

    return (
        <Box sx={{ display: 'flex', width: 'calc(100% - 10px)', marginLeft: '5px' }}>
            <Stack direction="column" display="flex" alignItems='left' sx={{width: '100%'}}>
                <Stack direction="row" display="flex" alignItems='center' justifyContent='space-between' sx={{marginTop: '5px', marginRight: '5px'}}>
                    <Stack direction="row" display="flex" alignItems='center' justifyContent='flex-start' >
                        <Typography sx={{fontWeight: 400}} color={theme.palette.grey[300]}>{"Custom Twitter Feed"}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent='flex-end'>  
                        <CustomFeedManager
                            column={currentOpenTab}
                            refreshFeed={refreshFeed}
                            usedFrom='dashboard'
                        />
                        <VscMove 
                            className='draggable-handle' 
                            color={theme.palette.warning.dark} 
                            size={18} 
                            style={{cursor: 'grabbing'}}
                        />
                        <MdCancel 
                            className='draggable-cancel' 
                            color={theme.palette.secondary.dark} 
                            size={18} 
                            onClick={removeCustomTweetFeedBox}
                            style={{cursor: 'pointer'}}
                        />

                    </Stack>
                </Stack>   
                <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>     
                    <TabContext value={tabValue} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList 
                                onChange={handleTabChange} 
                                aria-label="" 
                                indicatorColor="secondary" 
                                textColor="primary" 
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="feed 1" value="1" sx={{minWidth: '15px'}} />
                                <Tab label="feed 2" value="2" sx={{minWidth: '15px'}} />
                                <Tab label="feed 3" value="3" sx={{minWidth: '15px'}} />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{maxHeight: (maxBoxHeight - 70), paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflowY: 'auto', scrollbarWidth: 'thin', width: '100%'}}>
                                <Stack direction="column" alignItems='flex-start' justifyContent='flex-start' sx={{width: '100%'}}>
                                    <Stack direction="row" alignItems="center" justifyContent='space-between' sx={{width: '100%'}}>
                                        <CustomTweetFeedSearchBox
                                            type={1}
                                            searchFeed={searchFeed}
                                            clearSearchResults={clearSearchResults}
                                        />
                                        <Stack direction="row" alignItems="center" justifyContent='flex-end'>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                        <Checkbox  
                                                            size="small"
                                                            checked={filteringByKeywords['One'] ? true : false }
                                                            onChange={()=>filterByKeywords('One')}
                                                            color={filteringByKeywords['One'] ? 'primary' : 'secondary'}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } 
                                                    label="use keywords" 
                                                    color={filteringByKeywords['One'] ? 'primary' : 'secondary'}
                                                />
                                            </FormGroup>  
                                        </Stack>                                      
                                    </Stack>
                                    {isLoading ? <CircularProgress /> : 
                                        <CustomTweetFeedsList 
                                            feedNumber={1}
                                            feedToDisplay={oneResults}
                                            feedCount={oneFeedCount}
                                            feedPage={oneFeedPage}
                                            changeFeedPage={changeFeedPage}
                                            divHeight={boxHeight * rowHeight}
                                            boxWidth={boxWidth}
                                        />
                                    }
                                </Stack>
                            </Box>
                        </TabPanel>  
                        <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{maxHeight: (maxBoxHeight - 70), paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflowY: 'auto', scrollbarWidth: 'thin', width: '100%'}}>
                                <Stack direction="column" alignItems='flex-start' justifyContent='flex-start' sx={{width: '100%'}}>
                                    <Stack direction="row" alignItems="center" justifyContent='space-between' sx={{width: '100%'}}>
                                        <CustomTweetFeedSearchBox
                                            type={2}
                                            searchFeed={searchFeed}
                                            clearSearchResults={clearSearchResults}
                                        />         
                                        <Stack direction="row" alignItems="center" justifyContent='flex-end'>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                        <Checkbox  
                                                            size="small"
                                                            checked={filteringByKeywords['Two'] ? true : false }
                                                            onChange={()=>filterByKeywords('Two')}
                                                            color={filteringByKeywords['Two'] ? 'primary' : 'secondary'}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } 
                                                    label="use keywords" 
                                                    color={filteringByKeywords['One'] ? 'primary' : 'secondary'}
                                                />
                                            </FormGroup>  
                                        </Stack> 
                                    </Stack>                                                           
                                    {isLoading ? <CircularProgress /> : 
                                        <CustomTweetFeedsList 
                                            feedNumber={2}                                    
                                            feedToDisplay={twoResults}
                                            feedCount={twoFeedCount}
                                            feedPage={twoFeedPage}
                                            changeFeedPage={changeFeedPage}
                                            divHeight={boxHeight * rowHeight}
                                            boxWidth={boxWidth}                                            
                                        />
                                    }
                                    
                                </Stack>
                            </Box>
                        </TabPanel>    
                        <TabPanel value="3" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{maxHeight: (maxBoxHeight - 70),paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflowY: 'auto', scrollbarWidth: 'thin', width: '100%'}}>
                                <Stack direction="column" alignItems='flex-start' justifyContent='flex-start' sx={{width: '100%'}}>
                                    <Stack direction="row" alignItems="center" justifyContent='space-between' sx={{width: '100%'}}>
                                        <CustomTweetFeedSearchBox
                                            type={3}
                                            searchFeed={searchFeed}
                                            clearSearchResults={clearSearchResults}
                                        />
                                        <Stack direction="row" alignItems="center" justifyContent='flex-end'>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                        <Checkbox  
                                                            size="small"
                                                            checked={filteringByKeywords['Three'] ? true : false }
                                                            onChange={()=>filterByKeywords('Three')}
                                                            color={filteringByKeywords['Three'] ? 'primary' : 'secondary'}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } 
                                                    label="use keywords" 
                                                    color={filteringByKeywords['One'] ? 'primary' : 'secondary'}
                                                />
                                            </FormGroup>  
                                        </Stack> 
                                    </Stack>                                    
                                    {isLoading ? <CircularProgress /> : 
                                        <CustomTweetFeedsList 
                                            feedNumber={3}                                    
                                            feedToDisplay={threeResults}
                                            feedCount={threeFeedCount}
                                            feedPage={threeFeedPage}
                                            changeFeedPage={changeFeedPage}
                                            divHeight={boxHeight * rowHeight}
                                            boxWidth={boxWidth}                                            
                                        />
                                    }
                                </Stack>
                            </Box>
                        </TabPanel>      
                    </TabContext>                
            </Stack>
        </Box>        
    )
}

export default CustomTweetFeedsDisplay