import React from 'react'
import { Button, Link, Container, Typography, Avatar } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Logo from '../Header/Logo'

const RegisterComplete = () => {

  const theme = useTheme();

  return (
    <Container component='main' maxWidth='sm' sx={{marginTop: '50px', justifyContent: 'center'}}>
      <div sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Logo width={50} />
        <Typography variant='h3' sx={{paddingBottom: '25px', marginTop: '15px'}}>
          Registration Verification
        </Typography>
        <p>We've received your email but there's one more step!</p>
        <p>We've sent you an email that contains steps to create the password for your account and complete your registration</p>
      </div>

      <Link href="/login" underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
        <Typography sx ={{color: '#1D9BF0', fontSize: 16, marginTop: 1, marginBottom: 1}} >
          Return to Login
        </Typography>
      </Link>
    </Container>
  )

}

export default RegisterComplete
