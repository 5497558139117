import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import tweetsScreenReducer from "./tweetsScreenReducer";
import vipReducer from "./vipReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    tweetsScreen: tweetsScreenReducer,
    vip: vipReducer,
});

export default reducer;