import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconStethoscope, IconArrowUpCircle, IconArrowDownCircle } from '@tabler/icons';

const Netflow = () => {

    const theme = useTheme();

    const [tabValue,setTabValue] = useState('1')

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h3">About Netflow</Typography>
                <Box sx={{ width: '100%' }}>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="BitCoin Health - Miner Inflows">
                                <Tab icon={<IconBook />} value="1" />
                                <Tab icon={<IconSearch />} value="2" />
                                <Tab icon={<IconStethoscope />} value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Typography variant="h4">
                                Definition
                            </Typography>
                            <Typography variant="body2" mt={1}>
                                Exchange Netflow is the difference between BTC flowing into and out of the exchange. (Inflow - Outflow = Netflow)
                            </Typography>
                            <Typography variant="body2" mt={1}>
                                The mean value is the In/Outflow Total divided by the Transactions Count In/Outflow.
                            </Typography>
                        </TabPanel>
                        <TabPanel value="2">
                            <Typography variant="h4">
                                Interpretation
                            </Typography>
                            <Typography variant="h4">By Value Itself</Typography>
                            <Typography variant="body2" sx={{marginBottom: '5px'}}>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Increase of inflow to exchanges is mostly a Bearish sign "
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Increase of outflow from exchanges is mostly a Bullish sign"
                                        />
                                    </ListItem>
                                </List>
                            </Typography>
                            <Typography variant="h4">By examining trend</Typography>
                            <Typography variant="body2" sx={{marginBottom: '5px'}}>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Increasing inflow/outflow  indicates possible volatility and helps predict market trend"
                                        />
                                    </ListItem>
                                </List>
                            </Typography>
                        </TabPanel>
                        <TabPanel value="16">
                            <Typography variant="h4">About Netflow</Typography>
                            <Typography variant="h5" sx={{fontWeight: 600, marginTop: '15px'}}>1) Inflow vs Outflow</Typography>
                            <Typography variant="body2" sx={{marginTop: '5px'}}>
                                Exchange Netflow is the difference between BTC flowing into and out of the exchange. (Inflow - Outflow = Netflow)
                            </Typography>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Grid>
        </Grid>
    )

}

export default Netflow;