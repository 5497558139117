import * as React from 'react';
import {
  Typography,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles'

const WelcomePage = () => {
    const theme = useTheme()
    return (
        <Box sx={{justifyContent: 'center'}}>
            <Typography align='center' variant="h2" sx={{ mt: 2, mb: 1, color: theme.palette.grey[400] }}>
                Welcome to the
            </Typography>
            <Typography align='center' variant="h2" sx={{ mt: 2, mb: 1, color: theme.palette.primary.main }}>
                Token Information Upload Wizard
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography paragraph align='left' variant="h3" sx={{ mt: 2, mb: 1, color: theme.palette.grey[400] }}>
                - Here you can enter token essential information to earn rewards points.
                </Typography>
                <Typography align='left' variant="h3" sx={{ mt: 2, mb: 1, color: theme.palette.grey[400] }}>
                - The more information entered = more powerful custom searches
                </Typography>
                <Typography paragraph align='left' variant="h3" sx={{ mt: 2, mb: 1, color: theme.palette.grey[400] }}>
                - If your submission is approved then you may select to get notifications of project events without it counting towards your monthly project limit.
                </Typography>
            </Box>
        </Box>
    )
}

export default WelcomePage;
