import { mergeDeepRight } from 'ramda'

import auth from '../Components/Auth/auth'
import getAPIURL from './getAPIURL'

const makeCancelable = promise => {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? reject('Request cancelled.') : resolve(val),
      error => hasCanceled_ ? reject('Request cancelled.') : reject(error)
    )
  })

  return {
    promise: wrappedPromise,
    cancel: () => {
      hasCanceled_ = true
    }
  }
}

// everywhere we use async/await for fetch
// this is just a wrapper for it to reduce boilerplate code.
const waitFetch = async (url, errorMessage, fetchObj) => {
  // check if jwt already expired
  //console.log("top of waitFetch and fetchObj is:")
  //console.dir(fetchObj)

  var login_calling = false
  if (!url.startsWith('http')) {
    url = `${getAPIURL()}/${url}`
  } else if (url === 'api/v1/login/') {
    login_calling = true
  } else {
    //do nothing to url....it's not local
  }
  //console.log("url is: " + url)
  let response
  try {
    //console.log("inside waitFetch")
    //console.dir(url)
    //console.dir(fetchObj)
    response = await fetch(url, fetchObj)
  } catch {
    console.log('Unhandled Exception')
    return Promise.reject('Something bad happened on the server.  You didn\'t get an HTTP response.')
  }
  //console.log("response in waitFetch is:")
  //console.dir(response)
  //console.dir(response.status)
  if (fetchObj.method === 'PUT') {
    if (response.status >= 200 && response.status <= 299) {
      //console.log("PUT status is in the 200's")
      return true
    } else {
      //console.log("PUT status is NOT in the 200's")
      return false
    }
  } else {
    if (response.status >= 200 && response.status <= 299) {
      //console.log("status is in the 200's")
      const data = await response.json()
      return data
    } else if (response.status === 400) {
      //this server responds with 400 if something wrong in the data
      const data = await response.json()
      return data
    } else if (response.status === 401) {
      // if unauthorized is encountered need to direct user to
      // login feature...do not pass go and do not collect $200

      //adding this so no redirect and can properly show login error if login incorrect
      if (login_calling) {
        const data = await response.json()
        //console.log("inside response.status 401 and login_calling")
        //console.dir(data)
        return data

        //return response
      //} else if (response.statusText === 'UNAUTHORIZED') {
        //console.log("inside response.status 401 and statusText is UNAUTHORIZED")
        //console.dir(response)
        // logout removes the token from local storage which redirects the user to /login via App.js routing
      //
        //need to convert to use another component
        window.location.href ='/login'
      } else {
        // 401 but not 'UNAUTHORIZED' ???
        //console.log('401 but message is not UNAUTHORIZED???')
        console.log("inside response.status 401 else")
        console.dir(response)
        auth.logout()
        window.location.href ='/login'
      }
      return Promise.reject('Unauthorized access.  Please log in.')
    } else if (response.status >= 402 && response.status <= 499) {
      // this is a client error.
      // this section will not have errors generated by the endpoint software
      // it will only contain errors of communication to, from or at the endpoint
      // so will not look for software errors and only communication errors
      const event400 = new CustomEvent('fetchError', {
        detail: {
          header: 'Network error',
          errors: [
            'There was an issue in host communication',
            'Communications may have been rejected by the host or some other issue external to the client or host',
            'Hopefully this is temporary. Refresh and try again'
          ]
        }
      })
      window.dispatchEvent(event400)
      return Promise.reject(`host communication error: ${response.status}`)
    } else if (response.status >= 500 && response.status <= 599) {
      // this is an error host side programmatically
      // this should return an error if 500 was
      // generated programmatically, check for those errors
      // if none found produce a generic one
      const jsonData = await response.json()
      let event500
      if (jsonData.errors) {
        event500 = new CustomEvent('fetchError', {
          detail: {
            header: errorMessage,
            errors: jsonData.errors
          }
        })
      } else {
        // host passed back no specific errors
        // create some generic ones
        event500 = new CustomEvent('fetchError', {
          detail: {
            header: errorMessage,
            errors: [
              'There was an undetermined issue in host communication or operation',
              'The exact cause of the issue was not found',
              'Hopefully this is temporary. Refresh and try again'
            ]
          }
        })
      }
      window.dispatchEvent(event500)
      return Promise.reject(`application internal error: ${response.status}`)
    } else {
      // no recognized status code found
      // return general error
      const event = new CustomEvent('fetchError', {
        detail: {
          header: errorMessage,
          errors: [
            'There was an issue in host communication',
            'Communications may have been rejected by the host',
            'Hopefully this is temporary. Refresh and try again'
          ]
        }
      })
      window.dispatchEvent(event)
      return Promise.reject(`unknown http error: ${response.status}`)
    }
  }
}

const fetchData = (url, errorMessage, fetchObj) => {
  const errorTitle = errorMessage || 'An unexpected error occurred'
  const currentToken = auth.getToken()
  const defaultOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      // registration and login don't care if they get the empty Authorization header
      Authorization: currentToken ? `Token ${currentToken}` : ''
    }
  }
  const mergedOptions = mergeDeepRight(defaultOptions, fetchObj || {})
  //console.log("merged headers to send:")
  //console.dir(mergedOptions)
  return makeCancelable(waitFetch(url, errorTitle, mergedOptions))
}




export default fetchData
