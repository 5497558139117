import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles'

const CustomFeedSearch = ({colName, searchField, searchFeedFn, setSearchField}) => {
    const theme = useTheme();

    const [localSearchVal, setLocalSearchVal] = useState('')

    useEffect(() => {
        setLocalSearchVal(searchField)
    }, [searchField])

    return (
        <TextField
            id="search-feed-one"
            label={`Search Custom Feed ${colName}`}
            variant="outlined"
            value={localSearchVal}
            onBlur={() => setSearchField(localSearchVal)}
            onChange={(event) => setLocalSearchVal(event.target.value)}
            size="small"
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                    searchFeedFn(searchField)
                    ev.preventDefault();
                }
            }}
            sx={{
                width: '100%',
                minWidth: '80px',
                color: theme.palette.grey[500],
                input: {
                    background: theme.palette.dark[900],
                },
            }}
            InputLabelProps={{
                style: {
                    color: theme.palette.grey[500],
                },
            }}
            inputProps={{
                style: { height: '12px' },
            }}
        />
    )
}

export default CustomFeedSearch;