import React, {useState} from 'react'
import { IconButton, Divider, Grid, Paper, Box, Popover, Typography, useMediaQuery, Stack, Button,
  TextField } from '@mui/material'

import { useTheme } from '@mui/material/styles'

import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'

import { Clear } from '@mui/icons-material'
import { IconSquareX } from '@tabler/icons'
import { HiOutlineSave } from 'react-icons/hi'

const ScreenerListAdd = ( { AddNewScreener } ) => {

  //this needs to collect the new screener name, add to userObject.screener_name and put to /users/userdata/
  const theme = useTheme()
  const [newScreenerName,setNewScreenerName] = useState('')
  const [newScreenerNameError,setNewScreenerNameError] = useState(false)


  const [addNewScreenerOpen,setAddNewScreenerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const id = addNewScreenerOpen ? 'popover-add-new-screener' : undefined
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
      setAddNewScreenerOpen((prevOpen) => !prevOpen)
  }

  const handleNewScreenerNameChange = (e) => {
    setNewScreenerName(e.target.value)
  }

  const clearName = () => {
    setNewScreenerName('')
    setNewScreenerNameError(false)
  }

  const submitNewName = () => {
    AddNewScreener(newScreenerName)
    setAddNewScreenerOpen(false)
    setNewScreenerName('')
  }

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{borderRadius: '12px', background: theme.palette.primary.dark, '&:hover': {
          background: theme.palette.primary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
          color: theme.palette.primary.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
          },
          marginTop: '10px'
        }}
        size="small"
      >
        create new screener
      </Button>
      <Popover
        id={id}
        open={addNewScreenerOpen}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 400, left: 900 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >

        <Transitions position={matchesXs ? 'top' : 'top-right'} in={addNewScreenerOpen} >
          <Paper >
            <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '600px'}}>
              <Grid container spacing={0} sx={{width: '100%', backgroundColor: theme.palette.dark[800], padding: '10px'}}>
                <Grid item xs={10} >
                  <Typography variant="h3" sx={{marginTop: '15px', marginLeft: '30px'}}>Add A New Screener</Typography>
                </Grid>
                <Grid item xs={2} >
                  <IconButton
                    aria-label="close"
                    sx={{color: theme.palette.error.main}}
                    onClick={handleClick}>
                    <IconSquareX sx={{ marginTop: '15px'}}/>
                  </IconButton>
                </Grid>
                <Grid item xs={12} sx={{padding: '10px'}} ><Divider /></Grid>
                <Grid item xs={12} >
                  <Stack direction="row" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <TextField
                      name='newScreenerName'
                      variant='outlined'
                      required
                      value={newScreenerName}
                      id='newScreenerName'
                      label='Name of your new screener'
                      onChange={handleNewScreenerNameChange}
                      helperText={newScreenerNameError ? 'Please insert a name for your new screener' : null}
                      error={newScreenerNameError}
                      //inputProps={{ maxLength: 50 }}
                      size="small"
                      sx={{width: '75%'}}
                    />

                    <IconButton
                      type="submit"
                      sx={{ p: '10px' }}
                      aria-label="search"
                      onClick={() => submitNewName()}
                    >
                      <HiOutlineSave color={theme.palette.grey[500]} />
                    </IconButton>
                    <IconButton
                      type="submit"
                      sx={{ p: '10px' }}
                      aria-label="search"
                      onClick={() => clearName()}
                    >
                      <Clear sx={{ color: theme.palette.grey[500] }}  />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </MainCard>
          </Paper>
        </Transitions>
      </Popover>
    </div>
  )
}
export default ScreenerListAdd