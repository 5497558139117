import React, { useState, useEffect } from 'react'
import { Grid, Typography, Link, CircularProgress, Box, Tooltip, IconButton, Stack, Modal, Fade, Backdrop, Button, Checkbox, useMediaQuery, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { AreaChart, XAxis, YAxis, Area } from "recharts"
import { abbreviateNumber } from "js-abbreviation-number"
import fetchData from "../../utility/endpointFetch"

import { RiVipCrownFill } from 'react-icons/ri'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import { formatFloatToCurrency } from "../../utility/number";

const CurrenciesListItem = ( {currencyDetails, currentTimeFrame, chartData=[], userObject, allCoinsInVIP, allCoinsInVIPWithScreenerName, visibleColumns } ) => {
  //console.log("top of CurrenciesListItem")
  //console.log("currencyDetails is: ")
  //console.dir(currencyDetails)
  //console.dir("in vip:")
  //console.log(userObject.coin.includes(currencyDetails.coin.id))
  //console.log("chartData is: ")
  //console.dir(chartData)
  //console.log("userObject is:")
  //console.dir(userObject)
  //console.log("allCoinsInVIP is: ")
  //console.dir(allCoinsInVIP)
  //console.log("allCoinsInVIPWithScreenerName is: ")
  //console.dir(allCoinsInVIPWithScreenerName)
  //console.log("allCoinsInVIP.includes(currencyDetails.coin.id is:")
  //console.dir(allCoinsInVIP.includes(currencyDetails.coin.id))
  
  const theme = useTheme()
  const medScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"))

  const typographyStyle = {position: 'relative', top: '45%', zIndex: -1 }

  var six_months_ago = new Date()
  six_months_ago.setMonth(six_months_ago.getMonth() - 6)
  //console.log(six_months_ago.toDateString())
  //console.log(six_months_ago.toISOString())

  var dateSixMonthsAgo = new Date()
  dateSixMonthsAgo.setMonth(dateSixMonthsAgo.getMonth() - 6)


  //const [ chartSeries, setChartSeries ] = useState()
  //const [ chartOptions, setChartOptions ] = useState()
  const [ rechartsData,setRechartsData] = useState([])
  const [ isFavorite, setIsFavorite] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true)
  const [ chartMax,setChartMax ] = useState(0)
  const [ chartMin,setChartMin] = useState(0)
  const [ chartColor, setChartColor] = useState('colorValueUp')
  const [ strokeColor, setStrokeColor ] = useState(theme.palette.success.dark)
  const [ priceColor, setPriceColor] = useState(theme.palette.success.main)
  const [ priceIcon,setPriceIcon] = useState(<ArrowUpward fontSize="small" sx={{ position: 'relative', top: '6px'}}/>)
  const [ percentBTCDiffColor,setPercentBTCDiffColor] = useState(theme.palette.grey[500])
  const [ negPosBTCPercentage, setNegPosBTCPercentage ] = useState('')
  const [ percentETHDiffColor,setPercentETHDiffColor] = useState(theme.palette.grey[500])
  const [ negPosETHPercentage, setNegPosETHPercentage ] = useState('')
  const [ addToWatchListModal, setAddToWatchListModal] = useState(false)
  
  const detailLink = "coindetail/"+currencyDetails.coin

  //need to see which watchlists this coin is in
  var watchListsAndCoinIncludingThisCoin = allCoinsInVIPWithScreenerName.filter( coinAndScreener => coinAndScreener.coin_id === currencyDetails.coin)

  var watchListsIncludingThisCoin = watchListsAndCoinIncludingThisCoin.map(coinAndScreener => { return { "screener_name": coinAndScreener.screener_name, "id": coinAndScreener.id}})

  useEffect(() => {
    setIsFavorite(allCoinsInVIP.includes(currencyDetails.coin))
  }, [allCoinsInVIP, currencyDetails])
  //console.log("watchListsIncludingThisCoin is: ")
  //console.dir(watchListsIncludingThisCoin)
  //console.log("watchListsAndCoinIncludingThisCoin is: ") 
  //console.dir(watchListsAndCoinIncludingThisCoin)

  //remove useEffect unless we reinstitute the 1d, 1w, 2w 1m display
  //but when do get too many re-renders...so there for now

  const removeFromVIP = (screenerItemId) => {
    //console.log("top of removeFromVIP and screenerItemId is: " + screenerItemId)
    const fetchParams = {
      method: 'DELETE',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'coin_id': currencyDetails.coin
      })
    }
    ;(async () => {
      try {
        let removeCoinFromScreenerURL = `api/v1/users/screenercoinupdate/${screenerItemId}/`
        const removeCoinFromScreenerResponse = await fetchData(removeCoinFromScreenerURL, "could not remove coin from screener", fetchParams).promise
        //console.log("removeCoinFromScreenerResponse removing coin from screener")
        //console.dir(removeCoinFromScreenerResponse)
        //console.log(removeCoinFromScreenerURL)

        //need to remove this coin/screener from allCoinsInVIPWithScreenerName and allCoinsInVIP???

      } catch (error) {
        console.log("something is broke saving coin to screener: %s", error)
      }
    })()
  }

  const addToVIP = (screenerName) => {
    //console.log("top of addToVIP and screenerName is: " + screenerName)
    const fetchParams = {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'screener_name': screenerName,
        'user': userObject.user,
        'coin_id': currencyDetails.coin
      })
    }
    ;(async () => {
      try {
        const saveCoinToScreenerResponse = await fetchData(`api/v1/users/screenercoincreate/`, "could not save coin to screener", fetchParams).promise
        //console.log("saveCoinToScreenerResponse saving coin to screener")
        //console.dir(saveCoinToScreenerResponse)

        //need to add this coin/screener from allCoinsInVIPWithScreenerName and allCoinsInVIP???
      } catch (error) {
        console.log("something is broke saving coin to screener: %s", error)
      }
    })()
  }

  const removeOrAddToScreener = (e,screenerNameToAddTo,screenerItemObject) => {
    //console.log("top of removeOrAddToScreener and screenerItemObject is: ")
    //console.dir(screenerItemObject)
    if (e.target.checked === true) {
      //checkbox is now checked...add to screener
      addToVIP(screenerNameToAddTo)
      setIsFavorite(true);
    } else {
      //checkbox is NOT checked....remove from screener
      removeFromVIP(screenerItemObject.id)
      setIsFavorite(false)
    }
  }

  useEffect(() => {
    //get date for volume chart

    //console.log("top of useEffect")
    //console.log("chartData is:")
    //console.dir(chartData)

    if (currencyDetails.btc_24h_dif > 0) {
      setPercentBTCDiffColor(theme.palette.success.dark)
      setNegPosBTCPercentage('+')
    } else if (currencyDetails.btc_24h_dif < 0) {
      setPercentBTCDiffColor(theme.palette.error.dark)
      setNegPosBTCPercentage('-')
    }

    if (parseFloat(currencyDetails.eth_24h_dif) > 0) {
      setPercentETHDiffColor(theme.palette.success.dark)
      setNegPosETHPercentage('+')
    } else if (parseFloat(currencyDetails.eth_24h_dif) < 0) {
      setPercentETHDiffColor(theme.palette.error.dark)
      setNegPosETHPercentage('-')
    }    

    if (chartData.length > 0) {
      //console.log("chartData.length")
      chartData.reverse()

      //get % change in price
      // if coinmarket/ price is king
      //var differenceInPrice = Math.abs(currencyDetails.current_price - chartData[chartData.length - 1].price)

      // if marketchart/ price is king
      //console.log("chartData is in useEffect of CurrenciesListItem: " )
      //console.dir(chartData)
      //get % change for current coin
      if (chartData.length > 0)  {  //not sure why but there is no data for this coin from the endpoint
        /* remove volume chart for a bit
        var minMaxVolume = chartData.map( (data) => {
          return data.volume
        })

        setVolumeMax(minMaxVolume.reduce((a, b) => { return Math.max(a, b) }))
        setVolumeMin(minMaxVolume.reduce((a, b) => { return Math.min(a, b) }))

        setRechartsVolumeData(chartData.map( (data) => {
          return { 'name': 0, 'value': data.volume, 'amt': data.volume }
        }))
        */

        //get data for price chart
        var minMaxPrices = chartData.map( (data) => {
          return data.price
        })

        setChartMax(minMaxPrices.reduce((a, b) => { return Math.max(a, b) }))
        setChartMin(minMaxPrices.reduce((a, b) => { return Math.min(a, b) }))

        setChartColor('colorValueUp')
        setPriceIcon(<ArrowUpward fontSize="small" sx={{ position: 'relative', top: '6px'}}/>)
        if (chartData[0].price > chartData[chartData.length - 1].price) {
          //price went down over chart
          setChartColor('colorValueDown')
          setStrokeColor(theme.palette.error.dark)
          setPriceIcon(<ArrowDownward fontSize="small" sx={{ position: 'relative', top: '6px'}}/>)
          setPriceColor(theme.palette.error.main)
        }

        setRechartsData(chartData.map( (data) => {
          return { 'name': 0, 'value': data.price, 'amt': data.price }
        }))
      }
    }

    setIsLoading(false)

  }, [currentTimeFrame, chartData])

  const displayLargeNumber = (value) => {
    if (value > 1 ) {
      try {
        return abbreviateNumber(value, 2, {padding: false, symbols: ["", "k", "M", "B", "T", "P", "E"]})
      } catch (e) {
        return value
      }
    } else {
      return value
    }
  }

  //console.log("allCoinsInVIP is: ")
  //console.dir(allCoinsInVIP)
  //console.log("allCoinsInVIP.includes(currencyDetails.coin.id) is: ")
  //console.dir(allCoinsInVIP.includes(currencyDetails.coin.id))

  const closeVIPModal = () => {
    setAddToWatchListModal(false)
  }

  const displayModalVIP = () => {
    setAddToWatchListModal(true)

  }

  /*
  const displayAddToVIP = () => {
    if (allCoinsInVIP.includes(currencyDetails.coin_market.coin.id)) {
      return (
          <Tooltip title="Followed in VIP"><IconButton sx={{ position: 'relative', top: '12px', left: '-8px'}} onClick={()=>displayModalVIP()}><RiVipCrownFill color={theme.palette.error.dark} size={18} /></IconButton></Tooltip>
        )
    } else {
      return (
        <Tooltip title="NOT Followed in VIP"><IconButton sx={{ position: 'relative', top: '12px', left: '-8px'}} onClick={()=>displayModalVIP()}><RiVipCrownFill color={theme.palette.grey[300]} size={18} /></IconButton></Tooltip>
      )
    }
  }
  */

  const displayScreenerChoice = (thisScreener) => {
    //console.log("top of displayScreenerChoice and thisScreener is: " + thisScreener + " and watchListsIncludingThisCoin is:")
    //console.dir(watchListsIncludingThisCoin)
    let found = watchListsIncludingThisCoin.find(screener => screener.screener_name === thisScreener)
    //console.log("found is:")
    //console.dir(found)
    //this coin is within this screener
    if (thisScreener !== 'All Coins') {
      if (found !== undefined) {
        return (
          <Stack key={thisScreener} direction="row" display="flex" alignItems='center' justifyContent="flex-start">
            <Tooltip title={"Remove "+ currencyDetails.coin + " from "+thisScreener}>
              <Checkbox sx={{fontSize: '.9rem', marginLeft: '5px', marginRight: '10px'}} defaultChecked onChange={(e)=>removeOrAddToScreener(e,thisScreener,found)} />
            </Tooltip>
            <Typography variant="body1">{thisScreener}</Typography>
          </Stack>
        )
      } else {
        return (
          <Stack key={thisScreener} direction="row" display="flex" alignItems='center' justifyContent="flex-start">
            <Tooltip title={"Remove "+ currencyDetails.coin + " from "+thisScreener}>
              <Checkbox sx={{fontSize: '.9rem', marginLeft: '5px', marginRight: '10px'}} onChange={(e)=>removeOrAddToScreener(e,thisScreener,found)} />
            </Tooltip>
            <Typography variant="body1">{thisScreener}</Typography>
          </Stack>
        )
      }
    } else {
      //no need to add "all coins" as a list to choose....because any coins is automatically on that list
    }
  }
  if (medScreenOrSmaller) {
    return (
      <Grid container 
        spacing={.5}
        sx={{borderBottomWidth: '.5px', borderColor: theme.palette.grey[100]}}
      >
        <Grid item xs={3}
           component={Link} href={detailLink} underline="none"
        >
          <Stack direction="column"  alignItems="flex-start" justifyContent="flex-start">
            <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" sx={{paddingTop: '12px', }}>
              <Typography sx={{marginRight: '5px'}}><img src={ currencyDetails.coin_market.images !== null ? currencyDetails.image_thumbnail : null } alt={currencyDetails.coin} /></Typography>
              <Typography sx={[typographyStyle, {marginTop: '-4px'}]}>{String(currencyDetails.coin_symbol).toUpperCase()}</Typography>
            </Stack>
            <Typography sx={[typographyStyle, {fontWeight: '400'}]}>{currencyDetails.name}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} component={Link} href={detailLink} underline="none">
          <Box display="flex" justifyContent="center" alignItems='center' sx={{marginTop: '15px', marginRight: '10px'}}>
            <Typography sx={[typographyStyle, {color: priceColor}]}>{formatFloatToCurrency(currencyDetails.current_price)} USD ({parseFloat(currencyDetails.day_1_price_change).toFixed(2)}%{priceIcon})</Typography>
          </Box>
        </Grid>
        <Grid item xs={3} component={Link} href={detailLink} underline="none">
          <Box display="flex" justifyContent="flex-start" alignItems='center' sx={{marginTop: '15px', marginRight: '10px'}}>
            <Typography sx={typographyStyle}>{displayLargeNumber(currencyDetails.total_volume)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={1} component={Link} href={detailLink} underline="none"  sx={{marginTop: '-22px',}}>
          <Typography sx={[typographyStyle]}>{currencyDetails.market_cap_rank}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Tooltip
              title={isFavorite ? "Followed in VIP" : "NOT Followed in VIP"}
          >
            <IconButton sx={{ position: 'relative', top: '7px', }} onClick={()=>displayModalVIP()}>
              <RiVipCrownFill
                  color={isFavorite ? theme.palette.error.dark : theme.palette.primary.dark}
                  size={18}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{width: '100%', background: theme.palette.grey[50]}} ></Divider>
        </Grid>        
      </Grid>
    )
  } else {

    return (
      <Grid container
        maxWidth="false"
        sx={{
          borderBottom: '.25px solid', 
          borderColor: theme.palette.grey[100], 
        }}
        key={currencyDetails.coin}
      >
        <Grid item xs={2}
          sx={{textAlign: 'right'}} component={Link} href={detailLink} underline="none"
        >
          <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" sx={{marginTop: '12px', marginLeft: '15px'}}>
            <Typography sx={{marginRight: '15px'}}><img src={ currencyDetails.image_thumbnail !== null ? currencyDetails.image_thumbnail : null } alt={currencyDetails.coin} /></Typography>
            <Typography noWrap={true} sx={[typographyStyle, {fontWeight: '800'}]}>{currencyDetails.coin}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={.5} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
          <Typography sx={[typographyStyle, {marginTop: '-4px'}]}>{String(currencyDetails.coin_symbol).toUpperCase()}</Typography>
        </Grid>
        {
          visibleColumns['Current Price'] && (
                <Grid item xs={1.75} component={Link} href={detailLink} underline="none">
                  <Box display="flex" justifyContent="center" alignItems='center' sx={{marginTop: '10px', marginRight: '10px'}}>
                    <Typography sx={[typographyStyle, {color: priceColor}]}>{formatFloatToCurrency(currencyDetails.current_price)} USD ({parseFloat(currencyDetails.day_1_price_change).toFixed(2)}%{priceIcon})</Typography>
                  </Box>
                </Grid>
            )
        }
        {
          visibleColumns['24h High'] &&
              <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
                <Typography sx={[typographyStyle]}>{formatFloatToCurrency(currencyDetails.high_24h)}</Typography>
              </Grid>
        }
        {
          visibleColumns['24h Low'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{formatFloatToCurrency(currencyDetails.low_24h)}</Typography>
            </Grid>
        }
        {
          visibleColumns['24h Price Chart'] && (
                <Grid item xs={1} sx={{ zIndex: -1 }} component={Link} href={detailLink} underline="none">
                  <Box display="flex" justifyContent="center" alignItems='center' sx={{marginTop: '0px', marginRight: '10px'}}>
                    { isLoading ? <CircularProgress sx={{zIndex: -1, marginTop: '10px'}} size={25} /> :
                        <AreaChart
                            width={150}
                            height={50}
                            data={rechartsData}
                        >
                          <defs>
                            <linearGradient id="colorValueUp" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor='#76cf97' stopOpacity={0.8}/>
                              <stop offset="95%" stopColor='#76cf97' stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorValueDown" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor='#cf7676' stopOpacity={0.8}/>
                              <stop offset="95%" stopColor='#cf7676' stopOpacity={0}/>
                            </linearGradient>
                          </defs>
                          <XAxis hide={true} />
                          <YAxis hide={true} domain={[chartMin,chartMax]} />
                          <Area type="monotone" dataKey="value"  stroke={strokeColor} fillOpacity={1} fill={'url(#' + chartColor + ')'} />
                        </AreaChart>
                    }
                  </Box>
                </Grid>
            )
        }
        {
          visibleColumns['24h Volume'] && (
                <Grid item xs={1} component={Link} href={detailLink} underline="none">
                  <Box display="flex" justifyContent="center" alignItems='center' sx={{marginTop: '20px', marginRight: '10px'}}>
                    <Typography sx={typographyStyle}>{displayLargeNumber(currencyDetails.total_volume)}</Typography>
                  </Box>
                </Grid>
            )
        }
        {
          visibleColumns['Market Cap'] && (
                <Grid item xs={1} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
                  <Typography sx={[typographyStyle, ]}>${displayLargeNumber(currencyDetails.market_cap)} USD</Typography>
                </Grid>
            )
        }

        {
          visibleColumns['BTC Dif'] && (
                <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
                  <Typography sx={[typographyStyle, {color: percentBTCDiffColor}]} >{negPosBTCPercentage}{!isNaN(parseFloat(currencyDetails.btc_24h_dif)) ? parseFloat(Math.abs(currencyDetails.btc_24h_dif)).toFixed(2) : '--'}</Typography>
                </Grid>
            )
        }

        {
            visibleColumns['ETH Dif'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle, { color: percentETHDiffColor}]} >{negPosETHPercentage}{!isNaN(parseFloat(currencyDetails.eth_24h_dif)) ? parseFloat(Math.abs(currencyDetails.eth_24h_dif)).toFixed(2) : '--'}</Typography>
            </Grid>
        }
        {
            visibleColumns['Market Cap Rank'] &&
            <Grid item xs={.5} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{currencyDetails.market_cap_rank}</Typography>
            </Grid>
        }
        {
            visibleColumns['5 Minute Price Change'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{formatFloatToCurrency(currencyDetails.min_5_price_change)}</Typography>
            </Grid>
        }
        {
            visibleColumns['15 Minute Price Change'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{formatFloatToCurrency(currencyDetails.min_15_price_change)}</Typography>
            </Grid>
        }
        {
            visibleColumns['Social Rank'] &&
              <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
                <Typography sx={[typographyStyle]}>{currencyDetails.social_rank !== null ? currencyDetails.social_rank.toFixed(2) : '-'}</Typography>
              </Grid>
        }
        {
            visibleColumns['Total Social Engagement'] &&
              <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
                <Typography sx={[typographyStyle]}>{currencyDetails.total_social_engagement !== null ? currencyDetails.total_social_engagement : '-'}</Typography>
              </Grid>
        }
        {
            visibleColumns['1 Hour Price Change'] &&
              <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
                <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.hour_1_price_change).toFixed(2)}%</Typography>
              </Grid>
        }
        {
            visibleColumns['4 Hour Price Change'] &&
              <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
                <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.hour_4_price_change).toFixed(2)}%</Typography>
              </Grid>
        }
        {
            visibleColumns['1 Week Price Change'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.day_7_price_change).toFixed(2)}%</Typography>
            </Grid>
        }
        {
            visibleColumns['2 Week Price Change'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.day_14_price_change).toFixed(2)}%</Typography>
            </Grid>
        }
        {
            visibleColumns['Daily Influencer Posts'] &&
              <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
                <Typography sx={[typographyStyle]}>{currencyDetails.daily_influencer_post_count !== null ? currencyDetails.daily_influencer_post_count : '-'}</Typography>
              </Grid>
        }
        {
            visibleColumns['24 Hour Follower Change'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.day_1_follower_change).toFixed(2)}%</Typography>
            </Grid>
        }
        {
            visibleColumns['1 Month Follower Change'] &&
              <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
                <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.month_1_follower_change).toFixed(2)}%</Typography>
              </Grid>
        }
        {/*{*/}
        {/*    visibleColumns['1 Year Follower Change'] &&*/}
        {/*    <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">*/}
        {/*      <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.year_1_follower_change).toFixed(4)}</Typography>*/}
        {/*    </Grid>*/}
        {/*}*/}
        {
            visibleColumns['24 Hour Post Volume Change'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{currencyDetails.day_1_post_volume_change}%</Typography>
            </Grid>
        }
        {
            visibleColumns['1 Week Post Volume Change'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.week_1_post_volume_change).toFixed(2)}%</Typography>
            </Grid>
        }
        {
            visibleColumns['1 Month Post Volume Change'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.month_1_post_volume_change).toFixed(2)}%</Typography>
            </Grid>
        }
        {
            visibleColumns['3 Month Post Volume Change'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.month_3_post_volume_change).toFixed(2)}%</Typography>
            </Grid>
        }
        {/*{*/}
        {/*    visibleColumns['1 Year Post Volume Change'] &&*/}
        {/*    <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">*/}
        {/*      <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.year_1_post_volume_change).toFixed(4)}</Typography>*/}
        {/*    </Grid>*/}
        {/*}*/}
        {
            visibleColumns['1 Month Post Volume Average'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.month_1_post_volume_average).toFixed(4)}</Typography>
            </Grid>
        }
        {
            visibleColumns['3 Month Post Volume Average'] &&
            <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">
              <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.month_3_post_volume_average).toFixed(4)}</Typography>
            </Grid>
        }
        {/*{*/}
        {/*    visibleColumns['1 Year Post Volume Average'] &&*/}
        {/*    <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">*/}
        {/*      <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.year_1_post_volume_average).toFixed(4)}</Typography>*/}
        {/*    </Grid>*/}
        {/*}*/}

        <Grid item xs={1}>
          <Tooltip
              title={isFavorite ? "Followed in VIP" : "NOT Followed in VIP"}
          >
            <IconButton sx={{ position: 'relative', top: '10px', left: '-8px'}} onClick={()=>displayModalVIP()}>
              <RiVipCrownFill
                  color={isFavorite ? theme.palette.error.dark : theme.palette.grey[300]}
                  size={18}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        {/*<Grid item xs={.5}>
          {displayAlerts()}
        </Grid>*/}
        <Modal
          open={addToWatchListModal}
          aria-labelledby='spring-modal-title-vip'
          aria-describedby='spring-modal-description-vip'
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          disableEscapeKeyDown={false}        
        >
          <Fade in={addToWatchListModal}>
            <Box sx={{ position: 'absolute', width: 400, backgroundColor: theme.palette.background.default , border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)'}}>
              <Stack direction="column">
                <Typography variant='h5' sx={{marginBottom: '15px'}}>Choose Which WatchList To Add {currencyDetails.coin}</Typography>
                {userObject.screener_name.map( screenerName => displayScreenerChoice(screenerName))}
                <Button variant="outlined" startIcon={<CancelPresentationIcon />} onClick={()=>closeVIPModal()} >Close</Button>
              </Stack>
            </Box>
          </Fade>
        </Modal>      
      </Grid>
    )
  }
}


export default CurrenciesListItem