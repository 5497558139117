import React from 'react'
import { Typography, Grid, Stack, Box, Link, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { BsBoxArrowLeft } from 'react-icons/bs'

import ResearchBodyCoinItemLeft from './ResearchBodyCoinItemLeft'
import ResearchBodyCoinItemRight from './ResearchBodyCoinItemRight'

const ResearchBodyCoinItem = ({ coinDetails, showCoinTweetsAndChart }) => {
    //console.log("Top of ResearchBodyCoinItem")
    //console.log("coinDetails is: ")
    //console.dir(coinDetails)
    const theme = useTheme()

    const displayPriceChange = ( label, priceChange ) => {

        let priceColor = theme.palette.success.dark
        let priceValueColor = theme.palette.grey[50]
        if (priceChange < 0) {
            priceColor = theme.palette.error.dark
            priceValueColor = theme.palette.grey[500]
        } 

        if (isNaN(priceChange)) priceChange = '~'

        return (
            <Stack direction="row" display="flex" alignItems="center">
                <Typography sx={{fontWeight: 400, color: theme.palette.grey[400],fontSize: '.8rem' }} display="inLine">{label}</Typography>
                <Box sx={{marginLeft: '2px',  borderRadius: '10px', padding: '2px', paddingLeft: '3px', paddingRight: '5px',backgroundColor: priceColor }}>
                    <Typography sx={{fontWeight: 400, color: priceValueColor,fontSize: '.8rem' }} >{priceChange}%</Typography>
                </Box>
            </Stack>   
        )
    }

    const showCoinDetails = ( coinDetails ) => {
        showCoinTweetsAndChart(coinDetails)
    }

    const displayCategory = (category) => {
        return (
            <Box key={"displayCategorycoinitem"+Math.random().toString(36).substring(2,7)} sx={{marginLeft: '3px', borderRadius: '10px', padding: '2px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '3px',backgroundColor: theme.palette.primary.dark }}>
                <Typography noWrap={true} color={theme.palette.dark[900]} >{category}</Typography>
            </Box>
        )
        //return (
        //    <Chip label={category} color="primary" sx={{marginLeft: '5px'}} />
        //) 
    }

    const displayPrice = (price) => {
        if (price.toString().includes('e')) {
          //this has scientific notation
            return price.toFixed(price.toString().split('-')[1]);
        } else {
          return price
        }
    }    

    const borderDetails = '1px solid '+ theme.palette.grey[200]

    if (coinDetails.coins !== null) {
        return (
            <Box 
                sx={{
                    width: '98%', 
                    padding: '8px', 
                    paddingLeft: '10px',
                    marginLeft: '5px',
                    marginRight: '5px', 
                    marginBottom: '3px', 
                    cursor: "pointer"
                }}
                onClick={() => showCoinDetails(coinDetails)}
                key={coinDetails.coinscreener.id+Math.random().toString(36).substring(2,7)}
            >
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Stack direction="row" display="flex" alignItems="center" justifyContent='space-between'>
                            <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" sx={{paddingRight: '15px'}}>
                                <Typography sx={{marginRight: '15px'}}><img src={ coinDetails.coinscreener.image_thumbnail !== null && coinDetails.coinscreener.image_thumbnail !== '' ? coinDetails.coinscreener.image_thumbnail : null } alt={coinDetails.coinscreener.coin} /></Typography>
                                <Typography sx={{fontWeight: '800', position: 'relative', top: '-3px'}}>{coinDetails.coinscreener.coin}</Typography>
                                <Box sx={{marginLeft: '10px', borderRadius: '10px', border: borderDetails , backgroundColor: theme.palette.dark.main, paddingLeft: '10px', padding: '2px', paddingRight: '10px', position: 'relative', top: '-3px',}}>
                                    <Typography noWrap={true} sx={{fontWeight: '500', marginLeft: '10px', color: theme.palette.grey[500]}}>
                                        ${coinDetails.coinscreener.current_price}
                                    </Typography>
                                </Box>
                            </Stack> 
                            <Box sx={{marginLeft: '10px', borderRadius: '10px', border: borderDetails , backgroundColor: theme.palette.dark.main, paddingLeft: '10px', padding: '2px', paddingRight: '15px', position: 'relative', top: '-3px',}}>
                                <Link href={"/coindetail/"+coinDetails.coinscreener.id} target="_blank" rel="noreferrer" >
                                <Typography noWrap={true} sx={{fontWeight: '500', marginLeft: '10px', color: theme.palette.grey[600]}}>details</Typography>
                                </Link>
                            </Box>
                            <Stack direction="row" display="flex" alignItems="center" justifyContent='space-between' sx={{position: 'relative', top: '-3px', marginLeft: 2}}>
                                <BsBoxArrowLeft size={12}  />
                                <Typography sx={{fontWeight: '500', color: theme.palette.grey[400], marginLeft: '5px', cursor: 'pointer'}}>view {coinDetails.coinscreener.coin_symbol} chart/tweets</Typography>
                            </Stack>   
                            {/*<Typography sx={{fontWeight: '500', position: 'relative', top: '-3px', color: theme.palette.grey[400]}}>{coinDetails.coinscreener.coin}</Typography>   */}
                        </Stack>
                    </Grid>     
                    <Grid item xs={6}>
                        <ResearchBodyCoinItemLeft coinDetails={coinDetails} />
                    </Grid>    
                    <Grid item xs={6}>
                        <ResearchBodyCoinItemRight coinDetails={coinDetails} />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" display="flex" alignItems="center">
                            {displayPriceChange('1d', parseFloat(coinDetails.coinscreener.day_1_price_change).toFixed(2))}
                            {displayPriceChange('7d', parseFloat(coinDetails.coinscreener.day_7_price_change).toFixed(2))}
                            {displayPriceChange('14d', parseFloat(coinDetails.coinscreener.day_14_price_change).toFixed(2))}
                            {displayPriceChange('1m', parseFloat(coinDetails.coinscreener.month_1_price_change).toFixed(2))}
                            {displayPriceChange('200d', parseFloat(coinDetails.coinscreener.day_200_price_change).toFixed(2))}
                            {displayPriceChange('1y', parseFloat(coinDetails.coinscreener.year_1_price_change).toFixed(2))}
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" display="flex" alignItems="center" justifyContent='flex-start' sx={{marginTop: '5px', flexWrap: 'wrap'}}>
                            <Typography sx={{marginBottom: '3px', fontWeight: 400, color: theme.palette.grey[400],}}>category(s): </Typography>
                            { coinDetails.coinscreener.hasOwnProperty("category") && coinDetails.coinscreener.category !== null && coinDetails.coinscreener.category !== '' && coinDetails.coinscreener.category.map( category => { 
                                return displayCategory(category)
                            })}
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        )
    } else if (coinDetails.twitter_display_name !== null) {
        return <ResearchBodyCoinItemRight coinDetails={coinDetails} />

    } else {
        return <Typography>There is no coin or twitter data for that coin available</Typography>
    }
}

export default ResearchBodyCoinItem