import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import TokenWizardHelperText from "./TokenWizardHelperText";
import React from "react";
import { useTheme } from '@mui/material/styles'

const StakingRewards = ({pageData, setPageData}) => {
    const theme = useTheme()

    const handleHasStakingRewardsChange = (e) => {
        setPageData({
            ...pageData,
            hasStakingRewards: e.target.value
        });
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant="h2" sx={{color: theme.palette.primary.main, marginTop: '20px'}}>Staking Rewards</Typography>
            <Stack direction="row" display='flex' alignItems='center'>
                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500] }}>
                    Does the project offer staking rewards of additional project tokens?
                </Typography>
                <TokenWizardHelperText
                    title={'Staking Rewards'}
                    text={'Some projects offer rewards when the token is staked.\nNote: The Details page of the project will show this info once uploaded.'} />
            </Stack>
            <FormControl sx={{mt: 1}}>
                <RadioGroup
                    column={true}
                    aria-labelledby="tradepost-radio-buttons-group"
                    name="tradepost-radio-buttons-group"
                    value={pageData.hasStakingRewards}
                    onChange={handleHasStakingRewardsChange}
                >
                    <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                    <FormControlLabel value={"No"} control={<Radio />} label="No" />
                    <FormControlLabel value={"Unknown"} control={<Radio />} label="Unknown" />
                </RadioGroup>
            </FormControl>
        </Box>
    )
}

export default StakingRewards;