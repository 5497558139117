import React, {useState} from 'react'
import { Grid, Stack, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles'
import { ExpandMore } from '@mui/icons-material'

import ResearchBodyTweetResults from '../Research3/ResearchBodyTweetResults'
import fetchData from '../../utility/endpointFetch'

const VIPMainTabsFeedHotSearchesItem = ( {searchFeedItem} ) => {
    //console.log("top of VIPMainTabsFeedHotSearchesItem and searchFeedItem is:") 
    //console.dir(searchFeedItem)

    const [coinFilterResults,setCoinFilterResults] = useState([])
    const [coinFilterNextResults,setCoinFilterNextResults] = useState([])
    const [isLoading,setIsLoading] = useState(true)
    //const [dateSearch,setDateSearch] = useState(false)

    //const [selectedCategories,setSelectedCategories] = useState([])

    //format time
    var trimmedTime = searchFeedItem.created.split(".")[0]
    var splitTime = trimmedTime.split("T")

    const theme = useTheme()

    const themeAccordion = createTheme({
      components: {
        // Name of the component
        MuiButtonBase: {
          styleOverrides: {
            // Name of the slot
            root: {
              // Some CSS
              minHeight: '0px'
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              minHeight: '0px'
            }
          }
        }
      },
    })

    //searchFeedItem.saved_research_query

    //will get these from an endpoint later
    const startingValues = {
        min_market_cap: 0,
        max_market_cap: 417411407443,
        min_followers: 1000,
        max_followers: 5500000,
        vs_btc_min: -1000,
        vs_btc_max: 1000,
        vs_eth_min: -1000,
        vs_eth_max: 1000,
    }
    let queryStringNameValues = searchFeedItem.saved_research_query.split('&')
    
    let queryString = {}
    queryStringNameValues.map( pair => {
        let splitPair = pair.split('=')
        queryString[splitPair[0]] = splitPair[1]
    })
    //console.log("queryString before setting defaults is")
    //console.dir(queryString)

    const queryObjectProperties = {
        marketcap__lte: 'max_market_cap',
        marketcap__gte: 'min_market_cap',
        followers__gte: 'min_followers',
        followers__lte: 'max_followers',
        btc_24h_dif__gte: 'vs_btc_min',
        btc_24h_dif__lte: 'vs_btc_max',
        eth_24h_dif__gte: 'vs_eth_min',
        eth_24h_dif__lte: 'vs_eth_max',
        search: ''
    }

    Object.keys(queryObjectProperties).map( property => {
        if (!queryString.hasOwnProperty(property)) {
            //add this as a property with a default value
            //console.log("did not find " +  property + " inside of queryString: ")
            //console.dir(queryString)
            queryString[property] = startingValues[queryObjectProperties[property]]
        } else {
            //console.log("found property: " + property + " and it's value is: " + queryString[property])
        }
    })

    //console.log("queryString before categories defaults:")
    //console.dir(queryString)   
    
    //var selectedCategories = []

    if (!queryString.hasOwnProperty('categories__name__in')) {
        //console.log("categories__name__in was NOT found in queryString" )
        //console.dir(queryString)
        queryString['categories__name__in'] = []
    } else {
        //console.log("categories__name__in was found and is:")
        //console.dir(queryString['categories__name__in'])
        //let strippedCategoryNameIn = queryString['categories__name__in']
        if ((queryString['categories__name__in'].startsWith('[')) && (queryString['categories__name__in'].endsWith(']'))) {
            //console.log(" brackets found at the front and back....removing them")
            //strippedCategoryNameIn = queryString['categories__name__in'].slice(1, -1)
        } 
        //console.log("strippedCategoryNameIn is: ")
        //console.dir(strippedCategoryNameIn)
        //console.log("after removing the first [ and last ]")
        //console.log("and is being split to: ")
        //console.dir(strippedCategoryNameIn.split(','))

        //selectedCategories = strippedCategoryNameIn.split(',')
    }

    //console.log("queryString after adding defaults:")
    //console.dir(queryString)

    var displayQuery = {}
    var customizationCount = 0

    displayQuery['searchTerm'] = 'no text query term'
    if ((queryString.search !== undefined) && (queryString.search.length > 0)) {
        displayQuery['searchTerm'] = queryString.search
        customizationCount += 1
    }

    displayQuery['marketCapMin'] = "no minimum"
    if (startingValues.min_market_cap !== queryString.marketcap__gte) {
        displayQuery['marketCapMin'] = "greater than $" + queryString.marketcap__gte.toLocaleString()
        customizationCount += 1
    }
    displayQuery['marketCapMax'] = "no maximum"
    if (startingValues.max_market_cap !== queryString.marketcap__lte) {
        displayQuery['marketCapMax'] = "less than $" + queryString.marketcap__lte.toLocaleString()
        customizationCount += 1
    }


    displayQuery['followerMin'] = "no minimum"
    if (startingValues.min_followers !== queryString.followers__gte) {
        displayQuery['followerMin'] = "greater than " + queryString.followers__gte.toLocaleString()
        customizationCount += 1
    }

    displayQuery['followerMax'] = "no maximum"
    if (startingValues.max_followers !== queryString.followers__lte) {
        displayQuery['followerMax'] = "less than " + queryString.followers__lte.toLocaleString()
        customizationCount += 1
    }


    displayQuery['vsBTCMin'] = "no minimum"
    if (startingValues.vs_btc_min !== queryString.btc_24h_dif__gte) {
        displayQuery['vsBTCMin'] = "greater than " + queryString.btc_24h_dif__gte
        customizationCount += 1
    }
    displayQuery['vsBTCMax'] = "no maximum"
    if (startingValues.vs_btc_max !== queryString.btc_24h_dif__lte) {
        displayQuery['vsBTCMax'] = "less than " + queryString.btc_24h_dif__lte
        customizationCount += 1
    }    

    displayQuery['vsETHMin'] = "no minimum"
    if (startingValues.vs_btc_min !== queryString.eth_24h_dif__gte) {
        displayQuery['vsETHMin'] = "greater than " + queryString.eth_24h_dif__gte
        customizationCount += 1
    }
    displayQuery['vsETHMax'] = "no maximum"
    if (startingValues.vs_btc_max !== queryString.eth_24h_dif__lte) {
        displayQuery['vsETHMax'] = "less than " + queryString.eth_24h_dif__lte
        customizationCount += 1
    }        

    //displayQuery['categories'] = 'all categories'
    //if (queryString.categories.length !== 0) {
    //    queryString.categories.join(', ')
    //    customizationCount += 1
    //}

    displayQuery['categories__name__in'] = 'all categories'
    //console.log("queryString.categories__name__in is:")
    //console.dir(queryString.categories__name__in)
    if (queryString.categories__name__in.length !== 0) {
        if (Array.isArray(queryString.categories__name__in)) {
            //came as an array...display as a string
            //console.log("display['category_name_in'] is an array...join")
            displayQuery['categories__name__in'] = queryString.categories__name__in.join(', ')
        } else {
            //came as a string....leave as a string
            //console.log("display['category_name_in'] is a string")
            if ((queryString.categories__name__in.startsWith('[')) && (queryString.categories__name__in.endsWith(']'))) {
                //no need to strip...just a single category
                //console.log("display['category_name_in'] starts and ends with brackets...strip them")
                displayQuery['categories__name__in'] = queryString.categories__name__in.slice(1, -1).replace(',', ', ')
                
            } else {
                //category array in string mode...docter it
                //console.log("display['category_name_in'] does not begin/end in brackets...leave it")
                displayQuery['categories__name__in'] = queryString.categories__name__in
            }
        }
        customizationCount += 1
    }        

    //messy....but it will work...come back to only do this once

    var dateSearch = false
    let queryStringArray = searchFeedItem.saved_research_query.split('&')

    //console.log("CHECKING IF DATE IS IN search")
    queryStringArray.map(queryNameValuePair => {
        let currentPair = queryNameValuePair.split('=')
        if (currentPair[0] === 'search' && currentPair[1].includes('DATE')) {
            dateSearch = true
            //remove DATE and add currentMonth and nextMonth
        }
    })    
    
    var currentDate = new Date()
    var currentMonth = currentDate.toLocaleString('default', { month: 'long' })
    //console.log("currentMonth is:" + currentMonth)  
    currentDate.setMonth(currentDate.getMonth()+1)
    var nextMonth = currentDate.toLocaleString('default', { month: 'long' })
    //console.log("nextMonth is: " + nextMonth) 
    var resultsHeaderThisMonth = ''
    var resultsHeaderNextMonth = ''
    if (dateSearch === true) {
        resultsHeaderThisMonth = currentMonth + " results: "
        resultsHeaderNextMonth = nextMonth + " results: "
    }

    const setHotSearch = () => {
        //console.log(".........top of setHotSearch in VIPMainTabsFeedHotSearchesItem and searchFeedItem.saved_research_query is")
        //console.dir(searchFeedItem.saved_research_query)
        setIsLoading(true)

        let queryStringArray = searchFeedItem.saved_research_query.split('&')
        var checkedQueryStringNowArray = []
        var checkedQueryStringNextArray = []

        //console.log("CHECKING IF DATE IS IN search")
        queryStringArray.map(queryNameValuePair => {
            //console.log("queryNameValuePair is: "+ queryNameValuePair)
            let currentPair = queryNameValuePair.split('=')
            //console.log("currentPair[0] is: "+ currentPair[0] + " and currentPair[1] is: "+ currentPair[1])
            if (currentPair[0] === 'search' && currentPair[1].includes('DATE')) {
                //this is the search string pair and string contains DATE....do a second search
                //remove DATE and add currentMonth and nextMonth
                let searchWithCurrent = currentPair[1].replace('DATE', currentMonth)
                let searchWithNext = currentPair[1].replace('DATE', nextMonth)
                checkedQueryStringNowArray.push(currentPair[0]+'='+searchWithCurrent)
                checkedQueryStringNextArray.push(currentPair[0]+'='+searchWithNext)
            } else {
                checkedQueryStringNowArray.push(queryNameValuePair)
                checkedQueryStringNextArray.push(queryNameValuePair)
            }
        })
        var checkedQueryStringNow = checkedQueryStringNowArray.join('&')
        var checkedQueryStringNext = checkedQueryStringNextArray.join('&')

        //console.log("checkedQueryStringNow is: ")
        //console.dir(checkedQueryStringNow)
        //console.log("checkedQueryStringNext is: ")
        //console.dir(checkedQueryStringNext)

        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }

        ;(async () => {
            try {
                //var coinFilterResponseURL = `api/v1/vip/researchsignal/?${checkedQueryStringNow}`
                var coinFilterResponseURL = `api/v1/feed/alltweets/?${checkedQueryStringNow}&is_influencer=false`
                const coinFilterResponse = await fetchData(coinFilterResponseURL, "could not receive researcher post search results within VIP alerts", fetchParams).promise
                console.log("++++++++++++coinFilterResponse getting researcher post search results within VIP alerts")
                //console.log("coinFilterResponseURL is: " + coinFilterResponseURL)
                //console.dir(coinFilterResponse)

                if (dateSearch) {
                    ;(async () => {
                        try {
                            var offset= 0 //limit per page
                            //var coinTweetResponseURLNext = `api/v1/vip/researchsignal/?${checkedQueryStringNext}&limit=100&offset=${offset}`
                            //var coinTweetResponseURLNext = `api/v1/vip/researchsignal/?${checkedQueryStringNext}`
                            var coinTweetResponseURLNext = `api/v1/feed/alltweets/?${checkedQueryStringNext}&is_influencer=false`
                            const coinTweetResponseNext = await fetchData(coinTweetResponseURLNext, "could not receive researcher post search results next", fetchParams).promise
                            //console.log("000000000000000000000000000000000000000000000000000000000000000000000000000 coinTweetResponseNext getting researcher post search results FOR NEXT MONTH")
                            //console.log("coinTweetResponseURLNext is: " + coinTweetResponseURLNext)
                            //console.dir(coinTweetResponseNext)
                            setCoinFilterNextResults(coinTweetResponseNext.results)


                        } catch (error) {
                            console.log("could not receive researcher post search results next: ", error)
                        }
                    })()

                    //} else {

                }

                setCoinFilterResults(coinFilterResponse.results)
                setIsLoading(false)
            } catch (error) {
                console.log("could not receive researcher post search results within VIP alerts: ", error)
            }
        })()
    }

    return (
        <Grid item xs={12} sx={{padding: "2px", }}>
            <Box sx={{borderRadius: '5px',  padding: "2px", margin: '0px', bgcolor: theme.palette.background.default }}>
                <ThemeProvider theme={themeAccordion}>
                    <Accordion
                        onClick={setHotSearch}
                        sx={{
                            backgroundColor: theme.palette.dark[800],
                            marginTop: '3px',
                            borderRadius: '8px'
                        }}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore sx={{color: theme.palette.secondary.main}}/>}
                            aria-controls={"notificationPanel"+searchFeedItem.user.id+searchFeedItem.created}
                            id={"notificationPanel"+searchFeedItem.user.id+searchFeedItem.created}
                            sx={{
                                borderRadius: '8px',
                                '& .MuiAccordionSummary-root': {
                                minHeight: '0px'
                                },
                                '& .MuiAccordionSummary-content': {
                                margin: '0px 0px'
                                }
                            }}
                        >
                            <Grid container xs={12} direction="row" display="flex" alignItems="center" justifyContent="space-between" flex={1}>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'center',  fontWeight: 600, fontSize: 15, color: theme.palette.primary.main,}}>{searchFeedItem.name}</Typography>
                                </Grid>
                                <Grid item xs={4} direction="row" display="flex" alignItems="center" >
                                    <Typography sx={{textAlign: 'center', color: theme.palette.grey[300]}}>user:</Typography>
                                    <Typography sx={{textAlign: 'center', fontWeight: 500, fontSize: 15, color: theme.palette.primary.main, marginLeft: '5px'}}>{searchFeedItem.user}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign: 'center',  fontWeight: 500, fontSize: 15, color: theme.palette.primary.main,}}>{splitTime[0]} {splitTime[1]}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails sx={{backgroundColor: theme.palette.dark.dark, padding: '10px', borderRadius: '10px'}}>
                            
                            <Typography sx={{marginTop: '10px', color: theme.palette.grey[300] }} variant="h5" >Current Search</Typography> 
                            <Grid container sx={{marginBottom: '10px'}}>
                                <Grid item xs={6}>
                                    <Stack direction="column" display="flex" justifyContent="flex-start">
                                        <Typography sx={{paddingTop: '10px', color: theme.palette.grey[300]}}>Search Term</Typography>
                                        <Typography sx={{marginLeft: '5px', color: theme.palette.primary.main}}>{displayQuery['searchTerm']}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction="column" display="flex" justifyContent="flex-start">
                                        <Typography sx={{paddingTop: '10px', color: theme.palette.grey[300]}}>within Category(s)</Typography>
                                        <Typography sx={{marginLeft: '5px', color: theme.palette.primary.main}} >{displayQuery['categories__name__in']}</Typography>
                                    </Stack>                        
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{width: '100%'}}>
                                        <Stack direction="column" display="flex" justifyContent="flex-start">
                                            <Typography sx={{paddingTop: '10px', color: theme.palette.grey[300]}}>Market Cap</Typography>
                                            <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" sx={{marginLeft: '20px', color: theme.palette.primary.main}}>
                                                <Typography>{displayQuery['marketCapMin']}</Typography>
                                                <Typography sx={{marginLeft: '5px'}}>and</Typography>
                                                <Typography sx={{marginLeft: '5px'}}>{displayQuery['marketCapMax']}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>                        
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{width: '100%'}}>
                                        <Stack direction="column" display="flex" justifyContent="flex-start">
                                            <Typography sx={{paddingTop: '10px', color: theme.palette.grey[300]}}>Followers</Typography>
                                            <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" sx={{marginLeft: '20px', color: theme.palette.primary.main}}>
                                                <Typography>{displayQuery['followerMin']}</Typography>
                                                <Typography sx={{marginLeft: '5px'}}>and</Typography>
                                                <Typography sx={{marginLeft: '5px'}}>{displayQuery['followerMax']}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>                         
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{width: '100%'}}>
                                        <Stack direction="column" display="flex" justifyContent="flex-start">
                                            <Typography sx={{paddingTop: '10px', color: theme.palette.grey[300]}}>vs BTC</Typography>
                                            <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" sx={{marginLeft: '20px', color: theme.palette.primary.main}}>
                                                <Typography>{displayQuery['vsBTCMin']}</Typography>
                                                <Typography sx={{marginLeft: '5px'}}>and</Typography>
                                                <Typography sx={{marginLeft: '5px'}}>{displayQuery['vsBTCMax']}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>                          
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{width: '100%'}}>
                                        <Stack direction="column" display="flex" justifyContent="flex-start">
                                            <Typography sx={{paddingTop: '10px', color: theme.palette.grey[300]}}>vs ETH</Typography>
                                            <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" sx={{marginLeft: '20px', color: theme.palette.primary.main}}>
                                                <Typography>{displayQuery['vsETHMin']}</Typography>
                                                <Typography sx={{marginLeft: '5px'}}>and</Typography>
                                                <Typography sx={{marginLeft: '5px'}}>{displayQuery['vsETHMax']}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>                         
                                </Grid>
                            </Grid>           
                    
                            <ResearchBodyTweetResults 
                                resultsHeader={resultsHeaderThisMonth}
                                coinFilterResults={coinFilterResults}
                                coinFilterLoading={isLoading}
                            />
                            <ResearchBodyTweetResults 
                                resultsHeader={resultsHeaderNextMonth}
                                coinFilterResults={coinFilterNextResults}
                                coinFilterLoading={isLoading}
                            /> 
                        
                        </AccordionDetails>
                    </Accordion>
                </ThemeProvider>
            </Box>
        </Grid>
    )
}

export default VIPMainTabsFeedHotSearchesItem