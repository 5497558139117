import React, { useState } from 'react'
import { Box, Stack, Typography, Divider, Tab } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'

import { useTheme } from '@mui/material/styles'

import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'

import VIPTweetsResults from './VIPTweetsResults'
import VIPTweetsCoin from './VIPTweetsCoin'
import VIPTweetsAccountsTweets from './VIPTweetsAccountsTweets'
import VIPTweetsChart from './VIPTweetsChart'
import VIPTweetsSearch from './VIPTweetsSearch'

const VIPTweetsDisplay = ( { panelID, deleteBox, coinToDependents, boxHeight, rowHeight, boxWidth, selectedTab, changeTabInLayout} ) => {

    //console.log("......top of VIPTweetsDisplay and selectedTab is: " + selectedTab)
    //console.dir(selectedTab)

    const theme = useTheme()

    const [twitterAccount,setTwitterAccount] = useState('')
    const [twitterCoin,setTwitterCoin] = useState('')
    const [tweetsSearch,setTweetsSearch] = useState('')
    const [tweetsSearchType,setTweetsSearchType] = useState(0)

    const [vipTweetTabValue,setVIPTweetTabValue] = useState(selectedTab)

    const handleTabChange = (event, newTabValue) => {
        //console.log("top of handleTabChange and newTabValue is: " )
        //console.dir(newTabValue)
        setVIPTweetTabValue(newTabValue)
        changeTabInLayout(newTabValue,panelID)
    }    

    const changeTab = (newTabValue) => {
        console.log("top of changeTab in VIPTweetsDisplay and newTabValue is: " + newTabValue)
        setVIPTweetTabValue(newTabValue)
        changeTabInLayout(newTabValue,panelID)
    }

    const changeVIPTweetsAccountsTweetsTab = (twitterAccount, coinChart) => {
        //console.log("top of changeVIPTweetsAccountsTweetsTab")
        //console.log("coinChart is: " + coinChart)
        //console.log("twitterAccount is: " + twitterAccount)
        coinToDependents(coinChart)
        setTwitterAccount(twitterAccount)
        changeTab('3')
    }

    const changeVIPTweetsSearchTab = ( searchText,searchType ) => {
        //get the first character to determine the type
        //ie #this or @this or this
        setTweetsSearchType(searchType)
        setTweetsSearch(searchText)
        changeTab('5')
    }

    const removeVIPTweetBox = () => {
        //console.log("top of removeVIPTweetBox and panelID is: " + panelID)
        deleteBox(panelID)
    }
    
    const changeVIPTweetsCoinTab = (coin) => {
        setTwitterCoin(coin)
        changeTab('2')
    }

    const changeVIPTweetsChartTab = (coin) => {
        setTwitterCoin(coin)
        changeTab('4')
    }

    const changeTwitterCoin = (coin) => {
        setTwitterCoin(coin)
    }


    //make 3 - 4 tabs for this:
    //1) vip twitter with screener selection -- only pulls from screener chosen
    //2) official twitter account details -- include "show tweets" that switches to official tweets tab
    //3) official twitter account tweets
    //4) chart for that twitter accounts coin -- 
    //5) possibility of hashtag/mentioned/followed results
    //change name for tabs based on coin chosen

    //show tabs here
    return (
        <Box sx={{ display: 'flex', width: 'calc(100% - 10px)', marginLeft: '5px' }}>
            <Stack direction="column" display="flex" alignItems='left' sx={{width: '100%'}}>
                <Stack direction="row" display="flex" alignItems='center' justifyContent='space-between' sx={{marginTop: '5px', marginRight: '5px'}}>
                    <Stack direction="row" display="flex" alignItems='center' justifyContent='flex-start' >
                        <Typography display="inline" sx={{fontWeight: 400}} color={theme.palette.grey[300]}>VIP Twitter</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent='flex-end'>  
                    <VscMove 
                        className='draggable-handle' 
                        color={theme.palette.warning.dark} 
                        size={18} 
                        style={{cursor: 'grabbing'}}
                    />
                    <MdCancel 
                        className='draggable-cancel' 
                        color={theme.palette.secondary.dark} 
                        size={18} 
                        onClick={removeVIPTweetBox}
                        style={{cursor: 'pointer'}}
                    />
                    </Stack>
                </Stack>   
                <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>         
                <Box sx={{width: '100%'}}>
                    <TabContext value={vipTweetTabValue} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList 
                                onChange={handleTabChange} 
                                aria-label="" 
                                indicatorColor="secondary" 
                                textColor="primary" 
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="vip tweets" value="1" sx={{minWidth: '15px'}} />
                                <Tab label="coin" value="2" sx={{minWidth: '15px'}} />
                                <Tab label="account tweets" value="3" sx={{minWidth: '15px'}} />
                                <Tab label="chart" value="4" sx={{minWidth: '15px'}} />
                                <Tab label="@#search" value="5" sx={{minWidth: '15px'}} />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{paddingLeft: '0px', marginLeft: '0px',  overflowY: 'auto', scrollbarWidth: 'thin'}}>
                                <VIPTweetsResults 
                                    changeVIPTweetsAccountsTweetsTab={changeVIPTweetsAccountsTweetsTab}
                                    changeVIPTweetsSearchTab={changeVIPTweetsSearchTab}
                                    changeVIPTweetsChartTab={changeVIPTweetsChartTab}
                                    changeVIPTweetsCoinTab={changeVIPTweetsCoinTab}
                                    changeTwitterCoin={changeTwitterCoin}
                                    maxBoxHeight={(rowHeight * boxHeight) -115}
                                    divHeight={boxHeight * rowHeight}
                                    boxWidth={boxWidth}
                                />
                            </Box>
                        </TabPanel>  
                        <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{paddingLeft: '0px', marginLeft: '0px', overflowY: 'auto', scrollbarWidth: 'thin'}}>
                                <VIPTweetsCoin 
                                    coin={twitterCoin} //coin stats to show
                                    twitterAccount={twitterAccount}
                                    maxBoxHeight={(rowHeight * boxHeight) -80}
                                />
                            </Box>
                        </TabPanel>    
                        <TabPanel value="3" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflowY: 'auto', scrollbarWidth: 'thin'}}>
                                <VIPTweetsAccountsTweets 
                                    twitterAccount={twitterAccount} //twitter account to display
                                    changeVIPTweetsAccountsTweetsTab={changeVIPTweetsAccountsTweetsTab}
                                    changeVIPTweetsSearchTab={changeVIPTweetsSearchTab}
                                    changeVIPTweetsChartTab={changeVIPTweetsChartTab}
                                    changeVIPTweetsCoinTab={changeVIPTweetsCoinTab}   
                                    maxBoxHeight={(rowHeight * boxHeight) -105}     
                                    divHeight={boxHeight * rowHeight}
                                    boxWidth={boxWidth}                                                                
                                />
                            </Box>
                        </TabPanel>      
                        <TabPanel value="4" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{paddingLeft: '0px', marginLeft: '0px', overflowY: 'auto', scrollbarWidth: 'thin'}}>
                                <VIPTweetsChart     
                                    coin={twitterCoin} //coin chart to show
                                    panelID={panelID}     
                                    boxHeight={boxHeight}
                                    rowHeight={rowHeight}
                                />
                            </Box>
                        </TabPanel>                     
                        <TabPanel value="5" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{paddingLeft: '0px', marginLeft: '0px',  overflowY: 'auto', scrollbarWidth: 'thin'}}>
                                <VIPTweetsSearch 
                                    tweetsSearch={tweetsSearch} //search term to search
                                    tweetSearchType={tweetsSearchType}
                                    changeVIPTweetsAccountsTweetsTab={changeVIPTweetsAccountsTweetsTab}
                                    changeVIPTweetsSearchTab={changeVIPTweetsSearchTab}
                                    changeVIPTweetsChartTab={changeVIPTweetsChartTab}
                                    changeVIPTweetsCoinTab={changeVIPTweetsCoinTab}
                                    maxBoxHeight={(rowHeight * boxHeight) -85}  
                                    divHeight={boxHeight * rowHeight}
                                    boxWidth={boxWidth}                                  
                                />
                            </Box>
                        </TabPanel>   
                    </TabContext>
                </Box>
            </Stack>
        </Box>
    )
}

export default VIPTweetsDisplay