import React from 'react'
import { Typography, Stack, Box, Grid, Tooltip, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import VIPTwitterPost from './VIPTwitterPost'
import { BsFillInfoCircleFill } from 'react-icons/bs'

const VIPMainTabsFeedKeywordAlertsItem = ( { keywordAlert, setCoinInChart, changeAtFollowedTab, changeHashTab, changeAtMentionedTab, changeCoinInTab, changeDateInChart, showOfficialTweets, divHeight=0, boxWidth=0 } ) => {

    console.log("top of VIPMainTabsFeedKeywordAlertsItem and keywordAlert is:") 
    console.dir(keywordAlert)
    const theme = useTheme()

    var trimmedTime = keywordAlert.created.split("+")[0]
    var splitTime = trimmedTime.split("T")    

    var noHeader = false

    if (keywordAlert && keywordAlert.hasOwnProperty('twitter_display_name')) {
        return (
            <Grid item xs={12} sx={{padding: "2px", }}>
                <Box sx={{borderRadius: '5px',  padding: "2px", margin: '0px', bgcolor: theme.palette.background.default }}>
                    <VIPTwitterPost
                        key={'keywordAlertPanel'+keywordAlert.tweet_id}
                        listing={keywordAlert}
                        setCoinInChart={setCoinInChart}
                        changeAtFollowedTab={changeAtFollowedTab}
                        changeHashTab={changeHashTab}
                        changeAtMentionedTab={changeAtMentionedTab}
                        changeCoinInTab={changeCoinInTab}
                        changeDateInChart={changeDateInChart}
                        showOfficialTweets={showOfficialTweets}
                        noHeader={noHeader}
                        divHeight={divHeight}
                        boxWidth={boxWidth}                        
                    />
                    <Grid container xs={12} spacing={1} justifyContent={'center'} alignItems={'center'}>
                        {!noHeader ? <Grid item xs={1}></Grid> : null}

                        <Grid item xs={!noHeader ? 11 : 12} >
                            <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-end">
                                <Tooltip title="search term that triggered this alert and time alert was triggered">
                                    <IconButton>
                                        <BsFillInfoCircleFill 
                                            color={theme.palette.primary.main}
                                            size={14}
                                            
                                        />
                                    </IconButton>
                                </Tooltip>
                                {/*<Typography sx={{width: '100%',color: theme.palette.grey[500], textAlign: 'center'}}>search term: </Typography>*/}
                                <Typography sx={{fontWeight: 600, color: theme.palette.primary.main, marginLeft: '5px'}}> {keywordAlert.keywords}</Typography>
                                <Typography noWrap='true' variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], marginLeft: '8px' }}>{splitTime[0]} {splitTime[1].slice(0,-1)} UTC</Typography>
                            </Stack>
                        </Grid>
                    </Grid>                    
                </Box>
            </Grid>                      
        )
    } else {
        return null
    }
}

export default VIPMainTabsFeedKeywordAlertsItem