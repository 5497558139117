import React, { useEffect, useState } from 'react'
import { Box, Typography, Stack, Pagination,  } from '@mui/material'

import { useTheme } from '@mui/material/styles'

import HomeFeedManager from '../HomeFeed/HomeFeedManager'
import HomeFeedTwitterPost from '../HomeFeed/HomeFeedTwitterPost'


const FeedHomeFeed = ( {userObject, homeFeedList, homeFeedCount, refreshHomeFeed, homeFeedPageCount, homeFeedPage, changeHomeFeedPage, filterByCoinInFeed, filterByHashInFeed, filterByFollowedInFeed, filterByMentionInFeed, divHeight, boxWidth} ) => {

    console.log("top of FeedHomeFeed and homeFeedList is:")
    console.dir(homeFeedList)
    const theme = useTheme()

    return (
        <Box sx={{width: '100%'}} >
            <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <HomeFeedManager userObject={userObject} refreshHomeFeed={refreshHomeFeed} />    
                { homeFeedCount === 0 ? 
                    <Typography variant="h4" sx={{marginTop: '15px'}}>You do not have any feed items to view.  Either you do not have any twitter users within your list to follow or the twitter users you do follow do not have any current tweets within our system.   Are you following all you wish to follow? <br /><br />Click the "managed followed" button to manage the twitter users tweets that will appear in this list</Typography>
                    : 
                    homeFeedList.map( (listing, index) => 
                        <HomeFeedTwitterPost 
                            key={'homefeed'+index} 
                            twitterItem={listing}
                            filterByCoinInFeed={filterByCoinInFeed} 
                            filterByHashInFeed={filterByHashInFeed} 
                            filterByFollowedInFeed={filterByFollowedInFeed} 
                            filterByMentionInFeed={filterByMentionInFeed} 
                            divHeight={divHeight}
                            boxWidth={boxWidth}
                        />
                    )                  
                }      
                {homeFeedCount > 0 ? 
                    <Box sx={{width: '100%'}}>
                        <Stack direction="row" display="flex" alignItems="center">
                            <Typography variant="button" sx={{marginRight: '20px'}}>Results: {homeFeedCount} </Typography>
                            <Pagination shape="rounded" count={homeFeedPageCount} page={homeFeedPage} onChange={changeHomeFeedPage}/> 
                        </Stack>
                    </Box>
                    : null }  
            </Stack>  
        </Box>           
    )
}

export default FeedHomeFeed