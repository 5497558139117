import React from 'react'
import { Typography, Stack, Avatar, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const HealthDisplayMVRV = ( { ruleThemAll } ) => {

    const theme = useTheme()

    var useThisMVRVData = ruleThemAll[0]

    if (ruleThemAll.length === 0) {
        return (
            <Box sx={{width: '100%'}} >
                <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[500]}}>MVRV</Typography>
                <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
            </Box>
        )
    } else {  

        //console.log("useThisMVRVData is:")
        //console.dir(useThisMVRVData)

        var mvrvRateColor = theme.palette.grey[500]
        var mvrvDesc = 'NEUTRAL'
        if (useThisMVRVData.healthData.mvrv < 1) {
            mvrvRateColor = theme.palette.success.dark
            mvrvDesc = 'POSSIBLE BOTTOM'
        } else if (useThisMVRVData.healthData.mvrv > 3.7) {
            mvrvRateColor = theme.palette.error.dark
            mvrvDesc = 'POSSIBLE TOP'
        }

        return (
            <Stack
                direction="column"
                sx={{backgroundColor: theme.palette.dark.dark, margin: '0px', padding: '5px', paddingTop: '0px', borderRadius: '5px', minHeight: '7vh'}}
            >
                <Stack
                direction="row"
                display="flex"
                justifyContent='space-between'
                alignItems="center"
                >
                <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>MVRV</Typography>
                </Stack>
                <Box sx={{width: '99%', backgroundColor: theme.palette.dark.dark, paddingTop: '8px', paddingBottom: '5px'}} >
                <Stack direction="column" alignItems="center">
                    <Avatar sx={{width: 50, height: 50, bgcolor: mvrvRateColor, display: 'flex', justifyContent: 'center', fontSize: '.9rem', color: theme.palette.grey[100] }}>{useThisMVRVData.healthData.mvrv.toFixed(2)}</Avatar>
                    <Typography variant="Button" sx={{marginLeft: '5px', fontWeight: 700, color: mvrvRateColor}}>{mvrvDesc}</Typography>
                </Stack>
                </Box>
            </Stack>
        )
    }
}
export default HealthDisplayMVRV