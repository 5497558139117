import React, { useState } from 'react'
import { Avatar, Button, Typography, TextField, Link, Grid, Container } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import hofs from '../../utility/hofs'
import ResetMessage from './ResetMessage'
import fetchData from '../../utility/endpointFetch'
import Logo from '../Header/Logo'

const PasswordReset = () => {
  const theme = useTheme()

  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState(false)
  const [displayResetMessage, setDisplayResetMessage] = useState(false)

  const handleEmailChange = e => {
    setEmailError(false)
    setEmail(e.target.value)
  }

  const handleResetSubmit = e => {
    console.log("top of handleResetSubmit")
    e.preventDefault()

    // first is to check if email and password are filled in
    const isEmailPopulated = hofs.isPopulated(email)
    setEmailError(!isEmailPopulated)

    if (isEmailPopulated) {
      console.log("no errors....send")
      // there are no errors...send the data to backend to complete registration
      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'email': email })
      }

      ;(async () => {
        const response = await fetchData('api/v1/users/forgotpassword/', 'Error reseting your password.', fetchParams).promise
        console.log("response is: ")
        console.dir(response)
        if (response.status === 'OK') {
          // email was sent if email existed
          setDisplayResetMessage(true)
        } else {
          //console.log('password reset response was corrupted or something')
          // lets still do the same
          //setEmailError(true)
          //to protect from hacking...seeing if an email exists by sending an email in this feature...we will give the same feedback no matter the response from server
          setDisplayResetMessage(true)
        }
      })()
    } else {
      //console.log('there were errors in the password reset submission')
    }
  }

  return (
    <Container component='main' maxWidth='xs' sx={{marginTop: '50px', justifyContent: 'center'}}>
      <Logo width={50} />
      <Typography component='h1' variant='h5' sx={{marginTop: '15px'}}>
        Password Reset
      </Typography>
      {displayResetMessage ? <ResetMessage /> :
        <form sx={{ width: '100%', marginTop: theme.spacing(1) }} noValidate onSubmit={handleResetSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            onChange={handleEmailChange}
            inputProps={{ maxLength: 255 }}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={{ margin: theme.spacing(3, 0, 2) }}
          >
            Send Password Reset Email
          </Button>

          <Grid container>
            <Grid item>
              <Link href='/register' variant='body2'>
                Don't have an account? Sign Up!
              </Link>
            </Grid>
          </Grid>
        </form>
      }
    </Container>
  )
}

export default PasswordReset
