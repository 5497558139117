import React, { useEffect, useState } from 'react'
import { Grid, Typography, Stack, Divider, Pagination, Box, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import VIPMainTabsFullPostScreener from '../VIP/VIPMainTabsFullPostScreener'
import VIPTwitterPost from '../VIP/VIPTwitterPost'

import fetchData from '../../utility/endpointFetch'

const VIPTweetsResults = ( { changeVIPTweetsCoinTab, changeVIPTweetsSearchTab, changeTwitterCoin, changeVIPTweetsChartTab, changeVIPTweetsAccountsTweetsTab, maxBoxHeight, divHeight, boxWidth } ) => {

    //console.log("top of VIPTweetsResults and maxBoxHeight is: " + maxBoxHeight)
    const theme = useTheme()

    const [isLoading,setIsLoading] = useState(true)
    const [vipListings,setVIPListings] = useState([])
    const [vipPage,setVIPPage] = useState(1)
    const [vipPageCount,setVIPPageCount] = useState(1)
    const [responseCount,setResponseCount] = useState(0)
    const [vipCoins,setVIPCoins] = useState([])
    
    const changePage = (event, page) => {
        setVIPPage(page)
    }

    const changeInVIPCoins = (newVIPCoins) => {
        //console.log("top of changeInVIPCoins and newVIPCoins is:")
        //console.dir(newVIPCoins)
        getNewVIPSignal(newVIPCoins)
        setVIPCoins(newVIPCoins)
    }    

    const getNewVIPSignal = (currentVIPCoins) => {
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },
        }
        ;(async () => {
            try {
                //let getVIPSignalURL = `api/v1/vip/vipsignal/?has_post=true&ordering=-post__created&page=${vipPage}`
                let getVIPSignalURL = `api/v1/feed/alltweets/?vip=true&ordering=-post__created&page=${vipPage}`

                /*
                if (currentVIPCoins.length === 0) {
                    //assume all coins is selected...use a different endpoint
                    getVIPSignalURL = `api/v1/vip/vipsignal/?all&has_post=true&ordering=-post__created&page=${vipPage}`
                }  
                */
                
                const vipResponse = await fetchData(getVIPSignalURL, "could not receive vipsignal data within VIPTweetsResults", fetchParams).promise
                //console.log("vipResponse getting vipsignal data from api")
                //console.dir(vipResponse)
                //console.log("currentVIPCoins is:")
                //console.dir(currentVIPCoins)
                //console.log("getVIPSignalURL is:")
                //console.log(getVIPSignalURL)

                setResponseCount(vipResponse.count)

                if (vipResponse.count > 100) {
                    setVIPPageCount(Math.ceil(vipResponse.count/100))
                } else {
                    setVIPPageCount(1)
                }

                let coinsIncluded = []
                if (vipResponse.results.length > 0) {
                    vipResponse.results.map( listing => {
                        //add coins associated to coinsincluded
                        //at first only post coins....as coins filter will only work on posts.
                        //so if we later wish to filter notifications by posts....add that as it's own filter as it will only apply to posts
                        coinsIncluded = coinsIncluded.concat(listing.coins)
                    })

                    changeTwitterCoin(vipResponse.results[0].coins[0])        
                } 
                setVIPCoins(coinsIncluded)

                setVIPListings(vipResponse.results)

                //console.log("vipResponse.results is")
                //console.dir(vipResponse.results)

                setIsLoading(false)
            } catch (error) {
                console.log("something is broke getting vipsignal within VIPTweetsResult: " + error)
            }
        })()    
    }  

    useEffect(() => {
        //console.log("top of useEffect if vipPage change")
        //console.log(vipCoins)
        setIsLoading(true)
        getNewVIPSignal(vipCoins)
    
    }, [vipPage])    
    
    const changeDateInChart = () => {
        //provides a date that can mark the tweet on a chart....send to chart tab?

    } 

    const setAtMentionedForTweetsSearchTab = (searchText) => {
        //need to set search type to mentioned
        changeVIPTweetsSearchTab(searchText,1)
    }

    const setHashForTweetsSearchTab = (searchText) => {
        //need to set search type to hash
        changeVIPTweetsSearchTab(searchText,2)
    }
    if (isLoading) return <CircularProgress />
    return (
        <Grid container sx={{ display: 'flex', alignItems: 'flex-start', overflow: 'auto',}}>
            <Grid item xs={12}>
                <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" >
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around">
                        <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.secondary.main, paddingLeft: '20px', marginTop: '20px'}}>{responseCount}</Typography>
                        <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.secondary.main, marginTop: '20px', marginLeft: '3px'}}> Full VIP Results</Typography>
                    </Stack>
                    <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.warning.main, marginTop: '20px', marginLeft: '30px'}}>Select Tweet to Display related Market Data</Typography>
                    <VIPMainTabsFullPostScreener  changeInVIPCoins={changeInVIPCoins}/>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{height: maxBoxHeight, overflowY: 'auto', scrollbarWidth: 'thin'}}>
                    {vipListings.map( (listing, index) => {
                        if (listing !== null) {
                            if (listing.hasOwnProperty('tweet_id')) 
                                return (
                                    <VIPTwitterPost 
                                        key={"viptweetresults"+listing.display_name+index} 
                                        listing={listing} 
                                        setCoinInChart={changeVIPTweetsChartTab} 
                                        changeAtFollowedTab={changeVIPTweetsAccountsTweetsTab} //provides a user -- clicked account tweets
                                        changeAtMentionedTab={setAtMentionedForTweetsSearchTab} //provides the atted to search in 5 -- clicked in tweet
                                        changeHashTab={setHashForTweetsSearchTab} //provides the hash to search in 5 -- clicked in tweet
                                        changeCoinInTab={changeVIPTweetsCoinTab} //provides the coin --- coin clicked in tweet
                                        changeDateInChart={changeDateInChart} //provides a date that can be set in the chart -- date of tweet clicked
                                        showOfficialTweets={changeVIPTweetsAccountsTweetsTab} //provides user and coin --- clicked twitter account
                                        divHeight={divHeight}
                                        boxWidth={boxWidth}
                                    />
                                )
                            else return null //not sure what type this is
                        }
                    })}
                    <Divider sx={{marginBottom: '15px'}}/>
                    <Stack direction="row" display="flex" alignItems="center">
                        <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
                        <Pagination shape="rounded" count={vipPageCount} page={vipPage} onChange={changePage}/>
                    </Stack>                    
                </Box>
            </Grid>
        </Grid>
    )
}

export default VIPTweetsResults