import React, {useState, useEffect } from 'react'
import { Grid, Pagination, Stack, Typography, Divider, CircularProgress} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch'

import VIPResearchPost from './VIPResearchPost'

const VIPMainTabsComments = ( { commentType, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart } ) => {

  const theme = useTheme()

  const [commentListings,setCommentListings] = useState([])
  const [isLoading,setIsLoading] = useState(true)
  const [commentResponseCount,setCommentResponseCount] = useState(0)
  const [commentPage,setCommentPage] = useState(1)
  const [commentPageCount,setCommentPageCount] = useState(1)

  const changePage = (event, page) => {
    setIsLoading(true)
    setCommentPage(page)
  }

  var header = ''
  if (commentType === 'trade') {
    //this will display comments that are trade notifications
    header = "Trade Post Results"
  } else {
    //this instance will display comments that reference posts
    header = "Comment Post Results"
  }

  useEffect(() => {
    setIsLoading(true)
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        var commentTypeUrl = "tradepost=true"
        if (commentType === 'post') commentTypeUrl = "tradepost=false"
        const commentResponse = await fetchData(`api/v1/research/analysis/?${commentTypeUrl}&page=${commentPage}`, "could not receive comment data within vip", fetchParams).promise
        console.log("commentResponse getting comments data within vip from api")
        console.dir(commentResponse)

        setCommentResponseCount(commentResponse.count)

        if (commentResponse.count > 100) {
          setCommentPageCount(Math.ceil(commentResponse.count/100))
        } else {
          setCommentPageCount(1)
        }

        setCommentListings(commentResponse.results)

        setIsLoading(false)
      } catch (error) {
        console.log("error","something is broke getting comments in vip: " + error)
      }
    })()

  }, [commentPage])

  if (isLoading) return <CircularProgress />
  return (
    <Grid container sx={{minHeight: '200px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
      <Grid item xs={12}>
        <Divider sx={{width:'100%', marginTop: '20px'}} textAlign="left" >
          <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.primary.main, paddingLeft: '20px', marginTop: '20px'}}>{commentResponseCount}</Typography>
          <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.grey[300], marginTop: '20px'}}> {header}</Typography>
        </Divider>
      </Grid>
      {commentListings.map( (comment, index) => {
        return <VIPResearchPost key={'vipresearch'+index} researchItem={comment} setCoinInChart={setCoinInChart} changeAtFollowedTab={changeAtFollowedTab} changeAtMentionedTab={changeAtMentionedTab} changeHashTab={changeHashTab} changeCoinInTab={changeCoinInTab} changeDateInChart={changeDateInChart} /> //not sure if these are the only 5 types needed
      })}
      <Grid item xs={12}>
        <Divider sx={{marginBottom: '15px'}}/>
        <Stack direction="row" display="flex" alignItems="center">
          <Typography variant="button" sx={{marginRight: '20px'}}>Results: {commentResponseCount} </Typography>
          <Pagination shape="rounded" count={commentPageCount} page={commentPage} onChange={changePage}/>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default VIPMainTabsComments