import React from 'react'
import { Box, CssBaseline, Container, CircularProgress, Grid, Stack, Popover, Button } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

const ResearchTutorialButtons = ({researchTutorialStep, handleTutorialStepClick, closeTutorial}) => {

    return (
        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around" sx={{padding: '10px'}}>
            <Button size="small" variant="contained" startIcon={<ArrowBackIosNewIcon />} disabled={researchTutorialStep === 1 ? true : false} onClick={()=>handleTutorialStepClick('previous')}>
                Previous
            </Button>
            <Button size="small" variant="contained" endIcon={<HighlightOffIcon />} onClick={()=>closeTutorial()}>
                exit tutorial
            </Button>                        
            <Button size="small" variant="contained" endIcon={<NavigateNextIcon />} disabled={researchTutorialStep === 9 ? true : false} onClick={()=>handleTutorialStepClick('next')}>
                Next
            </Button>
        </Stack>        
    )
}
export default ResearchTutorialButtons