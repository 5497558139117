import React, { useEffect, useState } from 'react'
import { Grid, CircularProgress } from '@mui/material'

import fetchData from '../../utility/endpointFetch'

import VIPMainTabsFeedKeywordAlertsItem from './VIPMainTabsFeedKeywordAlertsItem'

const VIPMainTabsFeedKeywordAlerts = ( {changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, showOfficialTweets, divHeight=0, boxWidth=0 } ) => {

    const [keywordNotifications,setKeywordNotifications] = useState([])
    //const [keywordNotificationsCount,setKeywordNotificationsCount] = useState(0)
    const [isLoading,setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        //the popover was just opened...get the current saved searches
        const getKeywordNotificationsParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }          
        ;(async () => {
            try {
                let getKeywordAlertNotificationsURL = `api/v1/vip/keywordalert/`
                const getKeywordNotificationsResponse = await fetchData(getKeywordAlertNotificationsURL, "could not receive current keyword alert", getKeywordNotificationsParams).promise
                console.log("getKeywordNotificationsResponse getting current keyword alert")
                console.dir(getKeywordNotificationsResponse)
                console.log("getKeywordAlertNotificationsURL is: " + getKeywordAlertNotificationsURL)

                //setKeywordNotificationsCount(getKeywordNotificationsResponse.count)
                setKeywordNotifications(getKeywordNotificationsResponse.results)
        
                setIsLoading(false)

            } catch (error) {
                console.log("something is broke receiving current keyword alert: ", error)
            }

        })()              
    
    }, []) 

    if (isLoading) return <CircularProgress />
    return (
        <Grid container>
            {keywordNotifications.map( (keywordNotification, index) => 
                <VIPMainTabsFeedKeywordAlertsItem 
                    key={"keywordNotification"+keywordNotification.id+index}
                    keywordAlert={keywordNotification}
                    changeAtFollowedTab={changeAtFollowedTab}
                    changeAtMentionedTab={changeAtMentionedTab}
                    changeHashTab={changeHashTab}
                    changeCoinInTab={changeCoinInTab}
                    changeDateInChart={changeDateInChart}
                    showOfficialTweets={showOfficialTweets}    
                    divHeight={divHeight}
                    boxWidth={boxWidth}                                    
                />
            )}
        </Grid>
    )
}

export default VIPMainTabsFeedKeywordAlerts