import React from 'react'
import { Box, Tab, Grid } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import VIPTabsStats from './VIPTabsStats'
import VIPTabsHash from './VIPTabsHash'
import VIPTabsCoin from './VIPTabsCoin'
import VIPTabsAtFollowed from './VIPTabsAtFollowed'
import VIPTabsAtMentioned from './VIPTabsAtMentioned'
import VIPTabsBitCoinChart from './VIPTabsBitCoinChart'
import VIPCoinStats from './VIPCoinStats'
import VIPSocialStats from './VIPSocialStats'

const VIPTabs = ( {vipTabValue, hashTabValue, changeHashTab, changeVIPTabValue, vipListings, coinsMentioned, changeCoinInTab, coinInTab, setCoinInChart, atMentionedTabValue, changeAtMentionedTab, atFollowedTabValue, changeAtFollowedTab, changeDateInChart, coinChart, vipConfig, showOfficialTweets, userObject} ) => {
  //console.log("atMentionedTabValue is:")
  //console.dir(atMentionedTabValue)
  //console.dir(coinsMentioned)

  const theme = useTheme()

  const handleTabChange = (event, newValue) => {
    //console.log("top of handleTabChange in VIPTabs and newValue is:")
    //console.dir(newValue)
    changeVIPTabValue(newValue);
  }
  if (vipConfig === "noize") {
    //console.log("vipConfig is NOIZE.........................")
    //console.log("vipTabValue is:")
    //console.dir(vipTabValue)
    return (
      <Box sx={{width: '100%', backgroundColor: theme.palette.dark[800], height: '1050px',}}>
        <TabContext value={vipTabValue} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="" indicatorColor="secondary" textColor="primary" >
              <Tab label="stats" value="1" sx={{minWidth: '30px'}}/>
              <Tab label="VIP @Mentioned" value="2" sx={{minWidth: '30px'}} />
              <Tab label="VIP @Followed" value="3" sx={{minWidth: '30px'}} />
              <Tab label="VIP #" value="4" sx={{minWidth: '30px'}} />
              <Tab label="VIP Coin" value="5" sx={{minWidth: '30px'}} />
              <Tab label="BTC Chart" value="6" sx={{minWidth: '30px'}} />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
            <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '975px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPTabsStats
                vipListings={vipListings}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                coinsMentioned={coinsMentioned}
                changeAtFollowedTab={changeAtFollowedTab}
                changeCoinInTab={changeCoinInTab}
                coinInTab={coinInTab}
                setCoinInChart={setCoinInChart}
              />
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box sx={{height: '975px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPTabsAtMentioned
                vipListings={vipListings}
                atMentionedTabValue={atMentionedTabValue}
                setCoinInChart={setCoinInChart}
                changeAtFollowedTab={changeAtFollowedTab}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                changeDateInChart={changeDateInChart}
                showOfficialTweets={showOfficialTweets}
              />
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <Box sx={{height: '975px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPTabsAtFollowed
                vipListings={vipListings}
                atFollowedTabValue={atFollowedTabValue}
                setCoinInChart={setCoinInChart}
                changeAtFollowedTab={changeAtFollowedTab}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                changeDateInChart={changeDateInChart}
                showOfficialTweets={showOfficialTweets}
              />
            </Box>
          </TabPanel>
          <TabPanel value="4">
            <Box sx={{height: '975px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPTabsHash
                vipListings={vipListings}
                hashTabValue={hashTabValue}
                changeAtFollowedTab={changeAtFollowedTab}
                setCoinInChart={setCoinInChart}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                changeDateInChart={changeDateInChart}
                showOfficialTweets={showOfficialTweets}
              />
            </Box>
          </TabPanel>
          <TabPanel value="5">
            <Box sx={{height: '975px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPTabsCoin
                coinsMentioned={coinsMentioned}
                vipListings={vipListings}
                setCoinInChart={setCoinInChart}
                changeAtFollowedTab={changeAtFollowedTab}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                coinInTab={coinInTab}
                changeDateInChart={changeDateInChart}
                showOfficialTweets={showOfficialTweets}
              />
            </Box>
          </TabPanel>
          <TabPanel value="6">
            <Box sx={{height: '975px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <Grid container>
                <Grid item xs={12}>
                  <VIPTabsBitCoinChart userObject={userObject} />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ margin: '10px' }}>
                    <VIPSocialStats atFollowedTabValue={atFollowedTabValue} />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ margin: '10px' }} >
                    <VIPCoinStats 
                      coinChart={coinChart} 
                      showOfficialTweets={showOfficialTweets} 
                      setCoinInChart={setCoinInChart} 
                      changeAtFollowedTab={changeAtFollowedTab} 
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    )
  } else {
    return (
      <Box sx={{height: '975px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin', paddingTop: '22px'}}>
        <Grid container>
          <Grid item xs={12}>
            <VIPTabsBitCoinChart userObject={userObject}/>
          </Grid>
          <Grid item xs={6}>          
            <Box sx={{ margin: '10px' }}>
              <VIPSocialStats 
                atFollowedTabValue={atFollowedTabValue} 
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ margin: '10px' }} >
              <VIPCoinStats 
                coinChart={coinChart}
                showOfficialTweets={showOfficialTweets}
                setCoinInChart={setCoinInChart} 
                changeAtFollowedTab={changeAtFollowedTab}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default VIPTabs