import React, { useEffect, useState } from 'react';
import fetchData from "../../utility/endpointFetch";
import { Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import VIPMainTabsFeedTriggeredAlertsItem from "./VIPMainTabsFeedTriggeredAlertsItem";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";


const VIPMainTabsFeedTriggeredAlerts = () => {
    //console.log("top of VIPMainTabsFeedHotSearches")

    const [vipTriggeredAlertsList,setVipTriggeredAlertsList] = useState([])
    const [isLoading,setIsLoading] = useState(true)
    const [nextUrl, setNextUrl] = useState(null)
    const [prevUrl, setPrevUrl] = useState(null)
    const [page, setPage] = useState(1)

    const prevPage = () => {
        if(!prevUrl) return
        setPage(page - 1)
        const getVIPSearchFeedParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            try {
                const getVIPFeedTriggeredAlertsResponse = await fetchData(prevUrl, "could not receive alert coins", getVIPSearchFeedParams).promise
                setVipTriggeredAlertsList(getVIPFeedTriggeredAlertsResponse.results)
                setNextUrl(getVIPFeedTriggeredAlertsResponse.next)
                setPrevUrl(getVIPFeedTriggeredAlertsResponse.previous)
            } catch (error) {
                console.log("something is broke receiving current searchfeed hot searches: ", error)
            }
            setIsLoading(false)
        })()
    }

    const nextPage = () => {
        if(!nextUrl) return
        setPage(page + 1)
        setIsLoading(true)
        //the popover was just opened...get the current saved searches

        const getVIPSearchFeedParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            try {
                const getVIPFeedTriggeredAlertsResponse = await fetchData(nextUrl, "could not receive alert coins", getVIPSearchFeedParams).promise
                setVipTriggeredAlertsList(getVIPFeedTriggeredAlertsResponse.results)
                setNextUrl(getVIPFeedTriggeredAlertsResponse.next)
                setPrevUrl(getVIPFeedTriggeredAlertsResponse.previous)
            } catch (error) {
                console.log("something is broke receiving current searchfeed hot searches: ", error)
            }
            setIsLoading(false)
        })()
    }

    useEffect(() => {
        setIsLoading(true)
        //the popover was just opened...get the current saved searches

        const getVIPSearchFeedParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            try {
                let getVIPFeedTriggeredAlertsURL = `api/v1/vip/pricealert/`
                const getVIPFeedTriggeredAlertsResponse = await fetchData(getVIPFeedTriggeredAlertsURL, "could not receive alert coins", getVIPSearchFeedParams).promise
                console.log("getVIPFeedTriggeredAlertsURL is: " + getVIPFeedTriggeredAlertsURL)
                console.log("getVIPFeedTriggeredAlertsResponse is:")
                console.dir(getVIPFeedTriggeredAlertsResponse)
                setVipTriggeredAlertsList(getVIPFeedTriggeredAlertsResponse.results)
                setNextUrl(getVIPFeedTriggeredAlertsResponse.next)
                setPrevUrl(getVIPFeedTriggeredAlertsResponse.previous)
            } catch (error) {
                console.log("something is broke receiving current searchfeed hot searches: ", error)
            }
            setIsLoading(false)
        })()
    }, [])

    if (isLoading) return <CircularProgress />

    return (
        <Grid container>
            { vipTriggeredAlertsList.length > 0 ?
            <Stack direction={'row'} sx={{width: '100%'}} justifyContent={'center'} alignItems={'center'}>
                <Button onClick={() => prevPage()} disabled={!prevUrl}>
                    <ChevronLeft />
                </Button>
                <Typography sx={{fontWeight: 'bold'}} color={'white'}>{page}</Typography>
                <Button onClick={() => nextPage()} disabled={!nextUrl}>
                    <ChevronRight />
                </Button>
            </Stack> : null }
            <Stack direction={'column'} sx={{width: '100%'}}>
                {vipTriggeredAlertsList.map( (item, index) => {
                    return (
                        <VIPMainTabsFeedTriggeredAlertsItem item={item}/>
                    )
                })}
            </Stack>
            {
                vipTriggeredAlertsList.length === 0 && (
                    <React.Fragment>
                        <Typography sx={{textAlign: 'center', width: '100%', mt: 2}}>You haven't triggered any alerts yet.</Typography>
                        <Typography sx={{textAlign: 'center', width: '100%'}}>Try setting a price alert by clicking on any of the charts.</Typography>
                    </React.Fragment>
                )
            }
        </Grid>
    )
}

export default VIPMainTabsFeedTriggeredAlerts;