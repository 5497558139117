import { Typography, Stack, IconButton, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { TiDelete } from 'react-icons/ti'

const CustomFeedFollowedListItem = ({ followed, removeUserFromWhiteList,getTwitterUserTweets, col, addStrongItem, removeStrongItem, userData}) => {
    
    const theme = useTheme()

    const [isStrongUser, setIsStrongUser] = useState(false)

    useEffect(() => {
        if(userData['strong_twitter_users'].includes(followed)){
            setIsStrongUser(true)
        }else{
            setIsStrongUser(false)
        }
    }, [])

    return (
        <Stack direction="row" sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between', paddingRight: '15px' }} >
            <Typography sx={{cursor: 'pointer', }} onClick={() => getTwitterUserTweets(followed)}>{followed}</Typography>
            <Stack direction="row" sx={{display: 'flex', }}>
                {/*<Button*/}
                {/*    size="small"*/}
                {/*    onClick={isStrongUser ?*/}
                {/*        () => {*/}
                {/*            removeStrongItem(followed)*/}
                {/*            setIsStrongUser(!isStrongUser)*/}
                {/*        }*/}
                {/*        :*/}
                {/*        () => {*/}
                {/*            addStrongItem(followed)*/}
                {/*            setIsStrongUser(!isStrongUser)*/}
                {/*        }*/}
                {/*    }*/}
                {/*>*/}
                {/*    <Typography sx={{fontWeight: isStrongUser ? "bold" : "normal", color: theme.palette.primary.main}}>S</Typography>*/}
                {/*</Button>*/}
                <IconButton size="small" onClick={()=>removeUserFromWhiteList(followed, col)} ><TiDelete color={theme.palette.error.dark} /></IconButton>
            </Stack>
        </Stack>
    )
}

export default CustomFeedFollowedListItem