import React, { useState, useEffect } from 'react'
import { Typography, Grid, Divider, RadioGroup, Box, Radio, FormControlLabel, Popover, Stack, useMediaQuery, Pagination, InputLabel, OutlinedInput, InputAdornment, 
  TextareaAutosize, TextField, Button, FormControl, IconButton, Paper, FormHelperText, CircularProgress, Snackbar, Alert } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import hofs from '../../utility/hofs'
import fetchData from '../../utility/endpointFetch'

import ResearchGeneralCommentaryCoinListItem from './ResearchGeneralCommentaryCoinListItem'
import ResearchGeneralCommentaryCoinsDisplay from './ResearchGeneralCommentaryCoinsDisplay'

import {DropzoneArea} from 'react-mui-dropzone'

import Transitions from '../ui-component/extended/Transitions'
import MainCard from '../ui-component/cards/MainCard'

import { IconSearch, IconSquareX } from '@tabler/icons'

const ResearchGeneralCommentaryForm = ({ }) => {
  //console.log("top of ResearchGeneralCommentaryForm")
  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const commentsErrorMessage = "Please fill in your comments"

  const [isLoading,setIsLoading] = useState(false)
  const [bullishRecommendation,setBullishRecommendation] = useState('bearish')
  const [comments, setComments] = useState('')
  const [commentsError,setCommentsError] = useState(false)
  const [tradePost,setTradePost] = useState(false)
  const [formError,setFormError] = useState(false)
  const [commentImage,setCommentImage] = useState([])
  const [dropzoneKey,setDropzoneKey] = useState(0)

  const [searchCoinTerm,setSearchCoinTerm] = useState('')
  const [searchIsLoading,setSearchIsLoading] = useState(false)
  const [searchTermResult,setSearchTermResult] = useState([])
  const [searchTermCount,setSearchTermCount] = useState(0)

  const [relevantDate,setRelevantDate] = useState((new Date()).toISOString().split('T')[0])
  const [coinCorrelation,setCoinCorrelaton] = useState([])
  
  const [coinCorrelationError,setCoinCorrelationError] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [coinPage,setCoinPage] = useState(1)
  const [coinsList,setCoinsList] = useState([])
  const [coinCount,setCoinCount] = useState(0)
  const [coinsListPageCount,setCoinsListPageCount] = useState(1)
  const [researchCommentReceived,setResearchCommentReceived] = useState(false)

  const [coinDialogBoxOpen,setCoinDialogBoxOpen] = useState(false)

  const addCoinToCoinsCorrelated = (addCoin) => {
    //console.log("top of addCoinToCoinsCorrelated: " + addCoin)
    if (!coinCorrelation.includes(addCoin))
      setCoinCorrelaton([...coinCorrelation, addCoin])
  }

  const removeCoinToCorrelated = ( removeCoin ) => {
    //console.log("top of removeCoinToCorrelated: " + removeCoin)
    setCoinCorrelaton(coinCorrelation.filter( coin => coin !== removeCoin))
  }

  const handleSearchCoinChange = ( e) => {

    console.log("top of handleSearchCoinChange and searchTerm is: " + e.target.value)
    setSearchCoinTerm(e.target.value)
    if (e.target.value.length > 1) {
      //console.log("e.target.value is longer than 1")
      //lets search the coinmarket endpoint for coins
      setSearchIsLoading(true)
      
      const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
      }    
      ;(async () => {
        try {
          var coinMarketURL = `api/v1/coin/coinmarket/?search=${e.target.value}`
          const searchCoinResponse = await fetchData(coinMarketURL, "could not receive coin search results in general comment form", fetchParams).promise
          //console.log("searchCoinResponse getting coin search results in general comment for")
          //console.dir(searchCoinResponse)
          setSearchTermResult(searchCoinResponse.results)
          setSearchTermCount(searchCoinResponse.count)
          setSearchIsLoading(false)
  
        } catch (error) {
          console.log("could not receive coin search results in general comment form: ",error)
        }        
        })()      
    }
  }

  useEffect(() => {
    setIsLoading(true)
    //console.log("top of useEffect in HomeFeedManager to load followed")
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }    
    ;(async () => {
      try {
        var offset= (coinPage - 1) * 100 //limit per page
        var coinMarketURL = `api/v1/coin/coinmarket/?ordering=name&limit=100&offset=${offset}`
        //console.log("coinPage is: " + coinPage)
        //console.log("offset is: " + offset)
        //console.log(coinMarketURL)
        const response = await fetchData(coinMarketURL, "could not receive coin list in general comment form", fetchParams).promise
        //console.log("ResearchGeneralCommentaryForm response getting coin list in general comment for")
        //console.dir(response)
        setCoinsList(response.results)
        setCoinCount(response.count)

        if (response.count > 100) {
          setCoinsListPageCount(Math.ceil(response.count/100))
        } else {
          setCoinsListPageCount(1)
        }
        setIsLoading(false)

      } catch (error) {
        console.log("could not receive coin list in general comment form: ",error)
      }        
      })()
  }, [coinPage])

  //coin correlation helperfunctions
  const changeCoinsListPage = (event, page) => {
    //console.log("top of changeCoinsListPage: " + page)
    setIsLoading(true)
    setCoinPage(page)
  }  

  const handleCoinChoiceClose = (event) => {
    //console.log("top of handleCoinChoiceClose")
    //handleCoinCorrelationChange()
    setAnchorEl(null)
    setSearchCoinTerm('')
    setSearchTermCount(0)
    setCoinDialogBoxOpen((prevOpen) => !prevOpen)
  };  

  const handleCommentstChange = (e) => {
    //console.log('handleCommentstChange and e.target.value is: '+ e.target.value)
    setComments(e.target.value)
  }

  const handleRelevantDateChange = (new_value) => {
    //console.log('handleRelevantDateChange and new_value is: ' + new_value)
    //if last character of date string is "Z" need to remove it
    //console.dir(new_value)

    var relevant_date = new Date(new_value)
    //console.dir(relevant_date)
    var send_relevant_date = relevant_date.toISOString().split('T')[0]
    //console.log("send_relevant_date: " )
    //console.dir(send_relevant_date)
    setRelevantDate(send_relevant_date)
  }

  const handleImageAttach = (files) => {
    setCommentImage(files)
  }



  const handleBullishChange = (e) => {
    //console.log('handleBullishChange and e.target.value is: ' + e.target.value)
    setBullishRecommendation(e.target.value)
  }

  const handleTradePostChange = (e) => {
    setTradePost(e.target.value)
  }

  const handleCommentsSubmit = () => {
    //console.log("top of handleCommentsSubmit")
    var errorCount = 0
    //first is to check if all required forms are filled
    if (hofs.isPopulated(comments)) {
      //console.log("comments good")
      setCommentsError(false)
    } else {
      errorCount++
      setCommentsError(true)
      //console.log("comments empty")
    }

    if (coinCorrelation.length ===0 ) {
      setCoinCorrelationError(true)
    } else {
      setCoinCorrelationError(false)
    }



    //console.log("errorCount is: " + errorCount)
    if (errorCount === 0) {
      // there are no errors...send the data to backend to send comments
      //console.log("there are no errors in form...send comments")

      var formData = new FormData()
      formData.append("post", '')
      formData.append("comments", comments)
      coinCorrelation.map( coin => formData.append("coin", coin))
      formData.append("relevant_date", relevantDate)
      formData.append("bullish", bullishRecommendation === "bullish" ? true : false)
      formData.append("tradepost", tradePost)
      if(commentImage && commentImage.length){
        formData.append('image',commentImage[0])
      }

      const fetchParams = {
        method: 'POST',
        headers: {
          //'Content-Type': 'multipart/form-data; boundary=---WebKitFormBoundary2dkowOOM0fftW'
        },
        body: formData
      }
      //console.log("ResearchGeneralCommentaryForm post comment parameters are:" )
      //console.dir(fetchParams)
      const errorMessage = 'Error saving your comment.  Resubmit.  If still an error please contact support.'
      ;(async () => {
        const response = await fetchData('api/v1/research/post/', errorMessage, fetchParams).promise
        //console.log("research comments post response is:")
        //console.dir(response)
        if (response.success && response.success === 'Analysis received') {
          // user created
          //console.log("response.succes received")
          setFormError(false)
          setRelevantDate((new Date()).toISOString().split('T')[0])
          setComments('')
          setCoinCorrelaton([])
          setCommentImage([])
          setDropzoneKey(prevCount => prevCount + 1)
          setResearchCommentReceived(true)
          //reset the form
          //console.log("comment form reset")
        } else {
          //there is an error....what to do?
          //console.log("response.success not received")
          if (response.error) {
            console.log("error submitting research form: " + response.error)
            setFormError(true)
          }
          console.log("comments post error is: " + response.error)
        }
      })()
    }
  }

  const handleResearchReceivedClose = () => {
    //snackbar Closed
    setResearchCommentReceived(false)
  }

  const label = { inputProps: { 'aria-label': 'suggest removal' } };

  //console.log("coinCorrelation in researchsourcepostcommentaryForm is: ")
  //console.dir(coinCorrelation)

  return (
    <Box sx={{width: '100%', backgroundColor: theme.palette.dark[800], padding: '15px', borderRadius: '8px'}}>
    <Grid container spacing={.75}>
      <Grid item xs={12}>
        <Typography variant='h4' sx={{color: theme.palette.grey[500], marginBottom: '10px'}}>Leave General Comment</Typography>
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            id='relevant_date'
            name='relevant_date'
            label="relevant date"
            inputFormat="yyyy-MM-dd"
            value={relevantDate}
            onChange={handleRelevantDateChange}
            variant='outlined'
            mask="____-__-__"
            color="white"
            renderInput={(params) => <TextField size="small" {...params} sx={{borderColor: theme.palette.grey[500], width: '100%'}} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        {commentsError ? <Typography variant='body2' sx={{color: theme.palette.error.main, padding: '10px', marginTop: '15px'}}>{commentsErrorMessage}</Typography> : null }
        <TextareaAutosize
          id='comments'
          name='comments'
          label='comments'
          value={comments}
          onChange={handleCommentstChange}
          variant='comments'
          minRows={10}
          aria-label="comments area"
          placeholder="Leave Comments"
          InputProps={{color: theme.palette.grey[500]}}
          style={{ minWidth: 300, width: '100%', borderColor: theme.palette.grey[500], backgroundColor: theme.palette.dark[800], color: theme.palette.grey[700] }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <RadioGroup
            row
            aria-labelledby="bullish-radio-buttons-group"
            name="bullish-radio-buttons-group"
            value={bullishRecommendation}
            onChange={handleBullishChange}
          >
            <FormControlLabel value="bullish" control={<Radio />} label="Bullish" />
            <FormControlLabel value="bearish" control={<Radio />} label="Bearish" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <RadioGroup
            row
            aria-labelledby="tradepost-radio-buttons-group"
            name="tradepost-radio-buttons-group"
            value={tradePost}
            onChange={handleTradePostChange}
          >
            <FormControlLabel value={false} control={<Radio />} label="Comment" />
            <FormControlLabel value={true} control={<Radio />} label="Trade Recommend" />
          </RadioGroup>
        </FormControl>
      </Grid>      
      <Grid item xs={12}>
        <FormControl
          sx={{minWidth: 300, width: '100%' }}
          error={coinCorrelationError}
        >
          <TextField
            id="multiple-coin-correlation"
            value={coinCorrelation}
            onClick={() => setCoinDialogBoxOpen(true)}
            helperText="Choose Coins This Comment Concerns"
            size="small"
          />
          {coinCorrelationError ? <FormHelperText>Make sure you choose at least one coin</FormHelperText> : null}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Box key={dropzoneKey}>
          <DropzoneArea 
            onChange={(files) => handleImageAttach(files)}
            filesLimit={1}
            acceptedFiles={['image/jpeg', 'image/png']}
            dropzoneText="Drag and drop or click to choose an image to attach to your research post or click to choose"
            showPreviews={false}
            clearOnUnmount={true}
          />
        </Box>
      </Grid>
      {/*<Grid item xs={3}><Typography variant='body2' sx={{color: theme.palette.grey[800], padding: '10px', marginTop: '0px'}}>attach image?</Typography></Grid>
      <Grid item xs={9}>
            </Grid>*/}
      <Grid item xs={12} style={{paddingTop: '25px'}}>
        <Button variant='contained' 
          onClick={handleCommentsSubmit} color='primary'>
          Click To Submit Your Comments
        </Button>
      </Grid>
      <Popover
        id='popoverId'
        open={coinDialogBoxOpen}
        anchorEl={anchorEl}
        onClose={handleCoinChoiceClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 400, left: 900 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >      
        <Transitions position={matchesXs ? 'top' : 'top-right'} in={coinDialogBoxOpen} >
          <Paper>
            <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '900px', maxHeight: '800px', overflow: 'auto', backgroundColor: theme.palette.dark[800]}}>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  
                  <Typography variant="h3" sx={{marginTop: '15px', marginLeft: '30px'}}>Choose Coins Correlated To Your Comment</Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="close"
                    sx={{color: theme.palette.error.main}}
                    onClick={handleCoinChoiceClose}>
                    <IconSquareX sx={{ marginTop: '20px'}}/>
                  </IconButton>
                </Grid>
                <Grid item xs={12}><Divider sx={{width: '100%' }}/></Grid>
                <Grid item xs={4} >
                  <Stack direction="column" sx={{paddingLeft: '10px'}}>
                    <Typography sx={{color: theme.palette.primary.dark, marginBottom: '15px'}}>Choose Coins That Correlate</Typography>
                    {isLoading ? <CircularProgress /> : 
                      coinsList.map((coin,index) => <ResearchGeneralCommentaryCoinListItem coin={coin} key={'coinListIndex'+index} addCoinToCoinsCorrelated={addCoinToCoinsCorrelated} />)
                    }
                    <Pagination sx={{marginTop: '10px'}} shape="rounded" count={coinsListPageCount} page={coinPage} onChange={changeCoinsListPage}/>
                  </Stack>
                </Grid>
                <Grid item xs={5}>
                  <Stack direction="column" sx={{paddingLeft: '10px'}}>
                    <Typography sx={{color: theme.palette.primary.dark, marginBottom: '15px'}}>Search For Coins That Correlate</Typography>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <InputLabel htmlFor="search-coins">search coins</InputLabel>
                      <OutlinedInput
                        id="search-coins"
                        value={searchCoinTerm}
                        onChange={(e) => handleSearchCoinChange(e)}
                        startAdornment={<InputAdornment position="start" sx={{color: theme.palette.grey[300]}}><IconSearch /></InputAdornment>}
                        label="Search Coins"
                      />
                    </FormControl>
                    {searchIsLoading ? <CircularProgress /> : 
                      searchTermResult.map( (coin, index) => <ResearchGeneralCommentaryCoinListItem coin={coin} key={'coinSearchListIndex'+index} addCoinToCoinsCorrelated={addCoinToCoinsCorrelated} /> )
                    }
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack direction="column" sx={{minHeight: '650px', overflow: 'hidden', paddingLeft: '5px'}} >
                    <Typography sx={{color: theme.palette.primary.dark, marginBottom: '15px'}}>Coins Correlated</Typography>
                    <ResearchGeneralCommentaryCoinsDisplay coinCorrelation={coinCorrelation} removeCoinToCorrelated={removeCoinToCorrelated} />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                
              </Grid>                
              </Grid>
            </MainCard>

          </Paper>
        </Transitions>       
      </Popover>  
      <Snackbar
        open={researchCommentReceived}
        autoHideDuration={6000}
        onClose={() => handleResearchReceivedClose()}
        
      >
        <Alert 
          onClose={() => handleResearchReceivedClose()} 
          severity="success" 
          sx={{ width: '100%', bgcolor: theme.palette.success.dark, color: '#fff'}}
        >
          Researcher Comment Received!  Form Reset.
        </Alert>
      </Snackbar>
    </Grid>
    </Box>
  )
}

export default ResearchGeneralCommentaryForm