import React, { useState } from 'react'
import { Box, Backdrop, CssBaseline, Container, Typography, Grid, TextareaAutosize, Button,
  Modal, Fade } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch'
import Navigation from '../Navigation'
import { ThumbUpAlt } from '@mui/icons-material'
import TopAppBar from '../TopAppBar'

const Feedback = ( { userObject } ) => {

  const theme = useTheme()

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: '20px',
            width: `calc(100% - 20px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - 20px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - 20px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - 20px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
  }));

  const [ feedback,setFeedback] = useState('')
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ modalMessage,setModalMessage] = useState('We are capturing your feedback!  Just a moment')

  const handleUserFeedbackSubmit = ( ) => {
    //need to check if there is actually something to submit

    setModalOpen(true)

    const fetchParams = {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: feedback,
      })
    }
    ;(async () => {
      try {
        const user_response = await fetchData(`api/v1/feedback/post/`, "could not submit user feedback ", fetchParams).promise
        console.log("response submit user feedback")
        console.dir(user_response)
        setModalMessage('We have captured your feedback!  Thank you for letting us know what you think.  Your time is important to us and we will act on your suggestions.')

      } catch (error) {
        console.log("error","something is broke submit user feedback")
      }
    })()
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
        {/* header */}
        <TopAppBar userObject={userObject} />
        {/* main content */}
        <Main theme={theme} >
          <Container maxWidth="xl">
            <Box sx={{width: '100%', paddingTop: '10px', paddingBottom: '20px'}}>
              <Container component='main' maxWidth='sm' sx={{marginTop: '50px', justifyContent: 'center'}}>
                <div sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <Typography variant='h3' sx={{paddingBottom: '5px'}}>
                    How Are We Doing?
                  </Typography>
                  <Typography variant='subtitle2' sx={{paddingBottom: '25px'}}>
                    We're always looking for ways to improve.  To us what you think about our mission and how you think we can improve.  Thanks!
                  </Typography>
                  <form sx={{ width: '100%', marginTop: theme.spacing(5.5) }} noValidate onSubmit={handleUserFeedbackSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextareaAutosize
                          aria-label="Feedback"
                          placeholder="Leave Feedback Here"
                          style={{ width: '100%' }}
                          minRows={5}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      sx={{ margin: theme.spacing(3, 0, 2) }}
                      // disabled={checkingForDuplicateEmail}
                    >
                      Click to Submit Feedback
                    </Button>
                  </form>
                </div>
                <Modal
                  aria-labelledby='spring-modal-title-agreement'
                  aria-describedby='spring-modal-description-agreement'
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
                  open={modalOpen}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{ timeout: 500 }}
                  disableEscapeKeyDown={true}
                >
                  <Fade in={modalOpen}>
                    <Box sx={{ position: 'absolute', width: 600, backgroundColor: '#fff',  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)'}}>
                      <h2 id='spring-modal-title'>Alpha Centauri Block Chain App Feedback </h2>
                      <Grid container>
                        <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                          <Typography variant="subtitle2">{modalMessage}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: '25px'}}>
                          <Button startIcon={<ThumbUpAlt />} variant='contained' href="/home" color='primary'>
                            Click To Return Home
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Fade>
                </Modal>
              </Container>
            </Box>
          </Container>
        </Main>
    </Box>
  )
}

export default Feedback