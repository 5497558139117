import React, { useEffect, useState } from 'react'
import { Box, Tab, CircularProgress, Stack, Typography, Divider } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'
import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'    
import fetchData from '../../utility/endpointFetch'

import HealthDisplayDefault from './HealthDisplayDefault'
import HealthDisplayReserve from './HealthDisplayReserve'
import HealthDisplayELR from './HealthDisplayELR'
import HealthDisplayStockToFlow from './HealthDisplayStockToFlow'
import HealthDisplayMinerReserve from './HealthDisplayMinerReserve'
import HealthDisplayNetflow from './HealthDisplayNetflow'
import HealthDisplayStableCoinNetflow from './HealthDisplayStableCoinNetflow'

const HealthDisplay = ( {panelID, deleteBox, selectedTab='1', changeTabInLayout } ) => {

    const theme = useTheme()
    
    const [isLoading, setIsLoading] = useState(true)
    const [btcData, setBTCData] = useState({})
    const [ruleThemAll, setRuleThemAll] = useState([])
    const [healthTabValue,setHealthTabValue] = useState(selectedTab)

    useEffect(() => {

        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            const btcResponse = await fetchData(`api/v1/coin/coinmarket/?coin__id=bitcoin`, "could not receive coinmarket data for bitcoin", fetchParams).promise
            //console.log("btcResponse getting coinmarket data using bitcoin")
            //console.dir(btcResponse)
            ;(async () => {
              try {
                const healthResponse = await fetchData(`api/v1/coin/healthchart/?limit=365&offset=0`, "could not receive health chart data", fetchParams).promise
                //console.log("healthResponse getting health data from api")
                //console.dir(healthResponse)
        
                //add unix timestamp to compare to coingecko
                //not finding .unix_timestamp currently used anywhere....commenting out for possible future removal
                //healthResponse.results.map( (health, index) => {
                //  healthResponse.results[index].unix_timestamp = Math.round(new Date(health.timestamp).getTime())
                //})
        
                var allData = healthResponse.results.map( (data, index) => {
                  return {
                    "timestamp": data.timestamp,
                    "price": data.price,
                    "total_volume": data.volume,
                    "market_cap": data.market_cap,
                    "healthData": data,
                  }
                })
        
                //console.log("ruleThemAll(allData) is: ")
                //console.dir(allData)
        
                setRuleThemAll(allData)            

                setBTCData(btcResponse.results[0])

                setIsLoading(false)
              } catch (error) {
                console.log("something is broke getting healthchart in HealthOverview: " + error)
              }              
            })()
    
          } catch (error) {
            console.log("something is broke getting coinmarket in HealthOverview: " + error)
          }
        })()
    
    }, [])

    const handleTabChange = (event, newValue) => {
        setHealthTabValue(newValue)
        changeTabInLayout(newValue, panelID)
    }    

    const getDate = () => {
      var date = new Date()
      var month = date.getMonth()+1
      var day = date.getDate()
      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`
    }     

    const removeHealthBox = () => {
      console.log("top of removeListBox and panelID is: " + panelID)
      deleteBox(panelID)
    }    


    if (isLoading) return <CircularProgress />
    return (
      <Box sx={{ display: 'flex', width: 'calc(100% - 10px)', marginLeft: '5px' }}>
        <Stack direction="column" display="flex" alignItems='left' sx={{width: '100%'}}>
          <Stack direction="row" display="flex" alignItems='center' justifyContent='space-between' sx={{marginTop: '5px', marginRight: '5px'}}>
            <Stack direction="row" display="flex" alignItems='center' justifyContent='flex-start' >
                <Typography display="inline" sx={{fontWeight: 400}} color={theme.palette.grey[300]}>{"Bitcoin Health as of : " + getDate()}</Typography>
            </Stack>
            <Stack direction="row" justifyContent='flex-end'>  
              <VscMove 
                  className='draggable-handle' 
                  color={theme.palette.warning.dark} 
                  size={18} 
                  style={{cursor: 'grabbing'}}
              />
              <MdCancel 
                  className='draggable-cancel' 
                  color={theme.palette.secondary.dark} 
                  size={18} 
                  onClick={removeHealthBox}
                  style={{cursor: 'pointer'}}
              />
            </Stack>
          </Stack>   
          <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>         
          <Box sx={{width: '100%'}}>
            <TabContext value={healthTabValue} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList 
                        onChange={handleTabChange} 
                        aria-label="" 
                        indicatorColor="secondary" 
                        textColor="primary" 
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="detail" value="1" sx={{minWidth: '15px'}} />
                        <Tab label="RES" value="2" sx={{minWidth: '15px'}} />
                        <Tab label="ELR" value="3" sx={{minWidth: '15px'}} />
                        <Tab label="S2F" value="4" sx={{minWidth: '15px'}} />
                        <Tab label="MIN RES/FL" value="5" sx={{minWidth: '15px'}} />
                        <Tab label="NETF" value="6" sx={{minWidth: '15px'}} />
                        <Tab label="STAB NETF" value="7" sx={{minWidth: '15px'}} />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <HealthDisplayDefault 
                            ruleThemAll={ruleThemAll}
                            btcData={btcData}
                        />
                    </Box>
                </TabPanel>  
                <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <HealthDisplayReserve 
                            ruleThemAll={ruleThemAll}
                        />
                    </Box>
                </TabPanel>    
                <TabPanel value="3" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <HealthDisplayELR 
                            ruleThemAll={ruleThemAll}
                        />
                    </Box>
                </TabPanel>      
                <TabPanel value="4" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <HealthDisplayStockToFlow 
                            ruleThemAll={ruleThemAll}
                        />
                    </Box>
                </TabPanel>                     
                <TabPanel value="5" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <HealthDisplayMinerReserve 
                            ruleThemAll={ruleThemAll}
                        />
                    </Box>
                </TabPanel>   
                <TabPanel value="6" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <HealthDisplayNetflow 
                            ruleThemAll={ruleThemAll}
                        />
                    </Box>
                </TabPanel>                    
                <TabPanel value="7" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <HealthDisplayStableCoinNetflow 
                            ruleThemAll={ruleThemAll}
                        />
                    </Box>
                </TabPanel>  
            </TabContext>
          </Box>
        </Stack>
      </Box>
    )
}

export default HealthDisplay