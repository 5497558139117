import React, { useState, useEffect } from 'react'
import { Box, Grid, Pagination, Stack, Typography, Divider, CircularProgress } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch'

import InfluencerTwitterPost from './InfluencerTwitterPost'

const InfluencerSocialResults = ( { tweetCoin, showCoinTweetAndChart, tweetQueryString } ) => {

  console.log("......top of InfluencerSocialResults and tweetCoin is: " + tweetCoin)

  const [isLoading,setIsLoading] = useState(true)
  const [tweetCoinResults,setTweetCoinResults] = useState([])
  const [tweetCoinPage,setTweetCoinPage] = useState(1)
  const [tweetCoinPageCount,setTweetCoinPageCount] = useState(1)
  const [tweetCount,setTweetCount] = useState(0)

  const getTweetCoinResults = () => {
    setIsLoading(true)
    
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }

    console.log("+++++++++++++++++++++++++++++top of getTweetCoinResults and tweetQueryString is:")
    console.dir(tweetQueryString)
    if (tweetQueryString.length === 0) {
      //need to add default of no longer that 10 days ago
      var d = new Date()
      d.setDate(d.getDate() - 10)
      tweetQueryString = 'timestamp__gte='+ d.toISOString().split("T")[0]
    }
    ;(async () => {
      //var tweetCoinUrl = `api/v1/feed/twitter/?page=${tweetCoinPage}`
      var tweetCoinUrl = `api/v1/feed/alltweets/?page=${tweetCoinPage}`
      if (tweetCoin !== '') 
        tweetCoinUrl += `&coins=${tweetCoin}`
      if (tweetQueryString !== '')
        tweetCoinUrl += '&' + tweetQueryString
      try {
        console.log("tweetCoinURL is:")
        console.dir(tweetCoinUrl)
        const tweetCoinResponse = await fetchData(tweetCoinUrl, "could not receive all twitter data for influencer tracker", fetchParams).promise
        setTweetCount(tweetCoinResponse.count)
        if (tweetCoinResponse.count > 100) {
          setTweetCoinPageCount(Math.ceil(tweetCoinResponse.count/100))
        } else {
          setTweetCoinPageCount(1)
        }

        setTweetCoinResults(tweetCoinResponse.results)
        
        setIsLoading(false)
      } catch (error) {
        console.log("something is broke getting all twitter coin result in research3: " + error)
      }
    })()      
  }

  useEffect(() => {
    if (tweetCoin && tweetCoin.length > 0) {
        getTweetCoinResults()
    } else {
        setTweetCoinResults([])
    }

  }, [tweetCoin, tweetCoinPage, tweetQueryString])

  const theme = useTheme()

  const changeTweetPage = (event, page) => {
    setTweetCoinPage(page)
  }

  if (isLoading) return <CircularProgress />
  return (
    <Box sx={{height: window.innerHeight - 100, display: 'flex', alignItems: 'flex-start', overflowY: 'auto', scrollbarWidth: 'thin'}} >
      <Grid container >
        <Grid item xs={12}>
            <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" >
              <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around">
                <Typography display="inline" variant="body1" sx={{fontWeight: 700, color: theme.palette.secondary.main, paddingLeft: '20px', marginTop: '20px'}}>{tweetCount}</Typography>
                <Typography display="inline" variant="body1" sx={{fontWeight: 500, color: theme.palette.secondary.main, marginTop: '20px', marginLeft: '3px'}}> coin tweet Results for:</Typography>
                <Typography display="inline" variant="body1" sx={{fontWeight: 700, color: theme.palette.secondary.main, marginTop: '20px', marginLeft: '3px'}}>{tweetCoin}</Typography>
              </Stack>
            </Stack>
        </Grid>
        {tweetCoinResults.map( (tweet, index) =>
          <InfluencerTwitterPost key={"influencertweets"+tweet.display_name+index} twitterItem={tweet} showCoinTweetAndChart={showCoinTweetAndChart} />
        )}
        <Grid item xs={12}>
          <Divider sx={{marginBottom: '15px'}}/>
          <Stack direction="row" display="flex" alignItems="center">
            <Typography variant="button" sx={{marginRight: '20px'}}>Results: {tweetCount} </Typography>
            {tweetCount > 0 ? <Pagination shape="rounded" count={tweetCoinPageCount} page={tweetCoinPage} onChange={changeTweetPage}/> : null }
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default InfluencerSocialResults