import React from 'react'
import { Typography, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const WalletHeader = () => {
    const theme = useTheme()
    return (
        <Grid container>
            <Grid sx={{pl: 8,}} item xs={3}>
                <Typography color={theme.palette.grey[400]}>Coin</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography color={theme.palette.grey[400]}>price</Typography>
            </Grid>
            <Grid item xs={2} sx={{pl: 2}}>
                <Typography color={theme.palette.grey[400]}>#</Typography></Grid>
            <Grid item xs={2}>
                <Typography color={theme.palette.grey[400]}>24h change</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography color={theme.palette.grey[400]}>value</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
        </Grid>
    )
}

export default WalletHeader