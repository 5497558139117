import React, { useState, useEffect } from 'react'
import { Grid, TextField, CircularProgress, IconButton } from '@mui/material'
import fetchData from '../../utility/endpointFetch'
import { Cached } from '@mui/icons-material'

const DisplayCaptcha = ({ captchaKey, handleCaptchaChange, captchaError, captchaValue, refreshCaptcha }) => {
  //console.log("top of DisplayCaptcha and captchaKey is: " + captchaKey)

  const [ loading, setLoading ] = useState(true)
  const [ captchaImage, setCaptchaImage] = useState()

  useEffect(() => {
    setLoading(true)
    /* let defaultOptions = {
      method: 'GET',
      headers: {
        Accept: 'image/png',
      }
    }*/
    if (captchaKey != 0) {
      const defaultOptions = {
        method: 'GET',
        headers: {
          Accept: 'image/png',
        }
      }
      async function fetchCaptchaImage (captchaKey, defaultOptions) {
        let response
        try {
         response = await fetch(`/api/v2/login/captcha/${captchaKey}`, defaultOptions)
            .then(response => response.blob())
            .then( blob => {
              setCaptchaImage( URL.createObjectURL(blob) )
              return blob
            })
            .catch( err => console.error(err))
          //let image = await response.body
          //console.log("response from /login/captcha is: ")
          //console.dir(response)
          //await new Promise(r => setTimeout(r, 2000));
          return
          //console.dir(image)
        } catch {
          //console.log('Unhandled Exception')
          return Promise.reject('Something bad happened on the server.  You didn\'t get an HTTP response.')
        }
      }
      fetchCaptchaImage(captchaKey, defaultOptions)
      setLoading(false)

    } else {
      //do nothing as this will not display
    }
  }, [captchaKey])

  if (captchaKey == 0) {
    return null
  } else {
    if (loading) {
      return <CircularProgress />
    } else {
      return (
        <Grid container >
          <Grid item xs={8} sx={{marginTop: '15px'}}>
            <img src={captchaImage} alt="captcha image" />
            <IconButton sx={{verticalAlign: 'bottom'}} size="medium" aria-label="refresh captcha" color="primary" onClick={refreshCaptcha}>
              <Cached fontSize="large"/>
            </IconButton>
          </Grid>
          <Grid item xs='4'>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='captcha_value'
              label='Captcha Value'
              type='text'
              id='captcha_value'
              value={captchaValue}
              autoComplete='insert captcha value'
              error={captchaError}
              onChange={handleCaptchaChange}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
        </Grid>
      )
    }
  }
}

export default DisplayCaptcha
