import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import { useTheme } from '@mui/material/styles'



/**
 * The Token Whitepaper page for the TokenWizard
 * @param {*} - All variables for this page exist in the parent component.
 * @returns 
 */
const TokenWizardHelperText = ({title, text}) => {
    const theme = useTheme()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: theme.palette.dark[900],
        border: '2px solid theme.palette.primary.dark',
        borderRadius:  "8px",
        boxShadow: 24,
        p: 4,
      };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return(
        <div style={{marginLeft: 25}}>
            <IconButton sx={{alignSelf: 'center', ml: 5}} color='primary' aria-label='learn-more-button' onClick={handleOpen}>
                <InfoIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {text}
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export default TokenWizardHelperText;