import React, { useState, useEffect } from 'react'
import { Box, Stack, Chip } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const CurrencyListTimeChoice = ( { changeChartTimeFrame, currentTimeFrame } ) => {
  {/*

  useEffect(() => {

  }, [])
  */}

  const theme = useTheme()

  const unSelectedTime = {
    borderRadius: '12px',
    background: theme.palette.primary.light, //theme.palette.grey[300],  //grey[300]  theme.palette.secondary.light
    color: theme.palette.primary.dark, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
    '&:hover': {
      background: theme.palette.primary.dark, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
      color: theme.palette.primary.light, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
    }
  }

  const selectedTime = {
    borderRadius: '12px',
    background: theme.palette.primary.dark, //theme.palette.grey[300],  //grey[300]  theme.palette.secondary.light
    color: theme.palette.primary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
    '&:hover': {
      background: theme.palette.primary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
      color: theme.palette.primary.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
    }
  }

  //console.log("top of CurrencyListTimeChoice")
  //return null for now until work out which timeframes will use by default
  return null
  return (
    <Box sx={{width: '100%',  paddingBottom: '3px'}}>
      <Stack
        direction="row"
        spacing={2}
        sx={{paddingBottom: '10px'}}
      >
        <Chip
          label="1d"
          color="primary"
          sx={currentTimeFrame == 1 ? selectedTime : unSelectedTime}
          onClick={() => changeChartTimeFrame(1)}
        />
        <Chip
          label="1w"
          color="primary"
          sx={currentTimeFrame == 7 ? selectedTime : unSelectedTime}
          onClick={() => changeChartTimeFrame(7)}
        />
        <Chip
          label="2w"
          color="primary"
          sx={currentTimeFrame == 14 ? selectedTime : unSelectedTime}
          onClick={() => changeChartTimeFrame(14)}
        />
        <Chip
          label="1m"
          color="primary"
          sx={currentTimeFrame == 30 ? selectedTime : unSelectedTime}
          onClick={() => changeChartTimeFrame(30)}
        />
      </Stack>
    </Box>
  )
}

export default CurrencyListTimeChoice