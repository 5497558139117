import React from 'react'
import { TextField } from '@mui/material'

const DisplayOTPassword = ({ OTPassword, handleOTPasswordChange, OTPError, forceOTPUse }) => {
  //console.log("top of DisplayOTPassword and OTPassword is: " + OTPassword)

  if (forceOTPUse) {
    return (
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        name='ot_password'
        label='One Time Password'
        type='text'
        id='ot_password'
        value={OTPassword}
        autoComplete='insert OTP received by email'
        error={OTPError}
        onChange={handleOTPasswordChange}
        inputProps={{ maxLength: 100 }}
      />
    )
  } else {
    return null
  }
}

export default DisplayOTPassword
