import React from "react"
import { useGauge } from "use-gauge"
//import { polarToCartesian } from "use-gauge/dist/lib"
import { useTheme } from '@mui/material/styles'

const QuickFearAndGreedGauge2 = ( { fearAndGreedValue } ) => {

    //console.log("top of QuickFearAndGreedGauge2")
    //console.log("fearAndGreedValue is: " + fearAndGreedValue)
    //console.dir(fearAndGreedValue)

    fearAndGreedValue = parseInt(fearAndGreedValue)

    const theme = useTheme()
      
    const START_ANGLE = 45;
    const END_ANGLE = 315;
      
    const gauge = useGauge({
        domain: [0, 100],
        startAngle: START_ANGLE,
        endAngle: END_ANGLE,
        numTicks: 31,
        diameter: 70
    });

    const localDegreesToRadians = (degrees) => {

        //console.log("degrees * Math.PI is: " + degrees * Math.PI)
        //return (degrees * Math.PI) / 180;
        return (degrees * Math.PI)
      }

    const localPolarToCartesian = (
        centerX,
        centerY,
        radius,
        angleInDegrees
      ) => {
        const angleInRadians = localDegreesToRadians(angleInDegrees);
      
        return {
          x: centerX + radius * Math.cos(angleInRadians),
          y: centerY + radius * Math.sin(angleInRadians),
        };
      }

      const valueToAngle = (value) => {
        const angleRange = END_ANGLE - START_ANGLE;
        const valueRange = 100 - 0;
        const angle = START_ANGLE + ((value - 0) / valueRange) * angleRange;
        return Math.round(angle);
      };      

    const localGetNeedleProps = ( {value, baseRadius, tipRadius} ) => {
        //console.log("value in localGetNeedleProps: ")
        //console.dir(value)
        
        //console.log("valueToAngle(value) is: ")
        //console.dir(valueToAngle(value))
        const angle = valueToAngle(value);

        const baseCircleCenter = {
          x: 0,
          y: 0,
        };
  
        const tipCircleCenter = localPolarToCartesian(0, 0, 35, parseInt(fearAndGreedValue) + 90);

        //console.log("angle is: " + angle)
        //console.log("localDegreesToRadians(angle) is " + localDegreesToRadians(angle))
        //console.log("Math.cos(localDegreesToRadians(angle)) is: " + Math.cos(localDegreesToRadians(angle)))
        //console.log("baseRadius * Math.cos(localDegreesToRadians(angle)) is: " + baseRadius * Math.cos(localDegreesToRadians(angle)))
        //console.log("points x1 is: ")
        //console.log(baseCircleCenter.x + baseRadius * Math.cos(localDegreesToRadians(angle)))
  
        return {
          base: {
            r: baseRadius,
            cx: baseCircleCenter.x,
            cy: baseCircleCenter.y,
          },
          tip: {
            r: tipRadius,
            cx: tipCircleCenter.x,
            cy: tipCircleCenter.y,
          },
          points: [
            [
              baseCircleCenter.x + baseRadius * Math.cos(localDegreesToRadians(angle)),
              baseCircleCenter.y + baseRadius * Math.sin(localDegreesToRadians(angle)),
            ],
            [
              tipCircleCenter.x + tipRadius * Math.cos(localDegreesToRadians(angle)),
              tipCircleCenter.y + tipRadius * Math.sin(localDegreesToRadians(angle)),
            ],
            [
              tipCircleCenter.x + tipRadius * (Math.cos(localDegreesToRadians(angle) -180)),
              tipCircleCenter.y + tipRadius * (Math.sin(localDegreesToRadians(angle) - 180)),
            ],
            [
              baseCircleCenter.x + baseRadius * (Math.cos(localDegreesToRadians(angle) - 180)),
              baseCircleCenter.y + baseRadius * (Math.sin(localDegreesToRadians(angle) - 180)),
            ],
          ]
            .map(([x, y]) => `${x},${y}`)
            .join(' '),
        };        
    }

    
    const { tip, base, points } = localGetNeedleProps({
        value: fearAndGreedValue,
        baseRadius: 10,
        tipRadius: 2
    });
    //console.log("tip is:")
    //console.dir(tip)
    //console.log("base is:")
    //console.dir(base)
    //console.log("points is:")
    //console.dir(points)        
    //console.log("gauge is: ")
    //console.dir(gauge)

    var polarToCartesianResults = localPolarToCartesian(0, 0, 35, fearAndGreedValue + 90)
    //console.log("localPolarToCartesian.x: ")
    //console.dir(polarToCartesianResults.x)
    //console.log("localPolarToCartesian.y: ")
    //console.dir(polarToCartesianResults.y)

    var greedAndFearColor = '#b00c0c'
    if ((fearAndGreedValue >= 20) && (fearAndGreedValue < 40)) {
      greedAndFearColor = '#c7620a'
    } else if ((fearAndGreedValue >= 40) && (fearAndGreedValue < 60)){
      greedAndFearColor = '#c4c70a'
    } else if ((fearAndGreedValue >= 60) && (fearAndGreedValue < 80)) {
      greedAndFearColor = '#c7ab0a'
    } else if (fearAndGreedValue >= 80) {
      greedAndFearColor = '#0ac729'
    }    
    
    return (
      <div style={{padding: '4', margin: '20'}}>
        <svg style={{width: '100%', overflow: 'visible', padding: '2'}} {...gauge.getSVGProps()}>
            <g id="arcs">
            <path
                {...gauge.getArcProps({
                offset: 20,
                startAngle: START_ANGLE,
                endAngle: END_ANGLE
                })}
                fill="none"
                style={{stroke: '#e0e0e0'}} //300
                strokeLinecap="round"
                strokeWidth={10}
            />
            <path
                {...gauge.getArcProps({
                offset: 20,
                startAngle: START_ANGLE,
                endAngle: gauge.valueToAngle(fearAndGreedValue)
                })}
                fill="none"
                style={{stroke: greedAndFearColor}}
                strokeLinecap="round"
                strokeWidth={10}
            />
            </g>
            <g id="ticks">
            {gauge.ticks.map((angle) => {
                const asValue = gauge.angleToValue(angle);
                const showText = asValue === 0 || asValue === 20 || asValue === 40 || asValue === 60 || asValue === 80 || asValue === 100;
    
                return (
                <React.Fragment key={`tick-group-${angle}`}>
                    <line
                    style={{stroke: '#e0e0e0'}} //300
                    strokeWidth={2}
                    {...gauge.getTickProps({ angle, length: showText ? 12 : 6 })}
                    />
                    {showText && (
                    <text
                        style={{fontSize: '0.675rem', lineHeight: '1.1rem', fill: '#9e9e9e', fontWeight: 500}}
                        {...gauge.getLabelProps({ angle, offset: -35 })}
                    >
                        {asValue}
                    </text>
                    )}
                </React.Fragment>
                );
            })}
            </g>
            <g id="value">
                <text
                    style={{fontSize: '1.675rem', lineHeight: '1.8rem', fill: greedAndFearColor, fontWeight: 700}} x='-15' y='10'
                >
                    {fearAndGreedValue}
                </text>
            </g>
        </svg>
      </div>
    )

}

export default QuickFearAndGreedGauge2