import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import TokenWizardHelperText from "./TokenWizardHelperText";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import React from "react";
import { useTheme } from '@mui/material/styles'

const TokenReleases = ({pageData, setPageData}) => {

    const theme = useTheme();

    const handleHasMonthlyReleaseDateChange = (e) => {
        setPageData({
            ...pageData,
            hasSpecificReleaseDate: e.target.value,
            showTokenReleaseError: false,
        });
    }
    const handleHasDailyReleaseDateChange = (e) => {
        setPageData({
            ...pageData,
            hasDailyReleaseDate: e.target.value,
            showTokenReleaseError: false,
        });
    }
    const handleHasReleaseEveryMonthChange = (e) => {
        setPageData({
            ...pageData,
            hasReleaseEveryMonth: e.target.value,
            showTokenReleaseError: false,
        });
    }
    const handleIsTokenReleasePublishedChange = (e) => {
        setPageData({
            ...pageData,
            isTokenReleasePublished: e.target.value,
            showTokenReleaseError: false,
        });
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant="h2" sx={{color: theme.palette.primary.main, marginTop: '20px'}}>Token Release Schedule</Typography>
            <Stack direction="row" display='flex' alignItems='center'>
                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500] }}>
                    Are there token releases on any kind of schedule - Monthly, Daily, Unknown?
                </Typography>
                <TokenWizardHelperText
                    title={'Token Release'}
                    text={'Many projects have a specific date when tokens are generated. If a project releases new tokens monthly (1 day of the month), and the TGE date was 6 June, then each month on the 6th is when new tokens will be released. Some projects release tokens every single day to lessen the impact of more tokens in circulation (ie. more supply = less demand).\nNote: The Details page of the project will show this info once uploaded.'} />
            </Stack>
            <Typography align='center' variant="h4" sx={{ mt: 5, color: theme.palette.grey[500] }}>
                Is the token release schedule published?
            </Typography>
            <FormControl sx={{mt: 0}}>
                <RadioGroup
                    column={'true'}
                    aria-labelledby="tradepost-radio-buttons-group"
                    name="tradepost-radio-buttons-group"
                    value={pageData.isTokenReleasePublished}
                    onChange={handleIsTokenReleasePublishedChange}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>

            {
                (pageData.isTokenReleasePublished === 'true' || pageData.isTokenReleasePublished === true) && (
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography align='center' variant="h4" sx={{ mt: 5, mb: 3, color: theme.palette.grey[500] }}>
                            Are tokens released every month on a specific day of the month?
                        </Typography>
                        <FormControl sx={{mt: 0, mb: 2}}>
                            <RadioGroup
                                column={'true'}
                                aria-labelledby="tradepost-radio-buttons-group"
                                name="tradepost-radio-buttons-group"
                                value={pageData.hasSpecificReleaseDate}
                                onChange={handleHasMonthlyReleaseDateChange}
                            >
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                    {
                                        pageData.hasSpecificReleaseDate === 'Yes' && (
                                            <Box sx={{width: 350}}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        label="Monthly Release"
                                                        views={['day']}
                                                        inputFormat="yyyy-MM-dd"
                                                        value={pageData.tokenReleaseDate}
                                                        onChange={(newValue) => {
                                                            //console.log("top of onChange for desktopdatepicker")
                                                            setPageData({
                                                                ...pageData,
                                                                tokenReleaseDate: newValue
                                                            })
                                                        }}
                                                        variant='outlined'
                                                        mask="____-__-__"
                                                        color="white"
                                                        renderInput={
                                                            (params) => <TextField {...params} />
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                        )
                                    }
                                </Box>
                                <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                <FormControlLabel value={"Unknown"} control={<Radio />} label="Unknown" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                )
            }

            {
                (pageData.isTokenReleasePublished === 'true' || pageData.isTokenReleasePublished === true) && pageData.hasSpecificReleaseDate !== "Yes" && (
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography align='center' variant="h4" sx={{ mt: 5, color: theme.palette.grey[500] }}>
                            Are tokens released every day?
                        </Typography>
                        <FormControl sx={{mt: 0}}>
                            <RadioGroup
                                column={'true'}
                                aria-labelledby="tradepost-radio-buttons-group"
                                name="tradepost-radio-buttons-group"
                                value={pageData.hasDailyReleaseDate}
                                onChange={handleHasDailyReleaseDateChange}
                            >
                                <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                <FormControlLabel value={"Unknown"} control={<Radio />} label="Unknown" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                )
            }
            {
                (pageData.isTokenReleasePublished === 'true' || pageData.isTokenReleasePublished === true) && pageData.hasSpecificReleaseDate === "Yes" && (
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography align='center' variant="h4" sx={{ mt: 5, color: theme.palette.grey[500] }}>
                            Are tokens released every month?
                        </Typography>
                        <FormControl sx={{mt: 0}}>
                            <RadioGroup
                                column={'true'}
                                aria-labelledby="tradepost-radio-buttons-group"
                                name="tradepost-radio-buttons-group"
                                value={pageData.hasReleaseEveryMonth}
                                onChange={handleHasReleaseEveryMonthChange}
                            >
                                <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                <FormControlLabel value={"Unknown"} control={<Radio />} label="Unknown" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                )
            }
            {
                pageData.showTokenReleaseError === true && (
                    <Typography align='center' variant="h3" sx={{ mt: 1, color: theme.palette.error.main }}>
                        Please fill in a date or select No.
                    </Typography>
                )
            }
        </Box>
    )
}

export default TokenReleases;
