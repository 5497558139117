import React from 'react';
import cover from './cover.jpg'

const Main = () => {

    return (

        <div 
            style={{
                backgroundImage: 'url(pic.jpg)', 
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                display: 'grid',
                height: '80vh',
                gridTemplateRows: '.1fr .1fr .1fr',
                gridTemplateAreas: 'maintext subline start',             
            }} 
        >
            
            <div 
                style={{    
                    fontSize: '2vw',
                    marginTop: '300px',
                    marginLeft: '50px',
                    color: 'white',
                    gridArea: 'maintext',
                }}    
            >
                <h1>Artificial Intelligence Powering Investment</h1>
            </div>

            <div 
                style={{
                    fontSize: '1vw',
                    marginLeft: '50px',
                    color: 'white',
                    gridArea: 'subline',
                }}    
            >
                <p>Harness the power of intelligent networks to catapult your profits.</p>
            </div>

            <div 
                style={{
                    marginTop: '50px',
                    marginLeft: '50px',
                    gridArea: 'start',                     
                }}    
            >
                <a href="https://alphacentoro.com/register" target="_blank" rel="noopener noreferrer">
                    <button 
                        style={{
                            fontSize: '1vw',
                            marginTop: '40px',
                            backgroundColor: 'black',
                            padding: '1rem 5rem',
                            borderRadius: '15px', 
                        }}    
                    ><span style={{color: 'white'}}>Start Your AI Journey</span></button>
                </a>
            </div>
        </div>
    );
}

export default Main;       