import React, { useEffect, useState } from 'react'
import { Grid, Box, CircularProgress, Typography, Stack, Pagination, Button } from '@mui/material'

import InfluencerBodyResultItem from './InfluencerBodyResultItem'

import fetchData from '../../utility/endpointFetch'
import { useTheme } from '@mui/material/styles'
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CloseIcon from "@mui/icons-material/Close"

const InfluencerBodyResults = ( {showCoinTweetAndChart, coinQueryString, researchCustom=false}) => {
    //sole.log("top of InfluencerBodyResults")
    //console.log("coinQueryString is:")
    //console.dir(coinQueryString)
    const theme = useTheme()

    const [isLoading,setIsLoading] = useState(true)
    const [aggregateCoinResults,setAggregateCoinResults] = useState([])
    const [aggregateCoinDisplayResults,setAggregateCoinDisplayResults] = useState([])
    const [coinDetails,setCoinDetails] = useState([])
    const [aggregateCoinResultsPage,setAggregateCoinResultsPage] = useState(1)
    const [aggregateCoinResultsCount,setAggregateCoinResultsCount] = useState(0)
    const [aggregateCoinResultsPageCount,setAggregateCoinResultsPageCount] = useState(1)

    const [feedOrdering, setFeedOrdering] = useState({
        ascending: false,
        field: 'sort_total_tweets'
    })

    const getCoinResults = (page=aggregateCoinResultsPage) => {
        //set it false incase past search query had DATE and possibly this one does not
        console.log(" --------------top of getCoinResults and coinQueryString is:")
        console.dir(coinQueryString)
        setIsLoading(true)
        let queryStringArray = coinQueryString.split('&')
        //console.log("TOP OF getCoinResults and queryStringArray is: ")
        //console.dir(queryStringArray)
        //console.log("coinQueryString is:")
        //console.dir(coinQueryString)
        var checkedQueryStringArray = []

        let dateNotIncluded = true //to make sure ONLY the last 10 days at minimum are included in result
        queryStringArray.map(queryNameValuePair => {
            console.log("queryNameValuePair is: "+ queryNameValuePair)
            let currentPair = queryNameValuePair.split('=')
            if (currentPair[0] === 'timestamp__gte' || currentPair[0] === 'dateInfluencer__gte') dateNotIncluded = false
            console.log("currentPair[0] is: "+ currentPair[0] + " and currentPair[1] is: "+ currentPair[1])
            if (currentPair[0] === 'dateInfluencer__gte') {
                console.log("adding: timestamp__gte="+currentPair[1])
                checkedQueryStringArray.push('timestamp__gte='+currentPair[1])
            } else if (currentPair[0] === 'dateInfluencer__lte') {
                console.log("adding: timestamp__lte="+currentPair[1])
                checkedQueryStringArray.push('timestamp__lte='+currentPair[1])
            } else {
                console.log("adding: " + currentPair[0] + "=" + currentPair[1])
                checkedQueryStringArray.push(queryNameValuePair)
            }
        })
        //needs to be at least timestamp__gte if nothing else to limit the result to last 10 days
        if (dateNotIncluded) {
            console.log("timestamp__gte not included by default so adding")
            var d = new Date()
            d.setDate(d.getDate() - 10)
            checkedQueryStringArray.push('timestamp__gte='+ d.toISOString().split("T")[0])
        }
        var checkedQueryString = checkedQueryStringArray.join('&')


        console.log("checkedQueryString is: ")
        console.dir(checkedQueryString)
                
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }
        ;(async () => {
            try {
                var coinsArray = []
                var displayArray = []
                console.log("aggregateCoinResults before calling to get them is:")
                console.dir(aggregateCoinResults)
                console.log("feedOrdering is:")
                console.dir(feedOrdering)
                console.log("page is: ")
                console.dir(page)
                console.dir(feedOrdering.field)
                console.dir(feedOrdering.field.length)
                if ((aggregateCoinResults.length === 0) || (page === 1)) {
                    //on original calls the feedOrdering will always be default
                    //var offset= (page - 1) * 50 //limit per page...no paging from endpoint
                    var allTweetsAggregateCoinResponseURL = `api/v1/feed/alltweets/?aggregate=true&${checkedQueryString}`
                    if(feedOrdering.field && feedOrdering.field.length){
                        allTweetsAggregateCoinResponseURL += `&${feedOrdering.field}=${feedOrdering.ascending ? 'asc':'desc'}`
                    }

                    const allTweetsAggregateCoinResponse = await fetchData(allTweetsAggregateCoinResponseURL, "could not receive influencer post search results now", fetchParams).promise
                    console.log("coinResponse getting influencer post search results")
                    console.log("allTweetsAggregateCoinResponseURL is: " + allTweetsAggregateCoinResponseURL)
                    console.dir(allTweetsAggregateCoinResponse)

                    if (allTweetsAggregateCoinResponse.count > 0) {
                        setAggregateCoinResults(allTweetsAggregateCoinResponse.facets.coins.buckets)

                        if (allTweetsAggregateCoinResponse.facets.coins.buckets.length > 50) {
                            setAggregateCoinResultsPageCount(Math.ceil(allTweetsAggregateCoinResponse.facets.coins.buckets.length/50))
                        } else {
                            setAggregateCoinResultsPageCount(1)
                        }

                        //only need the first 50 and only take 50 coins for result....need to adjust for paging later
                        for (var i = 0; i < 50; i++) {
                            if (!coinsArray.includes(allTweetsAggregateCoinResponse.facets.coins.buckets[i].key)) {
                                coinsArray.push(allTweetsAggregateCoinResponse.facets.coins.buckets[i].key)
                            }
                            displayArray.push(allTweetsAggregateCoinResponse.facets.coins.buckets[i])

                        }
                        setAggregateCoinDisplayResults(displayArray)
                        showCoinTweetAndChart(coinsArray[0])

                        setAggregateCoinResultsCount(allTweetsAggregateCoinResponse.facets.coins.buckets.length)
                    } else {
                        setAggregateCoinResults([])
                        setAggregateCoinResultsPageCount(0)
                        setAggregateCoinDisplayResults([])
                        showCoinTweetAndChart('bitcoin')
                    }

                } else {

                    console.log("aggregateCoinResults already exists whether using feedOrdering or not......do not need it again unless sorting changes....page is:" + page)

                    //result already there...just use it
                    console.log("offset will start at: " + (page - 1) * 50)
                    console.log("and end at: " + (page * 50))
                    for (var i = ((page - 1) * 50); i < (page * 50); i++) {
                        if (!coinsArray.includes(aggregateCoinResults[i].key)) {
                            coinsArray.push(aggregateCoinResults[i].key)
                        }
                        displayArray.push(aggregateCoinResults[i])
                    }                    
                    setAggregateCoinDisplayResults(displayArray)
                    showCoinTweetAndChart(coinsArray[0])
                }

                

                //need to get the first result and set it as the left chart and left column tweets
                /*if (coinDetailsResponse.count > 0 && researchCustom === false) {
                    let returnCoinForTweetAndChart = coinDetailsResponse.results.filter( (coin,index) => {
                        return coin.coin === allTweetsAggregateCoinResponse.results[0].coin
                    })
                    
                }

                
                  var coinsArray = coinResponse.results.map( (coin, index) => {
                    //console.log("inside getting coinsArray and coin is:")
                    //console.dir(coin)
                    return coin.coin
                })
                */
                console.log("coinsArray is: ")
                console.dir(coinsArray)


                if (coinsArray.length > 0) {
                    let urlCoinString = coinsArray.join(",")

                    //console.log("urlCoinString is: ")
                    ;(async () => {
                        //var coinDetailsURL = `api/v1/coin/coinmarket/?coin_id_in=${urlCoinString}&limit=50&offset=${offset}${sortByUrl}${filterByUrl}`
                        var coinDetailsURL = `api/v1/coin/ecoinscreener/?coins=` + urlCoinString + `&limit=50&offset=0`
                        const coinDetailsResponse = await fetchData(coinDetailsURL, "could not receive coinmarket data", fetchParams).promise
                        console.log("coinDetailsURL is:")
                        console.log(coinDetailsURL)
                        console.log("coinDetailsResponse is:")
                        console.dir(coinDetailsResponse)
                        var coinDetailsArray = []

                        //put ecoinscreener results in an array that can be referened
                        coinDetailsResponse.results.map( (coin,index) => {
                            //console.log("setting coin.coin_market.coin.id to: [" + coin.coin_market.coin.id + "] " + coin.id)
                            coinDetailsArray[coin.coin] = coin
                        })

                        console.log("coinDetailsArray set in coinDetails is:")
                        console.dir(coinDetailsArray)
                    


                        //console.log("coinDetailsArray is:")
                        setCoinDetails(coinDetailsArray)
                        setIsLoading(false)

                    })() 
                } else {
                    console.log("no coins in coinsArray....no need to hit ecoinscreener")
                    setCoinDetails([])
                    setIsLoading(false)
                }

            } catch (error) {
                console.log("could not receive influencer post search results now: ", error)
            }
        })()        
    }

    useEffect(() => {
        console.log("top of useEffect if coinQueryString or feedOrdering changes")
        console.log(coinQueryString)
        getCoinResults()
    }, [coinQueryString, feedOrdering])

    const changeCoinPage =  (event, page) => {
        console.log("top of changeCoinPage")
        console.dir(page)
        setAggregateCoinResultsPage(page)
        getCoinResults(page)
    }

    const setOrdering = (field) => {
        if(feedOrdering.field === field){
            setFeedOrdering({
                ascending: !feedOrdering.ascending,
                field: field
            })
        }else{
            setFeedOrdering({
                ascending: false,
                field: field
            })
        }
    }

    const clearOrdering = () => {
        setFeedOrdering({
            ascending: false,
            field: ''
        })
    }

    const Sorting = () => {
        return (
            <Box>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{mr: 1}}
                    spacing={1}
                >
                    <Typography variant={'h4'} color={'white'}>
                        Sort Feeds by:
                    </Typography>
                    <Button
                        variant={feedOrdering.field === 'sort_total_tweets' ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => setOrdering('sort_total_tweets')}
                        size="small"
                        sx={{
                            borderColor: theme.palette.primary[700],
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 25,
                            borderWidth: 1,
                        }}>
                        <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                            <Typography variant={'h5'} color={'white'}>
                                Total Tweets
                            </Typography>
                            {
                                feedOrdering.field === 'sort_total_tweets' ?
                                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                        {
                                            feedOrdering.ascending ?
                                                <ExpandLessIcon color={'action'} />
                                                :
                                                <ExpandMoreIcon color={'action'} />
                                        }
                                    </Stack>
                                    :
                                    null
                            }
                        </Stack>
                    </Button>     
                    <Button
                        variant={feedOrdering.field === 'sort_social_rank' ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => setOrdering('sort_social_rank')}
                        size="small"
                        sx={{
                            borderColor: theme.palette.primary[700],
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 20,
                            borderWidth: 1,
                        }}>
                        <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                            <Typography variant={'h5'} color={'white'}>
                                Social Rank
                            </Typography>
                            {
                                feedOrdering.field === 'sort_social_rank' ?
                                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                        {
                                            feedOrdering.ascending ?
                                                <ExpandMoreIcon color={'action'} />
                                                :
                                                <ExpandLessIcon color={'action'} />
                                        }
                                    </Stack>
                                    :
                                    null
                            }
                        </Stack>
                    </Button>
                    <Button
                        variant={feedOrdering.field === 'sort_social_engagement' ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => setOrdering('sort_social_engagement')}
                        size="small"
                        sx={{
                            borderColor: theme.palette.primary[700],
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 25,
                            borderWidth: 1,
                        }}>
                        <Stack direction={'row'} spacing={1}>
                            <Typography variant={'h5'} color={'white'}>
                                Social Engagement
                            </Typography>
                            {
                                feedOrdering.field === 'sort_social_engagement' ?
                                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                        {
                                            feedOrdering.ascending ?
                                                <ExpandMoreIcon color={'action'} />
                                                :
                                                <ExpandLessIcon color={'action'} />
                                        }
                                    </Stack>
                                    :
                                    null
                            }
                        </Stack>
                    </Button>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() => clearOrdering()}
                        size="small"
                        style={{borderRadius: 25, width: 30, height: 30}}
                    >
                        <CloseIcon color={'primary'} size={12}/>
                    </Button>
                </Stack>
            </Box>
        )
    }
    //console.log(coinDetails)
    if (isLoading) return <CircularProgress />
    if (aggregateCoinResultsCount > 0) {
        return (
            <Box sx={{width: '100%', height: window.innerHeight - 400, overflow: 'auto', backgroundColor: theme.palette.background.default}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Typography display="inline" variant="body1" sx={{color: theme.palette.secondary.main, marginLeft: '10px', marginTop: '5px' }}>social influencer results: ({aggregateCoinResultsCount})</Typography>
                            <Sorting/>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}><Typography variant="caption" sx={{marginLeft: '25px'}}>icon / id / symbol</Typography></Grid>
                    {/*<Grid item xs={1}><Typography variant="caption">daily tweets</Typography></Grid>*/}
                    <Grid item xs={1}><Typography variant="caption">total tweets</Typography></Grid>
                    <Grid item xs={1}><Typography variant="caption">avg retweets</Typography></Grid>
                    <Grid item xs={1}><Typography variant="caption">avg fav</Typography></Grid>
                    <Grid item xs={1}><Typography variant="caption">social rank</Typography></Grid>
                    <Grid item xs={1}><Typography variant="caption">social engmt</Typography></Grid>
                    <Grid item xs={2}><Typography variant="caption">price/24hr</Typography></Grid>
                    <Grid item xs={1}><Typography variant="caption">% Diff Btc</Typography></Grid>
                    <Grid item xs={1}><Typography variant="caption">market cap</Typography></Grid>                    
                    <Grid item xs={12}>
                        {/* put top link here */}
                        {aggregateCoinDisplayResults.map( (coinInfluencerDetails, index) => {
                            //console.log("coinInfluencerDetails.key is: ")
                            //console.dir(coinInfluencerDetails.key)
                                return (
                                    <InfluencerBodyResultItem
                                        key={'influencerbodycoinresultsitem' + coinInfluencerDetails.key + index}
                                        influencerDetails={coinInfluencerDetails}
                                        coinDetails={coinDetails[coinInfluencerDetails.key]}
                                        showCoinTweetAndChart={showCoinTweetAndChart}/>
                                )
                            }
                        )}
                    </Grid>  
                                  
                </Grid>
                <Stack direction="row" display="flex" alignItems="center">
                    <Typography variant="button" sx={{marginRight: '20px'}}>Results: {aggregateCoinResultsCount} </Typography>
                    <Pagination sx={{marginTop: '10px'}} shape="rounded" count={aggregateCoinResultsPageCount} page={aggregateCoinResultsPage} onChange={changeCoinPage}/>
                </Stack>
            </Box>
        )
    } else {
        return (
            
            <Box sx={{width: '100%', height: window.innerHeight - 400, overflow: 'auto', backgroundColor: theme.palette.background.default, }}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography display="inline" sx={{color: theme.palette.secondary.main, marginLeft: '10px', marginTop: '10px'}}>tcoin results from filtered coins: (0)</Typography> 
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{justifyContent: 'center', marginLeft: '10px', marginTop: '15px'}}>
                            <Typography display="inline" sx={{color: theme.palette.primary.main}}>Use the search section to the right to find coins and display their results here</Typography>
                        </Box>
                    </Grid>
                </Grid>
                
            </Box>
            
        )
    }        
}

export default InfluencerBodyResults