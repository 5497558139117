import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Divider, Grid, Modal, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CurrencyListFilter from '../CurrencyListFilter'
//import CurrencyListTimeChoice from '../CurrencyListTimeChoice'
import CurrencyListSearch from './CurrencyListSearch'

const CurrencyFilterRow = ( {filterCurrencyList, categoryList, clearCategoryFilter, categoryChosen, currentTimeFrame, changeChartTimeFrame, clearSearch, searchCoins, visibleColumns, setColumns} ) => {
  
  const theme = useTheme()
  const medScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"))
  
  const [open, setOpen] = React.useState(false);
  const [numActiveColumns, setNumActiveColumns] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const MAX_COLUMNS = 9

  useEffect(() => {
    let active = 0
    for(let [k, v] of Object.entries(visibleColumns)){
      if(v) active++;
    }
    setNumActiveColumns(active)
  }, [visibleColumns])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
    maxHeight: 600,
  };
  const handleChecked = (column, event) => {
    const curr = {...visibleColumns}
    if(numActiveColumns >= MAX_COLUMNS && event.target.checked){
      return
    }
    curr[column] = event.target.checked
    setColumns(curr);
  };

  if (medScreenOrSmaller) {

    return (
      <Stack direction="row" alignItems='center' justifyContent='space-between'>
        <CurrencyListFilter 
          filterCurrencyList={filterCurrencyList} 
          categoryList={categoryList} 
          clearCategoryFilter={clearCategoryFilter} 
          categoryChosen={categoryChosen} 
        />
        <CurrencyListSearch clearSearch={clearSearch} searchCoins={searchCoins} />
      </Stack>
    )
  } else {

    return (
      <Grid container spacing={1}
        sx={{ flex: 1, width: '100%'}}
      >
        <Grid item xs={12} md={6} lg={6}>
          <CurrencyListFilter 
            filterCurrencyList={filterCurrencyList} 
            categoryList={categoryList} 
            clearCategoryFilter={clearCategoryFilter} 
            categoryChosen={categoryChosen} 
          />
        </Grid>
        {/* component currently returns null <Grid item xs={6} md={6} lg={4}>
          <CurrencyListTimeChoice changeChartTimeFrame={changeChartTimeFrame} currentTimeFrame={currentTimeFrame} />
        </Grid> */}
        <Grid item xs={12} md={6} lg={6}>
          <Stack direction={'row'} alignItems='center' justifyContent='flex-end'>
            <CurrencyListSearch clearSearch={clearSearch} searchCoins={searchCoins} />
            <Tooltip title="Add/Remove Columns" >
              <Button onClick={() => handleOpen()} sx={{mb: 2}}>
                Customize
              </Button>
            </Tooltip>
          </Stack>
        </Grid>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Stack direction={'column'}>
            <Stack direction={'column'} sx={style}>
              <Stack spacing={1} direction={'row'} justifyContent={'center'} alignItems={'center'}>
                <Typography sx={{fontSize: 16}}>Columns Visible:  </Typography>
                <Stack direction={'row'} spacing={1}>
                  <Typography sx={{color: '#1DA1F2', fontSize: 18}}>(</Typography>
                  <Typography sx={{color: numActiveColumns >= MAX_COLUMNS ? '#1DA1F2' : '#fff', fontSize: 18}}>{numActiveColumns}</Typography>
                  <Typography sx={{color: '#1DA1F2', fontSize: 18}}>/</Typography>
                  <Typography sx={{color: '#1DA1F2', fontSize: 18}}>{MAX_COLUMNS}</Typography>
                  <Typography sx={{color: '#1DA1F2', fontSize: 18}}>)</Typography>
                </Stack>
              </Stack>
              <Divider />
              {
                Object.entries(visibleColumns).map(([k,v], index) => {
                  return (
                    <Stack key={k +"-"+v} direction={'row'} alignItems={'center'}>
                      <Checkbox onChange={(e) => handleChecked(k, e)} checked={v} />
                      <Typography>{k}</Typography>
                    </Stack>
                  )
                })
              }
            </Stack>
          </Stack>
        </Modal>
      </Grid>
    )
  }
}

export default CurrencyFilterRow