import React, { useEffect, useState} from 'react'
import { CircularProgress, Grid } from '@mui/material'

import fetchData from '../../utility/endpointFetch'
import VIPMainTabsFeedHotSearchesItem from './VIPMainTabsFeedHotSearchesItem'

const VIPMainTabsFeedHotSearches = ( {} ) => {

    console.log("top of VIPMainTabsFeedHotSearches")

    const [vipSearchFeedList,setVIPSearchFeedList] = useState([])
    const [isLoading,setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        //the popover was just opened...get the current saved searches

        const getVIPSearchFeedParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }          
        ;(async () => {
            try {
                let getVIPFeedHotSearchesURL = `api/v1/research/savedsearch/`
                const getVIPFeedHotSearchesResponse = await fetchData(getVIPFeedHotSearchesURL, "could not receive current searchfeed hot searches", getVIPSearchFeedParams).promise
                console.log("getVIPFeedHotSearchesResponse getting current searchfeed hot searches")
                console.log(getVIPFeedHotSearchesURL)
                console.dir(getVIPFeedHotSearchesResponse)

                setVIPSearchFeedList(getVIPFeedHotSearchesResponse.results)
            } catch (error) {
                console.log("something is broke receiving current searchfeed hot searches: ", error)
            }
            setIsLoading(false)
        })()  
    }, [])        
    
    if (isLoading) return <CircularProgress />

    return (
        <Grid container>
            {vipSearchFeedList.map( (searchFeedItem,index) => 
                <VIPMainTabsFeedHotSearchesItem 
                    key={"hotsearches"+index} 
                    searchFeedItem={searchFeedItem} 
                />)
            }
        </Grid>
    )
}

export default VIPMainTabsFeedHotSearches