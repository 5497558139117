import React, {useState} from 'react'
import { Typography, Stack, Box, IconButton, Popover, Paper, TextField, Tooltip, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { TbLayoutGrid } from 'react-icons/tb'
import { MdDelete } from 'react-icons/md'
import { IconSquareX } from '@tabler/icons'

import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'

const ResearchCustomDeleteLayout = ( {deleteLayout, layoutList} ) => {
    //console.log("top of ResearchCustomDeleteLayout and layoutList is:")
    //console.dir(layoutList)

    const theme = useTheme()
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

    const [deleteOpen,setDeleteOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const displayLayouts = layoutList.filter((layout) => layout !== 'default') //do not allow to delete default or 0

    const handleDeleteClicked = (layoutRemoving) => {
        deleteLayout(layoutRemoving)
        handleClose()
    }

    const handleClick = (event) => {
        //console.log("top of handleClick and event is:")
        //console.dir(event)
        setAnchorEl(event.currentTarget)
        setDeleteOpen((prevOpen) => !prevOpen)
    }    

    const handleClose = () => {
        //console.log("top of handleClose")
        setAnchorEl(null)
        setDeleteOpen((prevOpen) => !prevOpen)
    }     

    const id = deleteOpen ? 'delete-popover' : undefined   

    return (
        <Box sx={{ display: 'flex', marginLeft: '5px'}}>
            <span 
                sx={{
                    '&:hover': {
                        color: theme.palette.success.dark,
                    },
                    cursor: 'pointer',
                }}
            >
                <TbLayoutGrid  
                    aria-describedby={id}
                    onClick={handleClick}
                    color={theme.palette.error.main}
                    sx={{
                        

                    }}
                    size={25}
                />    
            </span> 
            <Popover
                id={id}
                open={deleteOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 400, left: 1200 }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <Transitions position={matchesXs ? 'top' : 'top-right'} in={deleteOpen} >
                <Paper>
                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '500px', minHeight: '150px', padding: '15px', backgroundColor: theme.palette.dark[800]}}>
                        <Box sx={{width: '100%'}}>
                            <Stack direction="column" display="flex" alignItems="flex-start" sx={{width: '100%'}}>
                                <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
                                    <Typography variant='h2' sx={{color: theme.palette.primary.main}}>Delete Dashboard Layout</Typography>
                                    <IconButton
                                        aria-label="close"
                                        sx={{color: theme.palette.error.main}}
                                        onClick={handleClose}>
                                        <IconSquareX sx={{ marginTop: '15px'}}/>
                                    </IconButton>  
                                </Stack>

                                <Box sx={{width: '100%', padding: '5px', paddingBottom: '0px'}}>
                                    <Stack 
                                        direction="column" 
                                        display="flex" 
                                        alignItems="center"
                                        justifyContent='space-around'
                                    >

                                        {displayLayouts.map( (thisLayout,thisLayoutIndex) => 
                                            <Stack 
                                                key={thisLayoutIndex}
                                                direction="row"                                         
                                                display="flex" 
                                                alignItems="center"
                                                justifyContent='space-around'
                                                sx={{cursor: 'pointer'}}
                                            >                                                    
                                                <Typography>{thisLayout}</Typography>
                                                <MdDelete 
                                                    onClick={() => handleDeleteClicked(thisLayout)}
                                                    color={theme.palette.error.main}
                                                    sx={{
                                                        marginLeft: '5px',
                                                    }}
                                                    size={25}
                                                />
                                            </Stack>
                                        )}
                                    </Stack>    
                                </Box>                                
                            </Stack>
                        </Box>        
                    </MainCard>
                </Paper>
                </Transitions>    
            </Popover>                         
        </Box>     
    )
}
export default ResearchCustomDeleteLayout