import React from 'react'
import { Grid, Stack } from '@mui/material'
import { IconPlus } from '@tabler/icons'
import { useTheme } from '@mui/material/styles'

const ResearchGeneralCommentaryCoinListItem = ( { coin, addCoinToCoinsCorrelated } ) => {
    const theme = useTheme()
    return (
        <Grid item xs={12} sx={{cursor: 'pointer'}} onClick={() => addCoinToCoinsCorrelated(coin.coin.id)}><Stack direction="row" display="flex" alignItems="center">{coin.name} ({coin.coin.id}) <IconPlus size={12} color={theme.palette.success.dark} sx={{marginLeft: '10px'}} /></Stack></Grid>
    )
}

export default ResearchGeneralCommentaryCoinListItem