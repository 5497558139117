import React, { useState, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  ButtonGroup,
  Button,
  Stack,
  Chip,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import Chart from "react-apexcharts"
import fetchData from '../../utility/endpointFetch'


import CoinDetailsChartPriceAlert from './CoinDetailsChartPriceAlert';

const CoinDetailsChart = ( {currencyDetails, setFirstPrice, setLastPrice, userObject, medScreenOrSmaller=false} ) => {
  const theme = useTheme()
  console.log("top of CoinDetailsChart")
  console.log(currencyDetails)

  const [ isLoading, setIsLoading ] = useState(true)
  const [ mixedChartOptions, setMixedChartOptions ] = useState()
  const [ mixedChartSeries, setMixedChartSeries ] = useState()

  const [ chartDays, setChartDays ] = useState(180)
  const [ chartInterval, setChartInterval ] = useState('daily')
  const [ pageSize,setPageSize] = useState(180)
  const chartBaseCurrencySymbol = '$ '
  const chartBaseCurrency = 'USD'
  const [ chartRangeDetails, setChartRangeDetails ] = useState(!medScreenOrSmaller ? '6 months/daily intervals' : '6 m/dy int')

  const [ priceAlertOpen,setPriceAlertOpen] = useState(false)
  const [ priceAlert,setPriceAlert] = useState(0)
  const [ priceAlertError,setPriceAlertError] = useState(false)
  const [ alertMultiple,setAlertMultiple] = useState('')

  const handleChartTimeChange = ( days) => {
    //console.log("top of handleChartTimeChange...days is: ")
    //console.dir(days)
    setChartDays(days)
    if (days > 14) {
      setChartInterval('daily')
      setPageSize(days)
    } else if (days > 3) {
      setChartInterval('hourly')
      setPageSize(days*24)
    } else if (days > 1) {
      setChartInterval('quarter_hour')
      setPageSize(days*96)
    } else {
      setChartInterval('5m')
      setPageSize(days*288)
    }

    switch (days) {
      case 365:
        setChartRangeDetails('1y/daily intervals')
        break;
      case 180:
        setChartRangeDetails('180 days/daily intervals')
        break;
      case 90:
        setChartRangeDetails('90 days/daily intervals')
        break;
      case 30:
        setChartRangeDetails('30 days/daily intervals')
        break;
      case 14:
        setChartRangeDetails('2 weeks/hourly intervals')
        break;
      case 7:
        setChartRangeDetails('1 week /hourly intervals')
        break;
      case 3:
        setChartRangeDetails('3 days/15m intervals')
        break;
      case 1:
        setChartRangeDetails('1 day/5 minute intervals')
        break;
      default:
        //not sure what this is
        setChartRangeDetails('')
    }
  }

  useEffect(() => {

    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        var dateAgo = new Date(Date.now() - (chartDays * 24 * 60 * 60 * 1000))
        //console.log("timestamp used to pull marketchart data: " + dateAgo.toISOString())

        var urlString = `api/v1/coin/marketchart/?coin=${currencyDetails.coin}&interval=${chartInterval}&timestamp__gt=${dateAgo.toISOString()}&page_size=${pageSize}&page=1`
        const chartResponse = await fetchData(urlString, "could not receive marketchart data", fetchParams).promise
        //console.log("response getting marketchart data from backend for: " + currencyDetails.coin)
        //console.log(urlString)
        //console.log(urlString)
        //console.log(chartResponse)

        //find min max prices
        var minMaxPrices = chartResponse.results.map( (data) => {
          return data.price
        })

        var chartMax = minMaxPrices.reduce((a, b) => { return Math.max(a, b) });
        var chartMin = minMaxPrices.reduce((a, b) => { return Math.min(a, b) });

        //if using coingecko
        //var firstValue = chartResponse.results[0].price
        //var lastValue = chartResponse.results[chartResponse.results.length - 1].price

        //if using our data
        var firstValue = chartResponse.results[chartResponse.results.length - 1].price
        var lastValue = chartResponse.results[0].price
        //console.log("firstValue(firstPrice) in CoinDetailsChart is: " + firstValue)
        //console.log("lastValue(lastPrice) is CoinDetailsChart is: " + lastValue)
        setFirstPrice(firstValue)
        setLastPrice(lastValue)

        var chartColor = '#109e10'
        var legendPosition = 'left'
        if (firstValue > lastValue) {
          //price went down over chart
          chartColor = '#9e1010'
          legendPosition = 'right'
        }

        var chartPriceswithDateArrTimestamp = chartResponse.results.map( (data) => {
          return [ data.timestamp , data.price ]
        })

        var volumeDataArrWithTimeStamp = chartResponse.results.map( (data) => {
          return [ data.timestamp , data.volume ]
        })

        var dateArr = chartResponse.results.map( (data) => {
          var date = new Date(data.timestamp)
          var year = date.getFullYear()
          var month = date.getMonth()
          var day = date.getDate()

          return day+'-'+month+'-'+year
        })

        //there is no market cap data in current result set from backend
        //var marketDataArrWithTimestamp = response.market_caps.map( (data) => {
        //  return [ data[0], data[1] ]
        //})

        //find min max market cap for chart
        //var minMaxMarketCap = response.market_caps.map( (data) => {
        //  return data[1]
        //})

        //var chartMaxMarketCap = minMaxMarketCap.reduce((a, b) => { return Math.max(a, b) });
        //var chartMinMarketCap = minMaxMarketCap.reduce((a, b) => { return Math.min(a, b) });

        //var chartMaxMarketCapUsed = (3 * chartMaxMarketCap)

        //find min max volume for chart
        var minMaxVolumes = chartResponse.results.map( (data) => {
          return data.volume
        })

        var chartMaxVolume = minMaxVolumes.reduce((a, b) => { return Math.max(a, b) });
        var chartMinVolume = minMaxVolumes.reduce((a, b) => { return Math.min(a, b) });

        var chartMaxVolumeUsed = (2 * chartMaxVolume)

        setMixedChartSeries([
          {
            name: "Price",
            type: "area",
            data: chartPriceswithDateArrTimestamp
          },
          {
            name: "Volume",
            type: "column",
            data: volumeDataArrWithTimeStamp,
          },
          //{
          //  name: "Market Cap",
          //  type: "column",
          //  data: marketDataArrWithTimestamp,
          //}
        ])

        setMixedChartOptions({
          chart: {
            type: 'area',
            id: "price-volume-chart",
            group: "crypto2",
            toolbar: {
              show: false, 
            },
            background: theme.palette.background.default,
            events: {
              click: function(event, chartContext, config) {
                if (userObject.user.is_vip) {
                  //console.log("top of chart click event (event, chartContext, config):")
                  //console.dir(event)
                  //console.dir(chartContext)
                  //console.dir(config)
                  //console.log("default price alert set to: " + config.globals.series[0][config.dataPointIndex])
                  if (config.globals.series[0][config.dataPointIndex] !== undefined) {
                    setPriceAlert(config.globals.series[0][config.dataPointIndex])
                    setPriceAlertOpen(true)
                  }
                } else {
                  //this is a vip only feature
                }
              }
            }
          },
          colors: [chartColor, '#10689e', '#eba834'],
          stroke: {
            show: true,
            width: [ 2, 0, 0 ]
          },
          dataLabels: {
            enabled: false,
          },
          labels: dateArr,
          xaxis: {
            type: 'datetime',
            labels: {
              show: true,
              datetimeUTC: false,
              formatter: function(value, timestamp, opts) {
                return String(new Date(value).toLocaleDateString()).concat(' ').concat((chartInterval === 'daily') ? '' : new Date(value).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}))
              }
            }
          },
          yaxis: [
            {
              seriesName: "Price",
              opposite: false,
              show: true,
              min: chartMin,
              max: chartMax,
              forceNiceScale: true,
              labels: {
                show: true,
                formatter: (value) => {
                  const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 6
                  });
                  return formatter.format(value);
                },
                style: {
                  colors: [ '#bf3932']
                }
              },      
              tooltip: {
                shared: true,
                intersect: false,
                x: {
                  show: false,
                  format: 'dd MMM yyyy',

                },
                marker: {
                  show: true,
                },
              },                      
            },
            {
              seriesName: "Volume",
              opposite: true,
              axisTicks: {
                "show": true,
              },
              show: false,
              min: chartMinVolume,
              max: chartMaxVolumeUsed,
            },
            //{
            //  seriesName: "Market Cap",
            //  opposite: true,
            //  show: false,
            //  min: chartMinMarketCap,
            //  max: chartMaxMarketCapUsed,
            //}
          ],
          fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 1,
                gradientToColors: [theme.palette.background.default],
                inverseColors: false,
                opacityFrom: 0.9,
                opacityTo: 0.5,
            },
          },
          tooltip: {
            theme: 'dark',
            x: {
              show: false,
              format: 'dd MMM yyyy',
            },
            y: {
              formatter: function (value, index) {
                if (value === index.series[0][index.dataPointIndex]) {
                  //this is a price
                  let fixedDecimal = 2
                  if (value < .00001) 
                    fixedDecimal = 7
                  else if (value < .0001)
                    fixedDecimal = 6
                  else if (value < .001)
                    fixedDecimal = 5
                  else if (value < .01)
                    fixedDecimal = 4      
                  else if (value < .1)
                    fixedDecimal = 4                                     
                  return chartBaseCurrencySymbol + " " + (value).toFixed(fixedDecimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " " + chartBaseCurrency
                } else if (value === index.series[1][index.dataPointIndex]) {
                  //this is volume...just remove after .
                  return Math.round(value)
                } else if (value === index.series[2][index.dataPointIndex]) {
                  return chartBaseCurrencySymbol + " " + (value).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " " + chartBaseCurrency
                } else {
                  // not sure what it is...return unchanged
                  return value
                }
              }
            },
          },
          legend: {
            position: legendPosition,
            floating: true,
            offsetX: 10,
            offsetY: 10,
            labels: {
              colors: theme.palette.grey[300]
            }
          }
        })

        setIsLoading(false)
      } catch (error) {
        console.log("something is broke getting marketchart: ", error)
      }
    })()

  }, [chartDays, chartInterval, chartBaseCurrency])

  const saveAlert = e => {
    e.preventDefault()
    console.log("top of saveAlert")
    console.log("currencyDetails.coin is: "+currencyDetails.coin)
    console.log("alertMultiple is: " + alertMultiple)
    console.log("priceAlert is: " + priceAlert)

    if ((priceAlert === 0) || (priceAlert === '')) {
      setPriceAlertError(true)
    } else if (priceAlert > 0) {
      //console.log("priceAlert is greater than 0")
      if (userObject.user.is_vip) {
        const fetchParams = {
          method: 'POST',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'coin': currencyDetails.coin, 
            'price_target': priceAlert,
            'multiple': alertMultiple,
          })        
        }
        ;(async () => {
          try {
            var urlString = `api/v1/vip/alertcreate/`
            const saveAlertResponse = await fetchData(urlString, "could not save new price alert", fetchParams).promise
            console.log("saveAlertResponse saving new price alert: ")
            console.log(urlString)
            console.dir(saveAlertResponse)
          } catch (error) {
            console.log("something is broke new price alert: ", error)
          }
        })()
      }
      setPriceAlertOpen(false)
    } else {
      //console.log("priceAlert is zero or less")
      setPriceAlertError(true)
    }
  }

  const cancelAlert = e => {
    setPriceAlertOpen(false)  
  }

  const handlePriceAlertChange = e => {
    setPriceAlertError(false)
    setPriceAlert(e.target.value)
  }

  if (isLoading) return <CircularProgress />

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary800,
    },
  }));


  const handleChange = (event) => {
    setAlertMultiple(event.target.value);
  }

  return (
    <Box
      sx={{
        paddingTop: '20px',
      }}
    >
      <Stack direction="column">
        <Stack direction="row" sx={{justifyContent: 'space-between'}}>
          <Chip label={chartRangeDetails} sx={{backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.light}} variant="outlined" />
          <ButtonGroup size="small" aria-label="small button group" sx={{paddingRight: '10px'}}>
            { chartDays === 1 ? <ColorButton  variant="contained">1D</ColorButton> : <Button onClick={() => handleChartTimeChange(1)}>1D</Button> }
            { chartDays === 3 ? <ColorButton  variant="contained">3D</ColorButton> : <Button onClick={() => handleChartTimeChange(3)}>3D</Button> }
            { chartDays === 7 ? <ColorButton  variant="contained">1W</ColorButton> : <Button onClick={() => handleChartTimeChange(7)}>1W</Button> }
            { chartDays === 14 ? <ColorButton  variant="contained">2W</ColorButton> : <Button onClick={() => handleChartTimeChange(14)}>2W</Button> }
            { chartDays === 30 ? <ColorButton variant="contained">1M</ColorButton> : <Button onClick={() => handleChartTimeChange(30)}>1M</Button> }
            { chartDays === 90 ? <ColorButton  variant="contained">3M</ColorButton> : <Button onClick={() => handleChartTimeChange(90)}>3M</Button> }
            { chartDays === 180 ? <ColorButton  variant="contained">6M</ColorButton> : <Button onClick={() => handleChartTimeChange(180)}>6M</Button> }
            { chartDays === 365 ? <ColorButton  variant="contained">1Y</ColorButton> : <Button onClick={() => handleChartTimeChange(365)}>1Y</Button> }
          </ButtonGroup>
        </Stack>

        <Grid container>
          {/*<Grid item xs={12}>
            <Chart
              options={chartPriceOptions}
              series={chartPrices}
              type="area"
              height={300}
              width="100%"
            />

            <Chart
              options={chartVolumeOptions}
              series={volumeData}
              type="bar"
              height={100}
              width="100%"

            />
            <Chart
              options={chartMarketCapOptions}
              series={marketCapData}
              type="bar"
              height={100}
              width="100%"

            />

          </Grid>*/}
          <Grid item xs={12}>
            <Chart
              options={mixedChartOptions}
              series={mixedChartSeries}
              type="area"
              height={500}
            />
            {/*
            <Chart
              options={chartMarketCapOptions2}
              series={marketCapData}
              type="bar"
              height={100}
              width="100%"
              style={{ zIndex: -1 }}
            />*/}
          </Grid>
        </Grid>
      </Stack>
      <CoinDetailsChartPriceAlert
        priceAlertOpen={priceAlertOpen}
        priceAlert={priceAlert}
        handlePriceAlertChange={handlePriceAlertChange}
        priceAlertError={priceAlertError}
        coinID={currencyDetails.coin.id}
        handleChange={handleChange}
        alertMultiple={alertMultiple}
        saveAlert={saveAlert}
        cancelAlert={cancelAlert}
      />
    </Box>
  )
}

export default CoinDetailsChart