import React, { useEffect,useState } from 'react'
import { Typography, Grid, CircularProgress, Stack, Button, Link, Box } from "@mui/material"

import CoinDetailsLinks from '../CoinDetails/CoinDetailsLinks'
import CoinDetailsStats from '../CoinDetails/CoinDetailsStats'
import { useTheme } from '@mui/material/styles'
import { BsBoxArrowRight } from 'react-icons/bs'

import fetchData from '../../utility/endpointFetch'

const VIPCoinStats = ({ coinChart='', showOfficialTweets, setCoinInChart, changeAtFollowedTab }) => {
  //console.log("top of VIPCoinStats and coinChart is: " + coinChart)
  const theme = useTheme()

  const [coinDetails,setCoinDetails] = useState({})
  const [coinScreenerDetails,setCoinScreenerDetails] = useState({})
  const [isLoading,setIsLoading] = useState(true)

  const showMentionedPosts = () => {
    setCoinInChart(coinChart)
    //changeDateInChart(twitterItem.created)
    changeAtFollowedTab(coinDetails.twitter)    
    //showOfficialTweets(coinDetails.twitter, coinChart)
  }

  useEffect(() => {
      if (coinChart !== '') {
        setIsLoading(true)
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            let vipCoinStatsResponseURL = `api/v1/coin/ecoinscreener/?coin=${coinChart}`
            const vipCoinStatsResponse = await fetchData(vipCoinStatsResponseURL, "could not receive coin market data in VIPCoinStats", fetchParams).promise
            //console.log("vipCoinStatsResponse getting coin market data in VIPCoinStats")
            //console.dir(vipCoinStatsResponse)
            //console.log(vipCoinStatsResponseURL)

            //console.log("vipCoinStatsResponse.count  is : " + vipCoinStatsResponse.count )
            if (vipCoinStatsResponse.count === 1) {
              setCoinDetails(vipCoinStatsResponse.results[0])
                
            } else {
                //there is some issue as can only handle one result
                console.log('COINDETAILS IS NOT SET')
            }

            ;(async () => {
              try {
                let vipCoinScreenerResponseURL = `api/v1/coin/ecoinscreener/?coin=${coinChart}`
                const vipCoinScreenerResponse = await fetchData(vipCoinScreenerResponseURL, "could not receive coin screener data in VIPCoinStats", fetchParams).promise
                //console.log("vipCoinScreenerResponse getting coinscreener data in VIPCoinStats")
                //console.dir(vipCoinScreenerResponse)
                //console.log(vipCoinScreenerResponseURL)
    
                //console.log("vipCoinStatsResponse.count  is : " + vipCoinStatsResponse.count )
                if (vipCoinScreenerResponse.count === 1) {
                  setCoinScreenerDetails(vipCoinScreenerResponse.results[0])
                    
                } else {
                    //there is some issue as can only handle one result
                    console.log('COINSCREENER IS NOT SET')
                }
    
                
        
                setIsLoading(false)
              } catch (error) {
                console.log("could not receive coinChart data in VIPCoinStats: " + error)
              }
            })()
   
          } catch (error) {
            console.log("could not receive coinChart data in VIPCoinStats: " + error)
          }
        })()
      } else {
        console.log("no coinChart set")
      }
  
  }, [coinChart])    

   

  if (isLoading) return <CircularProgress />
  const coinDetailsURL = `/coindetail/${coinDetails.coin}`
  return (
    <Grid container >
      <Grid item xs={12}>
        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between">
          <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
            <Typography ><img src={ coinDetails.image_thumbnail !== null ? coinDetails.image_thumbnail : null} alt={coinDetails.coin}/></Typography>
            <Typography variant="h4" sx={{marginLeft: '10px'}}>{coinDetails.coin} </Typography>
          </Stack>
          <Link href={coinDetailsURL} sx={{color: theme.palette.primary.main}}>details</Link>
          <Button onClick={() => showMentionedPosts()} endIcon={<BsBoxArrowRight />}>
            tweets
          </Button>
        </Stack>
      </Grid>
      
      <Grid item xs={12}>
        <Stack direction="row" sx={{marginLeft: '10px', marginRight: '10px', marginBottom: '10px'}}>
          <Box sx={{ width: '40%' }}>
          <Typography variant="body1"
              sx={{ color: theme.palette.grey[400], fontWeight: 600  }}
            >social rank</Typography>
          </Box>
          <Box sx={{ width: '60%', textAlign: "right" }}>
            <Typography sx={{color: theme.palette.primary.main, fontWeight: 600}} variant="body1">
              {Number(coinScreenerDetails.social_rank).toFixed(2)}</Typography>
          </Box>
        </Stack>        
      </Grid>     
      <Grid item xs={12}>
        <Stack direction="row" sx={{marginLeft: '10px', marginRight: '10px', marginBottom: '10px'}}>
          <Box sx={{ width: '40%' }}>
          <Typography variant="body1"
              sx={{ color: theme.palette.grey[400], fontWeight: 600  }}
            >social engagement</Typography>
          </Box>
          <Box sx={{ width: '60%', textAlign: "right" }}>
            <Typography sx={{color: theme.palette.primary.main, fontWeight: 600}} variant="body1">
              {Number(coinScreenerDetails.total_social_engagement).toFixed(2)}</Typography>
          </Box>
        </Stack>        
      </Grid>        
      <Grid item xs={12}>
        <Stack direction="row" sx={{marginLeft: '10px', marginRight: '10px'}}>
          <Box sx={{ width: '40%' }}>
          <Typography variant="body1"
              sx={{ color: theme.palette.grey[400], fontWeight: 600  }}
            >current price</Typography>
          </Box>
          <Box sx={{ width: '60%', textAlign: "right" }}>
            <Typography sx={{color: theme.palette.primary.main, fontWeight: 600}} variant="body1">
              {Number(coinDetails.current_price).toLocaleString()}</Typography>
          </Box>
        </Stack>        
      </Grid>      
      <Grid item xs={12}>
        <CoinDetailsStats currencyDetails={coinDetails} />  
      </Grid>
      <Grid item xs={12} sx={{paddingTop: '15px'}}>
        <CoinDetailsLinks currencyDetails={coinDetails} />  
      </Grid>
    </Grid>
  )
}

export default VIPCoinStats