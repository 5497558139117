import React from 'react'
import { Grid, Divider, Typography} from '@mui/material'
import { useTheme } from '@mui/material/styles'

const WatchListDisplayHeader = ({}) => {

    const theme = useTheme()

    return (
        <>
        <Grid item xs={4} display="flex" justifyContent="flex-start">
            <Typography sx={{color: theme.palette.grey[300]}}>coin</Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
            <Typography sx={{color: theme.palette.grey[300]}}>last</Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
            <Typography sx={{color: theme.palette.grey[300]}}>chg</Typography>
        </Grid>
        <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', marginBottom: '5px',}}/>
        </>
    )
}

export default WatchListDisplayHeader