import React, { useState, useEffect } from 'react'

// material-ui
import { styled, useTheme } from '@mui/material/styles'
import { Box, Grid, Container, CssBaseline, Tab, Typography, CircularProgress,  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'

import TopAppBar from '../TopAppBar'

import HealthOverview from './HealthOverview'
import ExchangeReserve from './ExchangeReserve'
import ExchangeInflow from './ExchangeInflow'
import ExchangeOutflow from './ExchangeOutflow'
import MinerReserve from './MinerReserve'
import MinerOutflow from './MinerOutflow'
import MinerInflow from './MinerInflow'
import StockToFlow from './StockToFlow'
import MVRVRatio from './MVRVRatio'
import NUPL from './NUPL'
import FearAndGreed from './FearAndGreed'
import LeverageRatio from './LeverageRatio'
import OpenInterest from './OpenInterest'
import StableCoinSupply from './StableCoinSupply'
import FundingRates from './FundingRates'

// project imports
import Navigation from '../Navigation'
import HealthChart from './HealthChart'
import HealthChartELR from './HealthChartELR'
import HealthChartNUPL from './HealthChartNUPL'
import HealthChartFearAndGreed from './HealthChartFearAndGreed'
import Footer from '../Footer'

import fetchData from '../../utility/endpointFetch'
import Netflow from "./Netflow";
import StableNetflow from "./StableNetflow";

const chartTypes = [
  {
    'name': 'reserve',
    'header': 'Exchange Reserve',
    'description': 'this is a long description of Exchange Reserve',
    'indicator_index': 'reserve',
    'chart_type': 'line',
    'component': <ExchangeReserve />,
  },
  {
    'name': 'inflow',
    'header': 'Exchange Inflow (Total)',
    'description': 'this is a long description of Exchange Inflow (Total)',
    'indicator_index': 'inflow_total',
    'chart_type': 'bar',
    'component': <ExchangeInflow />,
  },
  {
    'name': 'outflow',
    'header': 'Exchange Outflow (Total)',
    'description': 'this is a long description of Exchange Outflow (Total)',
    'indicator_index': 'outflow_total',
    'chart_type': 'bar',
    'component': <ExchangeOutflow />,
  },
  {
    'name': 'miner reserve',
    'header': 'Miner Reserve USD',
    'description': 'this is a long description of Miner Reserve USD',
    'indicator_index': 'miner_reserve',
    'chart_type': 'line',
    'component': <MinerReserve />,
  },
  {
    'name': 'miner inflow',
    'header': 'Miner Inflow (Total)',
    'description': 'this is a long description of Miner Inflow (Total)',
    'indicator_index': 'miner_inflow',
    'chart_type': 'bar',
    'component': <MinerInflow />,
  },
  {
    'name': 'miner outflow',
    'header': 'Miner Outflow (Total)',
    'description': 'this is a long description of Miner Outflow (Total)',
    'indicator_index': 'miner_outflow',
    'chart_type': 'bar',
    'component': <MinerOutflow />,
  },
  {
    'name': 'stock to flow',
    'header': 'Stock To Flow',
    'description': "The USD value of coins held by the affiliated miners' wallets.",
    'indicator_index': 'stock_to_flow',
    'chart_type': 'line',
    'component': <StockToFlow />
  },
  {
    'name': 'MVRV Ratio',
    'header': 'MVRV Ratio',
    'description': "MVRV (Market Value to Realized Value) ratio is defined as an asset's market capitalization divided by realized capitalization.",
    'indicator_index': 'mvrv',
    'chart_type': 'line',
    'component': <MVRVRatio />
  },
  {
    'name': 'NUPL',
    'header': 'NUPL (Net Unrealized Profit/Loss)',
    'description': "Net Unrealized Profit and Loss (NUPL) is the difference between market cap and realized cap divided by market cap.",
    'indicator_index': 'nupl',
    'chart_type': 'line',
    'component': <NUPL />
  },
  {
    'name': 'Fear/Greed',
    'header': 'Fear And Greed',
    'description': "Net Unrealized Profit and Loss (NUPL) is the difference between market cap and realized cap divided by market cap.",
    'indicator_index': 'fear_and_greed',
    'chart_type': 'line',
    'component': <FearAndGreed />
  },
  {
    'name': 'Leverage Ratio',
    'header': 'Estimated Leverage Ratio',
    'description': "Net Unrealized Profit and Loss (NUPL) is the difference between market cap and realized cap divided by market cap.",
    'indicator_index': 'estimated_leverage_ratio',
    'chart_type': 'line',
    'component': <LeverageRatio />
  },
  {
    'name': 'Open',
    'header': 'Open Interest',
    'description': "Net Unrealized Profit and Loss (NUPL) is the difference between market cap and realized cap divided by market cap.",
    'indicator_index': 'open_interest',
    'chart_type': 'line',
    'component': <OpenInterest />
  },
  {
    'name': 'Stable Sup',
    'header': 'StableCoin Supply Ratio',
    'description': "Stablecoin Supply Ratio (SSR) is defined as a ratio of the Market Cap of BTC divided by the Market Cap of all Stablecoins",
    'indicator_index': 'stablecoin_supply_ratio',
    'chart_type': 'line',
    'component': <StableCoinSupply />
  },
  {
    'name': 'Fund Rates',
    'header': 'Funding Rates - All Exchanges',
    'description': "Funding rates represent traders' sentiments in the perpetual swaps market and the amount is proportional to the number of contracts.",
    'indicator_index': 'funding_rates',
    'chart_type': 'bar',
    'component': <FundingRates />
  },
  {
    'name': 'Netflow',
    'header': 'Netflow',
    'description': "Exchange Netflow is the difference between BTC flowing into and out of the exchange. (Inflow - Outflow = Netflow).",
    'indicator_index': 'netflow',
    'chart_type': 'bar',
    'component': <Netflow />
  },
  {
    'name': 'Stablecoin Netflow',
    'header': 'Stablecoin Netflow',
    'description': "Stablecoin Exchange Netflow is the difference between Stablecoin flowing into and out of the exchange. (Inflow - Outflow = Netflow)",
    'indicator_index': 'stable_netflow',
    'chart_type': 'bar',
    'component': <StableNetflow />
  },
]


// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
      }),
      [theme.breakpoints.up('md')]: {
          marginLeft: '20px',
          width: `calc(100% - 20px)`
      },
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px',
          width: `calc(100% - 20px)`,
          padding: '16px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px',
          width: `calc(100% - 20px)`,
          padding: '16px',
          marginRight: '10px'
      }
  }),
  ...(open && {
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - 20px)`,
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px'
      }
  })
}));

const Health = ( {userObject} ) => {
  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')
  const [chartType,setChartType] = useState(chartTypes[0])
  const [isLoading,setIsLoading] = useState(true)
  const [btcData,setBTCData] = useState({})
  const [ruleThemAll,setRuleThemAll] = useState([])

  const handleTabChange = (event, newValue) => {
    setChartType(chartTypes[newValue-2])
    setTabValue(newValue)
  };

  const changeTab = ( newTab ) => {
    setChartType(chartTypes[(parseInt(newTab)-2)])
    setTabValue(newTab);
  }


  useEffect(() => {

    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        const btcResponse = await fetchData(`api/v1/coin/ecoinscreener/?coin__id=bitcoin`, "could not receive btc data", fetchParams).promise
        //console.log("btcResponse getting btc data from api")
        //console.dir(btcResponse)
        setBTCData(btcResponse.results[0])

        const healthResponse = await fetchData(`api/v1/coin/health/?pag_size=365&page=1`, "could not receive health chart data", fetchParams).promise
        //console.log("healthResponse getting health data from api")
        //console.dir(healthResponse)

        //add unix timestamp to compare to coingecko
        //not finding .unix_timestamp currently used anywhere....commenting out for possible future removal
        //healthResponse.results.map( (health, index) => {
        //  healthResponse.results[index].unix_timestamp = Math.round(new Date(health.timestamp).getTime())
        //})

        var allData = healthResponse.results.map( (data, index) => {
          return {
            "timestamp": data.timestamp,
            "price": data.price,
            "total_volume": data.volume,
            "market_cap": data.market_cap,
            "healthData": data,
          }
        })

        //console.log("ruleThemAll(allData) is: ")
        //console.dir(allData)

        setRuleThemAll(allData)

        setIsLoading(false)
      } catch (error) {
        console.log("something is broke getting ecoinscreener in Health: " + error)
      }
    })()

  }, [])

  if (isLoading) return <CircularProgress />
  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* header */}
        <TopAppBar userObject={userObject} selectedPage='health' />
        {/* main content */}
        <Main theme={theme} >
          <Container maxWidth="false">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabChange} aria-label="BitCoin Health">
                        <Tab label="overview" value="1" sx={{minWidth: '50px'}}/>
                        <Tab label="reserve" value="2" sx={{minWidth: '50px',}}/>
                        <Tab label="inflow" value="3" sx={{minWidth: '50px'}}/>
                        <Tab label="outflow" value="4" sx={{minWidth: '50px'}}/>
                        <Tab label="miner res" value="5" sx={{minWidth: '50px'}} />
                        <Tab label="miner +" value="6" sx={{minWidth: '50px'}} />
                        <Tab label="miner -" value="7" sx={{minWidth: '50px'}} />
                        <Tab label="stock to flow" value="8" sx={{minWidth: '50px'}} />
                        <Tab label="MVRV" value="9" sx={{minWidth: '50px'}} />
                        <Tab label="NUPL" value="10" sx={{minWidth: '40px'}} />
                        <Tab label="Fear/Greed" value="11" sx={{minWidth: '40px'}} />
                        <Tab label="ELR" value="12" sx={{minWidth: '40px'}} />
                        <Tab label="OI" value="13" sx={{minWidth: '40px'}} />
                        <Tab label="SSR" value="14" sx={{minWidth: '40px'}} />
                        <Tab label="Fund Rate" value="15" sx={{minWidth: '40px'}} />
                        <Tab label="Netflow" value="16" sx={{minWidth: '40px'}} />
                        <Tab label="Stablecoin Netflow" value="17" sx={{minWidth: '40px'}} />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <HealthOverview
                        btcData={btcData}
                        ruleThemAll={ruleThemAll}
                        changeTab={changeTab}
                      />
                    </TabPanel>
                    <TabPanel value="2">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="3">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="4">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="5">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="6">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="7">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="8">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="9">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="10">
                      <HealthChartNUPL
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="11">
                      <HealthChartFearAndGreed
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="12">
                      <HealthChartELR
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="13">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="14">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="15">
                      <HealthChart
                        chartType={chartType}
                        ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="16">
                      <HealthChart
                          chartType={chartType}
                          ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                    <TabPanel value="17">
                      <HealthChart
                          chartType={chartType}
                          ruleThemAll={ruleThemAll}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Footer />
        </Main>
    </Box>
  );
}

export default Health