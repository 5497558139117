import React, {useEffect,useState,forwardRef,useImperativeHandle} from 'react'
import {
    Box,
    CircularProgress,
    Grid,
    Stack,
    Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'

import WatchListDisplaySelector from './WatchListDisplaySelector'
import WatchListDisplayItem from './WatchListDisplayItem'
import WatchListDisplayHeader from './WatchListDisplayHeader'
import FavoriteChoicesPopover from '../MyCurrencies/FavoriteChoicesPopover'
import fetchData from '../../utility/endpointFetch'

//import { DndContext, closestCenter, PointerSensor, useSensor } from '@dnd-kit/core'
//import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

const WatchListDisplay = forwardRef( ({userObject, deleteBox, panelID, list, saveSelectedList, coinToDependents}, componentSetCoinsRef ) => {

    const theme = useTheme()

    //watched dropdown
    //display:
    //  symbol
    //  last
    //  change
    //  change %
    
    //order by column

    const [localUserObject,setLocalUserObject] = useState(userObject)

    const [isLoading,setIsLoading] = useState(true)
    const [selectedList,setSelectedList] = useState(list)
    const [selectedListCount,setSelectedListCount] = useState(0)
    const [screenerCoinList,setScreenerCoinList] = useState([])
    //const [sortByUrl,setSortByUrl] = useState('')

    function setChosenCoinInComponent  (  )  {
        console.log("top of setChosendCoinInComponent in WatchListDisplay")
    }

    useImperativeHandle( componentSetCoinsRef, () => ({ setChosenCoinInComponent }),[] )

    useEffect(() => {

        const getWatchListCoins = (screenerName) => {
            setIsLoading(true)
            const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                  'Content-Type': 'application/json'
                },
            }        
            ;(async () => {
                try {
                  //var fetchScreenerCoinsURL = `api/v1/users/screener/?screener_name=${screenerName}${sortByUrl}`
                  var fetchScreenerCoinsURL = `api/v1/users/screener/?screener_name=${screenerName}`
                  const screenerCoinsresponse = await fetchData(fetchScreenerCoinsURL, "could not receive screener coin data within WatchListDisplay component", fetchParams).promise
                  //console.log("screenerCoinsresponse getting screener coins data within WatchListDisplay component")
                  console.log("fetchScreenerCoinsURL is: " + fetchScreenerCoinsURL)
                  console.dir(screenerCoinsresponse)

                  if (screenerCoinsresponse.results.length > 0) {
                    //get all coin id within this result into one array to add to coinmarket chart url selection
          
                    let coinsInList = screenerCoinsresponse.results.map( (coinDetails) => { return coinDetails.coin_id } )
                    console.log("coinsInList for ecoinscreener call is: ")
                    console.dir(coinsInList)
                    //setScreenerCoinList(coinsInList)
                    //coinsInList = [...new Set(coinsInList)]
                    //console.log("coinsInList after duplicates removed is: ")
                    //console.dir(coinsInList)
        
                    ;(async () => {
                      try {
                        //let myCoinsScreenerResponseURL = `api/v1/coin/ecoinscreener/?coins=${coinsInList.join(",")}${sortByUrl}`
                        let myCoinsScreenerResponseURL = `api/v1/coin/ecoinscreener/?coins=${coinsInList.join(",")}`
                        const myCoinsScreenerResponse = await fetchData(myCoinsScreenerResponseURL, "could not receive coin screener data in my currencies", fetchParams).promise
                        console.log("myCoinsScreenerResponse getting watchlist data in dashboard")
                        console.dir(myCoinsScreenerResponse)
                        console.log(myCoinsScreenerResponseURL)
            
                        let withNewId = myCoinsScreenerResponse.results.map( coin => ({...coin, orig_id: coin.id, id: coin.coin_id }))
                        console.log("withNewId:")
                        console.dir(withNewId)
      
                        setScreenerCoinList(withNewId)
                        //setScreenerCoinList(screenerCoinsresponse.results)
                        setSelectedListCount(myCoinsScreenerResponse.count)

                      } catch (error) {
                        console.log("could not receive coin/ecoinscreener data in MyCurrencies: " + error)
                      }    
                    })()        
                  }

                  setIsLoading(false)
        
                } catch (error) {
                  console.log("something is broke getting screener coins within WatchListDisplay component: " + error )
                }
            })()
        }   

        getWatchListCoins(selectedList)
        //console.log('setting a getWatchListCoins at 60000 for watchlist: ' + selectedList)
        const intervalID = setInterval(() => getWatchListCoins(selectedList), 60000);
        return () => clearInterval(intervalID)        
    }, [selectedList])

    const removeListBox = () => {
        //console.log("top of removeListBox and panelID is: " + panelID)
        deleteBox(panelID)
    }

    const sendCoinChoiceToDependents = (coin) => {
        //send the whole coin object
        coinToDependents(coin)
    }

    const changeScreener = (newScreener) => {
        //console.log("top of changeScreener and newScreener is:")
        //console.dir(newScreener)
        saveSelectedList(newScreener,panelID)
        setSelectedList(newScreener)
    }

    const checkArraysEqual = (array1,array2) => {
        return Array.isArray(array1) &&
            Array.isArray(array2) &&
            array1.length === array2.length &&
            array1.every((val, index) => val === array2[index])
    }    

    const updateUserDataCoin = (newUserObjectCoins) => {
        //console.log("top of updateUserDataCoin and newUserObjectCoins is: ")
        //console.dir(newUserObjectCoins)
        //console.log("localUserObject is:")
        //console.dir(localUserObject)
        var fetchParams = {
          method: 'PUT',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'coin': newUserObjectCoins,
            'user': localUserObject.user,
            'twitter_friend_list': localUserObject.twitter_friend_list
          })
        }
        ;(async () => {
          try {
            const updateVIPResponse = await fetchData(`api/v1/users/userdata/`, "could not update coin in user data in watchlists in mycurrencies", fetchParams).promise
            console.log("updateVIPResponse updating coin in  user data in watchlists")
            console.dir(updateVIPResponse) 
            console.dir(fetchParams)
          } catch (error) {
              console.log("could not update coin in user data in watchlist in MyCurrencies: " + error )
          }
        })()         
    }    

    const changeInMyFavoriteCoins = ( newFavoriteCoins, oldFavoriteCoins, fromReorder=false ) => {
        setIsLoading(true)
        //console.log("top fo changeInMyFavoriteCoins and newFavoriteCoins is:")
        //console.dir(newFavoriteCoins)
        //console.log("oldFavoritecoins is:")
        if (fromReorder === false) {
            let reducedNewFavoriteCoins = newFavoriteCoins.map(thisCoin => thisCoin.coin_id)
            //console.log("reducedNewFavoriteCoins is")
            //console.dir(reducedNewFavoriteCoins)
            //console.dir(oldFavoriteCoins)
            if (!checkArraysEqual(reducedNewFavoriteCoins, oldFavoriteCoins)) {
                //old and new are NOT equal...need to change
                setScreenerCoinList(newFavoriteCoins)
                //need to check if oldFavoriteCoins matches userObject.coins....if so....change userObject.coins also
                if (checkArraysEqual(oldFavoriteCoins,localUserObject.coin)) {
                    //console.log("old and current vip were the same but not now....change userObject.coin")
                    setLocalUserObject(prevState => ({...prevState, 'coin': reducedNewFavoriteCoins}))
                    updateUserDataCoin(reducedNewFavoriteCoins)
                } else {
                    //no changes were made in the coin list...no need to update anything
                }
            }
        } else {
            //this is from the reorder function....apply the changes
            if (selectedList === "Favorites") {

                let reducedNewFavoriteCoins = newFavoriteCoins.map(thisCoin => thisCoin.coin_id)
                setLocalUserObject(prevState => ({...prevState, 'coin': reducedNewFavoriteCoins}))
                updateUserDataCoin(reducedNewFavoriteCoins)                

            } else {
                //only update if on Favorites
                //to update "non favorites" screener lists...
                // - need to "remove all current screener coins one by one"
                // - then add each coin back to that screener one by one in the order you want
            }

        }
        
        setIsLoading(false)
    }

    //const sensors = [useSensor(PointerSensor)]

    /*const onDragEnd = ({active, over}) => {
        console.log("top of onDragEnd and active is:")
        console.dir(active)
        console.log("and over is: ")
        console.dir(over)
        if (active.id !== over.id) {
            //the current choice is not dropped onto itself
            const oldIndex = screenerCoinList.findIndex(item => item.id === active.id)
            const newIndex = screenerCoinList.findIndex(item => item.id === over.id)
      
            let newScreenerCoinList = arrayMove(screenerCoinList, oldIndex, newIndex)
            console.log("newScreenerCoinLIst is: ")
            console.dir(newScreenerCoinList)
            //put original id back in place 
            newScreenerCoinList = newScreenerCoinList.map(coin => {
                return {...coin, id: coin.orig_id }
            })
            setScreenerCoinList(newScreenerCoinList)
            if (selectedList === "Favorites") {
                changeInMyFavoriteCoins( newScreenerCoinList, screenerCoinList, true)
            }
        }        
    }    

    const onDragStart = (event) => {
        console.log("top of onDragStart and event is:")
        console.dir(event)
    }    */

    if (isLoading) return <CircularProgress />
    return (
        <Box sx={{ display: 'flex', width: 'calc(100% - 10px)', marginLeft: '5px' }}>
            <Stack direction="column" display="flex" alignItems='left' sx={{width: '100%'}}>
                <Stack direction="row" display="flex" alignItems='center' justifyContent='space-between' sx={{marginTop: '5px', marginRight: '5px'}}>
                    {/*<Chip label={"displaying: " + selectedList + " (" + selectedListCount + ")"} sx={{backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.light, marginLeft: '15px', borderRadius: '10px'}} variant="outlined" />*/}
                    <Stack direction="row" display="flex" alignItems='center' justifyContent='flex-start' >
                        <Typography display="inline" sx={{fontWeight: 400}} color={theme.palette.grey[300]}>{"watchlist: "}</Typography>
                        <Typography display="inline" sx={{fontWeight: 600, marginLeft: '5px'}} color={theme.palette.primary.main}>{selectedList + " (" + selectedListCount + ")"}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent='flex-end'>  
                        <FavoriteChoicesPopover
                            screenerCoinList={screenerCoinList}
                            userObject={userObject}
                            changeInMyFavoriteCoins={changeInMyFavoriteCoins}
                            selectedScreener={selectedList}
                            changeScreener={changeScreener}
                            sourceType='dashboard'
                        />                            
                        <WatchListDisplaySelector 
                            changeScreener={changeScreener}
                            watchLists={userObject.screener_name}
                        />
                        <VscMove 
                            className='draggable-handle' 
                            color={theme.palette.warning.dark} 
                            size={18} 
                            style={{cursor: 'grabbing'}}
                        />
                        <MdCancel 
                            className='draggable-cancel' 
                            color={theme.palette.secondary.dark} 
                            size={18} 
                            onClick={removeListBox}
                            style={{cursor: 'pointer'}}
                        />

                    </Stack>
                </Stack>
                <Box sx={{overflowY: 'auto'}}>
                    <Grid container>
                        
                        {screenerCoinList.length === 0 ? <Typography>There are no coins in this list</Typography> : null }
                        {screenerCoinList.length !== 0 ? <WatchListDisplayHeader /> : null }  
                        {/*<DndContext
                            collisionDetection={closestCenter}
                            onDragEnd={onDragEnd}   
                            onDragStart={onDragStart}    
                            sensors={sensors}            
                        >   
                            <SortableContext
                                items={screenerCoinList}
                                strategy={verticalListSortingStrategy}
                            >*/}
                                {screenerCoinList.map( (displayCoin) => 
                                    <WatchListDisplayItem
                                        id={displayCoin.coin_id}
                                        key={displayCoin.coin_id}
                                        currencyDetails={displayCoin}
                                        sendCoinChoiceToDependents={sendCoinChoiceToDependents}
                                    />
                                )}  
                            {/*</SortableContext>
                        </DndContext>*/}
                    </Grid>
                </Box>
            </Stack>
        </Box>         
    )
})

export default WatchListDisplay