import React from 'react'

import { useTheme } from '@mui/material/styles';
import { Box, Typography, Stack, Chip } from '@mui/material';

const CoinDetailsStats = ( {currencyDetails} ) => {

  //console.log("top of CoinDetailsStats")
  //console.dir(currencyDetails)

  const theme = useTheme();

  /*
    all time high = ath
    all time high date = ath_date
    all time low  = atl
    all time low date = atl_date
    fully diluted valuation = fully_diluted_valuation (valuation assuming max supply and current price)
    market cap = market_cap
    market cap_rank = market_cap_rank
    max supply = max_supply (coins that will ever exist)
    total volume = total_volume
  */

  var dataSX = {color: theme.palette.primary.main, fontWeight: 600}
  var labelSX = { color: theme.palette.grey[400], textAlign: 'left', fontWeight: 600 }

  var marketCap = Number(currencyDetails.market_cap).toLocaleString()

  var fullyDilutedValuation = Number(currencyDetails.fully_diluted_valuation).toLocaleString()

  var maxSupply = Number(currencyDetails.max_supply).toLocaleString()

  var totalVolume = Number(currencyDetails.total_volume).toLocaleString()
  var circulatingSupply = Number(currencyDetails.circulating_supply).toLocaleString()

  var allTimeHigh = Number(currencyDetails.ath).toLocaleString()
  var allTimeHighDate = null
  if (currencyDetails.ath_date !== null)
    allTimeHighDate = currencyDetails.ath_date.split('T')[0]

  var allTimeLow = Number(currencyDetails.atl).toLocaleString()
  var allTimeLowDate = null
  if (currencyDetails.ath_date !== null)
    allTimeLowDate = currencyDetails.atl_date.split('T')[0]

  const displayBlock = (label,data) => {
    return (
      <Stack direction="row" sx={{paddingBottom: '10px'}}>
        <Box sx={{ width: '40%' }}>
          <Typography variant="body1"
            sx={labelSX}
          >{label}</Typography>
        </Box>
        <Box sx={{ width: '60%', textAlign: "right" }}>
          <Typography sx={dataSX} variant="body1">{data}</Typography>
        </Box>
      </Stack>
    )
  }

  return (
    <Box sx={{marginTop: '15px', marginLeft: '10px', marginRight: '10px'}}>
      
      {displayBlock('Market Cap', '$ '+marketCap+'USD')}
      {displayBlock('Market Cap Rank', currencyDetails.market_cap_rank)}
      {displayBlock('Total Volume', totalVolume)}
      {displayBlock('Fully Diluted Value', '$ '+fullyDilutedValuation+'USD')}
      {displayBlock('Max Supply', maxSupply)}
      {displayBlock('Circulating Supply', circulatingSupply)}
      {displayBlock('24hr High', currencyDetails.high_24h)}
      {displayBlock('24hr Low', currencyDetails.low_24h)}
      {displayBlock('All Time High', '$ '+allTimeHigh+'USD ('+allTimeHighDate+')')}
      {displayBlock('All Time Low', '$ '+allTimeLow+'USD ('+allTimeLowDate+')')}

      <Stack direction="column">
        <Typography 
          variant="body1"
          sx={[labelSX, { marginBottom: '10px'  }]}
        >Categories</Typography>
        <Box sx={{ width: '100%', textAlign: "right" }}>
          {currencyDetails.category.map( ( category, index ) => {
            return (        
                <Chip 
                key={category+index} 
                size="small"
                sx={{ borderRadius: '12px', mr: '5px', mb: '3px'}}
                label={category} 
                color="success" 
                variant="filled" 
            />)
          } )}
        </Box>
      </Stack>

    </Box>
  );


}

export default CoinDetailsStats