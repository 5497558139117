import React, { useState } from 'react'
import { Typography, Stack, Box, IconButton, Popover, Paper, useMediaQuery, TextField, Tooltip } from '@mui/material'

import { TbSearch  } from 'react-icons/tb'
import { IconSquareX } from '@tabler/icons'
import { Clear, Search } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'

import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'

const ResearchDisplaySearch = ( {displaySavedSearch} ) => {
    //console.log("top of ResearchDisplaySearch")

    const theme = useTheme()
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'))
    
    const [postSearchTerm,setPostSearchTerm] = useState('')
    const [saveSearchOpen,setSaveSearchOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClose = () => {
        //console.log("top of handleClose")
        setAnchorEl(null)
        setSaveSearchOpen((prevOpen) => !prevOpen)
    }  

    const handleSearchClicked = () => {
      //console.log("top of handleSearchClicked and postSearchTerm is: " + postSearchTerm)
      displaySavedSearch(["text search: "+postSearchTerm, "search="+postSearchTerm])
      setPostSearchTerm('')
      handleClose()
  }    

    const handleClick = (event) => {
        //console.log("top of handleClick and event is:")
        //console.dir(event)
        setAnchorEl(event.currentTarget)
        setSaveSearchOpen((prevSavedSearchOpen) => !prevSavedSearchOpen)
    }

    const handleReset = ( valuesToReset ) => {
      switch(valuesToReset) {
        case 'searchtext':
          setPostSearchTerm('')
          break;
        default:
          //do nothing
          break;                                        
      }
  }    

  const handleSearchChange = (e) => {
    //console.log("top of handleSearchChange and e.target.value is: " + e.target.value)
    //console.log("vipTrackedKeywords is:")
    //console.dir(vipTrackedKeywords)
    setPostSearchTerm(e.target.value)
  }  

  const id = saveSearchOpen ? 'research-search-popover' : undefined   

  return (
      <Box sx={{ display: 'flex' }}>
          <TbSearch  
              aria-describedby={id}
              onClick={handleClick}
              color={theme.palette.success.main}
              style={{
                  '&:hover': {
                      //background: theme.palette.secondary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
                      color: theme.palette.success.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
                  },
                  cursor: 'pointer'
              }}
              size={18}
          />          
          <Popover
              id={id}
              open={saveSearchOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 400, left: 1200 }}
              anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
              }}
              transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
              }}
          >

              <Transitions position={matchesXs ? 'top' : 'top-right'} in={saveSearchOpen} >
              <Paper>
                  <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '500px', minHeight: '150px', padding: '15px', backgroundColor: theme.palette.dark[800]}}>
                      <Box sx={{width: '100%'}}>
                          <Stack direction="column" display="flex" alignItems="flex-start" sx={{width: '100%'}}>
                              <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
                                  <Typography variant='h2' sx={{color: theme.palette.primary.main}}>Crypto Twitter Text Search</Typography>
                                  <IconButton
                                      aria-label="close"
                                      sx={{color: theme.palette.error.main}}
                                      onClick={handleClose}>
                                      <IconSquareX sx={{ marginTop: '15px'}}/>
                                  </IconButton>  
                              </Stack>

                              <Box sx={{width: '100%', padding: '5px', paddingBottom: '0px'}}>
                                <Stack 
                                    direction="row" 
                                    display="flex" 
                                    alignItems="center"
                                    sx={{
                                        position: 'relative',
                                        top: '-10px'
                                    }}
                                >
                                  <TextField 
                                      id="research-tweet-search" 
                                      label="Twitter Search Text" 
                                      variant="outlined"
                                      value={postSearchTerm} 
                                      onChange={handleSearchChange}
                                      size="small"
                                      sx={{
                                          width: '100%', 
                                          minWidth: '80px',
                                          color: theme.palette.grey[500], 
                                          input: {
                                              background: theme.palette.dark[900],
                                          },
                                      }}
                                      InputLabelProps={{
                                          style: { 
                                              color: theme.palette.grey[500] ,
                                              position: 'relative',
                                              top: '17px'
                                          },
                                      }}
                                      inputProps={{
                                          style: { height: '12px' },
                                      }}

                                      
                                  />
                                  <Tooltip title="search">            
                                      <IconButton
                                          aria-label="clear coin"
                                          onClick={handleSearchClicked}
                                          edge="end"
                                          sx={{
                                              position: 'relative',
                                              top: '12px'
                                          }}
                                          type="submit"
                                      >
                                          <Search sx={{fontSize: '.8em', color: theme.palette.grey[500]}} />
                                      </IconButton> 
                                  </Tooltip> 
                                  <Tooltip title="clear search">
                                      <IconButton
                                          aria-label="clear search"
                                          onClick={() => handleReset('searchtext')}
                                          edge="end"
                                          sx={{
                                              position: 'relative',
                                              top: '12px'
                                          }} 
                                      >
                                          <Clear sx={{fontSize: '.8em',color: theme.palette.grey[500]}} />
                                      </IconButton>
                                  </Tooltip>          
                              </Stack>    
                            </Box>                                
                              
                          </Stack>
                      </Box>        

                  </MainCard>
              </Paper>
              </Transitions>    
          </Popover>
      </Box>
  )
}
export default ResearchDisplaySearch