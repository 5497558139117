import React from 'react';
import twitter from './twitter.jpg';
import metrics from './metrics.png';
import discord from './discord.jpg';

import { Stack, Box, Typography } from '@mui/material'
import { Scale } from '@mui/icons-material';


const Story3 = () => {
    return (
        <Box 
            sx={{
                width: '100%',
                backgroundColor: 'darkgrey',
            }}
        >
            <Stack 
                direction="column"
                alignItems='center'
                justifyContent='center'
            >
                <div 
                    style={{
                        fontSize: 'x-large',
                        color: '#3f07bf',                                
                    }}    
                > 
                    <h1>Alpha Centoro</h1>
                </div>                
                <h2>Our traders curate data feeds collected from around the internet to give you high confidence to produce quality trades</h2>

            </Stack>

            <Stack 
                direction="row"
                alignItems='flex-start'
                justifyContent='space-evenly'
                spacing={2}
                style={{
                    backgroundColor: 'darkgrey',
                    padding: '50px',
                    height: '40vh',
                    alignItems: '',
                }}
            >

                <Stack 
                    direction="column"
                    alignItems='center'
                    justifyContent='flex-start'
                >
                    <Typography 
                        variant='h4'
                        sx={{
                            marginBottom: '75px'
                        }}
                    >
                        Receive BTC Major Health Metrics at a Glance
                    </Typography>
                    <img src = {metrics} style={{transform: 'scale(1.5)'}} alt="twitter"/>
                </Stack>
                <Stack 
                    direction="column"
                    alignItems='center'
                    justifyContent='flex-start'
                >
                    <Typography 
                        variant='h4'
                        sx={{
                            marginBottom: '75px'
                        }}
                    >
                        Read your Twitter Feed in an Application specialized for Crypto
                    </Typography>
                    <img src = {twitter} style={{ transform: 'scale(1.5)'}} alt="metrics" />
                </Stack>
                <Stack 
                    direction="column"
                    alignItems='center'
                    justifyContent='flex-start'
                >
                    <Typography 
                        variant='h4'
                        sx={{
                            marginBottom: '25px'
                        }}
                    >
                        Receive the best trades through Discord and the app
                    </Typography>
                    <img src = {discord} style={{transform: 'scale(1)'}} alt="discord" />
                </Stack>
            
            </Stack>
        </Box>
    );
}
export default Story3;