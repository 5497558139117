import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import TokenWizardHelperText from "./TokenWizardHelperText";
import React from "react";
import { useTheme } from '@mui/material/styles'

const Vesting = ({pageData, setPageData}) => {
    const theme = useTheme()

    const handleHasVestedTokensChange = (e) => {
        setPageData({
            ...pageData,
            hasVestedTokens: e.target.value
        });
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant="h2" sx={{color: theme.palette.primary.main, marginTop: '20px'}}>Vesting</Typography>
            <Stack direction="row" display='flex' alignItems='center'>
                <Typography align='center' variant="h4" sx={{  color: theme.palette.grey[500]}}>
                    Has the project vested all of its tokens?
                </Typography>
                <TokenWizardHelperText
                    title={'Vesting'}
                    text={'Mature projects may already have all their tokens released to the public. Some tokens may still remain under the control of the foundation, often to be used as staking rewards.\nNote: The Details page of the project will show this info once uploaded.'} />
            </Stack>
            <FormControl sx={{mt: 1}}>
                <RadioGroup
                    column={true}
                    aria-labelledby="tradepost-radio-buttons-group"
                    name="tradepost-radio-buttons-group"
                    value={pageData.hasVestedTokens}
                    onChange={handleHasVestedTokensChange}
                >
                    <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                    <FormControlLabel value={"No"} control={<Radio />} label="No" />
                    <FormControlLabel value={"Unknown"} control={<Radio />} label="Unknown" />
                </RadioGroup>
            </FormControl>
        </Box>
    )
}

export default Vesting;