import React, { useEffect, useState } from 'react'
import { Typography, Grid, IconButton, Tooltip, Stack, Box } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { Check } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { TextField } from '@mui/material';
import fetchData from '../../utility/endpointFetch'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const PriceAlertsItem = ( { alert, editAlert, removeThisAlert } ) => {

    console.log("top of PriceAlertsItem and alert is:")
    console.dir(alert)

    const [target, setTarget] = useState(alert.price_target)
    const [frequency, setFrequency] = useState(alert.multiple)
    const [coinDetails, setCoinDetails] = useState([]);
    const theme = useTheme()

    useEffect(() => {
        const getVIPSearchFeedParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            try {
                let getDetailsURL = `api/v1/coin/ecoinscreener/?coin=` + alert.coin
                const getDetailsResponse = await fetchData(getDetailsURL, "could not receive alert notifications", getVIPSearchFeedParams).promise
                console.log("getDetailsURL is: " + getDetailsURL)
                console.log("getDetailsResponse is:")
                console.dir(getDetailsResponse)

                setCoinDetails(getDetailsResponse.results)
            } catch (error) {
                console.log("something is broke receiving current searchfeed hot searches: ", error)
            }
        })()
    }, [])



    return (
        <Grid container xs={12} spacing={2}>
            <Grid item xs={2}>
                {
                    coinDetails.length ?
                        <Stack sx={{paddingHorizontal: 10}} direction={'row'} justifyContent={'center'} alignItems={'center'}>
                            <img style={{width: 40, height: 40}} src={coinDetails[0].image_thumbnail} alt={coinDetails[0].coin} />
                            <h4 color={'#fff'}>{coinDetails[0].coin}</h4>
                        </Stack>
                    :
                        <h4 color={'#fff'}>{alert.coin}</h4>
                }
            </Grid>
            <Grid item xs={4}>
                <TextField value={target} onChange={e => setTarget(e.target.value)} />
            </Grid>
            <Grid item xs={4}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={frequency}
                    label="Frequency"
                    sx={{width: '100%'}}
                    onChange={e => setFrequency(e.target.value)}
                >
                    <MenuItem value={1}>Once</MenuItem>
                    <MenuItem value={3}>Three Times</MenuItem>
                    <MenuItem value={5}>Five Times</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={2}>
                <Tooltip title="update the price target for this alert">
                    <IconButton
                        onClick={() => editAlert(alert.alert_id, target, frequency)}>
                        <Check color={'primary'}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="delete this price alert">
                    <IconButton
                        onClick={() => removeThisAlert(alert.alert_id)}
                        size="small" >
                        <Delete
                            sx={{fontSize: '1.2rem', color: theme.palette.error.main}}
                        />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )

}

export default PriceAlertsItem