import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, Stack, Box, Avatar, Button  } from '@mui/material';
import { AddBox, Exposure, IndeterminateCheckBox, Twitter, Star, Person, FavoriteBorder, Repeat } from '@mui/icons-material'
import { IconCoin } from '@tabler/icons'
import { DateTime } from 'luxon'

import {FiExternalLink} from 'react-icons/fi'
import reactStringReplace from 'react-string-replace'
import { communitySize } from "../../utility/objHelper"
import ResearchTwitterResultItemImages from '../Research3/ResearchTwitterResultItemImages'

const HomeFeedTwitterPost = ({twitterItem, filterByCoinInFeed, filterByHashInFeed, filterByFollowedInFeed, filterByMentionInFeed, divHeight=0, boxWidth=0}) => {

    //console.log("top of HomeFeedTwitterPost")
    //console.log("divHeight is: " + divHeight)
    //console.log("boxWidth is: " + boxWidth)
    //console.dir(twitterItem)

    var twitterText = twitterItem.text

    const theme = useTheme();

    //format time
    var trimmedTime = twitterItem.created.split("+")[0]
    var splitTime = trimmedTime.split("T")

    var quoteTrimmedTime = null
    var quoteSplitTime = null
    if (twitterItem.quote_created !== null) {
        quoteTrimmedTime = twitterItem.quote_created.split("+")[0]
        quoteSplitTime = quoteTrimmedTime.split("T")
    }

    var retweetPercentage = ((twitterItem.retweets / twitterItem.favorites) * 100).toFixed(2)

    //display polarity (positive/negative)
    const polarityDisplay = (passedDirection, passedCount) => {
        if (passedCount === 0) return null
        if (passedDirection === 1) {
        return [ <AddBox sx={{color: theme.palette.primary.main, fontSize: "0.8rem"}}/>,  polarityDisplay(passedDirection, (passedCount-1) ) ]
        } else {
        return [ <IndeterminateCheckBox sx={{color: theme.palette.secondary.main, fontSize: "0.8rem"}}/>,  polarityDisplay(passedDirection, (passedCount-1) ) ]
        }
    }
    var direction = 0
    var count = 0
    switch(true) {
        case parseFloat(twitterItem.polarity) >= .7:
            direction = 1
            count = 3
        break
        case parseFloat(twitterItem.polarity) >= .4:
            direction = 1
            count = 2
        break
        case parseFloat(twitterItem.polarity) >= .1:
            direction = 1
            count = 1
        break
        case parseFloat(twitterItem.polarity) <= -.1:
            direction = -1
            count = 1
        break
        case parseFloat(twitterItem.polarity) <= -.4:
            direction = -1
            count = 2
        break
        case parseFloat(twitterItem.polarity) <= -.7:
            direction = -1
            count = 3
        break
        default:
            //leave default values
        break
    }

    //display efficacy (how strong match to purpose)
    const efficacyDisplay = (passedCount) => {
        if (passedCount === 0) return null
        return [ <Star sx={{color: theme.palette.primary.main, fontSize: "1.0rem"}}/>,  efficacyDisplay((passedCount-1)) ]

    }
    var efficacyCount = 0
    switch(true) {
        case parseFloat(twitterItem.subjectivity) >= .7:
            efficacyCount = 3
        break
        case parseFloat(twitterItem.subjectivity) >= .4:
        efficacyCount = 2
        break
        case parseFloat(twitterItem.subjectivity) >= .1:
        efficacyCount = 1
        break
        default:
        //leave default values
        break
    }

    const displayAvatar = () => {
        if (twitterItem.twitter_user_avatar_url === '') {
            return <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
        } else {
            return <Avatar alt={twitterItem.twitter_display_name} src={twitterItem.twitter_user_avatar_url} sx={{ width: 42, height: 42, marginRight: '3px' }}/>
        }

    }

    //replace link with preview
    //replace @
    twitterText = reactStringReplace(twitterText, /@(\w+)/g, (match, i) => (
        <Button key={'attext'+match + i} onClick={()=>setMentionedCascade(`${match}`,twitterItem.coins === null ? '' : `${twitterItem.coins[0]}`)} style={{cursor: 'pointer', color: theme.palette.success.dark}}>@{match}</Button>
    ))

    //replace #
    twitterText = reactStringReplace(twitterText, /#(\w+)/g, (match, i) => (
        <Button key={'hashtext'+match + i} onClick={()=>setHashCascade(`${match}`,twitterItem.coins === null ? '' : `${twitterItem.coins[0]}`)} style={{cursor: 'pointer', color: theme.palette.warning.dark}}>#{match}</Button>
    ))

    twitterText = reactStringReplace(twitterText, /(https:\/\/\S+)/g, (match, i) => (
        <a key={'search'+match} href={match} target="_blank" rel="noreferrer" style={{cursor: 'pointer', color: theme.palette.primary.main}}>{match}</a>
    ))

    const units = [
        'year',
        'month',
        'week',
        'day',
        'hour',
        'minute',
        'second',
    ];

    const timeAgo = () => {
        let dateTime = DateTime.fromISO(twitterItem.created)
        const diff = dateTime.diffNow().shiftTo(...units);
        const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

        const relativeFormatter = new Intl.RelativeTimeFormat('en', {
        numeric: 'auto',
        });
        return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
    };

    const setCoinCascade = (coin) => {
        console.log("top of setCoinCascade in HomeFeedTwitterPost and coin is:")
        console.dir(coin)
        //changeCoinInTab(coin)
        filterByCoinInFeed(coin)
    }

    const setFollowedCascade = (coin,user) => {
        console.log("top of setFollowedCascade")
        //console.log("coin is: " + coin)
        filterByFollowedInFeed(user)
    }

    const setMentionedCascade = (atted,coin) => {
        console.log("top of setMentionedCascade")
        //console.log("atted is "+ atted)
        filterByMentionInFeed(atted)
    }

    const setHashCascade = (hashed,coin) => {
        console.log("top of setHashCascade")
        //console.log("hashed is "+ hashed)
        filterByHashInFeed(hashed)
    }

    const linkToTweet = `https://twitter.com/${twitterItem.twitter_display_name}/status/${twitterItem.tweet_id}`

    return (
        <Grid container
            sx={{
                paddingTop: '10px',
                '&:hover': {backgroundColor: theme.palette.background.default}
            }}
            key={twitterItem.id}
        >
    
        <Grid item xs={1} sx={{paddingTop: '5px', paddingLeft: '5px'}}>
            <Stack direction="column" sx={{marginBottom: '10px'}} display="flex" justifyContent="space-between" alignItems="center">
                {displayAvatar()}
            </Stack>
        </Grid>
        <Grid item xs={11} sx={{padding: "8px", }}>
            <Box sx={{borderRadius: '5px',  padding: "5px", margin: '0px',  }}>
            <Grid container >
                <Grid item xs={12} onClick={()=>setFollowedCascade(twitterItem.coins === null ? '' : twitterItem.coins[0],twitterItem.twitter_display_name)} sx={{cursor: 'pointer'}}>
                <Stack direction="row" sx={{display: "flex", alignItems: "center", justifyContent: 'space-between',}}>
                    <Stack direction="row">
                        <Twitter sx={{marginRight: "5px", marginTop: '2px', fontSize: "1.2rem", color: theme.palette.primary.main}} />
                        <Typography variant="subtitle1" sx={{fontWeight: 600, color: theme.palette.grey[500]}}>{twitterItem.display_name}</Typography>
                        <Typography variant="subtitle1" sx={{fontWeight: 400, color: theme.palette.grey[300], marginLeft: '10px'}}>@{twitterItem.twitter_display_name}</Typography>
                        <Typography variant="subtitle1" sx={{fontWeight: 400, color: theme.palette.grey[300], marginLeft: '10px'}}>{timeAgo()}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <Person sx={{fontSize: '1rem'}} color={theme.palette.grey[500]} />
                        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[300], paddingLeft: '3px', marginTop: '3px'}}>{communitySize(twitterItem)}</Typography>
                        <a key={'linktotweet'+twitterItem.tweet_id} target="_blank" rel="noreferrer" style={{position: 'relative', top: '2px', left: '3px'}} href={linkToTweet}><FiExternalLink key={'linktotweeticon'+twitterItem.tweet_id} color={theme.palette.primary.main} /></a>
                    </Stack>
                </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{color: theme.palette.grey[500], fontSize: '.9rem'}}>{twitterText}</Typography>
                </Grid>
                <Grid item xs={12} onClick={()=>setFollowedCascade(twitterItem.coins === null ? '' : twitterItem.coins[0],twitterItem.twitter_display_name)} sx={{cursor: 'pointer'}}>
                <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{marginTop: '8px'}}>
                    <Stack direction="row" alignItems="center">
                        <Repeat sx={{fontWeight: 400, color: theme.palette.grey[400], fontSize: "1.2rem", transform: "rotate(90deg)"}} />
                        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '5px', marginTop: '3px'}}>{twitterItem.retweets}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <FavoriteBorder sx={{fontWeight: 400, color: theme.palette.grey[400], fontSize: "1.2rem"}} />
                        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '5px', marginTop: '2px'}}>{twitterItem.favorites}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{marginTop: "0px"}} alignItems="center">
                        <Repeat sx={{fontWeight: 400, color: theme.palette.grey[400], fontSize: "1.2rem", transform: "rotate(90deg)"}} />
                        <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400], marginTop: '2px'}}>%</Typography>
                        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], marginLeft: '5px', marginTop: '2px'}}>{retweetPercentage}</Typography>
                    </Stack>

                    <Stack direction="row" sx={{marginTop: "0px"}} alignItems="center">
                        <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400], marginRight: '3px', marginTop: '2px'}}>social engagement</Typography>
                        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], marginLeft: '5px', marginTop: '2px'}}>{twitterItem.social_engagement ? twitterItem.social_engagement : 0}</Typography>
                    </Stack>

                    <Stack direction="row" sx={{marginTop: "0px"}} alignItems="center">
                        <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400], marginRight: '3px', marginTop: '2px'}}>polarity</Typography>
                        {direction === 0 ? <Exposure sx={{fontWeight: 600, color: theme.palette.grey[500], fontSize: "0.9rem"}}/> : polarityDisplay(direction, count)}
                    </Stack>
                    <Stack direction="row" sx={{marginTop: "0px"}} alignItems="center">
                        <Typography variant="caption" sx={{color: theme.palette.grey[400], marginRight: '5px'}}>relevance</Typography>
                        {direction === 0 ? <Typography variant="caption" sx={{color: theme.palette.grey[500],}}>none</Typography>  : efficacyDisplay(efficacyCount)}
                    </Stack>
                </Stack>
                </Grid>
                    <Grid item xs={12} >
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{marginTop: '5px'}}>
                        <Stack direction="row"display="flex" justifyContent="space-around" alignItems="center" spacing={2}>
                            <IconCoin strokeWidth={1} size={15} color={theme.palette.grey[500]}  />
                            {twitterItem.coins !== null && twitterItem.coins.map( (coin, index ) => {
                                return <Typography key={'twittercoins' + coin + index} variant="subtitle1" display="inLine" sx={{fontWeight: 600, cursor: 'pointer', color: theme.palette.primary.main, marginLeft: '5px'}} onClick={()=>setCoinCascade( coin )} > {coin}</Typography>
                                })
                            }
                        </Stack>
                        <Stack direction="row" onClick={()=>setFollowedCascade(twitterItem.coins === null ? '' : twitterItem.coins[0],twitterItem.twitter_display_name)} sx={{cursor: 'pointer'}}>
                            <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>created:</Typography>
                            <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[800], paddingLeft: '5px'}}>{splitTime[0]} {splitTime[1].slice(0,-1)} UTC</Typography>
                        </Stack>
                    </Stack>        
                </Grid>
                { twitterItem.attachments !== null && twitterItem.attachments.length > 0 ?
                    <Grid item xs={12}>
                        <Box sx={{width: '100%', paddingLeft: '50px', paddingRight: '50px', align: 'center'}}>
                            <ResearchTwitterResultItemImages images={twitterItem.attachments} tweetID={twitterItem.id} inDashboardHeight={divHeight} inDashboardWidth={boxWidth} />
                        </Box>
                    </Grid> : null 
                }
                
                {
                    twitterItem.quote_tweet_id && (
                        <Grid item xs={12}>
                            <Box sx={{marginLeft: 5, marginTop: 1, border: '1px solid #2b2b2b', borderRadius: 1, p: 1}}>
                            <Stack direction={'row'}>
                                {
                                twitterItem.quote_avatar_url ?
                                    (
                                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/${twitterItem.quote_display_name}/status/${twitterItem.quote_tweet_id}`}>
                                        <Avatar alt={twitterItem.quote_avatar_url} src={twitterItem.quote_avatar_url} sx={{ width: 42, height: 42, marginRight: '3px' }}/>
                                    </a>
                                    )
                                    :
                                    (
                                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/${twitterItem.quote_display_name}/status/${twitterItem.quote_tweet_id}`}>
                                        <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
                                    </a>
                                    )
                                }
                                <Typography variant="subtitle1" sx={{fontWeight: 600, color: theme.palette.grey[500]}}>{twitterItem.quote_display_name}</Typography>
                            </Stack>
                            <Stack direction={'row'}>
                                <Typography>{twitterItem.quote_text}</Typography>
                            </Stack>
                            {quoteSplitTime !== null ? 
                                <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-end" sx={{cursor: 'pointer'}}>
                                    <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>created:</Typography>
                                    <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[800], paddingLeft: '5px'}}>{quoteSplitTime[0]} {quoteSplitTime[1].slice(0,-1)} UTC</Typography>
                                </Stack>   
                            : null }                              
                            { twitterItem.quote_attachments !== null && twitterItem.quote_attachments.length > 0 ?
                                <Stack display="flex" direction="column" alignItems="center" sx={{maxWwidth: '100%'}}>
                                    <ResearchTwitterResultItemImages images={twitterItem.quote_attachments} tweetID={twitterItem.quote_tweet_id} inDashboardHeight={divHeight} inDashboardWidth={boxWidth} />
                                </Stack> : null
                            }
                            </Box>
                        </Grid>
                    )
                }     
            </Grid>
            </Box>
        </Grid>
        </Grid>        
    )
}

export default HomeFeedTwitterPost