import React, { useState } from 'react'
import { Box, Typography, Stack, Divider } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'

import InfluencerBodyResults from '../InfluencerTracking/InfluencerBodyResults'
import SocialInfluencerSearch from './SocialInfluencerSearch'

const SocialInfluencerDisplay = ( {panelID, coinToDependents, deleteBox} ) => {

    const theme = useTheme()

    const [coinQueryString,setCoinQueryString] = useState('')
    const [tweetQueryString,setTweetQueryString] = useState('')
    const [coinTweetQueryStringLabel,setCoinTweetQueryStringLabel] = useState('')

    const showCoinTweetAndChart = (coin) => {
        console.log("top of showCoinTweetAndChart and coin is:")
        console.dir(coin)
        coinToDependents(coin)
    }

    const removeInfluencerBox = () => {
        console.log("top of removeInfluencerBox and panelID is: " + panelID)
        deleteBox(panelID)
    }

    const filterCoinList = ( queryStringForCoin, queryStringForTweet ) => {
        //console.log("top of filterCoinList and queryStringForCoin is: " +queryStringForCoin)
        //console.log("queryStringForTweet is: " + queryStringForTweet)
        setCoinQueryString(queryStringForCoin)
        setTweetQueryString(queryStringForTweet)
    }                                       

    return (
        <Box sx={{ display: 'flex', width: 'calc(100% - 10px)', marginLeft: '5px' }}>
        <Stack direction="column" display="flex" alignItems='left' sx={{width: '100%'}}>
            <Stack direction="row" display="flex" alignItems='center' justifyContent='space-between' sx={{marginTop: '5px', marginRight: '5px'}}>
                <Stack direction="row" display="flex" alignItems='center' justifyContent='flex-start' >
                    <Typography display="inline" sx={{fontWeight: 400}} color={theme.palette.grey[300]}>{"Influencer Search Results"}</Typography>
                    {/*<Typography display="inline" sx={{fontWeight: 600, marginLeft: '5px'}} color={theme.palette.primary.main}>{coinTweetQueryStringLabel}</Typography>*/}
                </Stack>
                <Stack direction="row" justifyContent='flex-end'>  
                    <SocialInfluencerSearch 
                        filterCoinList={filterCoinList} 
                    />
                    <VscMove 
                        className='draggable-handle' 
                        color={theme.palette.warning.dark} 
                        size={18} 
                        style={{cursor: 'grabbing'}}
                    />
                    <MdCancel 
                        className='draggable-cancel' 
                        color={theme.palette.secondary.dark} 
                        size={18} 
                        onClick={removeInfluencerBox}
                        style={{cursor: 'pointer'}}
                    />

                </Stack>
            </Stack>   
            <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>  
            <InfluencerBodyResults 
                showCoinTweetAndChart={showCoinTweetAndChart}
                coinQueryString={coinQueryString}   
                researchCustom={true}         
            />
        </Stack>
        </Box>
    )
}

export default SocialInfluencerDisplay