import React from 'react'
import { Grid } from '@mui/material'

import ResearchBodyCoinItem from './ResearchBodyCoinItem'
import ResearchBodyTweetResultsItem from './ResearchBodyTweetResultsItem'

import { useTheme } from '@mui/material/styles'

const ResearchBodyResultItem = ( {tweetCoin, showCoinTweetsAndChart, coinTweetQueryString, divHeight=0, boxWidth=0 }) => {
    //console.log("top of ResearchBodyResultItem and tweetCoin is:")
    //console.dir(tweetCoin)

    const theme = useTheme()

    if (tweetCoin.coinscreener.length === 0) {
        //for some reason some of these posts do not have coins attached so ignore them
        console.log("skipping this one as coinscreener was not populated")
        return null 
    } else {
        return (
            <Grid container spacing={2}
                sx={{width: '99%', 
                borderRadius: '5px', 
                backgroundColor: theme.palette.dark[800], 
                '&:hover': {backgroundColor: theme.palette.dark.dark},
                boxShadow: 1,
                marginBottom: '5px', marginLeft: '3px', marginTop: '5px',paddingBottom: '15px'}}
            >
                <Grid item xs={6}>
                    <ResearchBodyTweetResultsItem 
                        key={tweetCoin.tweet_id}
                        tweetDetails={tweetCoin} 
                        coinTweetQueryString={coinTweetQueryString} 
                        showCoinTweetsAndChart={showCoinTweetsAndChart} 
                        divHeight={divHeight}
                        boxWidth={boxWidth}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ResearchBodyCoinItem 
                        key={tweetCoin.tweet_id}
                        coinDetails={tweetCoin} 
                        showCoinTweetsAndChart={showCoinTweetsAndChart} 
                    />
                </Grid>
            </Grid>
        )
    }
}
export default ResearchBodyResultItem