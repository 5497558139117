import React, { useState } from 'react'
import { Box, CssBaseline, Container,
  Typography, TextField, Grid, InputAdornment, IconButton, Button } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch'
import PasswordStrengthBar from 'react-password-strength-bar'

import Header from '../Header'
import Navigation from '../Navigation'
import Footer from '../Footer'
import hofs from '../../utility/hofs'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import TopAppBar from '../TopAppBar'

const newPasswordErrorMessage = 'Account password is required and must have a password score of at least \'good\' according to the strength meter'
const oldPasswordErrorMessage = 'Your current account password is required to change your password'

const ResetPassword = ( {userObject} ) => {

  const theme = useTheme()

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: '20px',
            width: `calc(100% - 20px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - 20px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - 20px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - 20px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
  }));

  const [newPassword,setNewPassword] = useState('')
  const [oldPassword,setOldPassword] = useState('')
  const [passwordVerifier,setPasswordVerifier] = useState('')
  const [passwordScore,setPasswordScore] = useState('')
  const [newPasswordError,setNewPasswordError] = useState(false)
  const [oldPasswordError,setOldPasswordError] = useState(false)
  const [passwordVerifierError, setPasswordVerifierError] = useState()
  const [passwordVerifierErrorMessage, setPasswordVerifierErrorMessage] = useState('')

  const [valuesOldPassword, setValuesOldPassword] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showOldPassword: false
  })

  const [valuesNewPassword, setValuesNewPassword] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showNewPassword: false
  })

  const [valuesPasswordVerifier, setValuesPasswordVerifier] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPasswordVerifier: false
  })

  const handleClickShowNewPassword = () =>
    setValuesNewPassword({ ...valuesNewPassword, showNewPassword: !valuesNewPassword.showNewPassword })

  const handleClickShowOldPassword = () =>
    setValuesOldPassword({ ...valuesOldPassword, showOldPassword: !valuesOldPassword.showOldPassword })

  const handleClickShowPasswordVerifier = () =>
    setValuesPasswordVerifier({ ...valuesPasswordVerifier, showPasswordVerifier: !valuesPasswordVerifier.showPasswordVerifier })

  const handleMouseDownNewPassword = e => e.preventDefault()
  const handleMouseDownPasswordVerifier = e => e.preventDefault()
  const handleMouseDownOldPassword = e => e.preventDefault()
  const handleOldPasswordChange = e => setOldPassword(e.target.value) //console.log("e.target.value for old: " + e.target.value + ' = ')//setOldPassword(e.target.value)
  const handleNewPasswordChange = e => setNewPassword(e.target.value) //console.log("e.target.value for New: " + e.target.value) //setNewPassword(e.target.value)
  //const handlePasswordVerifierChange = e => console.log("e.target.value for verifier: " + e.target.value)//setPasswordVerifier(e.target.value)
  const handlePasswordVerifierChange = e => setPasswordVerifier(e.target.value)

  const handlePasswordChangeSubmit = e => {
    e.preventDefault()
    let errorCount = 0

    //console.log("top of handlePasswordChangeSubmit")

    if (hofs.isPopulated(oldPassword)) {
      setOldPasswordError(false)
    } else {
      errorCount++
      setOldPasswordError(true)
    }

    if (passwordScore < 3) {
      //we want a stronger password than that
      errorCount++
      setNewPasswordError(true)
    } else {
      if (hofs.isPopulated(newPassword)) {
        setNewPasswordError(false)
      } else {
        errorCount++
        setNewPasswordError(true)
      }
    }

    if (hofs.isPopulated(passwordVerifier)) {
      if (passwordVerifier === newPassword) {
        setPasswordVerifierError(false)
        setPasswordVerifierErrorMessage('')
      } else {
        errorCount++
        setPasswordVerifierError(true)
        setPasswordVerifierErrorMessage(
          'Your password verifier must match the password inserted above.  Please check and make sure both match'
        )
      }
    } else {
      errorCount++
      setPasswordVerifierError(true)
      setPasswordVerifierErrorMessage(
        'You must insert a password verifier that matches your password to complete submission'
      )
    }

    // need to check if email is unique with backend
    if (errorCount === 0) {
      // there are no errors...send the data to backend to complete registration

      console.log("there are no errors in form...show verify email modal")

      const fetchParams = {
        method: 'PUT',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        })
      }
      //console.log("password change parameters are:" )
      //console.dir(fetchParams)
      const errorMessage = 'Error saving your password.  Please contact support to do so.'
      ;(async () => {
        const response = await fetchData('api/v1/users/resetpassword/', errorMessage, fetchParams).promise

        //console.log("password reset response is:")
        //console.dir(response)

        if (response === true) {
          // user password changed

        } else {
          //there is an error....what to do?
          if (response.error && response.error.password) {
            setNewPasswordError(true)
            setOldPasswordError(true)
          }
          console.log("password reset error is: " + response.error_message)
        }
      })()
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
        {/* header */}
        <TopAppBar userObject={userObject} selectedPage='' />
        {/* main content */}
        <Main theme={theme} >
          <Container maxWidth="xl">
            <Box sx={{width: '100%', paddingTop: '10px', paddingBottom: '20px'}}>
              <Container component='main' maxWidth='sm' sx={{marginTop: '50px', justifyContent: 'center'}}>
                <div sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <Typography variant='h3' sx={{paddingBottom: '25px'}}>
                    Make Changes To Your Password
                  </Typography>
                  <form sx={{ width: '100%', marginTop: theme.spacing(5.5) }} noValidate onSubmit={handlePasswordChangeSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          type={valuesOldPassword.showOldPassword ? 'text' : 'password'}
                          required
                          fullWidth
                          key='old_password'
                          id='old_password'
                          label='Current Password'
                          name='old_password'
                          autoComplete='current password'
                          onChange={handleOldPasswordChange}
                          helperText={oldPasswordError ? oldPasswordErrorMessage : null}
                          error={oldPasswordError}
                          value={oldPassword}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle current password visibility'
                                  onClick={handleClickShowOldPassword}
                                  onMouseDown={handleMouseDownOldPassword}
                                >
                                  {valuesOldPassword.showOldPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'

                          type={valuesNewPassword.showNewPassword ? 'text' : 'password'}
                          required
                          fullWidth
                          key='new_password'
                          id='new_password'
                          label='New Password'
                          name='new_password'
                          autoComplete='new password'
                          onChange={handleNewPasswordChange}
                          helperText={newPasswordError ? newPasswordErrorMessage : null}
                          error={newPasswordError}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle new password visibility'
                                  onClick={handleClickShowNewPassword}
                                  onMouseDown={handleMouseDownNewPassword}
                                >
                                  {valuesNewPassword.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <PasswordStrengthBar
                          password={newPassword}
                          onChangeScore={(score,feedback) => {
                              setPasswordScore(score)
                            }
                          } />
                      </Grid>
                      <Grid item xs={12}>
                      <TextField
                        variant='outlined'
                        type={valuesPasswordVerifier.showPasswordVerifier ? 'text' : 'password'}
                        required
                        fullWidth
                        id='password_verifier'
                        label='Password Verifier'
                        name='password_verifier'
                        autoComplete='password'
                        onChange={handlePasswordVerifierChange}
                        helperText={passwordVerifierError ? passwordVerifierErrorMessage : null}
                        error={passwordVerifierError}
                        InputProps={{
                          maxLength: 100,
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password verifier visibility'
                                onClick={handleClickShowPasswordVerifier}
                                onMouseDown={handleMouseDownPasswordVerifier}
                              >
                                {valuesPasswordVerifier.showPasswordVerifier ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      </Grid>
                    </Grid>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      sx={{ margin: theme.spacing(3, 0, 2) }}
                    >
                      Click to Save Password Changes
                    </Button>
                  </form>
                </div>



              </Container>

              <Footer />
            </Box>
          </Container>
        </Main>
      </Box>
  )
}

export default ResetPassword