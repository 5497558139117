import React from 'react'
import { Typography, Grid, Box, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import ResearchBodyTweetResultsItem from './ResearchBodyTweetResultsItem'

const ResearchBodyTweetResults = ({ coinFilterResults, coinFilterLoading, resultsHeader='' }) => {

    //console.log("--------TOP OF ResearchBodyTweetResults")

    const theme = useTheme()

    var boxHeight = '780px'
    if (resultsHeader.length > 0) {
        boxHeight = 'auto'
    }

    if (coinFilterLoading) return <CircularProgress />
    if (coinFilterResults.length > 0) {
        return (
            <Box sx={{width: '100%', height: boxHeight, overflow: 'auto',}}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography display="inline" sx={{color: theme.palette.secondary.main, marginLeft: '10px', marginTop: '5px' }}> {resultsHeader} tweets from filtered coins: ({coinFilterResults.length})</Typography> 
                    </Grid>
                    <Grid item xs={12}>
                        {coinFilterResults.map( (tweet, index) => <ResearchBodyTweetResultsItem key={'researchbodytweetresultsitem'+tweet.id+index} tweetDetails={tweet} />)}
                    </Grid>                
                </Grid>

            </Box>
        )
    } else {
        return (
            <Box sx={{width: '100%', height: boxHeight, overflow: 'auto',}}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography display="inline" sx={{color: theme.palette.secondary.main, marginLeft: '10px', marginTop: '10px'}}>{resultsHeader} tweets from filtered coins: (0)</Typography> 
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{justifyContent: 'center', marginLeft: '10px', marginTop: '15px'}}>
                            { resultsHeader.length === 0 ? <Typography display="inline" sx={{color: theme.palette.primary.main}}>Use the search section to the right to find coins and display their associated tweets here</Typography> : null }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default ResearchBodyTweetResults