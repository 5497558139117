import React from 'react'
import { Box } from '@mui/material'

import VIPAllAtFollowed from '../VIP/VIPAllAtFollowed'

const VIPTweetsAccountsTweets = ({twitterAccount, changeVIPTweetsAccountsTweetsTab, changeVIPTweetsSearchTab, changeVIPTweetsChartTab, changeVIPTweetsCoinTab, maxBoxHeight, divHeight=0, boxWidth=0}) => {

    const changeDateInChart = () => {
        //not applied at the moment
    }

    const setAtMentionedForTweetsSearchTab = (searchText) => {
        //need to set search type to mentioned
        changeVIPTweetsSearchTab(searchText,1)
    }

    const setHashForTweetsSearchTab = (searchText) => {
        //need to set search type to hash
        changeVIPTweetsSearchTab(searchText,2)
    }    

    return (
        <Box sx={{maxHeight: maxBoxHeight, overflowY: 'auto'}}>
            <VIPAllAtFollowed
                atFollowedTabValue={twitterAccount}
                setCoinInChart={changeVIPTweetsChartTab}
                changeAtFollowedTab={changeVIPTweetsAccountsTweetsTab}
                changeAtMentionedTab={setAtMentionedForTweetsSearchTab}
                changeHashTab={setHashForTweetsSearchTab}
                changeCoinInTab={changeVIPTweetsCoinTab}
                changeDateInChart={changeDateInChart}
                showOfficialTweets={changeVIPTweetsAccountsTweetsTab}
                divHeight={divHeight}
                boxWidth={boxWidth}
            />
        </Box>
    )
}

export default VIPTweetsAccountsTweets