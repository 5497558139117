import React, { useState, useEffect } from 'react'
import { CircularProgress, Box, CssBaseline, Container, Typography, Grid, Stack} from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'

import TopAppBar from '../TopAppBar'
import Footer from '../Footer'
import PriceAlertsItem from './PriceAlertsItem'

import fetchData from '../../utility/endpointFetch'

const PriceAlerts = ( { userObject } ) => {

    const theme = useTheme()

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
      ...theme.typography.mainContent,
      ...(!open && {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
          }),
          [theme.breakpoints.up('md')]: {
              marginLeft: '20px',
              width: `calc(100% - 20px)`
          },
          [theme.breakpoints.down('md')]: {
              marginLeft: '20px',
              width: `calc(100% - 20px)`,
              padding: '16px'
          },
          [theme.breakpoints.down('sm')]: {
              marginLeft: '10px',
              width: `calc(100% - 20px)`,
              padding: '16px',
              marginRight: '10px'
          }
      }),
      ...(open && {
          transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen
          }),
          marginLeft: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          width: `calc(100% - 20px)`,
          [theme.breakpoints.down('md')]: {
              marginLeft: '20px'
          },
          [theme.breakpoints.down('sm')]: {
              marginLeft: '10px'
          }
      })
    }));    

    const [userCoinAlerts,setUserCoinAlerts] = useState([])
    const [isLoading,setIsLoading] = useState(true)

    const getPriceAlerts = () => {
        setIsLoading(true)
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
        }
        ;(async () => {
            try {
                const userAlertsResponse = await fetchData(`api/v1/vip/useralert/`, "could not receive user coin price alerts", fetchParams).promise
                console.log("userAlertsResponse getting user coin alerts")
                console.dir(userAlertsResponse)
                setUserCoinAlerts(userAlertsResponse.results)
                setIsLoading(false)
            } catch (error) {
                console.log("something is broke getting user coin price alerts", error)
            }
        })()
    }

    useEffect(() => {
        if (userObject.user.is_vip) {
          //console.log("user IS VIP")
          getPriceAlerts()
          
        } else {
          //console.log("user is not vip")
          setIsLoading(false)
        }
    },[])

    const editAlert = (priceAlertId, newPrice, newMultiple) => {
        console.log("top of editAlerts and userCoinAlerts is:")
        console.dir(userCoinAlerts)
        let currentAlertToRemove = userCoinAlerts.filter( alert => alert.alert_id === priceAlertId)
        const fetchParams = {
            method: 'POST',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'coin': currentAlertToRemove[0].coin,
                'price_target': newPrice,
                'multiple': newMultiple
            })
        }
        console.dir(fetchParams)
        ;(async () => {
            try {
                console.log("fetchParams of editAlert is:")
                console.dir(fetchParams)
                let editAlertURL = `api/v1/vip/alertcreate/?alert_id=${priceAlertId}`
                const userEditAlertsResponse = await fetchData( editAlertURL , "could not update user coin price alerts", fetchParams).promise
                console.log("userEditAlertsResponse updating user coin alerts")
                console.log(editAlertURL)
                console.dir(userEditAlertsResponse)

                //update the current userCoinAlerts from the server...and probably wait a second before we do
                setTimeout( function () {
                    getPriceAlerts()
                }, 1000)
            } catch (error) {
                console.log("something is broke updating user coin price alerts", error)
            }
        })()
    }

    const removeThisAlert = (priceAlertId ) => {
        console.log("top of removeThisAlert and priceAlertId is: " + priceAlertId)
        let currentAlertToRemove = userCoinAlerts.filter( alert => alert.id === priceAlertId)
        const removeFetchParams = {
            method: 'DELETE',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'alert_id': priceAlertId,
            })            
          }
          console.log("removeFetchParams is:")
          console.dir(removeFetchParams)
          ;(async () => {
            try {
                let deleteAlertURL = `api/v1/vip/alertcreate/`
              const userDeleteAlertsResponse = await fetchData( deleteAlertURL , "could not delete user coin price alerts", removeFetchParams).promise
              console.log("userDeleteAlertsResponse deleting user coin alerts")
              console.log(deleteAlertURL)
              console.dir(userDeleteAlertsResponse)


              setTimeout( function () {
                getPriceAlerts()
                }, 1000)              
            } catch (error) {
              console.log("something is broke deleting user coin price alerts", error)
            }
          })()
    }    

    if (isLoading) return <CircularProgress />  
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <TopAppBar userObject={userObject} selectedPage='' />
            {/* main content */}
            <Main theme={theme} >
                <Container maxWidth="xl">
                    <Box sx={{width: '100%', paddingTop: '10px', paddingBottom: '20px'}}>
                        <Container component='main' maxWidth='sm' sx={{marginTop: '50px', justifyContent: 'center'}}>
                            <Stack sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Typography variant='h3' sx={{paddingBottom: '25px'}}>
                                Price Alert Management
                            </Typography>
                            <Typography variant='caption' sx={{paddingBottom: '15px'}}>
                                You can delete price alerts through this admin tool.  To add a price alert click on a chart through out the site that is 
                                displaying a coin you wish to add the price alert to.  You will then see a popup asking at what price you wish to set that
                                price alert at.
                            </Typography>
                            <Grid container>
                                {userCoinAlerts.map( (alert, index) => <PriceAlertsItem key={"userpricealertslist" +  index} alert={alert} editAlert={editAlert} removeThisAlert={removeThisAlert} />)}
                            </Grid>
                            </Stack>
                        </Container>
                        <Footer />
                    </Box>
                </Container>
            </Main>
        </Box>                        
    )
}

export default PriceAlerts