import React from 'react'
import { Typography, IconButton, Grid, Tooltip } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { TiArrowForward } from 'react-icons/ti'
import { useTheme } from '@mui/material/styles'
import { filter } from 'ramda'

const ResearchSearchSavedItem = ( { vipTrackedKeywords, savedSearch, removeSavedSearch, searchIndex, sendSearchToVip, filteredVIPSearchFeedList, userObject, sendSearchTermToVipTrackedKeywords, deleteSearchTermToVipTrackedKeywords } ) => {
    console.log("top of ResearchSearchSavedItem 00000000000000000000000000000000000000000000000000000")
    
    const theme = useTheme()
    var savedSearchDetails = savedSearch.split('||')
    console.log("vipTrackedKeywords is:")
    console.dir(vipTrackedKeywords) //keywords that are already in vip
    console.log("savedSearchDetails is: ")
    console.dir(savedSearchDetails)
    console.log("filteredVIPSearchFeedList is:")
    console.dir(filteredVIPSearchFeedList)
    console.log("searchIndex is: " + searchIndex)

    //check if current research saved search is already a vip search or not
    //check if current research saved search name (already filtered for this user) is in vipSearchFeedList
    //if not in list display link to add that search to searchFeed list
    //if already in list do not display link to add to searchfeed list
    var isVIPSearch = filteredVIPSearchFeedList.filter( vipSearchFeedItem => vipSearchFeedItem.name === savedSearchDetails[0])
    console.log("isVIPSearch is:")
    console.dir(isVIPSearch)

    var is_internal = false
    if (userObject.user.is_internal === 'true' || userObject.user.is_internal === true) is_internal = true

    const displaySearchToVIPLink = () => {
        if (is_internal) {
            if (isVIPSearch.length === 0) {
                //this saved researcher search is NOT currently a vip searchfeed search
                return (
                    <Tooltip title="Send to VIP Hot Searches">
                        <IconButton
                            size="small"
                            onClick={() => sendSearchToVip(searchIndex)} 
                        >
                            <TiArrowForward 
                                sx={{fontSize: '1.2rem', color: theme.palette.warning.main}} 
                                color={theme.palette.warning.main}
                                size={22}
                            />
                        </IconButton>  
                    </Tooltip>              
                )
            } else {
                return null
            }
        } else {
            return null
        }
    }

    var queryStringParts = savedSearchDetails[1].split('&')
    console.log("queryStringParts is:")
    console.dir(queryStringParts)
    var searched = queryStringParts.find(queryItem => queryItem.split('=')[0] === 'search')
    console.log("searched is: ")
    console.dir(searched)
    var searchToSend = searched && searched.length ? searched.split('=')[1] : ''
    console.log("searchToSend is: " + searchToSend)
    console.log("vipTrackedKeywords is:")
    console.dir(vipTrackedKeywords)
    var vipKeywordID = vipTrackedKeywords.filter( keywordToCheck => keywordToCheck.keyword === searchToSend)
    console.log("vipKeywordID is:")
    console.dir(vipKeywordID)
    let useThisVIPKeywordID = false
    if (vipKeywordID.length > 0) {
        useThisVIPKeywordID = vipKeywordID[0].id
    }



    let month = ["January","February","March","April","May","June","July",
        "August","September","October","November","December"];

    const displaySearchToVIPKeywords = () => {
        console.log("top of displaySearchToVIPKeywords")
        if (userObject.user.is_vip) {
            //get the "search term" from the query string
            if (searched && searched.length > 0) {
                console.log("searched is:")
                console.dir(searched)
                //search is there....see if already in vip tracked keywords
                let isSearchedTracked = vipTrackedKeywords.filter( keywordToCheck => {
                    console.log("keywordToCheck is:")
                    console.dir(keywordToCheck)
                    return keywordToCheck.keyword === searched.split('=')[1]
                })

                console.log("isSearchedTracked is: ")
                console.dir(isSearchedTracked)

                if (isSearchedTracked.length > 0) {
                    //already tracked
                    console.log("already in vipTrackedKeywords")
                    console.log("isSearchedTracked is:")
                    console.dir(isSearchedTracked)
                    return (
                        <Tooltip title={searchToSend + " is already in VIP Tracked Keyword List...click to remove"}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    deleteSearchTermToVipTrackedKeywords(useThisVIPKeywordID)
                                }}
                            >
                                <TiArrowForward 
                                    sx={{fontSize: '1.2rem'}} 
                                    color={theme.palette.error.dark}
                                    size={22}
                                />
                            </IconButton>  
                        </Tooltip>                           
                    )
                } else {
                    //console.log("what will be sent is the sendSearchTermToVipTrackedKeywords is: " + searchToSend)
                    return (
                        <Tooltip title={"Send " + searchToSend + " to VIP Tracked Keyword List"}>
                            <IconButton
                                size="small"
                                onClick={() => sendSearchTermToVipTrackedKeywords(searchToSend)} 
                            >
                                <TiArrowForward 
                                    sx={{fontSize: '1.2rem'}} 
                                    color={theme.palette.primary.light}
                                    size={22}
                                />
                            </IconButton>  
                        </Tooltip>              
                    )                
                }
            } else {
                //there is no search term within the search
                //console.log("there is no search term to compare add/compare to viptracked keywords")
                return null
            }     

        } else {
            //console.log("this is not a vip user..................")
            return null
        }
    }    



    return (
        <>
            <Grid item xs={9}>
                <Typography sx={{position: 'relative', top: '4px'}}>{savedSearchDetails[0]}</Typography>
            </Grid>
            <Grid item xs={1}>
                <Tooltip title="Remove Saved Search">
                <IconButton
                    size="small"
                    onClick={() => removeSavedSearch(searchIndex,useThisVIPKeywordID)} 
                >
                    <Delete 
                        sx={{fontSize: '1.2rem', color: theme.palette.error.main}} 
                    />
                </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={1}>
                {displaySearchToVIPLink()}
            </Grid>
            <Grid item xs={1}>
                {displaySearchToVIPKeywords()}
            </Grid>            
        </>
    )
}

export default ResearchSearchSavedItem