import { complement, isEmpty } from 'ramda'

const isNotEmpty = complement(isEmpty)
const isPopulated = function (val) {
  // use default javascript check first.
  // this handles null, undefined, 0, "", NaN
  if (typeof val === 'undefined' || !val) return false
  // [], {} are considered empty, return that as falsey
  // a string with only whitespace is also falsey
  if (isNotEmpty(val)) {
    if (val.trim) return val.trim().length > 0
    // not a string and isPopulated checks that, so all good
    return true
  }
  return false
}

const validateEmail = input => {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return input.match(emailRegex) ? true : false
}

const exportedObjects = {
  isPopulated,
  validateEmail,
}

export default exportedObjects
