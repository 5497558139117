import React, {useState, useEffect, useRef} from 'react'
import { Box, CircularProgress, Grid, Stack, Typography, Button, Divider, ButtonGroup  } from '@mui/material'
import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'
import { styled, useTheme } from '@mui/material/styles'

import { ColorType, createChart } from 'lightweight-charts'

import fetchData from '../../utility/endpointFetch'

const VIPTweetsChart = ( {panelID, coin='bitcoin', defaultChartInterval='1d', windowWidth, maxBoxHeight} ) => {

    console.log("top of VIPTweetsChart and coin is:")
    console.dir(coin)
    const theme = useTheme()

    const [ isLoading,setIsLoading ] = useState(true)
    const [ chartInterval, setChartInterval ] = useState(defaultChartInterval)
    const [ pageSize,setPageSize ] = useState(400) //default to daily candles
    //const [ dateAgo, setDateAgo] = useState(new Date(Date.now() - (400 * 24 * 60 * 60 * 1000)))
    //const [ chartBaseCurrencySymbol, setChartBaseCurrencySymbol ] = useState('$ ')
    //const [ chartBaseCurrency, setChartBaseCurrency ] = useState('USD')
    const [ chartRangeDetails, setChartRangeDetails ] = useState('1y/daily')  
    const [ overallWindowWidth,setOverallWindowWidth] = useState(windowWidth)    

    const candleStickData = useRef([])
    const volumeData = useRef([])  
    const chartRef = useRef()    
    const firstRun = useRef(true)

    const callInterval = 5000 //may change from one minute to more for larger time frames

    const chartOptions = {
        //width: chartRef.current.clientWidth,
        //width: '100%',
        height: maxBoxHeight, //need to change this to height of box....calculate from row height times y setting
        layout: {
        background: {
            type: ColorType.Solid,
            color: theme.palette.background.default,
        },
        textColor: theme.palette.primary.main,
        },
        crosshair: {
        mode: 0,
        },
        grid: {
        vertLines: {
            color: theme.palette.secondary.dark,
            style: 4,

        },
        horzLines: {
            color: theme.palette.secondary.dark,
            style: 4,
        }
        },
    }  

    const candleStickOptions = {
        upColor: theme.palette.success.main,
        downColor: theme.palette.error.main,
    }    
    
    const volumeSeriesOptions = {
        color: '#3b04bd',
        priceFormat: {
            type: 'volume',
        },
        priceScaleId: '', // set as an overlay by setting a blank priceScaleId
        // set the positioning of the volume series
        scaleMargins: {
            top: 0.7, // highest point of the series will be 70% away from the top
            bottom: 0,
        },
    }  

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: theme.palette.background.default
        },
        margin: '1px',
        padding: '1px'
    }));   
    
    const NonColorButton = styled(Button)(({ theme }) => ({
        margin: '1px',
        padding: '1px'
    })); 

    const crosshairMoveHandler = (param) => {
        // Create and style the tooltip html element
        if (
                param.point === undefined ||
                !param.time ||
                param.point.x < 0 ||
                param.point.y < 0
            ) 
        {
            return
        } else {
            //for later right click and add price notice
            //console.log("param inside crosshairMoveHandler is: ")
            //console.dir(param)
            return
        }
    }      
    
    const getChartData = () => {
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            }
        }
        ;(async () => {
            try {
            var getExchangeChartDataURL = `api/v1/coin/exchangechart${chartInterval}/?symbol=${coin}&page_size=${pageSize}&page=1&ordering=-timestamp`
            const exhangechartResponse = await fetchData(getExchangeChartDataURL, "could not receive exchange chart data", fetchParams).promise
            console.log("exhangechartResponse getting exchangechart data using coin: " + coin)
            console.log(exhangechartResponse)
            console.log("getExchangeChartDataURL is: ")
            console.dir(getExchangeChartDataURL)
            console.log("chartRef.current is:")
            console.dir(chartRef.current)

            var candleStickChart = createChart(chartRef.current, chartOptions, )
            var candleSeries = candleStickChart.addCandlestickSeries(candleStickOptions)    
            var volumeSeries = candleStickChart.addHistogramSeries(volumeSeriesOptions) 
            //candleStickChart.subscribeClick(myClickHandler)
            candleStickChart.subscribeCrosshairMove(crosshairMoveHandler)           

            ///nasty little thing to figure out
            //if the chart is changed...originally just appended below the others
            //need to remove the first child of chartRef (previous chart drawn)
            //so that can add a new chart...replacing instead of appending...ugh
            if (!firstRun.current) {
                console.log("NOT FIRST RUN....................")
                //chartRef.current = null
                chartRef.current.removeChild(chartRef.current.children[0])  
            } else {
                firstRun.current = false
            }

            var reversedExchangeResponseResult = exhangechartResponse.results.reverse()
            
            candleStickChart.applyOptions( {width: chartRef.current.clientWidth, height: (parseInt(chartRef.current.clientHeight)-50.0)})
            //console.log("chartRef.current is:")
            //console.dir(chartRef.current)
            //console.log("chartRef.current.clientHeight is: " + parseInt(chartRef.current.clientHeight))
            //console.log("parseInt(chartRef.current.clientHeight)-50 is: " + (parseInt(chartRef.current.clientHeight)-50.0))
            //console.log("chartRef.current.clientWidth is: " + chartRef.current.clientWidth)
            
            var filteredCandleStick = reversedExchangeResponseResult.map( chartData => {
                return {
                "time": chartData.time,
                "open": chartData.open,
                "high": chartData.high,
                "low": chartData.low,
                "close": chartData.close,
                }
            })
            candleStickData.current = filteredCandleStick

            var filteredVolume = reversedExchangeResponseResult.map( chartData => {
                return {
                "time": chartData.time,
                "value": chartData.volume
                }
            })
            volumeData.current = filteredVolume
            candleSeries.setData(filteredCandleStick)
            volumeSeries.setData(filteredVolume)
            let currentChartTimerID = setInterval(getNewCandle, callInterval, candleSeries, volumeSeries, candleStickData, volumeData)

            setIsLoading(false)

            return () => clearInterval(currentChartTimerID)
            
            } catch (error) {
            console.log("something is broke getting exchange chart data: ", error)
            }
        })()        
    }    

    useEffect(() => {
        //console.log("top of useEffect of ChartDisplayByCoin and coin is============================================: ")
        //console.dir(coin)
        //console.log("coins is:")
        //console.dir(coins)
        setIsLoading(true)
        //console.log("chartRef.current is:")
        //console.dir(chartRef.current)
        //console.dir(chartRef)
        getChartData()

        
    }, [chartInterval,coin,overallWindowWidth])   
    
    const getNewCandle = (candleSeries, volumeSeries ) => {

        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ;(async () => {
            try {
                var getTenCandleExchangeChartDataURL = `api/v1/coin/exchangechart${chartInterval}/?symbol=${coin}&page_size=10&page=1&ordering=-timestamp`
                const getTenCandleExhangechartResponse = await fetchData(getTenCandleExchangeChartDataURL, "could not receive one candle exchange chart data", fetchParams).promise
                //console.log("getTenCandleExhangechartResponse getting one candle exchange data using coin: " + coin)
                //console.dir(getTenCandleExhangechartResponse)
                //console.log("getTenCandleExchangeChartDataURL is: ")
                //console.dir(getTenCandleExchangeChartDataURL)   
                var candleSticksToAdd = getTenCandleExhangechartResponse.results.filter( candle => candle.time > candleStickData.current[candleStickData.current.length - 1].time)
    
                //console.log("candleSticksToAdd is: ")
                //console.dir(candleSticksToAdd)
    
                if (candleSticksToAdd.length > 0) {
                    var convertedCandleSticksToAdd = candleSticksToAdd.map( candle => {
                        return {
                        "time": candle.time,
                        "open": candle.open,
                        "high": candle.high,
                        "low": candle.low,
                        "close": candle.close,
                        }
                    })
                    //need to sort candles by time going up
                    convertedCandleSticksToAdd.sort((a,b) => a.time - b.time)
        
                    //console.log("convertedCandleSticksToAdd is: ")
                    //console.dir(convertedCandleSticksToAdd)
        
                    var convertedVolumeToAdd = candleSticksToAdd.map( candle => {
                        return {
                        "time": candle.time,
                        "value": candle.volume,
                        }
                    })
                    //need to sort candles by time going up
                    convertedVolumeToAdd.sort((a,b) => a.time - b.time)    
                    //console.log("convertedVolumeToAdd is: ")
                    //console.dir(convertedVolumeToAdd)  
        
                    //console.log("ADDING NEW CANDLES top: " + convertedVolumeToAdd.length)
                    candleStickData.current = [...candleStickData.current, convertedCandleSticksToAdd]
                    volumeData.current = [...volumeData.current, convertedVolumeToAdd]
        
                    convertedCandleSticksToAdd.map(candleToAdd => candleSeries.update(candleToAdd))
                    convertedVolumeToAdd.map(candleToAdd => volumeSeries.update(candleToAdd))
                    //candleSeries.update(convertedCandleSticksToAdd)
                    //volumeSeries.update(convertedVolumeToAdd)              
                
                } else {
                    //there are no candles to add
                    //console.log("NO CANDLES TO ADD")
                }
            } catch (error) {
                console.log("something is broke getting exchange chart data: ", error)
            }
        })().then(() => {
            const fetchParams = {
                    method: 'GET',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ;(async () => {
                try {
                    var getTenCandleExchangeChartDataURL = `api/v1/coin/exchangechart${chartInterval}/?symbol=${coin}&ordering=-timestamp`
                    var getTenCandleExhangechartResponse = await fetchData(getTenCandleExchangeChartDataURL, "could not receive one candle exchange chart data", fetchParams).promise
                    var candleSticksToAdd = getTenCandleExhangechartResponse.results.filter( candle => candle.time > candleStickData.current[candleStickData.current.length - 1].time)
                    //console.log("candleStickData.current[candleStickData.current.length - 1].time is: " + candleStickData.current[candleStickData.current.length - 1].time)
                    //console.log("candleSticksToAdd is: ")
                    //console.dir(candleSticksToAdd)
                    //console.log("getTenCandleExchangeChartDataURL is :")
                    //console.log(getTenCandleExchangeChartDataURL)
                    //console.log("getTenCandleExhangechartResponse is:")
                    //console.dir(getTenCandleExhangechartResponse)

                    if (candleSticksToAdd.length > 0) {
                        var convertedCandleSticksToAdd = candleSticksToAdd.slice(candleSticksToAdd.length - 1, candleSticksToAdd.length).map( candle => {
                            return {
                                "time": candle.time,
                                "open": candle.open,
                                "high": candle.high,
                                "low": candle.low,
                                "close": candle.close,
                            }
                        })
                        //need to sort candles by time going up
                        convertedCandleSticksToAdd.sort((a,b) => a.time - b.time)

                        //console.log("convertedCandleSticksToAdd is: ")
                        //console.dir(convertedCandleSticksToAdd)

                        var convertedVolumeToAdd = candleSticksToAdd.map( candle => {
                            return {
                                "time": candle.time,
                                "value": candle.volume,
                            }
                        })
                        //need to sort candles by time going up
                        convertedVolumeToAdd.sort((a,b) => a.time - b.time)
                        //console.log("convertedVolumeToAdd is: ")
                        //console.dir(convertedVolumeToAdd)

                        //console.log("ADDING NEW CANDLES: " + convertedVolumeToAdd.length)
                        candleStickData.current = [...candleStickData.current, convertedCandleSticksToAdd]
                        volumeData.current = [...volumeData.current, convertedVolumeToAdd]

                        convertedCandleSticksToAdd.map(candleToAdd => candleSeries.update(candleToAdd))
                        convertedVolumeToAdd.map(candleToAdd => volumeSeries.update(candleToAdd))
                        //candleSeries.update(convertedCandleSticksToAdd)
                        //volumeSeries.update(convertedVolumeToAdd)

                    } else {
                        //there are no candles to add
                        //console.log("NO CANDLES TO ADD")
                    }
                } catch (error) {
                    console.log("something is broke getting exchange chart data: ", error)
                }
            })()
        })
    }        

    const handleChartIntervalChange = ( interval) => {
        //console.log("top of handleChartIntervalChange...interval is: ")
        //console.dir(interval)
        switch (interval) {
          case '1w':
            setChartRangeDetails('2y/weekly')
            setChartInterval('1w')
            setPageSize(120)
            break;        
          case '1d':
            setChartRangeDetails('1y/daily')
            setChartInterval('1d')
            setPageSize(400)
            break;
          case '4h':
            setChartRangeDetails('3 months/4 hours')
            setChartInterval('4h')
            setPageSize(540) 
            break;
          case '1h':
            setChartRangeDetails('14 days/1 hours')
            setChartInterval('1h')
            setPageSize(336) //14 days
            break;
          case '15m':
            setChartRangeDetails('4 days/15 min')
            setChartInterval('15m')
            setPageSize(384)
            break;
          case '5m':
            setChartRangeDetails('2 days/5 min')
            setChartInterval('5m')
            setPageSize(576)
            break;
          case '1m':
            setChartRangeDetails('12 hours/i min')
            setChartInterval('1m')
            setPageSize(720)
            break;
          default:
            //not sure what this is
            setChartRangeDetails('')
        }
    }    

    return (
        <Box
            sx={{
                paddingTop: '5px',
                display: 'flex', 
                width: 'calc(100% - 20px)',
                marginLeft: '20px'
            }}
        >      
            <Stack direction="column" sx={{width: 'calc(100% - 10px)'}}>
                <Stack direction="row" alignItems="center" justifyContent='space-between'>
                    <Typography color={theme.palette.primary.main}>{coin.toUpperCase() + " " + chartRangeDetails}</Typography>
                    {/*<Chip label={coin.toUpperCase() + " " + chartRangeDetails} sx={{backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.light, borderRadius: '10px'}} variant="outlined" />*/}
                    <ButtonGroup size="small" aria-label="choose interval in chart" sx={{paddingRight: '5px'}}>
                        { chartInterval === '1w' ? <ColorButton  variant="contained">1w</ColorButton> : <NonColorButton  onClick={() => handleChartIntervalChange('1w')}>1w</NonColorButton> }
                        { chartInterval === '1d' ? <ColorButton  variant="contained">1d</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('1d')}>1d</NonColorButton> }
                        { chartInterval === '4h' ? <ColorButton  variant="contained">4h</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('4h')}>4h</NonColorButton> }
                        { chartInterval === '1h' ? <ColorButton  variant="contained">1h</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('1h')}>1h</NonColorButton> }
                        { chartInterval === '14m' ? <ColorButton variant="contained">15m</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('15m')}>15m</NonColorButton> }
                        { chartInterval === '5m' ? <ColorButton  variant="contained">5m</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('5m')}>5m</NonColorButton> }
                        { chartInterval === '1m' ? <ColorButton  variant="contained">1m</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('1m')}>1m</NonColorButton> }
                    </ButtonGroup>
                </Stack>
                <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>
                <Grid container sx={{marginTop: '20px'}}>
                    <Grid item xs={12}  >
                        <div ref={chartRef} >
                        { isLoading ? <CircularProgress /> : null }
                        </div>
                    </Grid>
                </Grid>
            </Stack>
        </Box>        
    )
}

export default VIPTweetsChart