import React from 'react'
import { Typography, Stack, Box } from '@mui/material'
import { XAxis, YAxis, Line, LineChart, ResponsiveContainer  } from "recharts"

import { useTheme } from '@mui/material/styles'
const HealthDisplayStockToFlow = ( { ruleThemAll } ) => {

  const theme = useTheme()

  const abbreviateValue = (flowValue) => {
    if (flowValue > 1000000000) {
      return ((flowValue / 1000000000).toFixed(2)) + 'B'
    } else if (flowValue > 1000000) {
      return ((flowValue/1000000).toFixed(2)) + 'M'
    } else if (flowValue > 1000) {
      return ((flowValue/1000).toFixed(2)) + 'K'
    } else {
      //just display the full amount
      return flowValue.toFixed(2)
    }
  }

  //only need last 14 days of data
  //var stockToFlowData = ruleThemAll.slice((ruleThemAll.length-8), (ruleThemAll.length-1)) //as data is latest first ....this does not work
  var stockToFlowData = ruleThemAll.slice(0, 7) //as data is latest first ....this does not work

  stockToFlowData.reverse()

  if (stockToFlowData.length === 0) {
    return (
      <Box sx={{width: '100%'}} >
        <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[500]}}>Stock To Flow</Typography>
        <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
      </Box>
    )
  } else {

    //console.log("stockToFlowData is:")
    //console.dir(stockToFlowData)
    //console.dir(stockToFlowData[0])
    //console.dir(stockToFlowData[0].timestamp)

    let timestart = stockToFlowData[0].timestamp
    let timeend = stockToFlowData[6].timestamp

    var rechartsStockToFlowData = stockToFlowData.map( (data) => {
      return { 'name': 0, 'value': data.healthData.stock_to_flow , 'amt': data.healthData.stock_to_flow, "date": data.healthData.timestamp }
    })

    //get data range for reserve data
    var minMaxStockToFlow = stockToFlowData.map( (data) => {
      if (data.healthData)
        return data.healthData.stock_to_flow
    })

    var stockToFlowMax = minMaxStockToFlow.reduce((a, b) => { return Math.max(a, b) })
    var stockToFlowMin = minMaxStockToFlow.reduce((a, b) => { return Math.min(a, b) })

    const displayQuickDate = (timestamp) => {
      let dateOfData = new Date(timestamp)
      return dateOfData.getMonth() + '/' + dateOfData.getDate()
    }  

    return (
      <Stack
        direction="column"
        sx={{width: '100%', backgroundColor: theme.palette.dark.dark, marginTop: '5px', padding: '5px', paddingTop: '0px', borderRadius: '5px', minHeight: '6vh'}}
      >
        <Stack
          direction="row"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>Stock To Flow (14 days)</Typography>
        </Stack>
        <Box sx={{width: '99%', backgroundColor: theme.palette.dark.main, justifyContent: "flex-end" }} >
          <Stack direction="column" display="flex" justifyContent="space-around" alignItems="center">
            <ResponsiveContainer width='97%' height={115}>
              <LineChart
                data={rechartsStockToFlowData}
              >
                <XAxis 
                  dataKey="date"
                  tickFormatter={ (value) => displayQuickDate(value)}
                  style={{fontSize: '.6rem'}}
                  domain={[ stockToFlowData[0].timestamp, stockToFlowData[6].timestamp]}
                />
                <YAxis 
                  dataKey="value"
                  domain={[stockToFlowMin,stockToFlowMax]} 
                  tickFormatter={(value) => abbreviateValue(value)}
                  tick={{stroke: '#888', strokeWidth: .5}}
                  orientation='right'
                />
                <Line strokeWidth={2} type="monotone" dataKey="value" dot={false} stroke={theme.palette.primary.dark} />
              </LineChart>
            </ResponsiveContainer>
          </Stack>
        </Box>
      </Stack>
    )
  }
}

export default HealthDisplayStockToFlow