import React from 'react'
import {  Typography, Stack, Box, Tooltip, IconButton } from '@mui/material'
import QuickFearAndGreedGauge2 from './QuickFearAndGreedGauge2'

import { IconArrowUpRightCircle } from '@tabler/icons'
import { HelpOutline } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'
const QuickFearAndGreed = ( { ruleThemAll, changeTab } ) => {

  const theme = useTheme()

  var useThisFearAndGreedData = ruleThemAll[0]

  if (ruleThemAll.length === 0) {
    return (
      <Box sx={{width: '100%'}} >
        <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.primary.dark}}>Fear And Greed</Typography>
        <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
      </Box>
    )
  } else {

    //console.log("useThisFearAndGreedData is:")
    //console.dir(useThisFearAndGreedData)
    //console.dir(ruleThemAll)

    var greedAndFearColor = '#b00c0c'
    var greedAndFearDesc = 'EXTREME FEAR'
    if ((useThisFearAndGreedData.healthData.fear_and_greed >= 20) && (useThisFearAndGreedData.healthData.fear_and_greed < 40)) {
      greedAndFearColor = '#c7620a'
      greedAndFearDesc = 'FEARFUL'
    } else if ((useThisFearAndGreedData.healthData.fear_and_greed >= 40) && (useThisFearAndGreedData.healthData.fear_and_greed < 60)){
      greedAndFearColor = '#c4c70a'
      greedAndFearDesc = 'NEUTRAL'
    } else if ((useThisFearAndGreedData.healthData.fear_and_greed >= 60) && (useThisFearAndGreedData.healthData.fear_and_greed < 80)) {
      greedAndFearColor = '#c7ab0a'
      greedAndFearDesc = 'GREEDY'
    } else if (useThisFearAndGreedData.healthData.fear_and_greed >= 80) {
      greedAndFearColor = '#0ac729'
      greedAndFearDesc = 'EXTREME GREED'
    }

    return (
        <Stack
          direction="column"
          sx={{backgroundColor: theme.palette.dark.dark, padding: '5px', paddingTop: '0px', borderRadius: '5px', minHeight: '18.5vh', cursor: 'pointer', margin: '10px'}}
          onClick={() => changeTab('11')}

        >
          <Stack
            direction="row"
            display="flex"
            justifyContent='space-between'
            alignItems="center"
          >
            <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>Fear And Greed</Typography>
            <Stack direction="row" display="flex" alignItems="center">
              <Tooltip title="The crypto market behaviour is very emotional. People tend to get greedy when the market is rising which results in FOMO (Fear of missing out). Also, people often sell their coins in irrational reaction of seeing red numbers. With our Fear and Greed Index, we try to save you from your own emotional overreactions. The fear and greed indicator is derived from volatility, market momentum/volume, social media, surveys, dominance and trends."  >
                <IconButton size="small" aria-label="full chart">< HelpOutline sx={{fontSize: '1.1rem', color: theme.palette.grey[500]}} /></IconButton>
              </Tooltip>   
              <Tooltip title='click for full chart'  ><IconButton size="small" aria-label="full chart">< IconArrowUpRightCircle size={18} color={theme.palette.grey[500]} /></IconButton></Tooltip>
            </Stack>
          </Stack>
          <Stack           
            direction="row"
            display="flex"
            justifyContent='center'
            alignItems="center" 
            sx={{backgroundColor: theme.palette.dark.dark, minHeight: '14.75vh'}}
          >
            <Box sx={{width: '50%', paddingTop: '20px', paddingBottom: '5px'}} >
              <QuickFearAndGreedGauge2 fearAndGreedValue={useThisFearAndGreedData.healthData.fear_and_greed} />
            </Box>
            <Typography variant="h3" sx={{marginLeft: '5px', fontWeight: 700, color: greedAndFearColor}}>{greedAndFearDesc}</Typography>
          </Stack>
          {/*
          <Box sx={{width: '99%', backgroundColor: '#fff', paddingTop: '8px', paddingBottom: '5px'}} >
            <Stack direction="column" alignItems="center" >
              <Avatar sx={{width: 50, height: 50, bgcolor: greedAndFearColor, display: 'flex', justifyContent: 'center', fontSize: '.8rem', color: theme.palette.grey[100] }}>{useThisFearAndGreedData.healthData.fear_and_greed}</Avatar>
              <Typography variant="Button" sx={{marginLeft: '5px', fontWeight: 700, color: greedAndFearColor}}>{greedAndFearDesc}</Typography>
            </Stack>
    </Box>*/}
        </Stack>
    )
  }
}

export default QuickFearAndGreed