import React, { useState, useRef } from 'react'

import { Box, CssBaseline, Container, Grid } from '@mui/material'

import TopAppBar from '../TopAppBar'
import InfluencerSearch from './InfluencerSearch'

import InfluencerCoinChart from './InfluencerCoinChart'
import InfluencerCoinChartBTC from './InfluencerCoinChartBTC'
import InfluencerBodyResults from './InfluencerBodyResults'
import InfluencerSocialResults from './InfluencerSocialResults'

const InfluencerTracking = ( {userObject} ) => {
    //console.log("top of InfluencerTracking and userObject is: ")
    //console.dir(userObject)

    const [chartDays,setChartDays] = useState(3)
    const [chartLabel,setChartLabel] = useState('bitcoin')
    const [chartInterval,setChartInterval] = useState('hourly')    
    const [chartRangeDetails,setChartRangeDetails] = useState('2w/hourly')  
    const [chartCoin,setChartCoin] = useState('')  
    const [chartCoinName,setChartCoinName] = useState('')
    const [tweetCoin,setTweetCoin] = useState('')

    const [coinQueryString,setCoinQueryString] = useState('')
    const [tweetQueryString,setTweetQueryString] = useState('')

    const influencerBodyResultsRef = useRef()
    const coinChartRef = useRef()

    const filterCoinList = ( queryStringForCoin, queryStringForTweet ) => {
        //console.log("top of filterCoinList and queryStringForCoin is: " +queryStringForCoin)
        //console.log("queryStringForTweet is: " + queryStringForTweet)
        setCoinQueryString(queryStringForCoin)
        setTweetQueryString(queryStringForTweet)
    }  

    const influencerChartLabelChange = (label, chartDays, interval) => {
        setChartLabel(label)
        setChartDays(chartDays)
        setChartInterval(interval)
        switch (chartDays) {
            case 365:
                setChartRangeDetails('1y/daily')
              break;
            case 180:
                setChartRangeDetails('6m/daily')
              break;
            case 90:
                setChartRangeDetails('3m/daily')
              break;
            case 30:
                setChartRangeDetails('1m/daily')
              break;
            case 14:
                setChartRangeDetails('2w/hourly')
              break;
            case 7:
                setChartRangeDetails('1w/hourly')
              break;
            case 3:
                setChartRangeDetails('3d/hourly')
              break;
            case 1:
                setChartRangeDetails('1d/5m')
              break;
            default:
              //not sure what this is
          }        
    }        

    const showCoinTweetAndChart = (coinToShowInChart) => {
        //console.log("top of showCoinTweetAndChart and coinToShowInChart is: ")
        if(typeof coinToShowInChart === 'object') {
            //console.log("coinToShowInChart is an object")
            //console.log("setting chartCoin to (coinToShowInChart.coin.id): "+coinToShowInChart.coin.id)
            //console.log("setting chartCoinName to:" + coinToShowInChart.name)
            //console.log("setting tweetCoin to: " + coinToShowInChart.coin.id )
            setChartCoinName(coinToShowInChart.coin)
            setTweetCoin(coinToShowInChart.coin)
            setChartCoin(coinToShowInChart.coin)

        } else {
            //console.log("coinToShowInChart is NOT an object")
            //console.log("setting chartCoin to coinToShowInChart: "+coinToShowInChart)
            setChartCoinName(coinToShowInChart)
            setChartCoin(coinToShowInChart)
            setTweetCoin(coinToShowInChart)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <TopAppBar userObject={userObject} selectedPage="influencer"/>
            <Container maxWidth="false" sx={{marginTop: '80px'}}>
                <Grid container spacing={2} sx={{marginTop: '10px'}}>
                    <Grid item xs={3.5}>
                        <InfluencerSocialResults tweetCoin={tweetCoin} showCoinTweetAndChart={showCoinTweetAndChart} tweetQueryString={tweetQueryString} />
                    </Grid>
                    <Grid item xs={6.5} >
                        <Box ref={influencerBodyResultsRef} sx={{ height: window.innerHeight - 100, display: 'flex', alignItems: 'flex-start',}} >
                            <Grid  container>
                                <Grid item xs={6} ref={coinChartRef}>
                                    <Box sx={{width: '100%'}}>
                                        <InfluencerCoinChart
                                            coin={chartCoin} 
                                            chartName={chartCoin}
                                            chartDays={chartDays} 
                                            chartLabel={chartLabel} 
                                            influencerChartLabelChange={influencerChartLabelChange} 
                                            chartInterval={chartInterval} 
                                            chartRangeDetails={chartRangeDetails}
                                            userObject={userObject}
                                            chartCoinName={chartCoinName}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{width: '100%'}}>
                                        <InfluencerCoinChartBTC userObject={userObject} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{width: '100%'}}>
                                        <InfluencerBodyResults 
                                            showCoinTweetAndChart={showCoinTweetAndChart}
                                            coinQueryString={coinQueryString}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>                            
                        </Box>                        
                    </Grid>
                    <Grid item xs={2}>
                        <InfluencerSearch filterCoinList={filterCoinList} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default InfluencerTracking