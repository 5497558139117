import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconArrowUpCircle, IconArrowDownCircle } from '@tabler/icons';

const MVRVRatio = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About MVRV Ratio</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - MVRV Ratio">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
                MVRV (Market Value to Realized Value) ratio is defined as an asset's market capitalization divided by realized capitalization.
              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="h4">By Value Itself</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                MVRV accounts both realized cap and market cap into account making certain values critical in making an investment decision.
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowUpCircle color='#00b341' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Values over ‘3.7’: Possible Market Top"
                      secondary="If the values go above 3.7, it could be reasonable to sell the position off "
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Values under ‘1’: Possible Market Bottom"
                      secondary="If the values go under 1, it is time for taking a gradual long position.  "
                    />
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="h4">By examining trend</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconArrowUpCircle color='#00b341'/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Increasing trend: Increasing selling pressure"
                      secondary="As MVRV increases, it indicates that the market cap is outpacing realized cap meaning there is increasing motive for selling in the market."
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Decreasing trend: Decreasing selling pressure"
                      secondary="As MVRV decreases, it indicates that realized cap is outpacing the market cap meaning there is decreasing motive for selling in the market."
                    />
                  </ListItem>
                </List>
              </Typography>
            </TabPanel>

          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default MVRVRatio