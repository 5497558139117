import React, { useState } from 'react'

import { Paper, InputBase, Stack, TextField, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { Search, Clear } from '@mui/icons-material'

const PortfolioChooseSearchChoices = ( {searchFavorites, clearSearchFavorites } ) => {

  const theme = useTheme()

  const [searchTerm,setSearchTerm] = useState('')
  const [searchTermError,setSearchTermError] = useState(false)
  const [searchTermErrorMessage,setSearchTermErrorMessage] = useState('')

  const handleSearchTermChange = e => {
    if (searchTermError) setSearchTermError(false)
    setSearchTerm(e.target.value)
  }

  const searchSubmitted = () => {
    console.log("value of searchTerm is: " + searchTerm)

    //search term cannot have spaces
    //search term must be minimum of 3 spaces
    

    searchTerm.replace(/\s+/g, '')
    if (searchTerm.length > 0) {
      //console.log("searching..." + searchTerm)
      searchFavorites(searchTerm)
    } else {
      setSearchTermError(true)
      setSearchTermErrorMessage('search term needs to be at least 1 character')
    }

  }

  const clearSearch = () => {
    setSearchTerm('')
    setSearchTermError(false)
    setSearchTermErrorMessage('')
    clearSearchFavorites()
  }

  const handleSearchSubmitted = (e) => {
    e.preventDefault()
    console.dir(e)
    console.log("top of handleSearchSubmitted and searchTerm is: " + searchTerm)
  }  

  //console.log("searchTermError is: " + searchTermError)
  //console.log("searchTermError is: " + searchTermError)

  return (
    <form onSubmit={handleSearchSubmitted}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <TextField
          name='searchTerm'
          variant='outlined'
          required
          value={searchTerm}
          id='searchTerm'
          label='Search Crypto'
          autoComplete='Search Term'
          onChange={handleSearchTermChange}
          helperText={searchTermError ? searchTermErrorMessage : null}
          error={searchTermError}
          inputProps={{ maxLength: 25 }}
          size="small"
        />

        <IconButton
          type="submit"
          sx={{ p: '10px' }}
          aria-label="search"
          onClick={() => searchSubmitted()}
        >
          <Search sx={{ color: theme.palette.grey[500] }}  />
        </IconButton>
        <IconButton
          type="submit"
          sx={{ p: '10px' }}
          aria-label="search"
          onClick={() => clearSearch()}
        >
          <Clear sx={{ color: theme.palette.grey[500] }}  />
        </IconButton>
      </Stack>
    </form>

  )
}

export default PortfolioChooseSearchChoices