import React, { useState } from 'react'
import { Grid, Typography, Stack, Button, Chip, chipClasses, Modal, Backdrop, Fade, Box, Tooltip, Checkbox, useMediaQuery } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import { RiVipCrownFill } from 'react-icons/ri'
import CoinDetailsPrice from './CoinDetailsPrice'
import TokenWizard from '../TokenWizard/TokenWizard'
import fetchData from "../../utility/endpointFetch"
import CoinDetailsCorrelationBTC from './CoinDetailsCorrelationBTC'
import CoinDetailsCorrelationETH from './CoinDetailsCorrelationETH'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import { Switch } from "@material-ui/core";

const CoinDetailsTitle = ( {currencyDetails, firstPrice, lastPrice, userObject, allCoinsInVIP, allCoinsInVIPWithScreenerName, isLwChart, setIsLwChart, hasLwChart} ) => {
  //console.log("top of CoinDetailsTitle and currencyDetails is: ")
  //console.dir(currencyDetails)
  //console.dir(userObject)
  //console.log("userObject.user.is_internal is: " + userObject.user.is_internal)
  //console.log("allCoinsInVIP is: ")
  //console.dir(allCoinsInVIP)

  const theme = useTheme()
  const medScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"))
  console.log("medScreenOrSmaller is: " + medScreenOrSmaller)

  var is_internal = false
  if(userObject.user.is_internal === 'true' || userObject.user.is_internal === true) is_internal = true

  //console.log("allCoinsInVIP contains:")
  //console.dir(allCoinsInVIP.includes(currencyDetails.coin.id))

  var watchListsAndCoinIncludingThisCoin = allCoinsInVIPWithScreenerName.filter( coinAndScreener => coinAndScreener.coin_id === currencyDetails.coin.id)

  var watchListsIncludingThisCoin = watchListsAndCoinIncludingThisCoin.map(coinAndScreener => { return { "screener_name": coinAndScreener.screener_name, "id": coinAndScreener.id}})
 
  const [addToWatchListModal, setAddToWatchListModal] = useState(false)

  const closeVIPModal = () => {
    setAddToWatchListModal(false)
  }
  const displayModalVIP = () => {
    setAddToWatchListModal(true)

  }  

  const displayScreenerChoice = (thisScreener, screenerNameIndex) => {
    //console.log("top of displayScreenerChoice and thisScreener is: " + thisScreener + " and watchListsIncludingThisCoin is:")
    //console.dir(watchListsIncludingThisCoin)
    let found = watchListsIncludingThisCoin.find(screener => screener.screener_name === thisScreener)
    //console.log("found is:")
    //console.dir(found)
    //this coin is within this screener
    let defaultIsChecked = false
    if (thisScreener !== 'All Coins') {
      if (found !== undefined) {
        return (
          <Stack key={screenerNameIndex} direction="row" display="flex" alignItems='center' justifyContent="flex-start">
            <Tooltip title={"Remove "+ currencyDetails.coin.id + " from "+thisScreener}>
              <Checkbox sx={{fontSize: '.9rem', marginLeft: '5px', marginRight: '10px'}} defaultChecked onChange={(e)=>removeOrAddToScreener(e,thisScreener,found)} />
            </Tooltip>
            <Typography variant="body1">{thisScreener}</Typography>
          </Stack>
        )
      } else {
        return (
          <Stack key={screenerNameIndex} direction="row" display="flex" alignItems='center' justifyContent="flex-start">
            <Tooltip title={"Remove "+ currencyDetails.coin.id + " from "+thisScreener}>
              <Checkbox sx={{fontSize: '.9rem', marginLeft: '5px', marginRight: '10px'}} onChange={(e)=>removeOrAddToScreener(e,thisScreener,found)} />
            </Tooltip>
            <Typography variant="body1">{thisScreener}</Typography>
          </Stack>
        )
      }
    } else {
      //no need to add "all coins" as a list to choose....because any coins is automatically on that list
    }
  }  

  const addToVIP = (screenerName) => {
    //console.log("top of addToVIP and screenerName is: " + screenerName)
    const fetchParams = {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'screener_name': screenerName,
        'user': userObject.user,
        'coin_id': currencyDetails.coin.id
      })
    }
    ;(async () => {
      try {
        const saveCoinToScreenerResponse = await fetchData(`api/v1/users/screenercoincreate/`, "could not save coin to screener", fetchParams).promise
        //console.log("saveCoinToScreenerResponse saving coin to screener")
        //console.dir(saveCoinToScreenerResponse)

        //need to add this coin/screener from allCoinsInVIPWithScreenerName and allCoinsInVIP???

      } catch (error) {
        console.log("something is broke saving coin to screener: %s", error)
      }
    })()
  }  

  const removeFromVIP = (screenerItemId) => {
    //console.log("top of removeFromVIP and screenerItemId is: " + screenerItemId)
    const fetchParams = {
      method: 'DELETE',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'coin_id': currencyDetails.coin.id
      })
    }
    ;(async () => {
      try {
        let removeCoinFromScreenerURL = `api/v1/users/screenercoinupdate/${screenerItemId}/`
        const removeCoinFromScreenerResponse = await fetchData(removeCoinFromScreenerURL, "could not remove coin from screener", fetchParams).promise
        //console.log("removeCoinFromScreenerResponse removing coin from screener")
        //console.dir(removeCoinFromScreenerResponse)
        //console.log(removeCoinFromScreenerURL)

        //need to remove this coin/screener from allCoinsInVIPWithScreenerName and allCoinsInVIP???

      } catch (error) {
        console.log("something is broke saving coin to screener: %s", error)
      }
    })()
  }  

  const removeOrAddToScreener = (e,screenerNameToAddTo,screenerItemObject) => {
    //console.log("top of removeOrAddToScreener and screenerItemObject is: ")
    //console.dir(screenerItemObject)
    if (e.target.checked === true) {
      //checkbox is now checked...add to screener
      addToVIP(screenerNameToAddTo)
    } else {
      //checkbox is NOT checked....remove from screener
      removeFromVIP(screenerItemObject.id)
    }
  }
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
              '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ?  '#1D9BF0' : '#003892',
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

  if (medScreenOrSmaller) {
    console.log("medScreenOrSmaller in CoinDetailsTitle")
    return (
      <Grid container
        sx={{
          paddingLeft: '8px',
          paddingTop: '20px',
          paddingRight: '5px',
          width: '100%'
        }}
      >
        <Grid item xs={1}>
          <Typography sx={{paddingTop: '18px'}}><img src={ currencyDetails.images !== null ? currencyDetails.images.thumbnail : null} alt={"image for "+ currencyDetails.coin.id} /></Typography>
        </Grid>
        <Grid item xs={5}>
          <Stack direction="row" sx={{position: 'relative', top: '43%' }} display="flex" alignItems="center" justifyContent="flex-start">
            <Typography variant="h3" sx={{marginRight: '15px'}}>{currencyDetails.name} </Typography>
            {
              allCoinsInVIP.includes(currencyDetails.coin) ?
                <Chip size="small" label="" onClick={()=>displayModalVIP()} icon={<RiVipCrownFill size={14} />} sx={{backgroundColor: theme.palette.success.dark, color: theme.palette.success.light, [`& .${chipClasses.icon}`]: { color: theme.palette.success.light }}} variant="outlined" />
                    :
                <Chip size="small" label="" onClick={()=>displayModalVIP()} icon={<RiVipCrownFill size={14} />} sx={{backgroundColor: theme.palette.primary.dark , color: theme.palette.primary.light, [`& .${chipClasses.icon}`]: { color: theme.palette.primary.light }}}></Chip>  
            }
          </Stack>
        </Grid>    
        <Grid item xs={6}>
          <CoinDetailsPrice firstPrice={firstPrice} currencyDetails={currencyDetails} lastPrice={lastPrice} medScreenOrSmaller={medScreenOrSmaller} />
        </Grid>


      </Grid>
    )
  } else {

    return (
      <Grid container
        sx={{
          paddingTop: '20px',
        }}>
        <Grid item xs={.25}>
          <Typography sx={{paddingTop: '18px'}}><img src={ currencyDetails.image_thumbnail !== null ? currencyDetails.image_thumbnail : null} alt={"image for "+ currencyDetails.coin} /></Typography>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" sx={{position: 'relative', top: '43%' }} display="flex" alignItems="center" justifyContent="flex-start">
            <Typography variant="h3" sx={{marginRight: '15px'}}>{currencyDetails.coin} </Typography>
            {
              allCoinsInVIP.includes(currencyDetails.coin) ?
                <Chip size="small" label="vip" onClick={()=>displayModalVIP()} icon={<RiVipCrownFill size={14} />} sx={{backgroundColor: theme.palette.success.dark, color: theme.palette.success.light, [`& .${chipClasses.icon}`]: { color: theme.palette.success.light }}} variant="outlined" />
                    :
                <Chip size="small" label="add to vip" onClick={()=>displayModalVIP()} icon={<RiVipCrownFill size={14} />} sx={{backgroundColor: theme.palette.primary.dark , color: theme.palette.primary.light, [`& .${chipClasses.icon}`]: { color: theme.palette.primary.light }}}></Chip>  
            }
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around" sx={{position: 'relative', top: '43%' }}>
            {is_internal ? 
              <TokenWizard coin={currencyDetails.coin} userObject={userObject} /> : null}
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around" sx={{position: 'relative', top: '43%' }}>
            <CoinDetailsCorrelationBTC currencyDetails={currencyDetails} />
            <CoinDetailsCorrelationETH currencyDetails={currencyDetails} />
          </Stack>
        </Grid>
        <Grid item xs={1.5} alignItems={'center'} justifyContent={'center'}>
          {
            hasLwChart ?
                <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{position: 'relative', top: '35%'}}>
                  <Typography sx={{textAlign: 'center'}}>{isLwChart ? 'Candlestick Chart': 'Price Chart'}</Typography>
                  <MaterialUISwitch
                      checked={isLwChart}
                      onChange={(event) => setIsLwChart(event.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Stack>
                :
                null
          }
        </Grid>
        <Grid item xs={2}>
          <Stack direction="row" spacing={3} sx={{display: "flex", justifyContent: "flex-end",position: 'relative', top: '35%'}}>
            <CoinDetailsPrice firstPrice={firstPrice} currencyDetails={currencyDetails} lastPrice={lastPrice} />
          </Stack>
        </Grid>
        <Modal
          open={addToWatchListModal}
          aria-labelledby='spring-modal-title-vip'
          aria-describedby='spring-modal-description-vip'
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          disableEscapeKeyDown={false}        
        >
          <Fade in={addToWatchListModal}>
            <Box sx={{ position: 'absolute', width: 400, backgroundColor: theme.palette.background.default , border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)'}}>
              <Stack direction="column">
                <Typography variant='h5' sx={{marginBottom: '15px'}}>Choose Which WatchList To Add {currencyDetails.coin}</Typography>
                {userObject.screener_name.map( (screenerName,screenerNameIndex) => displayScreenerChoice(screenerName, screenerNameIndex))}
                <Button variant="outlined" startIcon={<CancelPresentationIcon />} onClick={()=>closeVIPModal()} >Close</Button>
              </Stack>
            </Box>
          </Fade>
        </Modal>       
      </Grid>
    )
  }
}

export default CoinDetailsTitle