import React, { useState } from 'react'
import { Box, Grid, Tab, Typography } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import { IconBook, IconSearch } from '@tabler/icons';

const StockToFlow = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About Stock To Flow</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - Exchange Inflows">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
                Stock to Flow (SF) Ratio is defined as a ratio of currently circulating coins divided by newly supplied coins
              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
              SF ratio assumes that the scarcity of the coin drives the price of the coin. The analysis on SF ratio is first presented by a pseudonymous Dutch institutional investor who operates under the Twitter account “PlanB”. By looking at SF ratio as a chart, we can spot where the market regime is.
              <br />-If Newly supplied coins decrease, SF Ratio rises.
              <br />-If circulating coins rises, SF Ratio rises. 
              </Typography>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default StockToFlow