import React, { useEffect, useState } from 'react'
import { Stack, Typography, Divider, Grid, Tab, Box } from '@mui/material'

import { useTheme } from '@mui/material/styles'

const FeedStats = ( { homeFeedListings, filterByCoinInFeed, filterByHashInFeed, filterByFollowedInFeed, filterByMentionInFeed} ) => {

    const theme = useTheme()

    //go through homeFeedList to get all @ accounts
    var all_atted_words = []
    homeFeedListings.map( (listing) => {
        if (listing.text.match(/@[a-z0-9_-]+/gi) !== null) {
            var matched = listing.text.match(/@[a-z0-9_-]+/gi)
            matched.map( (this_match) => all_atted_words.push(this_match))
        }}
    )
    //setAttedWords(all_atted_words)

    var displayAttedWords = []
    all_atted_words.map( (attedWord) => {
        //console.log("inside top of homeFeedList getting attedWords map: " )
        //console.log("displayAttedWords is: ")
        //console.dir(displayAttedWords)
        if (displayAttedWords.length === 0) {
            displayAttedWords.push( { "atted": attedWord, "attedCount": 1 })
        } else {
            var found = displayAttedWords.findIndex(( displayWord ) => displayWord.atted.toLowerCase() === attedWord.toLowerCase())
            //console.log("found is: " + found)
            //console.log("displayAttedWords[found] is: " + displayAttedWords[found])
            if (found != -1) {
                displayAttedWords[found].attedCount++
            } else  {
                displayAttedWords.push( { "atted": attedWord, "attedCount": 1 })
            }
        }
    })

    displayAttedWords.sort((a, b) => (a.attedCount < b.attedCount ? 1 : -1));
    //console.log("displayAttedWords complete is: ")
    //console.dir(displayAttedWords)    

    //go through homeFeedListings to get unique hashtags (#)
    var all_hashed_words = []
    homeFeedListings.map( (listing) => {
        if (listing.text.match(/#[a-z0-9_-]+/gi) !== null) {
            var matched = listing.text.match(/#[a-z0-9_-]+/gi)
            //all_hashed_words = all_hashed_words.concat(listing.text.match(/#[a-z0-9_-]+/gi))
            matched.map( (this_match) => all_hashed_words.push(this_match))
        }}
    )
    //console.log("all_hashed_words is: ")
    //console.dir(all_hashed_words)
    //setHashedWords(all_hashed_words)

    var displayHashedWords = []
    all_hashed_words.map( (hashedWord) => {
    //console.log("inside top of hashedWords map: " + hashedWord)
    //console.log("displayHashedWords is: ")
    //console.dir(displayHashedWords)
    if (displayHashedWords.length === 0) {
        displayHashedWords.push( { "hashed": hashedWord, "hashedCount": 1 })
    } else {
        var found = displayHashedWords.findIndex(( displayWord ) => displayWord.hashed.toLowerCase() === hashedWord.toLowerCase())
        //console.log("found is: " + found)
        //console.log("displayHashedWords[found] is: " + displayHashedWords[found])
        if (found != -1) {
            displayHashedWords[found].hashedCount++
        } else  {
            displayHashedWords.push( { "hashed": hashedWord, "hashedCount": 1 })
        }
    }
    })
    //console.log("displayHashedWords complete is: ")
    //console.dir(displayHashedWords)

    displayHashedWords.sort((a, b) => (a.hashedCount < b.hashedCount ? 1 : -1));    

    var displayFollowed = []
    homeFeedListings.map( ( listing ) => {
    //console.log("inside top of displayFollowed map: " + followed)
    //console.log("displayFollowed is: ")
    //console.dir(displayFollowed)
    if (displayFollowed.length === 0) {
        displayFollowed.push( { "followed": listing.twitter_display_name, "followedCount": 1 })
    } else {
        var found = displayFollowed.findIndex(( displayfollow ) => displayfollow.followed.toLowerCase() === listing.twitter_display_name.toLowerCase())
        //console.log("found is: " + found)
        //console.log("displayFollowed[found] is: " + displayFollowed[found])
        if (found != -1) {
            displayFollowed[found].followedCount++
        } else  {
            displayFollowed.push( { "followed": listing.twitter_display_name, "followedCount": 1 })
        }
    }
    })
    //console.log("displayFollowed complete is: ")
    //console.dir(displayFollowed)

    displayFollowed.sort((a, b) => (a.followedCount < b.followedCount ? 1 : -1));

    //go through homeFeedListings to get unique hashtags (#)
    var all_coins = []
    homeFeedListings.map( (listing) => {
        //add each coin if not currently in the list
        listing.coins.map( coin => {
            //add coin to list
            all_coins.push(coin)
        })
    })
    //console.log("all_coins is: ")
    //console.dir(all_coins)

    var displayCoins = []
    all_coins.map( (coin) => {
        //console.log("inside top of all_coins map: " + coin)
        //console.log("displayCoins is: ")
        //console.dir(displayCoins)
        if (displayCoins.length === 0) {
            displayCoins.push( { "coin": coin, "coinCount": 1 })
        } else {
            var found = displayCoins.findIndex(( displayCoin ) => displayCoin.coin.toLowerCase() === coin.toLowerCase())
            //console.log("found is: " + found)
            //console.log("displayCoins[found] is: " + displayCoins[found])
            if (found != -1) {
                displayCoins[found].coinCount++
            } else  {
                displayCoins.push( { "coin": coin, "coinCount": 1 })
            }
        }
    })
    //console.log("displayCoins complete is: ")
    //console.dir(displayCoins)

    displayCoins.sort((a, b) => (a.coinCount < b.coinCount ? 1 : -1));               

    return (
        <Box sx={{width: '100%', paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', scrollbarWidth: 'thin'}}>
            <Grid container spacing={1} sx={{paddingTop: '15px' }}>
                <Grid item xs={3}>
                    <Stack direction="column">
                        <Divider textAlign="left" ><Typography variant="caption" sx={{paddingTop: '1px' }}>#</Typography></Divider>
                        <Stack direction='column'>
                        {displayHashedWords.map( (hashedWord, index) =>
                            <Typography key={"hashed"+index} onClick={ () => filterByHashInFeed(hashedWord.hashed)} sx={{fontSize: '.75rem', cursor: 'pointer', fontWeight: 700, paddingLeft: '5px', color: theme.palette.primary.main}}>{hashedWord.hashed} ({hashedWord.hashedCount})</Typography>
                        )}
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction="column">
                        <Divider textAlign="left" ><Typography variant="caption" sx={{paddingTop: '1px' }}>@ mentioned</Typography></Divider>
                        <Stack direction='column'>
                        {displayAttedWords.map( (attedWord, index) =>
                            <Typography key={"atted"+index} onClick={() => filterByMentionInFeed(attedWord.atted)} sx={{fontSize: '.75rem', cursor: 'pointer', fontWeight: 700, paddingLeft: '5px', color: theme.palette.primary.main}}>{attedWord.atted} ({attedWord.attedCount})</Typography>
                        )}
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Divider textAlign="left" ><Typography variant="caption" sx={{paddingTop: '1px' }}>@ followed</Typography></Divider>
                    {displayFollowed.map( ( followed, index ) =>
                        <Typography key={"followed"+index} onClick={() => filterByFollowedInFeed(followed.followed)} sx={{fontSize: '.75rem', cursor: 'pointer', fontWeight: 700, paddingLeft: '5px', color: theme.palette.primary.main}}>@{followed.followed} ({followed.followedCount})</Typography>
                    )}
                </Grid>
                <Grid item xs={3}>
                    <Divider textAlign="left" ><Typography variant="caption" sx={{paddingTop: '1px' }}>Coins Mentioned</Typography></Divider>
                    {displayCoins.map( ( coin, index ) =>
                        <Typography key={"coined"+index} onClick={() => filterByCoinInFeed(coin.coin)} sx={{fontSize: '.75rem', cursor: 'pointer', fontWeight: 700, paddingLeft: '5px', color: theme.palette.primary.main}}>{coin.coin} ({coin.coinCount})</Typography>
                    )}
                </Grid>                            
            </Grid> 
        </Box>

    )
}

export default FeedStats