
// action - state management
import * as actionTypes from './types';

export const initialState = {
    watchlistName: 'All Coins'
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const vipReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_WATCHLIST_NAME:
            return {
                ...state,
                watchlistName: action.payload
            };
        default:
            return state;
    }
};

export default vipReducer;