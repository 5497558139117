import React, {useState, useEffect} from 'react'
import { Box, CircularProgress, Typography, Stack, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const VIPAddCoinCurrentScreener = ( { vipScreenerName, localUserData, currentScreeners } ) => {
    //console.log("top of VIPAddCoinCurrentScreener and localUserData is:")
    //console.dir(localUserData)
    //console.dir(currentScreeners)
    //console.log("vipScreenerName is: " + vipScreenerName)

    const theme = useTheme()

    const [isLoading,setIsLoading] = useState(true)
    const [currentScreener,setCurrentScreener] = useState([])

    useEffect(() => {
        //console.log("top of VIPAddCoinCurrentScreener useEffect")
        if (currentScreeners !== undefined) {
            let selectedScreener = Object.keys(currentScreeners).map( screenerName => {
                if (screenerName === vipScreenerName) {
                    return currentScreeners[screenerName]
                }
            })
            //console.log("selectedScreener is:")
            //console.dir(selectedScreener)
            let nonObjectSelectedScreener = selectedScreener.filter( thisScreener => thisScreener != undefined)
            //console.dir(nonObjectSelectedScreener[0])
            setCurrentScreener(nonObjectSelectedScreener[0])
        }
        setIsLoading(false)
    }, []) 


    if (isLoading) return <CircularProgress />
    if (localUserData.coin.length === 0) {
        //there are no coin set within the userObject.coin...so all coins is assumed
        return (
            <Box sx={{width: '100%'}}>
                <Stack direction="column" display="flex" alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant='button' >Screener: All Coins</Typography>
                    <Divider sx={{width: '100%'}} />
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.grey[300],
                            marginTop: '8px'
                        }}
                    >
                        All Coins...no need to display
                    </Typography>
                </Stack>
            </Box>                    
        )
    } else if ((currentScreeners.length === 0) || (vipScreenerName === '')) {
        return <Typography>there are no current screeners set</Typography>
    } else if ((currentScreener.length > 0) && (vipScreenerName !== '')) {
        return (
            <Box sx={{width: '100%'}}>
                <Stack direction="column" display="flex" alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant='button' >Screener: {vipScreenerName}</Typography>
                    <Divider sx={{width: '100%'}} />
                    {currentScreener.map( (thisScreener,index) => 
                        <Typography 
                            variant="body1"
                            sx={{
                                color: theme.palette.grey[300],
                                marginTop: '8px'
                            }}
                        >{thisScreener.coin_id}</Typography>)}
                </Stack>
            </Box>
        )
    } else if ((currentScreener.length > 0) && (vipScreenerName === '')) {
        //there are screeners but no screener is selected or could not match a current one from localUserData.coin
        //theoretically can't get here but ???
        //console.log('no currentScreener found to match')
        return (
            <Box sx={{width: '100%'}}>
                <Stack direction="column" display="flex" alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant='button' >No Current Screener Chosen...go to the next tab to select a screener</Typography>
                </Stack>
            </Box>        
        )
    } else {
        //there are no screeners...and thus no localUserData.coin...send to watchlists to create one
        //theoretically cannot get here as vip should not display
        //console.log('no screeners')
        return null
    }
}

export default VIPAddCoinCurrentScreener