import React, { useState, memo } from 'react'
import { Box } from '@mui/material'

import ResearchCoinChart from './ResearchCoinChart'

const ResearchCoinChartBTC = ( {userObject} ) => {
    //console.log("top of ResearchCoinChartBTC")

    const [chartDays,setChartDays] = useState(3)
    const [chartLabel,setChartLabel] = useState('bitcoin')
    const [chartInterval,setChartInterval] = useState('hourly')
    const [chartRangeDetails,setChartRangeDetails] = useState('2w/hourly')

    const researchBTCChartLabelChange = ( label, chartDays, interval) => {
        //console.log("ResearchMiscBTCChart chartNum is: " + chartNum)
          setChartLabel(label)
          setChartDays(chartDays)
          setChartInterval(interval)
          switch (chartDays) {
            case 365:
                setChartRangeDetails('1y/dy')
              break;
            case 180:
                setChartRangeDetails('6m/dy')
              break;
            case 90:
                setChartRangeDetails('3m/dy')
              break;
            case 30:
                setChartRangeDetails('1m/dy')
              break;
            case 14:
                setChartRangeDetails('2w/hr')
              break;
            case 7:
                setChartRangeDetails('1w/hr')
              break;
            case 3:
                setChartRangeDetails('3d/hr')
              break;
            case 1:
                setChartRangeDetails('1d/5m')
              break;
            default:
              //not sure what this is
          }
      }    

    return (
        <Box sx={{width: '100%'}}>
            <ResearchCoinChart 
                coin="bitcoin" 
                chartName="bitcoin-base"
                chartDays={chartDays} 
                chartLabel={chartLabel} 
                researchChartLabelChange={researchBTCChartLabelChange} 
                chartInterval={chartInterval}
                chartRangeDetails={chartRangeDetails}
                userObject={userObject}
                chartCoinName='bitcoin'
                chartCoinSymbol='btc'
            />
        </Box>
    )
}

export default memo(ResearchCoinChartBTC)