import React from 'react'
import { Typography, Stack, Box, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { ExpandMore, ChevronRight } from '@mui/icons-material'
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles'

import VIPMainTabsNotificationPostBodyLikes from './VIPMainTabsNotificationPostBodyLikes'
import VIPTwitterPost from './VIPTwitterPost'

const VIPMainTabsNotificationPostBody = ({notificationDetails, changeAtFollowedTab, changeHashTab, changeAtMentionedTab, changeDateInChart, setCoinInChart, changeCoinInTab, notificationText, startTime, endTime, priceStart, priceEnd, pricePercent, volumeStart, volumeEnd, volumePercent, showOfficialTweets, notificationType}) => {

  console.log("top of VIPMainTabsNotificationPostBody and notificationDetails is:")
  //if (notificationDetails.post_notification !== null) console.log("here is one")
  //if (notificationDetails.post !== null) console.log(" possibly this one")
  console.dir(notificationDetails)
  console.log("startTime is:")
  console.dir(startTime)
  console.log("notificationType is:")
  console.dir(notificationType)
  const theme = useTheme()

  const themeAccordion = createTheme({
    ...theme,
    components: {
      // Name of the component
      MuiButtonBase: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            minHeight: '0px'
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            minHeight: '0px'
          }
        }
      }
    },
  });

  const setCoinCascade = (coin) => {
    //console.log("top of setCoinCascade")
    //console.log("coin is: " + coin)
    changeDateInChart('')
    setCoinInChart(coin)
    changeCoinInTab(coin)
  }

  var priceColor = theme.palette.success.dark
  if (pricePercent < 0) priceColor = theme.palette.error.main

  var volumeColor = theme.palette.success.dark
  if (volumePercent < 0) volumeColor = theme.palette.error.main

  const displayTime = (time) => {
    if (time[1] === '' || time[1] === undefined) {
      return time[0].substring(5)
    } else {
      return time[0].substring(5)+':'+time[1]
    }
  }


  return (
    <Grid item xs={12} sx={{padding: "2px", }}>
      <Box sx={{borderRadius: '5px',  padding: "2px", margin: '0px', bgcolor: theme.palette.background.default }}>
        <Grid direction="column">
          <ThemeProvider theme={themeAccordion}>
          <Accordion
            sx={{
              backgroundColor: theme.palette.dark[800],
              marginTop: '3px',
              borderRadius: '8px'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{color: theme.palette.secondary.main}}/>}
              aria-controls={"notificationPanel"+notificationDetails.id}
              id={"notificationPanel"+notificationDetails.id}
              sx={{
                borderRadius: '8px',
                '& .MuiAccordionSummary-root': {
                  minHeight: '0px'
                },
                '& .MuiAccordionSummary-content': {
                  margin: '0px 0px'
                }
              }}
            >
              <Grid xs={12} container direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{width: '100%', padding: '3px', backgroundColor: theme.palette.dark[800], borderRadius: '8px'}}>
                <Grid xs={6} item direction="row" display="flex" justifyContent="flex-start" alignItems="center">
                  <Typography display="inline" onClick={()=>setCoinCascade(notificationDetails.coin[0].id)} variant='caption' sx={{marginright: '10px', fontWeight: 700, color: theme.palette.primary.main, cursor: 'pointer'}}>{notificationDetails.coin[0].id}</Typography>
                  {/* <Typography display="inline" variant='caption' sx={{marginLeft: '10px', fontWeight: 600, color: theme.palette.grey[300]}}>{startTime[0].substring(5)} {startTime[1].replace('Z','')}-{endTime[1].replace('Z','')}</Typography> */}
                  <Typography display="inline" variant='caption' sx={{marginLeft: '10px', fontWeight: 600, color: theme.palette.grey[300]}}>{displayTime(startTime)} <ChevronRight sx={{ fontSize: '14px', position: "relative", top: "2px"}}/> {displayTime(endTime)} </Typography>
                </Grid>
                <Grid xs={3} item direction="row" display="flex" justifyContent="flex-end" alignItems="center">
                  <Typography display="inline" variant='caption' sx={{marginLeft: '10px', fontWeight: 600, color: theme.palette.grey[300], marginRight: '5px'}}>$:</Typography>
                  <Typography display="inline" variant="caption" sx={{fontWeight: 600, fontSize: '.9rem', color: priceColor}}>{(pricePercent * 100).toFixed(2)}%</Typography>
                </Grid>
                <Grid xs={3} item direction="row" display="flex" justifyContent="flex-end" alignItems="center">
                  <Typography display="inline" variant='caption' sx={{marginLeft: '10px', fontWeight: 600, color: theme.palette.grey[300], marginRight: '5px'}}>vol:</Typography>
                  <Typography display="inline" variant="caption" sx={{fontWeight: 600, fontSize: '.9rem', color: volumeColor}}>{(volumePercent * 100).toFixed(2)}%</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{backgroundColor: theme.palette.dark.dark, padding: '10px', borderRadius: '10px'}}>
              <Box m={1} p={1} sx={{backgroundColor: theme.palette.grey[200] }}>
                <Typography>{notificationText}</Typography>
              </Box>
              <Stack direction="row" sx={{marginLeft: '10px', marginRight: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Stack direction="column" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>time start:</Typography>
                  {/*<Typography variant="caption" sx={{fontWeight: 600, fontSize: '.9rem', color: theme.palette.primary.dark}}>{startTime[0]} {startTime[1].replace('Z','')}</Typography>*/}
                  <Typography variant="caption" sx={{fontWeight: 600, fontSize: '.9rem', color: theme.palette.primary.dark}}>{displayTime(startTime)}</Typography>
                </Stack>
                <Stack direction="column" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>time end:</Typography>
                  <Typography variant="caption" sx={{fontWeight: 600, fontSize: '.9rem',color: theme.palette.primary.dark}}>{displayTime(endTime)}</Typography>
                </Stack>
              </Stack>
              <Stack direction="row" sx={{marginLeft: '10px', marginRight: '10px',display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
                <Stack direction="column" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>price start:</Typography>
                  <Typography variant="caption" sx={{fontWeight: 600, fontSize: '.9rem', color: theme.palette.primary.dark}}>{priceStart.toFixed(4)}</Typography>
                </Stack>
                <Stack direction="column" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>price end:</Typography>
                  <Typography variant="caption" sx={{fontWeight: 600, fontSize: '.9rem', color: theme.palette.primary.dark}}>{priceEnd.toFixed(4)}</Typography>
                </Stack>
                <Stack direction="column" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>price % change:</Typography>
                  <Typography variant="caption" sx={{fontWeight: 600, fontSize: '.9rem', color: priceColor}}>{(pricePercent * 100).toFixed(2)}%</Typography>
                </Stack>
              </Stack>
              <Stack direction="row" sx={{marginLeft: '10px', marginRight: '10px',display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
                <Stack direction="column" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>volume start:</Typography>
                  <Typography variant="caption" sx={{fontWeight: 600, fontSize: '.9rem', color: theme.palette.primary.dark}}>{volumeStart.toFixed(0)}</Typography>
                </Stack>
                <Stack direction="column" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>volume end:</Typography>
                  <Typography variant="caption" sx={{fontWeight: 600, fontSize: '.9rem', color: theme.palette.primary.dark}}>{volumeEnd.toFixed(0)}</Typography>
                </Stack>
                <Stack direction="column" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>volume % change:</Typography>
                  <Typography variant="caption" sx={{fontWeight: 600, fontSize: '.9rem', color: volumeColor}}>{(volumePercent * 100).toFixed(2)}%</Typography>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
          </ThemeProvider>                
          {/* "like data" is no longer in tweet details
             notificationDetails.hasOwnProperty("tweet_id") && notificationDetails.tweet_id !== null ?
              <VIPMainTabsNotificationPostBodyLikes
                likeStart={notificationDetails.post_notification.like_start}
                likeEnd={notificationDetails.post_notification.like_end}
                likePercent={notificationDetails.post_notification.like_percent}
              />
            : null 
            */}
        </Grid>

        { notificationType === 'post' && notificationDetails.hasOwnProperty("tweet_id") && notificationDetails.tweet_id !== null ? 
          <ThemeProvider theme={themeAccordion}>
            <Accordion
              sx={{
                backgroundColor: theme.palette.primary.dark,
                marginTop: '3px',

              }}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore sx={{color: '#FFF'}}/>}
                aria-controls={"notificationPanel"+notificationDetails.tweet_id}
                id={"notificationPanel"+notificationDetails.tweet_id}
                sx={{
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                  '& .MuiAccordionSummary-root': {
                    minHeight: '0px'
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: '0px 0px'
                  }
                }}
              >
                <Typography sx={{fontSize: '.8rem', color: '#FFF'}}>Possible Source Post For Action</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{backgroundColor: theme.palette.background.default, padding: '0px 0px'}}>
                <VIPTwitterPost
                  key={'notificationPanel'+notificationDetails.tweet_id}
                  listing={notificationDetails}
                  setCoinInChart={setCoinInChart}
                  changeAtFollowedTab={changeAtFollowedTab}
                  changeHashTab={changeHashTab}
                  changeAtMentionedTab={changeAtMentionedTab}
                  changeCoinInTab={changeCoinInTab}
                  changeDateInChart={changeDateInChart}
                  showOfficialTweets={showOfficialTweets}
                />
              </AccordionDetails>
            </Accordion>
          </ThemeProvider> : null }
      </Box>
    </Grid>
  )
}

export default VIPMainTabsNotificationPostBody