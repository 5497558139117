import React from 'react'
import { Typography, Grid, Stack, Tooltip, IconButton, Avatar, Box, Button } from '@mui/material'
import { Exposure, Twitter, Person } from '@mui/icons-material'
//import { Star } from '@mui/icons-material'
import { IconMessage, IconClock, IconHeart, IconCoin  } from '@tabler/icons'
import {FiExternalLink} from 'react-icons/fi'
import { useTheme } from '@mui/material/styles'
import reactStringReplace from 'react-string-replace'
import { DateTime } from 'luxon'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { communitySize } from "../../utility/objHelper";
import ResearchTwitterResultItemImages from './ResearchTwitterResultItemImages'

const ResearchBodyTweetResultsItem = ({tweetDetails, showCoinTweetsAndChart, coinTweetQueryString, divHeight=0, boxWidth=0 }) => {

  //console.log("top of ResearchBodyTweetResultsItem and tweetDetails is:")
  //console.dir(tweetDetails)
  //console.log("divHeight is: " + divHeight)
  //console.log("boxWidth is: " + boxWidth)
  //console.log("and coinTweetQueryString is:")
  //console.dir(coinTweetQueryString)
  const theme = useTheme()
  //if (tweetDetails.attachments.length > 1) console.log("this tweet has more than one image ===============================")



  // this is incomplete as the data coming from the backend is inconsistent...or changed without notifying...or just the connection issues between dev and pricing/tweet data
  var tweet = tweetDetails
  if (tweetDetails.hasOwnProperty('post')) {
    //this option does not have the full coin object...only the separate coin array
    //so will need to create the full coin object to pass back up through showCoinTweetsAndChart
    tweet = tweetDetails.post

  } else {
    //this option 
  }

  //format time
  var trimmedTime = tweet.created.split("+")[0]
  var splitTime = trimmedTime.split("T")
  var year_removed = splitTime[0].slice(5)
  //get day of week from time
  let day = new Date(tweet.created)
  var days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat']

  var quoteTrimmedTime = null
  var quoteSplitTime = null
  if (tweet.quote_created !== null) {
      quoteTrimmedTime = tweet.quote_created.split("+")[0]
      quoteSplitTime = quoteTrimmedTime.split("T")
  }  

  //display polarity (positive/negative)
  /*const polarityDisplay = (passedDirection, passedCount) => {
    if (passedCount === 0) return null
    if (passedDirection === 1) {
      return [ <AddBox sx={{color: theme.palette.primary.main, fontSize: "1.0rem"}}/>,  polarityDisplay(passedDirection, (passedCount-1) ) ]
    } else {
      return [ <IndeterminateCheckBox sx={{color: theme.palette.secondary.main, fontSize: "1.0rem"}}/>,  polarityDisplay(passedDirection, (passedCount-1) ) ]
    }
  }*/
  var direction = 0
 // var count = 0
  switch(true) {
    case parseFloat(tweet.polarity) >= .7:
        direction = 1
        //count = 3
      break
    case parseFloat(tweet.polarity) >= .4:
      direction = 1
      //count = 2
      break
    case parseFloat(tweet.polarity) >= .1:
      direction = 1
      //count = 1
      break
    case parseFloat(tweet.polarity) <= -.1:
      direction = -1
      //count = 1
      break
    case parseFloat(tweet.polarity) <= -.4:
      direction = -1
      //count = 2
      break
    case parseFloat(tweet.polarity) <= -.7:
      direction = -1
      //count = 3
      break
    default:
      //leave default values
    break
  }

  //display efficacy (how strong match to purpose)
  /*
  const efficacyDisplay = (passedCount) => {
    if (passedCount === 0) return null
    return [ <Star sx={{color: theme.palette.primary.main, fontSize: "1.0rem"}}/>,  efficacyDisplay((passedCount-1)) ]
  }
  var efficacyCount = 0
  switch(true) {
    case parseFloat(tweet.subjectivity) >= .7:
        efficacyCount = 3
      break
    case parseFloat(tweet.subjectivity) >= .4:
      efficacyCount = 2
      break
    case parseFloat(tweet.subjectivity) >= .1:
      efficacyCount = 1
      break
    default:
      //leave default values
    break
  }
  */

  const units = [
    'year',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
  ];

  const timeAgo = () => {
    let dateTime = DateTime.fromISO(tweet.created)
    const diff = dateTime.diffNow().shiftTo(...units);
    const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

    const relativeFormatter = new Intl.RelativeTimeFormat('en', {
      numeric: 'auto',
    });
    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
  }

  var twitterText = tweet.text
  //replace link with preview
  //replace @
  twitterText = reactStringReplace(twitterText, /@(\w+)/g, (match, i) => (
    <Button size="small" key={match + i} style={{cursor: 'pointer', color: theme.palette.warning.dark}}>@{match}</Button>
  ))

  //replace #
  twitterText = reactStringReplace(twitterText, /#(\w+)/g, (match, i) => (
    <Button size="small" key={match + i} style={{cursor: 'pointer', color: theme.palette.success.dark}}>#{match}</Button>
  ))

  twitterText = reactStringReplace(twitterText, /(https:\/\/\S+)/g, (match, i) => (
    <a href={match} style={{color: theme.palette.primary.dark}} target="_blank" rel="noreferrer" >{match}</a>
  ))

  // replace keywords (one call for upper case, one call for lower case
  if(coinTweetQueryString && coinTweetQueryString.length){
    let rx = /search=(.*)/g;
    let arr = rx.exec(coinTweetQueryString);
    if (arr !== null) {
      const searchText = arr[1];
      const words = searchText.split(' ');
      for(let word of words){
        if(word.length){
          // REPLACE DATE with this/next month
          if(word.trim() === ('DATE')){
            const now = new Date(Date.now())
            const month = now.toLocaleString('default', { month: 'long' });
            const shortMonth = month.slice(0,3)
            const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1).toLocaleString('default', { month: 'long' });
            const shortNextMonth = nextMonth.slice(0,3);
            const regexDate = new RegExp(`(`+month+`)+`, 'g')
            // this month - long string
            twitterText = reactStringReplace(twitterText, regexDate, (match, i) => {
              return (
                  <Button size="small" key={match + Math.random().toString(36).substring(2,7)} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match} </Button>
              )
            })
            // this month - short string
            const regexShortDate = new RegExp(`(`+shortMonth+`)`, 'g')
            twitterText = reactStringReplace(twitterText, regexShortDate, (match, i) => {
              return (
                  <Button size="small" key={match + Math.random().toString(36).substring(2,7)} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match} </Button>
              )
            })
            // next month - short string
            const regexNextMonthDate = new RegExp(`(`+nextMonth+`)`, 'g')
            twitterText = reactStringReplace(twitterText, regexNextMonthDate, (match, i) => {
              return (
                  <Button size="small" key={match + Math.random().toString(36).substring(2,7)} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match} </Button>
              )
            })
            const regexShortNextMonthDate = new RegExp(`(`+shortNextMonth+`)`, 'g')
            twitterText = reactStringReplace(twitterText, regexShortNextMonthDate, (match, i) => {
              return (
                  <Button size="small" key={match + Math.random().toString(36).substring(2,7)} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match} </Button>
              )
            })
          }else{
            const regexUpper = new RegExp(`(`+word[0].toUpperCase() + word.slice(1, word.length)+`)+`, 'g')
            twitterText = reactStringReplace(twitterText, regexUpper, (match, i) => {
              return (
                  <Button size="small" key={match + Math.random().toString(36).substring(2,7)} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{word[0].toUpperCase() + word.slice(1, searchText.length)} </Button>
              )
            })

            const regexLower = new RegExp(`(`+word[0].toLowerCase() + word.slice(1, word.length)+`)+`, 'g')
            twitterText = reactStringReplace(twitterText, regexLower, (match, i) => {
              return (
                  <Button size="small" key={match + Math.random().toString(36).substring(2,7)} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match}</Button>
              )
            })
          }
        }
      }
    } else {
      //there is no "search=" within the search term
      //if no "search=" is found arr is null
    }
  }

  const linkToTweet = `https://twitter.com/${tweet.twitter_display_name}/status/${tweet.tweet_id}`
  const displayAvatar = () => {
    if ((tweet.twitter_user_avatar_url === undefined) || (tweet.twitter_user_avatar_url === '')) {
      return (
        <a target="_blank" rel="noreferrer" href={linkToTweet}>
          <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main, cursor: 'pointer'}} />
        </a>
      )
    } else {
        return (
          <a target="_blank" rel="noreferrer" href={linkToTweet}>
            <Avatar alt={tweet.twitter_display_name} src={tweet.twitter_user_avatar_url} sx={{ width: 42, height: 42, marginRight: '3px', cursor: 'pointer' }}/>
          </a>
        )
    }
  }

  const showCoinDetails = () => {
    //console.log("top of showCoinDetails and tweetDetails is:")
    //console.dir(tweetDetails)
    showCoinTweetsAndChart(tweetDetails)
  }

  /* 
  const displayTweetImages = () => {
    if (tweet.attachments.length === 1) {
      return (
        <a key={'researchfeedimage'+tweet.id} href={tweet.attachments[0]}  target='_blank' rel={"noreferrer"}>
          <LazyLoadImage
              key={'researchfeedimg'+tweet.id}
              style={{maxWidth: '100%', height: 'auto'}}
              src={tweet.attachments[0]}
              alt="tweet attachment"
              effect="blur"
          />
        </a>
      )    
    } else {
      return (
        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
          {tweet.attachments.map((imageURL) => (
            <ImageListItem key={imageURL}>
              <LazyLoadImage
                  alt={'tweet attachment'}
                  effect="blur"
                  src={imageURL.includes('?') ? `${imageURL}&w=164&h=164&fit=crop&auto=format` : `${imageURL}?w=164&h=164&fit=crop&auto=format`} />
            </ImageListItem>
          ))}
        </ImageList>
      )
    }
  }
  */
  return (
      <Grid container
            sx={{
              paddingTop: '0px',
              marginTop: '5px',
              marginLeft: '5px',
              marginRight: '5px',

            }}
            key={tweet.id}
      >
        <Grid item xs={12}
              sx={{borderTopLeftRadius: '4px',borderTopRightRadius: '4px'}}
        >
          <Stack direction="row" sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '5px', paddingRight: '5px',marginBottom: '3px', marginTop: '3px'}} >
            <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" sx={{paddingRight: '5px'}}>
              {displayAvatar()}
              <Typography variant="body2" onClick={showCoinDetails} sx={{color: theme.palette.grey[500], fontSize: '.8rem', fontWeight: 600, marginLeft: '10px', cursor: 'pointer'}} >{tweet.display_name}</Typography>
              <Typography variant="subtitle1" onClick={showCoinDetails} sx={{fontWeight: 400, color: theme.palette.grey[300], marginLeft: '10px', cursor: 'pointer'}}>@{tweet.twitter_display_name}</Typography>
              <Typography variant="subtitle1" sx={{fontWeight: 400, color: theme.palette.grey[300], marginLeft: '10px'}}>{timeAgo()}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Person sx={{fontSize: '1rem', color: theme.palette.grey[400]}} />
              <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[300], paddingLeft: '3px', marginTop: '3px'}}>{communitySize(tweet)}</Typography>
              <a target="_blank" rel="noreferrer" style={{position: 'relative', top: '2px', left: '3px', marginRight: '5px'}} href={linkToTweet}><FiExternalLink color={theme.palette.grey[300]} /></a>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '10px'}}>
          <Typography variant="body2" sx={{color: theme.palette.grey[500], fontSize: '.75rem', paddingRight: '5px' }} >{twitterText}</Typography>
        </Grid>
        <Grid item xs={12}
        >
          <Stack direction="row" sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '5px', paddingRight: '5px'}} >
            <Stack direction="row">
              <IconMessage
                  size={17}
                  color={theme.palette.grey[400]}
                  stroke={2}/>
              <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '5px'}}>{tweet.retweets}</Typography>
            </Stack>

            <Stack direction="row">
              <IconHeart
                  size={17}
                  color={theme.palette.grey[400]}
                  stroke={2}
              />
              <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '5px'}}>{tweet.favorites}</Typography>
            </Stack>

            <Stack direction="row" sx={{marginTop: "3px", display: 'flex', alignItems: 'center',}}>
              <Typography sx={{color: theme.palette.grey[400], fontSize: '.75rem'}}>RT %: </Typography>
              <Typography sx={{color: theme.palette.grey[500], fontSize: "0.75rem"}}>{tweet.retweet_percentage}</Typography>
            </Stack>

            <Stack direction="row" sx={{marginTop: "0px"}} alignItems="center">
              <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400], marginRight: '3px', marginTop: '2px'}}>social engagement</Typography>
              <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], marginLeft: '5px', marginTop: '2px'}}>{tweet.social_engagement ? tweet.social_engagement : 0}</Typography>
            </Stack>

            <Stack direction="row" sx={{marginTop: "3px", display: 'flex', alignItems: 'center',}}>
              <Typography sx={{color: theme.palette.grey[400], fontSize: '.75rem'}}>relevance: </Typography>
              <Typography sx={{color: theme.palette.grey[500], fontSize: '.75rem'}}>{direction === 0 ? 'none'  : tweet.subjectivity.toFixed(2)}</Typography>
            </Stack>

            <Stack direction="row" sx={{marginTop: "3px", display: 'flex', alignItems: 'center',}}>
              <Typography sx={{color: theme.palette.grey[400], fontSize: '.75rem'}}>polarity: </Typography>
              {/*<Stack direction="row" ><Typography sx={{color: theme.palette.grey[100], fontSize: '.8rem'}}>{direction === 0 ? <Exposure sx={{fontSize: "1.0rem"}}/> : polarityDisplay(direction, count)}</Typography></Stack>*/}
              <Typography sx={{color: theme.palette.grey[500], fontSize: '.75rem', marginRight: '5px'}}>{direction === 0 ? <Exposure sx={{fontSize: "1.0rem"}}/> : tweet.polarity.toFixed(2) }</Typography>
            </Stack>

          </Stack>
        </Grid>
        <Grid item xs={12}
              sx={{
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
              }}
        >
          <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack direction="row" display="flex" justifyContent="flex-start" alignItems="center">
              <Tooltip title="coins concerned in this post"><IconButton><IconCoin strokeWidth={2} size={17} color={theme.palette.grey[400]} /></IconButton></Tooltip>
              {tweet.coins !== null && tweet.coins.length > 0 ?
                  <Stack direction="row" display="flex" justifyContent="flex-start" alignItems="center">
                    {tweet.coins.map( (coin, index ) => {
                      if (coin.length > 1)
                        return <Typography key={"tweetcoins"+coin+index} onClick={showCoinDetails} variant="subtitle1" display="inLine" sx={{fontWeight: 600, cursor: 'pointer', color: theme.palette.grey[500], marginLeft: '10px'}}  > {coin}</Typography>
                      else
                        return null
                    })
                    }
                  </Stack>
                  : <Typography variant="subtitle1" display="inLine" sx={{fontWeight: 400, color: theme.palette.grey[500], marginLeft: '10px'}} >none</Typography> }
            </Stack>
            <Stack direction="row">
              <IconClock
                  size={17}
                  color={theme.palette.grey[400]}
                  stroke={2}
              />
              <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '5px'}}>{days[day.getDay()]} {year_removed} {splitTime[1].slice(0,-1)}</Typography>
            </Stack>
          </Stack>
        </Grid>
        { tweet.attachments != null && tweet.attachments.length > 0 ?
          <Grid item xs={12}>
            <Box sx={{width: '100%', paddingLeft: '50px', paddingRight: '50px', align: 'center'}}>
              <ResearchTwitterResultItemImages images={tweet.attachments} tweetID={tweet.id} inDashboardHeight={divHeight} inDashboardWidth={boxWidth} />
            </Box>
          </Grid> : null 
        }    
        {
          tweet.quote_tweet_id && tweet.quote_tweet_id && (
            <Grid item xs={12}>
              <Box sx={{marginLeft: 5, marginTop: 1, border: '1px solid #2b2b2b', borderRadius: 1, p: 1}}>
                <Stack direction='row' display='flex' alignItems='center' justifyContent="space-between">
                  <Stack direction={'row'} display="flex" alignItems='center' justifyContent="flex-start" >
                    {
                      tweet.quote_avatar_url ?
                        (
                          <a target="_blank" rel="noreferrer" href={`https://twitter.com/${tweet.quote_display_name}/status/${tweet.quote_tweet_id}`}>
                              <Avatar alt={tweet.quote_avatar_url} src={tweet.quote_avatar_url} sx={{ width: 42, height: 42, marginRight: '3px' }}/>
                          </a>
                        )
                        :
                        (
                          <a target="_blank" rel="noreferrer" href={`https://twitter.com/${tweet.quote_display_name}/status/${tweet.quote_tweet_id}`}>
                              <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
                          </a>
                        )
                    }
                    <Typography variant="subtitle1" sx={{fontWeight: 600, color: theme.palette.grey[500]}}>{tweet.quote_display_name}</Typography>
                  </Stack>
                  {quoteSplitTime !== null ? 
                      <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-end" sx={{cursor: 'pointer'}}>
                          <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>created:</Typography>
                          <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[800], paddingLeft: '5px'}}>{quoteSplitTime[0]} {quoteSplitTime[1].slice(0,-1)} UTC</Typography>
                      </Stack>   
                  : null }                             
                </Stack>
                <Stack direction={'row'}>
                    <Typography>{tweet.quote_text}</Typography>
                </Stack>
              
              { tweet.quote_attachments !== null && tweet.quote_attachments.length > 0 ?
                <Box id={"quoted_images"+tweet.tweet_id} sx={{width: '100%', paddingLeft: '50px', paddingRight: '50px', align: 'center'}}>
                  <ResearchTwitterResultItemImages images={tweet.quote_attachments} tweetID={tweet.tweet_id} inDashboardHeight={divHeight} inDashboardWidth={boxWidth} />
                </Box> : null 
              }        
              </Box>          
            </Grid>
          )
        }               
      </Grid>
  )
  
}

export default ResearchBodyTweetResultsItem;
