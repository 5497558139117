import React from 'react'
import { TextField } from '@mui/material'

const LoginTOTP = ( {forceTOTPUse, handleTOTPChange, TOTPError, TOTPassword} ) => {

  if (forceTOTPUse)
    return (
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        id='totp_password'
        label='Time-Based Password'
        name='totp_password'
        autoComplete='time-based password'
        autoFocus
        value={TOTPassword}
        error={TOTPError}
        onChange={handleTOTPChange}
        inputProps={{ maxLength: 10 }}
      />
    )
  else return null
}

export default LoginTOTP