import React, { useState } from 'react'
import { Stack, TextField, IconButton } from '@mui/material'
import { Clear, Search } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

const CurrencyListSearch = ({ clearSearch, searchCoins}) => {
    //console.log("   top of CurrencyListSearch")

    const [localSearch,setLocalSearch] = useState('')

    const theme = useTheme()

    const handleClearSearch = () => {
        setLocalSearch('')
        clearSearch()
    }

    const handleSearchChange = (e) => {
        //console.log("top of handleSearchChange and e.target.value is: " + e.target.value)
        setLocalSearch(e.target.value.trim())
    }

    const handleSearchClicked = (e) => {
        //console.log("top of handleSearchClicked and localSearch is: " + localSearch)
        searchCoins(localSearch)
    }

    const handleSearchSubmitted = (e) => {
        e.preventDefault()
        //console.dir(e)
        //console.log("top of handleSearchSubmitted and localSearch is: " + localSearch)
    }

    return (
        <form onSubmit={handleSearchSubmitted}>
            <Stack 
                direction="row" 
                display="flex" 
                alignItems="center"
                sx={{
                    position: 'relative',
                    top: '-20px'
                }}
            >
                <TextField 
                    id="search-coins-home" 
                    label="Search Coins" 
                    variant="outlined"
                    value={localSearch} 
                    onChange={handleSearchChange}
                    size="small"
                    sx={{
                        width: '100%', 
                        maxWidth: '200px',
                        minWidth: '120px',
                        color: theme.palette.grey[500], 
                        input: {
                            background: theme.palette.primary.main,
                        },
                    }}
                    InputLabelProps={{
                        style: { 
                            color: theme.palette.grey[500] ,
                            position: 'relative',
                            top: '17px'
                        },
                    }}
                    inputProps={{
                        style: { height: '12px' }
                    }}
                    
                />
                    <IconButton
                        aria-label="clear coin"
                        onClick={handleSearchClicked}
                        edge="end"
                        sx={{
                            position: 'relative',
                            top: '12px'
                        }}
                        type="submit"
                    >
                        <Search sx={{color: theme.palette.grey[800]}} />
                    </IconButton>            
                    <IconButton
                        aria-label="clear coin"
                        onClick={handleClearSearch}
                        edge="end"
                        sx={{
                            position: 'relative',
                            top: '12px'
                        }} 
                    >
                        <Clear sx={{color: theme.palette.grey[800]}}/>
                    </IconButton>
            </Stack>    
        </form>    
    )
}

export default CurrencyListSearch