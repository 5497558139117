import React from 'react'
import { Typography, Stack, Divider, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { AiFillAlert } from 'react-icons/ai'

const VIPAddCoinScreenerChoices = ( {localUserData, vipScreenerName, currentScreeners, changeScreenerVIP} ) => {

    const theme = useTheme()
    // console.log("top of VIPAddCoinScreenerChoices and localUserData is")
    // console.dir(localUserData)
    // console.log("currentScreeners is:")
    // console.dir(currentScreeners)
    // console.log("vipScreenerName is:")
    // console.dir(vipScreenerName)

    return (
        <Grid container sx={{width: '100%', zIndex: 1000000}}>
            <Grid item xs={12}>
                <Stack direction="column" display="flex" alignItems="flex-start" justifyContent="flex-start" >
                    <Typography display="inline" variant="button">Current Watchlists</Typography>
                    <Divider sx={{width: '85%'}} />
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: vipScreenerName === 'All Coins' ? 700 : 400,
                            color: vipScreenerName === 'All Coins' ? theme.palette.primary.main : theme.palette.grey[400],
                            marginTop: '8px',
                            cursor: 'pointer',
                        }}
                        onClick={() => changeScreenerVIP('All Coins')}
                    >
                        All Coins
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: vipScreenerName === 'Favorites' ? 700 : 400,
                            color: vipScreenerName === 'Favorites' ? theme.palette.primary.main : theme.palette.grey[400],
                            marginTop: '8px',
                            cursor: 'pointer',
                        }}
                        onClick={() => changeScreenerVIP('Favorites')}
                    >
                        Favorites {currentScreeners.hasOwnProperty('Favorites') ? '(' + currentScreeners['Favorites'].length + ')' : '(0)'}
                    </Typography>
                    {localUserData.screener_name.map((screener,index) => 
                        screener !== 'Favorites' && screener !== 'All Coins' ?
                            <Stack key={index} display="flex" direction='row' justifyContent="flex-start" alignItems="center" sx={{marginTop: '8px'}}>
                                <Typography 
                                    display="inline"
                                    variant="body1" 
                                    sx={{
                                        fontWeight: vipScreenerName === screener ? 700 : 400,
                                        color: vipScreenerName === screener ? theme.palette.primary.main : theme.palette.grey[400],
                                        marginRight: '3px'
                                    }}
                                    onClick={() => changeScreenerVIP(screener)}
                                    key={"coinscreenerchosen"+index}
                                >
                                    {screener} {currentScreeners.hasOwnProperty(screener) ? '(' + currentScreeners[screener].length + ')' : '(0)'} 
                                    
                                </Typography>
                                <AiFillAlert size={15} color={theme.palette.success.main} />
                            </Stack>
                        : null
                    )}
                </Stack>
                
            </Grid>
        </Grid>
    )
}

export default VIPAddCoinScreenerChoices