import React from 'react'
import { Typography, Grid, Stack } from '@mui/material'
import { abbreviateNumber } from "js-abbreviation-number"
import { useTheme } from '@mui/material/styles'

const ResearchBodyCoinItemLeft = ( {coinDetails }) => {
    const theme = useTheme()

    const displayLargeNumber = (value) => {
        if (value > 0 )
          return abbreviateNumber(value, 2, {padding: false, symbols: ["", "k", "M", "B", "T", "P", "E"]} )
        else
          return value
    }

    const labelClasses = {
        fontWeight: 400,
        color: theme.palette.grey[400],

    }
    const valueClasses = {
        fontWeight: 600,
        color: theme.palette.primary.main
    }

    return (
        <Grid container spacing={0} sx={{marginTop: '5px', marginBottom: '5px'}}>
            <Grid item xs={6}><Typography sx={labelClasses} >Market Cap</Typography></Grid>
            <Grid item xs={6}><Typography sx={valueClasses} >{displayLargeNumber(coinDetails.coinscreener.market_cap)}</Typography></Grid>
            <Grid item xs={6}><Typography sx={labelClasses} >Circulating</Typography></Grid>
            <Grid item xs={6}><Typography sx={valueClasses} >{displayLargeNumber(coinDetails.coinscreener.circulating_supply)}</Typography></Grid>
            <Grid item xs={6}><Typography sx={labelClasses} >Total Volume</Typography></Grid>
            <Grid item xs={6}><Typography sx={valueClasses} >{displayLargeNumber(coinDetails.coinscreener.total_volume)}</Typography></Grid>
            <Grid item xs={6}><Typography sx={labelClasses} >Max Supply</Typography></Grid>
            <Grid item xs={6}><Typography sx={valueClasses} >{displayLargeNumber(coinDetails.coinscreener.max_supply)}</Typography></Grid>
            <Grid item xs={6}><Typography sx={labelClasses} >vs BTC</Typography></Grid>
            <Grid item xs={6}><Typography sx={valueClasses} >{coinDetails.coinscreener.hasOwnProperty('btc_24h_dif') && coinDetails.coinscreener.btc_24h_dif !== null ? coinDetails.coinscreener.btc_24h_dif.toFixed(2) : null}%</Typography></Grid>       
            <Grid item xs={6}><Typography sx={labelClasses} >vs ETH</Typography></Grid>
            <Grid item xs={6}><Typography sx={valueClasses} >{coinDetails.coinscreener.hasOwnProperty('eth_24h_dif') && coinDetails.coinscreener.eth_24h_dif !== null ? coinDetails.coinscreener.eth_24h_dif.toFixed(2) : null}%</Typography></Grid>                  
        </Grid>        
    )
}

export default ResearchBodyCoinItemLeft