import React, {useState, useEffect, memo, useRef} from 'react'
import { Typography, Divider, Stack, Slider, Box, CircularProgress, FormControl, Grid, Select, MenuItem, InputAdornment, 
    InputLabel, IconButton, TextField, Popover, Paper, useMediaQuery, Modal, Backdrop, Fade, Button, Chip, OutlinedInput, 
    selectChangeEvent, Tooltip } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { Clear, Search, FavoriteBorder } from '@mui/icons-material'
import { VscDebugRestart } from 'react-icons/vsc'
import { TiArrowForward } from 'react-icons/ti'

import { CgMoreO } from 'react-icons/cg'
import { AiOutlineBell } from 'react-icons/ai'
import { ImCancelCircle } from 'react-icons/im'
import { IconSquareX } from '@tabler/icons'

import fetchData from '../../utility/endpointFetch'

import ResearchSearchFormCategoryItems from './ResearchSearchFormCategoryItems'
import ResearchSearchDisplayCurrentSearch from './ResearchSearchDisplayCurrentSearch'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'

var date = new Date()
const dateRange = [
    { display : "today", match: 'today', date: new Date(date.getTime()).setDate(date.getDate())},
    { display : "1 Day", match: '1d', date: new Date(date.getTime()).setDate(date.getDate() - 1)},
    { display : "2 Days", match: '2d', date: new Date(date.getTime()).setDate(date.getDate() - 2)},
    { display : "3 Days", match: '3d', date: new Date(date.getTime()).setDate(date.getDate() - 3)},
    { display : "4 Days", match: '4d', date: new Date(date.getTime()).setDate(date.getDate() - 4)},
    { display : "5 Days", match: '5d', date: new Date(date.getTime()).setDate(date.getDate() - 5)},
    { display : "6 Days", match: '6d', date: new Date(date.getTime()).setDate(date.getDate() - 6)},
    { display : "1 wk", match: '1w', date: new Date(date.getTime()).setDate(date.getDate() - 7)},
    { display : "2 wks", match: '2w', date: new Date(date.getTime()).setDate(date.getDate() - 14)},
    { display : "3 wks", match: '3w', date: new Date(date.getTime()).setDate(date.getDate() - 21)},
    { display : "1 mon", match: '1m', date: new Date(date.getTime()).setDate(date.getDate() - 30)},
    { display : "2 mon", match: '2m', date: new Date(date.getTime()).setDate(date.getDate() - 60)},
    { display : ">3 mon", match: '3m', date: new Date(date.getTime()).setDate(date.getDate() - 90)},
]

const dateRangeMarks1 = [
    { label : "today", value: new Date(date.getTime()).setDate(date.getDate())},
    { label : "1 Day", value: new Date(date.getTime()).setDate(date.getDate() - 1)},
    { label : "2 Days", value: new Date(date.getTime()).setDate(date.getDate() - 2)},
    { label : "3 Days", value: new Date(date.getTime()).setDate(date.getDate() - 3)},
    { label : "4 Days", value: new Date(date.getTime()).setDate(date.getDate() - 4)},
    { label : "5 Days", value: new Date(date.getTime()).setDate(date.getDate() - 5)},
    { label : "6 Days", value: new Date(date.getTime()).setDate(date.getDate() - 6)},
    { label : "1 wk", value: new Date(date.getTime()).setDate(date.getDate() - 7)},
    { label : "2 wks", value: new Date(date.getTime()).setDate(date.getDate() - 14)},
    { label : "3 wks", value: new Date(date.getTime()).setDate(date.getDate() - 21)},
    { label : "1 mon", value: new Date(date.getTime()).setDate(date.getDate() - 30)},
    { label : "2 mon", value: new Date(date.getTime()).setDate(date.getDate() - 60)},
    { label : ">3 mon", value: new Date(date.getTime()).setDate(date.getDate() - 90)},
]

const dateRangeMarks = [
    { label : "", value: 0},
    { label : "", value: 1},
    { label : "", value: 2},
    { label : "", value: 3},
    { label : "", value: 4},
    { label : "", value: 5},
    { label : "", value: 6},
    { label : "", value: 7},
    { label : "", value: 8},
    { label : "", value: 9},
    { label : "", value: 10},
    { label : "", value: 11},
    { label : "", value: 12},
]

const socialEngRange = [
    { label : "0", value: 0},
    { label : ".25", value: 1},
    { label : ".5", value: 2},
    { label : ".75", value: 3},
    { label : "1", value: 4},
    { label : "1.25", value: 5},
    { label : "1.5", value: 6},
    { label : "1.75", value: 7},
    { label : "2", value: 8},
    { label : "2.25", value: 9},
    { label : "2.5", value: 10},
    { label : "2.75", value: 11},
    { label : "3", value: 12},
    { label : "3.25", value: 13},
    { label : "3.5", value: 14},
    { label : "3.75", value: 15},
    { label : "4", value: 16},
    { label : "4.25", value: 17},
    { label : "4.5", value: 18},
    { label : "4.75", value: 19},
    { label : "5", value: 20},
]

const socialEngRangeMarks = [
    { label : "", value: 0},
    { label : "", value: 1},
    { label : "", value: 2},
    { label : "", value: 3},
    { label : "", value: 4},
    { label : "", value: 5},
    { label : "", value: 6},
    { label : "", value: 7},
    { label : "", value: 8},
    { label : "", value: 9},
    { label : "", value: 10},
    { label : "", value: 11},
    { label : "", value: 12},
    { label : "", value: 13},
    { label : "", value: 14},
    { label : "", value: 15},
    { label : "", value: 16},
    { label : "", value: 17},
    { label : "", value: 18},
    { label : "", value: 19},
    { label : "", value: 20},
   
]

const selectableCategories = [
    {name: "Cryptocurrency", abbrev: "crypto"},
    {name: "Smart Contract Platform", abbrev: "smart cont"},
    {name: "Ethereum Ecosystem", abbrev: "ethereum"},
    {name: "StableCoins", abbrev: "stable"},
    {name: "USD Stablecoins", abbrev: "usd stable"},
    {name: "Polygon Ecosystem", abbrev: "polygon"},
    {name: "Avalanche Ecosystem", abbrev: "avalanche"},
    {name: "Arbitrum Ecosystem", abbrev: "arbitum"},
    {name: "Fantom Ecosystem", abbrev: "fantom"},
    {name: "Near Protocol Ecosystem", abbrev: "near protocol"},
    {name: "BNB Chain Ecosystem", abbrev: "bnb chain"},
    {name: "Cronos Ecosystem", abbrev: "cronos"},
    {name: "Moonriver Ecosystem", abbrev: "moonriver"},
    {name: "Moonbeam Ecosystem", abbrev: "moonbeam"},
    {name: "Gnosis Chain Ecosystem", abbrev: "gnosis chain"},
    {name: "Exchange-Based Tokens", abbrev: "xchange tok"},
    {name: "Centralized Exchange", abbrev: "cex"},
    {name: "Cardano Ecosystem", abbrev: "cardano"},
    {name: "Harmony Ecosystem", abbrev: "harmony"},
    {name: "Solana Ecosystem", abbrev: "solana"},
    {name: "Meme", abbrev: "meme"},
    {name: "Polkadot Ecosystem", abbrev: "polkadot"},
    {name: "Eth 2.0 Staking", abbrev: "eth 2 staking"},
    {name: "Decentralized Finance", abbrev: "defi"},
    {name: "Liquid Staking Tokens", abbrev: "liquid tok"},
    {name: "Tokenized BTC", abbrev: "token btc"},
    {name: "Wrapped-Tokens", abbrev: "wrapped tok"},
    {name: "Oracle", abbrev: "oracle"},
    {name: "Business Services", abbrev: "bus serv"},
    {name: "Infrastructure", abbrev: "infra"},
    {name: "Edgeware Ecosystem", abbrev: "edgeware"},
    {name: "Yield Farming", abbrev: "yield farm"},
    {name: "Governance", abbrev: "governance"},
    {name: "Automated Market Maker", abbrev: "amm"},
    {name: "Decentralized Exchange", abbrev: "dex"},    
    {name: "Cosmos Ecosystem", abbrev: "cosmos"},
    {name: "Privacy Coins", abbrev: "privacy coins"},
    {name: "Sports", abbrev: "sports"},
    {name: "NFT", abbrev: "nft"},
    {name: "Algorand Ecosystem", abbrev: "algorand"},
    {name: "Internet Of Things (IOT)", abbrev: "iot"},
    {name: "Artificial Intelligence", abbrev: "ai"},
    {name: "Business Platform", abbrev: "bus platform"},
    {name: "Storage", abbrev: "storage"},    
    {name: "Play To Earn", abbrev: "play/earn"},
    {name: "Metaverse", abbrev: "meta"},
    {name: "Entertainment", abbrev: "entertainment"},    
    {name: "Gaming", abbrev: "gaming"},
    {name: "Protocol", abbrev: "protocol"},
    {name: "Axie Infinity", abbrev: "axie"},
    {name: "Tezos Ecosystem", abbrev: "tezos"},
    {name: "Lending/Borrowing", abbrev: "lend/borrow"},
    {name: "Seigniorage", abbrev: "seigniorage"},
    {name: "Elrond Ecosystem", abbrev: "elrond"},
]

const CATEGORY_ITEM_HEIGHT = 48;
const CATEGORY_ITEM_PADDING_TOP = 8;
const CategoryMenuProps = {
    PaperProps: {
      style: {
        maxHeight: CATEGORY_ITEM_HEIGHT * 4.5 + CATEGORY_ITEM_PADDING_TOP,
        width: 250,
      },
    },
}

function getStyles(name, categoryName, theme) {
    return {
      fontWeight:
      categoryName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}


const ResearchSearchForm = ({ filterCoinList, userObject, searchQuerySet, tutorialOpen, openTutorial }) => {
    //console.log("top of ResearchSearchForm")
    const theme = useTheme()
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [isLoading,setIsLoading] = useState(true)
    const [startingValues,setStartingValues] = useState({})

    const [marketCapMin,setMarketCapMin] = useState(0)
    const [marketCapMax,setMarketCapMax] = useState(0)
    const [marketCapMinError,setMarketCapMinError] = useState(false)
    const [marketCapMaxError,setMarketCapMaxError] = useState(false)
    const [isMarketCapLoading,setIsMarketCapLoading] = useState(false)
    const [quickMarketCapCount,setQuickMarketCapCount] = useState(0)


    const [followersMin,setFollowersMin] = useState(0)
    const [followersMax,setFollowersMax] = useState(0)
    const [followersMinError,setFollowersMinError] = useState(false)
    const [followersMaxError,setFollowersMaxError] = useState(false)
    const [isFollowersLoading,setIsFollowersLoading] = useState(false)
    const [isSocEngLoading,setIsSocEngLoading] = useState(false)
    const [quickFollowersCount,setQuickFollowersCount] = useState(0)
    const [quickSocEngCount,setQuickSocEngCount] = useState(0)

    const [vsBTCMin,setVsBTCMin] = useState(0)
    const [vsBTCMax,setVsBTCMax] = useState(0)
    const [vsETHMin,setVsETHMin] = useState(0)
    const [vsETHMax,setVsETHMax] = useState(0)

    const [vsBTCTimeHorizon,setVsBTCTimeHorizon] = useState(1)
    const [vsETHTimeHorizon,setVsETHTimeHorizon] = useState(1)
    const [selectedCategories,setSelectedCategories] = useState([])
    const [queryString,setQueryString] = useState({})
    const [editedQueryString,setEditedQueryString] = useState({})       // query string that is updated as values are changed (for save search modal)
    const [anchorEl, setAnchorEl] = useState(null)
    const [saveSearchOpen,setSaveSearchOpen] = useState(false)
    const [savedSearchList,setSavedSearchList] = useState([])
    const [selectedSavedSearch,setSelectedSavedSearch] = useState('')
    const [selectedSavedSearchQueryString,setSelectedSavedSearchQueryString] = useState('')
    //const [vipSearches,setVipSearches] = useState([])
    const [postCreatedGTE,setPostCreatedGTE] = useState(0)
    const [postCreatedLTE,setPostCreatedLTE] = useState(0)
    const [postBodyGTE,setPostBodyGTE] = useState(new Date(Date.now()).toISOString())
    const [postBodyLTE,setPostBodyLTE] = useState(new Date(Date.now()).toISOString())

    const [socialEngMin,setSocialEngMin] = useState(0)
    const [socialEngMax,setSocialEngMax] = useState(5)
    const [socialEngMinError,setSocialEngMinError] = useState(false)
    const [socialEngMaxError,setSocialEngMaxError] = useState(false)    

    const [marketCapDetailsOpen,setMarketCapDetailsOpen] = useState(false)
    const [twitterFollowersDetailsOpen,setTwitterFollowersDetailsOpen] = useState(false)
    const [socialEngagementDetailsOpen, setSocialEngagementDetailsOpen] = useState(false)

    const [postSearchTerm,setPostSearchTerm] = useState('')
    const [buttonType,setButtonType] = useState('outlined')

    const [categoryNames, setCategoryNames] = useState([])

    const [minMax,setMinMax] = useState({})

    const [addSearchTermToVIPKeywordsList,setAddSearchTermToVIPKeywordsList] = useState(true) //assuming searchTerm is empty by default...state changed after each search term change
    const [vipTrackedKeywords,setVIPTrackedKeywords] = useState([])

    const researchSearchFormRef = useRef()

    const StyledTextField = styled(TextField)`
        backgroundColor: ${theme.palette.background.default}
        color: ${theme.palette.grey[500]}
        `;

    const handleClose = () => {
        //console.log("top of handleClose")
        setAnchorEl(null)
        setSaveSearchOpen((prevOpen) => !prevOpen)
    }    

    const handleSaveClick = (event) => {
        setAnchorEl(event.currentTarget)
        setSaveSearchOpen((prevOpen) => !prevOpen)
    }    

    const handleCategoryChange = (event) => {
        const { target: { value }, } = event;
        setCategoryNames(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        )
        setEditedQueryString({
            ...editedQueryString,
            category: categoryNames, //selectedCategories,
        })
        changeSubmitButtonState('change')
    }
    
    const changeCategoryState = ( category ) => {
        //console.log("top of changeCategoryState")
        //console.log("category is: " + category)
        //console.dir(selectedCategories)
        let newSelectedCategories = []
        if (selectedCategories.includes(category)) {
            //it's already there...remove it
            newSelectedCategories = selectedCategories.filter(selectedCategory => selectedCategory !== category)
            setSelectedCategories(newSelectedCategories)
            //console.log('removing')
        } else {
            //it's not there...add it
            newSelectedCategories = [...selectedCategories, category]
            setSelectedCategories(newSelectedCategories)
            //console.log('adding')
        }
        //setQueryString({...queryString, category: newSelectedCategories})
        changeSubmitButtonState('change')
    }    

    useEffect(() => {
        setIsLoading(true)
        //getBoxPosition()
       
        const getSavedSearchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }          
        ;(async () => {
            try {
                let getSavedSearchURL = `api/v1/users/userdata/`
                const getSavedSearchResponse = await fetchData(getSavedSearchURL, "could not receive current saved searches", getSavedSearchParams).promise
                //console.log("getSavedSearchResponse getting current saved searches")
                //console.dir(getSavedSearchResponse)

                setSavedSearchList(getSavedSearchResponse.saved_research_queries)

                ;await (async () => {
                    try {
                        let getMinMaxURL = `api/v1/feed/feedminmax/`
                        const getMinMaxResponse = await fetchData(getMinMaxURL, "could not receive min max values", getSavedSearchParams).promise
                        console.log("getMinMaxResponse getting min max values")
                        console.dir(getMinMaxResponse)

                        setMinMax(getMinMaxResponse.results[0])


                        var dataFromEndpoint = {

                            results: {
                                min_marketcap: parseInt(getMinMaxResponse.results[0].min_marketcap),
                                max_marketcap: parseInt(getMinMaxResponse.results[0].max_marketcap),
                                min_followers: parseInt(getMinMaxResponse.results[0].min_followers),
                                max_followers: parseInt(getMinMaxResponse.results[0].max_followers),
                                vs_btc_min: parseInt(getMinMaxResponse.results[0].min_btc_24h_dif),
                                vs_btc_max: parseInt(getMinMaxResponse.results[0].max_btc_24h_dif),
                                vs_eth_min: parseInt(getMinMaxResponse.results[0].min_eth_24h_dif),
                                vs_eth_max: parseInt(getMinMaxResponse.results[0].max_eth_24h_dif),
                                timestamp__lte: 0,
                                timestamp__gte: 12,
                                social_engagement__gte: parseInt(getMinMaxResponse.results[0].min_social_engagement),
                                social_engagement__lte: parseInt(getMinMaxResponse.results[0].max_social_engagement),
                                body_date__lte: getMinMaxResponse.results[0].max_post_date,
                                body_date__gte: getMinMaxResponse.results[0].min_post_date
                            }

                        }

                        console.log("dataFromEndpoint is: " )
                        console.dir(dataFromEndpoint)

                        setMarketCapMin(dataFromEndpoint.results.min_marketcap)
                        setMarketCapMax(dataFromEndpoint.results.max_marketcap)
                        setFollowersMin(dataFromEndpoint.results.min_followers)
                        setFollowersMax(dataFromEndpoint.results.max_followers)
                        setVsBTCMin(dataFromEndpoint.results.vs_btc_min)
                        setVsBTCMax(dataFromEndpoint.results.vs_btc_max)
                        setVsETHMin(dataFromEndpoint.results.vs_eth_min)
                        setVsETHMax(dataFromEndpoint.results.vs_eth_max)
                        setPostCreatedGTE(dataFromEndpoint.results.timestamp__gte)
                        setPostCreatedLTE(dataFromEndpoint.results.timestamp__lte)
                        setSocialEngMin(dataFromEndpoint.results.social_engagement__gte)
                        setSocialEngMax(dataFromEndpoint.results.social_engagement__lte)
                        setPostBodyGTE(dataFromEndpoint.results.body_date__gte)
                        setPostBodyLTE(dataFromEndpoint.results.body_date__lte)

                        setStartingValues(dataFromEndpoint.results)

                        //TODO add values for supply ratio when they appear
                        setQueryString({
                            market_cap__lte: dataFromEndpoint.results.max_marketcap,
                            market_cap__gte: dataFromEndpoint.results.min_marketcap,
                            followers__gte: dataFromEndpoint.results.min_followers,
                            followers__lte: dataFromEndpoint.results.max_followers,
                            btc_24h_dif__gte: dataFromEndpoint.results.vs_btc_min,
                            btc_24h_dif__lte: dataFromEndpoint.results.vs_btc_max,
                            eth_24h_dif__gte: dataFromEndpoint.results.vs_eth_min,
                            eth_24h_dif__lte: dataFromEndpoint.results.vs_eth_max,
                            search: '',
                            category: categoryNames, //selectedCategories,
                            timestamp__gte: 12,     
                            timestamp__lte: 0,   
                            social_engagement__lte: dataFromEndpoint.results.social_engagement__lte, 
                            social_engagement__gte: dataFromEndpoint.results.social_engagement__gte,
                            body_date__lte: dataFromEndpoint.results.body_date__lte,
                            body_date__gte: dataFromEndpoint.results.body_date__gte
                        })
                        setEditedQueryString({
                            market_cap__lte: dataFromEndpoint.results.max_marketcap,
                            market_cap__gte: dataFromEndpoint.results.min_marketcap,
                            followers__gte: dataFromEndpoint.results.min_followers,
                            followers__lte: dataFromEndpoint.results.max_followers,
                            btc_24h_dif__gte: dataFromEndpoint.results.vs_btc_min,
                            btc_24h_dif__lte: dataFromEndpoint.results.vs_btc_max,
                            eth_24h_dif__gte: dataFromEndpoint.results.vs_eth_min,
                            eth_24h_dif__lte: dataFromEndpoint.results.vs_eth_max,
                            search: '',
                            category: categoryNames, //selectedCategories,
                            timestamp__gte: 12,
                            timestamp__lte: 0,
                            social_engagement__lte: dataFromEndpoint.results.social_engagement__lte,
                            social_engagement__gte: dataFromEndpoint.results.social_engagement__gte,
                            body_date__lte: dataFromEndpoint.results.body_date__lte,
                            body_date__gte: dataFromEndpoint.results.body_date__gte
                        })

                    } catch (error) {
                        console.log("something is broke receiving min max values: ", error)
                    }
                    setIsLoading(false)
                })()           

            } catch (error) {
                console.log("something is broke receiving current saved searches: ", error)
            }

        })()           

        //get vip saved searches in vipSearches
        
        const getVIPSavedSearchesParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }          
        /*
        ;(async () => {
            try {
                let getVIPSavedSearchURL = `api/v1/research/savedsearch/`
                const getVIPSavedSearchResponse = await fetchData(getVIPSavedSearchURL, "could not receive current vip saved searches", getVIPSavedSearchesParams).promise
                console.log("getVIPSavedSearchResponse getting current vip saved searches")
                console.dir(getVIPSavedSearchResponse)

                let currentUsersSavedSearches = getVIPSavedSearchResponse.results.filter( currentSavedSearch => currentSavedSearch.user === userObject.user.username )

                setVipSearches(currentUsersSavedSearches)
            } catch (error) {
                console.log("something is broke receiving current vip saved searches: ", error)
            }
            setIsLoading(false)
        })()   
        */

        ;(async () => {
            try {
                let getVIPTrackedKeywordsURL = `api/v1/research/keywords/`
                const getVIPTrackedKeywordsResponse = await fetchData(getVIPTrackedKeywordsURL, "could not receive current tracked keywords", getVIPSavedSearchesParams).promise
                console.log("getVIPTrackedKeywordsResponse getting current tracked keywords$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
                console.dir(getVIPTrackedKeywordsResponse)

                if (getVIPTrackedKeywordsResponse.count > 0) {
                    let trackedKeywords = getVIPTrackedKeywordsResponse.results.map( (alert_keyword) => {
                        return (
                            {
                                "id": alert_keyword.keyword_id,
                                "keyword": alert_keyword.alert_keywords
                            }
                        )
                    })
                    console.log("to be saved in vipTrackedKeywords is:")
                    console.dir(trackedKeywords)
                    setVIPTrackedKeywords(trackedKeywords)
                }
            } catch (error) {
                console.log("something is broke receiving current searchfeed searches: ", error)
            }
            setIsLoading(false)
        })()          

        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (searchQuerySet !== '') {
            //console.log("searchQuerySet is:")
            //console.dir(searchQuerySet)
            setPostSearchTerm(searchQuerySet)
            filterCoinList('search='+searchQuerySet)
        } else {
            console.log("searchQuerySet is empty")
        }

    }, [searchQuerySet])

    useEffect(() => {
        if (saveSearchOpen === false) {
            setIsLoading(true)
            const getSavedSearchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                'Content-Type': 'application/json'
                },
            }          
            ;(async () => {
                try {
                    let getSavedSearchURL = `api/v1/users/userdata/`
                    const getSavedSearchResponse = await fetchData(getSavedSearchURL, "could not receive current saved searches", getSavedSearchParams).promise
                    //console.log("getSavedSearchResponse2 getting current saved searches")
                    //console.dir(getSavedSearchResponse)

                    setSavedSearchList(getSavedSearchResponse.saved_research_queries)
                } catch (error) {
                    console.log("something is broke receiving current saved searches: ", error)
                }
                setIsLoading(false)
            })()           

            setIsLoading(false)
        } else {
            console.log("saveSearchOpen is true...do not get here")
        }

    }, [saveSearchOpen])    

    useEffect(() => {
        //console.log("top of queryString change useEffect")
        //console.dir(queryString)
        //console.log("startingValues is:")
        //console.dir(startingValues)
        let passedQueryString = ''
        if (queryString.market_cap__gte !== startingValues.min_marketcap && queryString.market_cap__gte !== 0) {
            passedQueryString += 'market_cap__gte='+queryString.market_cap__gte
        }
        if (queryString.market_cap__lte !== startingValues.max_marketcap && queryString.market_cap__lte !== 0) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'market_cap__lte='+queryString.market_cap__lte
        }  

        if (queryString.followers__gte !== startingValues.min_followers && queryString.followers__gte !== 0) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'followers__gte='+queryString.followers__gte
        }
        if (queryString.followers__lte !== startingValues.max_followers && queryString.followers__lte !== 0) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'followers__lte='+queryString.followers__lte
        }   

        if (queryString.btc_24h_dif__gte !== startingValues.vs_btc_min && queryString.btc_24h_dif__gte !== 0) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'btc_24h_dif__gte='+queryString.btc_24h_dif__gte
        }
        if (queryString.btc_24h_dif__lte !== startingValues.vs_btc_max && queryString.btc_24h_dif__lte !== 0) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'btc_24h_dif__lte='+queryString.btc_24h_dif__lte
        }         

        if (queryString.eth_24h_dif__gte !== startingValues.vs_eth_min  && queryString.eth_24h_dif__gte !== 0) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'eth_24h_dif__gte='+queryString.eth_24h_dif__gte
        }
        if (queryString.eth_24h_dif__lte !== startingValues.vs_eth_max && queryString.eth_24h_dif__lte !== 0) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'eth_24h_dif__lte='+queryString.eth_24h_dif__lte
        }          

        if (queryString.timestamp__gte !== startingValues.timestamp__gte && queryString.timestamp__gte) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'timestamp__gte='+new Date(dateRangeMarks1[queryString.timestamp__gte].value).toISOString()
        }
        if (queryString.timestamp__lte !== startingValues.timestamp__lte && queryString.timestamp__lte) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'timestamp__lte='+ new Date(dateRangeMarks1[queryString.timestamp__lte].value).toISOString()
        }
        if (queryString.body_date__gte !== startingValues.body_date__gte) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            let gteDate = new Date( queryString.body_date__gte)
            passedQueryString += 'body_date__gte='+gteDate.toISOString()
        }
        if (queryString.body_date__lte !== startingValues.body_date__lte) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            let lteDate = new Date( queryString.body_date__lte)
            passedQueryString += 'body_date__lte='+lteDate.toISOString()
        }

        if (queryString.social_engagement__gte && queryString.social_engagement__gte !== startingValues.social_engagement__gte) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'social_engagement__gte='+queryString.social_engagement__gte
        }

        if (queryString.social_engagement__lte && queryString.social_engagement__lte !== startingValues.social_engagement__lte) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'social_engagement__lte='+queryString.social_engagement__lte
        }             

        if (queryString.search !== '' && queryString.search !== undefined) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'search='+queryString.search
        }

        //console.log("selectedCategories is:")
        //console.dir(selectedCategories)
        {/*if (selectedCategories.length > 0) {
            if (passedQueryString.length > 0) passedQueryString += '&'

            if (selectedCategories.length === 1)
                passedQueryString += 'category=' + selectedCategories[0] + ''
            else
                passedQueryString += 'category=' + selectedCategories.join(',') + ''
        }*/}

        //console.log("categoryNames is:")
        //console.dir(categoryNames)
        if (categoryNames.length > 0) {
            if (passedQueryString.length > 0) passedQueryString += '&'

            if (categoryNames.length === 1)
                passedQueryString += 'category=' + categoryNames[0] + ''
            else {
                //passedQueryString += 'category=' + categoryNames.join(',') + ''
                categoryNames.map( (categoryName,index) => {
                    if (index > 0) passedQueryString += '&'
                    passedQueryString += 'category='+categoryName
                })
            }
        }        

        //console.log("passedQueryString is:" + passedQueryString)
        filterCoinList(passedQueryString)

    }, [queryString])    
    
    const changeSubmitButtonState = (type) => {
        if (type === "change") {
            if (buttonType === 'outlined') {
                //first change after submitting....set to contained to emphasize search button
                setButtonType('contained')
            }
        } else if ( type === "submit") {
            if (buttonType === 'contained') {
                //submitting the form for search....reset to outlined to de-emphasize until a form change
                setButtonType('outlined')
            }
        }
    }

    const handleSearchChange = (e) => {
        //console.log("top of handleSearchChange and e.target.value is: " + e.target.value)
        //console.log("vipTrackedKeywords is:")
        //console.dir(vipTrackedKeywords)
        setPostSearchTerm(e.target.value)
        setEditedQueryString({
            ...editedQueryString,
            search: e.target.value,
        })
        //setQueryString({...queryString, search: e.target.value.trim()})
        if (vipTrackedKeywords.includes(e.target.value) || e.target.value.length === 0) {
            //console.log("setting addSearchTermToVIPKeywordsList to TRUE")
            setAddSearchTermToVIPKeywordsList(true)
        } else {
            //console.log("setting addSearchTermToVIPKeywordsList to FALSE")
            setAddSearchTermToVIPKeywordsList(false)
        }
        changeSubmitButtonState('change')
    }

    const handleSearchClicked = () => {
        //console.log("top of handleSearchClicked and postSearchTerm is: " + postSearchTerm)
        //console.log("marketCapMax is: " + marketCapMax)
        //console.dir(queryString)
        setQueryString({
            ...queryString,
            market_cap__lte: marketCapMax,
            market_cap__gte: marketCapMin,
            followers__lte: followersMax,
            followers__gte: followersMin,
            btc_24h_dif__lte: vsBTCMax,
            btc_24h_dif__gte: vsBTCMin,
            eth_24h_dif__lte: vsETHMax,
            eth_24h_dif__gte: vsETHMin,
            category: categoryNames,  //selectedCategories,
            timestamp__gte: postCreatedGTE,
            timestamp__lte: postCreatedLTE,
            social_engagement__gte: socialEngMin,
            social_engagement__lte: socialEngMax,
            body_date__lte: postBodyLTE,
            body_date__gte: postBodyGTE,
            search: postSearchTerm
        })
        changeSubmitButtonState('submit')
    }

    const handleSearchSubmitted = (e) => {
        e.preventDefault()
        //console.dir(e)
        //console.log("top of handleSearchSubmitted and postSearchTerm is: " + postSearchTerm)
        setQueryString({...queryString, market_cap__lte: marketCapMax, market_cap__gte: marketCapMin, 
            followers__lte: followersMax, followers__gte: followersMin, 
            btc_24h_dif__lte: vsBTCMax, btc_24h_dif__gte: vsBTCMin, 
            eth_24h_dif__lte: vsETHMax, eth_24h_dif__gte: vsETHMin,
            category: categoryNames, //selectedCategories,
            timestamp__gte: postCreatedGTE, timestamp__lte: postCreatedLTE,
            social_engagement__gte: socialEngMin, social_engagement__lte: socialEngMax,
            body_date__lte: postBodyLTE, body_date__gte: postBodyGTE,
            search: postSearchTerm
        })
        changeSubmitButtonState('submit')
    }    

    const handleMarketCapChange = (e, newMarketCapRange) => {
        //console.log("top of handleMarketCapChange")
        //console.dir(newMarketCapRange)
        setMarketCapMin(newMarketCapRange[0])
        setMarketCapMax(newMarketCapRange[1])
        setEditedQueryString({
            ...editedQueryString,
            market_cap__lte: newMarketCapRange[1],
            market_cap__gte: newMarketCapRange[0]
        })
        changeSubmitButtonState('change') 
    }

    const getResultOfMarketCapChange = (passedMarkCapMin, passedMarketCapMax) => {
        setIsMarketCapLoading(true)
        //build the query string just for market cap change
        let quickMarketCapQueryString = ''
        if (passedMarkCapMin !== startingValues.min_marketcap) {
            quickMarketCapQueryString += 'market_cap__gte='+passedMarkCapMin
        }
        if (passedMarketCapMax !== startingValues.max_marketcap) {
            if (quickMarketCapQueryString.length > 0) quickMarketCapQueryString += '&'
            quickMarketCapQueryString += 'market_cap__lte='+passedMarketCapMax
        }  

        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }
        ;(async () => {
            try {
                //var quickMarketCapResponseURL = `api/v1/vip/researchsignal/?${quickMarketCapQueryString}`
                var quickMarketCapResponseURL = `api/v1/feed/alltweets/?${quickMarketCapQueryString}&is_influencer=false`
                const quickMarketCapResponse = await fetchData(quickMarketCapResponseURL, "could not receive researcher post search market cap modal results", fetchParams).promise
                //console.log("getting researcher post search market cap modal results")
                //console.log("quickMarketCapResponseURL is: " + quickMarketCapResponseURL)
                //console.dir(quickMarketCapResponse)
                setQuickMarketCapCount(quickMarketCapResponse.count)
                setIsMarketCapLoading(false)
            } catch (error) {
                console.log("could not receive researcher post search market cap modal results: ", error)
            }
        })()
    }

    const handleSocialEngChange = (e, newSocialEngRange) => {
        setSocialEngMax(newSocialEngRange[1])
        setSocialEngMin(newSocialEngRange[0])
        setEditedQueryString({
            ...editedQueryString,
            social_engagement__lte: newSocialEngRange[1],
            social_engagement__gte: newSocialEngRange[0]
        })
        changeSubmitButtonState('change')
    }

    const handleMarketCapMinChange = (e) => {
        setMarketCapMinError(false)
        setMarketCapMin(e.target.value.replace(/\D/g,''))
        setEditedQueryString({
            ...editedQueryString,
            market_cap__gte: e.target.value.replace(/\D/g,'')
        })
        if (e.target.value > marketCapMax) { 
            setMarketCapMinError(true) 
        } else if (e.target.value.length > 3) getResultOfMarketCapChange(e.target.value, marketCapMax)
        changeSubmitButtonState('change')
    }

    const handleMarketCapMaxChange = (e) => {
        setMarketCapMaxError(false)
        setMarketCapMax(e.target.value.replace(/\D/g,''))
        setEditedQueryString({
            ...editedQueryString,
            market_cap__lte: e.target.value.replace(/\D/g,'')
        })
        if (e.target.value < marketCapMin) { 
            setMarketCapMaxError(true) 
        } else if (e.target.value.length > 3) getResultOfMarketCapChange(marketCapMin, e.target.value)
        changeSubmitButtonState('change')
    }

    const handleMarketCapCommit = (e, newMarketCapRange) => {
        //console.log("top of handleMarketCapCommit")
        //console.dir(newMarketCapRange)
        setQueryString({...queryString, market_cap__lte: newMarketCapRange[1], market_cap__gte: newMarketCapRange[0]})

    }

    const getResultOfFollowerChange = (passedFollowerMin, passedFollowerMax) => {
        setIsFollowersLoading(true)
        //build the query string just for followers change
        let quickFollowersQueryString = ''
        if (passedFollowerMin !== startingValues.min_followers) {
            quickFollowersQueryString += 'followers__gte='+passedFollowerMin
        }
        if (passedFollowerMax !== startingValues.max_followers) {
            if (quickFollowersQueryString.length > 0) quickFollowersQueryString += '&'
            quickFollowersQueryString += 'followers__lte='+passedFollowerMax
        }  

        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }
        ;(async () => {
            try {
                //var quickFollowersResponseURL = `api/v1/vip/researchsignal/?${quickFollowersQueryString}`
                var quickFollowersResponseURL = `api/v1/feed/alltweets/?${quickFollowersQueryString}&is_influencer=false`
                const quickFollowersResponse = await fetchData(quickFollowersResponseURL, "could not receive researcher post search followers modal results", fetchParams).promise
                //console.log("getting researcher post search followers modal results")
                //console.log("quickFollowersResponseURL is: " + quickFollowersResponseURL)
                //console.dir(quickFollowersResponse)
                setQuickFollowersCount(quickFollowersResponse.count)

                setIsFollowersLoading(false)
            } catch (error) {
                console.log("could not receive researcher post search followers modal results: ", error)
            }
        })()
    }

    const getResultOfSocEngChange = (passedSEMin, passedSEMax) => {
        setIsFollowersLoading(true)
        //build the query string just for followers change
        let quickFollowersQueryString = ''
        if (passedSEMin !== startingValues.max_social_engagement) {
            quickFollowersQueryString += 'social_engagement__gte='+passedSEMin
        }
        if (passedSEMax !== startingValues.max_social_engagement) {
            if (quickFollowersQueryString.length > 0) quickFollowersQueryString += '&'
            quickFollowersQueryString += 'social_engagement__lte='+passedSEMax
        }

        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            try {
                //var quickFollowersResponseURL = `api/v1/vip/researchsignal/?${quickFollowersQueryString}`
                var quickFollowersResponseURL = `api/v1/feed/alltweets/?${quickFollowersQueryString}&is_influencer=false`
                const quickFollowersResponse = await fetchData(quickFollowersResponseURL, "could not receive researcher post search followers modal results", fetchParams).promise
                //console.log("getting researcher post search followers modal results")
                //console.log("quickFollowersResponseURL is: " + quickFollowersResponseURL)
                //console.dir(quickFollowersResponse)
                setQuickSocEngCount(quickFollowersResponse.count)

                setIsFollowersLoading(false)
            } catch (error) {
                console.log("could not receive researcher post search followers modal results: ", error)
            }
        })()
    }

    const handleFollowerChange = (e, newFollowerRange) => {
        setFollowersMax(newFollowerRange[1])
        setFollowersMin(newFollowerRange[0])
        setEditedQueryString({
            ...editedQueryString,
            followers__gte: newFollowerRange[0],
            followers__lte: newFollowerRange[1]
        })
        changeSubmitButtonState('change')
    }

    const handleFollowersMinChange = (e) => {
        setFollowersMinError(false)
        setFollowersMin(e.target.value.replace(/\D/g,''))
        setEditedQueryString({
            ...editedQueryString,
            followers__gte: e.target.value.replace(/\D/g,'')
        })
        if (e.target.value > followersMax) {
            setFollowersMinError(true)
        } else if (e.target.value.length > 3) getResultOfFollowerChange(e.target.value, followersMax)
        changeSubmitButtonState('change')
    }

    const handleFollowersMaxChange = (e) => {
        setFollowersMaxError(false)
        setFollowersMax(e.target.value.replace(/\D/g,''))
        setEditedQueryString({
            ...editedQueryString,
            followers__gte: e.target.value.replace(/\D/g,'')
        })
        if (e.target.value < followersMin) {
            setFollowersMaxError(true)
        } else if (e.target.value.length > 3) getResultOfFollowerChange(followersMin, e.target.value)
        changeSubmitButtonState('change')
    }

    const handleSocEngMinChange = (e) => {
        setSocialEngMinError(false)
        setSocialEngMin(e.target.value.replace(/\D/g,''))
        setEditedQueryString({
            ...editedQueryString,
            social_engagement__gte: e.target.value.replace(/\D/g,'')
        })
        if (e.target.value > socialEngMax) {
            setSocialEngMinError(true)
        } else if (e.target.value.length > 3) getResultOfSocEngChange(e.target.value, socialEngMax)
        changeSubmitButtonState('change')
    }

    const handleSocEngMaxChange = (e) => {
        setSocialEngMaxError(false)
        setSocialEngMax(e.target.value.replace(/\D/g,''))
        setEditedQueryString({
            ...editedQueryString,
            social_engagement__lte: e.target.value.replace(/\D/g,'')
        })
        if (e.target.value < socialEngMin) {
            setSocialEngMaxError(true)
        } else if (e.target.value.length > 3) getResultOfSocEngChange(socialEngMin, e.target.value)
        changeSubmitButtonState('change')
    }

    const handleFollowerCommit = (e, newFollowerRange) => {
        setQueryString({...queryString, followers__lte: newFollowerRange[1], followers__gte: newFollowerRange[0]})
    }    

    const handleVsBTCRatioChange = (e, newVsBTCRange) => {
        setVsBTCMin(newVsBTCRange[0])
        setVsBTCMax(newVsBTCRange[1])
        setEditedQueryString({
            ...editedQueryString,
            btc_24h_dif__gte: newVsBTCRange[0],
            btc_24h_dif__lte: newVsBTCRange[1]
        })
        changeSubmitButtonState('change')
    }
    
    const handleVsBTCRatioCommit = (e, newVsBTCRange) => {
        setQueryString({...queryString, btc_24h_dif__lte: newVsBTCRange[1], btc_24h_dif__gte: newVsBTCRange[0]})
    }

    const handleVsBTCTimeHorizon = (e) => {
        setVsBTCTimeHorizon(e.target.value)
        changeSubmitButtonState('change')
    }    

    const handleVsETHRatioChange = (e, newVsETHRange) => {
        setVsETHMin(newVsETHRange[0])
        setVsETHMax(newVsETHRange[1])
        setEditedQueryString({
            ...editedQueryString,
            eth_24h_dif__gte: newVsETHRange[0],
            eth_24h_dif__lte: newVsETHRange[1]
        })
        changeSubmitButtonState('change')
    }

    const handleVsETHRatioCommit = (e, newVsETHRange) => {
        setQueryString({...queryString, eth_24h_dif__lte: newVsETHRange[1], eth_24h_dif__gte: newVsETHRange[0]})
    }    

    const handleVsETHTimeHorizon = (e) => {
        setVsETHTimeHorizon(e.target.value)
        changeSubmitButtonState('change')
    }

    const saveThisSearch = () => {
        //open popup to save queryString with a label
    }

    const handleReset = ( valuesToReset ) => {
        switch(valuesToReset) {
            case 'all':
                setQueryString({
                    ...queryString,
                    market_cap__lte: startingValues.max_marketcap,
                    market_cap__gte: startingValues.min_marketcap,
                    followers__lte: startingValues.max_followers,
                    followers__gte: startingValues.min_followers,
                    btc_24h_dif__lte: startingValues.vs_btc_max,
                    btc_24h_dif__gte: startingValues.vs_btc_min,
                    eth_24h_dif__lte: startingValues.vs_eth_max,
                    eth_24h_dif__gte: startingValues.vs_eth_min,
                    search: '',
                    category: [],
                    timestamp__gte: startingValues.timestamp__gte,
                    timestamp__lte: startingValues.timestamp__lte,
                    social_engagement__gte: startingValues.social_engagement__gte,
                    social_engagement__lte: startingValues.social_engagement__lte,
                    body_date__gte: startingValues.body_date__gte,
                    body_date__lte: startingValues.body_date__lte
                })
                setMarketCapMin(startingValues.min_marketcap)
                setMarketCapMax(startingValues.max_marketcap)
                setFollowersMax(startingValues.max_followers)
                setFollowersMin(startingValues.min_followers)
                setVsBTCMin(startingValues.vs_btc_min)
                setVsBTCMax(startingValues.vs_btc_max)
                setVsETHMin(startingValues.vs_eth_min)
                setVsETHMax(startingValues.vs_eth_max)
                setPostSearchTerm('')
                setCategoryNames([])
                setPostCreatedGTE(startingValues.timestamp__gte)
                setPostCreatedLTE(startingValues.timestamp__lte)
                setSocialEngMax(startingValues.social_engagement__lte)
                setSocialEngMin(startingValues.social_engagement__gte)
                setPostBodyGTE(startingValues.body_date__gte)
                setPostBodyLTE(startingValues.body_date__lte)
                break;
            case 'marketCap': 
                setQueryString({...queryString, market_cap__lte: startingValues.max_marketcap, market_cap__gte: startingValues.min_marketcap})
                setMarketCapMin(startingValues.min_marketcap)
                setMarketCapMax(startingValues.max_marketcap)
                break;
            case 'followers': 
                setQueryString({...queryString, followers__lte: startingValues.max_followers, followers__gte: startingValues.min_followers})
                setFollowersMax(startingValues.max_followers)
                setFollowersMin(startingValues.min_followers)
                break;   
            case 'vsBTC': 
                setQueryString({...queryString, btc_24h_dif__lte: startingValues.vs_btc_max, btc_24h_dif__gte: startingValues.vs_btc_min})
                setVsBTCMin(startingValues.vs_btc_min)
                setVsBTCMax(startingValues.vs_btc_max)
                break;    
            case 'vsETH': 
                setQueryString({...queryString, eth_24h_dif__lte: startingValues.vs_eth_max, eth_24h_dif__gte: startingValues.vs_eth_min})
                setVsETHMin(startingValues.vs_eth_min)
                setVsETHMax(startingValues.vs_eth_max)
                break;      
            case 'searchtext':
                setQueryString({...queryString, search: ''})
                setPostSearchTerm('')
                break;
            case 'category':
                //setSelectedCategories([])
                setCategoryNames([])
                setQueryString({...queryString, category: []})
                break;
            case 'dateRange':
                setQueryString({...queryString, timestamp__gte: startingValues.timestamp__gte, timestamp__lte: startingValues.timestamp__lte})
                setPostCreatedGTE(startingValues.timestamp__gte)
                setPostCreatedLTE(startingValues.timestamp__lte)
                break;               
            case 'social_engagement':
                setQueryString({...queryString, social_engagement__gte: startingValues.social_engagement__gte, social_engagement__lte: startingValues.social_engagement__lte})
                setSocialEngMax(startingValues.social_engagement__lte)
                setSocialEngMin(startingValues.social_engagement__gte)
                break;          
            case 'dateBodyRange':
                setQueryString({...queryString, body_date__gte: startingValues.body_date__gte, body_date__lte: startingValues.body_date__lte})
                setPostBodyGTE(startingValues.body_date__gte)
                setPostBodyLTE(startingValues.body_date__lte)
                break;                            
            default:
                //do nothing
                break;                                        
        }
    }

    const handleDateRangeChange = (e, newDateRange) => {
        console.log(new Date(dateRangeMarks1[newDateRange[1]].value).toISOString())
        //console.log("top of handleDateRangeChange and newDateRange is:")
        //console.dir(newDateRange)
        //1d,2d,3d,4d,5d,6d,1w,2w,3w,1m,2m,3m
        setPostCreatedLTE(dateRangeMarks[newDateRange[0]].value)
        setPostCreatedGTE(dateRangeMarks[newDateRange[1]].value)
        setEditedQueryString({
            ...editedQueryString,
            timestamp__gte: new Date(dateRangeMarks1[newDateRange[1]].value).toISOString(),
            timestamp__lte: new Date(dateRangeMarks1[newDateRange[0]].value).toISOString(),
        })
        changeSubmitButtonState('change')
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return [
          date.getFullYear(),  
          padTo2Digits(date.getMonth() + 1),
          padTo2Digits(date.getDate()),
          
        ].join('-');
    }    

    const handleDateRangeLTEChange = (newLTEDate) => {
        //console.log("top of handleDateRangeLTEChange and newLTEDate is:")
        let lteDate = formatDate(new Date(newLTEDate))
        //1d,2d,3d,4d,5d,6d,1w,2w,3w,1m,2m,3m
        setPostBodyLTE(lteDate)
        changeSubmitButtonState('change')
    }    

    const handleDateRangeGTEChange = (newGTEDate) => {
        //console.log("top of handleDateRangeGTEChange and newGTEDate is:")
        let gteDate = formatDate(new Date(newGTEDate))
        //1d,2d,3d,4d,5d,6d,1w,2w,3w,1m,2m,3m
        setPostBodyGTE(gteDate)
        changeSubmitButtonState('change')
    }        

    const handleSavedSearchChange = (e) => {
        //e.target.value is the index of the chosen saved search
        console.log("top of handleSavedSearchChange: " + e.target.value)
        console.dir(savedSearchList[e.target.value])
        let savedSearchPieces = savedSearchList[e.target.value].split('||')
        console.log("savedSearchPieces is")
        console.dir(savedSearchPieces)
        setSelectedSavedSearch(e.target.value)
        setSelectedSavedSearchQueryString(savedSearchPieces[1])

        //parse saved search query string to pull out value
        //market_cap__gte=35331718&market_cap__lte=87089450&followers__gte=233924&followers__lte=797156&btc_24h_dif__gte=-4&btc_24h_dif__lte=6&eth_24h_dif__gte=-4&eth_24h_dif__lte=9&search=burning&category=[Ethereum Ecosystem]
        //break at & to get name/value pairs
        //break at = to get name and value separately
        //all values will be set!!! to make sure anything not mentioned in the string is set to default value
        let queryStringNameValues = savedSearchPieces[1].split('&')

        let newQueryString = {}
        queryStringNameValues.map( pair => {
            let splitPair = pair.split('=')
            newQueryString[splitPair[0]] = splitPair[1]
        })
        console.log("newQueryString before setting defaults is")
        console.dir(newQueryString)

        const queryObjectProperties = {
            market_cap__lte: 'max_marketcap',
            market_cap__gte: 'min_marketcap',
            followers__gte: 'min_followers',
            followers__lte: 'max_followers',
            btc_24h_dif__gte: 'vs_btc_min',
            btc_24h_dif__lte: 'vs_btc_max',
            eth_24h_dif__gte: 'vs_eth_min',
            eth_24h_dif__lte: 'vs_eth_max',
            timestamp__gte: 'timestamp__gte',
            timestamp__lte: 'timestamp__lte',
            body_date__gte: 'body_date__gte',
            body_date__lte: 'body_date__lte',
            social_engagement__gte: 'social_engagement__gte',
            social_engagement__lte: 'social_engagement__lte',
            search: ''
        }

        Object.keys(queryObjectProperties).map( property => {
            if (!newQueryString.hasOwnProperty(property)) {
                //add this as a property with a default value
                //console.log("did not find " +  property + " inside of newQueryString: ")
                //console.dir(newQueryString)
                newQueryString[property] = startingValues[queryObjectProperties[property]]
            } else {
                console.log("found property: " + property + " and it's value is: " + newQueryString[property])
            }
        })

        //console.log("newQueryString before category defaults:")
        //console.dir(queryString)        

        if (!newQueryString.hasOwnProperty('category')) {
            //console.log("category was NOT found in queryString" )
            //console.dir(newQueryString)
            //setSelectedCategories([])
            setCategoryNames([])
            newQueryString['category'] = []
        } else {
            //console.log("category was found and is:")
            //console.dir(newQueryString['category'])
            let strippedCategoryNameIn = newQueryString['category']
            if ((newQueryString['category'].startsWith('[')) && (newQueryString['category'].endsWith(']'))) {
                //console.log(" brackets found at the front and back....removing them")
                strippedCategoryNameIn = newQueryString['category'].slice(1, -1)
            } 
            //console.log("strippedCategoryNameIn is: ")
            //console.dir(strippedCategoryNameIn)
            //console.log("after removing the first [ and last ]")
            //console.log("and is being split to: ")
            //console.dir(strippedCategoryNameIn.split(','))

            //setSelectedCategories(strippedCategoryNameIn.split(','))  
            setCategoryNames(strippedCategoryNameIn.split(','))       
        }

        //console.log("newQueryString after adding defaults:")
        //console.dir(newQueryString)

        setMarketCapMin(newQueryString['market_cap__gte'])
        setMarketCapMax(newQueryString['market_cap__lte'])
        setFollowersMin(newQueryString['followers__gte'])
        setFollowersMax(newQueryString['followers__lte'])       
        setVsBTCMin(newQueryString['btc_24h_dif__gte'])
        setVsBTCMax(newQueryString['btc_24h_dif__lte'])
        setVsETHMin(newQueryString['eth_24h_dif__gte'])
        setVsETHMax(newQueryString['eth_24h_dif__lte'])
        setPostCreatedGTE(newQueryString['timestamp__gte'])
        setPostCreatedLTE(newQueryString['timestamp__lte'])        
        setSocialEngMax(newQueryString['social_engagement__lte'])
        setSocialEngMin(newQueryString['social_engagement__gte'])            
        setPostSearchTerm(newQueryString['search'])

        //TODO add values for supply ratio when they appear
        setQueryString(newQueryString)        
    }

    const abbreviateValue = (number) => {
        if (number > 1000000000) {
          return ((number / 1000000000).toFixed(2)) + 'B'
        } else if (number > 1000000) {
          return ((number/1000000).toFixed(2)) + 'M'
        } else if (number > 1000) {
          return ((number/1000).toFixed(2)) + 'K'
        } else {
          //just display the full amount
          return number
        }
    }    

    const toggleMarketCapSearchDetails = () => {
        //console.log("top of toggleMarketCapSearchDetails")
        getResultOfMarketCapChange(marketCapMin, marketCapMax)
        setMarketCapDetailsOpen(!marketCapDetailsOpen)
    }

    const toggleSocialEngagementSearchDetails = () => {
        //console.log("top of toggleMarketCapSearchDetails")
        getResultOfSocEngChange(socialEngMin, socialEngMax)
        setSocialEngagementDetailsOpen(!socialEngagementDetailsOpen)
    }

    const saveMarketCapDetails = () => {
        applyMarketCapDetailsChange()
        setMarketCapDetailsOpen(false)
    }

    const cancelMarketCapDetails = () => {
        setMarketCapDetailsOpen(false)
        
    }

    const applyMarketCapDetailsChange = () => {
        //console.log("top of applyMarketCapDetailsChange and marketCapMin is: " + marketCapMin + " and marketCapMax is: " + marketCapMax)
        //setQueryString({...queryString, market_cap__lte: marketCapMax, market_cap__gte: marketCapMin})
    }

    const toggleFollowerSearchDetails = () => {
        //console.log("top of toggleFollowerSearchDetails")
        getResultOfFollowerChange(followersMin,followersMax)
        setTwitterFollowersDetailsOpen(!twitterFollowersDetailsOpen)
    }

    const saveFollowerDetails = () => {
        applyTwitterFollowersDetailsChange()
        setTwitterFollowersDetailsOpen(false)
    }

    const saveSocEngDetails = () => {
        applySocialEngagementDetailsChange()
        setSocialEngagementDetailsOpen(false)
    }

    const cancelfollowerDetails = () => {
        setTwitterFollowersDetailsOpen(false)
    }

    const cancelSocEngDetails = () => {
        setSocialEngagementDetailsOpen(false)
    }

    const applyTwitterFollowersDetailsChange = () => {
        //console.log("top of applyTwitterFollowersDetailsChange and followersMin is: " + followersMin + " and followersMax is: " + followersMax)
        //setQueryString({...queryString, followers__lte: followersMax, followers__gte: followersMin})
    }
    const applySocialEngagementDetailsChange = () => {
        //console.log("top of applyTwitterFollowersDetailsChange and followersMin is: " + followersMin + " and followersMax is: " + followersMax)
        //setQueryString({...queryString, followers__lte: followersMax, followers__gte: followersMin})
    }

    const displayNumber = (number) => {
        return parseInt(number).toLocaleString('en-US', {maximumFractionDigits: 2})
    }

    function dateValueText(value) {
        //console.log("top of dateValueText and value is:")
        //console.dir(value)        
        let arrayValue = dateRangeMarks.findIndex((mark) => mark.value === value)
        //console.dir(arrayValue)
        return arrayValue.value
    }
    
    function dateLabelFormat(value) {
        //console.log("top of dateLabelFormat and value is:")
        //console.dir(value)
        let arrayValue = dateRangeMarks.findIndex((mark) => mark.value === value)
        //console.dir(arrayValue)
        return arrayValue.label
    }    

    function socialEngLabelFormat(value) {
        //console.log("top of socialEngLabelFormat and value is:")
        //console.dir(value)
        let arrayValue = socialEngRange.findIndex((mark) => mark.value === value)
        //console.dir(arrayValue)
        return arrayValue.label
    }        

    function socialEngValueText(value) {
        //console.log("top of socialEngValueText and value is:")
        //console.dir(value)        
        let arrayValue = socialEngRangeMarks.findIndex((mark) => mark.value === value)
        //console.dir(arrayValue)
        return arrayValue.value
    }    

    const deleteSearchTermToVipTrackedKeywords = (vipKeywordID) => {
        console.log("top of deleteSearchTermToVipTrackedKeywords and vipKeywordID is:")
        console.dir(vipKeywordID)
        if (vipKeywordID) {
            ;(async () => {

                const deleteVIPKeywordParams = {
                    method: 'DELETE',
                    dataType: 'json',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'keyword_id': vipKeywordID,
                    }),
                }                  
                try {
                let deleteVIPKeywordURL = `api/v1/research/postkeywords/`
                const deleteVIPKeywordResponse = await fetchData(deleteVIPKeywordURL, "could not put new saved search", deleteVIPKeywordParams).promise
                //console.log("deleteVIPKeywordResponse putting new saved search")
                //console.dir(deleteVIPKeywordResponse)

                let newVIPTrackedKeywords = vipTrackedKeywords.filter( trackedKeyword => trackedKeyword.id !== vipKeywordID )
                //console.log("newVIPTrackedKeywords after deleting is:")
                //console.dir(newVIPTrackedKeywords)
                setVIPTrackedKeywords(newVIPTrackedKeywords)
                } catch (error) {
                    console.log("something is broke deleting vip keyword: ", error)
                }
            })()    
        } else {
            console.log("vipKeywordID is false....no need to remove from tracked keywords")
        }     
    }

    const sendSearchTermToVipTrackedKeywords = (sendThisSearch) => {
        console.log("top of sendSearchTermToVipTrackedKeywords sending: ")
        console.dir(sendThisSearch)

        //let newKeywords = [...vipTrackedKeywords, sendThisSearch]

        /*if(sendThisSearch.trim().includes('DATE')){
            let tokens = sendThisSearch.trim().split('DATE')
            let cleanedTokens = tokens.filter(item => item && item.length > 1 && item !== 'DATE').join(' ');
            console.log('cleaned tokens: ' + cleanedTokens)
            const now = new Date(Date.now())
            const month = now.toLocaleString('default', { month: 'long' });
            const shortMonth = month.slice(0,3)
            const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1).toLocaleString('default', { month: 'long' });
            const shortNextMonth = nextMonth.slice(0,3);
            for(let i of [month, nextMonth]){
                const postSearchTermToVIPTrackedKeywordsParams = {
                        method: 'POST',
                        dataType: 'json',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            'alert_keywords': cleanedTokens + " " + i
                        }),
                    }
                ;(async () => {
                    try {
                        let postSearhTermToVIPTrackedKeywordsURL = `api/v1/research/postkeywords/`
                        const postSearchTermToVIPTrackedKeywordResponse = await fetchData(postSearhTermToVIPTrackedKeywordsURL, "could not post new tracked keyword", postSearchTermToVIPTrackedKeywordsParams).promise
                        console.log(postSearchTermToVIPTrackedKeywordResponse)
                    } catch (error) {
                        console.log("something is broke posting new tracked keyword: ", error)
                    }
                })().then(async () => {
                    const getVIPTrackedParams = {
                        method: 'GET',
                        dataType: 'json',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    }
                    try {
                        let getVIPTrackedKeywordsURL = `api/v1/research/trackedkeywords/`
                        const getVIPTrackedKeywordsPostSaveResponse = await fetchData(getVIPTrackedKeywordsURL, "could not receive current tracked keywords", getVIPTrackedParams).promise
                        //console.log("getVIPTrackedKeywordsPostSaveResponse getting current tracked keywords after sending to vip tracked keywords")
                        //console.dir(getVIPTrackedKeywordsPostSaveResponse)

                        if (getVIPTrackedKeywordsPostSaveResponse.count > 0) {
                            let trackedKeywords = getVIPTrackedKeywordsPostSaveResponse.results.map( (alert_keyword) => {
                                return (
                                    {
                                        "id": alert_keyword.id,
                                        "keyword": alert_keyword.alert_keywords
                                    }
                                )
                            })
                            setVIPTrackedKeywords(trackedKeywords)
                        }
                    } catch (error) {
                        console.log("something is broke receiving current searchfeed searches: ", error)
                    }
                })
            }
        }else{ */
            const postSearchTermToVIPTrackedKeywordsParams = {
                    method: 'POST',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'user': userObject.user.username,
                        'alert_keywords': sendThisSearch.trim()
                    }),
                }
            ;(async () => {
                try {
                    //add new keywords to appear as a search in vip
                    let postSearhTermToVIPTrackedKeywordsURL = `api/v1/research/postkeywords/`
                    const postSearchTermToVIPTrackedKeywordResponse = await fetchData(postSearhTermToVIPTrackedKeywordsURL, "could not post new tracked keyword", postSearchTermToVIPTrackedKeywordsParams).promise
                    console.log("postSearhTermToVIPTrackedKeywordsURL is: " + postSearhTermToVIPTrackedKeywordsURL)
                    console.log("postSearchTermToVIPTrackedKeywordResponse is:")
                    console.dir(postSearchTermToVIPTrackedKeywordResponse)

                    setTimeout( function () {
                        const getVIPTrackedParams = {
                            method: 'GET',
                            dataType: 'json',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                        }
                        ;(async () => {                            
                            try {
                                let getVIPTrackedKeywordsURL = `api/v1/research/keywords/`
                                const getVIPTrackedKeywordsPostSaveResponse = await fetchData(getVIPTrackedKeywordsURL, "could not receive current tracked keywords", getVIPTrackedParams).promise
                                console.log("getVIPTrackedKeywordsURL is:" + getVIPTrackedKeywordsURL)
                                console.log("getVIPTrackedKeywordsPostSaveResponse is:")
                                console.dir(getVIPTrackedKeywordsPostSaveResponse)
                                if (getVIPTrackedKeywordsPostSaveResponse.count > 0) {
                                    let trackedKeywords = getVIPTrackedKeywordsPostSaveResponse.results.map( (alert_keyword) => {
                                        return (
                                            {
                                                "id": alert_keyword.keyword_id,
                                                "keyword": alert_keyword.alert_keywords
                                            }
                                        )
                                    })
                                    setVIPTrackedKeywords(trackedKeywords)
                                }
                            } catch (error) {
                                console.log("something is broke receiving current searchfeed searches: ", error)
                            }
                        })()                          
                    }, 2000)  

                } catch (error) {
                    console.log("something is broke posting new tracked keyword: ", error)
                }
            })()
                  
            
       // }
    }   

    const getBoxPosition = () => {
        console.log("top of getBoxPosition")
        console.log("x is: "+researchSearchFormRef.current.offsetLeft)
        console.log("y is: "+researchSearchFormRef.current.offsetTop)
        console.log("x2 is: "+researchSearchFormRef.current.offsetWidth)
        console.log("y2 is: "+researchSearchFormRef.current.offsetHeight)
        console.dir(researchSearchFormRef)
    }

    if (isLoading) return <CircularProgress />
    return (
        <Box width="100%" sx={{paddingLeft: "10px", paddingRight: "10px"}} ref={researchSearchFormRef}>
            <form onSubmit={handleSearchSubmitted}>
            <Stack direction="column" display="flex" alignItems="center" justifyContent="flex-start">
                <Box sx={{width: '100%', padding: '5px', paddingBottom: '0px'}}>
                    <Stack 
                        direction="row" 
                        display="flex" 
                        alignItems="center"
                        sx={{
                            position: 'relative',
                            top: '-10px'
                        }}
                    >
                        <TextField 
                            id="research-tweet-search" 
                            label="Crypto Project Twitter Search" 
                            variant="outlined"
                            value={postSearchTerm} 
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                width: '100%', 
                                minWidth: '80px',
                                color: theme.palette.grey[500], 
                                input: {
                                    background: theme.palette.dark[900],
                                },
                            }}
                            InputLabelProps={{
                                style: { 
                                    color: theme.palette.grey[500] ,
                                    position: 'relative',
                                    top: '17px'
                                },
                            }}
                            inputProps={{
                                style: { height: '12px' },
                            }}

                            
                        />
                            <Tooltip title="search">            
                                <IconButton
                                    aria-label="clear coin"
                                    onClick={handleSearchClicked}
                                    edge="end"
                                    sx={{
                                        position: 'relative',
                                        top: '12px'
                                    }}
                                    type="submit"
                                >
                                    <Search sx={{fontSize: '.8em', color: theme.palette.grey[500]}} />
                                </IconButton> 
                            </Tooltip> 
                            <Tooltip title="clear search">
                                <IconButton
                                    aria-label="clear search"
                                    onClick={() => handleReset('all')}
                                    edge="end"
                                    sx={{
                                        position: 'relative',
                                        top: '12px'
                                    }} 
                                >
                                    <Clear sx={{fontSize: '.8em',color: theme.palette.grey[500]}} />
                                </IconButton>
                            </Tooltip>          
                            <Tooltip title="open save search dialog">
                                <IconButton
                                    aria-label="open favorite search dialog"
                                    onClick={handleSaveClick}
                                    edge="end"
                                    sx={{
                                        position: 'relative',
                                        top: '12px'
                                    }} 
                                >
                                    <FavoriteBorder sx={{fontSize: '.8em',color: theme.palette.error.dark}} />
                                </IconButton>
                            </Tooltip>
                            
                            <Tooltip title="add search term to vip hot searches">
                                <span>
                                    <IconButton
                                        aria-label="add search term to vip hot searches"
                                        onClick={()=>sendSearchTermToVipTrackedKeywords(postSearchTerm)}
                                        disabled={addSearchTermToVIPKeywordsList}
                                        edge="end"
                                        sx={{
                                            position: 'relative',
                                            top: '12px'
                                        }} 
                                    >
                                        <TiArrowForward 
                                            sx={{fontSize: '.8em'}}
                                            color={addSearchTermToVIPKeywordsList ? theme.palette.grey[800] : theme.palette.warning.dark}
                                        />
                                    </IconButton> 
                                </span>               
                            </Tooltip>                                           
                    </Stack>    
                </Box>
                <Box sx={{ minWidth: 120, width: '100%' }}>
                    <FormControl fullWidth>
                        <InputLabel 
                            id="saved-search-select-label"
                            sx={{
                                color: theme.palette.grey[500] ,
                                position: 'relative',
                                top: '14px'
                            }}
                        >Saved Searches</InputLabel>
                        <Select
                            labelId="saved-search-select-label"
                            id="saved-search-select"
                            value={selectedSavedSearch}
                            label="Saved Searches"
                            onChange={handleSavedSearchChange}
                            size="small"
                            sx={{ backgroundColor: theme.palette.dark[900],
                                '.MuiSelect-icon': {
                                    color: 'white'
                                },
                                ".MuiSelect-outlined":{
                                    color: 'white'
                                }}}
                            inputProps= {{
                                sx: {
                                    backgroundColor: theme.palette.dark[900],
                                    "&:focus": {
                                        borderRadius: 4,
                                        background: "rgba(0,0,0,0)"
                                    },
                                    color: theme.palette.primary.dark,  
                                }
                            }}
                            //InputLabelProps={{
                            //    style: { 
                            //        color: theme.palette.grey[500] ,
                            ///        position: 'relative',
                            //</FormControl>        top: '17px'
                            //    },
                            //}}                            
                        >
                            <MenuItem value='0'>none</MenuItem>
                            {savedSearchList.map( (savedSearch, savedSearchIndex) => {
                                    let savedSearchElements = savedSearch.split('||')
                                    return (
                                        <MenuItem key={savedSearchIndex} value={savedSearchIndex}>{savedSearchElements[0]}</MenuItem>
                                    )
                                }
                            )}
                        </Select>
                    </FormControl>
                </Box>                  
                <Stack direction="column" sx={{width: '100%', marginTop: '5px'}}>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                            <Typography sx={{color: theme.palette.primary.main, fontWeight: 700, marginRight: '5px'}}>Market Cap</Typography>
                            <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('marketCap')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                        </Stack>
                        <IconButton onClick={()=>toggleMarketCapSearchDetails()}>
                            <CgMoreO size={18} color={theme.palette.primary.main}/>
                        </IconButton>
                    </Stack>
                  
                    <Box sx={{padding: '5px', border: 2, borderColor: theme.palette.grey[400], backgroundColor: theme.palette.background.default, width: '100%', borderRadius: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                            <Typography display="inline">${abbreviateValue(marketCapMin)}</Typography>
                            <Typography display="inline">to</Typography>
                            <Typography display="inline">${abbreviateValue(marketCapMax)}</Typography>
                        </Stack>
                    </Box>                    
                    <Slider 
                        getAriaLabel={() => "Market Cap" }
                        value={[parseInt(marketCapMin),parseInt(marketCapMax)]} 
                        min={startingValues.min_marketcap}
                        max={startingValues.max_marketcap}
                        //onChangeCommitted={handleMarketCapCommit}
                        onChange={handleMarketCapChange} 
                        defaultValue={[parseInt(marketCapMin),parseInt(marketCapMax)]}    
                        valueLabelDisplay="off"
                        sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}
                    />
                </Stack>
                <Stack direction="column" sx={{width: '100%', marginTop: '5px'}}> 
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                            <Typography sx={{color: theme.palette.primary.main, fontWeight: 700}}>Twitter Followers</Typography>
                            <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('followers')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                        </Stack>    
                        <IconButton onClick={()=>toggleFollowerSearchDetails()}>
                            <CgMoreO size={18} color={theme.palette.primary.main}/>
                        </IconButton>
                    </Stack>                      
                    <Box sx={{padding: '5px', border: 2, borderColor: theme.palette.grey[400], backgroundColor: theme.palette.background.default, width: '100%', borderRadius: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                            <Typography display="inline">{abbreviateValue(followersMin)}</Typography>
                            <Typography display="inline">to</Typography>
                            <Typography display="inline">{abbreviateValue(followersMax)}</Typography>
                        </Stack>
                    </Box>                             
                    <Slider 
                        getAriaLabel={() => "Twitter Follower Count" }
                        value={[parseInt(followersMin),parseInt(followersMax)]} 
                        min={startingValues.min_followers}
                        max={startingValues.max_followers}
                        onChange={handleFollowerChange}
                        //onChangeCommitted={handleFollowerCommit}
                        defaultValue={[parseInt(followersMin),parseInt(followersMax)]}
                        sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}
                    />
                </Stack>             
                <Stack direction="column" sx={{width: '100%', marginTop: '5px'}}> 
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                            <Typography sx={{color: theme.palette.primary.main, fontWeight: 700}}>Social Engagement</Typography>
                            <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('social_engagement')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                        </Stack>
                        <IconButton onClick={()=>toggleSocialEngagementSearchDetails()}>
                            <CgMoreO size={18} color={theme.palette.primary.main}/>
                        </IconButton>
                      </Stack>                      
                    <Box sx={{padding: '5px', border: 2, borderColor: theme.palette.grey[400], backgroundColor: theme.palette.background.default, width: '100%', borderRadius: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                            <Typography display="inline">{socialEngMin}</Typography>
                            <Typography display="inline">to</Typography>
                            <Typography display="inline">{socialEngMax}</Typography>
                        </Stack>
                    </Box>                             
                    {/*<Slider 
                        getAriaLabel={() => "Social Engagement" }
                        value={[socialEngMin,socialEngMax]} 
                        min={startingValues.social_engagement__gte}
                        max={startingValues.social_engagement__lte}
                        onChange={handleSocialEngChange}
                        defaultValue={[socialEngMin,socialEngMax]}
                        sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}
                            />*/}
                    <Slider 
                        marks={socialEngRangeMarks}
                        //getAriaLabel={} 
                        value={[parseInt(socialEngMin),parseInt(socialEngMax)]} 
                        min={startingValues.social_engagement__gte}
                        max={startingValues.social_engagement__lte}
                        onChange={handleSocialEngChange}
                        defaultValue={[parseInt(startingValues.timestamp__gte),parseInt(startingValues.timestamp__lte)]}
                        sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}
                        valueLabelFormat={socialEngLabelFormat}
                        getAriaValueText={socialEngValueText}
                        step={.25}
                        //valueLabelDisplay="off"

                    />                    
                </Stack>                   
                <Stack direction="column" sx={{width: '100%', marginTop: '5px'}}> 
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">    
                        <Typography sx={{color: theme.palette.primary.main, fontWeight: 700}}>vs BTC</Typography>
                        <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('vsBTC')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                    </Stack>                      
                    <Box sx={{padding: '5px', border: 2, borderColor: theme.palette.grey[400], backgroundColor: theme.palette.background.default, width: '100%', borderRadius: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                            <Typography display="inline">{vsBTCMin}%</Typography>
                            <Typography display="inline">to</Typography>
                            <Typography display="inline">{vsBTCMax}%</Typography>
                            {/* only have 1 day data atm...leave until the
                                <FormControl sx={{ m: 0, minWidth: 80 }} size="small">
                                <InputLabel id="select-vsbtctimehorizon-label">horizon</InputLabel>
                                <Select
                                    labelId="select-vsbtctimehorizon-label"
                                    id="select-vsbtctimehorizon"
                                    value={vsBTCTimeHorizon}
                                    onChange={handleVsBTCTimeHorizon}
                                    autoWidth
                                    label="horizon"
                                    SelectDisplayProps={{ style: { paddingTop: 4, paddingBottom: 4 } }}
                                >
                                    <MenuItem value={1}>1 Day</MenuItem>
                                    <MenuItem value={2}>2 Days</MenuItem>
                                    <MenuItem value={5}>5 Days</MenuItem>
                                    <MenuItem value={7}>7 Days</MenuItem>
                                    <MenuItem value={10}>10 Days</MenuItem>
                                    <MenuItem value={14}>14 Days</MenuItem>
                                    <MenuItem value={30}>1 Month</MenuItem>
                                </Select>
                            </FormControl>*/}                         
                        </Stack>
                    </Box>                             
                    <Slider 
                        getAriaLabel={() => "vs BTC"} 
                        value={[parseInt(vsBTCMin),parseInt(vsBTCMax)]} 
                        min={startingValues.vs_btc_min}
                        max={startingValues.vs_btc_max}
                        onChange={handleVsBTCRatioChange}
                        //onChangeCommitted={handleVsBTCRatioCommit}
                        defaultValue={[parseInt(vsBTCMin),parseInt(vsBTCMax)]}
                        sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}
                    />
                </Stack>     
                <Stack direction="column" sx={{width: '100%', marginTop: '5px'}}> 
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">    
                        <Typography sx={{color: theme.palette.primary.main, fontWeight: 700}}>vs ETH</Typography>
                        <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('vsETH')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                    </Stack>                      
                    <Box sx={{padding: '5px', border: 2, borderColor: theme.palette.grey[400], backgroundColor: theme.palette.background.default , width: '100%', borderRadius: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                            <Typography display="inline">{vsETHMin}%</Typography>
                            <Typography display="inline">to</Typography>
                            <Typography display="inline">{vsETHMax}%</Typography>
                            {/* only have 1 day data atm...leave until the
                                <FormControl sx={{ m: 0, minWidth: 80 }} size="small">
                                <InputLabel id="select-vsbtctimehorizon-label">horizon</InputLabel>
                                <Select
                                    labelId="select-vsbtctimehorizon-label"
                                    id="select-vsbtctimehorizon"
                                    value={vsETHTimeHorizon}
                                    onChange={handleVsETHTimeHorizon}
                                    autoWidth
                                    label="horizon"
                                    SelectDisplayProps={{ style: { paddingTop: 4, paddingBottom: 4 } }}
                                >
                                    <MenuItem value={1}>1 Day</MenuItem>
                                    <MenuItem value={2}>2 Days</MenuItem>
                                    <MenuItem value={5}>5 Days</MenuItem>
                                    <MenuItem value={7}>7 Days</MenuItem>
                                    <MenuItem value={10}>10 Days</MenuItem>
                                    <MenuItem value={14}>14 Days</MenuItem>
                                    <MenuItem value={30}>1 Month</MenuItem>
                                </Select>
                            </FormControl>*/}                       
                        </Stack>
                    </Box>                             
                    <Slider 
                        getAriaLabel={() => "vs ETH"} 
                        value={[parseInt(vsETHMin),parseInt(vsETHMax)]} 
                        min={startingValues.vs_eth_min}
                        max={startingValues.vs_eth_max}
                        onChange={handleVsETHRatioChange}
                        //onChangeCommitted={handleVsETHRatioCommit}
                        defaultValue={[parseInt(vsETHMin),parseInt(vsETHMax)]}
                        sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}
                    />
                </Stack>                    
                <Stack direction="column" sx={{width: '100%', marginTop: '5px'}}> 
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">    
                        <Typography sx={{color: theme.palette.primary.main, fontWeight: 700}}>Tweet Post Date Range</Typography>
                        <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('dateRange')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                    </Stack>                      
                    <Box sx={{padding: '5px', border: 2, borderColor: theme.palette.grey[400], backgroundColor: theme.palette.background.default , width: '100%', borderRadius: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                            <Typography display="inline">{dateRangeMarks1[postCreatedLTE].label}</Typography>
                            <Typography display="inline">to</Typography>
                            <Typography display="inline">{dateRangeMarks1[postCreatedGTE].label}</Typography>
                     
                        </Stack>
                    </Box>                             
                    <Slider 
                        marks={dateRangeMarks}
                        //getAriaLabel={} 
                        value={[parseInt(postCreatedLTE),parseInt(postCreatedGTE)]} 
                        min={startingValues.timestamp__lte}
                        max={startingValues.timestamp__gte}
                        onChange={handleDateRangeChange}
                        defaultValue={[parseInt(startingValues.timestamp__lte),parseInt(startingValues.timestamp__gte)]}
                        sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}

                        valueLabelFormat={dateLabelFormat}
                        getAriaValueText={dateValueText}
                        step={1}
                        //valueLabelDisplay="off"

                    />
                </Stack>              
                <Stack direction="column" sx={{width: '100%', marginTop: '5px'}}> 
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">    
                        <Typography sx={{color: theme.palette.primary.main, fontWeight: 700}}>Tweet Body Date Range</Typography>
                        <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('dateBodyRange')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                    </Stack>
                    <Box sx={{width: '100%'}}>
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                            >
                                <DatePicker
                                    value={postBodyGTE}
                                    onChange={(newValue) => { handleDateRangeGTEChange(newValue) }}
                                    renderInput={(params) =>  {
                                        return ( 
                                            <TextField 
                                                size="small"
                                                sx={{
                                                    '.MuiOutlinedInput-root': {
                                                        background: theme.palette.background.default,
                                                        color: theme.palette.grey[400],
                                                    },                                                    
                                                    '.MuiInputBase-input': {
                                                        background: theme.palette.background.default,
                                                        color: theme.palette.grey[400],
                                                    },                   
                                                    '.MuiSvgIcon-root': {
                                                        color: theme.palette.grey[400],
                                                    },                                                                                     
                                                    maxWidth: '150px',

                                                }} 
                                                {...params} 
                                            /> )
                                    }}
                                />
                            </LocalizationProvider>                             
                            
                            <Typography display="inline" sx={{marginLeft: '10px', marginRight: '10px'}}>to</Typography>     
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                            >
                                <DatePicker
                                    value={postBodyLTE}
                                    onChange={(newValue) => { handleDateRangeLTEChange(newValue) }}
                                    renderInput={(params) =>  {
                                        return ( 
                                            <TextField 
                                                size="small"
                                                color='primary'
                                                sx={{
                                                    '.MuiOutlinedInput-root': {
                                                        background: theme.palette.background.default,
                                                        color: theme.palette.grey[400],
                                                    },
                                                    '.MuiInputBase-input': {
                                                        background: theme.palette.background.default,
                                                        color: theme.palette.grey[500],
                                                    },
                                                    '.MuiSvgIcon-root': {
                                                        color: theme.palette.grey[400],
                                                    },
                                                    maxWidth: '150px',
                                                    
                                                }} 

                                                
                                                {...params} 
                                            /> )
                                    }}
                                />
                            </LocalizationProvider>  
                        </Stack>                                              
                    </Box>

                </Stack>   
                <Stack direction="column" sx={{width: '100%', marginTop: '10px'}}>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                        <Typography sx={{color: theme.palette.primary.main, fontWeight: 700}}>Category</Typography>
                        <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('category')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                    </Stack>
                    <Box>
                        <FormControl sx={{ m: 1, width: '95%' }}>
                            <InputLabel id="category-chip-label"></InputLabel>
                            <Select
                                labelId="category-chip-label"
                                size="small"
                                sx={{
                                    width: '100%',
                                    '.MuiSelect-icon': {
                                        color: theme.palette.grey[500]
                                    },
                                    '.MuiSelect-outlined':{
                                        color: theme.palette.grey[500],
                                        background: theme.palette.background.default                                        
                                    }                                    
                                }}                                
                                id="category-multiple-chip"
                                multiple
                                value={categoryNames}
                                onChange={handleCategoryChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={CategoryMenuProps}
                                >
                                {selectableCategories.map((category) => (
                                    <MenuItem
                                        key={category.abbrev}
                                        value={category.name}
                                        style={getStyles(category.name, categoryNames, theme)}
                                    >
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {/*
                    <Box> not used currently 
                        { selectableCategories.map( (category, index ) => <ResearchSearchFormCategoryItems key={"selectablechip"+category+index} changeCategoryState={changeCategoryState} category={category} selectedCategories={selectedCategories} /> )}
                    </Box> 
                    */}
                </Stack>  
                <Button type="submit" variant={buttonType} color="primary" sx={{marginTop: '20px'}}>Search</Button>
                {tutorialOpen ? null : <Button size="small" variant="contained" sx={{marginTop: '20px'}} onClick={()=>openTutorial()}>research tutorial</Button>  }
            </Stack>
            </form> 
            <Popover
                id='saved-search-popover'
                open={saveSearchOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 400, left: 1200 }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 100,
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 100,
                }}
            >

                <Transitions position={matchesXs ? 'top' : 'top-right'} in={saveSearchOpen} >
                <Paper>
                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '500px', minHeight: '600px', padding: '15px', backgroundColor: theme.palette.dark[800]}}>
                        <Box sx={{width: '100%'}}>
                            <Stack direction="column" display="flex" alignItems="flex-start" sx={{width: '100%'}}>
                                <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
                                    <Typography variant='h2' sx={{color: theme.palette.primary.main}}>Save Your Current Search</Typography>
                                    <IconButton
                                        aria-label="close"
                                        sx={{color: theme.palette.error.main}}
                                        onClick={handleClose}>
                                        <IconSquareX sx={{ marginTop: '15px'}}/>
                                    </IconButton>  
                                </Stack>

                                <Box sx={{width: '90%'}}>
                                    <ResearchSearchDisplayCurrentSearch 
                                        queryString={editedQueryString}
                                        startingValues={startingValues}
                                        handleClose={handleClose} 
                                        postSearchTerm={postSearchTerm} 
                                        userObject={userObject}
                                        sendSearchTermToVipTrackedKeywords={sendSearchTermToVipTrackedKeywords}
                                        deleteSearchTermToVipTrackedKeywords={deleteSearchTermToVipTrackedKeywords}
                                        vipTrackedKeywords={vipTrackedKeywords}
                                    />
                                </Box>
                                
                            </Stack>
                        </Box>        

                    </MainCard>
                </Paper>
                </Transitions>    
            </Popover>
            <Modal
                aria-labelledby='spring-modal-market-cap'
                aria-describedby='spring-modal-market-cap-description'
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
                open={marketCapDetailsOpen}
                onClose={applyMarketCapDetailsChange}
                closeAfterTransition
                components={Backdrop}
                disableEscapeKeyDown={true}
                >
                <Fade in={marketCapDetailsOpen}>
                    <Box sx={{ position: 'absolute', width: 800, backgroundColor: theme.palette.dark[800],  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)', borderRadius: '8px'}}>
                    <h2 id='spring-modal-market-cap'>Manually Insert Criterial For Market Cap Search</h2>
                    <p id='spring-modal-market-cap-description' style={{ fontSize: '1rem' }}>
                        This can give you more precise controls to insert you market cap criteria for you research
                        <br /><br />
                        <Grid container spacing={2}>
                            <Grid item xs={6} style={{ paddingBottom: '25px'}}>
                                <TextField
                                    type='text'
                                    required
                                    fullWidth
                                    id='marketCapMin'
                                    label='Market Cap Minimum'
                                    value={marketCapMin.toLocaleString(undefined, {maximumFractionDigits: 2})}
                                    name='marketCapMin'
                                    onChange={handleMarketCapMinChange}
                                    helperText={marketCapMinError ? 'Your minimum must be more than 0 and not more than your maximum' : null}
                                    error={marketCapMinError}
                                    variant="outlined"
                                    focused                
                                />  
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: '25px'}}>
                                <TextField
                                    type='text'
                                    required
                                    fullWidth
                                    id='marketCapMax'
                                    label='Market Cap Maximum'
                                    value={marketCapMax.toLocaleString(undefined, {maximumFractionDigits: 2})}
                                    name='marketCapMax'
                                    onChange={handleMarketCapMaxChange}
                                    helperText={marketCapMaxError ? 'Your maximum should not be more than BitCoins market cap of ~450Bn and more than your maximum' : null}
                                    error={marketCapMaxError}
                                    variant="outlined"
                                    focused                
                                />                              
                            </Grid>
                            <Grid item xs={12}>
                                
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}>Number of results between </Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.primary.main}>{displayNumber(marketCapMin)}</Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}> and </Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.primary.main}>{displayNumber(marketCapMax)}</Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}> is: </Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem", fontWeight: 700}}color={theme.palette.primary.main}>{isMarketCapLoading ? <CircularProgress size={16} sx={{color: theme.palette.primary.main, }} /> : displayNumber(quickMarketCapCount)  }</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                                <Button startIcon={<AiOutlineBell />} variant='outlined' onClick={saveMarketCapDetails} color='primary'>
                                    Click To Apply Your Criteria
                                </Button>
                                <Button startIcon={<ImCancelCircle />} variant='outlined' onClick={cancelMarketCapDetails} color='warning' sx={{ml: 1}}>
                                    Cancel
                                </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </p>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby='spring-modal-twitter-followers'
                aria-describedby='spring-modal-twitter-followers-description'
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
                open={twitterFollowersDetailsOpen}
                onClose={applyTwitterFollowersDetailsChange}
                closeAfterTransition
                components={Backdrop}
                disableEscapeKeyDown={true}
                >
                <Fade in={twitterFollowersDetailsOpen}>
                    <Box sx={{ position: 'absolute', width: 800, backgroundColor: theme.palette.dark[800],  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)', borderRadius: '8px'}}>
                    <h2 id='spring-modal-twitter-followers'>Manually Insert Criterial For Twitter Follower Search</h2>
                    <p id='spring-modal-twitter-followers-description' style={{fontSize: '1rem'}}>
                        This can give you more precise controls to insert your twitter follower cap criteria for you research
                        <br/><br/>
                        <Grid container spacing={2}>
                            <Grid item xs={6} style={{ paddingBottom: '25px'}}>
                                <TextField
                                    type='text'
                                    required
                                    fullWidth
                                    id='followersMin'
                                    label='Market Cap Minimum'
                                    value={followersMin.toLocaleString('en-US', {maximumFractionDigits: 2})}
                                    name='followersMin'
                                    onChange={handleFollowersMinChange}
                                    helperText={followersMinError ? 'Your minimum must be more than 0 and not more than your maximum' : null}
                                    error={followersMinError}
                                    variant="outlined"
                                    focused                
                                />  
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: '25px'}}>
                                <TextField
                                    type='text'
                                    required
                                    fullWidth
                                    id='followersMax'
                                    label='Followers Maximum'
                                    value={followersMax.toLocaleString('en-US', {maximumFractionDigits: 2})}
                                    name='followersMax'
                                    onChange={handleFollowersMaxChange}
                                    helperText={followersMaxError ? 'Your maximum should not be more than 10M and no less than you minimum' : null}
                                    error={followersMaxError}
                                    variant="outlined"
                                    focused                
                                />                              
                            </Grid>
                            <Grid item xs={12}>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}>Number of results between </Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.primary.main}>{displayNumber(followersMin)}</Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}> and </Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.primary.main}>{displayNumber(followersMax)}</Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}> is: </Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem", fontWeight: 700}}color={theme.palette.primary.main}>{isFollowersLoading ? <CircularProgress size={16} sx={{color: theme.palette.primary.main, }} /> : displayNumber(quickFollowersCount)  }</Typography>                                
                            </Grid>                            
                            <Grid item xs={12}>
                                <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                                <Button startIcon={<AiOutlineBell />} variant='outlined' onClick={saveFollowerDetails} color='primary'>
                                    Click To Apply Your Criteria
                                </Button>
                                <Button startIcon={<ImCancelCircle />} variant='outlined' onClick={cancelfollowerDetails} color='warning' sx={{ml: 1}}>
                                    Cancel
                                </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </p>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby='spring-modal-social-engagement'
                aria-describedby='spring-modal-social-engagement'
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
                open={socialEngagementDetailsOpen}
                onClose={applySocialEngagementDetailsChange}
                closeAfterTransition
                components={Backdrop}
                disableEscapeKeyDown={true}
            >
                <Fade in={socialEngagementDetailsOpen}>
                    <Box sx={{ position: 'absolute', width: 800, backgroundColor: theme.palette.dark[800],  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)', borderRadius: '8px'}}>
                        <h2 id='spring-modal-soc-eng'>Manually Insert Criteria For Social Engagement Search</h2>
                        <p id='spring-modal-twitter-followers-description' style={{ fontSize: '1rem' }}>
                            This can give you more precise controls to insert your social engagement score criteria for you research
                            <br /><br />
                            <Grid container spacing={2}>
                                <Grid item xs={6} style={{ paddingBottom: '25px'}}>
                                    <TextField
                                        type='text'
                                        required
                                        fullWidth
                                        id='socialEngMin'
                                        label='Social Engagement Minimum'
                                        value={socialEngMin.toLocaleString('en-US', {maximumFractionDigits: 2})}
                                        name='socialEngMin'
                                        onChange={handleSocEngMinChange}
                                        helperText={socialEngMinError ? 'Your minimum must be more than 0 and not more than your maximum' : null}
                                        error={socialEngMinError}
                                        variant="outlined"
                                        focused
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ paddingBottom: '25px'}}>
                                    <TextField
                                        type='text'
                                        required
                                        fullWidth
                                        id='socialEngMin'
                                        label='Social Engagement Maximum'
                                        value={socialEngMax.toLocaleString('en-US', {maximumFractionDigits: 2})}
                                        name='socialEngMax'
                                        onChange={handleSocEngMaxChange}
                                        helperText={socialEngMaxError ? 'Your maximum should not be more than 10M and no less than you minimum' : null}
                                        error={socialEngMaxError}
                                        variant="outlined"
                                        focused
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}>Number of results between </Typography>
                                    <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.primary.main}>{displayNumber(socialEngMin)}</Typography>
                                    <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}> and </Typography>
                                    <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.primary.main}>{displayNumber(socialEngMax)}</Typography>
                                    <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}> is: </Typography>
                                    <Typography display="inline" sx={{fontSize: "1.2rem", fontWeight: 700}}color={theme.palette.primary.main}>{isSocEngLoading ? <CircularProgress size={16} sx={{color: theme.palette.primary.main, }} /> : displayNumber(quickSocEngCount)  }</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                                        <Button startIcon={<AiOutlineBell />} variant='outlined' onClick={saveSocEngDetails} color='primary'>
                                            Click To Apply Your Criteria
                                        </Button>
                                        <Button startIcon={<ImCancelCircle />} variant='outlined' onClick={cancelSocEngDetails} color='warning' sx={{ml: 1}}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </p>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    )
}

export default memo(ResearchSearchForm)