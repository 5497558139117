import React, {useState, useEffect} from 'react'
import { Box, Grid, Stack, Divider, Typography, Pagination, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import fetchData from '../../utility/endpointFetch'

import VIPMainTabsNotificationPost from './VIPMainTabsNotificationPost'

const VIPMainTabsFeedNotifications = ( {notificationType, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, coinChart='', changeChartAnnotations='', showOfficialTweets, localVIPCoins=[]} ) => {
    
    //console.log("top of VIPMainTabsFeedNotifications and localVIPCoins: " + localVIPCoins)
    const theme = useTheme()

    const [isLoading,setIsLoading] = useState(true)
    const [notificationPage,setNotificationPage] = useState(1)
    const [notificationPageCount,setNotificationPageCount] = useState(1)
    const [notificationCount,setNotificationCount] = useState(0)
    const [notificationListings,setNotificationListings] = useState([])

    //difference between coin and post attached notifications is "has_post" if true
    var includesPostURL = '' //default coin only notification
    if (notificationType === "post") { 
        includesPostURL = '&has_tweet=true'
    } 

    var notificationTypeTitle = notificationType[0].toUpperCase() + notificationType.slice(1)

    var coinSpecific = ''
    var boxHeight = '920px'
    if (coinChart !== '') {
        coinSpecific = '&coin=' + coinChart
        boxHeight = '550px'
    }

    useEffect(() => {
        setIsLoading(true)
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            //var notificationURL = `api/v1/vip/coinalert/?has_post=${includesPost}&has_coin_notification=true&page=${notificationPage}${coinSpecific}`
            var notificationURL = `api/v1/vip/coinalert/?page=${notificationPage}${coinSpecific}${includesPostURL}`
            //if ( localVIPCoins.length === 0) {
            //    notificationURL = `api/v1/vip/vipsignal/?all&has_post=${includesPost}&has_coin_notification=true&page=${notificationPage}${coinSpecific}`
            //}

            const notificationResponse = await fetchData(notificationURL, "could not receive notification vip data", fetchParams).promise
            //console.log("notificationResponse getting vip notification data from api")
            //console.log(notificationURL)
            //console.dir(notificationResponse)
    
            setNotificationCount(notificationResponse.count)
    
            if (notificationResponse.count > 100) {
                setNotificationPageCount(Math.ceil(notificationResponse.count/100))
            } else {
                setNotificationPageCount(1)
            }
    
            setNotificationListings(notificationResponse.results)
            if (coinChart !== '') changeChartAnnotations(notificationResponse.results)
    
            setIsLoading(false)
          } catch (error) {
            console.log("something is broke getting vip notification data in VIPMainTabsFeedNotifications: " + error)
          }
        })()
    
    }, [notificationPage, coinChart])

    const changeNotificationPage = (event, page) => {
        setNotificationPage(page)
    }

    if (isLoading) return <CircularProgress />
    //console.log("notificationListings length is: " + notificationListings.length)
    return (
        <Box sx={{width: '100%', height: boxHeight }}>
            <Grid container sx={{minHeight: '200px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>        
                <Grid item xs={12}>
                    <Divider sx={{width:'100%', marginTop: '20px', borderColor: theme.palette.secondary.main}} textAlign="left" >
                    <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.primary.main, paddingLeft: '20px', marginTop: '20px'}}>{notificationCount}</Typography>
                    { coinChart === '' ?
                        <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.grey[300], marginTop: '20px'}}> Full VIP {notificationTypeTitle} Notification Results</Typography>
                        :
                        <>
                            <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.grey[300], marginTop: '20px'}}> VIP {notificationTypeTitle} Notification Results For Coin:</Typography>
                            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.primary.main, marginTop: '20px'}}> {coinChart}</Typography>
                        </>
                    }
                    </Divider>
                </Grid>            
                {notificationListings.map( (notification, index) => {
                    return <VIPMainTabsNotificationPost
                                key={"notification_listing_"+index}
                                notificationItem={notification}
                                setCoinInChart={setCoinInChart}
                                changeAtFollowedTab={changeAtFollowedTab}
                                changeAtMentionedTab={changeAtMentionedTab}
                                changeHashTab={changeHashTab}
                                changeCoinInTab={changeCoinInTab}
                                changeDateInChart={changeDateInChart}
                                showOfficialTweets={showOfficialTweets}
                                notificationType={notificationType}
                            />
                })}
                { notificationListings.length > 0 ?
                    <Grid item xs={12}>
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-end">
                            <Pagination shape="rounded" count={notificationPageCount} page={notificationPage} onChange={changeNotificationPage}/>
                        </Stack>
                    </Grid> : null }      
            </Grid>                                 
        </Box>
    )
}

export default VIPMainTabsFeedNotifications