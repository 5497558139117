import React from 'react'
import { Typography, Stack, Avatar, Box, Tooltip, IconButton } from '@mui/material'

import { IconArrowUpRightCircle } from '@tabler/icons'
import { HelpOutline } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'
const QuickMVRV = ( { ruleThemAll, changeTab } ) => {

  const theme = useTheme()

  //console.log("top of QuickMVRV and ruleThemAll is:")
  //console.dir(ruleThemAll)

  //var useThisMVRVData = ruleThemAll[ruleThemAll.length-1] //new endpoint provides data latest first...this will not work
  var useThisMVRVData = ruleThemAll[0]

  if (ruleThemAll.length === 0) {
    return (
      <Box sx={{width: '100%'}} >
        <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[500]}}>MVRV</Typography>
        <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
      </Box>
    )
  } else {  

    //console.log("useThisMVRVData is:")
    //console.dir(useThisMVRVData)

    var mvrvRateColor = theme.palette.grey[500]
    var mvrvDesc = 'NEUTRAL'
    if (useThisMVRVData.healthData.mvrv < 1) {
      mvrvRateColor = theme.palette.success.dark
      mvrvDesc = 'POSSIBLE BOTTOM'
    } else if (useThisMVRVData.healthData.mvrv > 3.7) {
      mvrvRateColor = theme.palette.error.dark
      mvrvDesc = 'POSSIBLE TOP'
    }



    return (
      <Stack
        direction="column"
        sx={{backgroundColor: theme.palette.dark.dark, margin: '10px', padding: '5px', paddingTop: '0px', borderRadius: '5px', minHeight: '7vh', cursor: 'pointer'}}
        onClick={() => changeTab('9')}
      >
        <Stack
          direction="row"
          display="flex"
          justifyContent='space-between'
          alignItems="center"
        >
          <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>MVRV</Typography>
          <Stack direction="row" display="flex" alignItems="center">
            <Tooltip title="MVRV (Market Value to Realized Value) ratio is defined as an asset's market capitalization divided by realized capitalization."  >
              <IconButton size="small" aria-label="full chart">< HelpOutline sx={{fontSize: '1.1rem', color: theme.palette.grey[500]}} /></IconButton>
            </Tooltip>          
            <Tooltip title='click for full MVRV chart'  ><IconButton size="small" aria-label="full chart">< IconArrowUpRightCircle size={18} color={theme.palette.grey[500]} /></IconButton></Tooltip>
          </Stack>
        </Stack>
        <Box sx={{width: '99%', backgroundColor: theme.palette.dark.dark, paddingTop: '8px', paddingBottom: '5px'}} >
          <Stack direction="column" alignItems="center">
            <Avatar sx={{width: 50, height: 50, bgcolor: mvrvRateColor, display: 'flex', justifyContent: 'center', fontSize: '.9rem', color: theme.palette.grey[100] }}>{useThisMVRVData.healthData.mvrv.toFixed(2)}</Avatar>
            <Typography variant="Button" sx={{marginLeft: '5px', fontWeight: 700, color: mvrvRateColor}}>{mvrvDesc}</Typography>
          </Stack>
        </Box>
      </Stack>
    )
  }
}

export default QuickMVRV