import React, { useState, useEffect} from 'react'
import { useTheme } from '@mui/material/styles'
import { Chip } from '@mui/material'

const ResearchSearchFormCategoryItems = ({category, changeCategoryState, selectedCategories}) => {

    //console.log("top of ResearchSearchFormCategoryItems")
    //console.dir(selectedCategories)
    //console.log("category is: ")
    //console.dir(category)

    const theme = useTheme()

    var selected = false
    var outlined = "outlined"
    if (selectedCategories.includes(category.name))  {
        selected = true
        outlined = 'filled'
    }

    return (
        <Chip 
            size="small"
            sx={{ borderRadius: '12px', mr: '5px', mb: '3px'}}
            label={category.abbrev} 
            color="primary" 
            variant={outlined} 
            onClick={() => changeCategoryState(category.name)}
        />
    )
}

export default ResearchSearchFormCategoryItems