import React, { useState, useEffect, useRef } from 'react'
import { Stack, Grid, Pagination, Divider, Typography, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { v4 as uuidv4 } from "uuid";

import fetchData from '../../utility/endpointFetch'

import VIPTwitterPost from './VIPTwitterPost'
import VIPTelegramPost from './VIPTelegramPost'
import VIPDiscordPost from './VIPDiscordPost'
import VIPRedditPost from './VIPRedditPost'

const VIPAllCoinsPosts = ( { coinChart, changeAtFollowedTab, setCoinInChart, changeAtMentionedTab, changeHashTab, changeDateInChart, changeCoinInTab, changeChartAnnotations, allCoinsScrollTo, showOfficialTweets, localVIPCoins } ) => {

    //console.log("top of VIPAllCoinsPosts and coinChart is: ")
    //console.dir(coinChart)
    //console.log("localVIPCoins is: ")
    //console.dir(localVIPCoins)
    //console.dir(allCoinsScrollTo)
    const theme = useTheme()

    const [vipCoinListings,setVIPCoinListings] = useState([])
    const [responseCount,setResponseCount] = useState(0)
    const [vipCoinPageCount,setVIPCoinPageCount] = useState(1)
    const [vipCoinPage,setVIPCoinPage] = useState(1)
    const [isLoading,setIsLoading] = useState(true)

    const mounted = useRef(false);

    const getVIPNotifications = () => {
        //console.log("top of getVIPNotifications and coinChart is:")
        //console.dir(coinChart)
        
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },
        }
        ;(async () => {
            try {
                if (coinChart !== '') {
                    if(mounted.current)setIsLoading(true)
                    let coinChartURL = `&coins=${coinChart}`

                    //var vipAllCoinsPostsURL = `api/v1/vip/vipsignal/?has_post=true&page=${vipCoinPage}${coinChartURL}`
                    let vipAllCoinsPostsURL = `api/v1/feed/alltweets/?vip=true&page=${vipCoinPage}${coinChartURL}`
                    
                    
                    // do not think this is necessary anymore but keeping just in case
                    //if (localVIPCoins.length === 0) {
                        //state where all coins is selected
                    //    vipAllCoinsPostsURL = `api/v1/vip/vipsignal/?all&has_post=true&page=${vipCoinPage}${coinChartURL}`
                    //} 

                    const vipAllCoinsPostsResponse = await fetchData(vipAllCoinsPostsURL, "could not receive vip data", fetchParams).promise
                    //console.log("vipAllCoinsPostsResponse getting vip data from api")
                    //console.dir(vipAllCoinsPostsResponse)
                    //console.log(vipAllCoinsPostsURL)
            
                    if(mounted.current){
                        setResponseCount(vipAllCoinsPostsResponse.count)
                    }
                    //setResponseCount(vipAllCoinsPostTestData.count)
            
                    
                    if (vipAllCoinsPostsResponse.count > 100) {
                        setVIPCoinPageCount(Math.ceil(vipAllCoinsPostsResponse.count/100))
                    } else {
                        setVIPCoinPageCount(1)
                    }
                    if(mounted.current){
                        setVIPCoinListings(vipAllCoinsPostsResponse.results)
                    }
                    //setVIPCoinListings(vipAllCoinsPostTestData.results)
                    //console.log("about to changeChartAnnotations to ")

                    let onlyOfficialAnnotations = vipAllCoinsPostsResponse.results.filter( coin => coin.twitter_user_is_influencer === false )
                    //var onlyOfficialAnnotations = vipAllCoinsPostTestData.results.filter( twitterItem => twitterItem.twitter_user_is_influencer === false )
                    //console.log("----------------onlyOfficialAnnotations is:")
                    //console.dir(onlyOfficialAnnotations)

                    changeChartAnnotations(onlyOfficialAnnotations)
        
                    if(mounted.current){
                        setIsLoading(false)
                    }
                }
            } catch (error) {
                console.log("something is broke getting vip in VIPAllCoinsPosts: " + error)
            }
        })()        
    }

    useEffect(() => {
        mounted.current = true;
        getVIPNotifications();
        return () => mounted.current = false;
    }, [vipCoinPage, coinChart])

    const handlePageChange = (event, coinPage) => {
        if(mounted.current){
            setVIPCoinPage(coinPage)
        }
    }

    if (isLoading) return <CircularProgress />
    return (
        <Grid id="VIPAllCoinsPostsContainer" container sx={{minHeight: '200px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
            <Grid item xs={12}>
                <Divider sx={{width:'100%', marginTop: '20px'}} textAlign="left" >
                    <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.secondary.main, paddingLeft: '20px', marginTop: '20px'}}>{responseCount}</Typography>
                    <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.secondary.main, marginTop: '20px'}}> Full VIP Results For Coin: </Typography>
                    <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.primary.main, marginTop: '20px'}}> {coinChart}</Typography>
                </Divider>
            </Grid>
            {vipCoinListings.map( (listing, index) => {
                if (listing !== null) {
                    if (listing.hasOwnProperty('tweet_id'))
                        return <VIPTwitterPost scrollTo={"scrollToName"+listing.tweet_id} key={uuidv4()} listing={listing} showOfficialTweets={showOfficialTweets} setCoinInChart={setCoinInChart} changeAtFollowedTab={changeAtFollowedTab} changeAtMentionedTab={changeAtMentionedTab} changeHashTab={changeHashTab} changeCoinInTab={changeCoinInTab} changeDateInChart={changeDateInChart} />
                    else if (listing.hasOwnProperty('telegram_group'))
                        return <VIPTelegramPost key={uuidv4()} telegramItem={listing} />
                    else if (listing.hasOwnProperty('discord_server'))
                        return <VIPDiscordPost key={uuidv4()} discordItem={listing} />
                    else if (listing.hasOwnProperty('subreddit'))
                        return <VIPRedditPost key={uuidv4()} redditItem={listing} />
                    else return null //not sure if these are the only 5 types needed
                }else return null
            })}
            <Grid item xs={12}>
                <Divider sx={{marginBottom: '15px'}}/>
                <Stack direction="row" display="flex" alignItems="center">
                <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
                <Pagination shape="rounded" count={vipCoinPageCount} page={vipCoinPage} onChange={handlePageChange}/>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default VIPAllCoinsPosts