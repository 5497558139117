import React from 'react'
import { Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const ResearchTutorial1Welcome = () => {

    const theme = useTheme()

    return (
        <Stack direction="column" alignItems="center" >
            <Typography variant="h2" sx={{paddingTop: '20px', color: theme.palette.warning.dark}}>Welcome To Research Mode</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>Here you can find hidden gems among the thousands of crypto projects we follow.</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>Social Media generates a ton of noise, finding projects with your unique criteria can be difficult. 
            There are MANY crypto projects which are constantly adding features, scheduling events, and posting surprise announcements.</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>So follow along through this tutorial to learn a bit about the tools at your fingertips</Typography>
        </Stack>
    )
}

export default ResearchTutorial1Welcome