import React, { useState, useCallback } from 'react'
import { Box, Typography, Stack, Divider } from '@mui/material'
import ResearchDisplaySearch from './ResearchDisplaySearch'
import ResearchBodyResults from '../Research3/ResearchBodyResults'
import ResearchDisplaySavedSearchSelection from './ResearchDisplaySavedSearchSelection'

import { useTheme } from '@mui/material/styles'
import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'

const ResearchDisplay = ( {panelID, deleteBox, coinToDependents, boxHeight, rowHeight, boxWidth} ) => {

    const theme = useTheme()

    const [coinTweetQueryString,setCoinTweetQueryString] = useState('all')
    const [coinTweetQueryStringLabel,setCoinTweetQueryStringLabel] = useState('all')
    //const [searchResultCount,setSearchResultCount] = useState(0)

    const removeResearchBox = () => {
        //console.log("top of removeListBox and panelID is: " + panelID)
        deleteBox(panelID)
    }

    const showCoinTweetsAndChart = useCallback ((coin) => {
        //console.log("top of showCoinTweetsAndChart and coin is:")
        //console.dir(coin)
        coinToDependents(coin)
    }, [coinToDependents])

    const displaySavedSearch = (tweetQueryString) => {
        //console.log("TOP OF DISPLAYSAVEDSEARCH AND tweetQueryString is:")
        //console.dir(tweetQueryString)

        setCoinTweetQueryStringLabel(tweetQueryString[0]) 
        setCoinTweetQueryString(tweetQueryString[1])
    }

    return (
        <Box id={panelID} sx={{ display: 'flex', width: 'calc(100% - 10px)', marginLeft: '5px' }}>
            <Stack direction="column" display="flex" alignItems='left' sx={{width: '100%'}}>
                <Stack direction="row" display="flex" alignItems='center' justifyContent='space-between' sx={{marginTop: '5px', marginRight: '5px'}}>
                    <Stack direction="row" display="flex" alignItems='center' justifyContent='flex-start' >
                        <Typography display="inline" sx={{fontWeight: 400}} color={theme.palette.grey[300]}>{"searching: "}</Typography>
                        <Typography display="inline" sx={{fontWeight: 600, marginLeft: '5px'}} color={theme.palette.primary.main}>{coinTweetQueryStringLabel}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent='flex-end'>  
                        <ResearchDisplaySearch 
                            displaySavedSearch={displaySavedSearch} 
                        />
                        <ResearchDisplaySavedSearchSelection 
                            displaySavedSearch={displaySavedSearch} 
                        />
                        <VscMove 
                            className='draggable-handle' 
                            color={theme.palette.warning.dark} 
                            size={18} 
                            style={{cursor: 'grabbing'}}
                        />
                        <MdCancel 
                            className='draggable-cancel' 
                            color={theme.palette.secondary.dark} 
                            size={18} 
                            onClick={removeResearchBox}
                            style={{cursor: 'pointer'}}
                        />

                    </Stack>
                </Stack>   
                <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>     
                <ResearchBodyResults
                    showCoinTweetsAndChart={showCoinTweetsAndChart}
                    coinTweetQueryString={coinTweetQueryString}
                    stopInitial={1}
                    divHeight={boxHeight * rowHeight}
                    boxWidth={boxWidth}
                />

            </Stack>
        </Box>                 
    )

}

export default ResearchDisplay