import React, { useState, useEffect } from 'react'
import { Box, CircularProgress, Grid, ButtonGroup, Button, Stack, Chip, Typography, Modal, Fade, Backdrop, IconButton, Divider } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { Help, Close } from '@mui/icons-material'
import Chart from "react-apexcharts"
import NUPL from './NUPL'

const HealthChartNUPL = ( {chartType, ruleThemAll} ) => {
  const theme = useTheme();
  //console.log("top of HealthChartNUPL")
  //console.log("chartType is: ")
  //console.dir(chartType)
  //console.log(chartType.indicator_index)
  //console.log("btcPriceData is: ")
  //console.dir(btcPriceData)
  //console.log("healthData is: ")
  //console.dir(healthData)

  const [ isLoading, setIsLoading ] = useState(true)
  const [ upperChartOptions, setUpperChartOptions ] = useState({})
  const [ upperChartSeries, setUpperChartSeries ] = useState([])
  const [ volumeChartSeries, setVolumeChartSeries] = useState([])
  const [ volumeChartOptions, setVolumeChartOptions] = useState({})
  const [ marketCapChartSeries, setMarketCapChartSeries] = useState([])
  const [ marketCapChartOptions, setMarketCapChartOptions] = useState({})
  const [ modalOpen, setModalOpen] = useState(false)

  const [ chartDays, setChartDays ] = useState(180)
  const [ chartInterval, setChartInterval ] = useState('daily')
  const [ chartBaseCurrencySymbol, setChartBaseCurrencySymbol ] = useState('$ ')
  const [ chartBaseCurrency, setChartBaseCurrency ] = useState('USD')
  const [ chartRangeDetails, setChartRangeDetails ] = useState('6 months/daily intervals')

  function EMACalc(mArray,mRange) {
    var k = 2/(mRange + 1);
    // first item is just the same as the first item in the input
    var emaArray = [mArray[0]];
    // for the rest of the items, they are computed with the previous one
    for (var i = 1; i < mArray.length; i++) {
      emaArray.push(mArray[i] * k + emaArray[i - 1] * (1 - k));
    }
    return emaArray;
  }

  function SMACalc(mArray,mRange) {
    var k = 2/(mRange + 1);
    // first item is just the same as the first item in the input
    var emaArray = [mArray[0]];
    // for the rest of the items, they are computed with the previous one
    for (var i = 1; i < mArray.length; i++) {
      emaArray.push(mArray[i] * k + emaArray[i - 1] * (1 - k));
    }
    return emaArray;
  }

  const handleDescriptionOpen = () => {
    setModalOpen(true)
  }

  const handleDescriptionClose = () => {
    setModalOpen(false)
  }


  const handleChartTimeChange = ( days) => {
    setChartDays(days)
    //only getting data daily so can only display daily intervals
    setChartInterval('daily')

    switch (days) {
      case 365:
        setChartRangeDetails('1y/daily intervals')
        break;
      case 180:
        setChartRangeDetails('180 days/daily intervals')
        break;
      case 90:
        setChartRangeDetails('90 days/daily intervals')
        break;
      case 30:
        setChartRangeDetails('30 days/daily intervals')
        break;
      case 14:
        setChartRangeDetails('2 weeks/daily intervals')
        break;
      case 7:
        setChartRangeDetails('1 week/daily intervals')
        break;
      default:
        //not sure what this is
        setChartRangeDetails('')
    }
  }

  const buildChart = ( subRuleThemAll ) => {

    //console.log("top of buildChart")
    //console.dir(subRuleThemAll)

    //find min max prices
    var minMaxPrices = subRuleThemAll.map( (data) => {
      //console.log("data [" + data.healthData['id'] + "] is ")
      if (data.healthData !== undefined)
        return data.healthData.price
      else
        return data.price
    })

    var chartPriceMax = minMaxPrices.reduce((a, b) => { return Math.max(a, b) });
    var chartPriceMin = minMaxPrices.reduce((a, b) => { return Math.min(a, b) });
    //console.log("chartPriceMax is now: "+ chartPriceMax + " and chartPriceMin is now: " +chartPriceMin)

    var firstValue = subRuleThemAll[0].price
    if (subRuleThemAll[0].healthData)
      firstValue = subRuleThemAll[0].healthData.price

    var lastValue = subRuleThemAll[subRuleThemAll.length - 1].price
    if (subRuleThemAll[subRuleThemAll.length - 1].healthData)
      lastValue = subRuleThemAll[subRuleThemAll.length - 1].healthData.price

    var chartColor = '#109e10'
    var legendPosition = 'left'
    if (firstValue > lastValue) {
      //price went down over chart
      legendPosition = 'right'
    }

    var chartPricesWithDateArrTimestamp = subRuleThemAll.map( (data, index) => {
      try {
        if (data.healthData)
          //return [ data.timestamp, data.price, data.total_volume, data.market_cap, data.healthData[chartType.indicator_index] ]
          return [ data.timestamp, data.healthData.price ]
        else
          //return [ data.timestamp, data.price, data.total_volume, data.market_cap, null ]
          return [ data.timestamp, data.price]
      } catch (error) {
        //console.log("index is: " + index)
        //console.log("error is : " + error)
      }
    })

    //console.log("chartPricesWithDateArrTimestamp is: ")
    //console.dir(chartPricesWithDateArrTimestamp)

    var chartIndicatorWithDateArrTimestamp = subRuleThemAll.map( (data ) => {
      if (data.healthData)
        return [ data.timestamp , data.healthData[chartType.indicator_index] ]
      else
        return [ data.timestamp , null ]
    })

    //console.log("chartIndicatorWithDateArrTimestamp is: ")
    //console.dir(chartIndicatorWithDateArrTimestamp)

    //find min max dopple
    var minMaxIndicatorData = chartIndicatorWithDateArrTimestamp.map( (data, index) => {
      if (data[1]) {
        return data[1]
      } else {
        console.log("not returning data: " + data[1])
      }
    })
    //need to remove the bad egg
    let minMaxIndex
    while((minMaxIndex = minMaxIndicatorData.indexOf(undefined)) > -1)
    {
      minMaxIndicatorData.splice(minMaxIndex, 1)
    }
    var chartIndicatorMax = minMaxIndicatorData.reduce((a, b) => { return Math.max(a, b) });
    var chartIndicatorMin = minMaxIndicatorData.reduce((a, b) => { return Math.min(a, b) });
    //console.log("chartIndicatorMax is now: "+ chartIndicatorMax + " and chartIndicatorMin is now: " +chartIndicatorMin)

    var volumeDataArrWithTimeStamp = subRuleThemAll.map( data => { return [ data.timestamp , data.total_volume ] })
    //console.log("volumeDataArrWithTimeStamp is: ")
    //console.dir(volumeDataArrWithTimeStamp)

    var marketDataArrWithTimestamp = subRuleThemAll.map( (data ) => { return [ data.timestamp, data.market_cap ] })
    //console.log("marketDataArrWithTimestamp is: ")
    //console.dir(marketDataArrWithTimestamp)

    var datePrices = subRuleThemAll.map( (data) => {
      var date = new Date(data.timestamp)
      var year = date.getFullYear()
      var month = date.getMonth()
      var day = date.getDate()

      return day+'-'+month+'-'+year
    })

    //find min max market cap for chart
    var minMaxMarketCap = subRuleThemAll.map( (data) => { return data.market_cap })

    var chartMaxMarketCap = minMaxMarketCap.reduce((a, b) => { return Math.max(a, b) });
    var chartMinMarketCap = minMaxMarketCap.reduce((a, b) => { return Math.min(a, b) });


    //var chartMaxMarketCapUsed = (3 * chartMaxMarketCap)

    //find min max volume for chart
    var minMaxVolumes = subRuleThemAll.map( (data) => { return data.total_volume })

    var chartMaxVolume = minMaxVolumes.reduce((a, b) => { return Math.max(a, b) });
    var chartMinVolume = minMaxVolumes.reduce((a, b) => { return Math.min(a, b) });

    //console.log("chartPricesWithDateArrTimestamp before charting is: ")
    //console.dir(chartPricesWithDateArrTimestamp)

    //console.log("chartIndicatorWithDateArrTimestamp before charting is:")
    //console.dir(chartIndicatorWithDateArrTimestamp)

    setUpperChartSeries([
      {
        name: chartType.header,
        type: chartType.chart_type,
        data: chartIndicatorWithDateArrTimestamp
      },
      {
        name: "BTC Price",
        type: 'line',
        data: chartPricesWithDateArrTimestamp
      },

    ])

    setUpperChartOptions({
      chart: {
        id: "chart1",
        toolbar: {
          show: false,
        },
        stacked: false,
        background: '#fff',
        height: 400,
        background: theme.palette.background.default,
      },
      colors: ['#0d6cba', '#bf3932', '#eba834'],
      stroke: {
        show: true,
        width: [chartType.chart_type === 'line'? 2 : 0,  2 ]
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: [0.85, 0.85],
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        }
      },
      yaxis: [
        {
          seriesName: chartType.header,
          opposite: true,
          show: true,
          min: chartIndicatorMin,
          max: chartIndicatorMax,
          forceNiceScale: false,
          labels: {
            show: true,
            formatter: (value) => {
              return value.toFixed(2)
            },
            style: {
              colors: [ '#0d6cba']
            }            
          }
        },
        {
          seriesName: "BTC Price",
          opposite: false,
          show: true,
          min: chartPriceMin,
          max: chartPriceMax,
          forceNiceScale: true,
          labels: {
            show: true,
            formatter: (value) => {
              return parseInt(value)
            },
            style: {
              colors: [ '#bf3932']
            }
          },
          crosshairs: {
            show: true,
            position: 'front'
          }
        },

      ],
      tooltip: {
        custom: function( {series, seriesIndex, dataPointIndex, w}) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
          //console.log("data in custom tooltip is")
          //console.dir(data)
          //console.dir(series)
          //console.dir(seriesIndex)
          //console.dir(dataPointIndex)
          //console.dir(chartPricesWithDateArrTimestamp[dataPointIndex][0])
          //console.dir(chartPricesWithDateArrTimestamp[dataPointIndex][1])
          //console.dir(chartIndicatorWithDateArrTimestamp[dataPointIndex][1])
          //console.dir(volumeDataArrWithTimeStamp[dataPointIndex][1])
          //console.dir(marketDataArrWithTimestamp[dataPointIndex][1])
          let [month, date, year] = new Date(subRuleThemAll[dataPointIndex]['timestamp']).toLocaleDateString("en-US").split("/")
          return ('<ul style="margin-right: 10px">' +
          '<li style="color:#000;"><b>Date</b>: ' + year + '/'+ month + '/'+ date + '</li>' +
          '<li style="color:#bf3932;"><b>BTC Price</b>: ' + chartBaseCurrencySymbol + " " + subRuleThemAll[dataPointIndex]['price'] + " " + chartBaseCurrency + '</li>' +
          '<li style="color:#0d6cba;"><b>'+ chartType.header +'</b>: ' + subRuleThemAll[dataPointIndex]['healthData'][chartType.indicator_index] + '</li>' +
          '<li style="color:#fc9d03;"><b>Volume</b>: ' + Math.round(subRuleThemAll[dataPointIndex]['total_volume']) + '</li>' +
          '<li style="color:#0484bf;"><b>Market Cap</b>: ' + chartBaseCurrencySymbol + " " + (subRuleThemAll[dataPointIndex]['market_cap']).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " " + chartBaseCurrency + '</li>')

        }


        /*
        x: {
          show: false,
          format: 'dd MMM yyyy',
        },
        y: {
          formatter: function (value, index) {
            if (value === index.series[0][index.dataPointIndex]) {
              //this is a price
              return chartBaseCurrencySymbol + " " + (value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " " + chartBaseCurrency
            } else if (value === index.series[1][index.dataPointIndex]) {
              //this is volume...just remove after .
              return Math.round(value)
            } else if (value === index.series[2][index.dataPointIndex]) {
              return chartBaseCurrencySymbol + " " + (value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " " + chartBaseCurrency
            } else {
              // not sure what it is...return unchanged
              return value
            }
          }
        },
        */
      },
      legend: {
        position: legendPosition,
        floating: true,
        offsetX: 20,
        offsetY: 20,
        labels: {
          colors: theme.palette.grey[300]
        }

      },
      annotations: {
        yaxis: [
          {
            y: 0,
            y2: -.25,
            borderColor: '#000',
            fillColor: '#6bb5fa',
            opacity: 0.2,
            label: {
              text: 'Capitulation',
              offsetY: 30,
              offsetX: 70,
              position: 'left',
              style: {
                background: '#6bb5fa',
                color: '#000',
              }
            }
          },
          {
            y: 0,
            y2: .25,
            borderColor: '#000',
            fillColor: '#6bfab0',
            opacity: 0.2,
            label: {
              text: 'Hope/Fear',
              offsetY: 30,
              offsetX: 70,
              position: 'left',
              style: {
                background: '#6bfab0',
                color: '#000',
              }
            }
          },
          {
            y: .25,
            y2: .50,
            borderColor: '#000',
            fillColor: '#f3fa6b',
            opacity: 0.2,
            label: {
              text: 'Optimism/Anxiety',
              offsetY: 30,
              offsetX: 105,
              position: 'left',
              style: {
                background: '#f3fa6b',
                color: '#000',
              }
            }
          },
          {
            y: .50,
            y2: .75,
            borderColor: '#000',
            fillColor: '#faba6b',
            opacity: 0.2,
            label: {
              text: 'Belief/Denial',
              offsetY: 30,
              offsetX: 90,
              position: 'left',
              style: {
                background: '#faba6b',
                color: '#000',
              }
            }
          },
          {
            y: 1.00,
            y2: .75,
            borderColor: '#000',
            fillColor: '#fa776b',
            opacity: 0.2,
            label: {
              text: 'Euforia/Greed',
              offsetY: 30,
              offsetX: 90,
              position: 'left',
              style: {
                background: '#fa776b',
                color: '#000',
              }
            }
          }

        ]
      }
    })

    setVolumeChartSeries([
      {
        name: "Volume",
        type: "bar",
        data: volumeDataArrWithTimeStamp,
      },
    ])

    setVolumeChartOptions({
      chart: {
        id: 'chart2',
        type: 'line',
        height: '100',
        toolbar: {
          show: false,
        },
        height: 100,
        background: theme.palette.background.default,
      },
      colors: ['#fc9d03'],
      labels: datePrices,
      dataLabels: {
        enabled: false,
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        }
      },

      yaxis: {
        seriesName: "Volume",
        opposite: false,
        show: false,
        tooltip: {
          enabled: false,
        },
        min: chartMinVolume,
        max: chartMaxVolume
      },
      tooltip: {
        enabled: false,
        x: {
          show: false,
          format: 'dd MMM yyyy',
        },
        y: {
          formatter: function (value, index) {
            if (value === index.series[0][index.dataPointIndex]) {
              //this is volume
              return Math.round(value) + " " + chartBaseCurrency
            }  else {
              // not sure what it is...return unchanged
              return value
            }
          }
        },
      },

    })

    setMarketCapChartSeries([
      {
        name: "Market Cap",
        type: "bar",
        data: marketDataArrWithTimestamp,
      }
    ])

    setMarketCapChartOptions({
      chart: {
        id: 'chart3',
        height: 100,
        toolbar: {
          show: false,
        },
        background: theme.palette.background.default,
      },
      colors: ['#0484bf'],
      labels: datePrices,
      dataLabels: {
        enabled: false,
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: true,
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        seriesName: "Market Cap",
        opposite: false,
        show: false,
        min: chartMinMarketCap,
        max: chartMaxMarketCap,
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: false,
            format: 'dd MMM yyyy',

          },
          marker: {
            show: true,
          },


        },
      },

      tooltip: {
        enabled: false,
        x: {
          show: false,
          format: 'dd MMM yyyy',
        },
        y: {
          formatter: function (value, index) {
            if (value === index.series[0][index.dataPointIndex]) {
              //this is volume
              return Math.round(value) + " " + chartBaseCurrency
            }  else {
              // not sure what it is...return unchanged
              return value
            }
          }
        },
      },
    })
  }

  useEffect(() => {

    //get the correct data given the timeframe from btcPriceData, btcVolumeData and btcMarketCapData

    //confusing....latest is not last...if using our data...latter...if using coingecko...the former
    //var firstPoint = ruleThemAll.length - chartDays
    //var lastPoint = ruleThemAll.length - 1
    var firstPoint = 0
    var lastPoint = chartDays - 1
    var subRuleThemAll = ruleThemAll.slice(firstPoint,lastPoint)

    buildChart(subRuleThemAll)

    setIsLoading(false)

  }, [chartDays])

  if (isLoading) return <CircularProgress />

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary800,
    },
  }));

  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        <Box
          sx={{
            paddingTop: '20px',
          }}>
            <Stack direction="column">
              <Stack direction='row' alignItems='center'>
                <Typography variant="h5" sx={{marginBottom: '10px'}}>{chartType.header}</Typography>
              </Stack>
              <Stack direction="row" sx={{justifyContent: 'space-between'}}>
                <Chip label={chartRangeDetails} sx={{backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.light}} />
                <ButtonGroup size="small" aria-label="small button group" sx={{paddingRight: '10px'}}>
                  { chartDays === 365 ? <ColorButton  variant="contained">1Y</ColorButton> : <Button onClick={() => handleChartTimeChange(365)}>1Y</Button> }
                  { chartDays === 180 ? <ColorButton  variant="contained">6M</ColorButton> : <Button onClick={() => handleChartTimeChange(180)}>6M</Button> }
                  { chartDays === 90 ? <ColorButton  variant="contained">3M</ColorButton> : <Button onClick={() => handleChartTimeChange(90)}>3M</Button> }
                  { chartDays === 30 ? <ColorButton variant="contained">1M</ColorButton> : <Button onClick={() => handleChartTimeChange(30)}>1M</Button> }
                  { chartDays === 14 ? <ColorButton  variant="contained">2W</ColorButton> : <Button onClick={() => handleChartTimeChange(14)}>2W</Button> }
                  { chartDays === 7 ? <ColorButton  variant="contained">1W</ColorButton> : <Button onClick={() => handleChartTimeChange(7)}>1W</Button> }
                </ButtonGroup>
              </Stack>

              <Grid container>
                <Grid item xs={12}>
                  <div id="chart1">
                    <Chart
                      options={upperChartOptions}
                      series={upperChartSeries}
                      type="line"
                      height={400}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Box  sx={{position: 'relative', top: '-20px', left: '-5px'}}>
                    <Grid container >
                      <Grid item xs={.35}></Grid>
                      <Grid item xs={11.3} id="chart2">
                        <Chart
                          options={volumeChartOptions}
                          series={volumeChartSeries}
                          type="bar"
                          height={100}
                        />
                      </Grid>
                      <Grid item xs={.35}></Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box  sx={{position: 'relative', top: '-55px', left: '-5px'}}>
                  <Grid container >
                      <Grid item xs={.35}></Grid>
                      <Grid item xs={11.3} id="chart3">
                        <Chart
                          options={marketCapChartOptions}
                          series={marketCapChartSeries}
                          type="bar"
                          height={100}
                        />
                      </Grid>
                      <Grid item xs={.35}></Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Modal
              aria-labelledby='spring-modal-title-agreement'
              aria-describedby='spring-modal-description-agreement'
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
              open={modalOpen}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{ timeout: 500 }}
              disableEscapeKeyDown={true}
            >
              <Fade in={modalOpen}>
                <Box sx={{ position: 'absolute', width: 600, backgroundColor: '#fff',  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)'}}>
                  <Stack direction="row">
                    <Typography id='spring-modal-title'>{chartType.header}</Typography>
                    <IconButton onClick={handleDescriptionClose}>
                      <Close />
                    </IconButton>
                  </Stack>
                  <Divider />
                  <Grid container>
                    <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                      <Typography variant="subtitle2">{chartType.description}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{paddingTop: '25px'}}>
                      <Button variant='contained' onClick={handleDescriptionClose} color='primary'>
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Fade>
            </Modal>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <NUPL />
      </Grid>
    </Grid>
  )
}

export default HealthChartNUPL