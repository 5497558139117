import 'intl';
import 'intl/locale-data/jsonp/en'; // or any other locale you need

export const formatFloatToCurrency = (num) => {
    //const formatter = new Intl.NumberFormat('en-US', {
    //    style: 'currency',
    //    currency: 'USD',
    //    minimumFractionDigits: 2,
    //    maximumFractionDigits: 6,
    //});
    //request to display just the number with decimal and comma formatting
    const formatter = new Intl.NumberFormat();    
    return formatter.format(num);
}
