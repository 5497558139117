import React from 'react'
import { Typography, Stack, Box } from '@mui/material'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { useTheme } from '@mui/material/styles'

const ResearchTutorial3Search = () => {

    const theme = useTheme()

    return (
        <Stack direction="column" alignItems="flex-end" sx={{padding: '15px'}}>
            <Typography sx={{paddingTop: '10px', marginRight: '20px', fontSize: '2em', color: theme.palette.warning.dark}}>Search Bar <FaArrowAltCircleRight size={20} /></Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>Here you set your search criteria. For now we will simply enter a search term in the, 
            “Crypto Project Twitter Search” box. Common search terms examples that many use are: ‘mainnet’, ‘staking’, ‘conferences’.</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>For now let’s search for:</Typography>
            <Typography variant="h4" color={theme.palette.primary.main} sx={{padding: '10px'}}>staking</Typography>
        </Stack>    
    )
}

export default ResearchTutorial3Search