import React from 'react'
import { Typography, Link } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const Copyright = () => {
  const theme = useTheme()
  
  return (
    <Typography variant='body2' sx={{color: theme.palette.primary.main, marginTop: '25px'}} align='center'>
      {'Copyright © '}
      <Link href='https://alphacentoro.com/'>
        Alpha Centoro
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default Copyright
