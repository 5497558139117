import React from 'react'
import { Box, Grid, Button, Stack, Modal,  Backdrop, Fade, Typography, 
    TextField, InputLabel, Select, MenuItem, FormControl } from '@mui/material'
import { useTheme } from '@mui/material/styles'   

import { AiOutlineBell } from 'react-icons/ai'
import { ImCancelCircle } from 'react-icons/im'

const CoinDetailsChartPriceAlert = ( {priceAlertOpen, priceAlert, handlePriceAlertChange, priceAlertError, coinID, handleChange, alertMultiple, saveAlert, cancelAlert} ) => {

    const theme = useTheme()

    return (
        <Modal
            aria-labelledby='spring-modal-price-alert'
            aria-describedby='spring-modal-price-alert-description'
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
            open={priceAlertOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            disableEscapeKeyDown={true}
        >
            <Fade in={priceAlertOpen}>
            <Box sx={{ position: 'absolute', width: 600, backgroundColor: theme.palette.dark[800],  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)', borderRadius: '8px'}}>
                <h2 id='spring-modal-price-alert'>Create A Price Alert For {coinID}</h2>
                <Box id='spring-modal-price-alert-description' style={{ fontSize: '1rem' }}>
                    You can have us notify you when price of {coinID} hits a certain price.  Just insert the value below and hit enter
                    <br /><br />
                    <Grid container>
                        <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around">
                            <Typography variant="subtitle2"></Typography>
                            <TextField
                            type='text'
                            required
                            fullWidth
                            id='priceAlert'
                            label='price'
                            defaultValue={priceAlert}
                            name='priceAlert'
                            onChange={handlePriceAlertChange}
                            helperText={priceAlertError ? 'Your price must be more than 0' : null}
                            error={priceAlertError}
                            inputProps={{ maxLength: 100 }}
                            variant="filled"
                            focused                
                            />                  
                        </Stack>
                        </Grid>
                        <FormControl fullWidth style={{ paddingBottom: '25px'}}>
                        <InputLabel id="demo-simple-select-label">Number of Alerts</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={alertMultiple}
                            label="Number of Alerts"
                            onChange={handleChange}
                        >
                            <MenuItem value={1}>One</MenuItem>
                            <br/>
                            <MenuItem value={3}>Three</MenuItem>
                            <br/>
                            <MenuItem value={5}>Five</MenuItem>
                        </Select>
                        </FormControl>
                        <Grid item xs={12}>
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" sx={{width: '100%'}}>
                            <Button startIcon={<AiOutlineBell />} variant='outlined' onClick={saveAlert} color='primary'>
                            Click To Save Your Alert
                            </Button>
                            <Button startIcon={<ImCancelCircle />} variant='outlined' onClick={cancelAlert} color='warning' sx={{ml: 1}}>
                            Cancel
                            </Button>
                        </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            </Fade>
        </Modal>        
    )
}

export default CoinDetailsChartPriceAlert