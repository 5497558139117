import Header from './Header'
import Main from './Main';
import Story1 from './Story1';
import Story2 from './Story2';
import Story3 from './Story3';
import Footer from './Footer';

function LandingPage() {
  return (
    <div className="container">
        <Header/>
        <Main/>
        <Story1/>
        <Story2/>
        <Story3/>
        <Footer/>
    
    </div>
  );
}

export default LandingPage;