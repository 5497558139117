import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import store from './store/store'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { CircularProgress, CssBaseline, StyledEngineProvider } from '@mui/material'
import { Route, Routes, Navigate } from 'react-router-dom'
import fetchData from './utility/endpointFetch'
import auth from './Components/Auth/auth'

import Home from './Components/Home'
import CoinDetails from './Components/CoinDetails'
import Register from './Components/Register'
import Login from './Components/Login'
import Logout from './Components/Login/Logout'
//import Research from './Components/Research'
//import Research2 from './Components/Research2'
import MyCurrencies from './Components/MyCurrencies'
import User from './Components/User'
import Feedback from './Components/Feedback'
import ResetPassword from './Components/User/ResetPassword'
import RegisterResearcher from './Components/RegisterResearcher/RegisterResearcher'
import Health from './Components/Health'
import PasswordReset from './Components/Login/PasswordReset'
import PasswordResetForm from './Components/Login/PasswordResetForm'
import VIP from './Components/VIP'
import HomeFeed from './Components/HomeFeed/HomeFeed'
import Research3 from './Components/Research3'
import PriceAlerts from './Components/User/PriceAlerts'
import UserReferrals from'./Components/UserReferrals'
import InfluencerTracking from './Components/InfluencerTracking'
import ResearchCustom from './Components/ResearchCustom'
import LandingPage from './Components/LandingPage'

// defaultTheme
import themes from './themes'
import TokenWizard from './Components/TokenWizard/TokenWizard'
import PortfolioTracking from "./Components/PortfolioTracking/PortfolioTracking";
import TweetDisplay from "./Components/TweetDisplay/TweetDisplay";

import './App.css'

const App = () => {
  var status = auth.checkLogStatus()
  const customization = useSelector((state) => state.customization)
  const [loggedIn, setLoggedIn] = useState(status)
  const [userDetails,setUserDetails] = useState({})
  const [userObject,setUserObject] = useState({})
  const [favoriteCoinsList, setFavoriteCoinsList] = useState([])
  const [isLoading,setIsLoading] = useState(true)

  //console.log("loggedIn is in top of App: ")
  //console.dir(loggedIn)

  function RequireAuth({ children }) {
    return auth.checkLogStatus() === true ? children : <Navigate to="/login" replace />;
  }

  useEffect(() => {
    //console.log("useEffect: status is: ")
    //console.dir(status)
    //console.log("loggedIn is: ")
    //console.dir(loggedIn)
    if (status) {
      const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
      }
      ;(async () => {
        try {
          const response = await fetchData(`api/v1/users/userdata/`, "could not receive user data and favorites data", fetchParams).promise
          console.log("response getting user data and favorites in app")
          console.dir(response)
          setUserObject(response)
          setUserDetails(response.user)
          setFavoriteCoinsList(response.coin)
          setIsLoading(false)
        } catch (error) {
          console.log("something is broke getting  user data and favorites: " + error)
        }
      })()
    } else {
      //console.log("there is no status lets go to login");
      setIsLoading(false);
    }
  }, [loggedIn, status])

  //console.log("Object.keys(userObject).length is: " + Object.keys(userObject).length)
  //console.log("isLoading is: " + isLoading)
  if (isLoading) {
    return <CircularProgress />
  }
  console.log("userObject before return in App.js: ")
  console.dir(userObject)
  return (
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <main >
              <Routes>
                <Route path="/register" element={<Register />} />
                <Route path="/forgotpassword" element={<PasswordResetForm />} />
                <Route path="/login/reset" element={<PasswordReset />} />
                <Route path="/login" element={<Login setLoggedIn={setLoggedIn} />} />
                <Route path="/logout" element={<Logout setLoggedIn={setLoggedIn} setUserDetails={setUserDetails} />} />
                <Route path="/" element={ <LandingPage />} />
                <Route path="/home" element={ <RequireAuth><Home userObject={userObject} /></RequireAuth>} />
                <Route path='/user' element={<RequireAuth><User /></RequireAuth>} />
                <Route path='/resetpassword' element={<RequireAuth><ResetPassword userObject={userObject} /></RequireAuth>} />
                <Route path='/feedback' element={<RequireAuth><Feedback userObject={userObject}/></RequireAuth>} />
                <Route path='/registerresearcher' element={<RequireAuth><RegisterResearcher userDetails={userDetails}/></RequireAuth>} />
                <Route path='/influencer' element={<RequireAuth><InfluencerTracking userObject={userObject}/></RequireAuth>} />
                <Route path='/research3' element={<RequireAuth><Research3 userObject={userObject}/></RequireAuth>} />
                <Route path='/researchcustom' element={<RequireAuth><ResearchCustom userObject={userObject}/></RequireAuth>} />
                <Route path='/homefeed' element={<RequireAuth><HomeFeed userObject={userObject}/></RequireAuth>} />
                <Route path='/tw' element={<RequireAuth><TokenWizard /></RequireAuth>} />
                <Route path='/portfolio' element={<RequireAuth><PortfolioTracking userObject={userObject} /></RequireAuth>} />
                {/*<Route path="coindetailcommentary:coinId" element={<RequireAuth><CoinCommentaryDetails /></RequireAuth> } />*/}
                <Route path='/mycurrencies' element={<RequireAuth><MyCurrencies userObject={userObject} setUserDetails={setUserDetails} favoriteCoinsList={favoriteCoinsList} setFavoriteCoinsList={setFavoriteCoinsList} /></RequireAuth>} />
                <Route path='/health' element={<RequireAuth><Health userObject={userObject} /></RequireAuth>} />
                <Route path='/vip' element={<RequireAuth><VIP userObject={userObject} favoriteCoinsList={favoriteCoinsList} /></RequireAuth>} />
                <Route path='/tweetdisplay' element={<RequireAuth><TweetDisplay userObject={userObject} /></RequireAuth>} />
                <Route path="/coindetail/:coinId" element={<RequireAuth><CoinDetails userObject={userObject}/></RequireAuth> } />
                <Route path='/pricealerts' element={<RequireAuth><PriceAlerts userObject={userObject} /></RequireAuth>} />
                <Route path='/userreferrals' element={<RequireAuth><UserReferrals userObject={userObject} /></RequireAuth>} />
              </Routes>
            </main>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
  );
};

export default App;