import React from 'react'
import {
    Grid,
    Stack,
    Typography,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { formatFloatToCurrency } from "../../utility/number"
//import { useSortable } from '@dnd-kit/sortable'
//import { CSS } from '@dnd-kit/utilities'

const WatchListDisplayItem = ( {currencyDetails, sendCoinChoiceToDependents } ) => { // if using dnd {id, currencyDetails, sendCoinChoiceToDependents }
    console.log("top of WatchListDisplayItem and currencyDetails is:")
    console.dir(currencyDetails)

    const theme = useTheme()

    //const { setNodeRef, attributes, listeners,  transition, transform, isDragging} = useSortable( {id: id} )

    var priceIcon = (<ArrowUpward sx={{fontSize: 16, position: 'relative', top: '3px'}}/>)
    var priceColor = theme.palette.success.main
    if (currencyDetails.day_1_price_change < 0 ) {
        priceIcon = (<ArrowDownward sx={{fontSize: 16, position: 'relative', top: '3px'}} />)
        priceColor = theme.palette.error.main
    }

    const typographyStyle = {position: 'relative', zIndex: -1 }

    /*const dragStyle = {
        transition,
        transform: CSS.Transform.toString(transform),
        width: '100%',
        opacity: isDragging ? 0.5 : 1,
        
    }*/

    return (
        //<div ref={setNodeRef} {...attributes} {...listeners} style={dragStyle}>
        <Grid container spacing={0} sx={{marginBottom: '3px'}} >
            <Grid item xs={4} display="flex" justifyContent="flex-start">
                <Stack direction="row" display="flex" alignItems="center" justifyContent="center" >
                    <Typography display="inline" sx={{marginRight: '5px'}}><img style={{maxWidth: '15px'}}  src={ currencyDetails.image_thumbnail !== null ? currencyDetails.image_thumbnail : null } alt={String(currencyDetails.coin_symbol).toUpperCase()} /></Typography>
                    <Typography display="inline" component="span" onClick={()=>sendCoinChoiceToDependents(currencyDetails)} sx={{cursor: 'pointer',color: theme.palette.grey[500]}}>{String(currencyDetails.coin_symbol).toUpperCase()}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
                <Stack direction="row" display="flex" alignItems="center" justifyContent="center" >
                    <Typography sx={{color: theme.palette.grey[500]}}>{formatFloatToCurrency(currencyDetails.current_price)}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
                <Stack direction="row" display="flex" alignItems="center" justifyContent="center" >
                    <Typography display="inline" sx={{color: priceColor}}>{parseFloat(currencyDetails.day_1_price_change).toFixed(2)}% {priceIcon}</Typography>
                </Stack>
            </Grid>
        </Grid>
        //</div>
    )
}

export default WatchListDisplayItem