import React from 'react'
import { Box, Grid, Stack, Typography, Divider } from '@mui/material'

import VIPSocialStats from '../VIP/VIPSocialStats'

import VIPCoinStats from '../VIP/VIPCoinStats'
//VIPCoinStats needs: 
//coinChart='' ---used to get coin market details and display within vipcoinstats
//showOfficialTweets --- not currently used within vipcoinstats
//setCoinInChart --- used to set coin for associated chart
//changeDateInChart --- will not be used here...and not used in vipcoinstats
//changeAtFollowedTab --- used to change followed...used to set extra tab here



const VIPTweetsCoin = ( {twitterAccount, coin, changeVIPTweetsChartTab, changeVIPTweetsAccountsTweetsTab, maxBoxHeight} ) => {

    const changeDateInChart = () => {
        //not sure will provide this yet
    }

    return (
        <Box sx={{height: maxBoxHeight, overflowY: 'auto'}}>
            <VIPSocialStats 
                atFollowedTabValue={twitterAccount}
            />
            <VIPCoinStats
                coinChart={coin}
                showOfficialTweets={changeVIPTweetsAccountsTweetsTab} //not used currently
                setCoinInChart={changeVIPTweetsChartTab}
                changeAtFollowedTab={changeVIPTweetsAccountsTweetsTab}
            />
        </Box>
    )
}

export default VIPTweetsCoin