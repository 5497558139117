import { Typography, Stack, IconButton } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import { TiDelete } from 'react-icons/ti'


const HomeFeedFollowedListItem = ({ followed, removeUserFromTwitterFeedList }) => {
    
    const theme = useTheme()

    return (
        <Stack direction="row" sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between', paddingRight: '15px' }} >
            <Typography>{followed}</Typography>
            <IconButton size="small" onClick={()=>removeUserFromTwitterFeedList(followed)} ><TiDelete color={theme.palette.error.dark} /></IconButton>
        </Stack>
    )
}

export default HomeFeedFollowedListItem