import React, { useState, useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Grid, CircularProgress, Typography, Stack } from '@mui/material'
import fetchData from '../../utility/endpointFetch'
import { checkArraysEqual } from '../../utility/objHelper'

import VIPAddCoinCurrentScreener from './VIPAddCoinCurrentScreener'
import VIPAddCoinScreenerChoices from './VIPAddCoinScreenerChoices'

// ==============================|| Favorite Choices Popover ||============================== //

const VIPAddCoinChoices = ( { changeInVIPCoins, changeInVIPScreener} ) => {
  //console.log("top of VIPAddCoinChoices and userObject is: ")
  //console.dir(userObject)
  const theme = useTheme()

  //const [vipAddCoinTabValue,setVIPAddCoinTabValue] = useState('1')

  const [isLoading,setIsLoading] = useState(true)
  //const [currentScreener,setCurrentScreener] = useState([])
  const [screenerListObject,setScreenerListObject] = useState({})
  const [localUserData,setLocalUserData] = useState({})
  const [vipScreenerName,setVIPScreenerName] = useState('')
  //const [localScreenerList,setLocalScreenerList] = useState(userObject.screener_name)
  //const [localDeletedScreenerList,setLocalDeletedScreenerList] = useState(userObject.deleted_screener)
 
  //const handleAddCoinTabChange = (event, newValue) => {
  //  setVIPAddCoinTabValue(newValue);
  //}

  //function delay(time) {
  //  return new Promise(resolve => setTimeout(resolve, time));
  //}  

  const checkVIPArraysEqual = (currentCoin, screener,index) => {
    //screener is the "whole coin with loaded data" and cannot compare in that state
    //console.log("top of checkVIPArraysEqual and screener is:")
    //console.dir(screener)
    //console.dir("index is: " + index)
    let reducedScreener = screener.map(thisScreenerDetails => thisScreenerDetails.coin_id)
    //console.log("reducedScreener is: ")
    //console.dir(reducedScreener)
    //console.log("currentCoin is:")
    //console.dir(currentCoin)
    if (checkArraysEqual(reducedScreener,currentCoin)) {
    //if (Array.isArray(reducedScreener) && Array.isArray(currentCoin) && reducedScreener.length === currentCoin.length && reducedScreener.every((val, index) => currentCoin.includes(val))) {
        //console.log("setting vipScreenerName to: " + index)
        setVIPScreenerName(index)
        //setCurrentScreener(screener)
        changeInVIPCoins(reducedScreener)
        setLocalUserData(prevState => ({...prevState, coin: reducedScreener}))
        return true
    } else {
        //console.log("does not match")
        return false
    }            
  }     

  /*
  const getAllScreeners = () => {
    //console.log("top of getAllScreeners")
    const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
        'Content-Type': 'application/json'
        },
    }
    ;(async () => {
        const getCurrentScreenersResponse = await fetchData(`api/v1/users/screener/`, "could not get all screeners in all vip coins list", fetchParams).promise
        //console.log("getCurrentScreenersResponse getting user data in all vip coins list")
        //console.dir(getCurrentScreenersResponse)
        return getCurrentScreenersResponse.results
    })() 
  }
  */

  useEffect(() => {
    //get all screeners for this user
    setIsLoading(true)
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
          'Content-Type': 'application/json'
      },            
    }
    ;(async () => {
      try {
        const getUserObject = await fetchData(`api/v1/users/userdata/`, "could not get new user object", fetchParams).promise
        //console.log("getUserObject in useEffect is and setting localUserData to: ")
        //console.dir(getUserObject)
        setLocalUserData(getUserObject)
        
        ;(async () => {
          const getCurrentScreenersResponse = await fetchData(`api/v1/users/screener/`, "could not get all screeners in all vip coins list", fetchParams).promise
          //console.log("getCurrentScreenersResponse getting user data in all vip coins list")
          //console.dir(getCurrentScreenersResponse)

          let objectScreeners = {} //why this needs to be an object instead of array??? 
          getCurrentScreenersResponse.results.map( coin => {
              if (!objectScreeners.hasOwnProperty(coin.screener_name)) {
                  objectScreeners[coin.screener_name] = []
              }
              objectScreeners[coin.screener_name].push(coin)
          })
          //console.log("objectScreeners in useEffect is:")
          //console.dir(objectScreeners)        
          setScreenerListObject(objectScreeners)

          let allScreenersArray = Object.entries(objectScreeners)
          //console.log("-----allScreenersArray is: ")
          //console.dir(allScreenersArray)
          let selectedScreener = allScreenersArray.find((screener, index) => checkVIPArraysEqual(getUserObject.coin, screener[1],screener[0]))  
          //console.log("selelctedScreener is:")
          //console.dir(selectedScreener)
          setIsLoading(false)        
        })()   
      } catch (error) {
        console.log("something is broke getting new userObject", error)
      }

    })()                
  }, [])   

  const getNewUserObject = () => {
    setIsLoading(true)
    const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
            'Content-Type': 'application/json'
        },            
    }
    ;(async () => {
        try {
            const getUserObject = await fetchData(`api/v1/users/userdata/`, "could not get new user object", fetchParams).promise
            //console.log("getUserObject in ScreenerList is and setting localUserData to: ")
            //console.dir(getUserObject)
            setLocalUserData(getUserObject)
            //setLocalScreenerList(getUserObject.screener_name)
            //setLocalDeletedScreenerList(getUserObject.deleted_screener)

            /* no need for this...userObject.coin.length === 0 just means "all coins" screener is selected
              if (getUserObject.coin.length === 0) {
                //there is no current watchlist set for vip....set one if available
                //console.log("getUserObject.coin has length 0 in getNewUserObject()")
                if (getUserObject.screener_name.length === 1) {
                    console.log("there is only one screener...set it's coins in coin")
                    //this is the first screener for this user....set it as the VIP coin filter by
                    //populating userObject.coin
                    //as there is only one screener...set that one screeners coins as vip coin filter in userObject.coin
                    let currentScreeners = getAllScreeners()
                    let newVIPCoins = currentScreeners.map( (screenerCoin,index) => { return screenerCoin.coin_id} )
                    //console.log("Only one newVIPCoins is:")
                    //console.dir(newVIPCoins)
                    setNewCoinsUserObject(newVIPCoins)                     

                } else if (getUserObject.screener_name.length === 0) {
                    console.log("there no screeners...cannot set coins in coin")
                    //do not do anything as nothing to set coin to
                } else {
                    //there's more than on screener while coin is empty....just choose the first one and set it.
                    console.log("there is more than one screener...set first screener coins in coin")
                    let currentScreeners = getAllScreeners()
                    let firstScreenerName = currentScreeners[0]['screener_name']
                    //console.log("firstScreenerName is: " + firstScreenerName)
                    let newVIPCoins = currentScreeners.map( (screenerCoin,index) => { 
                        if (screenerCoin.screener_name === firstScreenerName)
                            return screenerCoin.coin_id
                    } )
                    //console.log("firstScreenerName newVIPCoins is:")
                    //console.dir(newVIPCoins)
                    setNewCoinsUserObject(newVIPCoins)                                             
                }
            } else {
                console.log('ALREADY SOMETHING IN getUserObject.coin in VIPAddCoinChoices so no need to set something')
                //console.dir(getUserObject)
            }}
            */

            return getUserObject
            
        } catch (error) {
            console.log("something is broke getting new userObject", error)
        }

    })()        
  }  

  const setNewCoinsUserObject = (newCoins) => {
    setIsLoading(true)
    //console.log("TOP OF setNewCoinsUserObject and newCoins is:")
    //console.dir(newCoins)
    const fetchSetUserCoinsParams = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            {
                'coin': newCoins
            }
        )
    }
    const errorMessage = 'Error setting new coins for vip filter.  Resubmit.  If still an error please contact support.'
    ;(async () => {
        try {
            const setUserCoinsResponse = await fetchData('api/v1/users/userdata/', errorMessage, fetchSetUserCoinsParams).promise
            //console.log("setUserCoinsResponse is:")
            //console.dir(setUserCoinsResponse)
            //console.log("before setTimeout")
            getNewUserObject()
            setTimeout( function() {
              //console.log("inside setTimeout")
              setIsLoading(false)
            }, 1000)
            //await delay(2000)
            
        } catch (error) {
            console.log("something is broke setting new coins within setNewCounsUserObject", error)
        }                
    })()  
  }  

  const changeScreenerVIP = (newScreenerName) => {
    ////console.log("changeScreenerVIP and newScreenerName is:")
    //console.dir(newScreenerName)
    if (newScreenerName === 'All Coins') {
      setNewCoinsUserObject([])      
      setVIPScreenerName(newScreenerName) 

    } else if (screenerListObject.hasOwnProperty(newScreenerName) && screenerListObject[newScreenerName].length > 0) {
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }
        ;(async () => {
        try {
            var fetchScreenerCoinsURL = `api/v1/users/screener/?screener_name=${newScreenerName}`
            const screenerCoinsresponse = await fetchData(fetchScreenerCoinsURL, "could not receive screener coin data", fetchParams).promise
            //console.log("screenerCoinsresponse in changeScreenerVIP is:")
            //console.dir(screenerCoinsresponse)

            let coinsInList = screenerCoinsresponse.results.map( (coinDetails) => { return coinDetails.coin_id } )
            //console.log("coinsInList  is: ")
            //console.dir(coinsInList)
            
            setNewCoinsUserObject(coinsInList)      
            setVIPScreenerName(newScreenerName)      

        } catch (error) {
            console.log("something is broke getting screener coins in MyCurrencies: " + error )
            }
        })()
    } else {
        console.log("There are no coins in that screener....will not set")
    }
         
  }

  if (isLoading) return <CircularProgress />
  return (
    <Box sx={{width: '100%', height: '1050px',}}>
      <Stack direction="column">
        <Typography variant="h5" sx={{color: theme.palette.primary.main, marginTop: '20px'}}>Watchlist/Coins Used Within VIP</Typography>
        <Typography variant="subtitle2" sx={{color: theme.palette.grey[400], marginTop: '10px', marginBottom: '20px', marginRight: '20px'}}>On the left is the selected watchlist used within the VIP to filter results for.  Below that are the coins currently being used
        to filter results within the VIP feature.  On the right is the full list of watchlists to choose from and the currently selected one.  If you wish to filter VIP
        by a different watchlist click on it on the right.</Typography>
        <Typography variant="subtitle2" sx={{color: theme.palette.warning.main, marginTop: '10px', marginBottom: '20px', marginRight: '20px'}}>Any Screener without coins
        within it cannot be set as a VIP filter.</Typography>        
        <Grid container>
          <Grid item xs={6}>
            <Box sx={{width: '90%', paddingLeft: '0px', marginLeft: '0px', height: '920px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPAddCoinCurrentScreener 
                vipScreenerName={vipScreenerName}
                localUserData={localUserData} 
                currentScreeners={screenerListObject} 
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{width: '90%', paddingLeft: '0px', marginLeft: '0px', height: '920px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPAddCoinScreenerChoices 
                localUserData={localUserData}
                currentScreeners={screenerListObject}
                setNewCoinsUserObject={setNewCoinsUserObject}
                vipScreenerName={vipScreenerName}
                changeScreenerVIP={changeScreenerVIP}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default VIPAddCoinChoices