import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Box, CircularProgress, Grid, Stack, Typography, Pagination, Divider, IconButton } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { isEqual, sortBy } from 'lodash'
import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'

import ResearchTwitterResultItem from '../Research3/ResearchTwitterResultItem'
import TweetDisplayByCoinSelector from './TweetDisplayByCoinSelector'

import fetchData from '../../utility/endpointFetch'

//const TweetDisplayByCoin = forwardRef( ({coins=['bitcoin'], saveCoinChoiceToPanel, panelID, deleteBox, changeDependency, currentDependency, boxHeight, rowHeight, boxWidth}, componentSetCoinsRef ) => {
  const TweetDisplayByCoin = ( {coins=['bitcoin'], saveCoinChoiceToPanel, panelID, deleteBox, changeDependency, currentDependency, boxHeight, rowHeight, boxWidth} ) => {
  //console.log("top of TweetDisplayByCoin and coins is:")
  //console.dir(coins)
  //console.log("currentDependency is:")
  //console.dir(currentDependency)

  const theme = useTheme()

  const itemsPerResultPage = 100

  const [isLoading,setIsLoading] = useState(true)
  const [tweetsFromCoins,setTweetsFromCoins] = useState([])
  const [chosenCoins,setChosenCoins] = useState(coins)
  const [responseCount,setResponseCount] = useState(0)
  const [pageCount,setPageCount] = useState(0)
  const [selectedPage,setSelectedPage] = useState(1)

  const changeInMyCoins = (arrayChangeCoinsTo) => {
    //console.log("top of changeInMyCoins and arrayChangeCoinsTo is:")
    //console.dir(arrayChangeCoinsTo)
    setChosenCoins(arrayChangeCoinsTo)
    saveCoinChoiceToPanel(arrayChangeCoinsTo,panelID)
  }

  function setChosenCoinInComponent  ( coinToSet )  {
    console.log("top of setChosendCoinInComponent and coinToSet is: ")
    console.dir(coinToSet)
    setChosenCoins(coinToSet.coins)
  }

  //useImperativeHandle( componentSetCoinsRef, () => ({ setChosenCoinInComponent }),[setChosenCoins] )

  const changePage = (event, page) => {
    setSelectedPage(page)
  }

  const getTwitterCoinResults = () => {
    //console.log("top of GetTwitterCoinResults and chosenCoins is: ")
    //console.dir(chosenCoins)
    setIsLoading(true)
    
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      if (chosenCoins.length > 0)  {
        //there is no way to set pagination on this endpoint....default to 100 per page
        //var twitterCoinUrl = `api/v1/feed/twitter/?coins=${chosenCoins.toString()}&vip=true&page=${selectedPage}`
        var twitterCoinUrl = `api/v1/feed/alltweets/?coins=${chosenCoins.toString()}&page=${selectedPage}`
        try {
          const twitterCoinResponse = await fetchData(twitterCoinUrl, "could not receive all twitter coin data in TweetDisplayByCoin", fetchParams).promise
          console.log("twitterCoinResponse getting twitter coin data from api for TweetDisplayByCoin")
          console.log('twitterCoinUrl used: ' + twitterCoinUrl)
          console.dir(twitterCoinResponse)

          setResponseCount(twitterCoinResponse.count)

          if (twitterCoinResponse.count > itemsPerResultPage) {
            setPageCount(Math.ceil(twitterCoinResponse.count/itemsPerResultPage))
          } else {
            setPageCount(1)
          }

          setTweetsFromCoins(twitterCoinResponse.results)

          setIsLoading(false)
        } catch (error) {
          console.log("something is broke getting all twitter coin result in research3: " + error)
        } 
      } else {
        //there are no coins to pull from
      }
    })()      
  }

  useEffect(() => {
    //console.log(" USEEFFECT TOP WHEN CHOSENCOINS OR SELECT PAGE CHANGES")
    if (chosenCoins.length > 0) {
      getTwitterCoinResults()
    } else {
      setTweetsFromCoins([])
    }

  }, [chosenCoins, selectedPage])    

  useEffect(() => {
    if (chosenCoins.length > 0) {
      getTwitterCoinResults()
    } else {
      setTweetsFromCoins([])
    }

  }, [])     

  useEffect(() => {
    //console.log("top of useEffect within TweetDisplayByCoin where coins has changed to:")
    //console.dir(coins)
    //console.log("chosenCoins is:")
    //console.dir(chosenCoins)
    if (!isEqual(sortBy(chosenCoins), sortBy(coins))) {
      //console.log("chosenCoins is now coins")
      setChosenCoins(coins)
    }  else {
      //console.log("chosenCoins and coins are the same")
    }
  }, [coins])     

  const removeTweetBox = () => {
    //console.log("top of removeTweetBox and panelID is: " + panelID)
    deleteBox(panelID)
  }  

  const changeCustomDependency = (newDependency) => {
    changeDependency(panelID,newDependency)
  }

  if (isLoading) return <CircularProgress />
  return (
    <Box sx={{ display: 'flex', width: 'calc(100% - 10px)', marginLeft: '5px' }}>
      <Stack direction="column" display="flex" alignItems='left' sx={{width: '100%', overflowY: 'auto',}}>
        <Stack direction="row" display="flex" alignItems='center' justifyContent='space-between' sx={{marginTop: '5px', marginRight: '5px'}}>
          <Stack direction="row" display="flex" alignItems='center' justifyContent='flex-start' >
            <Typography display="inline" sx={{fontWeight: 400}} color={theme.palette.grey[300]}>{"tweets by coin(s): "}</Typography>
            <Typography display="inline" sx={{fontWeight: 600, marginLeft: '5px'}} color={theme.palette.primary.main}>{ chosenCoins.join(', ')+ " (" + responseCount + ")"}</Typography>
          </Stack>
          <Stack direction="row" justifyContent='flex-end'>  
            <TweetDisplayByCoinSelector 
                selectedCoins={chosenCoins}
                changeInMyCoins={changeInMyCoins}
                changeCustomDependency={changeCustomDependency}
                currentDependency={currentDependency}
              />
              <VscMove 
                className='draggable-handle' 
                color={theme.palette.warning.dark} 
                size={18} 
                style={{cursor: 'grabbing'}}
              />
              <MdCancel 
                  className='draggable-cancel' 
                  color={theme.palette.secondary.dark} 
                  size={18} 
                  onClick={removeTweetBox}
                  tyle={{cursor: 'pointer'}}
              />

          </Stack>          

        </Stack>
        <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>
        <Box sx={{overflowY: 'auto'}}>
          <Grid container spacing={0} >
            <Grid item xs={12} >
                {tweetsFromCoins.map( (tweet, index) => 
                  <ResearchTwitterResultItem 
                    key={tweet.id+Math.random().toString(36).substring(2,7)} 
                    tweet={tweet} 
                    search='' 
                    divHeight={rowHeight * boxHeight}
                    boxWidth={boxWidth}
                  />)
                }
            </Grid>  
            <Grid item xs={12}>
              <Pagination sx={{marginTop: '10px'}} shape="rounded" count={pageCount} page={selectedPage} onChange={changePage}/>          
            </Grid>              
          </Grid> 
        </Box>
      </Stack>           
    </Box>
  )
}

export default TweetDisplayByCoin