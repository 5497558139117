import { Stack, Typography, Checkbox, FormControlLabel  } from '@mui/material';

export const communitySize = (tweet) => {
    if(!tweet || !tweet.twitter_user_followers) return 0

    if (tweet.twitter_user_followers > 1000000) {
        return ((tweet.twitter_user_followers / 1000000).toFixed(1)) + 'M'
    } else if (tweet.twitter_user_followers > 10000) {
        return ((tweet.twitter_user_followers / 1000).toFixed(0)) + 'K'
    } else if (tweet.twitter_user_followers > 1000) {
        return ((tweet.twitter_user_followers/1000).toFixed(1)) + 'K'
    } else {
        //just display the full amount
        return tweet.twitter_user_followers
    }
}

export const checkArraysEqual = (array1,array2) => {
    return Array.isArray(array1) &&
        Array.isArray(array2) &&
        array1.length === array2.length &&
        array1.every((val, index) => val === array2[index])
}

export const isValidURL = (string) => {
    console.log("top of isValidURL and string is:")
    console.dir(string)
    try {
        new URL(string)
        return true
    } catch (err) {
        console.log("error in isValidURL is: " + err)
        return false
    }
}

export const displayUploadedFileORIMageURL = (arrayORFile, removeImageHandler, removeImage, displayMessage=true) => {

    console.log("top of displayUploadedFileORIMageURL and arrayORFile is:")
    console.dir(arrayORFile)
    console.log("Array.isArray(arrayORFile) is:" + Array.isArray(arrayORFile))
    console.log("removeImage is: " + removeImage)
    if (Array.isArray(arrayORFile) && arrayORFile.length > 0) {
        try {
            // this is a file is from the current process and will need to be displayed differently
            return (
                <Stack direction="column" alignItems="center" justifyContent="center">
                    {displayMessage ? <Typography variant="h4">File uploaded in the current session</Typography> : null }
                    <img src={URL.createObjectURL(arrayORFile[0])} style={{maxWidth: 200, maxHeight: 200, alignSelf: 'center', margin: '15px'}} alt="whitepaper file" /> 
                </Stack>
            )
        } catch (e) {
            console.log("ERROR in displayUploadedFileORIMageURL trying to display current upload: " + e)
        }
    } 
    else {
        try {
            //value returned from api will ONLY be an http link to the image...so see if the value is an image
            let url = new URL(arrayORFile)
            return (
                <Stack direction="column" alignItems="center" justifyContent="center">
                    <Typography variant="h4">File uploaded in the PREVIOUS session.  You can leave or overwrite with a new image</Typography>
                    <img src={arrayORFile} style={{width: 200, height: 200, alignSelf: 'center', marginTop: '5px'}} alt="whitepaper file" />
                    <FormControlLabel control={<Checkbox checked={removeImage} onChange={removeImageHandler} />} label="Remove Previous Image" />
                </Stack>
            )
        } catch (e) {
            console.log("ERROR in displayUploadedFileORIMageURL trying to display past upload: " + e)
        }                
    }
}