import React, { useState } from 'react'
import InfoIcon from "@mui/icons-material/Info";
// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, IconButton, Stack, useMediaQuery } from '@mui/material'

// project imports
import LogoSection from './LogoSection'
//import SearchSection from './SearchSection'
import ProfileSection from './ProfileSection'
//import NotificationSection from './NotificationSection'
//import WalletSection from './WalletSection'
import NavigationTop from '../NavigationTop'

// assets
//import { IconMenu2 } from '@tabler/icons'

const Header = ( { userObject, selectedPage } ) => {

  console.log("top of Header and userObject is:")
  console.dir(userObject)  
  const theme = useTheme()
  const medScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"))
  const [tutorialOpen, setTutorialOpen] = useState(false)

  if (medScreenOrSmaller) {
    return (
        <Stack 
            direction='row' 
            alignItems='center' 
            justifyContent='space-between'
            sx={{marginLeft: 0, paddingBottom: '10px', width: '100%', borderBottom: '1px solid', borderColor: theme.palette.grey[100] }}
        >
            <NavigationTop userObject={userObject} selectedPage={selectedPage} tutorialOpen={tutorialOpen} setTutorialOpen={setTutorialOpen} />
            <LogoSection medScreenOrSmaller={true} />
            <ProfileSection tutorialOpen={tutorialOpen} setTutorialOpen={setTutorialOpen}  />
        </Stack>
    )
  } else {
    return (
      <Stack direction='row' display="flex" alignItems="center" sx={{paddingBottom: '10px', width: '100%', borderBottom: '1px solid', borderColor: theme.palette.grey[100] }}>
          {/* logo & toggler button */}
          <Box
              sx={{
                  width: 300,
                  display: 'flex',
                  [theme.breakpoints.down('md')]: {
                      width: 'auto'
                  }
              }}
          >
              <Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
                  <LogoSection />
              </Box>
          </Box>
          <NavigationTop userObject={userObject} selectedPage={selectedPage} tutorialOpen={tutorialOpen} setTutorialOpen={setTutorialOpen} />
          <Box component="span" 
            sx={{
                display: 'flex',
                [theme.breakpoints.up('md')]: {
                    display: 'none'
                },
                [theme.breakpoints.down('md')]: {
                    width: 'auto',
                },                
                flexGrow: 1
            }}
          >
                <LogoSection />
          </Box>

          {/* header search 
          <SearchSection />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 1 }} />
          */}
          <IconButton 
            sx={{
                [theme.breakpoints.down('md')]: {
                    display: 'none'
                },
            }}            
            onClick={() => {
                console.log('clicked: ' + !tutorialOpen)
                setTutorialOpen(!tutorialOpen)
            }}>
              <InfoIcon color={'primary'} />
          </IconButton>

          {/* notification & profile */}
          {/* <WalletSection /> */}
          {/* <NotificationSection /> */}
          <ProfileSection tutorialOpen={tutorialOpen} setTutorialOpen={setTutorialOpen}  />
      </Stack>
    )
  }
};

export default Header