import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconArrowUpCircle, IconArrowDownCircle } from '@tabler/icons';

const NUPL = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About NUPL</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - MVRV Ratio">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
                Net Unrealized Profit and Loss (NUPL) is the difference between market cap and realized cap divided by market cap.
              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="h4">By Value Itself</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                Assuming that the latest coin movement is the result of a purchase, NUPL indicates the total amount of profit/loss in all the coins represented as a ratio.
                It could be interpreted as the ratio of investors who are in profit.
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Values over '0': Coins or investors who are in profit are more than investors that are in loss"
                      secondary="In order for NUPL to be over 0 (+), the Market Cap has to be larger than the Realized Cap. As Market Cap exceeds the Realized Cap, it indicates that the value that is being traded on market is greater than the on-chain expected value.
                      For example, if the NUPL value hits 0.75, it means that Market Cap is four times the Realized Cap. It is reasonable to assume that most coins that were moved are on profit and the wide gap between the Realized Cap and Market Cap could act as selling pressure. "
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowUpCircle color='#00b341' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Values under '0': Coins or investors who are in loss are more than investors that are in profit"
                      secondary="In order for NUPL to be under 0 (-), the Market Cap has to be less than the Realized Cap. As Realized Cap exceeds the Market Cap, it indicates that the value that is being traded on market is greater than the on-chain expected value.
                      For example, if the NUPL value hits -0.5, it means that Market Cap is half the Realized Cap. It is reasonable to assume that most coins that were moved are on loss and the wide gap between the Realized Cap and Market Cap could act as a buying motive. "
                    />
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="h4">By examining trend</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
              It shows the changing profit geography of investors
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />

                    </ListItemIcon>
                    <ListItemText
                      primary="Increasing trend: Increasing selling pressure"
                      secondary="More coins or investors are beginning to be in profit and this phase naturally indicates the increasing motive to take profit which leads to an increase in sell pressure.
                      Also, it indicates that Market Cap is exceeding Realized Cap making coins profitable to be sold through the market."
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                    <IconArrowUpCircle color='#00b341'/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Decreasing trend: Decreasing selling pressure"
                      secondary="More coins or investors are beginning to be in loss and this phase naturally indicates the decreasing motive to realize loss which leads to a decrease in sell pressure.
                      Also, it indicates that Realized Cap is exceeding Market Cap making coins less profitable to be sold through the market."
                    />
                  </ListItem>
                </List>
              </Typography>
            </TabPanel>

          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default NUPL