import React, { useState, useRef } from 'react'
import { Paper, Typography, Box, Chip, Popper, ClickAwayListener, Grid,
  Divider, IconButton, useMediaQuery, Button } from '@mui/material'

import { useTheme } from '@mui/material/styles'

import { IconAdjustmentsHorizontal, IconSquareX } from '@tabler/icons'

import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'
import CurrencyListFilterItem from './CurrencyListFilterItem'

const CurrencyListFilter = ( {filterCurrencyList, categoryList, clearCategoryFilter, categoryChosen} ) => {
  //console.log("top of CurrencyListFilter and categoryList is:")
  //console.dir(categoryList)
  //console.log("categoryChosen is:")
  //console.dir(categoryChosen)


  const theme = useTheme();

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  /**
  * anchorRef is used on different componets and specifying one type leads to other components throwing an error
  * */
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
    }
    setOpen(false);
  };

  const chooseCategory = ( showThisCategoryID ) => {
    setOpen(false)
    filterCurrencyList(showThisCategoryID)
  }

  const handleToggle = () => {
    //console.log("top of handleToggle in CurrencyListFilter")
    setOpen((prevOpen) => !prevOpen);
  }

  const resetCategoryFilter = () => {
    //console.log("top of resetCategoryFilter")
    clearCategoryFilter()
    setOpen(false)
  }

  const chipLabel = () => {
    if (categoryChosen !== '')
      return "Categories - "+ categoryChosen
    else
      return "Categories"
  }

  return (
      <>
          <Box
              sx={{
                  ml: 2,
                  mr: 3,
                  [theme.breakpoints.down('md')]: {
                      mr: 2,
                      ml: 0,
                      mb: 2
                  }
              }}
          >
            <Chip
              sx={{ borderRadius: '12px',
                background: theme.palette.primary.main, //theme.palette.grey[300],  //grey[300]  theme.palette.secondary.light
                color: theme.palette.grey[500], //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
                '&:hover': {
                  background: theme.palette.primary.dark, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
                  color: theme.palette.grey[900], //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
                }
              }}
              label={chipLabel()}
              onClick={handleToggle}
              icon={<IconAdjustmentsHorizontal color={theme.palette.grey[500]}  stroke={1.5} size="1.1rem" />}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
            />
          </Box>
          <Popper
              placement={matchesXs ? 'bottom' : 'bottom-end'}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              popperOptions={{
                  modifiers: [
                      {
                          name: 'offset',
                          options: {
                              offset: [matchesXs ? 5 : 200, 20],
                              zIndex: (theme) => theme.zIndex.drawer + 1
                          }
                      }
                  ]
              }}
          >
              {({ TransitionProps }) => (
                  <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                    <Paper sx={{}}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '300px', backgroundColor: theme.palette.dark[800]}}>
                          <Grid container spacing={2}>
                            <Grid item xs={10}>
                              <Typography variant="h3" sx={{marginTop: '15px', marginLeft: '30px'}}>Filter By Type</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                aria-label="close"
                                sx={{color: theme.palette.error.main}}
                                onClick={handleClose}>
                                <IconSquareX />
                              </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                              <Box 
                                style={{ display: "flex", flexDirection: 'column', maxHeight: '800px', overflow: 'hidden', overflowY: 'auto' }}
                              >
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Button
                                      key='none'
                                      onClick={() => resetCategoryFilter()}>
                                        <Typography sx={{fontWeight: 700}} >clear filter</Typography>
                                    </Button>
                                  </Grid>
                                  {categoryList.map( (category,index) => 
                                    <CurrencyListFilterItem 
                                      key={"categorylist"+category+index} 
                                      category={category} 
                                      chooseCategory={chooseCategory} 
                                      resetCategoryFilter={resetCategoryFilter} 
                                      categoryChosen={categoryChosen} 
                                    />  )}
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </MainCard>
                      </ClickAwayListener>
                    </Paper>
                  </Transitions>
              )}
          </Popper>
      </>
  )
}

export default CurrencyListFilter