import React, { useState, useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { IconButton, Divider, Grid, Paper, Pagination,
    Popover, CircularProgress, Typography, useMediaQuery, FormControl, Stack, FormGroup, Button,
} from '@mui/material'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'
import fetchData from '../../utility/endpointFetch'

// project imports
import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'
import PortfolioChooseCategoryChoices from './PortfolioChooseCategoryChoices'
import PortfolioChooseSortChoices from './PortfolioChooseSortChoices'
import PortfolioChooseSearchChoices from './PortfolioChooseSearchChoices'
import PortfolioChooseCoinItem from './PorfolioChooseCoinItem'

// assets
import { IconSquareX } from '@tabler/icons';

// ==============================|| Favorite Choices Popover ||============================== //

const PortfolioChooseCoinPopover = ( { setNewWalletCoin, selectedWallet } ) => {
  //console.log("top of PortfolioChooseCoinPopover")
  //console.log("selectedWallet is: " + selectedWallet)
  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const [isLoading,setIsLoading] = useState(true)

  const [coinsList,setCoinsList] = useState([])
  const [chooseOpen, setChooseOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const [currencyPage,setCurrencyPage] = useState(1)
  const [pageCount,setPageCount] = useState(1)
  const [sortByUrl,setSortByUrl] = useState('')
  const [filterByUrl,setFilterByUrl] = useState('')
  const [searchByUrl,setSearchByUrl] = useState('')
  const [sortingBy,setSortingBy] = useState('')
  const [categoryList,setCategoryList] = useState([])
  const [categoryChosen,setCategoryChosen] = useState('')
  const [responseCount,setResponseCount] = useState(0)

  const sortResults = (sortBy) => {
    //console.log("top of sortResults in FavoriteChoicesPopover: " + sortBy)
    setSortingBy(sortBy)
    setCurrencyPage(1)
    switch(sortBy) {
      case 'rank-highest':
        setSortByUrl("&ordering=market_cap_rank")
        break
      case 'rank-lowest':
        setSortByUrl("&ordering=-market_cap_rank")
        break
      case 'name-alphabetical':
        setSortByUrl("&ordering=name")
        break
      case 'name-alphabetical-reverse':
        setSortByUrl("&ordering=-name")
        break
      case 'symbol-alphabetical':
        setSortByUrl("&ordering=coin__symbol")
        break
      case 'symbol-alphabetical-reverse':
        setSortByUrl("&ordering=-coin__symbol")
        break
      case 'price-highest':
        setSortByUrl("&ordering=-current_price")
        break
      case 'price-lowest':
        setSortByUrl("&ordering=current_price")
        break
      case 'volume-lowest':
        setSortByUrl("&ordering=total_volume")
        break
      case 'volume-highest':
        setSortByUrl("&ordering=-total_volume")
        break
    }
    return
  }

  const clearSort = () => {
    setSortingBy('')
    setSortByUrl('')
  }

  const changePage = (event, page) => {
    console.log("top of changePage")
    console.dir(page)
    setCurrencyPage(page)
  }

  const handleClick = (event) => {
    console.log("top of handleClick")
    setAnchorEl(event.currentTarget)
    setChooseOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    console.log("top of handleClose")
    setAnchorEl(null)
    //setChooseOpen((prevOpen) => !prevOpen)
  };

  const id = chooseOpen ? 'simple-popover' : undefined

  const getCurrencyResult = () => {
    setIsLoading(true)
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }    
    ;(async () => {
      try {
        const response = await fetchData(`api/v1/coin/ecoinscreener/?page=${currencyPage}&page_size=50${sortByUrl}${filterByUrl}${searchByUrl}`, "could not receive all coins data", fetchParams).promise
        console.log("response getting all coins")
        console.dir(response)
        setCoinsList(response.results)

        setResponseCount(response.count)

        if (response.count > 100) {
          setPageCount(Math.ceil(response.count/100))
        } else {
          setPageCount(1)
        }

        if (sortByUrl === '') {
          //reorderlist by rank 1 first by default
          sortResults('rank-highest', response.results)
        }

        //extract all categories from currency list
        var category_list = []
        response.results.map( coin => {
          coin.category.forEach( category => {
            if (!category_list.includes(category)) {
              category_list.push(category)
            }
          })
        })
        setCategoryList(category_list)

        setIsLoading(false)
      } catch (error) {
        console.log("something is broke getting all coins in favorite choices popover: %s", error)
      }
    })()    
  }

  useEffect(() => {
    //console.log("top of useEffect after change")
    //console.log("currencyPage is: " + currencyPage)
    //console.log("sortByUrl is: " + sortByUrl)
    //console.log("filterByUrl is: " + filterByUrl)
    //console.log("searchByUrl is: " + searchByUrl)
    getCurrencyResult()


  }, [currencyPage, filterByUrl, sortByUrl, searchByUrl])

  useEffect(() => {
    //console.log("top of useEffect at load")
    //console.log("currencyPage is: " + currencyPage)
    //console.log("sortByUrl is: " + sortByUrl)
    //console.log("filterByUrl is: " + filterByUrl)
    //console.log("searchByUrl is: " + searchByUrl)
    getCurrencyResult()


  }, [])  

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }      

  const filterByCategory = (category) => {
    console.log("top of filterByCategory and category is: " + category)
    if (category !== '') {
      setFilterByUrl("&category=" + category)
      setCurrencyPage(1)
      setCategoryChosen(category)
    } else {
      setCurrencyPage(1)
      setCategoryChosen('')
      setFilterByUrl('')
    }
  }

  const clearCategoryFilter = () => {
    //remove a current category filter if there is one currently
    //console.log("top of clearCategoryFilter")
    setCurrencyPage(1)
    setFilterByUrl('')
    setCategoryChosen('')
  }

  const searchFavorites = (searchTerm) => {
    //console.log("top or searchFavorites...searchTerm: " + searchTerm)
    if (searchTerm.length > 0) {
      //console.log("searchTerm greater than 2")
      if (searchTerm.indexOf(' ') >= 0) {
        //console.log("searchTerm contains spaces")
        //contains spaces...no search
        setSearchByUrl('')
        return null
      } else {
        //console.log("about to search")
        var searchString = "&search=" + encodeURIComponent(searchTerm)
        setCurrencyPage(1)
        setSearchByUrl(searchString)
      }
    } else {
      //search term too short...wait till at least 3 characters
      //console.log("searchTerm 2 or less letters")
      setSearchByUrl('')
      return null
    }
  }

  const setCoinInWallet = (coin) => {
    console.log("top of setCoinInWallet and coin is: " )
    console.dir(coin)
    setNewWalletCoin(coin)
    setChooseOpen(false)
  }

  const clearSearchFavorites = () => {
    setSearchByUrl('')
    setCurrencyPage(1)
  }

  return (
    <div>
      <Button  variant="contained" onClick={(e)=>handleClick(e)} >Choose Coin</Button>
      <Popover
        id={id}
        open={chooseOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 400, left: 900 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >

        <Transitions position={matchesXs ? 'top' : 'top-right'} in={chooseOpen} >
          <Paper >
            <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '600px', minHeight: '700px'}}>
              <Grid container spacing={2} sx={{marginTop: '15px'}}>
                <Grid item xs={10} sx={{bgcolor: theme.palette.dark[800]}}>
                  <Typography display="inline" variant="h4" sx={{marginTop: '15px', marginLeft: '30px'}}>Choose Coin to Choose Add to: </Typography>
                  <Typography display="inline" variant="h4" color={theme.palette.primary.main} sx={{fontWeight: 700, marginTop: '15px'}}>{selectedWallet}</Typography>
                </Grid>
                <Grid item xs={2} sx={{bgcolor: theme.palette.dark[800]}}>
                  <IconButton
                    aria-label="close"
                    sx={{color: theme.palette.error.main}}
                    onClick={handleClose}>
                    <IconSquareX sx={{ marginTop: '15px'}}/>
                  </IconButton>
                </Grid>
                <Grid item xs={12} sx={{bgcolor: theme.palette.dark[800]}}><Divider /></Grid>
                <Grid item xs={7} sx={{bgcolor: theme.palette.dark[800]}}>
                  <PortfolioChooseCategoryChoices categoryList={categoryList} categorySelected={categoryChosen} filterByCategory={filterByCategory} clearCategoryFilter={clearCategoryFilter} />
                  <PortfolioChooseSortChoices clearSort={clearSort} sortResults={sortResults} selectedSort={sortingBy} />
                  <PortfolioChooseSearchChoices searchFavorites={searchFavorites} clearSearchFavorites={clearSearchFavorites} />
                </Grid>
                <Grid item xs={5} sx={{bgcolor: theme.palette.dark[800]}}>
                  <Stack direction="column" sx={{minHeight: '650px'}}>
                    <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
                    {isLoading ? <CircularProgress /> :
                      <PerfectScrollbar
                        style={{ overflowX: 'hidden' }}
                      >
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          {/*<FormLabel component="legend">Pick Favorites</FormLabel>*/}
                          <FormGroup>
                            {coinsList && coinsList.map( (coin, index ) => <PortfolioChooseCoinItem key={coin.coin+String(index)} coin={coin} setCoinInWallet={setCoinInWallet} />  )}
                          </FormGroup>
                        </FormControl>
                      </PerfectScrollbar>
                    }
                    <Pagination sx={{marginTop: '10px'}} shape="rounded" count={pageCount} page={currencyPage} onChange={changePage}/>
                  </Stack>
                </Grid>
              </Grid>
            </MainCard>
          </Paper>
        </Transitions>
      </Popover>
    </div>
  );
};

export default PortfolioChooseCoinPopover