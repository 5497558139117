import React, { useEffect, useState } from 'react'
import { Stack, RadioGroup, FormControlLabel, Radio, Typography, Tooltip, IconButton, Box } from '@mui/material'

import { useTheme } from '@mui/material/styles'

import { TiDelete } from 'react-icons/ti'
import { AiFillAlert } from 'react-icons/ai'
import ScreenerListLiveItem from './ScreenerListLiveItem'

const ScreenerListLive = ( { liveScreenerList, changeScreener, selectedScreener, removeScreener, vipScreenerName, setVIPCoinsFromScreener, localUserData }) => {
    console.log("top of ScreenerListLive")
    console.log("vipScreenerName is: " + vipScreenerName)
    console.log("selectedScreener is : " + selectedScreener)
    //console.log("liveScreenerList is:")
    //console.dir(liveScreenerList)
    //console.log("localUserData is: ") 
    //console.dir(localUserData)
    const theme = useTheme()

    const [radioVIPScreenerName,setRadioVIPScreenerName] = useState(vipScreenerName)

    useEffect(() => {
        setRadioVIPScreenerName("Favorites")
    }, [])

    const displayRemoveIcon = (currentScreener) => {
        //console.log("top of displayRemoveIcon and currentScreener is: " + currentScreener + " and vipScreenerName is: " + vipScreenerName)
        if (vipScreenerName === currentScreener || currentScreener === 'Favorites' || currentScreener === 'All Coins') {
            //this is the screener currently shown in the watchlist tool
            return null
        } else {
            return (
                <Tooltip title={"remove "+currentScreener+" list"}><IconButton size="small" sx={{position: 'relative', top: '-1px' }} onClick={()=>removeScreener(currentScreener)}><TiDelete  color={theme.palette.warning.main} /></IconButton></Tooltip>
            )
        }
    }

    const getRadioGroupProps = () => {
        if ((vipScreenerName === '' || vipScreenerName === undefined) && localUserData.coin.length === 0 ) {
            return {
                value: 'All Coins',
            }
        } else if (vipScreenerName === undefined) {
            return {}
        } else {
            return {
                value: vipScreenerName,
            }
        }
    }

    const changeVIPCoinsFromScreener = (e) => {
        setVIPCoinsFromScreener(e.target.value)
    }

    return (
        <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={changeVIPCoinsFromScreener}
            {...getRadioGroupProps()}
            sx={{width: '100%'}}
            value={vipScreenerName}
        >
            <Stack direction="column" display="flex" alignItems="center" justifyContent="space-between" sx={{width: '100%'}}>
                {liveScreenerList.map((screener, index) => 
                    <ScreenerListLiveItem 
                        screener={screener}
                        selectedScreener={selectedScreener}
                        removeScreener={removeScreener}
                        vipScreenerName={vipScreenerName}
                        changeScreener={changeScreener}
                        localUserData={localUserData}
                        changeVIPCoinsFromScreener={changeVIPCoinsFromScreener}
                    />  )}
            </Stack>
        </RadioGroup>
    )
}

export default ScreenerListLive