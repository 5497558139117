import React, { useState } from 'react'

import { Stack, TextField, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material'

import { Search, Clear } from '@mui/icons-material'

const HomeFeedTwitterUserSearch = ( {searchTermChangeHandler, clearTwitterUserSearch, setInfluencerHandler } ) => {

  const [searchTerm,setSearchTerm] = useState('')
  const [searchTermError,setSearchTermError] = useState(false)
  const [searchTermErrorMessage,setSearchTermErrorMessage] = useState('')
  const [influencerValue,setInfluencerValue] = useState('both')

  const handleSearchTermChange = e => {
    if (searchTermError) setSearchTermError(false)
    setSearchTerm(e.target.value)
  }

  const handleInfluencerChange = e => {
    setInfluencerValue(e.target.value)
    setInfluencerHandler(e.target.value)
  }

  const searchSubmitted = () => {
    //console.log("value of searchTerm is: " + searchTerm)

    //search term cannot have spaces
    //search term must be minimum of 3 spaces

    searchTerm.replace(/\s+/g, '')
    if (searchTerm.length > 2) {
      //console.log("searching..." + searchTerm)
      searchTermChangeHandler(searchTerm)
    } else {
      setSearchTermError(true)
      setSearchTermErrorMessage('search term needs to be at least 3 characters')
    }

  }

  const clearSearch = () => {
    setSearchTerm('')
    setSearchTermError(false)
    setSearchTermErrorMessage('')
    clearTwitterUserSearch()
  }

  //console.log("searchTermError is: " + searchTermError)
  //console.log("searchTermError is: " + searchTermError)

  return (
    <Stack direction="column" sx={{marginTop: '15px'}}>
        <Stack direction="row" alignItems="center" justifyContent="center">
        <FormControl>
            <FormLabel id="account-type-filter">account type</FormLabel>
            <RadioGroup
                row
                aria-labelledby="account-type-filter-group-label"
                name="account-type-filter-group"
                value={influencerValue}
                onChange={handleInfluencerChange}
            >
                <FormControlLabel value="influencer" control={<Radio />} label="influencer" />
                <FormControlLabel value="official" control={<Radio />} label="official" />
                <FormControlLabel value="both" control={<Radio />} label="both" />
            </RadioGroup>
        </FormControl>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
            <TextField
                name='searchTerm'
                variant='outlined'
                required
                value={searchTerm}
                id='searchTerm'
                label='Search Twitter Users Followed'
                autoComplete='Search Term'
                onChange={handleSearchTermChange}
                helperText={searchTermError ? searchTermErrorMessage : null}
                error={searchTermError}
                inputProps={{ maxLength: 25 }}
                size="small"
            />

            <IconButton
                type="submit"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={() => searchSubmitted()}
            >
                <Search />
            </IconButton>
            <IconButton
                type="submit"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={() => clearSearch()}
            >
                <Clear />
            </IconButton>
        </Stack>
    </Stack>

  )
}

export default HomeFeedTwitterUserSearch