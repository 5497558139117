import React from 'react'
import alpha from './alpha.png'

const Header = () => {
    return ( 
        <nav 
            className='Header' 
            style={{
                display: 'flex',
                justifyContent: 'space-between', /*spacing of items in bar*/
                alignItems: 'center', /* positon of items in bar*/
                boxShadow: '0 5px 10px 0 grey', 
                borderRadius: '15px 15px',
                padding: '0px 30px',
                width: '95%', /* length of bar*/
                height: '80px', /* height of bar*/   
                position: 'fixed',
                top: '10px', /* positon top of bar*/
                left: '50%', /* positon of bar (middle screen)*/
                transform: 'translate(-50%)',
                backgroundColor: 'white',                    
            }}
        >
            <div className='Logo' >
                <img src={alpha} height={65} width={80} alt="Logo" />
            </div>
    
            <ul 
                className='nav-menu'
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '20px',
                    listStyleType: 'none', /*removes dot from list*/                        
                }}>
                <li>
                    <a href="/login" target="_blank" rel="noopener noreferrer">
                        <button
                            style={{
                                padding: '1rem 2rem', /* height width item*/
                                fontFamily: 'Arial',
                                whiteSpace: 'nowrap',
                                border: 'none',
                                fontSize: '1rem',
                                fontWeight: '110', /*thickness*/
                                cursor: 'pointer',
                                color: 'white',
                                backgroundColor: '#3f07bf',     
                            }}                         
                        >Sign In</button>
                    </a>
                </li>
        
                <li>
                    <a href="/register" target="_blank" rel="noopener noreferrer">
                        <button 
                            class= 'button button1'
                            style={{
                                padding: '1rem 2rem', /* height width item*/
                                fontFamily: 'Arial',
                                whiteSpace: 'nowrap',
                                border: 'none',
                                fontSize: '1rem',
                                fontWeight: '110', /*thickness*/
                                cursor: 'pointer',
                                color: 'white',
                                backgroundColor: '#3f07bf',     
                            }}    
                        >Sign Up</button>
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default Header;