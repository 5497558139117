import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconStethoscope, IconArrowUpCircle, IconArrowDownCircle } from '@tabler/icons';

const StableNetflow = () => {

    const theme = useTheme();

    const [tabValue,setTabValue] = useState('1')

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h3">About Stablecoin Netflow</Typography>
                <Box sx={{ width: '100%' }}>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="BitCoin Health - Miner Inflows">
                                <Tab icon={<IconBook />} value="1" />
                                <Tab icon={<IconSearch />} value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Typography variant="h4">
                                Definition
                            </Typography>
                            <Typography variant="body2" mt={1}>
                                Stablecoin Exchange Netflow is the difference between Stablecoin flowing into and out of the exchange. (Inflow - Outflow = Netflow)
                            </Typography>
                            <Typography variant="body2" mt={1}>
                                The mean value is the In/Outflow Total divided by the Transactions Count In/Outflow.
                            </Typography>
                        </TabPanel>
                        <TabPanel value="2">
                            <Typography variant="h4">
                                Interpretation
                            </Typography>
                            <Typography variant="h4" mt={1}>By Value Itself</Typography>
                            <Typography mt={1} variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                                <Typography variant="h5" sx={{fontWeight: 600, marginTop: '15px'}}>1) To buy coins - Bullish </Typography>
                                <Typography variant="body2" sx={{marginTop: '5px'}}>
                                    In the case of inflow to spot exchange, it is highly likely that investors are sending Stablecoins for buying coins.
                                    This reason of action mostly leads to the price rise which indicates a bullish sign.
                                </Typography>
                                <Typography variant="h5" sx={{fontWeight: 600, marginTop: '15px'}}>2) To trade in derivatives market - volatility risk</Typography>
                                <Typography variant="body2" sx={{marginTop: '5px'}}>
                                    The purpose for sending coins to derivative market wallets indicates that more trades will be happening on the derivative market. This could possibly lead to increased volatility risk as a result of increased trading activity, investors looking to take profits, and/or to rebalance to de-risk their investment portfolios. This type of action has difficulty in deciding the effect on price since coins in the derivative market could be used to open both long/short positions.
                                    Instead, it should be interpreted as increased volatility risk.
                                </Typography>
                            </Typography>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Grid>
        </Grid>
    )

}

export default StableNetflow;