import React from 'react'
import { Box } from '@mui/material'
import VIPAllAtMentioned from '../VIP/VIPAllAtMentioned'
import VIPAllHash from '../VIP/VIPAllHash'


const VIPTweetsSearch = ( {tweetsSearch, tweetSearchType, changeVIPTweetsAccountsTweetsTab, changeVIPTweetsSearchTab, changeVIPTweetsChartTab, changeVIPTweetsCoinTab, maxBoxHeight, divHeight=0, boxWidth=0} ) => {

    //console.log("top of VIPTweetsSearch:")
    //console.dir(tweetsSearch)
    //console.log("tweetsSearchType is:")
    //console.dir(tweetSearchType)
    //console.log("divHeight is: " + divHeight)
    //console.log("boxWidth is: " + boxWidth)
    const setAtMentionedForTweetsSearchTab = (searchText) => {
        //need to set search type to mentioned
        changeVIPTweetsSearchTab(searchText,1)
    }

    const setHashForTweetsSearchTab = (searchText) => {
        //need to set search type to hash
        changeVIPTweetsSearchTab(searchText,2)
    }

    const changeDateInChart = () => {
        //provides a date that can mark the tweet on a chart....send to chart tab?
    }     

    if (tweetSearchType === 1) {
        //this is a mention

        return (
            <Box sx={{maxHeight: maxBoxHeight, overflowY: 'auto'}}>
                <VIPAllAtMentioned 
                    atMentionedTabValue={tweetsSearch}
                    setCoinInChart={changeVIPTweetsChartTab}
                    changeAtFollowedTab={changeVIPTweetsAccountsTweetsTab}
                    changeAtMentionedTab={setAtMentionedForTweetsSearchTab}
                    changeHashTab={setHashForTweetsSearchTab}
                    changeCoinInTab={changeVIPTweetsCoinTab}
                    changeDateInChart={changeDateInChart}
                    showOfficialTweets={changeVIPTweetsAccountsTweetsTab}
                    divHeight={divHeight}
                    boxWidth={boxWidth}
                /> 
            </Box>  
        )     
    } else if (tweetSearchType === 2) {
        //this is a hash

        return (
            <Box sx={{maxHeight: maxBoxHeight, overflowY: 'auto'}}>
                <VIPAllHash 
                    hashTabValue={tweetsSearch}
                    setCoinInChart={changeVIPTweetsChartTab}
                    changeAtFollowedTab={changeVIPTweetsAccountsTweetsTab}
                    changeAtMentionedTab={setAtMentionedForTweetsSearchTab}
                    changeHashTab={setHashForTweetsSearchTab}
                    changeCoinInTab={changeVIPTweetsCoinTab}
                    changeDateInChart={changeDateInChart}
                    showOfficialTweets={changeVIPTweetsAccountsTweetsTab}
                    divHeight={divHeight}
                    boxWidth={boxWidth}                    
                />
            </Box>
        )
    } else {
        return null
    }
}

export default VIPTweetsSearch