import React from 'react'
import { Grid, Typography, Box, Button, IconButton, Tooltip, useMediaQuery } from '@mui/material'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { RiVipCrownFill } from 'react-icons/ri'

const CurrenciesListHeader = ({sortResults, sortByColumn, sortingBy, currentTimeFrame, visibleColumns }) => {

  const theme = useTheme()
  
  const medScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"))

  /*
    currency name
    currency symbol
    price
    change (1h, 1d, 7d, 30)
    1d volume
    mkt cap
    small chart
    <img class="" width="135" height="50" alt="bitcoin (BTC) 7d chart" data-src="https://www.coingecko.com/coins/1/sparkline" data-srcset="https://www.coingecko.com/coins/1/sparkline 1x" src="https://www.coingecko.com/coins/1/sparkline" srcset="https://www.coingecko.com/coins/1/sparkline 1x">

  useEffect(() => {

  }, [])
  */
  

  //console.log("top of CurrenciesListHeader")
  let currentTimeFrameDisplay = '24hr '
  switch(currentTimeFrame) {
    case 7:
      currentTimeFrameDisplay = '1 Wk '
      break
    case 14:
      currentTimeFrameDisplay = '2 Wks '
      break
    case 30:
      currentTimeFrameDisplay = '1 Mn '
      break
    default:
      break;
  }

  const showArrow = ( column ) => {
    if (column === sortByColumn) {
      //this is the column used to sort....which arrow to display
      switch(column) {
        case 'name':
          if (sortingBy === 'name-alphabetical') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'symbol':
          if (sortingBy === 'symbol-alphabetical') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'price':
          if (sortingBy === 'price-lowest') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'volume':
          if (sortingBy === 'volume-highest') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'rank':
          if (sortingBy === 'rank-highest') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case '24h_low':
          if (sortingBy === '24h_low_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case '24h_high':
          if (sortingBy === '24h_high_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'market_cap':
          if (sortingBy === 'market_cap_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'btc_dif':
          if (sortingBy === 'btc_dif_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'eth_dif':
          if (sortingBy === 'eth_dif_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'social_rank':
          if (sortingBy === 'social_rank_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'total_social_engagment':
          if (sortingBy === 'total_social_engagement_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'min_5_price_change':
          if (sortingBy === 'min_5_price_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'min_15_price_change':
          if (sortingBy === 'min_15_price_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'hour_1_price_change':
          if (sortingBy === 'hour_1_price_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'hour_4_price_change':
          if (sortingBy === 'hour_4_price_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'week_1_price_change':
          if (sortingBy === 'week_1_price_changee_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'week_2_price_change':
          if (sortingBy === 'week_2_price_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'daily_influencer_post_count':
          if (sortingBy === 'daily_influencer_post_count_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'day_1_follower_change':
          if (sortingBy === 'day_1_follower_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'month_1_follower_change':
          if (sortingBy === 'month_1_follower_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'year_1_follower_change':
          if (sortingBy === 'year_1_follower_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'day_1_post_volume_change':
          if (sortingBy === 'day_1_post_volume_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'day_7_post_volume_change':
          if (sortingBy === 'day_7_post_volume_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'month_1_post_volume_change':
          if (sortingBy === 'month_1_post_volume_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'month_3_post_volume_change':
          if (sortingBy === 'month_3_post_volume_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'year_1_post_volume_change':
          if (sortingBy === 'year_1_post_volume_change_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'year_1_post_volume_average':
          if (sortingBy === 'year_1_post_volume_average_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'month_1_post_volume_average':
          if (sortingBy === 'month_1_post_volume_average_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        case 'month_3_post_volume_average':
          if (sortingBy === 'month_3_post_volume_average_asc') {
            return <ArrowDownward sx={{fontSize: '.8rem'}}/>
          } else {
            return <ArrowUpward sx={{fontSize: '.8rem'}}/>
          }
        default:
          break;
      }
    } else {
      //this not the sorted column....do nothing
      return null
    }
  }

  if (medScreenOrSmaller) {
    return (
        <Grid container
          maxWidth="false"
          sx={{borderBottom: '1px solid', borderColor: theme.palette.grey[100], padding: '1px'}}
        >
          <Grid item xs={3}>Coin</Grid>
          <Grid item xs={4}>Price</Grid>
          <Grid item xs={3}>24hVol</Grid>
          <Grid item xs={1}>Rnk</Grid>
          <Grid item xs={1}>
            <Tooltip title="following in VIP?" >
              <IconButton sx={{position: 'relative', top: '-6px', left: '5px'}}>
                <RiVipCrownFill color={theme.palette.primary.main} size={15} />
              </IconButton>
            </Tooltip>
          </Grid>          
        </Grid>
    )
  } else {

    return (
      <Grid container
        maxWidth="false"
        sx={{borderBottom: '1px solid', borderColor: theme.palette.grey[100], padding: '1px'}}
      >
        <Grid item xs={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{width: '100%'}}
          >
            <Button
              variant="text"
              size="small"
              sx={{ fontWeight: sortByColumn === 'name' ? 800 : 400 }}
              onClick={()=> sortResults( sortingBy === 'name-alphabetical' ? 'name-alphabetical-reverse' : 'name-alphabetical' )}
            >Coin {showArrow('name')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={.5}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{width: '100%'}}
          >
            <Button
              variant="text"
              size="small"
              sx={{ fontWeight: sortByColumn === 'symbol' ? 800 : 400 }}
              onClick={()=> sortResults( sortingBy === 'symbol-alphabetical' ? 'symbol-alphabetical-reverse' : 'symbol-alphabetical' )}
            >
              Symbol {showArrow('symbol')}
            </Button>
          </Box>
        </Grid>
        {
          visibleColumns['Current Price'] && (
                <Grid item xs={1.75}>
                  <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                  >
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === 'price' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === 'price-highest' ? 'price-lowest' : 'price-highest' )}
                    >Price Now/{currentTimeFrameDisplay} Change {showArrow('price')}
                    </Button>
                  </Box>
                </Grid>
            )
        }
        {
          visibleColumns['24h High'] && (
                <Grid item xs={.75}>
                  <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                  >
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === '24h_high' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === '24h_high' ? '24h_high_asc' : '24h_high' )}
                    >
                      24h High
                      {showArrow('24h_high')}
                    </Button>
                  </Box>
                </Grid>
            )
        }
        {
          visibleColumns['24h Low'] && (
                <Grid item xs={.75}>
                  <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                  >
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === '24h_low' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === '24h_low' ? '24h_low_asc' : '24h_low' )}
                    >
                      24h Low
                      {showArrow('24h_low')}
                    </Button>
                  </Box>
                </Grid>
            )
        }
        {
          visibleColumns['24h Price Chart'] && (
                <Grid item xs={1}>
                  <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                  >
                    <Typography sx={{position: 'relative', marginTop: '4px', color: theme.palette.primary.dark}}>{currentTimeFrameDisplay} Price Chart</Typography>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['24h Volume'] && (
                <Grid item xs={1}>
                  <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                  >
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === 'volume' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === 'volume-highest' ? 'volume-lowest' : 'volume-highest' )}
                    >
                      1d Vol {showArrow('volume')}
                    </Button>
                  </Box>
                </Grid>
            )
        }

        {/*
        <Grid item xs={1.75}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          <Button
            variant="text"
            size="small"
          >
            {currentTimeFrameDisplay} Volume Chart
          </Button>

          </Box>
        </Grid>
    */}
        {
          visibleColumns['Market Cap'] && (
                <Grid item xs={1}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === 'market_cap' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === 'market_cap' ? 'market_cap_asc' : 'market_cap' )}
                    >
                      Mkt Cap
                      {showArrow('market_cap')}
                    </Button>

                  </Box>
                </Grid>
            )
        }
        {
          visibleColumns['BTC Dif'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === 'btc_dif' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === 'btc_dif' ? 'btc_dif_asc' : 'btc_dif' )}
                    >
                      % diff btc
                      {showArrow('btc_dif')}
                    </Button>

                  </Box>
                </Grid>
            )
        }
        {
          visibleColumns['ETH Dif'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === 'eth_dif' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === 'eth_dif' ? 'eth_dif_asc' : 'eth_dif' )}
                    >
                      % diff eth
                      {showArrow('eth_dif')}
                    </Button>
                  </Box>
                </Grid>
            )
        }

        {
          visibleColumns['Market Cap Rank'] && (
                <Grid item xs={.5}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === 'rank' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === 'rank-highest' ? 'rank-lowest' : 'rank-highest' )}
                    >Rank {showArrow('rank')}</Button>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['5 Minute Price Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === 'min_5_price_change' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === 'min_5_price_change' ? 'min_5_price_change_asc' : 'min_5_price_change' )}
                    >
                      5m Price Change
                      {showArrow('min_5_price_change')}
                    </Button>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['15 Minute Price Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === 'min_15_price_change' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === 'min_15_price_change' ? 'min_15_price_change_asc' : 'min_15_price_change' )}
                    >
                      15m Change
                      {showArrow('min_15_price_change')}
                    </Button>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['Social Rank'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontWeight: sortByColumn === 'social_rank' ? 800 : 400 }}
                        onClick={()=> sortResults( sortingBy === 'social_rank' ? 'social_rank_asc' : 'social_rank' )}
                    >
                      Social Rank
                      {showArrow('social_rank')}
                    </Button>

                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['Total Social Engagement'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="Total Social Engagement">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'total_social_engagement' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'total_social_engagement' ? 'total_social_engagement_asc' : 'total_social_engagement' )}
                      >
                        TSE
                        {showArrow('total_social_engagement')}
                      </Button>
                    </Tooltip>

                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['1 Hour Price Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="1h Price Change">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'hour_1_price_change' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'hour_1_price_change' ? 'hour_1_price_change_asc' : 'hour_1_price_change' )}
                      >
                        1h
                        {showArrow('hour_1_price_change')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['4 Hour Price Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="4h Price Change">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'hour_4_price_change' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'hour_4_price_change' ? 'hour_4_price_change_asc' : 'hour_4_price_change' )}
                      >
                        4h
                        {showArrow('hour_4_price_change')}
                      </Button>
                    </Tooltip>

                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['1 Week Price Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="1w Price Change">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'week_1_price_change' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'week_1_price_change' ? 'week_1_price_change_asc' : 'week_1_price_change' )}
                      >
                        1w
                        {showArrow('week_1_price_change')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['2 Week Price Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="2w Price">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'week_2_price_change' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'week_2_price_change' ? 'week_2_price_change_asc' : 'week_2_price_change' )}
                      >
                        2w
                        {showArrow('week_2_price_change')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['Daily Influencer Posts'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="Daily Influencer Posts">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'daily_influencer_post_count' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'daily_influencer_post_count' ? 'daily_influencer_post_count_asc' : 'daily_influencer_post_count' )}
                      >
                        DIP
                        {showArrow('daily_influencer_post_count')}
                      </Button>
                    </Tooltip>


                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['24 Hour Follower Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="24h Follower Change">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'day_1_follower_change' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'day_1_follower_change' ? 'day_1_follower_change_asc' : 'day_1_follower_change' )}
                      >
                        24h FC
                        {showArrow('day_1_follower_change')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['1 Month Follower Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="1m Follower Change">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'month_1_follower_change' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'month_1_follower_change' ? 'month_1_follower_change_asc' : 'month_1_follower_change' )}
                      >
                        1m FC
                        {showArrow('month_1_follower_change')}
                      </Button>
                    </Tooltip>

                  </Box>
                </Grid>
            )
        }
        {/*{*/}
        {/*    visibleColumns['1 Year Follower Change'] && (*/}
        {/*        <Grid item xs={.75}>*/}
        {/*          <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>*/}
        {/*            <Button*/}
        {/*                variant="text"*/}
        {/*                size="small"*/}
        {/*                sx={{ fontWeight: sortByColumn === 'year_1_follower_change' ? 800 : 400 }}*/}
        {/*                onClick={()=> sortResults( sortingBy === 'year_1_follower_change' ? 'year_1_follower_change_asc' : 'year_1_follower_change' )}*/}
        {/*            >*/}
        {/*              <Typography variant='button' color={theme.palette.primary.main} sx={{marginTop: '2px'}}>1y Follower Change</Typography>*/}
        {/*              {showArrow('year_1_follower_change')}*/}
        {/*            </Button>*/}
        {/*          </Box>*/}
        {/*        </Grid>*/}
        {/*    )*/}
        {/*}*/}
        {
            visibleColumns['24 Hour Post Volume Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="24h Post Volume Change">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'day_1_post_volume_change' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'day_1_post_volume_change' ? 'day_1_post_volume_change_asc' : 'day_1_post_volume_change' )}
                      >
                        24h PVC
                        {showArrow('day_1_post_volume_change')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['1 Week Post Volume Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="1w Post Volume Change">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'day_7_post_volume_change' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'day_7_post_volume_change' ? 'day_7_post_volume_change_asc' : 'day_7_post_volume_change' )}
                      >
                        1w PVC
                        {showArrow('day_7_post_volume_change')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['1 Month Post Volume Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="1m Post Volume Change">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'month_1_post_volume_change' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'month_1_post_volume_change' ? 'month_1_post_volume_change_asc' : 'month_1_post_volume_change' )}
                      >
                        1m PVC
                        {showArrow('month_1_post_volume_change')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['3 Month Post Volume Change'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="3m Post Volume Change">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'month_3_post_volume_change' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'month_3_post_volume_change' ? 'month_3_post_volume_change_asc' : 'month_3_post_volume_change' )}
                      >
                        3m PVC
                        {showArrow('month_3_post_volume_change')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
            )
        }
        {/*{*/}
        {/*    visibleColumns['1 Year Post Volume Change'] && (*/}
        {/*        <Grid item xs={.75}>*/}
        {/*          <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>*/}
        {/*            <Button*/}
        {/*                variant="text"*/}
        {/*                size="small"*/}
        {/*                sx={{ fontWeight: sortByColumn === 'year_1_post_volume_change' ? 800 : 400 }}*/}
        {/*                onClick={()=> sortResults( sortingBy === 'year_1_post_volume_change' ? 'year_1_post_volume_change_asc' : 'year_1_post_volume_change' )}*/}
        {/*            >*/}
        {/*              <Typography variant='button' color={theme.palette.primary.main} sx={{marginTop: '2px'}}>3m Post Volume Change</Typography>*/}
        {/*              {showArrow('year_1_post_volume_change')}*/}
        {/*            </Button>*/}

        {/*          </Box>*/}
        {/*        </Grid>*/}
        {/*    )*/}
        {/*}*/}
        {/*{*/}
        {/*    visibleColumns['1 Year Post Volume Average'] && (*/}
        {/*        <Grid item xs={.75}>*/}
        {/*          <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>*/}
        {/*            <Button*/}
        {/*                variant="text"*/}
        {/*                size="small"*/}
        {/*                sx={{ fontWeight: sortByColumn === 'year_1_post_volume_average' ? 800 : 400 }}*/}
        {/*                onClick={()=> sortResults( sortingBy === 'year_1_post_volume_average' ? 'year_1_post_volume_average_asc' : 'year_1_post_volume_average' )}*/}
        {/*            >*/}
        {/*              <Typography variant='button' color={theme.palette.primary.main} sx={{marginTop: '2px'}}>1y Post Volume Average</Typography>*/}
        {/*              {showArrow('year_1_post_volume_average')}*/}
        {/*            </Button>*/}

        {/*          </Box>*/}
        {/*        </Grid>*/}
        {/*    )*/}
        {/*}*/}
        {
            visibleColumns['1 Month Post Volume Average'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="1m Post Volume Average">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'month_1_post_volume_average' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'month_1_post_volume_average' ? 'month_1_post_volume_average_asc' : 'month_1_post_volume_average' )}
                      >
                        1m PVA
                        {showArrow('month_1_post_volume_average')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
            )
        }
        {
            visibleColumns['3 Month Post Volume Average'] && (
                <Grid item xs={.75}>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '100%'}}>
                    <Tooltip title="3m Post Volume Average">
                      <Button
                          variant="text"
                          size="small"
                          sx={{ fontWeight: sortByColumn === 'month_3_post_volume_average' ? 800 : 400 }}
                          onClick={()=> sortResults( sortingBy === 'month_3_post_volume_average' ? 'month_3_post_volume_average_asc' : 'month_3_post_volume_average' )}
                      >
                        3m PVA
                        {showArrow('month_3_post_volume_average')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
            )
        }
        <Grid item xs={1}>
        <Tooltip title="following in VIP?" ><IconButton sx={{position: 'relative', left: '-5px',}}><RiVipCrownFill color={theme.palette.primary.main} size={15} /></IconButton></Tooltip>
        </Grid>
        
        {/*<Grid item xs={.5}>
          <IconButton size="small" sx={{}}>
            <AiFillBell color={theme.palette.primary.main} sx={{fontSize: '1.0rem'}}  />
          </IconButton>
        </Grid>  */}    
      </Grid>
    )
  }
}

export default CurrenciesListHeader