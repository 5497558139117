import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import parse, { domToReact } from 'html-react-parser';

const CoinDetailsDescription = ( {currencyDetails} ) => {
  const theme = useTheme();

  //console.log("top of CoinDetailsDescription")
  //console.dir(currencyDetails)

  const parseOptions = {
    replace: ({ name, attribs, children }) => {
      if (name === 'a' && attribs.href) {
        return <a target="_blank" rel="noreferrer" style={{cursor: 'pointer', color: theme.palette.primary.main}} href={attribs.href}>{domToReact(children)}</a>;
      }
    }
  };

  var parsedDescription = ''
  if (currencyDetails.description !== null) parsedDescription = parse(currencyDetails.description, parseOptions)

  return (
    <Box sx={{marginTop: '15px', marginLeft: '10px', marginRight: '10px'}}>

      <Typography variant="caption" >{parsedDescription}</Typography>
    </Box>
  )
}

export default CoinDetailsDescription