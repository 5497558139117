import React, { useState } from 'react'
import { Popover, Typography, Button, Grow, Box, Stack } from '@mui/material'
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { ArrowRight, Image } from "@mui/icons-material";


const OverviewTutorial = ( {overviewTutorialStep, changePopover, userObject }) => {

    //setting defaults of welcome

    //const whichComponent = () => {
    console.log("top of whichComponent and overviewTutorialStep is:" + overviewTutorialStep)
    switch (overviewTutorialStep) {
        case 8: //tweets
            changePopover( 'anchorEl', 0, 0,  'tweets', 'tweets', 'bottom','center','top','center')
            return (
                <Box sx={{width: '800px', minHeight: '500px'}}>
                    <Stack direction="column">
                        <Typography sx={{textAlign: 'center', pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>Tweets</Typography>
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2}} fontSize={16} color={'#fff'}>
                            Manage custom twitter feeds. Select twitter users to view posts from. You have access to 3 custom feeds.
                        </Typography>
                        <img style={{width: 800, height: 450, padding: 30, borderRadius: 50}} src={require('../../assets/images/tweets_tutorial.png')} />
                        <Stack direction={'row'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Typography sx={{textAlign: 'center', }}>Research</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>VIP</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Portfolio</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Currencies</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center',}}>Feed</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center'}}>Health</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center',color: '#1D9BF0', fontWeight: 'bold'}}>Tweets</Typography>
                        </Stack>
                    </Stack>
                </Box>
            )
        case 10: //influencer
            changePopover( 'anchorEl', 0, 0,  'influencer', 'influencer', 'bottom','center','top','center')
            return (
                <Box sx={{width: '800px', minHeight: '500px'}}>
                    <Stack direction="column">
                        <Typography sx={{textAlign: 'center', pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>Influencer</Typography>
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2}} fontSize={16} color={'#fff'}>
                            Track posts from influencers involved in the community of your favorite coins.
                        </Typography>
                        <img style={{width: 800, height: 450, padding: 30, borderRadius: 50}} src={require('../../assets/images/influencer_tutorial.png')} />
                        <Stack direction={'row'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Typography sx={{textAlign: 'center', }}>Research</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>VIP</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Portfolio</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Currencies</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center',}}>Feed</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center'}}>Health</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center'}}>Tweets</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center',}}>Dashboard</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', color: '#1D9BF0', fontWeight: 'bold'}}>Influencer</Typography>
                        </Stack>
                    </Stack>
                </Box>
            )
        case 9: //dashboard
            changePopover( 'anchorEl', 0, 0,  'dashboard', 'dashboard', 'bottom','center','top','center')
            return (
                <Box sx={{width: '800px', minHeight: '500px'}}>
                    <Stack direction="column">
                        <Typography sx={{textAlign: 'center', pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>Dashboard</Typography>
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2}} fontSize={16} color={'#fff'}>
                            Manage your crypto analysis workflow with the dashboard.
                        </Typography>
                        <img style={{width: 800, height: 450, padding: 30, borderRadius: 50}} src={require('../../assets/images/dashboard_tutorial.png')} />
                        <Stack direction={'row'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Typography sx={{textAlign: 'center', }}>Research</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>VIP</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Portfolio</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Currencies</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center',}}>Feed</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center'}}>Health</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center'}}>Tweets</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', color: '#1D9BF0', fontWeight: 'bold'}}>Dashboard</Typography>
                        </Stack>
                    </Stack>
                </Box>
            )
        case 11: //welcome
            changePopover( 'anchorPosition', (window.innerWidth/2 -150), (window.innerHeight/2 - 150), 'none', 'welcome', '','','','')
            return (
                <Box sx={{width: '400px', minHeight: '150px', }}>
                    <Stack direction="column" justifyContent={'space-between'} alignItems={'center'}>
                        <Typography  sx={{textAlign: 'center', mt: 3}} fontSize={16} color={'#fff'}>Get all of this on your desktop or mobile device!</Typography>
                        <Stack direction={'row'} justifyContent={'center'} sx={{mt: 2}}>
                            <Button sx={{width: 60, height: 60, p: 1, bgcolor: '#fff', borderRadius: 30}} onClick={() => alert('Google Play Store')}>
                                <AndroidIcon fontSize={'large'} sx={{color: '#a4c639'}}/>
                            </Button>
                            <Button sx={{p:1, bgcolor: '#fff', borderRadius: 30}} onClick={() => alert('Apple App Store')}>
                                <AppleIcon fontSize={'large'} sx={{color: '#A2AAAD'}} />
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            )
        case 4: //search form
            changePopover( 'anchorEl', 0, 0,  'currencies', 'currencies', 'bottom','center','top','center')
            return (
                <Box sx={{width: '800px', minHeight: '500px'}}>
                    <Stack direction="column">
                        <Typography sx={{textAlign: 'center', pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>Currencies</Typography>
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2}} fontSize={16} color={'#fff'}>
                            Here you'll find all of the cryptocurrencies that we monitor. Click on a currency to see more details about it.
                        </Typography>
                        <Typography sx={{textAlign: 'center', pt: 2}} fontSize={16} color={'#fff'}>
                            Set unlimited price alerts for your favorite coins.
                        </Typography>
                        <img style={{width: 800, height: 450, padding: 30, borderRadius: 50}} src={require('../../assets/images/currencies_tutorial_2.PNG')} />
                        <Stack direction={'row'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Typography sx={{textAlign: 'center', }}>Research</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>VIP</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Portfolio</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', color: '#1D9BF0', fontWeight: 'bold' }}>Currencies</Typography>
                        </Stack>
                    </Stack>
                </Box>
            )
        case 5: //event results
            changePopover( 'anchorEl', 0, 0, 'watchlists', 'watchlists', 'bottom','center','top','center')
            return (
                <Box sx={{width: '800px', minHeight: '500px'}}>
                    <Stack direction="column">
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>Watchlists</Typography>
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2}} fontSize={16} color={'#fff'}>
                            Here you'll be able to create your own lists of coins to monitor. 
                        </Typography>
                        <img style={{width: 800, height: 425, padding: 30, borderRadius: 50}} src={require('../../assets/images/watchlist_tutorial.PNG')} />
                        <Stack direction={'row'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Typography sx={{textAlign: 'center', }}>Research</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>VIP</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Portfolio</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Currencies</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', color: '#1D9BF0', fontWeight: 'bold'}}>Watchlists</Typography>
                        </Stack>
                    </Stack>
                </Box>
            )
        case 6: //market data results
            changePopover( 'anchorEl', 0, 0, 'feed', 'feed', 'bottom','center','top','center')
            return (
                <Box sx={{width: '800px', minHeight: '500px'}}>
                    <Stack direction="column">
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>Feed</Typography>
                        <Typography  sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2}} fontSize={16} color={'#fff'}>See a personalized feed of influencer posts related to your favorite coins.</Typography>
                        <img style={{width: 800, height: 425, padding: 30, borderRadius: 50}} src={require('../../assets/images/feed_tutorial.PNG')} />
                        <Stack direction={'row'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Typography sx={{textAlign: 'center', }}>Research</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>VIP</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Portfolio</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Currencies</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center'}}>Watchlists</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', color: '#1D9BF0', fontWeight: 'bold'}}>Feed</Typography>
                        </Stack>
                    </Stack>
                </Box>
            )
        case 7: //chart data results
            changePopover( 'anchorEl', 0, 0, 'health', 'health', 'bottom', 'center', 'top', 'center')
            return (
                <Box sx={{width: '800px', minHeight: '500px'}}>
                    <Stack direction="column">
                        <Typography  sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>Health</Typography>
                        <Typography  sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2}} fontSize={16} color={'#fff'}>Receive relevant BTC metrics and a synopsis of Fed events that may impact crypto.</Typography>
                        <img style={{width: 800, height: 425, padding: 30, borderRadius: 50}} src={require('../../assets/images/health_tutorial.PNG')} />
                        <Stack direction={'row'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Typography sx={{textAlign: 'center', }}>Research</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>VIP</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Portfolio</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>Currencies</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center',}}>Feed</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', color: '#1D9BF0', fontWeight: 'bold'}}>Health</Typography>
                        </Stack>
                    </Stack>
                </Box>
            )
        case 1: //coin tweet results
            changePopover( 'anchorEl', 0, 0, 'research', 'research', 'bottom','center','top','center')
            return (
                <Box sx={{width: '800px', minHeight: '500px'}}>
                    <Stack direction="column">
                        <Typography sx={{textAlign: 'center', fontSize: 18, paddingLeft: 2, paddingRight: 2, pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>This Overview Tutorial will cover the 7 main features to help multiply your portfolio.</Typography>
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>Research</Typography>
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2}} fontSize={16} color={'#fff'}>Discover upcoming major events such as mainnet upgrades, staking opportunities, and new listings. </Typography>
                        <img style={{width: 800, height: 425, padding: 30, borderRadius: 50}} src={require('../../assets/images/researcher_tutorial.PNG')} />
                        <Stack direction={'row'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Typography sx={{textAlign: 'center', color: '#1D9BF0', fontWeight: 'bold' }}>Research</Typography>
                        </Stack>
                    </Stack>
                </Box>
            )
        case 2: //more filters
            changePopover( 'anchorEl', 0, 0, 'vip', 'vip', 'bottom','center','top','center')
            return (
                <Box sx={{width: '800px', minHeight: '500px'}}>
                    <Stack direction="column">
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>VIP</Typography>
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2}} fontSize={16} color={'#fff'}>Receive trade calls curated from throughout the internet and vetted by our researchers.</Typography>
                        <img style={{width: 800, height: 425, padding: 30, borderRadius: 50}} src={require('../../assets/images/vip_tutorial.PNG')} />
                        <Stack direction={'row'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Typography sx={{textAlign: 'center', }}>Research</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', color: '#1D9BF0', fontWeight: 'bold'}}>VIP</Typography>
                        </Stack>
                    </Stack>
                </Box>
            )
        case 3: //tutorial end
            changePopover( 'anchorEl', 0, 0, 'portfolio', 'portfolio', 'bottom','center','top','center')
            return (
                <Box sx={{width: '800px', minHeight: '500px'}}>
                    <Stack direction="column">
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2, fontWeight: 'bold'}} fontSize={16} color={'#fff'}>Portfolio</Typography>
                        <Typography sx={{textAlign: 'center', paddingLeft: 2, paddingRight: 2, pt: 2}} fontSize={16} color={'#fff'}>Track your portfolio and create multiple wallets</Typography>
                        <img style={{width: 800, height: 425, padding: 30, borderRadius: 50}} src={require('../../assets/images/portfolio_tutorial.PNG')} />
                        <Stack direction={'row'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Typography sx={{textAlign: 'center', }}>Research</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', }}>VIP</Typography>
                            <ArrowRight />
                            <Typography sx={{textAlign: 'center', color: '#1D9BF0', fontWeight: 'bold'}}>Portfolio</Typography>
                        </Stack>
                    </Stack>
                </Box>
            )
        default: {
            console.log("whichComponent default????????????")
            return null
        }
    }
    //}
    /*
    return (

        <Popover
            open={true}
            id={popoverID}
            anchorReference={popoverAnchorReference}
            anchorPosition={{top: anchorPositionX, left: anchorPositionY}}
            anchorEl={popoverAnchorEl}

        >
            <Stack direction="column">
            {whichComponent()}

            </Stack>

        </Popover>

    )
    */
}
export default OverviewTutorial