import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Grid } from '@mui/material'

import VIPMainTabsNotificationPostBody from './VIPMainTabsNotificationPostBody'

const VIPMainTabsNotificationPost = ( {notificationItem, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, showOfficialTweets, notificationType } ) => {

  console.log("top of VIPMainTabsNotificationPost")
  console.dir(notificationItem)
  console.log("notificationType is:")
  console.dir(notificationType)

  const theme = useTheme()

  if (notificationItem !== null) {
    let changePercent = (notificationItem.price_percent * 100).toFixed(2)
    var coinNotificationText = "We've noticed a " + changePercent + "% price change in coin " + notificationItem.coin + ". Details below:"

  }

  if (notificationItem.hasOwnProperty("tweet_id") && notificationItem.tweet_id !== null) {
    let changePercent = (notificationItem.price_percent * 100).toFixed(2)
    var postNotificationText = "We've noticed a " + changePercent + "% price change in coin " + notificationItem.coin + ". Details possibly due to a post.  See details below:"
  }

  let trimmedStartTime = notificationItem.time_start.split("+")[0]
  console.log("trimmedStartTime is:")
  console.dir(trimmedStartTime)
  var splitStartTime = trimmedStartTime.split("T")
  console.log("splitStartTime is:")
  console.dir(splitStartTime)
  let trimmedEndTime = notificationItem.time_end.split("+")[0]
  var splitEndTime = trimmedEndTime.split("T")

  return (
    <Grid container
      sx={{
        paddingTop: '10px',
        /*'&:hover': {backgroundColor: theme.palette.dark[800]}*/
        backgroundColor: theme.palette.background.default
      }}
    >
      { notificationItem !== null ?
        <VIPMainTabsNotificationPostBody
          key={"coinNotificationBody"+notificationItem.id}
          changeDateInChart={changeDateInChart}
          setCoinInChart={setCoinInChart}
          changeCoinInTab={changeCoinInTab}
          changeAtFollowedTab={changeAtFollowedTab}
          changeHashTab={changeHashTab}
          changeAtMentionedTab={changeAtMentionedTab}
          notificationDetails={notificationItem}
          notificationText={coinNotificationText}
          //startTime={notificationItem.time_start}
          startTime={splitStartTime}
          //endTime={notificationItem.time_end}
          endTime={splitEndTime}
          priceStart={notificationItem.price_start}
          priceEnd={notificationItem.price_end}
          pricePercent={notificationItem.price_percent}
          volumeStart={notificationItem.volume_start}
          volumeEnd={notificationItem.volume_end}
          volumePercent={notificationItem.volume_percent}
          showOfficialTweets={showOfficialTweets}
          notificationType={notificationType}
        />
        : null }
      { notificationItem.hasOwnProperty("tweet_id") && notificationItem.tweet_id !== null ?
        <VIPMainTabsNotificationPostBody
          key={"postNotificationBody"+notificationItem.tweet_id}
          changeDateInChart={changeDateInChart}
          setCoinInChart={setCoinInChart}
          changeCoinInTab={changeCoinInTab}
          changeAtFollowedTab={changeAtFollowedTab}
          changeHashTab={changeHashTab}
          changeAtMentionedTab={changeAtMentionedTab}
          notificationDetails={notificationItem}
          notificationText={postNotificationText}
          //startTime={notificationItem.time_start}
          startTime={splitStartTime}
          //endTime={notificationItem.time_end}
          endTime={splitEndTime}
          priceStart={notificationItem.price_start}
          priceEnd={notificationItem.price_end}
          pricePercent={notificationItem.price_percent}
          volumeStart={notificationItem.volume_start}
          volumeEnd={notificationItem.volume_end}
          volumePercent={notificationItem.volume_percent} 
          showOfficialTweets={showOfficialTweets}
          notificationType={notificationType}
        />
        : null }


    </Grid>
  )
}

export default VIPMainTabsNotificationPost