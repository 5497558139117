import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Grid, Typography, Stack, Box, Avatar, Button } from '@mui/material'
import { AddBox, Exposure, IndeterminateCheckBox, Twitter, Star, Person, FavoriteBorder, Repeat } from '@mui/icons-material'
import { IconCoin } from '@tabler/icons'
import { DateTime } from 'luxon'
import reactStringReplace from 'react-string-replace'
import { communitySize } from "../../utility/objHelper"

import ResearchTwitterResultItemImages from '../Research3/ResearchTwitterResultItemImages'

const CustomFeedTwitterUserTweetsItem = ({ twitterItem }) => {
    //console.log("top of HomeFeedTwitterUserTweetsItem")
    const theme = useTheme()
  
    //format time
    let trimmedTime = twitterItem.created.split("+")[0]
    let splitTime = trimmedTime.split("T")

    let quoteTrimmedTime = null
    let quoteSplitTime = null
    if (twitterItem.quote_created !== null) {
        quoteTrimmedTime = twitterItem.quote_created.split("+")[0]
        quoteSplitTime = quoteTrimmedTime.split("T")
    }

    let retweetPercentage = ((twitterItem.retweets / twitterItem.favorites) * 100).toFixed(2)
  
    //display polarity (positive/negative)
    const polarityDisplay = (passedDirection, passedCount) => {
      if (passedCount === 0) return null
      if (passedDirection === 1) {
        return [ <AddBox sx={{color: theme.palette.primary.main, fontSize: "0.8rem"}}/>,  polarityDisplay(passedDirection, (passedCount-1) ) ]
      } else {
        return [ <IndeterminateCheckBox sx={{color: theme.palette.secondary.main, fontSize: "0.8rem"}}/>,  polarityDisplay(passedDirection, (passedCount-1) ) ]
      }
    }
    let direction = 0
    let count = 0
    switch(true) {
      case parseFloat(twitterItem.polarity) >= .7:
          direction = 1
          count = 3
        break
      case parseFloat(twitterItem.polarity) >= .4:
        direction = 1
        count = 2
        break
      case parseFloat(twitterItem.polarity) >= .1:
        direction = 1
        count = 1
        break
      case parseFloat(twitterItem.polarity) <= -.1:
        direction = -1
        count = 1
        break
      case parseFloat(twitterItem.polarity) <= -.4:
        direction = -1
        count = 2
        break
      case parseFloat(twitterItem.polarity) <= -.7:
        direction = -1
        count = 3
        break
      default:
        //leave default values
      break
    }
  
    //display efficacy (how strong match to purpose)
    const efficacyDisplay = (passedCount) => {
      if (passedCount === 0) return null
      return [ <Star sx={{color: theme.palette.primary.main, fontSize: "1.0rem"}}/>,  efficacyDisplay((passedCount-1)) ]
  
    }
    let efficacyCount = 0
    switch(true) {
      case parseFloat(twitterItem.subjectivity) >= .7:
          efficacyCount = 3
        break
      case parseFloat(twitterItem.subjectivity) >= .4:
        efficacyCount = 2
        break
      case parseFloat(twitterItem.subjectivity) >= .1:
        efficacyCount = 1
        break
      default:
        //leave default values
      break
    }
  
    const displayAvatar = () => {
      if (twitterItem.twitter_user_avatar_url === '') {
        return <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.0rem", color: theme.palette.primary.main}} />
      } else {
        return <Avatar alt={twitterItem.twitter_display_name} src={twitterItem.twitter_user_avatar_url} sx={{ width: 30, height: 30, marginRight: '1px' }}/>
      }
  
    }
  
    const displayCoinsConcerning = () => {
      if (twitterItem.coins && twitterItem.coins.length) return twitterItem.coins.join(', ')
      else {
          return "none"
      }
    }
  
    const units = [
      'year',
      'month',
      'week',
      'day',
      'hour',
      'minute',
      'second',
    ];
  
    const timeAgo = () => {
      let dateTime = DateTime.fromISO(twitterItem.created)
      const diff = dateTime.diffNow().shiftTo(...units);
      const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';
  
      const relativeFormatter = new Intl.RelativeTimeFormat('en', {
        numeric: 'auto',
      });
      return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
    };

    var twitterText = twitterItem.text

    //replace link with preview
    //replace @
    twitterText = reactStringReplace(twitterText, /@(\w+)/g, (match, i) => (
      <Button size="small" key={"homefeedtwitterpostatted"+ match + i} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>@{match}</Button>
    ))

    //replace #
    twitterText = reactStringReplace(twitterText, /#(\w+)/g, (match, i) => (
      <Button size="small" key={"homefeedtwitterposthash"+ match + i} style={{cursor: 'pointer', color: theme.palette.warning.main}}>#{match}</Button>
    ))

    twitterText = reactStringReplace(twitterText, /(https:\/\/\S+)/g, (match, i) => (
      <a key={"homefeedtwitterpostlink"+match + i} href={match} target="_blank" rel="noreferrer" style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match}</a>
    ))

    return (
        <Box sx={{width: '95%' }}>
        <Grid container
            sx={{
            paddingTop: '10px',
            
            '&:hover': {backgroundColor: theme.palette.dark[800]}
            }}
            spacing={1}
            key={twitterItem.id}
        >
            <Grid item xs={1} sx={{paddingTop: '5px', paddingLeft: '5px'}}>
                <Stack direction="column" sx={{marginBottom: '10px'}} display="flex" justifyContent="space-between" alignItems="center">
                {displayAvatar()}
                </Stack>
            </Grid>

            <Grid item xs={11} sx={{padding: "8px", }}>
                <Box sx={{borderRadius: '5px',  padding: "5px", margin: '0px',  }}>
                    <Grid container >
                      <Grid item xs={12}>
                          <Stack direction="row" sx={{display: "flex", alignItems: "center", justifyContent: 'space-between',}}>
                            <Stack direction="row">
                                <Twitter sx={{marginRight: "5px", marginTop: '2px', fontSize: "1.1rem", color: theme.palette.primary.main}} />
                                <Typography variant="subtitle1" sx={{fontWeight: 600, fontSize: ".9rem", color: theme.palette.grey[500]}}>{twitterItem.display_name}</Typography>
                                <Typography variant="subtitle1" sx={{fontWeight: 400, color: theme.palette.grey[800], marginLeft: '10px'}}>@{twitterItem.twitter_display_name}</Typography>
                                <Typography variant="subtitle1" sx={{fontWeight: 400, color: theme.palette.grey[800], marginLeft: '10px'}}>{timeAgo()}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                                <Person sx={{fontSize: '1rem'}} color={theme.palette.grey[500]} />
                                <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '3px', marginTop: '3px'}}>{communitySize(twitterItem)}</Typography>
                            </Stack>
                          </Stack>
                      </Grid>
                      <Grid item xs={12}>
                          <Typography variant="subtitle1" sx={{color: theme.palette.grey[500], fontSize: '.8rem'}}>{twitterText}</Typography>
                      </Grid>
                      {
                        twitterItem.quote_tweet_id && (
                          <Grid item xs={12}>
                            <Box sx={{marginLeft: 5, marginTop: 1, border: '1px solid #2b2b2b', borderRadius: 1, p: 1}}>
                                <Stack direction='row' display='flex' alignItems='center' justifyContent="space-between">
                                    <Stack direction={'row'} display="flex" alignItems='center' justifyContent="flex-start" >
                                        {
                                            twitterItem.quote_avatar_url ?
                                                (
                                                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/${twitterItem.quote_display_name}/status/${twitterItem.quote_tweet_id}`}>
                                                        <Avatar alt={twitterItem.quote_avatar_url} src={twitterItem.quote_avatar_url} sx={{ width: 42, height: 42, marginRight: '3px' }}/>
                                                    </a>
                                                )
                                                :
                                                (
                                                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/${twitterItem.quote_display_name}/status/${twitterItem.quote_tweet_id}`}>
                                                        <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
                                                    </a>
                                                )
                                        }
                                        <Typography variant="subtitle1" sx={{fontWeight: 600, color: theme.palette.grey[500]}}>{twitterItem.quote_display_name}</Typography>
                                    </Stack>
                                    {quoteSplitTime !== null ? 
                                        <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-end" sx={{cursor: 'pointer'}}>
                                            <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>created:</Typography>
                                            <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[800], paddingLeft: '5px'}}>{quoteSplitTime[0]} {quoteSplitTime[1].slice(0,-1)} UTC</Typography>
                                        </Stack>   
                                    : null }                             
                                </Stack>
                                <Stack direction={'row'}>
                                    <Typography>{twitterItem.quote_text}</Typography>
                                </Stack>
                            </Box>
                          </Grid>
                        )
                      }                          
                      <Grid item xs={12}>
                          <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{marginTop: '8px'}}>
                          <Stack direction="column" alignItems="center">
                              <Repeat sx={{fontWeight: 400, color: theme.palette.grey[800], fontSize: "1.2rem", transform: "rotate(90deg)"}} />
                              <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '5px', marginTop: '3px'}}>{twitterItem.retweets}</Typography>
                          </Stack>
                          <Stack direction="column" alignItems="center">
                              <FavoriteBorder sx={{fontWeight: 400, color: theme.palette.grey[800], fontSize: "1.2rem"}} />
                              <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '5px', marginTop: '2px'}}>{twitterItem.favorites}</Typography>
                          </Stack>
                          <Stack direction="column" sx={{marginTop: "0px"}} alignItems="center">
                              <Stack direction={'row'}>
                                  <Repeat sx={{fontWeight: 400, color: theme.palette.grey[400], fontSize: "1.2rem", transform: "rotate(90deg)"}} />
                                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[800], marginTop: '2px'}}>%</Typography>
                              </Stack>
                              <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], marginLeft: '5px', marginTop: '2px'}}>{retweetPercentage}</Typography>
                          </Stack>
                          <Stack direction="column" sx={{marginTop: "0px"}} alignItems="center">
                              <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[800], marginRight: '3px', marginTop: '2px'}}>social engagement</Typography>
                              <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], marginLeft: '5px', marginTop: '2px'}}>{twitterItem.social_engagement ? twitterItem.social_engagement : 0}</Typography>
                          </Stack>
                          <Stack direction="column" sx={{marginTop: "0px"}} alignItems="center">
                              <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[500], marginRight: '3px', marginTop: '2px'}}>polarity</Typography>
                              {direction === 0 ? <Exposure sx={{fontWeight: 600, color: theme.palette.grey[500], fontSize: "0.9rem"}}/> : polarityDisplay(direction, count)}
                          </Stack>
                          <Stack direction="column" sx={{marginTop: "0px"}} alignItems="center">
                              <Typography variant="caption" sx={{color: theme.palette.grey[500], marginRight: '5px'}}>relevance</Typography>
                              {direction === 0 ? <Typography variant="caption" sx={{color: theme.palette.grey[500],}}>none</Typography>  : efficacyDisplay(efficacyCount)}
                          </Stack>
                          </Stack>
                      </Grid>
                      <Grid item xs={12}>
                          <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{marginTop: '5px'}}>
                            <Stack direction="row"display="flex" justifyContent="space-around" alignItems="center" >
                                <IconCoin strokeWidth={1} size={15} color={theme.palette.grey[500]}  />
                                <Typography variant="subtitle1" sx={{fontWeight: 600, color: theme.palette.grey[800], marginLeft: '5px'}}>{displayCoinsConcerning()}</Typography>
                            </Stack>
                            <Stack direction="row">
                                <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>created:</Typography>
                                <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[800], paddingLeft: '5px'}}>{splitTime[0]} {splitTime[1].slice(0,-1)}</Typography>
                            </Stack>
                          </Stack>
                      </Grid>
                    
                      { twitterItem.attachments && twitterItem.attachments.length > 0 ?
                        <Grid item xs={12}>
                          <Box sx={{width: '100%', paddingLeft: '50px', paddingRight: '50px', align: 'center'}}>
                            <ResearchTwitterResultItemImages images={twitterItem.attachments} tweetID={twitterItem.tweet_id} />
                          </Box>
                        </Grid> : null
                      }      
                      {
                        twitterItem.quote_tweet_id && (
                          <Grid item xs={12}>
                            <Box sx={{marginLeft: 5, marginTop: 1, border: '1px solid #2b2b2b', borderRadius: 1, p: 1}}>
                              <Stack direction={'row'}>
                                {
                                  twitterItem.quote_avatar_url ?
                                    (
                                      <a target="_blank" rel="noreferrer" href={`https://twitter.com/${twitterItem.quote_display_name}/status/${twitterItem.quote_tweet_id}`}>
                                          <Avatar alt={twitterItem.quote_avatar_url} src={twitterItem.quote_avatar_url} sx={{ width: 42, height: 42, marginRight: '3px' }}/>
                                      </a>
                                    )
                                    :
                                    (
                                      <a target="_blank" rel="noreferrer" href={`https://twitter.com/${twitterItem.quote_display_name}/status/${twitterItem.quote_tweet_id}`}>
                                          <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
                                      </a>
                                    )
                                }
                                <Typography variant="subtitle1" sx={{fontWeight: 600, color: theme.palette.grey[500]}}>{twitterItem.quote_display_name}</Typography>
                              </Stack>
                              <Stack direction={'row'}>
                                <Typography>{twitterItem.quote_text}</Typography>
                              </Stack>
                              { twitterItem.quote_attachments && twitterItem.quote_attachments.length > 0 ?
                                <Stack display="flex" direction="column" alignItems="center" sx={{maxWwidth: '100%'}}>
                                  <ResearchTwitterResultItemImages images={twitterItem.quote_attachments} tweetID={twitterItem.quote_tweet_id} />
                                </Stack> : null
                              }
                            </Box>
                          </Grid>
                        )
                      }     

                    </Grid>
                </Box>
            </Grid>
        </Grid>
        </Box>
    )
}

export default CustomFeedTwitterUserTweetsItem