import React from 'react'
import { Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const ResearchTutorial9End = () => {

    const theme = useTheme()

    return (
        <Stack direction="column" alignItems="center" >
            <Typography variant="h2" sx={{paddingTop: '20px', color: theme.palette.warning.dark}}>That's The End Of This Quick Tutorial</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>You can click the previous button to review any pane of the tutorial</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>When you are finished with the tutorial you can click exit tutorial at any time</Typography>
        </Stack>         
    )
}

export default ResearchTutorial9End