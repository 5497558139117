import React from 'react'
import { Typography, Stack, Avatar, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const HealthDisplayFearAndGreed = ( { ruleThemAll } ) => {

    const theme = useTheme()

    var useThisFearAndGreedData = ruleThemAll[0]
  
    if (ruleThemAll.length === 0) {
        return (
            <Box sx={{width: '100%'}} >
            <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.primary.dark}}>Fear And Greed</Typography>
            <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>no data</Typography>
            </Box>
        )
    } else {
  
      //console.log("useThisFearAndGreedData is:")
      //console.dir(useThisFearAndGreedData)
      //console.dir(ruleThemAll)
  
        var greedAndFearColor = '#b00c0c'
        var greedAndFearDesc = 'EXTREME FEAR'
        if ((useThisFearAndGreedData.healthData.fear_and_greed >= 20) && (useThisFearAndGreedData.healthData.fear_and_greed < 40)) {
            greedAndFearColor = '#c7620a'
            greedAndFearDesc = 'FEARFUL'
        } else if ((useThisFearAndGreedData.healthData.fear_and_greed >= 40) && (useThisFearAndGreedData.healthData.fear_and_greed < 60)){
            greedAndFearColor = '#c4c70a'
            greedAndFearDesc = 'NEUTRAL'
        } else if ((useThisFearAndGreedData.healthData.fear_and_greed >= 60) && (useThisFearAndGreedData.healthData.fear_and_greed < 80)) {
            greedAndFearColor = '#c7ab0a'
            greedAndFearDesc = 'GREEDY'
        } else if (useThisFearAndGreedData.healthData.fear_and_greed >= 80) {
            greedAndFearColor = '#0ac729'
            greedAndFearDesc = 'EXTREME GREED'
        }
    
        return (
            <Stack
                direction="column"
                sx={{backgroundColor: theme.palette.dark.dark, margin: '0px', padding: '5px', paddingTop: '0px', borderRadius: '5px', minHeight: '7vh', cursor: 'pointer'}}
            >
                <Stack
                    direction="row"
                    display="flex"
                    justifyContent='space-between'
                    alignItems="center"
                >
                <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>Fear And Greed</Typography>
                </Stack>
                <Box sx={{width: '99%', backgroundColor: theme.palette.dark.dark, paddingTop: '8px', paddingBottom: '5px'}} >
                <Stack direction="column" alignItems="center">
                    <Avatar sx={{width: 50, height: 50, bgcolor: greedAndFearColor, display: 'flex', justifyContent: 'center', fontSize: '.9rem', color: theme.palette.grey[100] }}>{useThisFearAndGreedData.healthData.fear_and_greed.toFixed(2)}</Avatar>
                    <Typography variant="Button" sx={{marginLeft: '5px', fontWeight: 700, color: greedAndFearColor}}>{greedAndFearDesc}</Typography>
                </Stack>
                </Box>
            </Stack>
        )
    }
}

export default HealthDisplayFearAndGreed