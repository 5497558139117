import React, { useEffect, useState } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import fetchData from '../../utility/endpointFetch'
import Chart from "react-apexcharts"

const ChartDisplayByCoinMarket = ( {chartDays, pageSize, chartInterval, coinToChartID, openPriceAlert, chartHeight } ) => {

    const theme = useTheme()

    const [ isLoading, setIsLoading ] = useState(true)
    const [ mixedChartOptions, setMixedChartOptions ] = useState()
    const [ mixedChartSeries, setMixedChartSeries ] = useState()
    const [ chartError,setChartError] = useState(false)

    const chartBaseCurrencySymbol = '$ '
    const chartBaseCurrency = 'USD'

    useEffect(() => {

        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            var dateAgo = new Date(Date.now() - (chartDays * 24 * 60 * 60 * 1000))
            //console.log("timestamp used to pull marketchart data: " + dateAgo.toISOString())
    
            var urlString = `api/v1/coin/marketchart/?coin=${coinToChartID}&interval=${chartInterval}&timestamp__gt=${dateAgo.toISOString()}&page_size=${pageSize}&page=1`
            const chartResponse = await fetchData(urlString, "could not receive coinmarket data", fetchParams).promise
            console.log("response getting marketchart data from backend for: " + coinToChartID)
            console.log(urlString)
            console.log(chartResponse)
    
            //find min max prices
            var minMaxPrices = chartResponse.results.map( (data) => {
              return data.price
            })
    
            var chartMax = minMaxPrices.reduce((a, b) => { return Math.max(a, b) });
            var chartMin = minMaxPrices.reduce((a, b) => { return Math.min(a, b) });
    
            //if using coingecko
            //var firstValue = chartResponse.results[0].price
            //var lastValue = chartResponse.results[chartResponse.results.length - 1].price
    
            //if using our data
            var firstValue = chartResponse.results[chartResponse.results.length - 1].price
            var lastValue = chartResponse.results[0].price
            //console.log("firstValue(firstPrice) in CoinDetailsChart is: " + firstValue)
            //console.log("lastValue(lastPrice) is CoinDetailsChart is: " + lastValue)
            //setFirstPrice(firstValue)
            //setLastPrice(lastValue)
    
            var chartColor = '#109e10'
            var legendPosition = 'left'
            if (firstValue > lastValue) {
              //price went down over chart
              chartColor = '#9e1010'
              legendPosition = 'right'
            }
    
            var chartPriceswithDateArrTimestamp = chartResponse.results.map( (data) => {
              return [ data.timestamp , data.price ]
            })
    
            var volumeDataArrWithTimeStamp = chartResponse.results.map( (data) => {
              return [ data.timestamp , data.volume ]
            })
    
            var dateArr = chartResponse.results.map( (data) => {
              var date = new Date(data.timestamp)
              var year = date.getFullYear()
              var month = date.getMonth()
              var day = date.getDate()
    
              return day+'-'+month+'-'+year
            })
    
            //there is no market cap data in current result set from backend
            //var marketDataArrWithTimestamp = response.market_caps.map( (data) => {
            //  return [ data[0], data[1] ]
            //})
    
            //find min max market cap for chart
            //var minMaxMarketCap = response.market_caps.map( (data) => {
            //  return data[1]
            //})
    
            //var chartMaxMarketCap = minMaxMarketCap.reduce((a, b) => { return Math.max(a, b) });
            //var chartMinMarketCap = minMaxMarketCap.reduce((a, b) => { return Math.min(a, b) });
    
            //var chartMaxMarketCapUsed = (3 * chartMaxMarketCap)
    
            //find min max volume for chart
            var minMaxVolumes = chartResponse.results.map( (data) => {
              return data.volume
            })
    
            var chartMaxVolume = minMaxVolumes.reduce((a, b) => { return Math.max(a, b) });
            var chartMinVolume = minMaxVolumes.reduce((a, b) => { return Math.min(a, b) });
    
            var chartMaxVolumeUsed = (2 * chartMaxVolume)
    
            setMixedChartSeries([
              {
                name: "Price",
                type: "area",
                data: chartPriceswithDateArrTimestamp
              },
              {
                name: "Volume",
                type: "column",
                data: volumeDataArrWithTimeStamp,
              },
              //{
              //  name: "Market Cap",
              //  type: "column",
              //  data: marketDataArrWithTimestamp,
              //}
            ])
    
            setMixedChartOptions({
              chart: {
                type: 'area',
                id: "price-volume-chart",
                group: "crypto2",
                toolbar: {
                  show: false, 
                },
                background: theme.palette.background.default,
                events: {
                    click: function(event, chartContext, config) {
                        //console.log("top of chart click event (event, chartContext, config):")
                        //console.dir(event)
                        //console.dir(chartContext)
                        //console.dir(config)
                        //console.log("default price alert set to: " + config.globals.series[0][config.dataPointIndex])
                        if (config.globals.series[0][config.dataPointIndex] !== undefined) {
                            openPriceAlert(config.globals.series[0][config.dataPointIndex])
                            //setPriceAlert(config.globals.series[0][config.dataPointIndex])
                            //setPriceAlertOpen(true)
                        }
                    }
                }
              },
              colors: [chartColor, '#10689e', '#eba834'],
              stroke: {
                show: true,
                width: [ 2, 0, 0 ]
              },
              dataLabels: {
                enabled: false,
              },
              labels: dateArr,
              xaxis: {
                type: 'datetime',
                labels: {
                  show: true,
                  datetimeUTC: false,
                  formatter: function(value, timestamp, opts) {
                    return String(new Date(value).toLocaleDateString()).concat(' ').concat((chartInterval === 'daily') ? '' : new Date(value).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}))
                  }
                }
              },
              yaxis: [
                {
                  seriesName: "Price",
                  opposite: false,
                  show: true,
                  min: chartMin,
                  max: chartMax,
                  forceNiceScale: true,
                  labels: {
                    show: true,
                    formatter: (value) => {
                      const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 6
                      });
                      return formatter.format(value);
                    },
                    style: {
                      colors: [ '#bf3932']
                    }
                  },      
                  tooltip: {
                    shared: true,
                    intersect: false,
                    x: {
                      show: false,
                      format: 'dd MMM yyyy',
    
                    },
                    marker: {
                      show: true,
                    },
                  },                      
                },
                {
                  seriesName: "Volume",
                  opposite: true,
                  axisTicks: {
                    "show": true,
                  },
                  show: false,
                  min: chartMinVolume,
                  max: chartMaxVolumeUsed,
                },
                //{
                //  seriesName: "Market Cap",
                //  opposite: true,
                //  show: false,
                //  min: chartMinMarketCap,
                //  max: chartMaxMarketCapUsed,
                //}
              ],
              fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "vertical",
                    shadeIntensity: 1,
                    gradientToColors: [theme.palette.background.default],
                    inverseColors: false,
                    opacityFrom: 0.9,
                    opacityTo: 0.5,
                },
              },
              tooltip: {
                theme: 'dark',
                x: {
                  show: false,
                  format: 'dd MMM yyyy',
                },
                y: {
                  formatter: function (value, index) {
                    if (value === index.series[0][index.dataPointIndex]) {
                      //this is a price
                      let fixedDecimal = 2
                      if (value < .00001) 
                        fixedDecimal = 7
                      else if (value < .0001)
                        fixedDecimal = 6
                      else if (value < .001)
                        fixedDecimal = 5
                      else if (value < .01)
                        fixedDecimal = 4      
                      else if (value < .1)
                        fixedDecimal = 4                                     
                      return chartBaseCurrencySymbol + " " + (value).toFixed(fixedDecimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " " + chartBaseCurrency
                    } else if (value === index.series[1][index.dataPointIndex]) {
                      //this is volume...just remove after .
                      return Math.round(value)
                    } else if (value === index.series[2][index.dataPointIndex]) {
                      return chartBaseCurrencySymbol + " " + (value).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " " + chartBaseCurrency
                    } else {
                      // not sure what it is...return unchanged
                      return value
                    }
                  }
                },
              },
              legend: {
                position: legendPosition,
                floating: true,
                offsetX: 10,
                offsetY: 10,
                labels: {
                  colors: theme.palette.grey[300]
                }
              }
            })
    
            setIsLoading(false)
          } catch (error) {
            console.log("something is broke getting marketchart: ", error)
            setChartError(true)
          }
        })()
    
    }, [chartDays, chartInterval, chartBaseCurrency])

    if (isLoading) return <CircularProgress />
    if (chartError) {
      return (
        <Box><Typography>There was a problem loading data for {coinToChartID}</Typography></Box>
      )
    } else {
      return (
          <Box>
              <Chart
                options={mixedChartOptions}
                series={mixedChartSeries}
                type="area"
                height={chartHeight}
              />            
          </Box>
      )
    }
}

export default ChartDisplayByCoinMarket