import { Button, Divider, Stack, Typography } from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CustomFeedTabFollowSearchItem = ({item, index, removeUserFromWhiteList, addUserToWhiteListHandler, getTwitterUserTweets}) => {
    const theme = useTheme();
    const columnOneHandles = useSelector((state) => state.tweetsScreen.columnOneHandles)
    const columnTwoHandles = useSelector((state) => state.tweetsScreen.columnTwoHandles)
    const columnThreeHandles = useSelector((state) => state.tweetsScreen.columnThreeHandles)

    const [toggled1, setToggled1] = useState(false);
    const [toggled2, setToggled2] = useState(false);
    const [toggled3, setToggled3] = useState(false);

    useEffect(() => {
        setToggled1(columnOneHandles.includes(item.username))
        setToggled2(columnTwoHandles.includes(item.username))
        setToggled3(columnThreeHandles.includes(item.username))
    }, [columnOneHandles,columnTwoHandles,columnThreeHandles]);

    return (
        <React.Fragment>
            <Stack direction={'column'} sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between', p: 1, }}>
                <Stack onClick={() => getTwitterUserTweets(item.username)} direction="column"  sx={{ display: "flex", alignItems: "center", justifyContent: 'center',p: 1, width: '75%'}}>
                    {/*{*/}
                    {/*    item.avatar_url && (*/}
                    {/*        <img key={item.avatar_url+String(index)} src={item.avatar_url} alt={item.avatar_url} style={{width: 25, height: 25, borderRadius: 10}} />*/}
                    {/*    )*/}
                    {/*}*/}
                    <Typography key={item.username+String(index)} fontSize={30 - item.username.length} sx={{cursor: 'pointer', textAlign: 'center'}}>@{item.username}</Typography>
                </Stack>
                <Typography color={theme.palette.grey[500]}>
                    Add to Custom Feed Whitelist
                </Typography>
                <Stack direction={'row'} spacing={1} justifyContent={'space-evenly'}>
                    <Button
                        variant={toggled1 ? 'contained' : 'outlined'}
                        onClick={toggled1 ?
                                () => {
                                    setToggled1(!toggled1);
                                    removeUserFromWhiteList(item.username, 'One');
                                }
                                :
                                () => {
                                    setToggled1(!toggled1);
                                    addUserToWhiteListHandler(item, 'One');
                                }
                        }>
                        <Typography color={theme.palette.grey[500]}>
                            Feed 1
                        </Typography>
                    </Button>
                    <Button
                        variant={toggled2 ? 'contained' : 'outlined'}
                        onClick={toggled2 ?
                                () => {
                                    setToggled2(!toggled2);
                                    removeUserFromWhiteList(item.username, 'Two');
                                }
                                :
                                () => {
                                    setToggled2(!toggled2);
                                    addUserToWhiteListHandler(item, 'Two');
                                }
                        }
                    >
                        <Typography color={theme.palette.grey[500]}>
                            Feed 2
                        </Typography>
                    </Button>
                    <Button
                        variant={toggled3 ? 'contained' : 'outlined'}
                        onClick={toggled3 ?
                                () => {
                                    setToggled3(!toggled3);
                                    removeUserFromWhiteList(item.username, 'Three');
                                }
                                :
                                () => {
                                    setToggled3(!toggled3);
                                    addUserToWhiteListHandler(item, 'Three');
                                }
                        }
                    >
                        <Typography color={theme.palette.grey[500]}>
                            Feed 3
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
            <Divider variant={'fullWidth'} sx={{flex: 1}} color={theme.palette.primary.main} />
        </React.Fragment>
    )
}

export default CustomFeedTabFollowSearchItem;