import React, { useEffect, useState } from 'react'
import { Grid, Box, CircularProgress, Typography, Stack, Tab, Button, Pagination } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'

import ResearchBodyResultItem from './ResearchBodyResultItem'

import fetchData from '../../utility/endpointFetch'
import { useTheme } from '@mui/material/styles'
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from "@mui/icons-material/Close";

const test_results = {}

const ResearchBodyResults = ( {showCoinTweetsAndChart, coinTweetQueryString, stopInitial=0, divHeight=0, boxWidth=0}) => {
    console.log("top of ResearchBodyResults")
    //console.log("divHeight is: " + divHeight)
    //console.log("boxWidth is: ")
    //console.dir(boxWidth)
    console.log("coinTweetQueryString is:")
    console.dir(coinTweetQueryString)
    const theme = useTheme()

    const [isLoading,setIsLoading] = useState(true)

    const [coinTweetResults,setCoinTweetResults] = useState([])
    const [coinTweetResultsCount,setCoinTweetResultsCount] = useState(0)
    const [coinTweetResultsPage,setCoinTweetResultsPage] = useState(1)
    const [coinTweetResultsPageCount,setCoinTweetResultsPageCount] = useState(0)
    
    const [coinTweetResearchResponsePageNext,setCoinTweetResearchResponsePageNext] = useState(1)

    const [coinTweetResultsNext,setCoinTweetResultsNext] = useState([])
    const [coinTweetResultsNextCount,setCoinTweetResultsNextCount] = useState(0)
    const [coinTweetResultsNextPage,setCoinTweetResultsNextPage] = useState(1)
    const [coinTweetResultsNextPageCount,setCoinTweetResultsNextPageCount] = useState(0)

    const [researchBodyResultsTabValue,setResearchBodyResultsTabValue] = useState('1')
    const [dateSearch,setDateSearch] = useState(false)
    const [feedOrdering, setFeedOrdering] = useState({
        ascending: false,
        field: ''
    })
    const handleResearchBodyTabChange = (event, newValue) => {
        setResearchBodyResultsTabValue(newValue);
    }

    var currentDate = new Date()
    var currentMonth = currentDate.toLocaleString('default', { month: 'long' })
    currentDate.setMonth(currentDate.getMonth()+1)
    var nextMonth = currentDate.toLocaleString('default', { month: 'long' })

    

    useEffect(() => {
        console.log("top of useEffect where coinTweetQueryString, coinTweetResultsPage, coinTweetResultsPageNext change calling getCointTweetResults")
        console.log("coinTweetQueryString is:")
        console.dir(coinTweetQueryString)
        
        const getCoinTweetResults = () => {
            //set it false incase past search query had DATE and possibly this one does not
            console.log("-----------------------------------TOP OF getCoinTweetResults")
            setIsLoading(true)
            setDateSearch(false)
            var itemsPerResultPage = 50
            let ds = false;
            let queryStringArray = coinTweetQueryString.split('&')
            var checkedQueryStringNowArray = []
            var checkedQueryStringNextArray = []

            var containsETHorBTCinQuery = false
    
            queryStringArray.map(queryNameValuePair => {
                console.log("queryNameValuePair is: "+ queryNameValuePair)
                let currentPair = queryNameValuePair.split('=')
                console.log("currentPair[0] is: "+ currentPair[0] + " and currentPair[1] is: "+ currentPair[1])
                if (currentPair[0] === 'search' && currentPair[1].includes('DATE')) {
                    //this is the search string pair and string contains DATE....do a second search
                    console.log("SETTING dateSearch TO TRUE")
                    ds = true
                    setDateSearch(true)
                    //remove DATE and add currentMonth and nextMonth
                    let searchWithCurrent = currentPair[1].replace('DATE', currentMonth)
                    let searchWithNext = currentPair[1].replace('DATE', nextMonth)
                    checkedQueryStringNowArray.push(currentPair[0]+'='+searchWithCurrent)
                    checkedQueryStringNextArray.push(currentPair[0]+'='+searchWithNext)                
                } else if (currentPair[0] === 'category') {
                    itemsPerResultPage = 500
                    checkedQueryStringNowArray.push(queryNameValuePair)
                    checkedQueryStringNextArray.push(queryNameValuePair)                     
                } else {

                    if ( currentPair[0] === "btc_24h_dif__gte" || currentPair[0] === "btc_24h_dif__lte" || currentPair[0] === "eth_24h_dif__gte" || currentPair[0] === "eth_24h_dif__lte") {
                        containsETHorBTCinQuery = true
                    }

                    checkedQueryStringNowArray.push(queryNameValuePair)
                    checkedQueryStringNextArray.push(queryNameValuePair)
                }
            })
    
            //insert directly into researchsignal url
            var checkedQueryStringNow = checkedQueryStringNowArray.join('&')
            var checkedQueryStringNext = checkedQueryStringNextArray.join('&')
                    
            const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                'Content-Type': 'application/json'
                },
            }


            ;(async () => {
                try {
                    // old var coinTweetResponseURL = `api/v1/vip/researchsignal/?${checkedQueryStringNow}&limit=${itemsPerResultPage}&offset=${offset}`
                    var coinTweetResearchResponseURL = `api/v1/feed/alltweets/?${checkedQueryStringNow}&is_influencer=false&page_size=${itemsPerResultPage}&page=${coinTweetResultsPage}`
                    console.log("coinTweetResearchResponseURL is: ")
                    console.dir(coinTweetResearchResponseURL)
    
                    var coinTweetResearchResponse = await fetchData(coinTweetResearchResponseURL, "could not receive /feed/alltweets elk research results now", fetchParams).promise
                    console.log("coinTweetResearchResponse is: ")
                    console.dir(coinTweetResearchResponse)

                    if (containsETHorBTCinQuery) {
                        console.log("setting count to actual length of coinTweetResearchResponse.results instead or hardcoding to 500: " + coinTweetResearchResponse.results.length)
                        setCoinTweetResultsCount(coinTweetResearchResponse.results.length)
                    } else 
                        setCoinTweetResultsCount(coinTweetResearchResponse.count)
    
                    if (coinTweetResearchResponse.count > itemsPerResultPage) {
                        //set the next button to display
                        setCoinTweetResultsPageCount(Math.ceil(coinTweetResearchResponse.count/itemsPerResultPage))
                    } else {
                        setCoinTweetResultsPageCount(1)
                    }

                    console.log("stopInitial is: " + stopInitial)
                    if (coinTweetResearchResponse.count > 0 && stopInitial === 0) {
                        console.log("coinTweetResearchResponse.count is: " + coinTweetResearchResponse.count + " showCoinTweetsAndCharts called with:")
                        console.dir(coinTweetResearchResponse.results[0].coinscreener[0])
                        let firstCoin = coinTweetResearchResponse.results.find( item => item.coins !== null )
                        if (firstCoin !== null)
                            showCoinTweetsAndChart(firstCoin)
                        else //coins is null....set bitcoin
                            showCoinTweetsAndChart(null)                        
                    }
                    //need to get the first result and set it as the left chart and left column tweets
                    setCoinTweetResults(coinTweetResearchResponse.results)
                    

                    /*
                    setCoinTweetResultsCount(test_results.count)
    
                    if (test_results.count > itemsPerResultPage) {
                        //set the next button to display
                        setCoinTweetResultsPageCount(Math.ceil(test_results.count/itemsPerResultPage))
                    } else {
                        setCoinTweetResultsPageCount(1)
                    }

                    
                    setChartCoinName(coinDetails.coinscreener.coin)
                    setChartLabel(coinDetails.coinscreener.coin) //not sure why need this
                    setChartCoin(coinDetails.coinscreener.coin_symbol)
                    setTweetCoin(coinDetails.coinscreener.coin_symbol)
                    setChartCoinSymbol(coinDetails.coinscreener.coin_symbol)
                    

                    console.log("stopInitial is: " + stopInitial + " and test_results.count is: " + test_results.count)
                    if (test_results.count > 0 && stopInitial === 0) {
                        console.log("test_results.count is: " + test_results.count + " showCoinTweetsAndCharts called with:")
                        console.dir(test_results.results[0])
                        let firstCoin = test_results.results.find( item => item.coins !== null )
                        if (firstCoin !== null)
                            showCoinTweetsAndChart(firstCoin)
                        else //coins is null....set bitcoin
                            showCoinTweetsAndChart(null)
                    }
                    //need to get the first result and set it as the left chart and left column tweets
                    setCoinTweetResults(test_results.results)
                    */

                    if (ds) {
                        ;(async () => {
                            try {
                                
                                //old var coinTweetResponseURLNext = `api/v1/vip/researchsignal/?${checkedQueryStringNext}&limit=${itemsPerResultPage}&offset=${offset}`
                                var coinTweetResearchResponseURLNext = `api/v1/feed/alltweets/?${checkedQueryStringNext}&page_size=50&page=${coinTweetResearchResponsePageNext}`
                                const coinTweetResearchResponseNext = await fetchData(coinTweetResearchResponseURLNext, "could not receive tweetselk search results next", fetchParams).promise
                                console.log("coinTweetResearchResponseNext getting tweetselk search results")
                                console.log("coinTweetResearchResponseURLNext is: " + coinTweetResearchResponseURLNext)
                                console.dir(coinTweetResearchResponseNext)

                                if (containsETHorBTCinQuery) {
                                    console.log("setting count to actual length of coinTweetResearchResponseNext.results instead or hardcoding to 500:" + coinTweetResearchResponseNext.results.length)
                                    setCoinTweetResultsNextCount(coinTweetResearchResponseNext.results.length)
                                } else {
                                    setCoinTweetResultsNextCount(coinTweetResearchResponseNext.count)
                                }
            
                                if (coinTweetResearchResponseNext.count > itemsPerResultPage) {
                                    //set the next button to display
                                    setCoinTweetResultsNextPageCount(Math.ceil(coinTweetResearchResponseNext.count/itemsPerResultPage))
                                } else {
                                    setCoinTweetResultsNextPageCount(1)
                                }
            
                                setCoinTweetResultsNext(coinTweetResearchResponseNext.results)
    
                                setIsLoading(false)              
                                
                            } catch (error) {
                                console.log("could not receive researcher post search results next: ", error)
                                setIsLoading(false)
                            }
                        })() 
                    } else {
                        setIsLoading(false)
                    }                        
                    
                } catch (error) {
                    setCoinTweetResults([])
                    setCoinTweetResultsPageCount(0)
                    setCoinTweetResultsCount(0)

                    setCoinTweetResultsNext([])
                    setCoinTweetResultsNextCount(0)
                    setCoinTweetResultsNextPageCount(1)
                    console.log("could not receive researcher post search results now: ", error)
                    setIsLoading(false)
                }
            })()    

        }        
        getCoinTweetResults()

    }, [ coinTweetQueryString, coinTweetResultsPage, coinTweetResearchResponsePageNext, feedOrdering, currentMonth, nextMonth, showCoinTweetsAndChart, stopInitial])


    const changePage =  (event, page) => {
        //console.log("top of changePage")
        //console.dir(page)
        setCoinTweetResultsPage(page)
    }

    const changePageNext =  (event, page) => {
        //console.log("top of changePageNext")
        //console.dir(page)
        setCoinTweetResultsNextPage(page)
    }    

    const setOrdering = (field) => {
        if(feedOrdering.field === field){
            setFeedOrdering({
                ascending: !feedOrdering.ascending,
                field: field
            })
        }else{
            setFeedOrdering({
                ascending: false,
                field: field
            })
        }
    }

    const clearOrdering = () => {
        setFeedOrdering({
            ascending: false,
            field: ''
        })
    }

    const Sorting = () => {
        return (
            <Box>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{mr: 1}}
                    spacing={1}
                >
                    <Typography variant={'h4'} color={'white'}>
                        Sort Feeds by:
                    </Typography>
                    <Button
                        variant={feedOrdering.field === 'created' ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => setOrdering('created')}
                        sx={{
                            borderColor: theme.palette.primary[700],
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 25,
                            borderWidth: 1,
                        }}>
                        <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                            <Typography variant={'h5'} color={'white'}>
                                Created
                            </Typography>
                            {
                                feedOrdering.field === 'created' ?
                                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                        {
                                            feedOrdering.ascending ?
                                                <ExpandLessIcon color={'action'} />
                                                :
                                                <ExpandMoreIcon color={'action'} />
                                        }
                                    </Stack>
                                    :
                                    null
                            }
                        </Stack>
                    </Button>
                    <Button
                        variant={feedOrdering.field === 'coinscreener__total_social_engagement' ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => setOrdering('coinscreener__total_social_engagement')}
                        sx={{
                            borderColor: theme.palette.primary[700],
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 25,
                            borderWidth: 1,
                        }}>
                        <Stack direction={'row'} spacing={1}>
                            <Typography variant={'h5'} color={'white'}>
                                Social Engagement
                            </Typography>
                            {
                                feedOrdering.field === 'coinscreener__total_social_engagement' ?
                                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                        {
                                            feedOrdering.ascending ?
                                                <ExpandLessIcon color={'action'} />
                                                :
                                                <ExpandMoreIcon color={'action'} />
                                        }
                                    </Stack>
                                    :
                                    null
                            }
                        </Stack>
                    </Button>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() => clearOrdering()}
                        style={{borderRadius: 25, width: 30, height: 30}}
                    >
                        <CloseIcon color={'primary'} size={12}/>
                    </Button>
                </Stack>
            </Box>
        )
    }

    if (isLoading) return <CircularProgress />
    console.log("00000000000000000000000000000000000000000 just before return and coinTweetResults.length is: " + coinTweetResults.length)
    console.log("coinTweetResultsNext.length is: " + coinTweetResultsNext.length)
    
    if (coinTweetResults && (coinTweetResults.length > 0 || coinTweetResultsNext.length > 0) && dateSearch) {
        //this will need tabs as there is the current month and next month results
        return (
            <Box sx={{width: '100%', height: window.innerHeight - 420, overflow: 'auto', backgroundColor: theme.palette.background.default}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TabContext value={researchBodyResultsTabValue} >
                            <TabList onChange={handleResearchBodyTabChange} aria-label="" indicatorColor="secondary" textColor="primary" >
                                <Tab label={currentMonth+ " Tweets/Coin"} value="1" sx={{minWidth: '30px'}} />
                                <Tab label={nextMonth+ " Tweets/Coin"} value="2" sx={{minWidth: '30px'}} />
                            </TabList>
                            <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                                <Box sx={{width: '100%'}}>
                                    <Stack direction="column">
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <Typography display="inline" sx={{color: theme.palette.secondary.main, marginLeft: '10px', marginTop: '5px' }}>tweet/coin {currentMonth} results from filtered coins (total {coinTweetResultsCount}/page {coinTweetResultsPage})</Typography>
                                            <Sorting />
                                        </Stack>
                                        {coinTweetResults.map( (tweetCoin, index) => <ResearchBodyResultItem key={'researchbodytweetresultsitem'+tweetCoin.id+index}  coinTweetQueryString={coinTweetQueryString} showCoinTweetsAndChart={showCoinTweetsAndChart} tweetCoin={tweetCoin} divHeight={divHeight} boxWidth={boxWidth} />)}
                                        <Stack direction="row" display="flex" alignItems="center">
                                            <Typography variant="button" sx={{marginRight: '20px'}}>Results: {coinTweetResultsCount} </Typography>
                                            <Pagination sx={{marginTop: '10px'}} shape="rounded" count={coinTweetResultsPageCount} page={coinTweetResultsPage} onChange={changePage}/>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </TabPanel>
                            <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                                <Box sx={{width: '100%'}}>
                                    <Stack direction="column">
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <Typography display="inline" sx={{color: theme.palette.secondary.main, marginLeft: '10px', marginTop: '5px' }}>tweet/coin {nextMonth} results from filtered coins: (total {coinTweetResultsNextCount}/page {coinTweetResultsNextPage} ) </Typography>
                                            <Sorting/>
                                        </Stack>
                                        {coinTweetResultsNext.map( (tweetCoin, index) => <ResearchBodyResultItem key={'researchbodytweetresultsitemnext'+tweetCoin.id+index} coinTweetQueryString={coinTweetQueryString} showCoinTweetsAndChart={showCoinTweetsAndChart} tweetCoin={tweetCoin} divHeight={divHeight} boxWidth={boxWidth} />)}
                                        <Stack direction="row" display="flex" alignItems="center">
                                            <Typography variant="button" sx={{marginRight: '20px'}}>Results: {coinTweetResultsNextCount} </Typography>
                                            <Pagination sx={{marginTop: '10px'}} shape="rounded" count={coinTweetResultsNextPageCount} page={coinTweetResultsNextPage} onChange={changePageNext} />
                                        </Stack>                                        
                                    </Stack>
                                </Box>
                            </TabPanel>
                        </TabContext>

                    </Grid>  
                </Grid>

            </Box>
        )
    } else if (coinTweetResults && coinTweetResults.length > 0) {
        return (
            <Box sx={{width: '100%', height: window.innerHeight - 420, overflow: 'auto', backgroundColor: theme.palette.background.default}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Typography display="inline" sx={{color: theme.palette.secondary.main, marginLeft: '10px', marginTop: '5px' }}>tweet/coin results from filtered coins (total {coinTweetResultsCount} / page {coinTweetResultsPage})  </Typography>
                            <Sorting/>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        {/* put top link here */}
                        {coinTweetResults.map( (tweetCoin, index) => 
                            <ResearchBodyResultItem key={'researchbodytweetresultsitem'+tweetCoin.id+index} coinTweetQueryString={coinTweetQueryString} showCoinTweetsAndChart={showCoinTweetsAndChart} tweetCoin={tweetCoin} divHeight={divHeight} boxWidth={boxWidth} />
                        )}
                        <Stack direction="row" display="flex" alignItems="center">
                            <Typography variant="button" sx={{marginRight: '20px'}}>Results: {coinTweetResultsCount} </Typography>
                            <Pagination sx={{marginTop: '10px'}} shape="rounded" count={coinTweetResultsPageCount} page={coinTweetResultsPage} onChange={changePage}/>
                        </Stack>                        
                    </Grid>  
                                  
                </Grid>
            </Box>
        )
    } else {
        return (
            
            <Box sx={{width: '100%', height: window.innerHeight - 420, overflow: 'auto', backgroundColor: theme.palette.background.default, }}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography display="inline" sx={{color: theme.palette.secondary.main, marginLeft: '10px', marginTop: '10px'}}>tweet/coin results from filtered coins: (0)</Typography> 
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{justifyContent: 'center', marginLeft: '10px', marginTop: '15px'}}>
                            <Typography display="inline" sx={{color: theme.palette.primary.main}}>Use the search section to the right to find coins and display their associated tweets here</Typography>
                        </Box>
                    </Grid>
                </Grid>
                
            </Box>
            
        )
    }        
}

export default ResearchBodyResults