import React, { useState, useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  IconButton, Divider, Grid, Paper, FormLabel,
  Popover, CircularProgress, Typography, useMediaQuery, FormControl, Stack, FormGroup, Button, Box, Modal,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { CgSelectO } from 'react-icons/cg' 

// third-party
import fetchData from '../../utility/endpointFetch'

// project imports
import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'
import CustomFeedTabFollowSearch from './CustomFeedTabFollowSearch'
import CustomFeedFollowedListItem from './CustomFeedFollowedListItem'

// assets
import { IconSquareX } from '@tabler/icons';
import CustomFeedTwitterUserTweets from "./CustomFeedTwitterUserTweets";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { setColumnOneHandles, setColumnThreeHandles, setColumnTwoHandles } from "../../store/actions";

// ==============================|| white list manager Popover ||============================== //
// need to get the list of twitter users
// need to establish current whitelist
// left side will be twitter users to choose from...filtering out ones already in white list
// right side will be current white list

// onchange to add to white list one by one due to endpoint
// onchange to remove from white list.....one by one

//filters for twitter user list...
// only one built into endpoint is is_influencer

//seearch for twitter user list
// can only search through names...

//filters and search provided by endpoint are inadequate
// wants:
// filter/search by followers, coins associated, 

//possible...click on a user and get stats from the tweets from that user....ie coins mainly

//ugh....no ordering on the endpoint


const CustomFeedManager = ({column, refreshFeed, usedFrom='tweetdisplay' } ) => {
  //console.log("top of HomeFeedManager")
    
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  let followedList = []
  
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)
  const [userData, setUserData] = useState(null)
  const [isLoading,setIsLoading] = useState(true)
  const [currentlyFollowedTab, setCurrentlyFollowedTab] = useState(column)
  const columnOneHandles = useSelector((state) => state.tweetsScreen.columnOneHandles)
  const columnTwoHandles = useSelector((state) => state.tweetsScreen.columnTwoHandles)
  const columnThreeHandles = useSelector((state) => state.tweetsScreen.columnThreeHandles)
  const dispatch = useDispatch();
  const [isViewingTweets, setIsViewingTweets] = useState(false)   // is the far-right tab showing currently followed or the tweets window
  const [isLoadingTweets, setIsLoadingTweets] = useState(false)   // is the far-right tab showing currently followed or the tweets window
  const [twitterUserTweets,setTwitterUserTweets] = useState([])
  const [twitterUserTweetsCount,setTwitterUserTweetsCount] = useState(0)
  const [tweetsFrom,setTweetsFrom] = useState('')
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [strongTwitterUsers, setStrongTwitterUsers] = useState([]);

  const handleClose = (event) => {
    //console.log("top of handleClose")
    setAnchorEl(null)
    setOpen((prevOpen) => !prevOpen)
    const fetchFollowedParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
    ;(async () => {
      try {
        const response = await fetchData(`api/v1/users/userdata/`, "could not receive user data in home feed manager", fetchFollowedParams).promise
        setUserData(response)
        setStrongTwitterUsers(response.strong_twitter_users)
        dispatch(setColumnOneHandles(response['twitter_feed_one']))
        dispatch(setColumnTwoHandles(response['twitter_feed_two']))
        dispatch(setColumnThreeHandles(response['twitter_feed_three']))
        setIsLoading(false)
      } catch (error) {
        console.log("could not receive user data in home feed manager: ",error)
      }
    })()
    refreshFeed('One', columnOneHandles)
    refreshFeed('Two', columnTwoHandles)
    refreshFeed('Three', columnThreeHandles)
  };

  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    setIsLoading(true)
    //console.log("top of useEffect in HomeFeedManager to load followed")
    const fetchFollowedParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        const response = await fetchData(`api/v1/users/userdata/`, "could not receive user data in home feed manager", fetchFollowedParams).promise
        setUserData(response)
        setStrongTwitterUsers(response.strong_twitter_users)
        dispatch(setColumnOneHandles(response['twitter_feed_one']))
        dispatch(setColumnTwoHandles(response['twitter_feed_two']))
        dispatch(setColumnThreeHandles(response['twitter_feed_three']))
        setIsLoading(false)
      } catch (error) {
        console.log("could not receive user data in home feed manager: ",error)
      }
    })()
  }, [])

  const removeUserFromWhiteList = (twitterUserToRemove, col) => {
    let fl;
    switch(col){
      case "One":
        fl = columnOneHandles
        break;
      case "Two":
        fl = columnTwoHandles
        break;
      case "Three":
        fl = columnThreeHandles
        break;
      default:
        break;
    }
    if (fl.includes(twitterUserToRemove)) {
      const fetchParams = {
        method: 'DELETE',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'handle_list': [twitterUserToRemove],
        })
      }
      ;(async () => {
        try {
          let removeFromTwitterFeedURL = ''
          switch(currentlyFollowedTab){
            case "One":
              removeFromTwitterFeedURL = "api/v1/users/twitter_feed_update/?feed_index=1"
              break;
            case "Two":
              removeFromTwitterFeedURL = "api/v1/users/twitter_feed_update/?feed_index=2"
              break;
            case "Three":
              removeFromTwitterFeedURL = "api/v1/users/twitter_feed_update/?feed_index=3"
              break;
            default:
              break;
          }
          const response = await fetchData(removeFromTwitterFeedURL, "could not remove twitter user using twitter feed update", fetchParams).promise
          let newList = fl.filter(val => val !== newList);
          switch(col){
            case "One":
              dispatch(setColumnOneHandles(response['twitter_feed_one']))
              break;
            case "Two":
              dispatch(setColumnTwoHandles(response['twitter_feed_two']))
              break;
            case "Three":
              dispatch(setColumnThreeHandles(response['twitter_feed_three']))
              break;
            default:
              break;
          }

        } catch (error) {
          console.log("could not remove twitter user from white list: %s", error)
        }
      })() 
    } else {
      console.log("user not in followedList.  Do not need to remove: " + twitterUserToRemove)
    } 
  }

  const addUserToWhiteList = (userToAdd, col = column, event) => {
    //setAnchorEl(event.currentTarget)
      let currentFollowed;
      switch(col){
        case "One":
          currentFollowed = [...columnOneHandles];
          currentFollowed.push(userToAdd.username);
          dispatch(setColumnOneHandles(currentFollowed));
          break;
        case "Two":
          currentFollowed = [...columnTwoHandles];
          currentFollowed.push(userToAdd.username)
          dispatch(setColumnTwoHandles(currentFollowed))
          break;
        case "Three":
          currentFollowed = [...columnThreeHandles];
          currentFollowed.push(userToAdd.username)
          dispatch(setColumnThreeHandles(currentFollowed))
          break;
        default:
          break;
      }
      const addFetchParams = {
          method: 'POST',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'handle_list': [ userToAdd.username ],
          })
      }
      ;(async () => {
        let addUserToTwitterFeedURL = ''
        switch(col){
          case "One":
            addUserToTwitterFeedURL = "api/v1/users/twitter_feed_update/?feed_index=1"
            break;
          case "Two":
            addUserToTwitterFeedURL = "api/v1/users/twitter_feed_update/?feed_index=2"
            break;
          case "Three":
            addUserToTwitterFeedURL = "api/v1/users/twitter_feed_update/?feed_index=3"
            break;
          default:
            break;
        }
        const response = await fetchData(addUserToTwitterFeedURL, "could not add twitter user to white list", addFetchParams).promise
      })()
  }

  const addStrongItem = (userToAdd, col) => {
    let temp = [...userData['strong_twitter_users']]
    temp.push(userToAdd)
    const fetchParams = {
          method: 'PUT',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
                "coin": userData['coin'],
                "saved_research_queries": userData['saved_research_queries'],
                "deleted_research_queries": userData['deleted_research_queries'],
                "twitter_handle": userData['twitter_handle'],
                "wallet_names": userData['wallet_names'],
                "deleted_wallet": userData['deleted_wallet'],
                "screener_names": userData['screener_names'],
                "deleted_screener": userData['deleted_screener'],
                "strong_twitter_users": temp
              }
          )
        }
    ;(async () => {
      try{
        let response_query = `api/v1/users/userdata/`
        let response = await fetchData(response_query, 'could not post user data', fetchParams).promise
        if(response){
          switch(col){
            case "One":
              refreshFeed("One",columnOneHandles);
              break;
            case "Two":
              refreshFeed("Two",columnTwoHandles)
              break;
            case "Three":
              refreshFeed('Three',columnThreeHandles);
              break;
            default:break;
          }
        }
      }catch(error){
        console.log('could not post user data: %s', error)
      }
    })()
  }


  const removeStrongItem = (userToAdd, col) => {
    let temp = [...strongTwitterUsers]
    temp = temp.filter((value, index) => {
      return value !== userToAdd
    })
    const fetchParams = {
          method: 'PUT',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
                "coin": userData['coin'],
                "saved_research_queries": userData['saved_research_queries'],
                "deleted_research_queries": userData['deleted_research_queries'],
                "twitter_handle": userData['twitter_handle'],
                "wallet_names": userData['wallet_names'],
                "deleted_wallet": userData['deleted_wallet'],
                "screener_names": userData['screener_names'],
                "deleted_screener": userData['deleted_screener'],
                "strong_twitter_users": temp
              }
          )
        }
    ;(async () => {
      try{
        let response_query = `api/v1/users/userdata/`
        let response = await fetchData(response_query, 'could not post user data', fetchParams).promise
        if(response){
          switch(col){
            case "One":
              refreshFeed("One",columnOneHandles);
              break;
            case "Two":
              refreshFeed("Two",columnTwoHandles)
              break;
            case "Three":
              refreshFeed('Three',columnThreeHandles);
              break;
            default:break;
          }
        }
      }catch(error){
        console.log('could not post user data: %s', error)
      }
    })()
  }

  const getTwitterUserTweets = (twitterUser) => {
    console.log("top of getTwitterUserTweets and twitterUser is: " + twitterUser)
    setTweetsFrom(twitterUser)
    setIsViewingTweets(true);
    setIsLoadingTweets(true)
    const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
    ;(async () => {
      const response = await fetchData(`api/v1/feed/alltweets/?twitter_user__name=${twitterUser}`, "could not get twitter user tweets", fetchParams).promise
      //console.log("response twitter user tweets")
      //console.dir(response)
      setTwitterUserTweetsCount(response.results.length)
      setTwitterUserTweets(response.results)
      setIsLoadingTweets(false)
    })()
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prevOpen) => !prevOpen)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
    maxHeight: 600,
  };

  const WhichButton = () => {
    if (usedFrom === 'dashboard') {
      return (
        <CgSelectO 
          aria-describedby={id}
          onClick={handleClick}
          color={theme.palette.primary.main}
          style={{
              color: theme.palette.primary.main,
              '&:hover': {
                  //background: theme.palette.secondary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
                  color: theme.palette.primary.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
              },
              cursor: 'pointer'
          }}

          size={18}
        />  
      )          
    } else {
      return (
        <Button
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          sx={{borderRadius: '12px', background: theme.palette.primary.dark, '&:hover': {
            background: theme.palette.primary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
            color: theme.palette.primary.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
          }}}
          size="small"
        >
          Manage Followed
        </Button>
      )
    }

  }

  if (isLoading) return <CircularProgress />
  return (
    <div>
      <WhichButton />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 400, left: 900 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >

        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open}>
          <Paper>
            <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '1300px', minHeight: '800px'}}>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <Typography variant="h3" sx={{marginTop: '15px', marginLeft: '30px'}}>Choose Twitter Users To Follow</Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="close"
                    sx={{color: theme.palette.error.main}}
                    onClick={handleClose}>
                    <IconSquareX sx={{ marginTop: '20px'}}/>
                  </IconButton>
                </Grid>
                <Grid item xs={12}><Divider sx={{width: '100%' }}/></Grid>
                <Grid item xs={7}>
                  <CustomFeedTabFollowSearch
                    column={column}
                    addUserToWhiteList={addUserToWhiteList} 
                    followedList={currentlyFollowedTab === "One" ? columnOneHandles : currentlyFollowedTab === "Two" ? columnTwoHandles : columnThreeHandles}
                    getTwitterUserTweets={getTwitterUserTweets}
                    refreshFeed={refreshFeed}
                  />
                </Grid>
                <Grid item xs={5}>
                  { isViewingTweets ?
                      isLoadingTweets ?
                        <Box sx={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                          <CircularProgress />
                        </Box>
                        :
                        <Stack direction="column" sx={{minHeight: '650px', overflow: 'hidden'}} >
                          <Stack sx={{width: '100%', paddingRight: '10px'}} direction={'row'}>
                            <Button sx={{flex: 1}} onClick={() => setCurrentlyFollowedTab('One')} variant={currentlyFollowedTab === "One" ? "contained" : 'outlined'} color={'primary'}>
                              <Typography sx={{fontSize: 12}}>Feed 1</Typography>
                            </Button>
                            <Button sx={{flex: 1}} onClick={() => setCurrentlyFollowedTab('Two')} variant={currentlyFollowedTab === "Two" ? "contained" : 'outlined'} color={'primary'}>
                              <Typography sx={{fontSize: 12}}>Feed 2</Typography>
                            </Button>
                            <Button sx={{flex: 1}} onClick={() => setCurrentlyFollowedTab('Three')} variant={currentlyFollowedTab === "Three" ? "contained" : 'outlined'} color={'primary'}>
                              <Typography sx={{fontSize: 12}}>Feed 3</Typography>
                            </Button>
                          </Stack>
                          <Stack direction={'row'} sx={{marginRight: '20px', justifyContent: 'space-between', alignItems: 'center', paddingTop: '5px' }}>
                            <Typography variant="h5" sx={{color: theme.palette.primary.dark }}>
                              Currently Followed: {currentlyFollowedTab === "One" ? columnOneHandles.length : currentlyFollowedTab === "Two" ? columnTwoHandles.length : columnThreeHandles.length}
                            </Typography>
                            <Modal
                                open={infoOpen}
                                onClose={() => setInfoOpen(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                              <Box sx={{...style}} >
                                <Typography sx={{color: '#fff', textAlign: 'center', mt: 2}}>
                                  Delete who is followed or, if selected, only display
                                  crypto tweets from that influencer. This feature allow one to focus on only the most important tweets.
                                  {/*Manage where Tweets will appear. Followed accounts loaded from your Twitter profile appear first, then Alpha Centoro selected accounts.*/}
                                </Typography>
                              </Box>
                            </Modal>
                            <IconButton sx={{alignSelf: 'flex-end'}} color={'primary'} onClick={() => setInfoOpen(!infoOpen)}>
                              <InfoIcon color={'primary'} />
                            </IconButton>
                          </Stack>
                          <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                            <IconButton sx={{float: 'right'}} onClick={() => setIsViewingTweets(false)}>
                              <CloseIcon color={'error'}/>
                            </IconButton>
                            <CustomFeedTwitterUserTweets
                                twitterUserTweets={twitterUserTweets}
                                twitterUserTweetsCount={twitterUserTweetsCount}
                                tweetsFrom={tweetsFrom}
                                isLoading={isLoading}
                            />
                          </Box>
                        </Stack>
                      :
                      <Stack direction="column" sx={{minHeight: '650px', overflow: 'hidden'}} >
                        <Stack sx={{width: '100%', paddingRight: '10px'}} direction={'row'}>
                          <Button sx={{flex: 1}} onClick={() => setCurrentlyFollowedTab('One')} variant={currentlyFollowedTab === "One" ? "contained" : 'outlined'} color={'primary'}>
                            <Typography sx={{fontSize: 12}}>Feed 1</Typography>
                          </Button>
                          <Button sx={{flex: 1}} onClick={() => setCurrentlyFollowedTab('Two')} variant={currentlyFollowedTab === "Two" ? "contained" : 'outlined'} color={'primary'}>
                            <Typography sx={{fontSize: 12}}>Feed 2</Typography>
                          </Button>
                          <Button sx={{flex: 1}} onClick={() => setCurrentlyFollowedTab('Three')} variant={currentlyFollowedTab === "Three" ? "contained" : 'outlined'} color={'primary'}>
                            <Typography sx={{fontSize: 12}}>Feed 3</Typography>
                          </Button>
                        </Stack>
                        <Typography variant="h5" sx={{marginRight: '20px', paddingTop: '5px', color: theme.palette.primary.dark }}>
                          Currently Followed: {currentlyFollowedTab === "One" ? columnOneHandles.length : currentlyFollowedTab === "Two" ? columnTwoHandles.length : columnThreeHandles.length}
                        </Typography>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          {
                            currentlyFollowedTab === "One" ?
                                <FormGroup>
                                  {columnOneHandles.map( (followed, index ) => <CustomFeedFollowedListItem col={"One"} key={index} getTwitterUserTweets={getTwitterUserTweets} followed={followed} removeUserFromWhiteList={removeUserFromWhiteList} addStrongItem={addStrongItem} removeStrongItem={removeStrongItem} userData={userData}/>  )}
                                </FormGroup>
                                :
                                null
                          }
                          {
                            currentlyFollowedTab === "Two" ?
                                <FormGroup>
                                  {columnTwoHandles.map( (followed, index ) => <CustomFeedFollowedListItem col={"Two"} key={index} getTwitterUserTweets={getTwitterUserTweets} followed={followed} removeUserFromWhiteList={removeUserFromWhiteList} addStrongItem={addStrongItem} removeStrongItem={removeStrongItem} userData={userData}/>  )}
                                </FormGroup>
                                :
                                null
                          }
                          {
                            currentlyFollowedTab === "Three" ?
                                <FormGroup>
                                  {columnThreeHandles.map( (followed, index ) => <CustomFeedFollowedListItem col={"Three"} key={index} getTwitterUserTweets={getTwitterUserTweets} followed={followed} removeUserFromWhiteList={removeUserFromWhiteList} addStrongItem={addStrongItem} removeStrongItem={removeStrongItem} userData={userData}/>  )}
                                </FormGroup>
                                :
                                null
                          }
                        </FormControl>
                      </Stack>
                  }
                </Grid>
              </Grid>
            </MainCard>
          </Paper>
        </Transitions>
      </Popover>
    </div>
  );
};

export default CustomFeedManager