import { Container, Typography, Stack, Button } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'

const ResetMessage = () => {
  const theme = useTheme()
  return (
    <Container component='main' maxWidth='xs' sx={{marginTop: '50px', justifyContent: 'center'}}>
      <Stack direction="column" sx={{marginBottom: '30px'}}>
        <Stack direction="row">
          <CheckCircleIcon fontSize='large' color={theme.palette.success.dark} />
          <Typography sx={{marginLeft: '20px'}}>
            An email has been sent that includes instructions on reseting your password.
          </Typography>
        </Stack>
        <Button
          component={Link}
          variant='contained'
          style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
          size='small'
          to='/login'
        >
          Return To Login
        </Button>
      </Stack>
    </Container>
  )
}

export default ResetMessage
