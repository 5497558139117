import React, {useState } from 'react'
import { Box, Tab } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'

import VIPAllCoinsPosts from './VIPAllCoinsPosts'
import VIPMainTabsFeedNotifications from './VIPMainTabsFeedNotifications'
import VIPOfficialTwitterDisplay from './VIPOfficialTwitterDisplay'

const VIPAllCoins = ( { coinChart, changeAtFollowedTab, setCoinInChart, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, changeChartAnnotations, allCoinsScrollTo, officialTwitter, showOfficialTweets, localVIPCoins } ) => {    //console.log("top of VIPAllCoins")
    //console.log("top of VILAllCoins and coinChart is:")
    //console.log(coinChart)
    //console.log("officialTwitter is: " + officialTwitter)
    //console.log(allCoinsScrollTo)
    //console.log("localVIPCoins.coin is:")
    //console.dir(localVIPCoins)

    const [tabValue,setTabValue] = useState("1")

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    if ((coinChart === undefined) && (officialTwitter === '')) return null
    //console.log("coinChart is not undefined")

    if (officialTwitter !== '') {
        return (
            <VIPOfficialTwitterDisplay 
                officialTwitter={officialTwitter} 
                changeChartAnnotations={changeChartAnnotations}
                changeAtFollowedTab={changeAtFollowedTab}
                setCoinInChart={setCoinInChart}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeDateInChart={changeDateInChart}
                changeCoinInTab={changeCoinInTab}
                showOfficialTweets={showOfficialTweets}
            />
        )
    } else if (coinChart !== undefined) {
        return (
            <Box sx={{width: '100%', height: '680px',}}>
                <TabContext value={tabValue} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="" indicatorColor="secondary" textColor="primary" >
                        <Tab label="Posts" value="1" sx={{minWidth: '30px'}}/>
                        <Tab label="Coin Notifications" value="2" sx={{minWidth: '30px'}} />
                        <Tab label="Coin Notifications w/Post" value="3" sx={{minWidth: '30px'}} />
                    </TabList>
                    </Box>
                    <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                        <Box sx={{paddingLeft: '0px', width: '100%', marginLeft: '0px', height: '580px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                            <VIPAllCoinsPosts 
                                coinChart={coinChart}
                                changeAtFollowedTab={changeAtFollowedTab}
                                setCoinInChart={setCoinInChart}
                                changeAtMentionedTab={changeAtMentionedTab}
                                changeHashTab={changeHashTab}
                                changeDateInChart={changeDateInChart}    
                                changeCoinInTab={changeCoinInTab}
                                changeChartAnnotations={changeChartAnnotations}
                                allCoinsScrollTo={allCoinsScrollTo}
                                showOfficialTweets={showOfficialTweets}
                                localVIPCoins={localVIPCoins}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                        <Box sx={{paddingLeft: '0px', width: '100%', marginLeft: '0px', height: '580px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                            <VIPMainTabsFeedNotifications
                                notificationType="coin"
                                coinChart={coinChart}
                                changeAtFollowedTab={changeAtFollowedTab}
                                setCoinInChart={setCoinInChart}
                                changeAtMentionedTab={changeAtMentionedTab}
                                changeHashTab={changeHashTab}
                                changeDateInChart={changeDateInChart}
                                changeCoinInTab={changeCoinInTab}
                                changeChartAnnotations={changeChartAnnotations}
                                showOfficialTweets={showOfficialTweets}
                                localVIPCoins={localVIPCoins}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value="3" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                        <Box sx={{paddingLeft: '0px', width: '100%', marginLeft: '0px', height: '580px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                            <VIPMainTabsFeedNotifications
                                notificationType="post"
                                coinChart={coinChart}
                                changeAtFollowedTab={changeAtFollowedTab}
                                setCoinInChart={setCoinInChart}
                                changeAtMentionedTab={changeAtMentionedTab}
                                changeHashTab={changeHashTab}
                                changeDateInChart={changeDateInChart}
                                changeCoinInTab={changeCoinInTab}
                                changeChartAnnotations={changeChartAnnotations}
                                showOfficialTweets={showOfficialTweets}
                                localVIPCoins={localVIPCoins}
                            />
                        </Box>
                    </TabPanel>                
                </TabContext>
            </Box>
        )
    } else {
        return null
    }
}

export default VIPAllCoins
