import React from 'react'
import { Typography, Grid, Stack, Avatar, IconButton, Box } from '@mui/material'
import { Twitter } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { abbreviateNumber } from "js-abbreviation-number"
import {FiExternalLink} from 'react-icons/fi'
import ResearchBodyCoinItemRightLinks from './ResearchBodyCoinItemRightLinks'

const ResearchBodyCoinItemRight = ( {coinDetails }) => {
    const theme = useTheme()

    //console.log("top of ResearchBodyCoinItemRight")
    //console.dir(coinDetails)

    const displayLargeNumber = (value) => {
      if (value > 0 )
        return abbreviateNumber(value, 2, {padding: false, symbols: ["", "k", "M", "B", "T", "P", "E"]} )
      else
        return value
    }

    const displayLink = () => {
        return (
            <IconButton 
              aria-label="Official Twitter Account" 
              component="a"
              target="_blank"
              rel="noreferrer" 
              href={coinDetails.twitter_user_url}            
            >
              <FiExternalLink color={theme.palette.primary.main} size={12}/>
            </IconButton>
        )   
    }

    const labelClasses = {
      fontWeight: 400,
      color: theme.palette.grey[400],
      textAlign: 'left'

    }
    const valueClasses = {
        fontWeight: 600,
        color: theme.palette.primary.main
    }    

    /* not currently used
      {"link_text": 'facebook', "link_property": 'facebook_username', "link": "http://www.facebook.com/XXX", "link_icon": "IconBrandFacebook", 'key':1 },
      {"link_text": 'reddit', "link_property": 'reddit_url', "link": 'XXX', "link_icon": "IconBrandReddit", 'key':1 },
      {"link_text": 'telegram', "link_property": 'telegram_url', "link": 'https://telegram.me/XXX', "link_icon": "IconBrandTelegram", 'key':1 },
    */
    const linkList = [
      {"link_text": 'coin website', "link_property": 'website',"link": 'XXX', "link_icon": "IconLink", 'key':1 },
      {"link_text": 'discord', "link_property": 'discord_url', "link": 'XXX', "link_icon": "IconBrandDiscord", 'key':1 },
      {"link_text": 'github', "link_property": 'github_url', "link": 'XXX', "link_icon": "IconBrandGithub", 'key':1 },
      {"link_text": 'twitter', "link_property": 'twitter_url', "link": 'XXX', "link_icon": "IconBrandTwitter", 'key':1 },
      {"link_text": 'blockchain exp', "link_property": 'blockchain_explorer', "link": 'XXX', "link_icon": "SiWprocket", 'key':1 },
    ]

    const displaySocialRank = () => {
      if (coinDetails.coinscreener.hasOwnProperty('social_rank')) 
        return coinDetails.coinscreener.social_rank === null ? 'none' : coinDetails.coinscreener.social_rank.toFixed(2)
      else 
        return 'none'
    }

    if (coinDetails.twitter_display_name !== null) {
      return (
          <Grid container spacing={0}>
              <Grid item xs={12}>
                <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                    <Twitter sx={{ marginRight: '5px', fontSize: "1.0rem", color: theme.palette.primary.main}} />
                    <Typography sx={{fontWeight: '800'}}>{coinDetails.twitter_display_name} ({displayLargeNumber(coinDetails.twitter_user_followers)})</Typography>
                    {displayLink()}
                </Stack>
              </Grid>
              <Grid item xs={6}><Typography sx={labelClasses}>social rank </Typography></Grid>
              <Grid item xs={6}><Typography sx={valueClasses}>{displaySocialRank()}</Typography></Grid>
              <Grid item xs={6}><Typography sx={labelClasses}>total social engagement </Typography></Grid>
              <Grid item xs={6}><Typography sx={valueClasses}>{ coinDetails.social_engagement === null ? 'none' : coinDetails.social_engagement.toFixed(2) }</Typography></Grid>              
              <Grid item xs={12}>
                <Box sx={{marginTop: '10px'}}>
                    {linkList.map((link) => <ResearchBodyCoinItemRightLinks currencyDetails={coinDetails.coinscreener} link={link} key={link.link_text} />)}
                  </Box>
              </Grid>
          </Grid>         
      )
    } else {
      return (
        <Typography sx={{fontWeight: '500'}}>There is no currently associated Twitter account</Typography>
      )
    }
}

export default ResearchBodyCoinItemRight