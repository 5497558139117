import logo from '../../assets/images/alpha-centoro-small-cropped.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = ( {width=60}) => {
    return (
      <img src={logo} alt="Alpha Centoro" width={width} />
    );
};

export default Logo;