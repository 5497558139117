import React from 'react'
import { Box, Typography, Grid, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { MdCheckCircle, MdCancel } from 'react-icons/md'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { textAlign } from '@mui/system'

const CoinDetailsWizardDetails = ({ currencyWizardDetails, medScreenOrSmaller=false}) => {

    const theme = useTheme()
    console.log(currencyWizardDetails)

    var dataSX = {color: theme.palette.primary.main, fontWeight: 600}
    var labelSX = { color: theme.palette.grey[400], textAlign: 'left', fontWeight: 600 }    

    const displayIcon = ( displayType ) => {
        if (displayType === "No" || displayType === "false") {
            //return a red x
            return (
                <MdCancel color={theme.palette.error.dark} size={18} />
            )
        } else if(displayType === "Yes" || displayType === "true") {
            //return a green check
            return (
                <MdCheckCircle color={theme.palette.success.dark} size={18}/>
            )
        }else{
            return (
                <QuestionMarkIcon color={theme.palette.success.dark} size={18}/>
            )
        }
    }

    const displayBlock = (label,data) => {
        return (
          <Stack direction="row" sx={{}}>
            <Box sx={{ width: '60%' }}>
              <Typography variant="body1"
                sx={labelSX}
              >{label}</Typography>
            </Box>
            <Box sx={{ width: '40%', textAlign: "right" }}>
              <Typography sx={dataSX} variant="body1">{data}</Typography>
            </Box>
          </Stack>
        )
    }    

    const displayImageLink = (label,image) => {
        return (
            <Grid item xs={12} >
                <Stack direction="column">
                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <Typography variant="body1" sx={labelSX}>{label}</Typography>
                    </Box>                                                
                    <Box sx={{width: '100%', borderRadius: '15px', margin: '10px', backgroundColor: theme.palette.dark[900]}}>
                        <Stack direction="column" display="flex" alignItems="center" justifyContent="center" sx={{margin: '10px'}}>
                            <a href={image} style={{textDecoration: 'none', color: theme.palette.secondary.main }} target="_blank" rel="noreferrer" ><img src={image} style={{maxHeight: '100px', maxWidth: '90px'}} alt={label}/></a>
                            <Typography variant="caption" sx={{fontSize: '.6rem', color: theme.palette.grey[400]}}>click image to view</Typography>
                        </Stack>
                    </Box>
                </Stack>
            </Grid>             
        )
    }
    
    console.log("just before return and token_alloc_img is:")
    console.dir(currencyWizardDetails.token_alloc_img)

    if (!currencyWizardDetails || Object.keys(currencyWizardDetails).length === 0) {
        //there are no wizard details for this coin....return nothing
        return null
    } else {
        if (medScreenOrSmaller) {
            return (
                <Box sx={{backgroundColor: theme.palette.dark[800], borderRadius: '15px', padding: "10px", margin: '5px', width: '98%'}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h3" sx={{marginTop: '15px', marginLeft: '20px'}}>Expert Provided Details</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{width: '95%', borderRadius: '15px', margin: '10px', padding: '10px', backgroundColor: theme.palette.dark[900]}}>
                                <Typography variant="h4" sx={{marginTop: '5px', marginLeft: '10px', marginBottom: '5px', color: theme.palette.primary.main}}>Dates</Typography>
                                <Stack direction="column">
                                    {displayBlock('created', currencyWizardDetails.created ? currencyWizardDetails.created.split('T')[0] : '--')}
                                    {
                                        currencyWizardDetails.token_gen_date_choice && (
                                            currencyWizardDetails.token_gen_date_choice === "Yes" ?
                                                displayBlock('token generation event', currencyWizardDetails.token_gen_date ? currencyWizardDetails.token_gen_date.split('T')[0] : '--')
                                                :
                                                displayBlock('token generation event', currencyWizardDetails.token_gen_date_choice)
                                        )
                                    }
                                    {
                                        currencyWizardDetails.token_release_everyday === "Yes" ?    
                                            displayBlock('token release', 'everyday')
                                            :
                                            currencyWizardDetails.token_release_specific_day === "Yes" ? 
                                                displayBlock('token release day of each month', currencyWizardDetails.token_release_date ? currencyWizardDetails.token_release_date : '--')
                                                :
                                                null
                                    }   
                                    {currencyWizardDetails.published_token_dates ? 
                                        <Stack direction={'column'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <Typography variant="body1" sx={{mt: 3, textAlign: 'right', marginRight: '10px'}}>Published Token Dates</Typography>
                                        <Stack direction={'row'} spacing={1} sx={{ width: '100%', maxHeight: 100}} justifyContent={'space-around'} alignItems={'center'}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Typography sx={{color: theme.palette.grey[400], fontSize: 16, textAlign: 'center'}}>
                                                        Date
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={{color: theme.palette.grey[400],fontSize: 16, textAlign: 'center' }}>
                                                        # of Tokens
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={{color: theme.palette.grey[400],fontSize: 16, textAlign: 'center' }}>
                                                        Category
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                        {currencyWizardDetails.published_token_dates && currencyWizardDetails.published_token_dates.length && currencyWizardDetails.published_token_dates[0].split(',').map((item, index) => {
                                                let token = item.split("||")
                                                if(!token || !token[0] || !token[1]) return null
                                                return (
                                                    <Stack key={String(item) + String(index)} direction={'row'} spacing={1} sx={{mt: 1, width: '100%', maxHeight: 100}} justifyContent={'space-around'} alignItems={'center'}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Typography sx={{color: theme.palette.grey[500], textAlign: 'center'}}>
                                                                    {new Date(token[0]).toLocaleDateString()}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography sx={{color: theme.palette.grey[500], textAlign: 'center' }}>
                                                                    {token[1]}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography sx={{color: theme.palette.grey[500], textAlign: 'center' }}>
                                                                    {token[2] ? token[2] : 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Stack>
                                                )
                                            })
                                        }
                                        </Stack> : <Typography variant="body1" sx={{mt: 3, textAlign: 'right', marginRight: '10px'}}>There are no Published Token Dates</Typography> }
                                </Stack>                               

                            </Box>
                        </Grid>                        
                        <Grid item xs={12}>
                            <Box sx={{width: '95%', borderRadius: '15px', margin: '10px', padding: '10px', backgroundColor: theme.palette.dark[900]}}>
                                <Typography variant="h4" sx={{marginTop: '5px', marginLeft: '10px', marginBottom: '5px', color: theme.palette.primary.main}}>Stats</Typography>
                                <Grid container>
                                    <Grid item xs={12}>   
                                        { displayBlock('non-inflationary',displayIcon(currencyWizardDetails.inflationary)) }
                                    </Grid>
                                    <Grid item xs={12}>   
                                        { displayBlock('staking', displayIcon(currencyWizardDetails.staking)) }
                                    </Grid>
                                    <Grid item xs={12}>   
                                        { displayBlock('vested',displayIcon(currencyWizardDetails.vested)) }
                                    </Grid>
                                    <Grid item xs={12}>   
                                        { displayBlock('seed price',
                                                    currencyWizardDetails.seed_price_choice && currencyWizardDetails.seed_price_choice === "Yes" ?
                                                        currencyWizardDetails.seed_price !== 0 ? '$ '+currencyWizardDetails.seed_price+' USD' : 'none'
                                                        :
                                                        <MdCancel color={theme.palette.error.dark} size={18} />
                                        )}
                                    </Grid>                                                                        

                                    
                                </Grid>
                            </Box>                         
                        </Grid>                        
                        <Grid item xs={12}>
                            <Box sx={{width: '95%', borderRadius: '15px', margin: '10px', padding: '10px', backgroundColor: theme.palette.dark[900]}}>
                                <Typography variant="h4" sx={{marginTop: '5px', marginLeft: '10px', marginBottom: '5px', color: theme.palette.primary.main}}>Documents</Typography>
                                <Grid container>
                                    { (currencyWizardDetails.whitepaper_url !== '' || currencyWizardDetails.whitepaper !== null) ? 
                                        <Grid item xs={12}>
                                            <Stack direction="column">
                                                <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                                    <Typography variant="body1" sx={labelSX}>Token WhitePaper</Typography>
                                                </Box>
                                                <Stack sx={{width: '100%', margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                    {currencyWizardDetails.whitepaper !== '' && currencyWizardDetails.whitepaper !== null ?
                                                        <Stack direction="column" display="flex" alignItems="center" justifyContent="center">
                                                            <a href={currencyWizardDetails.whitepaper} style={{textDecoration: 'none', color: theme.palette.secondary.main }} target="_blank" rel="noreferrer" ><img src={currencyWizardDetails.whitepaper} style={{maxHeight: '100px', maxWidth: '90px'}} alt="whitepaper attachment" /></a>
                                                            <Typography variant="caption" sx={{fontSize: '.6rem', color: theme.palette.grey[400]}}>click image to view</Typography>
                                                        </Stack>           
                                                        : null                                          
                                                    }

                                                    {currencyWizardDetails.whitepaper_url === '' && currencyWizardDetails.whitepaper_url !== null ?
                                                        <Stack direction="column" display="flex" alignItems="center" justifyContent="center" sx={{margin: '10px'}}>
                                                            <Typography variant="body1" sx={{fontWeight: 700}}><a href={currencyWizardDetails.whitepaper_url} style={{textDecoration: 'none', textAlign: 'center', color: theme.palette.primary.main }} target="_blank" rel="noreferrer" >{currencyWizardDetails.whitepaper_url}</a></Typography>
                                                        </Stack>
                                                        : null
                                                    }
                                                </Stack>                                                
                                            </Stack>
                                        </Grid>
                                        : null 
                                    }
                                    { currencyWizardDetails.token_alloc_img !== null ?
                                        displayImageLink('Token Allocation',currencyWizardDetails.token_alloc_img)
                                    : null }
                                    {currencyWizardDetails.token_release_detailed_img !== null ? 
                                        displayImageLink('Token Release Details',currencyWizardDetails.token_release_detailed_img)
                                    : null }     
                                    {currencyWizardDetails.token_release_img !== null ? 
                                        displayImageLink('Token Release', currencyWizardDetails.token_release_img)
                                    : null }                                                                   

                                </Grid>
                            </Box>
                            
                        </Grid>                        
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{marginLeft: '20px', color: theme.palette.grey[400]}}>expert info last edited by: {currencyWizardDetails.user.username}</Typography>
                        </Grid>                      
                    </Grid>
                </Box>
            )

        } else {

            return (
                <Box sx={{backgroundColor: theme.palette.dark[800], borderRadius: '15px', padding: "10px", margin: '20px', width: '98%'}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h3" sx={{marginTop: '15px', marginLeft: '20px'}}>Expert Provided Details</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{width: '95%', borderRadius: '15px', margin: '10px', backgroundColor: theme.palette.dark[900]}}>
                                <Stack direction="row">
                                    <Box sx={{width: '20%', paddingLeft: '15px', minHeight: '125px', display: 'flex', alignItems: 'center'}}>
                                        <Typography variant="h1" sx={{color: theme.palette.primary.main ,opacity: 0.60}}>Dates</Typography>
                                    </Box>
                                    <Box sx={{width: '80%', margin: '5px',}}>
                                        <Stack direction="column">
                                            <Stack direction="row" display="flex" justifyContent="space-around" sx={{width: '100%', marginTop: '10px'}}>
                                                <Box sx={{width: '50%', alignContent: 'flex-end'}}>
                                                    <Typography variant="body1" sx={{textAlign: 'right', marginRight: '10px'}}>created:</Typography>
                                                </Box>
                                                <Box sx={{width: '50%', alignContent: 'flex-start'}}>
                                                    <Typography variant="body1" sx={{fontWeight: 700}}>{currencyWizardDetails.created ? currencyWizardDetails.created.split('T')[0] : '--'}</Typography>
                                                </Box>
                                            </Stack>

                                            {
                                                currencyWizardDetails.token_gen_date_choice && (
                                                    <Stack direction="row" display="flex" justifyContent="space-around" sx={{width: '100%', marginTop: '20px'}}>
                                                        <Box sx={{width: '50%', alignContent: 'flex-end'}}>
                                                            <Typography variant="body1" sx={{textAlign: 'right', marginRight: '10px'}}>token generation event:</Typography>
                                                        </Box>
                                                        {
                                                            currencyWizardDetails.token_gen_date_choice === "Yes" ?
                                                                <Box sx={{width: '50%', alignContent: 'flex-start'}}>
                                                                    <Typography variant="body1" sx={{fontWeight: 700}}>{currencyWizardDetails.token_gen_date ? currencyWizardDetails.token_gen_date.split('T')[0] : '--'}</Typography>
                                                                </Box>
                                                                :
                                                                <Box sx={{width: '50%', alignContent: 'flex-start'}}>
                                                                    <Typography variant="body1" sx={{fontWeight: 700}}>{currencyWizardDetails.token_gen_date_choice}</Typography>
                                                                </Box>
                                                        }

                                                    </Stack>
                                                )
                                            }
                                            {
                                                currencyWizardDetails.token_release_everyday === "Yes" ?
                                                    <Stack direction="row" display="flex" justifyContent="space-around"
                                                        sx={{width: '100%', marginTop: '20px', marginBottom: '10px'}}>
                                                        <Box sx={{width: '50%', alignContent: 'flex-end'}}>
                                                            <Typography 
                                                                variant="body1"
                                                                sx={{textAlign: 'right', marginRight: '10px'}}
                                                            >
                                                                token release:
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{width: '50%', alignContent: 'flex-start'}}>
                                                            <Typography variant="body1"
                                                                        sx={{fontWeight: 700}}>everyday</Typography>
                                                        </Box>
                                                    </Stack>
                                                    :
                                                    currencyWizardDetails.token_release_specific_day === "Yes" ?
                                                        <Stack direction="row" display="flex" justifyContent="space-around" sx={{width: '100%', marginTop: '20px', marginBottom: '10px'}}>
                                                            <Box sx={{width: '50%', alignContent: 'flex-end'}}>
                                                                <Typography variant="body1" sx={{textAlign: 'right', marginRight: '10px'}}>token release day of each month:</Typography>
                                                            </Box>
                                                            <Box sx={{width: '50%', alignContent: 'flex-start'}}>
                                                                <Typography variant="body1" sx={{fontWeight: 700}}>{currencyWizardDetails.token_release_date ? currencyWizardDetails.token_release_date : '--'}</Typography>
                                                            </Box>
                                                        </Stack>
                                                        : null
                                            }
                                            
                                            {currencyWizardDetails.published_token_dates ?
                                                <Stack direction={'column'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    <Typography variant="body1" sx={{mt: 3, textAlign: 'right', marginRight: '10px'}}>Published Token Dates</Typography>
                                                    <Stack direction={'row'} spacing={1} sx={{ width: '100%', maxHeight: 100}} justifyContent={'space-around'} alignItems={'center'}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Typography sx={{color: theme.palette.grey[400], fontSize: 16, textAlign: 'center'}}>
                                                                    Date
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography sx={{color: theme.palette.grey[400],fontSize: 16, textAlign: 'center' }}>
                                                                    # of Tokens
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography sx={{color: theme.palette.grey[400],fontSize: 16, textAlign: 'center' }}>
                                                                    Category
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Stack>
                                                    {
                                                        currencyWizardDetails.published_token_dates && currencyWizardDetails.published_token_dates.length && currencyWizardDetails.published_token_dates[0].split(',').map((item, index) => {
                                                            let token = item.split("||")
                                                            if(!token || !token[0] || !token[1]) return null
                                                            return (
                                                                <Stack key={String(item) + String(index)} direction={'row'} spacing={1} sx={{mt: 1, width: '100%', maxHeight: 100}} justifyContent={'space-around'} alignItems={'center'}>
                                                                    <Grid container>
                                                                        <Grid item xs={4}>
                                                                            <Typography sx={{color: theme.palette.grey[500], textAlign: 'center'}}>
                                                                                {new Date(token[0]).toLocaleDateString()}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <Typography sx={{color: theme.palette.grey[500], textAlign: 'center' }}>
                                                                                {token[1]}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <Typography sx={{color: theme.palette.grey[500], textAlign: 'center' }}>
                                                                                {token[2] ? token[2] : 'N/A'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Stack>
                                                            )
                                                        })
                                                    }
                                                </Stack> : <Typography variant="body1" sx={{mt: 3, textAlign: 'right', marginRight: '10px'}}>There are no Published Token Dates</Typography> }
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>

                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{width: '95%', borderRadius: '15px', margin: '10px', backgroundColor: theme.palette.dark[900]}}>
                                <Stack direction="row">
                                    <Box sx={{width: '20%', paddingLeft: '15px', minHeight: '125px', display: 'flex', alignItems: 'center'}}>
                                        <Typography variant="h1" sx={{color: theme.palette.primary.main ,opacity: 0.60}}>Stats</Typography>
                                    </Box>
                                    <Box sx={{width: '80%', margin: '5px',}}>
                                        <Stack direction="column">
                                            <Stack direction="row" display="flex" justifyContent="space-around" sx={{width: '100%', marginTop: '10px'}}>
                                                <Box sx={{width: '50%', alignContent: 'flex-end'}}>
                                                    <Typography variant="body1" sx={{textAlign: 'right', marginRight: '10px'}}>non-inflationary:</Typography>
                                                </Box>
                                                <Box sx={{width: '50%', alignContent: 'flex-start'}}>
                                                    <Typography variant="body1" sx={{fontWeight: 700}}>{displayIcon(currencyWizardDetails.inflationary)}</Typography>
                                                </Box> 
                                            </Stack>   
                                            <Stack direction="row" display="flex" justifyContent="space-around" sx={{width: '100%', marginTop: '10px'}}>
                                                <Box sx={{width: '50%', alignContent: 'flex-end'}}>
                                                    <Typography variant="body1" sx={{textAlign: 'right', marginRight: '10px'}}>staking:</Typography>
                                                </Box>
                                                <Box sx={{width: '50%', alignContent: 'flex-start'}}>
                                                    <Typography variant="body1" sx={{fontWeight: 700}}>{displayIcon(currencyWizardDetails.staking)}</Typography>
                                                </Box> 
                                            </Stack>          
                                            <Stack direction="row" display="flex" justifyContent="space-around" sx={{width: '100%', marginTop: '10px'}}>
                                                <Box sx={{width: '50%', alignContent: 'flex-end'}}>
                                                    <Typography variant="body1" sx={{textAlign: 'right', marginRight: '10px'}}>vested:</Typography>
                                                </Box>
                                                <Box sx={{width: '50%', alignContent: 'flex-start'}}>
                                                    <Typography variant="body1" sx={{fontWeight: 700}}>{displayIcon(currencyWizardDetails.vested)}</Typography>
                                                </Box> 
                                            </Stack>  
                                            <Stack direction="row" display="flex" justifyContent="space-around" sx={{width: '100%', marginTop: '10px'}}>
                                                <Box sx={{width: '50%', alignContent: 'flex-end'}}>
                                                    <Typography variant="body1" sx={{textAlign: 'right', marginRight: '10px'}}>seed price:</Typography>
                                                </Box>
                                                {
                                                    currencyWizardDetails.seed_price_choice && currencyWizardDetails.seed_price_choice === "Yes" ?
                                                        <Box sx={{width: '50%', alignContent: 'flex-start'}}>
                                                            <Typography variant="body1" sx={{fontWeight: 700}}> { currencyWizardDetails.seed_price !== 0 ? '$ '+currencyWizardDetails.seed_price+' USD' : 'none'}</Typography>
                                                        </Box>
                                                        :
                                                        <Box sx={{width: '50%', alignContent: 'flex-start'}}>
                                                            <MdCancel color={theme.palette.error.dark} size={18} />
                                                        </Box>
                                                }
                                            </Stack>                                    
                                        </Stack>
                                    </Box>                                                                          
                                </Stack>             
                            </Box>       
                        </Grid>
                        { (currencyWizardDetails.whitepaper_url !== '' || currencyWizardDetails.whitepaper !== null) ?
                            <Grid item xs={4}>
                                <Box sx={{width: '95%', borderRadius: '15px', margin: '10px', backgroundColor: theme.palette.dark[900]}}>
                                    <Stack direction="row">
                                        <Box sx={{width: '100%', minHeight: '125px', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                            <Typography variant="h1" sx={{textAlign: 'left', color: theme.palette.primary.main ,opacity: 0.60}}>Token WhitePaper</Typography>
                                        </Box>
                                        <Box sx={{width: '100%', margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <Stack direction="column" display="flex" alignItems="center" justifyContent="center" sx={{margin: '10px'}}>
                                                {currencyWizardDetails.whitepaper !== null &&  currencyWizardDetails.whitepaper !== '' ?
                                                    <>
                                                        <a href={currencyWizardDetails.whitepaper} style={{textDecoration: 'none', color: theme.palette.secondary.main }} target="_blank" rel="noreferrer" ><img src={currencyWizardDetails.whitepaper} style={{maxHeight: '100px', maxWidth: '90px'}} alt="whitepaper attachment" /></a>
                                                        <Typography variant="caption" sx={{fontSize: '.6rem', color: theme.palette.grey[400]}}>click image to view</Typography>
                                                    </>
                                                    : null
                                                }
                                                {currencyWizardDetails.whitepaper_url !== null &&  currencyWizardDetails.whitepaper_url !== '' ?
                                                    <Typography variant="body1" sx={{fontWeight: 700}}><a href={currencyWizardDetails.whitepaper_url} style={{textDecoration: 'none', textAlign: 'center', color: theme.palette.primary.main }} target="_blank" rel="noreferrer" >{currencyWizardDetails.whitepaper_url}</a></Typography>
                                                    : null
                                                }
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Grid>
                            :
                            null
                        }
                        { currencyWizardDetails.token_alloc_img !== null && currencyWizardDetails.token_alloc_img !== '' ?
                        <Grid item xs={4} >
                            <Box sx={{width: '95%', borderRadius: '15px', margin: '10px', backgroundColor: theme.palette.dark[900]}}>
                                <Stack direction="row" sx={{width: '100%'}}>
                                    <Box sx={{width: '20%', paddingLeft: '15px', minHeight: '150px', display: 'flex', alignItems: 'center'}}>
                                        <Stack direction="column">
                                            <Typography variant="h1" sx={{color: theme.palette.primary.main ,opacity: 0.60}}>Token</Typography>
                                            <Typography variant="h1" sx={{color: theme.palette.primary.main ,opacity: 0.60}}>Allocation</Typography>
                                        </Stack>
                                    </Box>
                                    <Box sx={{width: '80%', margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>  
                                        <Stack direction="column" display="flex" alignItems="center" sx={{margin: '10px'}}>
                                            <a href={currencyWizardDetails.token_alloc_img} style={{textDecoration: 'none', color: theme.palette.secondary.main }} target="_blank" rel="noreferrer" ><img src={currencyWizardDetails.token_alloc_img} style={{maxHeight: '100px', maxWidth: '90px'}} alt="token allocation attachment"/></a>
                                            <Typography variant="caption" sx={{fontSize: '.6rem', color: theme.palette.grey[400]}}>click image to view</Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid> : null }
                        {currencyWizardDetails.token_release_detailed_img !== null && currencyWizardDetails.token_release_detailed_img !== '' ? 
                        <Grid item xs={4} >
                            <Box sx={{width: '95%', borderRadius: '15px', margin: '10px', backgroundColor: theme.palette.dark[900]}}>
                                <Stack direction="row" sx={{width: '100%'}}>
                                    <Box sx={{width: '20%', paddingLeft: '15px', minHeight: '150px', display: 'flex', alignItems: 'center'}}>
                                        <Stack direction="column">
                                            <Typography variant="h1" sx={{color: theme.palette.primary.main ,opacity: 0.60}}>Token</Typography>
                                            <Typography variant="h1" sx={{color: theme.palette.primary.main ,opacity: 0.60}}>Release</Typography>
                                            <Typography variant="h1" sx={{color: theme.palette.primary.main ,opacity: 0.60}}>Details</Typography>
                                        </Stack>
                                    </Box>
                                    <Box sx={{width: '80%', margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}> 
                                        <Stack direction="column" display="flex" alignItems="center" sx={{margin: '10px'}}>     
                                            <a href={currencyWizardDetails.token_release_detailed_img} style={{textDecoration: 'none', color: theme.palette.secondary.main }} target="_blank" rel="noreferrer" ><img src={currencyWizardDetails.token_release_detailed_img} style={{maxHeight: '100px', maxWidth: '90px'}} alt="token release detail attachment"/></a>              
                                            <Typography variant="caption" sx={{fontSize: '.6rem', color: theme.palette.grey[400]}}>click image to view</Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>                    
                        </Grid> : null }
                        {currencyWizardDetails.token_release_img !== null && currencyWizardDetails.token_release_img !== ''? 
                        <Grid item xs={4}>
                            <Box sx={{width: '95%', borderRadius: '15px', margin: '10px', backgroundColor: theme.palette.dark[900]}}>
                                <Stack direction="row" sx={{width: '100%'}}>
                                    <Box sx={{width: '20%', paddingLeft: '15px', minHeight: '150px', display: 'flex', alignItems: 'center'}}>
                                        <Stack direction="column">
                                            <Typography variant="h1" sx={{color: theme.palette.primary.main ,opacity: 0.60}}>Token</Typography>
                                            <Typography variant="h1" sx={{color: theme.palette.primary.main ,opacity: 0.60}}>Release</Typography>
                                        </Stack>
                                    </Box>
                                    <Box sx={{width: '80%', margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px'}}>   
                                        <Stack direction="column" display="flex" alignItems="center">
                                            <a href={currencyWizardDetails.token_release_img} style={{textDecoration: 'none', color: theme.palette.secondary.main }} target="_blank" rel="noreferrer" ><img src={currencyWizardDetails.token_release_img} style={{maxHeight: '100px', maxWidth: '90px'}} alt="token release attachment" /></a>
                                            <Typography variant="caption" sx={{fontSize: '.6rem', color: theme.palette.grey[400]}}>click image to view</Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>                      
                        </Grid> : null }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{marginLeft: '20px', color: theme.palette.grey[400]}}>expert info last edited by: {currencyWizardDetails.user}</Typography>
                    </Grid>
                </Box>

            )
        }
    }
}

export default CoinDetailsWizardDetails