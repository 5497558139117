import * as React from 'react'
import { 
  TextField, Grid, Stack,
  Typography,
  Box,
} from '@mui/material'
import { DropzoneArea } from 'react-mui-dropzone'
import TokenWizardHelperText from './TokenWizardHelperText'
import { displayUploadedFileORIMageURL } from '../../utility/objHelper'
import { useTheme } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch'

/**
 * The Token Whitepaper page for the TokenWizard
 * @param {*} - All variables for this page exist in the parent component.
 * @returns 
 */
const WhitepaperPage = ({
    pageData,
    setPageData,
    showError,
    coin
}) => {
    console.log("tttttttttttttttttttttttttttttttop of WhitepaperPage")
    console.log("pageData is:")
    console.dir(pageData)

    const theme = useTheme()

    const handleWhitepaperImageAttach = (file) => {  // Handle adding whitepaper file
        console.log("top of handleWhitepaperImageAttach and file is:")
        console.dir(file)
        if(file.length >= 1){ 
            // Make sure it doesn't set to [] when changing pages
            
            //instead of saving in pageData only....
            //need to save this directly to the endpoint as it's uploaded
            //found no way to reliably make a file type object of the url
            //in order to save the current image within the post

            /* decided to upload at end instead of here when we receive it
            let formData = new FormData()
            formData.append("whitepaper",  file[0])
            formData.append('coin', coin)

            const fetchParams = {
                method: 'POST',
                headers: { },
                body: formData
            }

            console.log("fetchParams is:")
            console.dir(fetchParams)

            let wizardURL = `api/v1/research/postwizard/?whitepaper=true&coin=${coin}`
            let errorMessage = 'Error saving your token information.  Resubmit.  If still an error please contact support.'
        
            ;(async () => {
                try {
                    const whitepaperResponse = await fetchData(wizardURL, errorMessage, fetchParams).promise
                    console.log("whitepaperResponse from api/v1/research/postwizard/?whitepaper=true is:")
                    console.dir(whitepaperResponse)
                    if ((whitepaperResponse.hasOwnProperty('success')) || whitepaperResponse === true) {

                    } else {
                        throw 'field not excepted?'
                    }
                } catch (error) {
                    console.log("something is broke submitting whitepaper image: " + error)
                }
            })()
            */
                        
            setPageData({
                ...pageData,
                whitepaperFile: file
            })
        } else {
            console.log("no file submitted to upload")
        }
    }

    const removeImageHandler = (currentState) => {
        console.log("top of removeImageHandler in WhitepagerPage and currentState.target.checked is:")
        console.dir(currentState.target.checked)
        console.log("so setting removeWhitepaper to " + currentState.target.checked)
        setPageData({
            ...pageData,
            removeWhitepaper: currentState.target.checked
        })        
    }      

    return(
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
            <Typography variant="h3" sx={{color: theme.palette.primary.main}}>Enter Token Whitepaper Details</Typography>

            <Grid container sx={{marginTop: '20px'}} spacing={5}>
                <Grid item xs={6} container direction="row" alignItems="center" justifyContent="flex-end">
                    <Stack direction="row" display="flex" alignItems="center">
                        <Typography align='center' variant="h5" sx={{ color: theme.palette.grey[500] }}>
                            token name:
                        </Typography>
                        <TokenWizardHelperText title={'Token Name'} text={'The name of the token'} />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    {/*<TextField
                        id="tokenName"
                        type="search"
                        label="token name"
                        inputProps={
                            {
                            step: 300
                            }
                        } 
                        color={'primary'} 
                        value={pageData.tokenName}
                        onChange={event => setPageData({
                            ...pageData,
                            tokenName: event.target.value
                        })}
                        style={{width: 300}}
                    />*/}
                    <Typography align='left' variant="h3" sx={{ color: theme.palette.grey[500], position: "relative", top: "15px"}}>
                        {pageData.tokenName}
                    </Typography>                    
                </Grid>
                <Grid item xs={6} container direction="row" alignItems="center" justifyContent="flex-end">
                    <Stack direction="row" display="flex" alignItems="center">
                        <Typography align='center' variant="h5" sx={{ color: theme.palette.grey[500] }}>
                            Enter the token whitepaper URL:
                        </Typography>
                        <TokenWizardHelperText title={'Whitepaper'} text={'Describes how the tokens are allocated between investors, team members, the public, and other groups (like how many will be reserved for staking rewards. Paper or URL also reveals when tokens will be released to the public.\nNote: The Details page of the project will show this info once uploaded.'} />
                    </Stack>
                </Grid>     
                <Grid item xs={6}>
                    <TextField
                        id="whitepaperURL"
                        type="url"
                        label="Enter URL"
                        inputProps={
                            {
                            step: 300
                            }
                        } 
                        color={'primary'} 
                        value={pageData.whitepaperUrl}
                        onChange={event => setPageData({...pageData, whitepaperUrl: event.target.value})}
                        style={{width: 300}}
                    />                    
                </Grid>
                <Grid item xs={12} alignItems={'center'} justifyContent={'center'}>
                    <Typography variant="h4" sx={{ color: theme.palette.grey[500], marginBottom: '15px', textAlign: 'center' }}>
                        OR upload whitepaper file directly instead of entering it's url above
                    </Typography>
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '25px', mb: 5}}>
                        <Stack display='flex' direction='row' alignItems='center' justifyContent='center' sx={{flex: 1, maxWidth: 800, margin: '25px'}}>
                            <DropzoneArea
                                //initialFiles={pageData.whitepaperFile}
                                onChange={(file) => handleWhitepaperImageAttach(file)}
                                filesLimit={1}
                                dropzoneText="Optionally upload Whitepaper file image by clicking or dragging the file here"
                                showPreviews={false}
                                clearOnUnmount={false}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={false}
                            />
                            <Stack display='flex' direction='row' justifyContent='center' sx={{margin: '25px', mb: 5}}>
                                {displayUploadedFileORIMageURL(pageData.whitepaperFile, removeImageHandler, pageData.removeWhitepaper)}
                            </Stack>
                                
                        </Stack>
                    </Box>
                </Grid>        
            </Grid>




            {
                showError && (
                    <Typography align='center' variant="h3" sx={{ mt: 1, color: theme.palette.error.main }}>
                        Please fill in all fields before continuing.
                    </Typography>
                )
            }
        </Box>
    );
}

export default WhitepaperPage;