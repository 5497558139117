import React, {useState } from 'react'

import {
  Box,
  CircularProgress,
  Tab,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip, Typography
} from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip';
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles';
import VIPMainTabsCommentsTab from './VIPMainTabsCommentsTab'
import ResearchGeneralCommentaryForm from '../Research2/ResearchGeneralCommentaryForm'
import VIPMainTabsFullPost from './VIPMainTabsFullPost'
import VIPMainTabsAlertTabs from './VIPMainTabsAlertTabs'
import VIPAddCoinChoices from './VIPAddCoinChoices'

import { Repeat } from '@mui/icons-material'
import { BsFillGearFill } from 'react-icons/bs'
import { RiVipCrown2Fill } from 'react-icons/ri'

const VIPMainTabs = ( { vipScreenerName, vipListings, responseCount, vipPageCount, vipPage, changePage, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, userDetails, isFullPostLoading, handleConfigChange, vipConfig, showOfficialTweets, resetVIPCoins, localVIPCoins } ) => {

  const theme = useTheme()

  var is_internal = false
  if (userDetails.user.is_internal === 'true' || userDetails.user.is_internal === true) is_internal = true

  const [vipMainTabValue,setVIPMainTabValue] = useState('1')
  //const [currentVipCoins,setCurrentVipCoins] = useState([])
  const changeTabInLayout = () => {
    //there is no "state" for vip currently...possibly use dashboard vip twitter state??????
  }

  const handleMainTabChange = (event, newValue) => {
    if (vipMainTabValue === '6') {
      //changing from vip coin selection
      //set the new coins in userobject in vip
      //make sure to rebuild the viplistings array as the vip coins have changed
      //console.log("the current tab is 6 so resetVIPCoins ")
      resetVIPCoins()
    }
    setVIPMainTabValue(newValue);
  }

  const changeInVIPCoins = (newScreenerName, newVIPCoins) => {
    //console.log("top of changeInVIPCoins and newVIPCoins is:")
    //console.dir(newVIPCoins)
    //setCurrentVipCoins(newVIPCoins)
    resetVIPCoins(newScreenerName, newVIPCoins)
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.primary.main,
      color: '#d2d2d2',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #000',
    },
  }));

  return (
    <Box sx={{width: '100%', height: '1050px',}}>
      <TabContext value={vipMainTabValue} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleMainTabChange} aria-label="" indicatorColor="secondary" textColor="primary" >
            <Tab label="Tweets" value="1" sx={{minWidth: '30px'}} />
            <Tab label="Alerts" value="2" sx={{minWidth: '30px'}} />
            <Tab label="Trades" value="3" sx={{minWidth: '30px'}} />
            {
              is_internal ?
                <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography sx={{pb: 1, fontWeight: 500}} color="inherit">Leave a Comment.</Typography>
                        {"As an internal user, you can leave comments about any cryptocurrency. "}{' '}
                        {"We will use your comments in our analysis."}
                      </React.Fragment>
                    }
                >
                  <Tab label={<Repeat sx={{fontWeight: 400, color: theme.palette.grey[400], fontSize: "1.2rem", transform: "rotate(90deg)"}} />} value="4" sx={{minWidth: '30px'}} />
                </HtmlTooltip>
              : null 
            }
            <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography sx={{pb: 1, fontWeight: 500}} color="inherit">Change your view configuration</Typography>
                    {"Toggle between the simple and advanced configurations."}
                  </React.Fragment>
                }
            >
              <Tab label={<BsFillGearFill />} value="5" sx={{minWidth: '30px'}} />
            </HtmlTooltip>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography sx={{pb: 1, fontWeight: 500}} color="inherit">Filter your VIP feed by Watchlist</Typography>
                  {"Use one of your coin "}<b>{'Watchlists'}</b>{' '}
                  {"to filter the results of your VIP feed."}
                </React.Fragment>
              }
            >
              <Tab label={<RiVipCrown2Fill />} value="6" sx={{minWidth: '30px'}} />
            </HtmlTooltip>
          </TabList>
        </Box>
        <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
          <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '980px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
            { isFullPostLoading ? <CircularProgress /> :
              <VIPMainTabsFullPost 
                vipListings={vipListings}
                vipPageCount={vipPageCount}
                responseCount={responseCount}
                vipPage={vipPage}
                changePage={changePage}
                setCoinInChart={setCoinInChart}
                changeAtFollowedTab={changeAtFollowedTab}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                changeDateInChart={changeDateInChart}
                showOfficialTweets={showOfficialTweets}
                changeInVIPCoins={changeInVIPCoins}
              />
            }
          </Box>
        </TabPanel>
        <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
          <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '980px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
            <VIPMainTabsAlertTabs 
              setCoinInChart={setCoinInChart}
              changeAtFollowedTab={changeAtFollowedTab}
              changeAtMentionedTab={changeAtMentionedTab}
              changeHashTab={changeHashTab}
              changeCoinInTab={changeCoinInTab}
              changeDateInChart={changeDateInChart}   
              showOfficialTweets={showOfficialTweets}  
              localVIPCoins={localVIPCoins}  
              changeTabInLayout={changeTabInLayout}     
            />
          </Box>
        </TabPanel>        
        <TabPanel value="3" sx={{'&.MuiTabPanel-root': {padding: '2px'}}}>
          <Box sx={{height: '980px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
            <VIPMainTabsCommentsTab
              setCoinInChart={setCoinInChart}
              changeAtFollowedTab={changeAtFollowedTab}
              changeAtMentionedTab={changeAtMentionedTab}
              changeHashTab={changeHashTab}
              changeCoinInTab={changeCoinInTab}
              changeDateInChart={changeDateInChart} 
            />
          </Box>
        </TabPanel>
        { is_internal ? <TabPanel value="4" sx={{'&.MuiTabPanel-root': {padding: '2px'}}}>
          <Box sx={{height: '980px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin', padding: '10px'}}>
            <ResearchGeneralCommentaryForm />
          </Box>
        </TabPanel> : null}      

        <TabPanel value="5" sx={{'&.MuiTabPanel-root': {padding: '2px'}}}>
          <Box sx={{height: '980px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
            <Stack direction="column">

              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">VIP Tool Configuration</FormLabel>
                <RadioGroup
                  aria-labelledby="config-controlled-radio-buttons-group"
                  name="VIP Tool Configuration"
                  value={vipConfig}
                  onChange={handleConfigChange}
                >
                  <FormControlLabel value="simple" control={<Radio />} label="Keep It Simple" />
                  <FormControlLabel value="noize" control={<Radio />} label="Bring Tha NOIZE" />
                </RadioGroup>
              </FormControl>              
            </Stack>
          </Box>
        </TabPanel>    
        <TabPanel value="6" sx={{'&.MuiTabPanel-root': {padding: '2px'}}}>
          <Box sx={{height: '980px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
            <VIPAddCoinChoices
              vipCoinsList={userDetails.coin}
              userObject={userDetails}
              changeInVIPCoins={changeInVIPCoins}
            />
          </Box>
        </TabPanel>              
      </TabContext>
    </Box>
  )
}

export default VIPMainTabs