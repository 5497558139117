import React, { useState } from 'react'

import {
    Stack,
    TextField,
    IconButton,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputAdornment, Typography, Modal, Box
} from '@mui/material'

import { Search, Clear } from '@mui/icons-material'
import InfoIcon from "@mui/icons-material/Info";

const CustomFeedTwitterUserSearch = ({searchTermChangeHandler, clearTwitterUserSearch, setInfluencerHandler } ) => {

  const [searchTerm,setSearchTerm] = useState('')
  const [searchTermError,setSearchTermError] = useState(false)
  const [searchTermErrorMessage,setSearchTermErrorMessage] = useState('')
  const [influencerValue,setInfluencerValue] = useState('both')
  const [infoOpen, setInfoOpen] = React.useState(false);

  const handleSearchTermChange = e => {
    if (searchTermError) setSearchTermError(false)
    setSearchTerm(e.target.value)
  }

  const handleInfluencerChange = e => {
    setInfluencerValue(e.target.value)
    setInfluencerHandler(e.target.value)
  }

  const searchSubmitted = () => {
    //console.log("value of searchTerm is: " + searchTerm)

    //search term cannot have spaces
    //search term must be minimum of 3 spaces

    searchTerm.replace(/\s+/g, '')
    searchTerm.replace(/@/g, '')
    if (searchTerm.length > 2) {
      //console.log("searching..." + searchTerm)
      searchTermChangeHandler(searchTerm)
    } else {
      setSearchTermError(true)
      setSearchTermErrorMessage('search term needs to be at least 3 characters')
    }

  }

  const clearSearch = () => {
    setSearchTerm('')
    setSearchTermError(false)
    setSearchTermErrorMessage('')
    clearTwitterUserSearch()
  }

  function keyPress(e){
      if(e.keyCode === 13){
          searchSubmitted()
      }
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
    maxHeight: 600,
  };
  //console.log("searchTermError is: " + searchTermError)
  //console.log("searchTermError is: " + searchTermError)

  return (
    <Stack direction="column" sx={{marginTop: '15px'}}>
        <Stack direction="row" alignItems="center" justifyContent="center">
        <FormControl>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <FormLabel id="account-type-filter">account type</FormLabel>
                <IconButton color={'primary'} onClick={() => setInfoOpen(!infoOpen)}>
                    <InfoIcon color={'primary'} />
                </IconButton>
            </Stack>
            <Modal
                open={infoOpen}
                onClose={() => setInfoOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style}} >
                    <Typography sx={{textAlign: 'center', color: '#fff', fontSize: 20}}>Twitter Account Search</Typography>
                    <Typography sx={{color: '#fff', textAlign: 'center', mt: 2}}>
                        Search for Twitter accounts followed by Alpha Centoro and have them appear in your personalized feeds.
                    </Typography>
                </Box>
            </Modal>
            <RadioGroup
                row
                aria-labelledby="account-type-filter-group-label"
                name="account-type-filter-group"
                value={influencerValue}
                onChange={handleInfluencerChange}
            >
                <FormControlLabel value="influencer" control={<Radio />} label="influencer" />
                <FormControlLabel value="official" control={<Radio />} label="official" />
                <FormControlLabel value="both" control={<Radio />} label="both" />
            </RadioGroup>
        </FormControl>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
            <TextField
                name='searchTerm'
                variant='outlined'
                required
                value={searchTerm}
                id='searchTerm'
                label='Search Twitter Users Followed'
                autoComplete='Search Term'
                onChange={handleSearchTermChange}
                helperText={searchTermError ? searchTermErrorMessage : null}
                error={searchTermError}
                inputProps={{ maxLength: 25 }}
                size="small"
                onKeyDown={e => keyPress(e)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Typography sx={{color: '#fff'}}>
                                @
                            </Typography>
                        </InputAdornment>
                    ),
                }}
            />

            <IconButton
                type="submit"
                sx={{ p: '10px' }}
                aria-label="search"
                color={'primary'}
                onClick={() => searchSubmitted()}
            >
                <Search />
            </IconButton>
            <IconButton
                type="submit"
                sx={{ p: '10px' }}
                aria-label="search"
                color={'primary'}
                onClick={() => clearSearch()}
            >
                <Clear />
            </IconButton>
        </Stack>
    </Stack>

  )
}

export default CustomFeedTwitterUserSearch