import React from 'react'
import { Grid, Typography, Stack, Avatar, Box, Tooltip, IconButton } from '@mui/material'

import { useTheme } from '@mui/material/styles'
const HealthDisplayFundingRates = ( { ruleThemAll } ) => {

const theme = useTheme()

  //console.log("top of HealthDisplayFundingRates and ruleThemAll is:")
  //console.dir(ruleThemAll)

  //var useThisFundingRateData = ruleThemAll[ruleThemAll.length-1] //new endpoint provides data latest first...this will not work
  var useThisFundingRateData = ruleThemAll[0]

  if (ruleThemAll.length === 0) {
    return (
      <Box sx={{width: '100%'}} >
        <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[500]}}>Funding Rates</Typography>
        <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
      </Box>
    )
  } else {  

    //console.log("useThisFundingRateData is:")
    //console.dir(useThisFundingRateData)

    var fundingRateColor = theme.palette.success.dark
    var fundingRateDesc = 'POSITIVE'
    var fundingRateFontColor = theme.palette.grey[100]
    if (useThisFundingRateData.healthData.funding_rates < 0) {
      fundingRateColor = theme.palette.error.dark
      fundingRateDesc = 'NEGATIVE'
      fundingRateFontColor = theme.palette.grey[700]
    }

    return (
        <Stack
          direction="column"
          sx={{backgroundColor: theme.palette.dark.dark, margin: '0px', padding: '5px', paddingTop: '0px', borderRadius: '5px', minHeight: '7vh', cursor: 'pointer'}}
        >
          <Stack
            direction="row"
            display="flex"
            justifyContent='space-between'
            alignItems="center"
          >
            <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>Funding Rates</Typography>
          </Stack>
          <Box sx={{width: '99%', backgroundColor: theme.palette.dark.dark, paddingTop: '8px', paddingBottom: '5px'}} >
            <Stack direction="column" alignItems="center" >
              <Avatar sx={{width: 50, height: 50, bgcolor: fundingRateColor, display: 'flex', justifyContent: 'center', fontSize: '.8rem', color: fundingRateFontColor }}>{useThisFundingRateData.healthData.funding_rates.toFixed(4)}</Avatar>
              <Typography variant="Button" sx={{marginLeft: '5px', fontWeight: 700, color: fundingRateColor}}>{fundingRateDesc}</Typography>
            </Stack>
          </Box>
        </Stack>
    )
  }
}
export default HealthDisplayFundingRates