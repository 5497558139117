import { Box, Button, Divider, IconButton, Pagination, Stack, Tooltip, Typography } from "@mui/material";
import CustomFeedManager from "./CustomFeedManager";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import React, { useCallback } from "react";
import CustomFeedList from "./CustomFeedList";
import CustomFeedSearch from "./CustomFeedSearch";

const CustomFeedColumn = ({colName, searchField, setSearchField, searchFeedFn, filterByKeywordsFn, filteringByKeywords, handleAddUserByHandleAndCol, userObject, refreshFeed, colData, colLoading, colCount, colPage, colPageCount, changeColPage}) => {

    const Footer = useCallback(() => {
        return (
            <Box>
                <Stack direction="row" display="flex" alignItems="center">
                    <Typography variant="button" sx={{marginRight: '20px'}}>Results: {colCount} </Typography>
                    <Pagination sx={{}} shape="rounded" count={colPageCount} page={colPage} onChange={changeColPage}/>
                </Stack>
            </Box>
        )
    }, [colCount, colPage, colPageCount, changeColPage])

    // const Input = useCallback(() => {
    //     return (
    //
    //     )
    // }, [searchField, handleChangeSearchField])

    return (
        <Box sx={{height: '100%', mt: 4, mr: 2, minWidth: 600, flex: 1, p: 2, borderRadius: 2, border: '1px solid grey'}}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography sx={{color: '#fff', textAlign: 'center', fontWeight: 'bold', fontSize: 18}}>{`Custom Feed ${colName}`}</Typography>
                <Stack direction={'row'} alignItems={'center'}>
                    <Tooltip title={"Filter by Keywords"}>
                        <Button
                            sx={{
                                borderRadius: 5,
                                border: filteringByKeywords ? '1px solid #1DA1F2' : '1px dashed grey',
                                backgroundColor: filteringByKeywords ? '#1DA1F2' : 'transparent',
                                color: filteringByKeywords ? '#fff' : '#1DA1F2'
                            }}
                            onClick={() => filterByKeywordsFn(colName)}
                        >
                            S
                        </Button>
                    </Tooltip>
                    <CustomFeedManager column={colName} handleAddUserByHandleAndCol={handleAddUserByHandleAndCol} userObject={userObject} refreshFeed={refreshFeed}/>
                </Stack>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{pb: 1}}>
                <CustomFeedSearch colName={colName} searchField={searchField} searchFeedFn={searchFeedFn} setSearchField={setSearchField} />
                <IconButton onClick={() => {
                    setSearchField('')
                    searchFeedFn('')
                }}>
                    <ClearIcon color={"primary"} />
                </IconButton>
                <IconButton onClick={() => searchFeedFn(searchField)}>
                    <SearchIcon color={'primary'} />
                </IconButton>
            </Stack>
            <Divider/>
            <CustomFeedList colData={colData} colLoading={colLoading} />
            <Footer/>
        </Box>
    )
}

export default CustomFeedColumn;