import React from 'react';

const Footer = () => {
    return (
        <div 
            className="Footer" 
            style={{ 
                backgroundImage: 'url(pic.jpg)', 
                backgroundSize: 'cover',
                height: '7vh',
                width: 'auto',
                whiteSpace: 'none',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'white',
                padding: '15px',    
            }}>

            <h1>Sign Up For A Membership!</h1>

            <a href="https://alphacentoro.com/register" target="_blank" rel="noopener noreferrer">
            <button 
                class="button button5"
                style={{
                    backgroundColor: 'black',
                    padding: '14px 80px',
                    borderRadius: '15px',
                    cursor: 'pointer',
                    color: 'white',                        
                }}
                >Sign Up</button>
            </a>

        </div>

    );
}
export default Footer;