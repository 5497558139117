import { Typography, Stack, Button, Container } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

const PasswordResetSuccess = () => {
  const theme = useTheme()
  return (
    <Container component='main' maxWidth='xs' sx={{marginTop: '50px', justifyContent: 'center'}}>
      <Stack direction="column">
        <Stack direction="row" sx={{marginBottom: '20px'}}>
          <CheckCircle fontSize='large' color={theme.palette.success.dark} sx={{marginRight: '20px'}}/>
          <Typography>
            Your password has been successfully reset!<br/>
            You can now login with your new password.
          </Typography>
        </Stack>
        <Button variant='contained' color='primary' href='/login'>
          Login Now
        </Button>
      </Stack>
    </Container>

  )
}

export default PasswordResetSuccess
