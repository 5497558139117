import React from 'react'

import { FormControlLabel, Checkbox } from '@mui/material'

const TweetDisplayByCoinSelectorItem = ( {selectedCoins, coin, saveCoinToSelection, removeCoinFromSelection} ) => {
  //console.log("top of TweetDisplayByCoinSelectorItem")
  //console.log("coin is: ")
  //console.dir(coin)
  //console.log("selectedCoins is:")
  //console.dir(selectedCoins)
  var coinLabel = coin.name + " ("+ coin.coin.symbol+")"

  if (selectedCoins.length > 0 && selectedCoins.filter(thisCoin=>coin.coin.id === thisCoin).length > 0) {
    //display ability to remove coin from list
    return (
      <FormControlLabel
        control={
          <Checkbox 
            key={coin} 
            checked={true} 
            onChange={(e) => removeCoinFromSelection(coin.coin.id)} 
            name={coin.coin.id} />
        }
        label={coinLabel}
        key={coin}

      />
    )
  } else {
    //display ability to add a coin to the list
    return (
      <FormControlLabel
        control={
          <Checkbox 
            key={coin} 
            checked={false} 
            onChange={(e) => saveCoinToSelection(coin.coin.id)} 
            name={coin.coin.id} />
        }
        label={coinLabel}
        key={coin}
      />
    )
  }

}

export default TweetDisplayByCoinSelectorItem