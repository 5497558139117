import React, { useState, useEffect } from 'react'
import {
  CircularProgress,
  Grid,
  Box,
  Stack,
  Chip,
  ButtonGroup,
  Button,
  Typography,
  Modal,
  Backdrop,
  Fade,
  TextField,
  InputLabel, Select, MenuItem, FormControl, ThemeProvider
} from '@mui/material'
import fetchData from '../../utility/endpointFetch'
import { styled, useTheme, createTheme } from '@mui/material/styles'

import Chart from "react-apexcharts"

import { AiOutlineBell } from 'react-icons/ai'
import { ImCancelCircle } from 'react-icons/im'

const VIPChartTabsSelected = ( { coinChart, coinChartName, chartAnnotations, userObject } ) => {
  //console.log("top of VIPChartTabsSelected")
  //console.log("coinChart is: " + coinChart)
  //console.log("chartAnnotations is: ")
  //console.dir(chartAnnotations) //should be empty if "user.is_internal is false"
  //console.dir(userObject)


  const theme = useTheme()

  const [isLoading,setIsLoading] = useState(true)
  const [chartInterval,setChartInterval] = useState('hourly')
  const [chartDays,setChartDays] = useState(7)
  const [pageSize,setPageSize] = useState(200) //using hourly over 14 days by default
  //const [btcChartData,setBTCChartData] = useState([]) //fixed to default values for now...change later
  const [mixedChartSeries,setMixedChartSeries] = useState()
  const [mixedChartOptions,setMixedChartOptions] = useState()
  const [chartBaseCurrencySymbol,setChartBaseCurrencySymbol ] = useState('$ ')
  const [chartBaseCurrency,setChartBaseCurrency ] = useState('USD')
  const [chartRangeDetails,setChartRangeDetails ] = useState('1 week/hourly intervals')

  const [ priceAlertOpen,setPriceAlertOpen] = useState(false)
  const [ priceAlert,setPriceAlert] = useState(0)
  const [ priceAlertError,setPriceAlertError] = useState(false)

  const buildChart = (chartData) => {
    var minMaxPrices = chartData.map( (data) => {
      return data.price
    })

    var chartMax = minMaxPrices.reduce((a, b) => { return Math.max(a, b) });
    var chartMin = minMaxPrices.reduce((a, b) => { return Math.min(a, b) });
    //console.log("chartMax for prices is: " + chartMax)
    //console.log("chartMin for prices is: " + chartMin)

    //if using coingecko
    //var firstValue = chartData[0].price
    //var lastValue = chartData[chartData.length - 1].price

    //if using our data
    var firstValue = chartData[chartData.length - 1].price
    var lastValue = chartData[0].price

    var chartColor = '#109e10'
    var legendPosition = 'left'
    if (firstValue > lastValue) {
      //price went down over chart
      chartColor = '#9e1010'
      legendPosition = 'right'
    }

    var chartPriceswithDateArrTimestamp = chartData.map( (data) => {
      return [ data.timestamp , data.price ]
    })

    var volumeDataArrWithTimeStamp = chartData.map( (data) => {
      return [ data.timestamp , data.volume ]
    })

    var dateArr = chartData.map( (data) => {
      var date = new Date(data.timestamp)
      var year = date.getFullYear()
      var month = date.getMonth()
      var day = date.getDate()

      return day+'-'+month+'-'+year
    })



    //there is no market cap data in current result set from backend
    //var marketDataArrWithTimestamp = response.market_caps.map( (data) => {
    //  return [ data[0], data[1] ]
    //})

    //find min max market cap for chart
    //var minMaxMarketCap = response.market_caps.map( (data) => {
    //  return data[1]
    //})

    //var chartMaxMarketCap = minMaxMarketCap.reduce((a, b) => { return Math.max(a, b) });
    //var chartMinMarketCap = minMaxMarketCap.reduce((a, b) => { return Math.min(a, b) });

    //var chartMaxMarketCapUsed = (3 * chartMaxMarketCap)

    //find min max volume for chart
    var minMaxVolumes = chartData.map( (data) => {
      return data.volume
    })

    var chartMaxVolume = minMaxVolumes.reduce((a, b) => { return Math.max(a, b) });
    var chartMinVolume = minMaxVolumes.reduce((a, b) => { return Math.min(a, b) });

    var chartMaxVolumeUsed = (3 * chartMaxVolume)

    setMixedChartSeries([
      {
        name: "Price",
        type: "area",
        data: chartPriceswithDateArrTimestamp
      },
      {
        name: "Volume",
        type: "column",
        data: volumeDataArrWithTimeStamp,
      },
      //{
      //  name: "Market Cap",
      //  type: "column",
      //  data: marketDataArrWithTimestamp,
      //}
    ])

    setMixedChartOptions({
      chart: {
        type: 'area',
        id: "selected-price-volume-chart",
        group: "vipcharttabsselected",
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            }
          },
          autoSelected: 'zoom'          
        },
        background: theme.palette.background.default,
        events: {
          click: function(event, chartContext, config) {
            if (userObject.user.is_vip) {
              //console.log("top of chart click event (event, chartContext, config):")
              //console.dir(event)
              //console.dir(chartContext)
              //console.dir(config)
              console.log("default price alert set to: " + config.globals.series[0][config.dataPointIndex])
              if (config.globals.series[0][config.dataPointIndex] !== undefined) {
                //console.log("opening price alert dialog")
                setPriceAlert(config.globals.series[0][config.dataPointIndex])
                setPriceAlertOpen(true)
              } else {
                //console.log("NOT opening price alert dialog")
              }
            } else {
              //this is a vip only feature
            }
          }
        }
      },
      colors: [chartColor, '#10459c', '#eba834'],
      stroke: {
        show: true,
        width: [ 2, 0, 0 ]
      },
      dataLabels: {
        enabled: false,
      },
      labels: dateArr,
      xaxis: {
        type: 'datetime',
        tickPlacement: 'on',
        labels: {
          show: true,
          datetimeUTC: false,
        }
      },
      annotations: {
        position: 'back',
        xaxis: chartAnnotations,
      },
      yaxis: [
        {
          seriesName: "Price",
          opposite: false,
          show: true,
          min: chartMin,
          max: chartMax,
          forceNiceScale: false,
          labels: {
            show: true,
            formatter: (value) => {
              if (value < 10) {
                let fixedDecimal = 2
                if (value < .00001) 
                  fixedDecimal = 7
                else if (value < .0001)
                  fixedDecimal = 6
                else if (value < .001)
                  fixedDecimal = 5
                else if (value < .01)
                  fixedDecimal = 4
                else if (value < .1)
                  fixedDecimal = 4   
                return (value).toFixed(fixedDecimal).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                //return parseFloat(value)
              } else 
              return parseInt(value)
            },
            style: {
              colors: [ '#bf3932']
            }
          },
          crosshairs: {
            show: true,
            position: 'front'
          },
          tooltip: {
            shared: true,
            intersect: false,
            x: {
              show: false,
              format: 'dd MMM yyyy',

            },
            marker: {
              show: true,
            },


          },
        },
        {
          seriesName: "Volume",
          opposite: true,
          axisTicks: {
            "show": true,
          },
          show: false,
          min: chartMinVolume,
          max: chartMaxVolumeUsed,
        },
        //{
        //  seriesName: "Market Cap",
        //  opposite: true,
        //  show: false,
        //  min: chartMinMarketCap,
        //  max: chartMaxMarketCapUsed,
        //}
      ],
      fill: {
        type: 'gradient',
        gradient: {
            shade: 'light',
            type: "vertical",
            shadeIntensity: 1,
            gradientToColors: [theme.palette.background.default],
            inverseColors: false,
            opacityFrom: 0.9,
            opacityTo: 0.5,
        },
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: false,
          format: 'dd MMM yyyy',
        },
        y: {
          formatter: function (value, index) {
            if (value === index.series[0][index.dataPointIndex]) {
              //this is a price
              let fixedDecimal = 2
              if (value < .00001) 
                fixedDecimal = 7
              else if (value < .0001)
                fixedDecimal = 6
              else if (value < .001)
                fixedDecimal = 5
              else if (value < .01)
                fixedDecimal = 4    
              else if (value < .1)
                fixedDecimal = 4                          
              return chartBaseCurrencySymbol + " " + (value).toFixed(fixedDecimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " " + chartBaseCurrency
            } else if (value === index.series[1][index.dataPointIndex]) {
              //this is volume...just remove after .
              return Math.round(value)
            } else if (value === index.series[2][index.dataPointIndex]) {
              return chartBaseCurrencySymbol + " " + (value).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " " + chartBaseCurrency
            } else {
              // not sure what it is...return unchanged
              return value
            }
          }
        },
        marker: {
          show: true,
        },
        fixed: {
          enabled: true,
          position: 'bottomLeft'
        }
      },
      legend: {
        position: legendPosition,
        floating: true,
        offsetX: 10,
        offsetY: 10,
        labels: {
          colors: theme.palette.grey[300]
        }
      }
    })
  }

  useEffect(() => {
    setIsLoading(true)
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        var dateAgo = new Date(Date.now() - (chartDays * 24 * 60 * 60 * 1000))
        //console.log("timestamp used to pull marketchart data: " + dateAgo.toISOString())

        var urlString = `api/v1/coin/marketchart/?coin=${coinChart}&interval=${chartInterval}&timestamp__gt=${dateAgo.toISOString()}&page_size=${pageSize}&page=1`
        const chartResponse = await fetchData(urlString, "could not receive coinmarket data", fetchParams).promise
        buildChart(chartResponse.results)
        setIsLoading(false)
      } catch (error) {
        console.log("something is broke getting marketchart in VIPChartTabsSelected: " + error)
      }
    })()

  }, [coinChart, chartAnnotations, chartDays, chartInterval])

  const handleChartTimeChange = ( days) => {
    //console.log("top of handleChartTimeChange...days is: ")
    //console.dir(days)
    setChartDays(days)
    if (days > 14) {
      setChartInterval('daily')
      setPageSize(days)
    } else if (days > 3) {
      setChartInterval('hourly')
      setPageSize(days*24)
    } else if (days > 1) {
      setChartInterval('quarter_hour')
      setPageSize(days*96)
    } else {
      setChartInterval('5m')
      setPageSize(days*288)
    }

    switch (days) {
      case 365:
        setChartRangeDetails('1y/daily intervals')
        break;
      case 180:
        setChartRangeDetails('180 days/daily intervals')
        break;
      case 90:
        setChartRangeDetails('90 days/daily intervals')
        break;
      case 30:
        setChartRangeDetails('30 days/daily intervals')
        break;
      case 14:
        setChartRangeDetails('2 weeks/hourly intervals')
        break;
      case 7:
        setChartRangeDetails('1 week /hourly intervals')
        break;
      case 3:
        setChartRangeDetails('3 days/15m intervals')
        break;
      case 1:
        setChartRangeDetails('1 day/5 minute intervals')
        break;
      default:
        //not sure what this is
        setChartRangeDetails('')
    }
  }  

  const saveAlert = e => {
    e.preventDefault()
    //console.log("top of saveAlert")

    if ((priceAlert === 0) || (priceAlert === '')) {
      setPriceAlertError(true)
    } else if (priceAlert > 0) {
      //console.log("priceAlert is greater than 0")
      if (userObject.user.is_vip) {
        const fetchParams = {
          method: 'POST',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'coin': coinChartName, 
            'price_target': priceAlert,
            'multiple': alertMultiple,
          })        
        }
        ;(async () => {
          try {
            var urlString = `api/v1/vip/alertcreate/`
            const saveAlertResponse = await fetchData(urlString, "could not save new price alert", fetchParams).promise
            console.log(saveAlertResponse)
            //console.log("saveAlertResponse saving new price alert: ")
            //console.log(urlString)
            //console.dir(saveAlertResponse)
          } catch (error) {
            console.log("something is broke new price alert: ", error)
          }
        })()
      }
      setPriceAlertOpen(false)
    } else {
      console.log("priceAlert is zero or less")
      setPriceAlertError(true)
    }
  }

  const cancelAlert = e => {
    setPriceAlertOpen(false)  
  }

  const handlePriceAlertChange = e => {
    setPriceAlertError(false)
    setPriceAlert(e.target.value)
  }  

  /*
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary800,
    },
  }));
  */
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
        backgroundColor: theme.palette.background.default
    },
    '& .MuiButton-root': {
        padding: '3px 3px',
        minWidth: '20px',
    },
    '& .MuiButtonGroup-grouped': {
        minWidth: '20px',
    },
  }));   

  const NonColorButton = styled(Button)(({ theme }) => ({
      '& .MuiButton-root': {
          padding: '3px 3px',
          minWidth: '20px',
      },
      '& .MuiButtonGroup-grouped': {
          minWidth: '20px',
      },        
  }));  
  const themeButton = createTheme({
    ...theme,
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            minWidth: '20px',
            padding: '3px 3px',
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          grouped: {
            minWidth: '20px',
          }
        }
      }
    },
  })  

  const [alertMultiple, setAlertMultiple] = React.useState(1);

  const handleChange = (event) => {
    setAlertMultiple(event.target.value);
  };

  //if (isLoading) return <CircularProgress />
  return (
    <ThemeProvider theme={themeButton}>
    <Stack direction="column" sx={{width: '100%'}}>
      <Stack direction="row" sx={{justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', marginTop: '21px', paddingLeft: '5px', paddingRight: '5px'}}>
        <Chip label={coinChartName} sx={{backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.light}} />
        <ButtonGroup size="small" aria-label="small button group" sx={{paddingRight: '10px'}}>
          { chartDays === 365 ? <ColorButton  variant="contained">1Y</ColorButton> : <NonColorButton onClick={() => handleChartTimeChange(365)}>1Y</NonColorButton> }
          { chartDays === 180 ? <ColorButton  variant="contained">6M</ColorButton> : <NonColorButton onClick={() => handleChartTimeChange(180)}>6M</NonColorButton> }
          { chartDays === 90 ? <ColorButton  variant="contained">3M</ColorButton> : <NonColorButton onClick={() => handleChartTimeChange(90)}>3M</NonColorButton> }
          { chartDays === 30 ? <ColorButton variant="contained">1M</ColorButton> : <NonColorButton onClick={() => handleChartTimeChange(30)}>1M</NonColorButton> }
          { chartDays === 14 ? <ColorButton  variant="contained">2W</ColorButton> : <NonColorButton onClick={() => handleChartTimeChange(14)}>2W</NonColorButton> }
          { chartDays === 7 ? <ColorButton  variant="contained">1W</ColorButton> : <NonColorButton onClick={() => handleChartTimeChange(7)}>1W</NonColorButton> }
          { chartDays === 3 ? <ColorButton  variant="contained">3D</ColorButton> : <NonColorButton onClick={() => handleChartTimeChange(3)}>3D</NonColorButton> }
          { chartDays === 1 ? <ColorButton  variant="contained">1D</ColorButton> : <NonColorButton onClick={() => handleChartTimeChange(1)}>1D</NonColorButton> }
        </ButtonGroup>
        <Chip label={chartRangeDetails} sx={{backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.light}} />

      </Stack>
      {isLoading ? <CircularProgress /> :
        <Chart
          options={mixedChartOptions}
          series={mixedChartSeries}
          type="area"
          height={300}
        />
      }
      <Modal
        aria-labelledby='spring-modal-price-alert'
        aria-describedby='spring-modal-price-alert-description'
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
        open={priceAlertOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        disableEscapeKeyDown={true}
      >
        <Fade in={priceAlertOpen}>
          <Box sx={{ position: 'absolute', width: 600, backgroundColor: theme.palette.dark[800],  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)', borderRadius: '8px'}}>
            <h2 id='spring-modal-price-alert'>Create A Price Alert For {coinChartName}</h2>
            <p id='spring-modal-price-alert-description' style={{ fontSize: '1rem' }}>
              You can have us notify you when price of {coinChartName} hits a certain price.  Just insert the value below and hit enter
              <br /><br />
              <Grid container>
                <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                  <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around">
                    <Typography variant="subtitle2"></Typography>
                    <TextField
                      type='text'
                      required
                      fullWidth
                      id='priceAlert'
                      label='price'
                      defaultValue={priceAlert}
                      name='priceAlert'
                      onChange={handlePriceAlertChange}
                      helperText={priceAlertError ? 'Your price must be more than 0' : null}
                      error={priceAlertError}
                      inputProps={{ maxLength: 100 }}
                      variant="filled"
                      focused                
                    />                  
                  </Stack>
                </Grid>
                <FormControl fullWidth style={{ paddingBottom: '25px'}}>
                  <InputLabel id="demo-simple-select-label">Number of Alerts</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={alertMultiple}
                      label="Number of Alerts"
                      onChange={handleChange}
                  >
                    <MenuItem value={1}>One</MenuItem>
                    <MenuItem value={3}>Three</MenuItem>
                    <MenuItem value={5}>Five</MenuItem>
                  </Select>
                </FormControl>
                <Grid item xs={12}>
                  <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                    <Button startIcon={<AiOutlineBell />} variant='outlined' onClick={saveAlert} color='primary'>
                      Click To Save Your Alert
                    </Button>
                    <Button startIcon={<ImCancelCircle />} variant='outlined' onClick={cancelAlert} color='warning' sx={{ml: 1}}>
                      Cancel
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </p>
          </Box>
        </Fade>
      </Modal>            
    </Stack>
    </ThemeProvider>
  )
}

export default VIPChartTabsSelected