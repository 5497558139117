export const componentBaseProperties = {
    "tweetDisplayByCoin": { 
        minW: 3, 
        minH: 2,
        isBounded: true, 
        isDraggable: true,  
        name: 'TweetDisplayByCoin', 
        coins: ['bitcoin'],
        influencer_coin: '',
        list: null,
        currentDependency: true,
        static: false, 
        isResizable: true,
        resizeHandles: [ 'se' ],
        selectedTab: '1',
    }, 
    "chartDisplayByCoin": {
        minW: 3, 
        minH: 3,            
        isBounded: true, 
        isDraggable: true, 
        name: 'ChartDisplayByCoin', 
        coins: ['btc'],
        influencer_coin: 'bitcoin',
        list: null,
        currentDependency: true,
        static: false, 
        isResizable: true,
        selectedTab: '1',
    },
    "watchListDisplay": {
        minW: 2, 
        minH: 3,            
        isBounded: true, 
        isDraggable: true, 
        coins: [],
        influencer_coin: '',
        name: 'WatchListDisplay', 
        list: 'Favorites',
        currentDependency: null,
        static: false, 
        isResizable: true,
        selectedTab: '1',            
    },        
    "researchDisplay": {
        minW: 5, 
        minH: 3,            
        isBounded: true, 
        isDraggable: true, 
        coins: [],
        influencer_coin: '',
        name: 'ResearchDisplay', 
        list: null,
        currentDependency: null,
        static: false, 
        isResizable: true,            
    }, 
    "influencerDisplay": {
        minW: 5, 
        minH: 3,            
        isBounded: true, 
        isDraggable: true, 
        coins: [],
        influencer_coin: '',
        name: 'SocialInfluencerDisplay', 
        list: null,
        currentDependency: null,
        static: false, 
        isResizable: true,
        selectedTab: '1',            
    },      
    "vipAlertsDisplay": {
        minW: 4, 
        minH: 4,            
        isBounded: true, 
        isDraggable: true, 
        coins: [],
        influencer_coin: '',
        name: 'VIPAlertsDisplay', 
        list: null,
        currentDependency: null,
        static: false, 
        isResizable: true,
        selectedTab: '1',           
    },    
    "healthDisplay": {
        minW: 3, 
        minH: 3,            
        isBounded: true, 
        isDraggable: true, 
        coins: [],
        influencer_coin: '',
        name: 'HealthDisplay', 
        list: null,
        currentDependency: null,
        static: false, 
        isResizable: true,
        selectedTab: '1',
    },  
    "vipTweetDisplay": {
        minW: 3, 
        minH: 3,            
        isBounded: true, 
        isDraggable: true, 
        coins: [],
        influencer_coin: '',
        name: 'VIPTweetsDisplay', 
        list: null,
        currentDependency: null,
        static: false, 
        isResizable: true,
        selectedTab: '1',
    },              
    "portfolioDisplay": {
        minW: 3, 
        minH: 3,            
        isBounded: true, 
        isDraggable: true, 
        coins: [],
        influencer_coin: '',
        name: 'PortfolioDisplay', 
        list: null,
        currentDependency: null,
        static: false, 
        isResizable: true,
        selectedTab: '1',
    },
    "feedDisplay": {
        minW: 3, 
        minH: 3,            
        isBounded: true, 
        isDraggable: true, 
        coins: [],
        influencer_coin: '',
        name: 'FeedDisplay', 
        list: null,
        currentDependency: null,
        static: false, 
        isResizable: true,
        selectedTab: '1',
    },        
    "customTweetFeedsDisplay": {
        minW: 3, 
        minH: 3,            
        isBounded: true, 
        isDraggable: true, 
        coins: [],
        influencer_coin: '',
        name: 'CustomTweetFeedsDisplay', 
        list: null,
        currentDependency: null,
        static: false, 
        isResizable: true,
        selectedTab: '1',
    },   
    "chartDisplayBTC": {
        minW: 3, 
        minH: 3,            
        isBounded: true, 
        isDraggable: true, 
        name: 'ChartDisplayBTC', 
        coins: ['btc'],
        influencer_coin: 'bitcoin',
        list: null,
        currentDependency: false,
        static: false, 
        isResizable: true,
        selectedTab: '1',
    },        
}