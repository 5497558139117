import React from 'react'

import { AppBar, Toolbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Header from '../Header'

const TopAppBar = ( { userObject, selectedPage }) => {
  console.log("top of TopAppBar and userObject is:")
  console.log(userObject)
  const theme = useTheme()

  return (
    <AppBar
      enableColorOnDark
      position="fixed"
      color="inherit"
      elevation={0}
      sx={{
          bgcolor: theme.palette.background.default,
          transition: 'none',
          padding: 0,
      }}
    >
      <Toolbar sx={{ paddingLeft: 0, paddingTop: '5px'}}>
        <Header userObject={userObject} selectedPage={selectedPage} />
      </Toolbar>
    </AppBar>        
  )
}
export default TopAppBar