import { useState, useRef, useEffect } from 'react';

import { useNavigate, navigate } from 'react-router-dom';
import { BsBank2 } from 'react-icons/bs'
import auth from '../Auth/auth'

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Button,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';
import ProfileSectionFavoriteCoins from './ProfileSectionFavoriteCoins'
import fetchData from '../../utility/endpointFetch'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from '../ui-component/cards/MainCard';
import Transitions from '../ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';

// assets
import { IconLogout, IconEdit, IconSettings, IconUser } from '@tabler/icons';
import { Campaign, PersonAddAlt } from '@mui/icons-material'
import { AiFillBell } from "react-icons/ai"
import { RiUserReceived2Fill } from 'react-icons/ri'

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = ( {} ) => {
    const theme = useTheme();
    //const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)
    const [userDetails,setUserDetails] = useState({})
    const [favoriteCoinsList,setFavoriteCoinsList] = useState([])

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        console.log('Logout');
        auth.logout()
        navigate('/login')
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const makeVIP = () => {
        const fetchParams = {
            method: 'PUT',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },
        }
        ;(async () => {
        try {
            const response = await fetchData(`api/v1/users/upgrade/`, "could not upgrade user to vip status", fetchParams).promise
            console.log("response upgrading user to vip status")
            console.dir(response)
            if (!response)
                console.log("something is broke upgrading user to vip: " + response)
            else {
                //need to reset user details with new state for "is_vip"
            }
        } catch (error) {
            console.log("something is broke upgrading user to vip: " + error)
        }
        })()
        auth.logout()
        navigate('/login')
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {

        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            const response = await fetchData(`api/v1/users/userdata/`, "could not receive  user data and favorites data", fetchParams).promise
            //console.log("response getting user data and favorites")
            //console.dir(response)
            setUserDetails(response.user)
            setFavoriteCoinsList(response.coin)
            setIsLoading(false)
          } catch (error) {
            console.log("error","something is broke getting  user data and favorites")
          }
        })()
    }, [])

    if (isLoading) return null
    return (
        <>
            <Chip
                sx={{
                    height: '35px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.dark, //theme.palette.grey[300],  //grey[300] theme.palette.primary.light
                    backgroundColor: theme.palette.primary.dark, //theme.palette.grey[300], //grey[300] theme.palette.primary.light
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.light, //theme.palette.grey[600],  //grey[600] theme.palette.primary.light
                        background: `${theme.palette.primary.light}!important`,  //grey[600] theme.palette.primary.light
                        color: theme.palette.primary.main, //theme.palette.grey[300],  //grey[300] theme.palette.primary.light
                        '& svg': {
                            stroke: theme.palette.primary.main, //theme.palette.grey[300]  //grey[300] theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <IconUser color={theme.palette.primary.light} />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.light} />}  // theme.palette.primary.main
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2, backgroundColor: theme.palette.dark[800]}}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center" sx={{ justifyContent:'space-between', display: 'flex'}} >
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {userDetails.username}
                                                </Typography>
                                                <Button onClick={handleLogout} sx={{display: 'flex', justifyContent: 'center', letterSpacing: 2}}>Logout <IconLogout stroke={1.5} size="1.3rem" sx={{paddingLeft: '3px'}} /></Button>
                                            </Stack>
                                            <Typography variant="subtitle2">{userDetails.email}</Typography>
                                        </Stack>
                                        <Divider />
                                    </Box>

                                    <Box sx={{ p: 2, margin: '12px', backgroundColor: theme.palette.dark[800], borderRadius: '10px'}}>
                                        {/* <UpgradePlanCard /> */}
                                        <Stack direction="column" display="flex" alignItems="flex-start">
                                            <Button href="/user" startIcon={<IconEdit />}>Edit User Details</Button>
                                            {/*<Button href="/pt" startIcon={<BsBank2 />}>Portfolio</Button>*/}
                                            <Button href="/resetpassword" startIcon={<IconEdit />}>Change Password</Button>
                                            <Button href='https://forms.gle/Yabo9vAtR6YkutBF8' target='blank' startIcon={<Campaign />}>Leave Feedback</Button>
                                            <Button href="/pricealerts" startIcon={<AiFillBell />}>Price Alerts</Button>
                                            <Button href="/userreferrals" startIcon={<RiUserReceived2Fill />}>User Referrals</Button>
                                            {/*<Divider />
                                            <Button onClick={ () => makeVIP()} startIcon={<PersonAddAlt />}>Make VIP</Button>*/}
                                        </Stack>
                                    </Box>
                                        {/*
                                        <Box sx={{ p: 2, margin: '12px', backgroundColor: theme.palette.warning.light, borderRadius: '10px'}}>
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                }
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, '/user/account-profile/profile1')}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 1}
                                                    onClick={(event) => handleListItemClick(event, 1, '/user/social-profile/posts')}
                                                >
                                                    <ListItemIcon>
                                                        <IconSend stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Grid container spacing={1} justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="body2">Notification Configuration</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItemButton>


                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px`, padding: '5px' }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>

                                            </List>
                                        </Box>*/}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;