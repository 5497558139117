import React, {useState, useRef, useCallback} from 'react'
import {
    Box,
    CssBaseline,
    Container,
    Grid,
    Popover,
} from '@mui/material'
import {  useTheme } from '@mui/material/styles'

import ResearchSearchForm from './ResearchSearchForm'
import ResearchTwitterResults from './ResearchTwitterResults'
import TopAppBar from '../TopAppBar'
import ResearchCoinChartBTC from './ResearchCoinChartBTC'
import ResearchCoinChart from './ResearchCoinChart'
import ResearchBodyResults from './ResearchBodyResults'
import ResearchTutorial from '../ResearchTutorial'
import ResearchTutorialButtons from '../ResearchTutorial/ResearchTutorialButtons'

const Research3 = ({ userObject }) => {
    console.log("top of Research3")

    const getResearchTutorialUsedCount = () => {
        return localStorage.getItem("research_tutorial_used_count")
    }

    const setResearchTutorialUsedCount = () => {
        localStorage.setItem("research_tutorial_used_count", 0)
    }

    const incrementResearchTutorialUsedCount = () => {
        localStorage.setItem("research_tutorial_used_count", (parseInt(getResearchTutorialUsedCount())+1))
    }

    const getCurrentResearchTutorialUsedCount = () => {
        let returnValue = getResearchTutorialUsedCount()
        if ( returnValue === null) {
            setResearchTutorialUsedCount()
            return 1
        } else {
            return returnValue
        }
    }
    const theme = useTheme()

    const [chartCoin,setChartCoin] = useState('')
    const [chartCoinSymbol,setChartCoinSymbol] = useState('')
    const [chartCoinName,setChartCoinName] = useState('')
    const [tweetCoin,setTweetCoin] = useState('')
    const [coinTweetQueryString,setCoinTweetQueryString] = useState('')

    const [chartDays,setChartDays] = useState(3)
    const [chartLabel,setChartLabel] = useState('bitcoin')
    const [chartInterval,setChartInterval] = useState('hourly')    
    const [chartRangeDetails,setChartRangeDetails] = useState('2w/hourly')

    const [tutorialOpen,setTutorialOpen] = useState(getCurrentResearchTutorialUsedCount() >= 2 ? false : true);
    const [researchTutorialStep,setResearchTutorialStep] = useState(1);
    const [popoverAnchorReference,setPopoverAnchorReference] = useState('anchorPosition'); //anchorEl, anchorPosition, none
    const [anchorPositionX,setAnchorPositionX] = useState(window.innerWidth -150); //numeric value to center popover horizontally
    const [anchorPositionY,setAnchorPositionY] = useState(window.innerHeight - 150); //numeric value to center popover vertically
    const [popoverAnchorEl,setPopoverAnchorEl] = useState(null);
    const [popoverAnchorOriginVert,setPopoverAnchorOriginVert] = useState(100);
    const [popoverAnchorOriginHoriz,setPopoverAnchorOriginHoriz] = useState(100);
    const [popoverTransformOriginVert,setPopoverTransformOriginVert] = useState(100);
    const [popoverTransformOriginHoriz,setPopoverTransformOriginHoriz] = useState(100);
    const [popoverID,setPopoverID] = useState('welcome');
    const [searchQuerySet,setSearchQuerySet] = useState('');

    const searchBoxRef = useRef();
    const researchBodyResultsRef = useRef();
    const tweetResultsRef = useRef();
    const coinChartRef = useRef();

    const showCoinTweetsAndChart = useCallback ((coinDetails) => {
        //console.log("top of showCoinTweetsAndChart")
        //console.dir(coinDetails)
        if(coinDetails.coin !== null ){
            setChartCoinName(coinDetails.coinscreener.coin)
            setChartLabel(coinDetails.coinscreener.coin) //not sure why need this
            setChartCoin(coinDetails.coinscreener.coin)
            setTweetCoin(coinDetails.coinscreener.coin)
            setChartCoinSymbol(coinDetails.coinscreener.coin_symbol)
        }
    } ,[])

    const filterCoinList = ( queryString ) => {
        console.log(queryString)
        //console.log("top of filterCoinList and queryString is: " +queryString)
        setCoinTweetQueryString(queryString)
    }

    const researchChartLabelChange = (label, setChartDaysTo, interval) => {
        console.log("top of researchChartLabelChange and label is: " + label)
        setChartLabel(label)
        setChartDays(setChartDaysTo)
        setChartInterval(interval)
        switch (setChartDaysTo) {
            case 365:
                setChartRangeDetails('1y/daily')
              break;
            case 180:
                setChartRangeDetails('6m/daily')
              break;
            case 90:
                setChartRangeDetails('3m/daily')
              break;
            case 30:
                setChartRangeDetails('1m/daily')
              break;
            case 14:
                setChartRangeDetails('2w/hourly')
              break;
            case 7:
                setChartRangeDetails('1w/hourly')
              break;
            case 3:
                setChartRangeDetails('3d/hourly')
              break;
            case 1:
                setChartRangeDetails('1d/5m')
              break;
            default:
              //not sure what this is
          }        
    }    

    const changePopover = ( newAnchorReference, newAnchorPositionX, newAnchorPositionY, newAnchorEl, newPopoverID, newPopoverAnchorOriginVert, newPopoverAnchorOriginHoriz, newPopoverTransformOriginVert, newPopoverTransformOriginHoriz ) => {
        //console.log("top of changePopover and newPopoverID is: " + newPopoverID)
        //console.log("newAnchorReference is: " + newAnchorReference)
        //console.log("newAnchorPositionX is: " + newAnchorPositionX)
        //console.log("newAnchorPositionY is: " + newAnchorPositionY)
        //console.log("newAnchorEl is:")
        //console.dir(newAnchorEl)
        switch (newAnchorEl) {
            case 'search':
                setPopoverAnchorEl(searchBoxRef.current)
                setSearchQuerySet('staking')
                break
            case 'result':
                setPopoverAnchorEl(researchBodyResultsRef.current)
                break
            case 'market':
                setPopoverAnchorEl(researchBodyResultsRef.current)
                break
            case 'chart':
                setPopoverAnchorEl(coinChartRef.current)
                break    
            case 'tweet':
                setPopoverAnchorEl(tweetResultsRef.current)
                break          
            case 'filter':
                setPopoverAnchorEl(searchBoxRef.current)
                break                                                        
            default:
                setPopoverAnchorEl('none')
                break
        }
        setPopoverAnchorReference(newAnchorReference)
        setAnchorPositionX(newAnchorPositionX)
        setAnchorPositionY(newAnchorPositionY)
        setPopoverAnchorOriginVert(newPopoverAnchorOriginVert)
        setPopoverAnchorOriginHoriz(newPopoverAnchorOriginHoriz)
        setPopoverTransformOriginVert(newPopoverTransformOriginVert)
        setPopoverTransformOriginHoriz(newPopoverTransformOriginHoriz)
        
        setPopoverID(newPopoverID)
    }

    const handleTutorialStepClick = (clickedButton) => {
        //need to set anchorEl based on the step
        //each step will attach to a different element in the design
        // create individual useRef for each component to reference within tutorial
        //those references will need to be passed in???
        if (clickedButton === "next") setResearchTutorialStep( prev => prev + 1)
        else setResearchTutorialStep( prev => prev - 1)
    }    

    const closeTutorial = () => {
        incrementResearchTutorialUsedCount()
        setTutorialOpen(false)
    }

    const openTutorial = () => {
        setTutorialOpen(true)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            
            <CssBaseline />
            {/* header */}
            <TopAppBar userObject={userObject} selectedPage="researcher"/>
            <Container maxWidth="false" sx={{marginTop: '80px'}}>
                <Grid container spacing={.5} sx={{marginTop: '10px'}}>
                    <Grid item xs={3.5} ref={tweetResultsRef} >
                        <Box sx={{backgroundColor: theme.palette.dark[800], height: window.innerHeight - 100, display: 'flex', alignItems: 'flex-start', }} >
                            <ResearchTwitterResults tweetCoin={tweetCoin} search={coinTweetQueryString}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6.5} >
                        <Box  ref={researchBodyResultsRef} sx={{ height: window.innerHeight - 100, display: 'flex', alignItems: 'flex-start',}} >
                            <Grid  container>
                                <Grid item xs={6} ref={coinChartRef}>
                                    <Box sx={{width: '100%'}}>
                                        <ResearchCoinChart
                                            coin={chartCoin}
                                            chartDays={chartDays}
                                            chartLabel={chartLabel}
                                            chartCoinSymbol={chartCoinSymbol}
                                            researchChartLabelChange={researchChartLabelChange}
                                            chartInterval={chartInterval}
                                            chartRangeDetails={chartRangeDetails}
                                            userObject={userObject}
                                            coinTweetQueryString={coinTweetQueryString}
                                            chartCoinName={chartCoinName}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{width: '100%'}}>
                                        <ResearchCoinChartBTC userObject={userObject} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{width: '100%'}}>
                                        <ResearchBodyResults
                                            showCoinTweetsAndChart={showCoinTweetsAndChart}
                                            coinTweetQueryString={coinTweetQueryString}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box ref={searchBoxRef} sx={{backgroundColor: theme.palette.dark[800], height: window.innerHeight - 100, display: 'flex', alignItems: 'flex-start', overflow: 'auto', }} >
                            <ResearchSearchForm
                                searchQuerySet={searchQuerySet}
                                filterCoinList={filterCoinList}
                                userObject={userObject}
                                tutorialOpen={tutorialOpen}
                                openTutorial={openTutorial}
                            />
                        </Box>
                    </Grid>
                </Grid>
                { openTutorial ?
                <Popover
                    open={tutorialOpen}
                    id={popoverID}
                    anchorReference={popoverAnchorReference}
                    anchorPosition={{top: anchorPositionY, left: anchorPositionX }}
                    anchorEl={popoverAnchorEl}
                    anchorOrigin={{horizontal: popoverAnchorOriginHoriz, vertical: popoverAnchorOriginVert}}
                    transformOrigin={{vertical: popoverTransformOriginVert, horizontal: popoverTransformOriginHoriz}}

                >
                    <Box sx={{width: '100%', backgroundColor: theme.palette.secondary[800]}}>
                        { researchTutorialStep === 5 ?
                            <Box sx={{marginTop: '10px'}}>
                                <ResearchTutorialButtons
                                    researchTutorialStep={researchTutorialStep}
                                    handleTutorialStepClick={handleTutorialStepClick}
                                    closeTutorial={closeTutorial}
                                />
                            </Box>
                            : null }
                        <ResearchTutorial
                            researchTutorialStep={researchTutorialStep}
                            changePopover={changePopover}
                            filterCoinList={filterCoinList}
                        />
                        { researchTutorialStep !== 5 ?
                            <ResearchTutorialButtons
                                researchTutorialStep={researchTutorialStep}
                                handleTutorialStepClick={handleTutorialStepClick}
                                closeTutorial={closeTutorial}
                            />
                            : null }
                    </Box>
                </Popover> : null }
            </Container>
        </Box>        
    )

}

export default Research3