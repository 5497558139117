import React from 'react'

import ChartDisplayByCoin from './ChartDisplayByCoin'

//const ChartDisplayByCoin = ( {coins=['bitcoin'], boxHeight, rowHeight, windowWidth, defaultChartInterval='1d', saveCoinChoiceToPanel, panelID, deleteBox, changeDependency, currentDependency, setChartTimers} ) => {
const ChartDisplayBTC = ( {boxHeight, rowHeight, windowWidth, defaultChartInterval='1d', saveCoinChoiceToPanel, panelID, deleteBox, changeDependency, currentDependency, setChartTimers}) => {
    //console.log("top of ChartDisplayBTC")
    return (
        <ChartDisplayByCoin 
            coins={['btc']}
            displayCoinSelector={false}
            boxHeight={boxHeight}
            rowHeight={rowHeight}
            windowWidth={windowWidth}
            saveCoinChoiceToPanel={null} //no need to set coin choice as this only displays bitcoin
            panelID={panelID}
            deleteBox={deleteBox}
            changeDependency={null} //no need to change dependency in this box type....only displays bitcoin
            currentDependency={null} //not used as coin change is not allowed nor choice displayed
            setChartTimers={setChartTimers}
        />
    )
}

export default ChartDisplayBTC