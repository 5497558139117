
// action - state management
import * as actionTypes from './types';

export const initialState = {
    columnOneHandles: [],
    columnTwoHandles: [],
    columnThreeHandles: [],
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const tweetsScreenReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_COLUMN_1_HANDLES:
            return {
                ...state,
                columnOneHandles: action.payload
            };
        case actionTypes.SET_COLUMN_2_HANDLES:
            return {
                ...state,
                columnTwoHandles: action.payload
            };
        case actionTypes.SET_COLUMN_3_HANDLES:
            return {
                ...state,
                columnThreeHandles: action.payload
            };
        default:
            return state;
    }
};

export default tweetsScreenReducer;