import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import TokenWizardHelperText from "./TokenWizardHelperText";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import React from "react";
import { useTheme } from '@mui/material/styles'

const TokenGenEvent = ({pageData, setPageData}) => {
    const theme = useTheme()

    const handleTokenGenDateChoiceChange = (e) => {
        setPageData({
            ...pageData,
            tokenGenDateChoice: e.target.value,
            showTokenGenDateError: false,
        })
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant="h2" sx={{color: theme.palette.primary.main, marginTop: '20px'}}>Token Generation Event</Typography>
            <Stack direction="row" display='flex' alignItems='center'>
                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500] }}>
                    Does the token have a Token Generation Event?
                </Typography>
                <TokenWizardHelperText
                    title={'Token Generation Event'}
                    text={'Many projects have a specific date when tokens are generated. If a project is releases new tokens monthly (1 day of the month), and the TGE date was 6 June, then each month on the 6th is when new tokens will be released.\n Note: The Details page of the project will show this info once uploaded.'} />
            </Stack>
            <FormControl sx={{mt: 4}}>
                <RadioGroup
                    aria-labelledby="tradepost-radio-buttons-group"
                    name="tradepost-radio-buttons-group"
                    value={pageData.tokenGenDateChoice}
                    onChange={handleTokenGenDateChoiceChange}
                >
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                        {
                            pageData.tokenGenDateChoice === "Yes" && (
                                <Box sx={{width: 350}}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Token Generation Date"
                                            inputFormat="yyyy-MM-dd"
                                            value={pageData.tokenGenerationDate}
                                            onChange={(newValue) => {
                                                //console.log("top of onChange for desktopdatepicker token generation date" )
                                                //console.dir(newValue)
                                                setPageData({
                                                    ...pageData,
                                                    tokenGenerationDate: newValue
                                                })
                                            }}
                                            variant='outlined'
                                            mask="____-__-__"
                                            color="white"
                                            //views={['day']}
                                            renderInput={
                                                (params) => <TextField disabled {...params} />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Box>
                            )
                        }
                    </Box>
                    <FormControlLabel value={"No"} control={<Radio />} label="No" />
                    <FormControlLabel value={"Unknown"} control={<Radio />} label="Unknown" />
                </RadioGroup>
            </FormControl>
            {
                pageData.showTokenGenDateError && (
                    <Typography align='center' variant="h3" sx={{ mt: 1, color: theme.palette.error.main }}>
                        Please fill in a date or select No.
                    </Typography>
                )
            }
        </Box>
    )
}

export default TokenGenEvent;
