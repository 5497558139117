import React, { useCallback, useEffect, useState} from 'react';
import {
    Autocomplete, Stack, 
    Typography,
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Collapse,
    TextField,
    Button, IconButton, CircularProgress
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import WalletRow from "./WalletRow";
import fetchData from "../../utility/endpointFetch";
import PortfolioChooseCoinPopover from './PortfolioChooseCoinPopover'

import WalletHeader from './WalletHeader'

import { useTheme } from '@mui/material/styles'

const Wallet = ({wallet, userData, walletTotals, setWalletTotals, removeWallet, allCoinsList}) => {

    const theme = useTheme()
    const [open, setOpen] = useState(true);
    const [addCoinInput, setAddCoinInput] = useState(''); // The text field value in the 'add coin' field
    const [selectedCoin, setSelectedCoin] = useState({}); // The coin selected in the 'add coin' field.
    const [walletCoins, setWalletCoins] = useState([]);   // list of coins in this wallet
    const [quantity, setQuantity] = useState(0);
    const [walletTotal, setWalletTotal] = useState(0);
    const [updatingTotal,setUpdatingTotal] = useState(false)

    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    }

    // Get Wallet info
    useEffect(() => {
        getWalletData();
    }, [wallet])

    // Get Wallet info
    useEffect(() => {
        let total = 0;
        // Recalculate wallet total
        for(let coin of walletCoins){
            if(coin && coin['current_price']){
                total += (parseFloat(coin['current_price']) * coin['quantity'])
            }
        }
        setWalletTotal(total);
        let portfolioTotal = {...walletTotals}
        portfolioTotal[wallet] = total
        setWalletTotals(portfolioTotal)
    }, [walletCoins, wallet])

    const handleClick = () => {
        setOpen(!open);
    };

    // Get this wallet by name and set the data.
    const getWalletData = useCallback(() => {
        //console.log("top of getWalletData")
        setUpdatingTotal(true)
        const fetchParamsWallet = {
            method: 'GET',
            headers: {
                //'Content-Type': 'multipart/form-data; boundary=---WebKitFormBoundary2dkowOOM0fftW'
            },
        }
        const errorMessageWallet = 'Error getting wallet'
        ;(async () => {
            const getWalletDataResponse = await fetchData('api/v1/users/wallet/?wallet_name='+wallet, errorMessageWallet, fetchParamsWallet).promise
            //console.log("getWalletDataResponse is (setting walletCoins to this:")
            //console.dir(getWalletDataResponse)
            let coinNames = '';
            getWalletDataResponse.results.forEach((item, index) => {
                coinNames += item.coin_id + ','
            })
            console.log(getWalletDataResponse)
            const fetchParams = {
                method: 'GET',
                headers: {
                    //'Content-Type': 'multipart/form-data; boundary=---WebKitFormBoundary2dkowOOM0fftW'
                },
            }
            const errorMessage = 'Error getting screener'
            ;(async () => {
                const getCSDataResponse = await fetchData('api/v1/coin/ecoinscreener/?coins='+coinNames, errorMessage, fetchParams).promise
                //console.log("getWalletDataResponse is (setting walletCoins to this:")
                //console.dir(getWalletDataResponse)
                let wc = getCSDataResponse.results.map((item, index) => {
                    let newVal = item;
                    let match = getWalletDataResponse.results.filter(val => val.coin_id === item.coin)
                    if(match){
                        newVal['id'] = match[0].id
                        newVal['quantity'] = match[0].quantity
                    }
                    return newVal
                })
                setWalletCoins(wc)
                setUpdatingTotal(false)
            })()
            setWalletCoins(getWalletDataResponse.results)
            setUpdatingTotal(false)
        })()
    }, [wallet])

    // Adds a WalletCoin to this wallet.
    const addCoinToWallet = () => { 
        //console.log("top of addCoinToWallet")
        let coin = selectedCoin
        let isExisting = false;
        let wc = [...walletCoins];
        for(let c of wc){
            if(c.coin === coin.id){
                isExisting = true;
            }
        }

        if(isExisting){
            //console.log("isExisting: "+coin.id)
            // Add to total
            let id = ''
            let newQuantity = quantity;
            for(let c of wc){
                if(c.coin === coin.id){
                    id = c.id;
                    newQuantity = parseFloat(c.quantity) + parseFloat(quantity);
                    c.quantity = newQuantity
                }
            }
            const fetchParams = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'quantity': newQuantity
                })
            }
            const errorMessage = 'Error adding coin.  Resubmit.  If still an error please contact support.'
            ;(async () => {
                const updateQuantityResponse = await fetchData('api/v1/users/walletcoinupdate/'+id+"/", errorMessage, fetchParams).promise
                //console.log("updateQuantityResponse is:")
                //console.dir(updateQuantityResponse)
                getWalletData();
            })()
        }else{
            //console.log("coin[quantity] is: " + quantity)
            coin['quantity'] = quantity;
            //console.log("adding this coin to walletCoins")
            //console.dir(coin)
            //setWalletCoins([...walletCoins, coin]) // do not add here....coin does not have all the data it needs to display...let getWalletData set walletCoins
            const fetchParams = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'user': JSON.stringify(userData['user']),
                    'wallet_name': wallet,
                    'coin_id': selectedCoin.id,
                    'quantity': quantity
                })
            }
            const errorMessage = 'Error adding coin.  Resubmit.  If still an error please contact support.'
            ;(async () => {
                const createCoinInWalletResponse = await fetchData('api/v1/users/walletcoincreate/', errorMessage, fetchParams).promise
                //console.log("createCoinInWalletResponse is:")
                //console.dir(createCoinInWalletResponse)
                getWalletData();
            })()
        }


        // Reset 'add coin' row fields
        //console.log("setting selectedCoin to empty object")
        setSelectedCoin({});
        setAddCoinInput('');
        setQuantity(0);
    }

    const changeWalletTotal = () => {
        getWalletData()
    }

    const toggleChooseCoin = () => {

    }

    // Deletes a WalletCoin from this wallet.
    const removeCoinFromWallet = (item, index) => {
        let copy = [...walletCoins];
        const fetchParams = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        }
        const errorMessage = 'Error removing coin.  Retry.  If still an error please contact support.'
        ;(async () => {
            const removeCoinFromWalletResponse = await fetchData('api/v1/users/walletcoinupdate/'+item.id+'/', errorMessage, fetchParams).promise
            //console.log('removeCoinFromWalletResponse is')
            //console.dir(removeCoinFromWalletResponse)
            copy.splice(index, 1);
            setWalletCoins(copy);
        })()
    }

    const deleteWallet = () => {
        removeWallet(wallet);
    }
    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const setNewWalletCoin = (newCoin) => {
        //console.log("top of setNewWalletCoin and newCoin is:")
        //console.dir(newCoin)
        setSelectedCoin(newCoin)
    }

    return (
        <ListItem 
            sx={{
                flexDirection: 'column', 
                display: 'flex', 
                width: '100%', 
                marginTop: 2,
            }} 
            disablePadding>
            <Box sx={{borderRadius: 5, border: '1px dashed grey', width: '90%', p: 1 }}>
                <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" 
                    sx={{
                        borderRadius: 5,
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <Stack 
                        onClick={handleClick}
                        sx={{
                            width: "100%", 
                            padding: "15px",
                            "&:hover" : {
                                backgroundColor: theme.palette.dark[800]
                            },
                            borderRadius: '5px',
                            marginBottom: "10px"
                        }} direction="row" display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h3">{wallet}</Typography>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </Stack>
                    <IconButton onClick={deleteWallet} sx={{marginBottom: '10px'}}>
                        <DeleteIcon color="error" />
                    </IconButton>
                </Stack>
                <Box sx={{display: 'flex', flexDirection: 'row', ml: 2, mb: 2, alignItems: 'center'}}>
                    <Typography sx={{fontSize: 18,  mr: 1, color: theme.palette.grey[400]}}>
                        {"Total Value: "}
                    </Typography>
                    <Typography sx={{fontSize: 18, fontWeight: 'bold', color: theme.palette.success.dark}}>
                        {updatingTotal ? <CircularProgress /> : walletTotal ? currencyFormat(walletTotal) : 0}
                    </Typography>
                </Box>
                {/* Wallet content */}
                <Collapse  sx={{width: '100%'}} in={open} timeout="auto" unmountOnExit>
                    <Box sx={{border: '1px solid grey', justifyContent: 'flex-start', flexDirection: 'row', display: 'flex', p: 1.5, borderRadius: 3}}>
                        <PortfolioChooseCoinPopover selectedWallet={wallet} setNewWalletCoin={setNewWalletCoin} />
                        {
                            Object.entries(selectedCoin).length === 0 ?
                                null
                                :
                                <Box sx={{border: '1px dashed grey', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', ml: 2, width: 150, borderRadius: 2}}>
                                    <Typography sx={{textAlign: 'center', color: theme.palette.grey[500]}}>
                                        {selectedCoin.name}
                                    </Typography>
                                </Box>
                        }

                        <TextField size="small" type="number" value={quantity} onChange={handleQuantityChange} label="Quantity" sx={{ml: 2, width: 150, color: theme.palette.grey[500]}}/>
                        <Button disabled={Object.entries(selectedCoin).length === 0 || quantity === 0} size="small" variant="contained" onClick={addCoinToWallet} sx={{maxWidth: 100, ml: 2, borderRadius: 2}}>
                            Add Coin
                        </Button>
                    </Box>
                    <List sx={{width: '100%', marginTop: 2}} component="div" disablePadding>
                        {walletCoins.length > 0 ? <WalletHeader /> : null }
                        {
                            walletCoins.map((item, index) => {
                                return (
                                    <WalletRow item={item} key={index} index={index} removeCoinFromWallet={removeCoinFromWallet} changeWalletTotal={changeWalletTotal} />
                                )
                            })
                        }
                    </List>
                </Collapse>
            </Box>
        </ListItem>
    );
}

export default Wallet;