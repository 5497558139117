import React, { useState, useEffect } from 'react'
import {
    Box, useMediaQuery, Paper, IconButton, Divider, FormControl, FormGroup, Pagination,
    CircularProgress,
    Grid,
    Stack,
    Popover,
    Typography
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'
import fetchData from '../../utility/endpointFetch'

import { IconSquareX } from '@tabler/icons'
import { CgSelectO } from 'react-icons/cg' 

// project imports
import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'
import ChartDisplayByCoinSelectorItem from './ChartDisplayByCoinSelectorItem'
import TweetDisplayByCoinSelectorCategoryChoices from './TweetDisplayByCoinSelectorCategoryChoices'
import TweetDisplayByCoinSelectorSortChoices from './TweetDisplayByCoinSelectorSortChoices'
import TweetDisplayByCoinSelectorSearchChoices from './TweetDisplayByCoinSelectorSearchChoices'
import ResearchCustomDependencySelection from './ResearchCustomDependencySelection'

const ChartDisplayByCoinSelector = ( {selectedChartCoin, changeInChartCoin, changeCustomDependency, currentDependency, backToTabOne} ) => {
    console.log("top of ChartDisplayByCoinSelector and selectedChartCoin is: " + selectedChartCoin)

    const theme = useTheme()
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

    const [isLoading,setIsLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)    
    const [chosenCoin,setChosenCoin] = useState(selectedChartCoin)
    const [chosenCoinID,setChosenCoinID] = useState('')
    const [coinsList,setCoinsList] = useState([])
    const [currencyPage,setCurrencyPage] = useState(1)
    const [sortingBy,setSortingBy] = useState('')
    const [sortByUrl,setSortByUrl] = useState('')
    const [categoryChosen,setCategoryChosen] = useState('')
    const [filterByUrl,setFilterByUrl] = useState('')
    const [searchByUrl,setSearchByUrl] = useState('')
    const [responseCount,setResponseCount] = useState(0)
    const [pageCount,setPageCount] = useState(0)
    const [categoryList,setCategoryList] = useState([])

    const sortResults = (sortBy) => {
        //console.log("top of sortResults in TweetDisplayByCoinSelector: " + sortBy)
        setSortingBy(sortBy)
        setCurrencyPage(1)
        switch(sortBy) {
          case 'rank-highest':
            setSortByUrl("&ordering=market_cap_rank")
            break
          case 'rank-lowest':
            setSortByUrl("&ordering=-market_cap_rank")
            break
          case 'name-alphabetical':
            setSortByUrl("&ordering=name")
            break
          case 'name-alphabetical-reverse':
            setSortByUrl("&ordering=-name")
            break
          case 'symbol-alphabetical':
            setSortByUrl("&ordering=coin__symbol")
            break
          case 'symbol-alphabetical-reverse':
            setSortByUrl("&ordering=-coin__symbol")
            break
          case 'price-highest':
            setSortByUrl("&ordering=-current_price")
            break
          case 'price-lowest':
            setSortByUrl("&ordering=current_price")
            break
          case 'volume-lowest':
            setSortByUrl("&ordering=total_volume")
            break
          case 'volume-highest':
            setSortByUrl("&ordering=-total_volume")
            break
          default: //lets do alpha
            setSortByUrl("&ordering=name")
            break
        }
        return
    }
    
    const clearSort = () => {
        setSortingBy('')
        setSortByUrl('')
    }    

    const changePage = (event, page) => {
        //console.log("top of changePage")
        //console.dir(page)
        setIsLoading(true)
        setCurrencyPage(page)
    }
    
    const handleClick = (event) => {
        //console.log("top of handleClick and event is:")
        //console.dir(event)
        if (open === false) {
          //this will open the choose coin popover...go back to tab 1
          backToTabOne()
        }
        setAnchorEl(event.currentTarget)
        setOpen((prevOpen) => !prevOpen)
    }
    
    const handleClose = (event) => {
        //console.log("top of handleClose and chosenCoin is:")
        //console.dir(chosenCoin)

        //reset
        setSortingBy('')
        setSortByUrl('')
        setSearchByUrl('')
        setCurrencyPage(1)  
        setCategoryChosen('')
        
        setAnchorEl(null)
        changeInChartCoin(chosenCoin,chosenCoinID)
        setOpen((prevOpen) => !prevOpen)
    }
    
    const id = open ? 'simple-popover' : undefined    

    const filterByCategory = (category) => {
        //console.log("top of filterCurrencyList and category is: " + category)
        if (category !== '') {
          setFilterByUrl("&category=" + category)
          setCurrencyPage(1)
          setCategoryChosen(category)
        } else {
          setCurrencyPage(1)
          setCategoryChosen('')
          setFilterByUrl('')
        }
      }
    
    const clearCategoryFilter = () => {
        //remove a current category filter if there is one currently
        //console.log("top of clearCategoryFilter")
        setCurrencyPage(1)
        setFilterByUrl('')
        setCategoryChosen('')
    }    

    const searchCoins = (searchTerm) => {
        //console.log("top or searchFavorites...searchTerm: " + searchTerm)
        if (searchTerm.length > 2) {
          //console.log("searchTerm greater than 2")
          if (searchTerm.indexOf(' ') >= 0) {
            //console.log("searchTerm contains spaces")
            //contains spaces...no search
            setSearchByUrl('')
            return null
          } else {
            //console.log("about to search")
            var searchString = "&search=" + encodeURIComponent(searchTerm)
            setCurrencyPage(1)
            setSearchByUrl(searchString)
          }
        } else {
          //search term too short...wait till at least 3 characters
          //console.log("searchTerm 2 or less letters")
          setSearchByUrl('')
          return null
        }
    }
    
    const clearSearchCoins = () => {
        setSearchByUrl('')
        setCurrencyPage(1)
    }      

    const saveCoinToSelection = (coinSymbol,coinID) => {
        //console.log("top of saveCoinToSelection and coinSymbol is:")
        //console.dir(coinSymbol)
        setChosenCoin(coinSymbol)
        setChosenCoinID(coinID)
        changeInChartCoin(coinSymbol,coinID)

        //reset
        setSortingBy('')
        setSortByUrl('')
        setSearchByUrl('')
        setCurrencyPage(1)        
        setCategoryChosen('')

        setAnchorEl(null)
        setOpen((prevOpen) => !prevOpen)
    }

    useEffect(() => {
        setIsLoading(true)
        //console.log("top of useEffect")
        //console.log("currencyPage is: " + currencyPage)
        //console.log("sortByUrl is: " + sortByUrl)
        //console.log("filterByUrl is: " + filterByUrl)
        //console.log("searchByUrl is: " + searchByUrl)
    
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            var offset= (currencyPage - 1) * 50 //limit per page
            const response = await fetchData(`api/v1/coin/coinmarket/?limit=50&offset=${offset}${sortByUrl}${filterByUrl}${searchByUrl}`, "could not receive all coins data in tweet display by coin selector", fetchParams).promise
            //console.log("response getting all coins")
            //console.dir(response)
            setCoinsList(response.results)
    
            setResponseCount(response.count)
    
            if (response.count > 100) {
              setPageCount(Math.ceil(response.count/100))
            } else {
              setPageCount(1)
            }
    
            if (sortByUrl === '') {
              //reorderlist by rank 1 first by default
              sortResults('rank-highest', response.results)
            }
    
            //extract all categories from currency list
            var category_list = []
            response.results.map( coin => {
              coin.categories.map( category => {
                if (!category_list.includes(category)) {
                  category_list.push(category)
                }
              })
            })
            setCategoryList(category_list)
    
            setIsLoading(false)
          } catch (error) {
            console.log("something is broke getting all coins in tweet display by coin selector popover: %s", error)
          }
        })()
    }, [currencyPage, filterByUrl, sortByUrl, searchByUrl])    

    return (
        <Box sx={{ display: 'flex' }}>
          <CgSelectO 
            aria-describedby={id}
            onClick={handleClick}
            color={theme.palette.primary.main}
            style={{
                color: theme.palette.primary.main,
                '&:hover': {
                    //background: theme.palette.secondary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
                    color: theme.palette.primary.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
                },
                cursor: 'pointer'
            }}
            size={18}
          />  
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 400, left: 900 }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >

              <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} >
                <Paper >
                  <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '600px', minHeight: '700px'}}>
                    <Grid container spacing={2} sx={{marginTop: '15px'}}>
                    <Grid item xs={12} sx={{bgcolor: theme.palette.dark[800]}}>
                      <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between">
                        <Typography display="inline" variant="h3" sx={{marginTop: '15px', marginLeft: '30px'}}>Choose Coin To Display Chart For </Typography>
                        <IconButton
                          aria-label="close"
                          sx={{color: theme.palette.error.main, marginTop: '15px', marginRight: '30px'}}
                          onClick={handleClose}
                        >
                          <IconSquareX />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{bgcolor: theme.palette.dark[800]}}><Divider /></Grid>
                    <Grid item xs={7} sx={{bgcolor: theme.palette.dark[800]}}>
                        <TweetDisplayByCoinSelectorCategoryChoices categoryList={categoryList} categorySelected={categoryChosen} filterByCategory={filterByCategory} clearCategoryFilter={clearCategoryFilter} />
                        <TweetDisplayByCoinSelectorSortChoices clearSort={clearSort} sortResults={sortResults} selectedSort={sortingBy} />
                        <TweetDisplayByCoinSelectorSearchChoices searchCoins={searchCoins} clearSearchCoins={clearSearchCoins} />
                        <ResearchCustomDependencySelection changeCustomDependency={changeCustomDependency} currentDependency={currentDependency} />
                    </Grid>
                    <Grid item xs={5} sx={{bgcolor: theme.palette.dark[800]}}>
                        <Stack direction="column" sx={{minHeight: '650px'}}>
                        <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
                        {isLoading ? <CircularProgress /> :
                            <PerfectScrollbar
                            style={{ maxHeight: 'calc(100vh - 300px)', overflowX: 'hidden' }}
                            >
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                {/*<FormLabel component="legend">Pick Favorites</FormLabel>*/}
                                <FormGroup>
                                {coinsList.map( (coin, index ) => 
                                    <ChartDisplayByCoinSelectorItem 
                                        key={"researchcustomselectorchoices"+index} 
                                        selectedCoin={chosenCoin} 
                                        coin={coin} 
                                        saveCoinToSelection={saveCoinToSelection} 
                                    />  
                                )}
                                </FormGroup>
                            </FormControl>
                            </PerfectScrollbar>
                        }
                        <Pagination sx={{marginTop: '10px'}} shape="rounded" count={pageCount} page={currencyPage} onChange={changePage}/>
                        </Stack>
                    </Grid>
                    </Grid>
                  </MainCard>
                </Paper>
              </Transitions>
            </Popover>

        </Box>
    )
}

export default ChartDisplayByCoinSelector