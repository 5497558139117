import React, { useState, useRef } from 'react'
import { Box } from '@mui/material'

import CoinDetailsLinksChip from './CoinDetailsLinksChip'

const CoinDetailsLinks = ( {currencyDetails, medScreenOrSmaller=false } ) => {
  

  //console.log("top of CoinDetailsLinks") 
  //console.dir(currencyDetails)

  const linkList = [
    {"link_text": 'coin website', "link_property": 'website',"link": 'XXX', "link_icon": "IconLink", 'key':1 },
    {"link_text": 'twitter', "link_property": 'twitter_url', "link": 'XXX', "link_icon": "IconBrandTwitter", 'key':1 },
    {"link_text": 'facebook', "link_property": 'facebook_url', "link": "XXX", "link_icon": "IconBrandFacebook", 'key':1 },
    {"link_text": 'reddit', "link_property": 'reddit_url', "link": 'XXX', "link_icon": "IconBrandReddit", 'key':1 },
    {"link_text": 'telegram', "link_property": 'telegram_url', "link": 'XXX', "link_icon": "IconBrandTelegram", 'key':1 },
    {"link_text": 'discord', "link_property": 'discord_url', "link": 'XXX', "link_icon": "IconBrandDiscord", 'key':1 },
    {"link_text": 'github', "link_property": 'github_url', "link": 'XXX', "link_icon": "IconBrandGithub", 'key':1 },
    {"link_text": 'blockchain explorer', "link_property": 'blockchain_explorer', "link": 'XXX', "link_icon": "IconCoin", 'key':1 },
    {"link_text": 'white paper', "link_property": 'whitepaper', "link": 'XXX', "link_icon": "IconFile", 'key':1 },
  ]
  var marginTopValue = '0px'
  if (medScreenOrSmaller) marginTopValue = '20px'
  return (
    <Box sx={{marginTop: marginTopValue, marginLeft: '10px'}}>
      {linkList.map((link) => <CoinDetailsLinksChip link={link} key={link.link_text} currencyDetails={currencyDetails} /> )}
    </Box>
  )
}

export default CoinDetailsLinks