import React from 'react'
import { Grid, Typography, Stack, Box, Tooltip, Button, IconButton } from '@mui/material'
import { AreaChart, BarChart, Bar, XAxis, YAxis, Area, Line, LineChart, ResponsiveContainer  } from "recharts"

import { IconArrowUpRightCircle } from '@tabler/icons'
import { HelpOutline } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'
const QuickELR = ( { ruleThemAll, changeTab } ) => {

  const theme = useTheme()

  const abbreviateValue = (flowValue) => {
    if (flowValue > 1000000000) {
      return ((flowValue / 1000000000).toFixed(2)) + 'B'
    } else if (flowValue > 1000000) {
      return ((flowValue/1000000).toFixed(2)) + 'M'
    } else if (flowValue > 1000) {
      return ((flowValue/1000).toFixed(2)) + 'K'
    } else if (flowValue < 1) {
      return (flowValue.toFixed(2))
    } else {
      //just display the full amount
      return flowValue
    }
  }

  //only need last 7 days of data

  //var leverageData = ruleThemAll.slice((ruleThemAll.length-8), (ruleThemAll.length-1)) //as the data is current first....do not use
  var leverageData = ruleThemAll.slice(0, 7)
  leverageData.reverse()

  if (leverageData.length === 0) {
    return (
      <Box sx={{width: '100%'}} >
        <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[500]}}>Estimated Leverage Ratio</Typography>
        <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
      </Box>
    )
  } else {

    //console.log("leverageData is:")
    //console.dir(leverageData)

    var rechartsleverageData = leverageData.map( (data) => {
      return { 'name': 0, 'value': data.healthData.estimated_leverage_ratio , 'amt': data.healthData.estimated_leverage_ratio, 'date': data.healthData.timestamp }
    })

    //get data range for reserve data
    var minMaxLeverage = leverageData.map( (data) => {
      if (data.healthData)
        return data.healthData.estimated_leverage_ratio
    })

    //the current day may not be there...get second to last
    var useThisLastLeverageData = leverageData[leverageData.length-1]

    //get % change over 7 days
    var leverageChangePercent = Math.abs( useThisLastLeverageData.healthData.estimated_leverage_ratio - leverageData[0].healthData.estimated_leverage_ratio )/ leverageData[0].healthData.estimated_leverage_ratio * 100

    var leverageChangeColor = theme.palette.success.main
    var leverageChangePrecursor = "+"
    if (useThisLastLeverageData.healthData.estimated_leverage_ratio < leverageData[0].healthData.estimated_leverage_ratio) {
      leverageChangeColor = theme.palette.error.main
      leverageChangePrecursor = "-"
    }

    var leverageMax = minMaxLeverage.reduce((a, b) => { return Math.max(a, b) })
    var leverageMin = minMaxLeverage.reduce((a, b) => { return Math.min(a, b) })

    const displayQuickDate = (timestamp) => {
      let dateOfData = new Date(timestamp)
      return dateOfData.getMonth() + '/' + dateOfData.getDate()
    }

    return (
      <Stack
        direction="column"
        sx={{backgroundColor: theme.palette.dark.dark, padding: '5px', paddingTop: '0px', borderRadius: '5px', minHeight: '18.5vh', alignItems: 'stretch', cursor: 'pointer', margin: '10px'}}
        onClick={() => changeTab('12')}
      >
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>Estimated Leverage Ratio</Typography>
          <Stack direction="row" display="flex" alignItems="center">
            <Tooltip title="Estimated Leverage Ratio (ELR) is defined as the ratio of open interest divided by the reserve of an exchange."  >
              <IconButton size="small" aria-label="full chart">< HelpOutline sx={{fontSize: '1.1rem', color: theme.palette.grey[500]}} /></IconButton>
            </Tooltip>   
            <Tooltip title='click for full Estimated Leverage chart'  ><IconButton size="small" aria-label="full chart">< IconArrowUpRightCircle size={18} color={theme.palette.grey[500]} /></IconButton></Tooltip>
          </Stack>
        </Stack>

        <Box sx={{width: '99%', backgroundColor: theme.palette.dark.main, alignItems: 'center'}} >
          <Stack direction="column" display="flex" justifyContent="space-between" sx={{ minHeight: '15.5vh'}}>
            <Typography variant="caption" sx={{marginLeft: '5px'}}>leverage</Typography>
            <ResponsiveContainer width='99%' height={110}>
              <LineChart
                data={rechartsleverageData}
              >
                <XAxis 
                  dataKey="date"
                  tickFormatter={ ( value ) => displayQuickDate(value)}
                  style={{fontSize: '.6rem'}}
                />
                <YAxis 
                  dataKey="value"
                  domain={[leverageMin,leverageMax]}
                  tick={{stroke: '#888', strokeWidth: .5}}
                  tickFormatter={(value) => abbreviateValue(value)}  
                  orientation='right'                
                  style={{fontSize: '.8rem'}}
                />
                <Line tickLine={true} strokeWidth={2} type="monotone" dataKey="value" dot={false} stroke={theme.palette.primary.dark} />
              </LineChart>
            </ResponsiveContainer>
            <Box sx={{width: '100%', paddingLeft: '5px', minHeight: '3vh', paddingRight: '5px', height: '100%', backgroundColor: theme.palette.dark[800]}}>
              <Stack direction="row" display="flex" alignItems="baseline" justifyContent="space-around" >
                <Typography display="inline" sx={{fontSize: '.7rem'}}>7 day change:</Typography>
                <Typography variant="button" sx={{fontSize: '1.2rem', fontWeight: 700, color: leverageChangeColor}} >{leverageChangePrecursor} {leverageChangePercent.toFixed(2)} %</Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Stack>
    )
  }
}

export default QuickELR