import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconArrowUpCircle, IconArrowDownCircle } from '@tabler/icons';

const StableCoinSupply = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About Stablecoin Supply Ratio</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - Stablecoin Supply Ratio">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
              Stablecoin Supply Ratio (SSR) is defined as a ratio of the Market Cap of BTC divided by the Market Cap of all Stablecoins.

              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                In understanding SSR, understanding the function of stablecoin and its assumption should be done.<br /><br />
                First, stablecoins play an important role in the cryptocurrency market as a fiat currency like USD in the regulated market. This is because fiat currencies like USD as a supplier of liquidity have a lot of regulation issues. <br /><br />
                Second, SSR is easy to understand when there is an assumption that the market is a closed system containing only cryptocurrencies and stablecoins.  The relationship between BTC and Stablecoins is like seasaw and provide valuable insight on who has more weight at the moment.
              </Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                Additional stablecoins could be minted anytime disrupting the assumption that crypto market is a closed system. However, as crypto market continues to expand, additional mint would gradually lose its impact on the model.<br /><br />
                As time passes, the degree of values' meaning could differ on level.
              </Typography>
              <Typography variant="h4">By Value Itself</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
              It shows comparative power status between BTC and Stablecoin by comparing market cap
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />
                    </ListItemIcon>
                    <ListItemText
                      primary="High: Low Potential Buying Pressure"
                      secondary="High values mean Low Stablecoin supply compared to the market cap of BTC indicating potential low buying pressure and possible price drop. "
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowUpCircle color='#00b341' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Low : High Potential Buying Pressure - Bullish "
                      secondary="Low values mean high Stablecoin supply compared to the market cap of BTC indicating potential buying pressure and possible price rise. "
                    />
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="h4">By examining trend</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
              It shows the level of Exchange Activeness & Volatility
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825'/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Increasing trend : Slowing down status of stablecoin's buying power - Bearish or sideways sentiment"
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowUpCircle color='#00b341' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Decreasing trend : Rising status of stablecoin's buying power - Bullish"
                    />
                  </ListItem>
                </List>
              </Typography>
            </TabPanel>

          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default StableCoinSupply