import React from 'react'
import { Typography, Stack, Box } from '@mui/material'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { CgMoreO } from 'react-icons/cg'
import { useTheme } from '@mui/material/styles'

const ResearchTutorial8Filters = () => {

    const theme = useTheme()

    return (
        <Stack direction="column" alignItems="flex-end" sx={{padding: '15px'}}>
            <Typography sx={{paddingTop: '20px', marginRight: '20px', fontSize: '2em', color: theme.palette.warning.dark}}>Additional Search Filters <FaArrowAltCircleRight size={20} sx={{marginLeft: '15px'}} /></Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>The search bar contains other filters to further refine your search criteria. In addition to searching for 
            specific text, projects can also be filtered against Market Cap, Performance vs Bitcoin, Performance vs Ethereum, and Coin Type. </Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>All filter criteria have sliders that allows you to set Min and Max values. The ‘Market Cap’ 
            and ‘Twitter Followers’ criteria allow you to directly type in Min and Max values – simply click the 3 dot icon <CgMoreO size={15} color={theme.palette.primary.main}/>. To filter by category, 
            select one or more categories at the bottom of the search box. </Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>Once you have finished with a specific search criteria and you wish to save it, you only 
            need to click the heart icon at the top right of the search box. That will open another box that shows the criteria of the search you wish to save. 
            To save you only need to give it a name and click the save search button.</Typography>            
        </Stack>   
    )
}
export default ResearchTutorial8Filters