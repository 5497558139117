import React from 'react'
import { Typography, Button } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'

const CoinDetailsCorrelationETH = ( {currencyDetails, medScreenOrSmaller} ) => {

    const theme = useTheme()

    const ColorButtonPositive = styled(Button)(({ theme }) => ({
      '&&&': {
        textTransform: 'uppercase',
        paddingLeft: '10px',
        paddingRight: '5px',
        borderRadius: '20px',
        height: '50px',
        color: theme.palette.dark[900],
        backgroundColor: theme.palette.success.light,
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
        },
      }
      }));
    
      const ColorButtonNegative = styled(Button)(({ theme }) => ({
        '&&&': {
          textTransform: 'uppercase',
          paddingLeft: '10px',
          paddingRight: '5px',
          borderRadius: '20px',
          height: '50px',
          color: theme.palette.dark[900],
          backgroundColor: theme.palette.error.light,
          '&:hover': {
            backgroundColor: theme.palette.error.main,
          },
        }
      }));
    if (medScreenOrSmaller) {
      if (currencyDetails.eth_24h_dif > 0) {
        return <ColorButtonPositive >24hr vs ETH {parseFloat(currencyDetails.eth_24h_dif).toFixed(2)}% <ArrowUpward sx={{marginLeft: '3px'}}/> </ColorButtonPositive>
      } else {
        return <ColorButtonNegative > 24hr vs ETH {parseFloat(currencyDetails.eth_24h_dif).toFixed(2)}%<ArrowDownward sx={{marginLeft: '3px'}}/> </ColorButtonNegative>

      }
    } else {
      if (currencyDetails.eth_24h_dif > 0) {
        return <ColorButtonPositive variant="h4" >24hr vs ETH {parseFloat(currencyDetails.eth_24h_dif).toFixed(2)}% <ArrowUpward sx={{marginLeft: '5px'}}/> </ColorButtonPositive>
      } else {
        return <ColorButtonNegative variant="h4" > 24hr vs ETH {parseFloat(currencyDetails.eth_24h_dif).toFixed(2)}%<ArrowDownward sx={{marginLeft: '5px'}}/> </ColorButtonNegative>

      }
    }
}

export default CoinDetailsCorrelationETH