import {  Stack, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import fetchData from "../../utility/endpointFetch";
import { useTheme } from '@mui/material/styles'

const VIPMainTabsFeedTriggeredAlertsItem = ({item}) => {

    console.log("top of VIPMainTabsFeedTriggeredAlertsItem and item is:")
    console.dir(item)

    const [coinDetails, setCoinDetails] = useState([]);
    const theme = useTheme();

    useEffect(() => {
        const getVIPSearchFeedParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            try {
                let getDetailsURL = `api/v1/coin/ecoinscreener/?coin=` + item.coin
                const getDetailsResponse = await fetchData(getDetailsURL, "could not receive alert notifications", getVIPSearchFeedParams).promise
                console.log("getDetailsResponse is: ")
                console.dir(getDetailsResponse)
                console.log("getDetailsURL is:")
                console.dir(getDetailsURL)
                setCoinDetails(getDetailsResponse.results)
            } catch (error) {
                console.log("something is broke receiving current searchfeed hot searches: ", error)
            }
        })()
    }, [])

    return (
        coinDetails.length ?
            <Stack direction={'column'} justifyContent={'space-around'} alignItems={'center'} sx={{width: '100%', paddingBottom: 1, marginRight: '10px'}}>
                <Stack direction={'row'} sx={{width: '100%'}} justifyContent={'space-between'} alignItems={'center'} spacing={4}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent='space-around'  spacing={1}>
                        <img style={{width: 25, height: 25}} src={coinDetails[0].image_thumbnail} alt={coinDetails[0].coin} />
                        <h4 color={'#fff'}>{coinDetails[0].coin}</h4>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Typography>Price Target of:</Typography>
                        <Typography sx={{fontWeight: 'bold'}}>  {item['price_target']}</Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Typography>Created: {'  '}</Typography>
                        <Typography sx={{fontWeight: 'bold'}}>  {new Date(item['created']).toLocaleString()}</Typography>
                    </Stack>
                </Stack>
                <Divider color={theme.palette.primary.dark} sx={{width: '100%', height: '.5px',marginTop: '5px', marginBottom: '5px'}}/>
            </Stack>
            :
            null
    )
}

export default VIPMainTabsFeedTriggeredAlertsItem;