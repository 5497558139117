import React from 'react'
import { Typography, IconButton, Stack, Tooltip } from '@mui/material'

import { useTheme } from '@mui/material/styles'

import { MdOutlineSettingsBackupRestore } from 'react-icons/md'

const ScreenerListDeletedItem = ( { deletedScreener, restoreScreener } ) => {
    
    const theme = useTheme()

    return (
        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between"
            sx={{
                width: '90%',
                '&:hover': {
                    backgroundColor: theme.palette.dark.dark,
                },
                padding: '3px',
                marginBottom: '3px',
                paddingLeft: '15px',
                cursor: 'pointer',
            }}
        >
            <Typography> {deletedScreener}</Typography>
            <Tooltip title={"restore "+deletedScreener+" list"}><IconButton size="small" onClick={()=>restoreScreener(deletedScreener)}><MdOutlineSettingsBackupRestore color={theme.palette.warning.main} /></IconButton></Tooltip>
        </Stack>
    )
}

export default ScreenerListDeletedItem