import React from 'react'
import { Typography, Stack, ListItemText, List, ListItemIcon, ListItem } from '@mui/material'
import { FaArrowAltCircleLeft } from 'react-icons/fa'
import { useTheme } from '@mui/material/styles'
import CheckIcon from '@mui/icons-material/Check'

const ResearchTutorial7Tweets = () => {

    const theme = useTheme()

    return (
        <Stack direction="column" alignItems="flex-start" sx={{padding: '15px'}}>
            <Typography sx={{paddingTop: '20px', marginRight: '20px', fontSize: '2em', color: theme.palette.warning.dark}}><FaArrowAltCircleLeft size={20} /> Coin Specific Tweets </Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>This is where other tweets will appear that are associated with the currently selected coin 
            (which also matches the price chart). Tweets can come from influencers that mention the coin or the crypto project’s twitter account. 
            This lets you quickly learn more information about the project. In the future, these tweets can be sorted by Twitter engagement 
            level or Influencer rank.</Typography>
            <List>
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText ><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>Official Twitter Name and Account (click to view tweet at twitter.com)</Typography></ListItemText>
                </ListItem>
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>followers of that twitter account (with link to account)</Typography></ListItemText>
                </ListItem>                
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>color coded # and @</Typography></ListItemText>
                </ListItem>             
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>Links open in new tab</Typography></ListItemText>
                </ListItem>          
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>various stats at time of retrieval</Typography></ListItemText>
                </ListItem>    
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>coins associated with that tweet</Typography></ListItemText>
                </ListItem>                              
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>time of tweet</Typography></ListItemText>
                </ListItem>                                
            </List>
        </Stack>   
    )
}
export default ResearchTutorial7Tweets