import React from 'react'
import { Typography, Grid, Box, Stack, } from '@mui/material'
import { DropzoneArea } from 'react-mui-dropzone';
import TokenWizardHelperText from './TokenWizardHelperText';
import { useTheme } from '@mui/material/styles'
import { displayUploadedFileORIMageURL } from '../../utility/objHelper'
import fetchData from '../../utility/endpointFetch'

const TokenReleaseDetails = ( { pageData, setPageData, wizardForCoinAlreadyExists, coin, showError } ) => {
    const theme = useTheme()
    //console.log("top of TokenReleaseDetails and handleTRDCImageAttach is: ")
    //console.dir(handleTRDCImageAttach)

    var instructionText = 'Take a screenshot of the Token Release Detailed Chart and upload it to the box below.'
    if (Array.isArray(pageData.trsFile)) 
        instructionText = 'There is already a token release details chart attached.  If you wish overwrite this one with a new one drag the screenshot of the new Token Release Detailed Chart and upload it to the box below.'

    const handleTRDCImageAttach = (file) => { // Handle adding Token release detailed image file
        

        if(file.length === 1) {
            /* decided to upload at end instead of here when we receive it
            let formData = new FormData()
            formData.append("token_release_detailed_img",  file[0])
            formData.append('coin', coin)
            const fetchParams = {
                method: 'POST',
                headers: { },
                body: formData
            }

            let wizardURL = `api/v1/research/postwizard/?token_release_detailed_img=true&coin=${coin}`
            let errorMessage = 'Error saving your token information.  Resubmit.  If still an error please contact support.'
        
            ;(async () => {
                try {
                    const tagResponse = await fetchData(wizardURL, errorMessage, fetchParams).promise
                    console.log("tagResponse from api/v1/research/postwizard/?token_release_detailed_img=true is:")
                    console.dir(tagResponse)
                    if ((tagResponse.hasOwnProperty('success')) || tagResponse === true) {

                    } else {
                        throw 'field not excepted?'
                    }
                } catch (error) {
                    console.log("something is broke submitting token_release_detailed_img: " + error)
                }
            })()
            */
            setPageData({
                ...pageData,
                trdcFile: file,
            })
        } else {
            console.log("no file submitted to upload")
        }
    }

    const removeImageHandler = (currentState) => {
        console.log("top of removeImageHandler in TokenReleaseDetails and currentState.target.checked is:")
        console.dir(currentState.target.checked)
        console.log("so setting removeTRDCFile to " + currentState.target.checked)
        setPageData({
            ...pageData,
            removeTRDCFile: currentState.target.checked
        })        
    }     

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant="h3" sx={{color: theme.palette.primary.main, marginTop: '20px'}}>Token Release Detailed Chart</Typography>
            <Stack direction="row" display='flex' alignItems='center'>
                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500] }}>
                    {instructionText}
                </Typography>
                <TokenWizardHelperText 
                    title={'Token Detailed Release Chart'} 
                    text={'The token detailed release chart reveals more precise information on when tokens are released to the public. Do NOT enter graphic images as it will reduce your account features.\nNote: The Details page of the project will show this info once uploaded.'}
                />
            </Stack>     

            <Grid container sx={{marginTop: '20px'}} spacing={5}>    
                <Grid item xs={12} alignItems={'center'} justifyContent={'center'}>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Stack display='flex' direction='row' alignItems='center' justifyContent='center' sx={{flex: 1, maxWidth: 800, margin: '25px'}}>                        
                            <DropzoneArea
                                //initialFiles={pageData.trdcFile}
                                onChange={(file) => handleTRDCImageAttach(file)}
                                filesLimit={1}
                                //acceptedFiles={['image/jpeg', 'image/png']}
                                dropzoneText="Optionally upload Token Release Details Image by clicking or dragging the file here"
                                showPreviews={false}
                                clearOnUnmount={false}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={false}
                            />

                            <Stack display='flex' direction='row' justifyContent='center' sx={{margin: '25px', mb: 5}}>
                                {displayUploadedFileORIMageURL(pageData.trdcFile, removeImageHandler, pageData.removeTRDCFile)}
                            </Stack> 
                        </Stack>
                                           
                    </Box>
                </Grid>
            </Grid>
                    
            {
                showError && (
                    <Typography align='center' variant="h3" sx={{ mt: 1, color: 'red' }}>
                        Please fill in all fields before continuing.
                    </Typography>
                )
            }
        </Box>        
    )

}

export default TokenReleaseDetails