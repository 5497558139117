import React from 'react'
import { IconButton, Link, Stack, Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
//import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
//import AppleIcon from '@mui/icons-material/Apple'
import Copyright from './Copyright'
import LogoSection from "./Header/LogoSection";
import ReactStoreBadges from 'react-store-badges'
//import { ReactComponent  as Visa } from '../assets/images/visa.svg'
//import { ReactComponent  as MasterCard } from '../assets/images/mastercard.svg'
//import { ReactComponent  as Discover } from '../assets/images/discover.svg'
//import { ReactComponent  as PayPal } from '../assets/images/paypal.svg'

const Footer = () => {
  const theme = useTheme()
  const medScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"))
  
  const footerStyles = makeStyles(() => ({
    footerLinks: {
      marginLeft: '5px',
      marginRight: '5px',
      
    }
  }))

  const classes = footerStyles()
  
  return (
    <Box sx={{marginTop: '50px'}}>
      <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
          <Stack direction={'row'} spacing={5} justifyContent={'space-between'} alignSelf={'center'}>
              <Stack direction={'column'} alignItems={'center'}>
                  <LogoSection />
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                      <IconButton onClick={() => alert('Link to our Twitter coming soon!')} target={'_blank'} aria-label={'twitterLink'}>
                          <TwitterIcon sx={{color: '#fff'}}/>
                      </IconButton>
                      <IconButton onClick={() => alert('Link to our Instagram coming soon!')} aria-label={'instaLink'}>
                          <InstagramIcon sx={{color: '#fff'}}/>
                      </IconButton>
                  </Stack>
              </Stack>
              <Stack direction={'column'} justifyContent='flex-end' alignItems='flex-start'>
                  <Stack justifyContent={'center'} alignItems={'flex-end'} direction={ medScreenOrSmaller ? 'column' : 'row' }>
                      <Link className={classes.footerLinks} target={'_blank'} noWrap={true} href='https://def-logix.com/contact-us/'>Contact Us</Link>
                      <Link className={classes.footerLinks} target={'_blank'} noWrap={true} href='https://def-logix.com/legal-alphacentoro/'>User Agreement</Link>
                      <Link className={classes.footerLinks} href='https://forms.gle/Yabo9vAtR6YkutBF8' target='blank'>Feedback</Link>
                  </Stack>
                  <Stack justifyContent={'center'} alignItems={'flex-end'} direction={ medScreenOrSmaller ? 'column' : 'row' }>
                      <Link className={classes.footerLinks} noWrap={true} href='https://def-logix.com/legal-alphacentoro/#1650381999628-f8f17a73-37e5' target='blank'>Privacy Policy</Link>
                      <Link className={classes.footerLinks} noWrap={true} href='https://def-logix.com/legal-alphacentoro/#1625588192630-f23c56bb-ddaf' target='blank'>Terms of Service</Link>
                  </Stack>
                  
              </Stack>
          </Stack>
          <Copyright />
          <Stack direction={'row'} spacing={5} justifyContent={'center'} alignSelf={'center'} sx={{mt: 1}}>
              <ReactStoreBadges
                  platform={'ios'}
                  url={'https://testflight.apple.com/join/8a9tj5ZS'}
                  locale={'en-us'}
                  target={'_blank'}
              />
              <ReactStoreBadges
                  platform={'android'}
                  url={'https://play.google.com/apps/testing/com.alphacentauri.bcaexpo/'}
                  locale={'en-us'}
                  target={'_blank'}
              />
              {/** Credit Card Icons **/}
              {/*<Stack direction={'row'} justifyContent={'center'} spacing={1} sx={{mt: 2}}>*/}
              {/*    <Visa style={{width: 40, height: 40}}/>*/}
              {/*    <MasterCard style={{width: 40, height: 40}}/>*/}
              {/*    <Discover style={{width: 40, height: 40}}/>*/}
              {/*    <PayPal style={{width: 40, height: 40}}/>*/}
              {/*</Stack>*/}
          </Stack>
      </Stack>
    {/*
    // <Grid container sx={{marginTop: '25px'}} justifyContent={'center'}>
    //
    //   
    //   <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
    //     <IconButton onClick={() => window.open('https://www.facebook.com/', '_blank')}>
    //       <FacebookIcon style={{ color: '#495C7B' }} />
    //     </IconButton>
    //     <IconButton onClick={() => window.open('https://twitter.com/', '_blank')}>
    //       <TwitterIcon style={{ color: '#495C7B' }} />
    //     </IconButton>
    //     <IconButton onClick={() => window.open('https://www.instagram.com/', '_blank')}>
    //       <InstagramIcon style={{ color: '#495C7B' }} />
    //     </IconButton>
    //   </Grid>
    //   */}
       {/*<Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>*/}
       {/*  <Link className={classes.footerLinks} href='/home'>home</Link> <Divider orientation="vertical" variant="middle" flexItem />*/}
       {/*  <Link className={classes.footerLinks} href='/mycurrencies'>screeners</Link> <Divider orientation="vertical" variant="middle" flexItem />*/}
       {/*  <Link className={classes.footerLinks} href='https://forms.gle/Yabo9vAtR6YkutBF8' target='blank'>feedback</Link> <Divider orientation="vertical" variant="middle" flexItem />*/}
       {/*  <Link className={classes.footerLinks} href='/user' >user</Link>*/}
       {/*</Grid>*/}
       {/*<Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>*/}
       {/*  <Link className={classes.footerLinks} href='/contactus'>Contact Us</Link> <Divider orientation="vertical" variant="middle" flexItem />*/}
       {/*  <Link className={classes.footerLinks} href='https://def-logix.com/legal-alphacentoro/'>User Agreement</Link>*/}
       {/*</Grid>*/}
       {/*<Grid item xs={12}>*/}
       {/*  <Copyright />*/}
       {/*</Grid>
        </Grid>*/}
    </Box>
  )
}

export default Footer
