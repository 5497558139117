import { Typography, Box, Stack } from '@mui/material'
import React from 'react'
import HomeFeedTwitterPost from './HomeFeedTwitterPost'
import { useTheme } from '@mui/material/styles'

const HomeFeedTabFiltered = ( {homeFeedListings, coinFilter, hashFilter, mentionedFilter, followedFilter, filterByCoinInFeed, filterByHashInFeed, filterByFollowedInFeed, filterByMentionInFeed } ) => {

    const theme = useTheme()

    //console.log("top of HomeFeedTabFiltered")
   
    var filterBy = ''
    var filteredListing = []
    if (coinFilter != '') {
        filterBy = "Coin: " + coinFilter
        homeFeedListings.map ( listing => {
            if (listing.coins !== null) {
                if (listing.coins.includes(coinFilter)) filteredListing.push(listing)
            } else {
                //coins can be null and an array now
            }
        })

    } else if ( hashFilter != '') {
        filterBy = "HashTag: " + hashFilter
        homeFeedListings.map ( listing => {
            if (listing.text.includes(hashFilter)) filteredListing.push(listing)
        })
    } else if ( mentionedFilter != '') {
        filterBy = "@mentioned: " + mentionedFilter
        homeFeedListings.map ( listing => {
            if (listing.text.includes(mentionedFilter)) filteredListing.push(listing)
        })
    } else if (followedFilter != '') {
        filterBy = "@followed: " + followedFilter
        homeFeedListings.map ( listing => {
            if (listing.twitter_display_name === (followedFilter)) filteredListing.push(listing)
        })
    } else {
        //nothing to filter...leave empty
    }

    var filteredText = filterBy + " (" + filteredListing.length + ")"
    if (filterBy === '' ) {
        //there is nothing to filter feed by
        filteredText = "There is no filter set to filter by"
    } 

    return (
        <Box sx={{width: '100%'}}>
            <Stack direction="column">
                <Typography variant="h5" sx={{color: theme.palette.primary.dark, marginTop: '15px'}}>{filteredText}</Typography>
            
            { filteredListing.map( (listing, index) => 
                <HomeFeedTwitterPost 
                    key={"filteredListing"+index}
                    twitterItem={listing} 
                    filterByCoinInFeed={filterByCoinInFeed}
                    filterByHashInFeed={filterByHashInFeed}
                    filterByFollowedInFeed={filterByFollowedInFeed}
                    filterByMentionInFeed={filterByMentionInFeed}
                />         
            )}
            </Stack>
        </Box>
    )

}

export default HomeFeedTabFiltered