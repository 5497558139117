import React, { useState, useEffect } from 'react'
import { Grid, Typography, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import VIPTwitterPost from './VIPTwitterPost'
import VIPTelegramPost from './VIPTelegramPost'
import VIPDiscordPost from './VIPDiscordPost'
import VIPRedditPost from './VIPRedditPost'
import VIPResearchPost from './VIPResearchPost'

const VIPTabsCoin = ( { vipListings, coinsMentioned, setCoinInChart, changeCoinInTab, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, coinInTab, changeDateInChart, showOfficialTweets } ) => {

  //console.log("top of VIPTabsCoin")
  //console.dir(coinInTab)
  //console.dir(vipListings)

  const theme = useTheme()

  const [coinFilteredListings,setCoinFilteredListings] = useState([])

  useEffect(() => {

    setCoinFilteredListings(vipListings.filter( listing => listing.coins.includes(coinInTab)))

  }, [coinInTab])

  return (
    <Grid container>
      <Grid item xs={12}><Typography variant="h5" sx={{color: theme.palette.grey[700], margin: '10px'}}>Filtered Coins From Current VIP Results Page</Typography></Grid>
      <Grid item xs={12}>
          <Divider sx={{width:'100%'}} textAlign="left" >
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[800], paddingLeft: '20px', marginTop: '20px'}}>{coinFilteredListings.length}</Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.grey[500], marginTop: '20px'}}> mentions In VIP results of </Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[800], marginTop: '20px'}}>{coinInTab}</Typography>
          </Divider>
        </Grid>      
      {coinFilteredListings.map( (listing, index) => {
          if (listing.hasOwnProperty('tweet_id')) return <VIPTwitterPost key={'vipcoin'+index} listing={listing} changeCoinInTab={changeCoinInTab} showOfficialTweets={showOfficialTweets} changeAtFollowedTab={changeAtFollowedTab} changeHashTab={changeHashTab} changeAtMentionedTab={changeAtMentionedTab} setCoinInChart={setCoinInChart} changeDateInChart={changeDateInChart} />
          else if (listing.hasOwnProperty('telegram_group')) return <VIPTelegramPost key={'vipcoin'+index} telegramItem={listing} />
          else if (listing.hasOwnProperty('discord_server')) return <VIPDiscordPost key={'vipcoin'+index} discordItem={listing} />
          else if (listing.hasOwnProperty('subreddit')) return <VIPRedditPost key={'vipcoin'+index} redditItem={listing} />
          else return <VIPResearchPost key={'vipcoin'+index} researchItem={listing} /> //not sure if these are the only 5 types needed
      })}
    </Grid>
  )

}

export default VIPTabsCoin

