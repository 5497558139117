import React from 'react'

import { Button, Grid } from '@mui/material'
import { IconCheck } from '@tabler/icons';

const CurrencyListFilterItem = ( {category, chooseCategory, resetCategoryFilter, categoryChosen} ) => {

  //console.log("top of CurrencyListFilterItem and category is:")
  //console.dir(category)
  //console.log("categoryChosen is: ")
  //console.dir(categoryChosen)
  if (category.name === categoryChosen) {
    //selected category
    return (
      <Grid item xs={12}>
        <Button
          startIcon={<IconCheck />}
          key={category}
          onClick={() => resetCategoryFilter()}>
            {category}
        </Button>
      </Grid>
    )
  } else {
    return (
      <Grid item xs={12} key={category}>
        <Button
          key={category}
          onClick={() => chooseCategory(category)}>
            {category}
        </Button>
      </Grid>
    )
  }
}

export default CurrencyListFilterItem