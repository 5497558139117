import { FormControl, FormControlLabel, Stack, Typography } from "@mui/material";
import TokenWizardHelperText from "./TokenWizardHelperText";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import React from "react";
import { useTheme } from '@mui/material/styles'

const Inflationary = ({pageData, setPageData}) => {

    const theme = useTheme()

    const handleIsInflationaryChange = (e) => {
        setPageData({
            ...pageData,
            isNonInflationary: e.target.checked
        });
    }

    return (
        <Stack sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant="h2" sx={{color: theme.palette.primary.main, mt: 4}}>Non-Inflationary</Typography>
            <Stack direction="row" display='flex' alignItems='center'>
                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500] }}>
                    Is the token non-inflationary?
                </Typography>
                <TokenWizardHelperText
                    title={'Inflationary Tokens'}
                    text={'Tokens that are inflationary have no limit as to how many tokens can be produced. \nNote: The Details page of the project will show this info once uploaded.'} />
            </Stack>
            <FormControl sx={{mt: 1}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{
                                '&:hover': { bgcolor: 'transparent' },
                                mr: 1,
                            }}
                            checkedIcon={<CheckBoxIcon/>}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={Boolean(pageData.isNonInflationary)}
                            onChange={handleIsInflationaryChange}
                        />
                    }
                    labelPlacement={'top'}
                    label={""}
                />
            </FormControl>
        </Stack>
    )
}

export default Inflationary;