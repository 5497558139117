import React from 'react'

import { FormControlLabel, Checkbox } from '@mui/material'

const FavoriteChoicesPopoutItem = ( {selectedCoin, coin, saveCoinToScreener, removeCoinFromScreener} ) => {
  //console.log("top of FavoriteChoicesPopoutItem")
  //console.log("coin is: ")
  //console.dir(coin)
  //console.log("selectedCoin is:")
  //console.dir(selectedCoin)
  //console.log("coin.coin.id is: " + coin.coin.id)
  ///console.log("coin.id is: " + coin.id)
  var coinLabel = coin.name + " ("+ coin.coin.symbol+")"
  if (selectedCoin.length > 0) {
    //display ability to remove coin from list
    return (
      <FormControlLabel
        control={
          <Checkbox 
            key={coin} 
            checked={true} 
            onChange={(e) => removeCoinFromScreener(coin.id, coin.coin.id, e)} 
            name={coin.coin.id} />
        }
        label={coinLabel}
        key={coin}

      />
    )
  } else {
    //display ability to add a coin to the list
    return (
      <FormControlLabel
        control={
          <Checkbox 
            key={coin} 
            checked={false} 
            onChange={(e) => saveCoinToScreener(coin.coin.id, e)} 
            name={coin.coin.id} />
        }
        label={coinLabel}
        key={coin}
      />
    )
  }

}

export default FavoriteChoicesPopoutItem