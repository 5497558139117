import React, {useState, useEffect} from 'react'

import {
  Button, Checkbox, Stack, Box, Typography, Modal, Backdrop,
  Fade, TextField, Grid, Switch, InputLabel, FormControl, MenuItem,
  Select, alpha
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch'

import { AiOutlineBell } from 'react-icons/ai'
import { ImCancelCircle } from 'react-icons/im'
import { CandlestickChart } from '@mui/icons-material'
import ResearchCoinChartMarket from './ResearchCoinChartMarket'
import ChartDisplayByCoinLWChart from '../ResearchCustom/ChartDisplayByCoinLWChart'

const ResearchCoinChart = ( {coin, chartLabel, chartCoinName, chartCoinSymbol='', chartRangeDetails, researchChartLabelChange, chartDays, chartInterval, defaultChartInterval='15m', sourcePostsForChart=[], userObject} ) => {
  console.log("top of ResearchCoinChart....coin/chartCoinName/chartCoinSymbol is: " + coin + "/" + chartCoinName + "/" + chartCoinSymbol)
  //console.dir(sourcePostsForChart)
  //console.log("chartRangeDetails is: " + chartRangeDetails)

  const theme = useTheme()

  const [ pageSize,setPageSize] = useState(180)

  const [ priceAlertOpen,setPriceAlertOpen] = useState(false)
  const [ priceAlert,setPriceAlert] = useState(0)
  const [ priceAlertError,setPriceAlertError] = useState(false)
  const [ showSocialRank, setShowSocialRank ] = useState(true)

  const [ chartIntervalLW, setChartIntervalLW ] = useState(defaultChartInterval)
  const [ pageSizeLW,setPageSizeLW] = useState(384) //default to daily candles
  const [ chartLWRangeDetails, setChartLWRangeDetails ] = useState('4dys/15m') 

  const [ showLWChoice, setShowLWChoice ] = useState(false)
  const [ useLWChart, setUseLWChart ] = useState(false)
 
  const handleChange = (event) => {
    setShowSocialRank(event.target.checked);
  }

  useEffect(() => {
    console.log("top of useEffect in ResearchCoinChart andchartCoinSymbol is: " + chartCoinSymbol)
    if (chartCoinSymbol.length !== 0) {
      const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
          }
      ;(async () => {
        var getExchangeChartDataURL = `api/v1/coin/exchangechart1d/?symbol=${chartCoinSymbol}&page_size=100&page=1&ordering=-timestamp`
        const exhangechartResponse = await fetchData(getExchangeChartDataURL, "could not receive exchange chart data", fetchParams).promise
        console.log("getExchangeChartDataURL is: " + getExchangeChartDataURL)
        console.log("exhangechartResponse is:")
        console.dir(exhangechartResponse)
        //console.log("exhangechartResponse.results.length is: ")
        //console.dir(exhangechartResponse.results.length)
        //console.log("exhangechartResponse.count is: ")
        //console.dir(exhangechartResponse.count)
        if (exhangechartResponse.results && exhangechartResponse.results.length > 0 && exhangechartResponse.count > 0) {
          //console.log("setting showLWChoice to true") 
          setShowLWChoice(true)
        } else {
          //console.log("leaving showLWChoice as:") 
          //setShowLWChoice(false)
          //console.dir(showLWChoice)
        }
      })()
    } else {
      console.log("chartCoinSymbol has no length: " + chartCoinSymbol)
    }
  },[chartCoinSymbol]) 

  const handleChartIntervalChange = ( e ) => {
    //console.log("top of handleChartIntervalChange...e.target.value is: ")
    //console.dir(e.target.value)
    switch (e.target.value) {
      case '1w':
        setChartLWRangeDetails('2y/wk')
        setChartIntervalLW('1w')
        setPageSizeLW(120)
        break;        
      case '1d':
        setChartLWRangeDetails('1y/dy')
        setChartIntervalLW('1d')
        setPageSizeLW(400)
        break;
      case '4h':
        setChartLWRangeDetails('3m/4h')
        setChartIntervalLW('4h')
        setPageSizeLW(540) 
        break;
      case '1h':
        setChartLWRangeDetails('2w/1h')
        setChartIntervalLW('1h')
        setPageSizeLW(336) //14 days
        break;
      case '15m':
        setChartLWRangeDetails('4dys/15m')
        setChartIntervalLW('15m')
        setPageSizeLW(384)
        break;
      case '5m':
        setChartLWRangeDetails('2dys/5m')
        setChartIntervalLW('5m')
        setPageSizeLW(576)
        break;
      case '1m':
        setChartLWRangeDetails('12h/1m')
        setChartIntervalLW('1m')
        setPageSizeLW(720)
        break;
      default:
        //not sure what this is...set the default settings
        setChartLWRangeDetails('4dys/15m')
        setChartIntervalLW('15m')
        setPageSizeLW(384)
    }
  }

  const saveAlert = e => {
    e.preventDefault()
    //console.log("top of saveAlert")

    if ((priceAlert === 0) || (priceAlert === '')) {
      setPriceAlertError(true)
    } else if (priceAlert > 0) {
      //console.log("priceAlert is greater than 0")
      if (userObject.user.is_vip) {
        const fetchParams = {
          method: 'POST',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'coin': chartCoinName, 
            'price_target': priceAlert,
            'multiple': alertMultiple,
          })        
        }
        ;(async () => {
          try {
            var urlString = `api/v1/vip/alertcreate/`
            const saveAlertResponse = await fetchData(urlString, "could not save new price alert", fetchParams).promise
            //console.log(saveAlertResponse)
            //console.log("saveAlertResponse saving new price alert: ")
            //console.log(urlString)
            //console.dir(saveAlertResponse)
          } catch (error) {
            console.log("something is broke new price alert: ", error)
          }
        })()
      }
      setPriceAlertOpen(false)
    } else {
      setPriceAlertError(true)
    }
  }

  const cancelAlert = e => {
    setPriceAlertOpen(false)  
  }

  const openPriceAlert = (defaultPrice) => {
    setPriceAlert(defaultPrice)
    setPriceAlertOpen(true)
  }

  const handlePriceAlertChange = e => {
    setPriceAlertError(false)
    setPriceAlert(e.target.value)
  }

  const myClickHandlerLW = (param) => {
    //console.log("params from click is:")
    //console.dir(param)
    //console.log("param.seriesPrices.keys is:")
    const iterator1 = param.seriesPrices.values()
    let prices = iterator1.next().value
    //console.log("prices is")
    //console.dir(prices)

    //console.log(iterator1.next().value)
    //price will be:
    //param.seriesPrices.0.value = { open, high, low, close}
    //volume will be:
    //param.seriesPrices.1.value = xxxx

    setPriceAlert(prices.close)
    setPriceAlertOpen(true)
    if (!param.point) {
        return;
    }

  }    

  const switchToMarketChartNoData = () => {
    //called by exchangechart/Lightweight chart/candlestick chart returns no data
    //console.log("top of switchToMarketChartNoData")
    setShowLWChoice(false)
    setUseLWChart(false)
  }

  const handleChartTypeChange = (e) => {
    //console.log("top of handleChartTypeChange and e.target.checked is: " + e.target.checked)
    setUseLWChart(e.target.checked)
  }  

  const [alertMultiple, setAlertMultiple] = React.useState(1);
  const [chartTimeFrame, setChartTimeFrame] = React.useState(3);
  const handleMultipleChange = (event) => {
    setAlertMultiple(event.target.value);
  };
  const handleChartTimeFrameChange = (event) => {
    //console.log("top of handleChartTimeFrameChange and even.target.value is: " + event.target.value)
    let days = parseFloat(event.target.value)
    //console.log("top of handleChartTimeFrameChange and days is: " + days)
    if (days > 14) {
      //setChartInterval('daily')
      setPageSize(days)
    } else if (days > 3) {
      //setChartInterval('hourly')
      setPageSize(days*24)
    } else if (days > 1) {
      //setChartInterval('quarter_hour')
      setPageSize(days*96)
    } else {
      //setChartInterval('5m')
      setPageSize(days*288)
    }

    switch (days) {
      case 365:
        researchChartLabelChange(chartCoinName, days, 'daily')
        break;
      case 180:
        researchChartLabelChange(chartCoinName, days, 'daily')
        break;
      case 90:
        researchChartLabelChange(chartCoinName, days, 'daily')
        break;
      case 30:
        researchChartLabelChange(chartCoinName, days, 'daily')
        break;
      case 14:
        researchChartLabelChange(chartCoinName, days, 'hourly')
        break;
      case 7:
        researchChartLabelChange(chartCoinName, days, 'hourly')
        break;
      case 3:
        researchChartLabelChange(chartCoinName, days, 'hourly')
        break;
      case 1:
        researchChartLabelChange(chartCoinName, days,'5m')
        break;
      default:
        //not sure what this is
        break;
    }
    setChartTimeFrame(days);
  };

  const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: theme.palette.primary.dark,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));

  if (coin && coin.length) {
    return (
      <Stack direction="column" sx={{width: '100%', minHeight: '275px', height: '40%'}}>
        <Stack spacing={1} direction="row" sx={{justifyContent: 'space-between', alignItems: 'center', paddingLeft: '5px', paddingRight: '5px'}}>
          <Box sx={{padding: 1, backgroundColor: theme.palette.primary.dark, borderRadius: 1}}>
            <Typography sx={{color: '#fff'}}>{chartLabel}</Typography>
          </Box>
          {useLWChart ?  
            <Select
              sx={{height: 40}}
              value={chartIntervalLW}
              label="chart time frame"
              onChange={handleChartIntervalChange}
            >
              <MenuItem value={'1w'}>1w</MenuItem>
              <MenuItem value={'1d'}>1d</MenuItem>
              <MenuItem value={'4h'}>4h</MenuItem>
              <MenuItem value={'1h'}>1h</MenuItem>
              <MenuItem value={'15m'}>15m</MenuItem>
              <MenuItem value={'5m'}>5m</MenuItem>
              <MenuItem value={'1m'}>1m</MenuItem>
            </Select>          
            :
            <Select
              sx={{height: 40}}
              value={chartTimeFrame}
              label="chart time frame"
              onChange={handleChartTimeFrameChange}
            >
              <MenuItem value={365}>1Y</MenuItem>
              <MenuItem value={180}>6M</MenuItem>
              <MenuItem value={90}>3M</MenuItem>
              <MenuItem value={30}>1M</MenuItem>
              <MenuItem value={14}>2W</MenuItem>
              <MenuItem value={7}>1W</MenuItem>
              <MenuItem value={3}>3D</MenuItem>
              <MenuItem value={1}>1D</MenuItem>
            </Select>
          }
          <Stack direction="row" justifyContent='space-around' alignItems="center" display="flex">
            <Typography sx={[{fontSize: 12}, useLWChart && {color: theme.palette.grey[200]} ]}>Engagement</Typography>
            <StyledSwitch
                sx={{alignSelf: 'center'}}
                checked={showSocialRank}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={useLWChart ? true : false}
            />
            <Typography sx={[{fontSize: 12}, useLWChart && {color: theme.palette.grey[200]} ]}>Rank</Typography>
          </Stack>

          { showLWChoice ?
          <Stack direction="row" justifyContent='space-around' alignItems='center'>
            <CandlestickChart 
                sx={{
                    color: theme.palette.primary.dark
                }}
            />
            
              <Checkbox 
                size="small" 
                checked={useLWChart} 
                onChange={handleChartTypeChange}
              />
          </Stack> : null }   

          <Box sx={{padding: 1, backgroundColor: theme.palette.primary.dark, borderRadius: 1}}>
            <Typography sx={{color: '#fff'}}>{useLWChart ? chartLWRangeDetails : chartRangeDetails}</Typography>
          </Box>
        </Stack>
        { useLWChart ? 
          <Box sx={{marginTop: '10px'}} >
            <ChartDisplayByCoinLWChart 
              chosenCoin={chartCoinSymbol}
              chartInterval={chartIntervalLW}
              pageSize={pageSizeLW}
              boxHeight={1}
              rowHeight={340}
              myClickHandler={myClickHandlerLW}
              switchToMarketChartNoData={switchToMarketChartNoData}
            />  
          </Box>      
          : 
          <ResearchCoinChartMarket
            chartDays={chartDays}
            coin={coin} 
            showSocialRank={showSocialRank}
            sourcePostsForChart={sourcePostsForChart}
            chartInterval={chartInterval}
            pageSize={pageSize}
            userObject={userObject}
            openPriceAlert={openPriceAlert}
          />
        }
        <Modal
          aria-labelledby='spring-modal-price-alert'
          aria-describedby='spring-modal-price-alert-description'
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
          open={priceAlertOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          disableEscapeKeyDown={true}
        >
          <Fade in={priceAlertOpen}>
            <Box sx={{ position: 'absolute', width: 600, backgroundColor: theme.palette.dark[800],  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)', borderRadius: '8px'}}>
              <h2 id='spring-modal-price-alert'>Create A Price Alert For {chartCoinName}</h2>
              <p id='spring-modal-price-alert-description' style={{ fontSize: '1rem' }}>
                You can have us notify you when price of {chartCoinName} hits a certain price.  Just insert the value below and hit enter
                <br /><br />
                <Grid container>
                  <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around">
                      <Typography variant="subtitle2"></Typography>
                      <TextField
                        type='text'
                        required
                        fullWidth
                        id='priceAlert'
                        label='price'
                        defaultValue={priceAlert}
                        name='priceAlert'
                        onChange={handlePriceAlertChange}
                        helperText={priceAlertError ? 'Your price must be more than 0' : null}
                        error={priceAlertError}
                        inputProps={{ maxLength: 100 }}
                        variant="filled"
                        focused                
                      />

                    </Stack>
                  </Grid>
                  <FormControl fullWidth style={{ paddingBottom: '25px', minHeight: 200}}>
                    <InputLabel id="demo-simple-select-label">Number of Alerts</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={alertMultiple}
                        label="Number of Alerts"
                        onChange={handleMultipleChange}
                    >
                      <MenuItem value={1}>One</MenuItem>
                      <MenuItem value={3}>Three</MenuItem>
                      <MenuItem value={5}>Five</MenuItem>
                    </Select>
                  </FormControl>
                  <Grid item xs={12}>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                      <Button startIcon={<AiOutlineBell />} variant='outlined' onClick={saveAlert} color='primary'>
                        Click To Save Your Alert
                      </Button>
                      <Button startIcon={<ImCancelCircle />} variant='outlined' onClick={cancelAlert} color='warning' sx={{ml: 1}}>
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </p>
            </Box>
          </Fade>
        </Modal>           
      </Stack>
    )
  } else {
    return (
      <Stack direction="column" sx={{width: '100%', minHeight: '275px'}}>
        <Typography sx={{color: theme.palette.secondary.main, paddingBottom: '5px', marginLeft: '10px',}}>We could not find chart data for {chartLabel}</Typography>
        <Typography sx={{color: theme.palette.primary.main, marginTop: '15px', paddingBottom: '5px', marginLeft: '10px',}}>Please choose another coin to see it's chart</Typography>
      </Stack>
    )
  }

}

export default ResearchCoinChart