import React from 'react'
import { Typography, Stack, Box } from '@mui/material'
import { XAxis, YAxis, Line, LineChart, ResponsiveContainer  } from "recharts"

import { useTheme } from '@mui/material/styles'
const HealthDisplayNetflow = ( { ruleThemAll } ) => {

    const theme = useTheme()

    const abbreviateValue = (flowValue) => {
        if (flowValue > 1000000000) {
            return ((flowValue / 1000000000).toFixed(2)) + 'B'
        } else if (flowValue > 1000000) {
            return ((flowValue/1000000).toFixed(2)) + 'M'
        } else if (flowValue > 1000) {
            return ((flowValue/1000).toFixed(2)) + 'K'
        } else if (flowValue < 1) {
            return (flowValue.toFixed(2))
        } else {
            //just display the full amount
            return flowValue
        }
    }

    //only need last 7 days of data

    //var netflowData = ruleThemAll.slice((ruleThemAll.length-8), (ruleThemAll.length-1)) //as the data is current first....do not use
    var netflowData = ruleThemAll.slice(0, 7)
    netflowData.reverse()

    if (netflowData.length === 0) {
        return (
            <Box sx={{width: '100%'}} >
                <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[500]}}>Netflow</Typography>
                <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
            </Box>
        )
    } else {

        //console.log("netflowData is:")
        //console.dir(netflowData)

        var rechartsNetflowData = netflowData.map( (data) => {
            return { 'name': 0, 'value': data.healthData.netflow , 'amt': data.healthData.netflow, 'date': data.healthData.timestamp }
        })

        //get data range for reserve data
        var minMaxNetflow = netflowData.map( (data) => {
            if (data.healthData)
                return data.healthData.netflow
        })

        //the current day may not be there...get second to last
        var useThisLastNetflow = netflowData[netflowData.length-1]

        //get % change over 7 days
        var netflowChangePercent = Math.abs( useThisLastNetflow.healthData.netflow - netflowData[0].healthData.netflow )/ netflowData[0].healthData.netflow * 100

        var netflowChangeColor = theme.palette.success.main
        var netflowChangePrecursor = "+"
        if (useThisLastNetflow.healthData.netflow < netflowData[0].healthData.netflow) {
            netflowChangeColor = theme.palette.error.main
            netflowChangePrecursor = "-"
        }

        var netflowMax = minMaxNetflow.reduce((a, b) => { return Math.max(a, b) })
        var netflowMin = minMaxNetflow.reduce((a, b) => { return Math.min(a, b) })

        const displayQuickDate = (timestamp) => {
            let dateOfData = new Date(timestamp)
            return dateOfData.getMonth() + '/' + dateOfData.getDate()
        }

        return (
            <Stack
                direction="column"
                sx={{width: '100%', backgroundColor: theme.palette.dark.dark, padding: '5px', paddingTop: '0px', borderRadius: '5px', minHeight: '10vh', alignItems: 'stretch', marginTop: '5px'}}
            >
                <Stack
                    direction="row"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>Netflow</Typography>
                </Stack>

                <Box sx={{width: '99%', backgroundColor: theme.palette.dark.main, alignItems: 'center'}} >
                    <Stack direction="column" display="flex" justifyContent="space-between" sx={{ minHeight: '12vh'}}>
                        <Typography variant="caption" sx={{marginLeft: '5px'}}>netflow</Typography>
                        <ResponsiveContainer width='99%' height={110}>
                            <LineChart
                                data={rechartsNetflowData}
                            >
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={ ( value ) => displayQuickDate(value)}
                                    style={{fontSize: '.6rem'}}
                                />
                                <YAxis
                                    dataKey="value"
                                    domain={[netflowMin,netflowMax]}
                                    tick={{stroke: '#888', strokeWidth: .5}}
                                    tickFormatter={(value) => abbreviateValue(value)}
                                    orientation='right'
                                    style={{fontSize: '.8rem'}}
                                />
                                <Line tickLine={true} strokeWidth={2} type="monotone" dataKey="value" dot={false} stroke={theme.palette.primary.dark} />
                            </LineChart>
                        </ResponsiveContainer>
                        <Box sx={{width: '100%', paddingLeft: '5px', minHeight: '2vh', paddingRight: '5px', height: '100%', backgroundColor: theme.palette.dark[800]}}>
                            <Stack direction="row" display="flex" alignItems="baseline" justifyContent="space-around" >
                                <Typography display="inline" sx={{fontSize: '.7rem'}}>7 day change:</Typography>
                                <Typography variant="button" sx={{fontSize: '1.2rem', fontWeight: 700, color: netflowChangeColor}} >{netflowChangePrecursor} {Math.abs(netflowChangePercent).toFixed(2)} %</Typography>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        )
    }
}

export default HealthDisplayNetflow