import React from 'react'
import { Typography, Stack, ListItemText, List, ListItemIcon, ListItem } from '@mui/material'
import { FaArrowAltCircleLeft } from 'react-icons/fa'
import { useTheme } from '@mui/material/styles'
import CheckIcon from '@mui/icons-material/Check'

const ResearchTutorial6ChartData = () => {

    const theme = useTheme()

    return (
        <Stack direction="column" alignItems="flex-start" sx={{padding: '15px'}}>
            <Typography sx={{paddingTop: '20px', marginRight: '20px', fontSize: '2em', color: theme.palette.warning.dark}}><FaArrowAltCircleLeft size={20} /> Coin Chart </Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>So you can see the price action of the selected tweet, this price chart will always change to match the selected tweet. 
            To view the price chart for a different coin, selected any tweet icon below the price charts.</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>The Bitcoin price chart is displayed to the left so you can compare both charts.
            Within these results you'll see:</Typography>
            <List>
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingRight: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText ><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>Price chart with price in red and volume in the blue bar chart</Typography></ListItemText>
                </ListItem>
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingRight: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>Coin name displayed top left</Typography></ListItemText>
                </ListItem>                
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingRight: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>Current selected time frame and interval</Typography></ListItemText>
                </ListItem>             
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingRight: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>Ability to choose different time frames/intervals at top</Typography></ListItemText>
                </ListItem>          
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingRight: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>Click on the chart to have the system send you a price alert for that coin at a specific price level</Typography></ListItemText>
                </ListItem>    
                <ListItem >
                    <ListItemIcon><CheckIcon sx={{paddingRight: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>Rolling over the chart will give you the price and volume for the time period you are currently hovered over</Typography></ListItemText>
                </ListItem>                              
            </List>
        </Stack>    
    )
}

export default ResearchTutorial6ChartData