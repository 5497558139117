import React from 'react'

import { FormControlLabel, Checkbox } from '@mui/material'

const ProfileSectionFavoriteCoinsItem = ( {favoritedCoin, coin, saveFavoriteCoins, removeFavoriteCoin} ) => {
  //console.log("top of ProfileSectionFavoriteCoinsItem")
  //console.log("favoritedCoin is: " + favoritedCoin)
  //console.dir(coin)
  if (favoritedCoin) {
    //display ability to remove coin from list
    return (
      <FormControlLabel
        control={
          <Checkbox key={coin.coin.symbol} checked={favoritedCoin} onChange={() => removeFavoriteCoin(coin.coin.id)} name={coin.coin.id} />
        }
        label={coin.name}
      />
    )
  } else {
    //display ability to add a coin to the list
    return (
      <FormControlLabel
        control={
          <Checkbox key={coin.coin.symbol} checked={favoritedCoin} onChange={() => saveFavoriteCoins(coin.coin.id)} name={coin.coin.id} />
        }
        label={coin.name}
      />
    )
  }

}

export default ProfileSectionFavoriteCoinsItem