import React, { useState, useEffect } from 'react'
import {
    Box, useMediaQuery, Paper, IconButton, Divider, FormControl, FormGroup, Pagination,
    CircularProgress,
    Grid,
    Stack,
    Popover,
    Button,
    Typography,
    Popper, Icon
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { CgSelectO } from 'react-icons/cg' 
import PerfectScrollbar from 'react-perfect-scrollbar'

// project imports
import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'

import { IconSquareX } from '@tabler/icons'

const WatchListDisplaySelector = ( { watchLists, changeScreener } ) => {

    const theme = useTheme()
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)  

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        setOpen((prevOpen) => !prevOpen)
    }       

    const chooseWatchlist = (watchlist) => {
        console.log("top of handleClick and watchlist is:")
        console.dir(watchlist)
        changeScreener(watchlist)
        setAnchorEl(null)
        setOpen((prevOpen) => !prevOpen)
    }    

    const handleClose = (event) => {
        //console.log("top of handleClose and chosenCoins is:")
        //console.dir(chosenCoins)
        setAnchorEl(null)
        setOpen((prevOpen) => !prevOpen)
    }
    
    const id = open ? 'simple-popover' : undefined   

    return (
        <Box sx={{ display: 'flex' }}>
            <CgSelectO 
                aria-describedby={id}
                onClick={handleClick}
                color={theme.palette.primary.main}
                style={{
                    color: theme.palette.primary.main,
                    '&:hover': {
                        //background: theme.palette.secondary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
                        color: theme.palette.primary.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
                    },
                    cursor: 'pointer'
                }}

                size={18}
            />                
          
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 400, left: 900 }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >

              <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} >
                <Paper >
                  <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '300px', minHeight: '300px'}}>
                    <Grid container spacing={2} sx={{marginTop: '15px'}}>
                    <Grid item xs={12} sx={{bgcolor: theme.palette.dark[800]}}>
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between">
                            <Typography display="inline" variant="h3" sx={{marginTop: '15px', marginLeft: '30px'}}>Choose Watchlist</Typography>
                            <IconButton
                                aria-label="close"
                                sx={{color: theme.palette.error.main, marginTop: '12px'}}
                                onClick={handleClose}
                            >
                                <IconSquareX />
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{bgcolor: theme.palette.dark[800]}}><Divider /></Grid>
                    <Grid item xs={12} sx={{bgcolor: theme.palette.dark[800]}}>
                        <Stack direction="column" sx={{minHeight: '650px'}}>
                            {watchLists.map( (watchlist,watchlistIndex) => {
                                if (watchlist !== 'All Coins')
                                    return (
                                        <Button 
                                            key={watchlistIndex}
                                            variant="text" 
                                            onClick={()=>chooseWatchlist(watchlist)}
                                            style={{cursor: 'pointer'}}
                                        >
                                            {watchlist}
                                        </Button>
                                    )
                                }
                            )}
                            
                        </Stack>
                    </Grid>
                    </Grid>
                  </MainCard>
                </Paper>
              </Transitions>
            </Popover>

        </Box>
    )
}

export default WatchListDisplaySelector