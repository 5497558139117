import React, { memo } from 'react'

import { Select, FormControl, InputLabel, MenuItem } from '@mui/material'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  menuPaper: {
    maxHeight: 300
  }
}));

const FavoriteSortChoices = ( { clearSort, sortResults, selectedSort } ) => {

  //console.log("top of FavoriteSortChoices")

  const sortChoices = ['rank-highest', 'rank-lowest', 'name-alphabetical', 'name-alphabetical-reverse', 'symbol-alphabetical', 'symbol-alphabetical-reverse', 'price-highest', 'price-lowest', 'volume-lowest', 'volume-highest' ]

  const classes = useStyles()

  const handleSortChange = (event) => {
    //console.log("top of handleSortChange")
    //console.log(event.target.value)
    if ((event.target.value === 'clear' ) || (event.target.value === selectedSort)){
      clearSort()
    } else {
      sortResults(event.target.value)
    }
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 300, marginBottom: '30px' }} size="small">
    <InputLabel id="demo-select-small">Sort By</InputLabel>
    <Select
      MenuProps={{ classes: { paper: classes.menuPaper } }}
      labelId="select-category"
      id="select-category"
      value={selectedSort}
      label="category filter"
      onChange={handleSortChange}
      sx={{maxHeight: "300px"}}
    >
      <MenuItem value='clear'>clear category filter</MenuItem>
      {sortChoices.map( (item, index) => <MenuItem key={index} value={item} >{item}</MenuItem>)}
    </Select>
    </FormControl>
  )
}

export default memo(FavoriteSortChoices)