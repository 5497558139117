import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconStethoscope, IconArrowUpCircle, IconArrowDownCircle } from '@tabler/icons';

const MinerInflow = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About Miner Inflow</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - Miner Inflows">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
                <Tab icon={<IconStethoscope />} value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
                The total amount of coins transferred to the affiliated miners' wallets.
                It shows the amount of coins that are received as a reward for mining and the coins they purchased.
              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="h4">By Value Itself</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowUpCircle color='#00b341' />
                    </ListItemIcon>
                    <ListItemText
                      primary="High : A lot of miners' coins are exposed to selling - Bearish "
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Low : only a few of miners' coins are exposed to selling - Bullish  "
                    />
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="h4">By examining trend</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconArrowUpCircle color='#00b341'/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Increasing : Miners' selling power is increasing - Bearish"
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Decreasing : Miners' selling power is decreasing - Bullish"
                    />
                  </ListItem>
                </List>
              </Typography>
            </TabPanel>
            <TabPanel value="3">
              <Typography variant="h4">About Miner Outflow</Typography>
              <Typography variant="body2" sx={{marginTop: '15px'}}>
                Miners send their coins outside for various reasons.
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
                Considering the fact that moving coins cost fees, miners' wallets send mined coins or purchased coins outside for two possible scenarios
              </Typography>
              <Typography variant="h5" sx={{fontWeight: 600, marginTop: '15px'}}> 1) Moving to the exchanges for possible selling - Bearish</Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
                Moving their coins to the exchanges includes the purposes of selling to cover the cost or to prepare for possible sell. Both cases are correlated to sell action which naturally lead to interpret this reason to the price drop which indicates a bearish sign.
              </Typography>
              <Typography variant="h5" sx={{fontWeight: 600, marginTop: '15px'}}>2) Internal wallet movement - Neutral</Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
                Moving their coins to miners' wallets excluding trading purposes has a neutral sign. It could be done to security issues.
              </Typography>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default MinerInflow