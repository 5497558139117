import React, { useEffect, useRef, useState } from 'react'
import { Stack, Typography, Box, Link, Button, Popover, useMediaQuery, Menu, MenuItem, ListItemIcon, ListItemText, Paper, IconButton } from '@mui/material'
import { Home, Favorite, Biotech, MonitorHeart } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { RiVipCrownFill } from 'react-icons/ri'
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import { MdPersonPinCircle, MdDashboard } from 'react-icons/md'
import MenuIcon from '@mui/icons-material/Menu'
import OverviewTutorial from "../OverviewTutorial/OverviewTutorial"
//import { useCookies } from "react-cookie"
import TwitterIcon from '@mui/icons-material/Twitter'


const selectedPageStyle = {
  fontWeight: 800,
  textDecoration: "none",
  fontSize: '1.5em',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap'
}

const nonSelectedPageStyle = {
  fontWeight: 400,
  textDecoration: 'none',
  fontSize: '1.2em',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap'
}

const selectedIconStyle = {
    fontSize: '2em',
    marginRight: '2px', 
}

const nonSelectedIconStyle = {
    fontSize: '1.4em',
    position: 'relative', 
    marginRight: '2px', 
    bottom: '2px',
}

const NavigationTop = ( { selectedPage, userObject, tutorialOpen, setTutorialOpen } ) => {
    console.log("top of NavigationTop..........................")
    console.log("tutorialOpen is:" + tutorialOpen)
    console.log("selectedPage is:" + selectedPage)
    console.log("userObject is:")
    console.dir(userObject)
    const theme = useTheme()
    const medScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"))

    const hasVisitedTutorial = false

    const [anchorElSmallScreen, setAnchorElSmallScreen] = useState(null)
    const openSmallScreen = Boolean(anchorElSmallScreen)

    const [overviewTutorialStep,setOverviewTutorialStep] = useState(1)
    const [popoverAnchorReference,setPopoverAnchorReference] = useState('anchorPosition') //anchorEl, anchorPosition, none
    const [anchorPositionX,setAnchorPositionX] = useState(window.innerWidth-150) //numeric value to center popover horizontally
    const [anchorPositionY,setAnchorPositionY] = useState(window.innerHeight-150) //numeric value to center popover vertically
    const [popoverAnchorEl,setPopoverAnchorEl] = useState(window.document.getElementById('root'))
    const [popoverAnchorOriginVert,setPopoverAnchorOriginVert] = useState(window.innerHeight-150)
    const [popoverAnchorOriginHoriz,setPopoverAnchorOriginHoriz] = useState(window.innerWidth-150)
    const [popoverTransformOriginVert,setPopoverTransformOriginVert] = useState(window.innerHeight-150)
    const [popoverTransformOriginHoriz,setPopoverTransformOriginHoriz] = useState(window.innerWidth-150)
    const [popoverID,setPopoverID] = useState('welcome')

    /*
    useEffect(() => {
        // console.log(document.cookie)
        // if(document.cookie.includes('has_visited_overview_tutorial=true')){
        //     console.log('true')
        //     setHasVisitedTutorial(true);
        // }else{
        //     console.log('false')
        //     setHasVisitedTutorial(false);
        // }
    }, [])
    */

    const currenciesTabRef = useRef()
    const watchlistsTabRef = useRef()
    const feedTabRef = useRef()
    const tweetDisplayTabRef = useRef()
    const healthTabRef = useRef()
    const researchTabRef = useRef()
    const desktopTabRef = useRef()
    const vipTabRef = useRef()
    const portfolioTabRef = useRef()
    const InfluencerTabRef = useRef()

    //console.log("top of NavigationTop and userObject is:")
    //console.dir(userObject)
    const changePopover = ( newAnchorReference, newAnchorPositionX, newAnchorPositionY, newAnchorEl, newPopoverID, newPopoverAnchorOriginVert, newPopoverAnchorOriginHoriz, newPopoverTransformOriginVert, newPopoverTransformOriginHoriz ) => {
        //console.log("top of changePopover and newPopoverID is: " + newPopoverID)
        //console.log("newAnchorReference is: " + newAnchorReference)
        //console.log("newAnchorPositionX is: " + newAnchorPositionX)
        //console.log("newAnchorPositionY is: " + newAnchorPositionY)
        //console.log("newAnchorEl is:")
        //console.dir(newAnchorEl)
        //console.log("newPopoverAnchorOriginVert is: ")
        //console.dir(newPopoverAnchorOriginVert)
        //console.log("newPopoverAnchorOriginHoriz is: ")
        //console.dir(newPopoverAnchorOriginHoriz)
        switch (newAnchorEl) {
            case 'currencies':
                setPopoverAnchorEl(currenciesTabRef.current)
                break
            case 'watchlists':
                setPopoverAnchorEl(watchlistsTabRef.current)
                break
            case 'vip':
                setPopoverAnchorEl(vipTabRef.current)
                break
            case 'research':
                setPopoverAnchorEl(researchTabRef.current)
                break
            case 'dashboard':
                setPopoverAnchorEl(desktopTabRef.current)
                break                
            case 'health':
                setPopoverAnchorEl(healthTabRef.current)
                break
            case 'feed':
                setPopoverAnchorEl(feedTabRef.current)
                break
            case 'portfolio':
                setPopoverAnchorEl(portfolioTabRef.current)
                break
            case 'influencer':
                setPopoverAnchorEl(InfluencerTabRef.current)
                break
            case 'tweets':
                setPopoverAnchorEl(tweetDisplayTabRef.current)
                break
            default:
                setPopoverAnchorEl('none')
                break
        }
        setPopoverAnchorReference(newAnchorReference)
        setAnchorPositionX(newAnchorPositionX)
        setAnchorPositionY(newAnchorPositionY)
        setPopoverAnchorOriginVert(newPopoverAnchorOriginVert)
        setPopoverAnchorOriginHoriz(newPopoverAnchorOriginHoriz)
        setPopoverTransformOriginVert(newPopoverTransformOriginVert)
        setPopoverTransformOriginHoriz(newPopoverTransformOriginHoriz)

        setPopoverID(newPopoverID)
    }

    const handleTutorialStepClick = (clickedButton) => {
        //need to set anchorEl based on the step
        //each step will attach to a different element in the design
        // create individual useRef for each component to reference within tutorial
        //those references will need to be passed in???

        if (clickedButton === "next") setOverviewTutorialStep( prev => prev + 1)
        else setOverviewTutorialStep( prev => prev - 1)

    }

    const closeTutorial = () => {
        setTutorialOpen(false)
        setPopoverAnchorEl('none')
    }
    if (Object.keys(userObject).length === 0) {
        console.log("userObject is empty")
        return null
    }

    const handleClick = (event) => {
        setAnchorElSmallScreen(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorElSmallScreen(null)
    }    
    console.log("just before return in NavigationTop")
    if (medScreenOrSmaller) {
        //this is a smaller screen...use drawer
        console.log("display nedScreenOrSmaller")
        return (
            <Paper sx={{width: '50px', maxWidth: '33%'}}>
                <IconButton
                    id="demo-positioned-button"
                    aria-controls={openSmallScreen ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSmallScreen ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MenuIcon sx={{color: theme.palette.grey[200]}}/>
                </IconButton>
                <Menu
                    sx={{
                        "& .MuiMenu-paper": 
                        { backgroundColor: theme.palette.grey[400], }
                    }}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorElSmallScreen}
                    open={openSmallScreen}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem
                        component={Link}
                        href="/home"
                    >
                        <ListItemIcon>
                            <Home fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Home</ListItemText>
                    </MenuItem>          
                    <MenuItem
                        component={Link}
                        href="/mycurrencies"
                    >
                        <ListItemIcon>
                            <Favorite fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>My Coins</ListItemText>
                    </MenuItem>  
                    <MenuItem
                        component={Link}
                        href="/homefeed"                    
                    >
                        <ListItemIcon>
                            <RssFeedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Feed</ListItemText>
                    </MenuItem>  
                    <MenuItem
                        component={Link}
                        href="/tweetdisplay"                     
                    >
                        <ListItemIcon>
                            <TwitterIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Tweets</ListItemText>
                    </MenuItem>  
                    <MenuItem
                        component={Link}
                        href="/health"                     
                    >
                        <ListItemIcon>
                            <MonitorHeart fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Health</ListItemText>
                    </MenuItem>   
                    { userObject.user.is_researcher ?   
                        <MenuItem
                            component={Link}
                            href="/resarch3"                         
                        >
                            <ListItemIcon>
                                <Biotech fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Researcher</ListItemText>
                        </MenuItem> : null }             
                        
                    {/* no use putting dashboard on such a small screen
                        userObject.user.is_researcher ?   
                        <MenuItem
                            component={Link}
                            href="/researchcustom"                         
                        >
                            <ListItemIcon>
                                <Dashboard fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Dashboard</ListItemText>
                    </MenuItem> : null  */}
                    { userObject.user.is_vip ?   
                        <MenuItem
                            component={Link}
                            href="/vip"                         
                        >
                            <ListItemIcon>
                                <RiVipCrownFill fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>VIP</ListItemText>
                        </MenuItem> : null }      

                    <MenuItem
                        component={Link}
                        href="/pt"                     
                    >
                        <ListItemIcon>
                            <AccountBalanceIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Portfolio</ListItemText>
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        href="/influencer"                     
                    >
                        <ListItemIcon>
                            <MdPersonPinCircle fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText>Influencer</ListItemText>
                    </MenuItem>                                                                                                                                                                              
                </Menu>
            </Paper>            
        )
    } else {
        console.log("not small screen in NavigationTop.js-------------------")
        return (
            <Box sx={{width: '100%' }}>
                <Popover
                    open={tutorialOpen}
                    id={popoverID}
                    anchorReference={popoverAnchorReference}
                    anchorPosition={{top: anchorPositionY, left: anchorPositionX }}
                    anchorEl={popoverAnchorEl}
                    anchorOrigin={{horizontal: popoverAnchorOriginHoriz, vertical: popoverAnchorOriginVert}}
                    transformOrigin={{vertical: popoverTransformOriginVert, horizontal: popoverTransformOriginHoriz}}
                >
                    <Box sx={{width: '100%', border: 4, borderColor: '#1D9BF0', borderRadius: 5}}>
                        <OverviewTutorial
                            overviewTutorialStep={overviewTutorialStep}
                            changePopover={changePopover}
                            userObject={userObject}
                        />
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around" sx={{padding: '10px'}}>
                            <Button size="small" variant="contained" startIcon={<ArrowBackIosNewIcon />} disabled={overviewTutorialStep === 1} onClick={()=>handleTutorialStepClick('previous')}>
                                Previous
                            </Button>
                            <Button size="small" variant="contained" endIcon={<HighlightOffIcon />} onClick={()=>closeTutorial()}>
                                exit tutorial
                            </Button>
                            <Button size="small" variant="contained" endIcon={<NavigateNextIcon />} disabled={overviewTutorialStep === 11} onClick={()=>handleTutorialStepClick('next')}>
                                Next
                            </Button>
                        </Stack>
                    </Box>
                </Popover>

                <Stack
                    direction="row"
                    spacing={6}
                >
                    <Link ref={currenciesTabRef} href="/" underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <Stack direction="row" display="flex" alignItems="center">
                            <Home sx={[selectedPage === "currencies" ? selectedPageStyle : nonSelectedPageStyle, selectedPage === "currencies" ? selectedIconStyle : nonSelectedIconStyle]} />
                            <Typography sx ={ selectedPage === "currencies" ? selectedPageStyle : nonSelectedPageStyle  } >
                                Currencies
                            </Typography>
                        </Stack>
                    </Link>
                    <Link ref={watchlistsTabRef} href="/mycurrencies" underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <Stack direction="row" display="flex" alignItems="center">
                            <Favorite sx={[selectedPage === "mycurrencies" ? selectedPageStyle : nonSelectedPageStyle, selectedPage === "mycurrencies" ? selectedIconStyle : nonSelectedIconStyle]} />
                            <Typography sx ={[selectedPage === "mycurrencies" ? selectedPageStyle : nonSelectedPageStyle, {marginLeft: '2px'}]} > 
                                My Coins
                            </Typography>
                        </Stack>
                    </Link>
                    <Link ref={feedTabRef} href="/homefeed" underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <Stack direction="row" display="flex" alignItems="center">
                            <RssFeedIcon sx={[selectedPage === "homefeed" ? selectedPageStyle : nonSelectedPageStyle, selectedPage === "homefeed" ? selectedIconStyle : nonSelectedIconStyle]} />
                            <Typography sx ={[selectedPage === "homefeed" ? selectedPageStyle : nonSelectedPageStyle, {marginLeft: '2px'}]} > 
                                Feed
                            </Typography>
                        </Stack>
                    </Link>
                    <Link ref={tweetDisplayTabRef} href="/tweetdisplay" underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <Stack direction="row" display="flex" alignItems="center">
                            <TwitterIcon sx={[selectedPage === "tweetdisplay" ? selectedPageStyle : nonSelectedPageStyle, selectedPage === "tweetdisplay" ? selectedIconStyle : nonSelectedIconStyle]} />
                            <Typography sx ={[selectedPage === "tweetdisplay" ? selectedPageStyle : nonSelectedPageStyle, {marginLeft: '2px'}]} >
                                Tweets
                            </Typography>
                        </Stack>
                    </Link>
                    <Link ref={healthTabRef} href="/health" underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <Stack direction="row" display="flex" alignItems="center">
                            <MonitorHeart sx={[selectedPage === "health" ? selectedPageStyle : nonSelectedPageStyle, selectedPage === "health" ? selectedIconStyle : nonSelectedIconStyle]} />
                            <Typography sx ={[selectedPage === "health" ? selectedPageStyle : nonSelectedPageStyle, {marginLeft: '2px'}]} > 
                                Health
                            </Typography>
                        </Stack>
                    </Link>
                    { userObject.user.is_researcher ? 
                        <Link ref={researchTabRef} href="/research3" underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                            <Stack direction="row" display="flex" alignItems="center">
                                <Biotech sx={[selectedPage === "researcher" ? selectedPageStyle : nonSelectedPageStyle, selectedPage === "researcher" ? selectedIconStyle : nonSelectedIconStyle]} />
                                <Typography sx ={[selectedPage === "researcher" ? selectedPageStyle : nonSelectedPageStyle, {marginLeft: '2px'}]} > 
                                    Researcher
                                </Typography>
                            </Stack>
                        </Link> : null }
                    { userObject.user.is_researcher ? 
                        <Link ref={desktopTabRef} href="/researchcustom" underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                            <Stack direction="row" display="flex" alignItems="center">
                                <MdDashboard sx={[selectedPage === "desktop" ? selectedPageStyle : nonSelectedPageStyle, selectedPage === "desktop" ? selectedIconStyle : nonSelectedIconStyle]} />
                                <Typography sx ={[selectedPage === "desktop" ? selectedPageStyle : nonSelectedPageStyle, {marginLeft: '2px'}]} > 
                                    Dashboard
                                </Typography>
                            </Stack>
                        </Link> : null }                    
                    { userObject.user.is_vip ? 
                        <Link ref={vipTabRef} href="/vip" underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                            <Stack direction="row" display="flex" alignItems="center">
                                <RiVipCrownFill  size={selectedPage === "vip" ? 20 : 15} sx={[selectedPage === "vip" ? selectedPageStyle : nonSelectedPageStyle, { position: 'relative', marginRight: '2px', bottom: '5px'}]} />
                                <Typography sx ={[ selectedPage === "vip" ? selectedPageStyle : nonSelectedPageStyle, {marginLeft: '2px'}] } > 
                                    VIP
                                </Typography>
                            </Stack>
                        </Link> : null }
                    <Link ref={portfolioTabRef} href='/portfolio' underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <Stack direction="row" display="flex" alignItems="center">
                            <AccountBalanceIcon sx={[selectedPage === "pt" ? selectedPageStyle : nonSelectedPageStyle, selectedPage === "pt" ? selectedIconStyle : nonSelectedIconStyle]} />
                            <Typography sx ={[selectedPage === "pt" ? selectedPageStyle : nonSelectedPageStyle, {marginLeft: '2px'} ] } > 
                                Portfolio
                            </Typography>
                        </Stack>
                    </Link>
                    <Link ref={InfluencerTabRef} href='/influencer' underline="hover" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <Stack direction="row" display="flex" alignItems="center">
                            <MdPersonPinCircle size={selectedPage === "influencer" ? 30 : 20} sx={[selectedPage === "influencer" ? selectedPageStyle : nonSelectedPageStyle, { position: 'relative', marginRight: '2px', bottom: '5px'}]} />
                            <Typography sx ={[selectedPage === "influencer" ? selectedPageStyle : nonSelectedPageStyle, {marginLeft: '2px'} ] } > 
                                Influencer
                            </Typography>
                        </Stack>
                    </Link>                
                </Stack>
            </Box>

        )
    }
}

export default NavigationTop