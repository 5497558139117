import React, { useState } from 'react'
import { Grid, Box } from '@mui/material'


import HomeFeedTwitterPost from './HomeFeedTwitterPost'
import HomeFeedTabsStats from './HomeFeedTabStats'

const HomeFeedListDisplay = ( { homeFeedList } ) => {

    const [coinFilter,setCoinFilter] = useState('')
    const [hashFilter,setHashFilter] = useState('')
    const [mentionedFilter,setMentionedFilter] = useState('')
    const [followedFilter,setFollowedFilter] = useState('')
    const [tabValue,setTabValue] = useState('1')

    const filterByCoinInFeed = (coin) => {
        //console.log("top of filterByCoinInFeed")
        //console.log("coin is: " + coin)
        setHashFilter('')
        setMentionedFilter('')
        setFollowedFilter('')
        setCoinFilter(coin)
        setTabValue('2')
    }
  
    const filterByHashInFeed = ( hash ) => {
        //console.log("top of filterByHashInFeed")
        //console.log("hash is: " + hash)
        setMentionedFilter('')
        setFollowedFilter('')
        setCoinFilter('')
        setHashFilter(hash)
        setTabValue('2')
    }
  
    const filterByMentionInFeed = ( mentioned ) => {
        //console.log("top of filterByMentionInFeed")
        //console.log("mentioned is: " + mentioned)  
        setFollowedFilter('')
        setCoinFilter('')
        setHashFilter('')      
        setMentionedFilter(mentioned)  
        setTabValue('2')
    }
  
    const filterByFollowedInFeed = ( followed ) => {
        //console.log("top of filterByFollowedInFeed")
        //console.log("followed is: " + followed)  
        setCoinFilter('')
        setHashFilter('')      
        setMentionedFilter('')   
        setFollowedFilter(followed)      
        setTabValue('2')
    }
  
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      }    

    if (homeFeedList.length === 0) {
        return null
    } else {
        return (
            <>
            <Grid item xs={6}>
                <Box sx={{width: '100%',height: '980px', overflow: 'auto', scrollbarWidth: 'thin'}}>
                {homeFeedList.map( (listing, index) => 
                    <HomeFeedTwitterPost 
                        key={'homefeed'+index} 
                        twitterItem={listing}
                        filterByCoinInFeed={filterByCoinInFeed} 
                        filterByHashInFeed={filterByHashInFeed} 
                        filterByFollowedInFeed={filterByFollowedInFeed} 
                        filterByMentionInFeed={filterByMentionInFeed} 
                    />
                )}
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{width: '100%',height: '980px', overflow: 'auto', scrollbarWidth: 'thin'}}>
                    <HomeFeedTabsStats 
                        tabValue={tabValue}
                        handleTabChange={handleTabChange}
                        homeFeedListings={homeFeedList} 
                        filterByCoinInFeed={filterByCoinInFeed} 
                        filterByHashInFeed={filterByHashInFeed} 
                        filterByFollowedInFeed={filterByFollowedInFeed} 
                        filterByMentionInFeed={filterByMentionInFeed} 
                        coinFilter={coinFilter}
                        hashFilter={hashFilter}
                        mentionedFilter={mentionedFilter}
                        followedFilter={followedFilter}
                    />
                </Box>
            </Grid>
            </>
        )
    }
}

export default HomeFeedListDisplay