import React, { useState, useEffect } from 'react'
import { Grid, Typography, Divider } from '@mui/material'

import VIPTwitterPost from './VIPTwitterPost'
import VIPTelegramPost from './VIPTelegramPost'
import VIPDiscordPost from './VIPDiscordPost'
import VIPRedditPost from './VIPRedditPost'
import VIPResearchPost from './VIPResearchPost'

import { useTheme } from '@mui/material/styles'
import {FiExternalLink} from 'react-icons/fi'

const VIPTabsAtMentioned = ( { vipListings, atMentionedTabValue, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, showOfficialTweets }) => {

  //console.log("VIPTabsAtMentioned and atMentionedTabValue is:")
  //console.dir(atMentionedTabValue)
  
  const theme = useTheme()

  let searchAtMentioned = "@"+atMentionedTabValue
  if (atMentionedTabValue.includes('@'))
    searchAtMentioned = atMentionedTabValue
  else if (atMentionedTabValue.length === 0)
    searchAtMentioned = ''

  //console.log("searchAtMentioned is:")  
  //console.dir(searchAtMentioned)

  const [atMentionedListings,setAtMentionedListings] = useState([])

  useEffect(() => {
    if (searchAtMentioned.length > 0)
      setAtMentionedListings(vipListings.filter( listing => listing.text.includes(searchAtMentioned) ))
    else
      setAtMentionedListings([])
  }, [atMentionedTabValue])

  if (atMentionedListings.length > 0) {

    const linkToMentions = "https://twitter.com/search/?q=%28"+searchAtMentioned.replace("@", "")

    return (
      <Grid container>
        <Grid item xs={12}><Typography variant="h5" sx={{color: theme.palette.grey[700], margin: '10px'}}>@Mentions From Current VIP Results Page</Typography></Grid>
        <Grid item xs={12}>
          <Divider sx={{width:'100%'}} textAlign="left" >
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[800], paddingLeft: '20px', marginTop: '20px'}}>{atMentionedListings.length}</Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.grey[500], marginTop: '20px'}}> mentions In VIP results of </Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[800], marginTop: '20px'}}>{searchAtMentioned}</Typography>
            <a target="_blank" rel="noreferrer" style={{position: 'relative', top: '2px', left: '3px'}} href={linkToMentions}><FiExternalLink color={theme.palette.primary.main} /></a>
          </Divider>
        </Grid>
      {atMentionedListings.map( (listing, index) => {
          if (listing.hasOwnProperty('tweet_id')) return <VIPTwitterPost key={'vipmentioned'+index} listing={listing} setCoinInChart={setCoinInChart} showOfficialTweets={showOfficialTweets} changeAtFollowedTab={changeAtFollowedTab} changeHashTab={changeHashTab} changeAtMentionedTab={changeAtMentionedTab} changeCoinInTab={changeCoinInTab} changeDateInChart={changeDateInChart} />
          else if (listing.hasOwnProperty('telegram_group')) return <VIPTelegramPost key={'vipmentioned'+index} telegramItem={listing} />
          else if (listing.hasOwnProperty('discord_server')) return <VIPDiscordPost key={'vipmentioned'+index} discordItem={listing} />
          else if (listing.hasOwnProperty('subreddit')) return <VIPRedditPost key={'vipmentioned'+index} redditItem={listing} />
          else return <VIPResearchPost key={'vipmentioned'+index} researchItem={listing} /> //not sure if these are the only 5 types needed
      })}
      </Grid>
    )
  } else {
    if (searchAtMentioned.length > 0) {
      return (
        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>There are no VIP results for: {searchAtMentioned}</Typography>
      )
    } else {
      return (
        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>There are no @mentions to search for</Typography>
      )
    }
  }

}

export default VIPTabsAtMentioned