import React, { useState, useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { IconButton, Divider, Grid, Paper, Pagination,
    Popover, CircularProgress, Typography, useMediaQuery, FormControl, Stack, FormGroup, Button,
} from '@mui/material'
import { MdPlaylistAdd } from 'react-icons/md'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'
import fetchData from '../../utility/endpointFetch'

// project imports
import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'
import FavoriteChoicesPopoutItem from './FavoriteChoicesPopoutItem'
import FavoriteCategoryChoices from './FavoriteCategoryChoices'
import FavoriteSortChoices from './FavoriteSortChoices'
import FavoriteSearchChoices from './FavoriteSearchChoices'

// assets
import { IconSquareX } from '@tabler/icons';

// ==============================|| Favorite Choices Popover ||============================== //

const FavoriteChoicesPopover = ( { screenerCoinList, userObject, changeInMyFavoritedCoins, selectedScreener, changeScreener, sourceType='mycoins'} ) => {
  console.log("-----------------------------top of FavoriteChoicesPopover")
  console.log("screenerCoinList is:")
  console.dir(screenerCoinList)
  console.log("userObject is:")
  console.dir(userObject)
  console.log("selectedScreener is: " + selectedScreener)
  console.log("sourceType is: " + sourceType)
  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const [localIsLoading,setLocalIsLoading] = useState(true)
  const [localScreenerCoinsList,setLocalScreenerCoinsList] = useState(screenerCoinList)

  const [coinsList,setCoinsList] = useState([])
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const [currencyPage,setCurrencyPage] = useState(1)
  const [pageCount,setPageCount] = useState(1)
  const [sortByUrl,setSortByUrl] = useState('')
  const [filterByUrl,setFilterByUrl] = useState('')
  const [searchByUrl,setSearchByUrl] = useState('')
  const [sortingBy,setSortingBy] = useState('')
  const [categoryList,setCategoryList] = useState([])
  const [categoryChosen,setCategoryChosen] = useState('')
  const [responseCount,setResponseCount] = useState(0)
  const [oldFavoriteCoins,setOldFavoriteCoins] = useState(userObject.coin)

  const sortResults = (sortBy) => {
    //console.log("top of sortResults in FavoriteChoicesPopover: " + sortBy)
    setSortingBy(sortBy)
    setCurrencyPage(1)
    switch(sortBy) {
      case 'rank-highest':
        setSortByUrl("&ordering=market_cap_rank")
        break
      case 'rank-lowest':
        setSortByUrl("&ordering=-market_cap_rank")
        break
      case 'name-alphabetical':
        setSortByUrl("&ordering=name")
        break
      case 'name-alphabetical-reverse':
        setSortByUrl("&ordering=-name")
        break
      case 'symbol-alphabetical':
        setSortByUrl("&ordering=coin__symbol")
        break
      case 'symbol-alphabetical-reverse':
        setSortByUrl("&ordering=-coin__symbol")
        break
      case 'price-highest':
        setSortByUrl("&ordering=-current_price")
        break
      case 'price-lowest':
        setSortByUrl("&ordering=current_price")
        break
      case 'volume-lowest':
        setSortByUrl("&ordering=total_volume")
        break
      case 'volume-highest':
        setSortByUrl("&ordering=-total_volume")
        break
    }
    return
  }

  const clearSort = () => {
    setSortingBy('')
    setSortByUrl('')
  }

  const changePage = (event, page) => {
    //console.log("top of changePage")
    //console.dir(page)
    setLocalIsLoading(true)
    setCurrencyPage(page)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    //console.log("top of handleClose")
    //console.dir(localScreenerCoinsList)
    //console.log("selectedScreener is : " +  selectedScreener)
    setAnchorEl(null)
    changeInMyFavoritedCoins(localScreenerCoinsList,oldFavoriteCoins)
    changeScreener(selectedScreener)
    setOpen((prevOpen) => !prevOpen)
  };

  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    setLocalIsLoading(true)
    console.log("top of useEffect these change: currencyPage, filterByUrl, sortByUrl, searchByUrl")
    //console.log("currencyPage is: " + currencyPage)
    //console.log("sortByUrl is: " + sortByUrl)
    //console.log("filterByUrl is: " + filterByUrl)
    //console.log("searchByUrl is: " + searchByUrl)

    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        var offset= (currencyPage - 1) * 50 //limit per page
        const response = await fetchData(`api/v1/coin/coinmarket/?limit=50&offset=${offset}${sortByUrl}${filterByUrl}${searchByUrl}`, "could not receive all coins data", fetchParams).promise
        //console.log("response getting all coins")
        //console.dir(response)
        setCoinsList(response.results)

        setResponseCount(response.count)

        if (response.count > 100) {
          setPageCount(Math.ceil(response.count/100))
        } else {
          setPageCount(1)
        }

        if (sortByUrl === '') {
          //reorderlist by rank 1 first by default
          sortResults('rank-highest', response.results)
        }

        //extract all categories from currency list
        var category_list = []
        response.results.map( coin => {
          coin.categories.map( category => {
            if (!category_list.includes(category)) {
              category_list.push(category)
            }
          })
        })
        setCategoryList(category_list)

        setLocalIsLoading(false)
      } catch (error) {
        console.log("something is broke getting all coins in favorite choices popover: %s", error)
      }
    })()
  }, [currencyPage, filterByUrl, sortByUrl, searchByUrl])

  useEffect(() => {
    console.log("useEffect of FavoriteChoicesPopover and setLocalScreenerCoinsList is:")
    console.dir(screenerCoinList)
    setLocalScreenerCoinsList(screenerCoinList)
  }, [screenerCoinList])

  useEffect(() => {
    console.log("..................................................top of default useEffect for FavoriteChoicesPopover....selectedScreener is: " + selectedScreener)
    setLocalIsLoading(true)
    if (selectedScreener.length === 0) {
      //the selectedScreener is an empty object so there is no screener set
      console.log("There is no selectedScreener")
      setLocalIsLoading(false)
    } else {
      //get the coins from the currently selected list
      console.log("selectedScreener in default useEffect is: " + selectedScreener)
      const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
      }
      ;(async () => {
        try {
          var fetchScreenerCoinsURL = `api/v1/users/screener/?screener_name=${selectedScreener}`
          const screenerCoinsresponse = await fetchData(fetchScreenerCoinsURL, "could not receive screener coin data", fetchParams).promise
          console.log("+++++++++++++++++++++++++++++++++++screenerCoinsresponse getting screener coins data in favorite choices popover")
          console.log("fetchScreenerCoinsURL is: " + fetchScreenerCoinsURL)
          console.dir(screenerCoinsresponse)
          setLocalScreenerCoinsList(screenerCoinsresponse.results)
          if (screenerCoinsresponse.results.length > 0) {
            //get all coin id within this result into one array to add to coinmarket chart url selection

            let coinsInList = screenerCoinsresponse.results.map( (coinDetails) => { return coinDetails.coin_id } )
            console.log("coinsInList before duplicates removed is: ")
            console.dir(coinsInList)
            //coinsInList = [...new Set(coinsInList)]
            console.log("coinsInList after duplicates removed is: ")
            console.dir(coinsInList)
          }
          setLocalIsLoading(false)

        } catch (error) {
          console.log("something is broke getting screener coins in MyCurrencies: " + error )
        }
      })()
    }
  }, [])

  const removeCoinFromScreener = (coinToRemove, coin_id) => {
    console.log("top of removeCoinFromScreener and screener is: " + selectedScreener)
    console.log("coinToRemove is: " + coinToRemove)
    console.log("coin_id is: " + coin_id)
    console.log("localScreenerCoinsList is: ") 
    console.dir(localScreenerCoinsList)
    
    var oneToRemove = localScreenerCoinsList.filter( favorited => favorited.coinscreener.id === coinToRemove)
    //console.log("oneToRemove is:")
    ////console.dir(oneToRemove)
    setAnchorEl(null)

    const fetchParams = {
      method: 'DELETE',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'coin_id': coin_id
      })
    }
    //console.log("removeCoinFromScreener fetchParams is:")
    //console.dir(fetchParams)
    ;(async () => {
      try {
        let removeCoinFromScreenerURL = `api/v1/users/screenercoinupdate/${oneToRemove[0].id}/`
        const removeCoinFromScreenerResponse = await fetchData(removeCoinFromScreenerURL, "could not remove coin from screener", fetchParams).promise
        console.log("removeCoinFromScreenerResponse removing coin from screener")
        console.dir(removeCoinFromScreenerResponse)
        console.log(removeCoinFromScreenerURL)
        let newScreenerCoins = localScreenerCoinsList.filter( favorited => {
          console.log("GETTING NEW localScreenerCoinList favorited is:")
          console.dir(favorited)
          console.log("coinToRemove is:" + coinToRemove)
          return favorited.coinscreener.id !== coinToRemove
          }
        )
        setLocalScreenerCoinsList(newScreenerCoins)
        //setLocalScreenerCoinsList( previousCoins => [...previousCoins, coinToSave])
        //setScreenerCoinsList( previousFavorites => [...previousFavorites, coinToSave])

        if (selectedScreener === "Favorites") {
          //this coin needs to be added to the favorites field of the userdata so it will trigger alerts in vip
          let newFavorites = newScreenerCoins.map(coin => { return coin.coin_id})
          let saveFavoritesParams = {
            method: 'PUT',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'user': userObject.user,
              'favorites': newFavorites
            })
          }
          ;(async () => {
            const saveFavoritesResponse = await fetchData(`api/v1/users/userdata/`, "could not save favorites to user data", saveFavoritesParams).promise
            //console.log("saveFavoritesResponse saving favorites to user data")
            //console.dir(saveFavoritesResponse) 
          })()                      
        }

      } catch (error) {
        console.log("something is broke saving coin to screener: %s", error)
      }
    })()
  }

  const saveCoinToScreener = (coinToSave, event) => {
    console.log("top of saveCoinToScreener")
    console.log("event is: ")
    console.dir(event)
    console.log("coinToSave is: ")
    console.dir(coinToSave)
    setAnchorEl(event.currentTarget)

    const fetchParams = {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'screener_name': selectedScreener,
        'coin_id': coinToSave,
        'user': userObject.user,
        
      })
    }
    console.log("fetchParams is:")
    console.dir(fetchParams)
    ;(async () => {
      try {
        var saveCoinToScreenerURL = `api/v1/users/screenercoincreate/`
        const saveCoinToScreenerResponse = await fetchData(saveCoinToScreenerURL, "could not save coin to screener", fetchParams).promise
        console.log("saveCoinToScreenerResponse saving coin to screener")
        console.dir(saveCoinToScreenerResponse)
        console.log("saveCoinToScreenerURL is:" + saveCoinToScreenerURL)

        const fetchScreenerParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            var fetchScreenerCoinsURL = `api/v1/users/screener/?screener_name=${selectedScreener}`
            const fetchScreenerCoinsresponse = await fetchData(fetchScreenerCoinsURL, "could not receive new contents of coin screener", fetchScreenerParams).promise
            console.log("fetchScreenerCoinsresponse getting new coin screener contents")
            console.log("fetchScreenerCoinsresponse is: ")
            console.dir(fetchScreenerCoinsresponse)
            let coinsInList = fetchScreenerCoinsresponse.results.map( (coinDetails) => { return coinDetails.coin_id } )
            console.dir(coinsInList)
            setLocalScreenerCoinsList(fetchScreenerCoinsresponse.results)
  
            /*;(async () => {
              try {
                let myCoinsScreenerResponseURL = `api/v1/coin/ecoinscreener/?coins=${coinsInList.join(",")}`
                const myCoinsScreenerResponse = await fetchData(myCoinsScreenerResponseURL, "could not receive coin screener data in my currencies", fetchScreenerParams).promise
                console.log("myCoinsScreenerResponse getting coinscreener data in my coins")
                console.dir(myCoinsScreenerResponse)
                console.log(myCoinsScreenerResponseURL)
    
                
        
              } catch (error) {
                console.log("could not receive coin/ecoinscreener data in MyCurrencies: " + error)
              }   
            })()   
            */        

            if (selectedScreener === "Favorites") {
              //this coin needs to be added to the favorites field of the userdata so it will trigger alerts in vip
              //save the saveScreenerCoinsresponse.results into userdata->favorites also
              console.log("new favorites will be coinsInList is: ")
              console.dir(coinsInList)
              let saveFavoritesParams = {
                method: 'PUT',
                dataType: 'json',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  'user': userObject.user,
                  'favorites': coinsInList
                })
              }
              ;(async () => {
                const saveFavoritesResponse = await fetchData(`api/v1/users/userdata/`, "could not save favorites to user data", saveFavoritesParams).promise
                console.log("saveFavoritesResponse saving favorites to user data")
                console.dir(saveFavoritesResponse) 
              })()                      
            }


          } catch (error) {
            console.log("something is broke getting new coin screener contents: %s", error)
          }
        })()
      } catch (error) {
        console.log("something is broke saving coin to screener: %s", error)
      }
    })()
  }



  const filterByCategory = (category) => {
    //console.log("top of filterCurrencyList and category is: " + category)
    if (category !== '') {
      setFilterByUrl("&category=" + category)
      setCurrencyPage(1)
      setCategoryChosen(category)
    } else {
      setCurrencyPage(1)
      setCategoryChosen('')
      setFilterByUrl('')
    }
  }

  const clearCategoryFilter = () => {
    //remove a current category filter if there is one currently
    //console.log("top of clearCategoryFilter")
    setCurrencyPage(1)
    setFilterByUrl('')
    setCategoryChosen('')
  }

  const clearFavoriteCoins = () => {
    setLocalScreenerCoinsList([])
    const fetchParams = {
      method: 'PUT',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'coin': [],
        'user': userObject.user,
        'twitter_friend_list': userObject.twitter_friend_list
      })
    }
    ;(async () => {
      try {
        const response = await fetchData(`api/v1/users/userdata/`, "could not clear user coin favorites data", fetchParams).promise
        //console.log("response clearing user coin favorites")
        //console.dir(response)

      } catch (error) {
        console.log("error","something is broke saving  user coin favorites")
      }
    })()
  }

  const searchFavorites = (searchTerm) => {
    //console.log("top or searchFavorites...searchTerm: " + searchTerm)
    if (searchTerm.length > 2) {
      //console.log("searchTerm greater than 2")
      if (searchTerm.indexOf(' ') >= 0) {
        //console.log("searchTerm contains spaces")
        //contains spaces...no search
        setSearchByUrl('')
        return null
      } else {
        //console.log("about to search")
        var searchString = "&search=" + encodeURIComponent(searchTerm)
        setCurrencyPage(1)
        setSearchByUrl(searchString)
      }
    } else {
      //search term too short...wait till at least 3 characters
      //console.log("searchTerm 2 or less letters")
      setSearchByUrl('')
      return null
    }
  }

  const clearSearchFavorites = () => {
    setSearchByUrl('')
    setCurrencyPage(1)
  }

  let displayOpenButton = {}

  if (sourceType === 'mycoins') {
    //component used from mycoins 
    displayOpenButton = (
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{borderRadius: '12px', background: theme.palette.primary.dark, '&:hover': {
          background: theme.palette.primary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
          color: theme.palette.primary.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
        }}}
        size="small"
        disabled={selectedScreener === ''? true : false}
      >
        Add Coin To {selectedScreener} Screener
      </Button>      
    )
  } else {
    //component used from within dashboard
    displayOpenButton = (
      <MdPlaylistAdd 
        color={theme.palette.primary.dark}
        size={22}
        style={{cursor: 'pointer'}}
        onClick={handleClick}
      />   
    )   
  } 
  

  //if (localIsLoading) return <CircularProgress />
  //if (selectedScreener === 'All Coins' || userObject.coin.length === 0 ) {
  console.log("just before return in FavoriteChoicesPopover and coinsList is:")
  console.dir(coinsList)
  console.log("and localScreenerCoinsList is:")
  console.dir(localScreenerCoinsList)
  if (selectedScreener === 'All Coins' ) {
    // the all coins screener already has all coins within it and you will not be able to remove from it
    return (
      <Button
          aria-describedby={id}
          variant="contained"
          sx={{borderRadius: '12px', background: theme.palette.primary.dark, '&:hover': {
            background: theme.palette.primary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
            color: theme.palette.primary.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
          }}}
          size="small"
        >
          All Coins Screener Is Not Editable
      </Button>      
    )
  } else {
    return (
      <div>
        {displayOpenButton}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 400, left: 900 }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        >

          <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} >
            <Paper >
              <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '600px', minHeight: '700px'}}>
                <Grid container spacing={2} sx={{marginTop: '15px'}}>
                  <Grid item xs={10} sx={{bgcolor: theme.palette.dark[800]}}>
                    <Typography display="inline" variant="h3" sx={{marginTop: '15px', marginLeft: '30px'}}>Choose Favorites for </Typography>
                    <Typography display="inline" variant="h3" color={theme.palette.primary.main} sx={{fontWeight: 700, marginTop: '15px'}}>{selectedScreener}</Typography>
                  </Grid>
                  <Grid item xs={2} sx={{bgcolor: theme.palette.dark[800]}}>
                    <IconButton
                      aria-label="close"
                      sx={{color: theme.palette.error.main}}
                      onClick={handleClose}>
                      <IconSquareX sx={{ marginTop: '15px'}}/>
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sx={{bgcolor: theme.palette.dark[800]}}><Divider /></Grid>
                  <Grid item xs={7} sx={{bgcolor: theme.palette.dark[800]}}>
                    <FavoriteCategoryChoices categoryList={categoryList} categorySelected={categoryChosen} filterByCategory={filterByCategory} clearCategoryFilter={clearCategoryFilter} />
                    <FavoriteSortChoices clearSort={clearSort} sortResults={sortResults} selectedSort={sortingBy} />
                    <FavoriteSearchChoices searchFavorites={searchFavorites} clearSearchFavorites={clearSearchFavorites} />
                  </Grid>
                  <Grid item xs={5} sx={{bgcolor: theme.palette.dark[800]}}>
                    <Stack direction="column" sx={{minHeight: '650px'}}>
                      <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
                      {localIsLoading ? <CircularProgress /> :
                        <PerfectScrollbar
                          style={{ maxHeight: 'calc(100vh - 300px)', overflowX: 'hidden' }}
                        >
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            {/*<FormLabel component="legend">Pick Favorites</FormLabel>*/}
                            <FormGroup>
                              {coinsList.map( (coin, index ) => <FavoriteChoicesPopoutItem key={"favoritechoices"+index} selectedCoin={localScreenerCoinsList.filter( listItem => listItem.coin_id === coin.coin.id)} coin={coin} saveCoinToScreener={saveCoinToScreener} removeCoinFromScreener={removeCoinFromScreener} />  )}
                            </FormGroup>
                          </FormControl>
                        </PerfectScrollbar>
                      }
                      <Pagination sx={{marginTop: '10px'}} shape="rounded" count={pageCount} page={currencyPage} onChange={changePage}/>
                    </Stack>
                  </Grid>
                </Grid>
              </MainCard>
            </Paper>
          </Transitions>
        </Popover>
      </div>
    );
  }
};

export default FavoriteChoicesPopover