import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconCircle } from '@tabler/icons';

const OpenInterest = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About Open Interest</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - MVRV Ratio">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
              Open interest is defined as the number of open positions (including both long and short positions) currently on a derivative exchange's BTC/USD trading pairs.
              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                A large value of open interest can indicate increased volatility in the price of BTC. For this reason, an open interest chart could also be useful for determining the market tops and bottoms with additional indicators.
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                  <ListItemIcon>
                      <IconCircle />
                    </ListItemIcon>
                    <ListItemText
                      primary="Increasing :  Adding liquidity, volatility, and attention + supporting ongoing price trend"
                      secondary="As positions and contracts are continuously being opened, it indicates more liquidity, volatility, and attention are coming into the derivative market. Increasing trend in OI could support the current ongoing price trend."
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconCircle />
                    </ListItemIcon>
                    <ListItemText
                      primary="Decreasing: Exiting liquidity, volatility, and attention "
                      secondary="As Open Interest (OI) decrease, it indicates futures contract/ positions are being closed. In turn, this might trigger the possibility of long/short squeeze caused by sudden price movement or vice versa."
                    />
                  </ListItem>
                </List>
              </Typography>
            </TabPanel>

          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default OpenInterest