import React from 'react'
import { useTheme } from '@mui/material/styles'
import { withStyles } from '@mui/styles'
import MaterialChip from '@mui/material/Chip';

import { IconBrandFacebook, IconBrandTwitter, IconFile, IconBrandTelegram, IconLink, IconBrandReddit, IconBrandGithub, IconCoin, IconBrandDiscord } from '@tabler/icons';


const CoinDetailsLinksChip = ( { link, currencyDetails }) => {

  //console.log("top of CoinDetailsLinksChip")
  //console.dir(link)

  //console.log( "currencyDetails[link.link_property] is " +  currencyDetails[link.link_property])
  const theme = useTheme();

  const Icon = ( ) => {

    //console.log("type in Icon is: " + link.link_icon)
    //console.dir(link.link_icon)

    var icon = IconFile
    switch ( link.link_icon ) {
      case "IconBrandTwitter":
          icon = IconBrandTwitter
        break;
      case "IconBrandFacebook":
          icon = IconBrandFacebook
        break;
      case "IconFile":
          icon = IconFile
        break;
      case "IconBrandTelegram":
          icon = IconBrandTelegram
        break;
      case "IconLink":
          icon = IconLink
        break;
      case "IconBrandReddit":
          icon = IconBrandReddit
        break;
      case "IconBrandGithub":
          icon = IconBrandGithub
        break;
      case "IconCoin":
          icon = IconCoin
        break;
      case "IconBrandDiscord":
          icon = IconBrandDiscord
        break;
      default:
          icon = IconLink
    }
    return React.createElement(icon)
  }

  if ((currencyDetails[link.link_property] !== null) && (currencyDetails[link.link_property] !== "")) {

    let linkURL = link.link.replace("XXX", currencyDetails[link.link_property] )

    //const styles = {
    //  margin: '10px',
    //  fontSize: "1em",
    //  paddingLeft: "10px",
    //  color: theme.palette.warning.main
    //}

    const Chip = (props) => {
      const StyledChip = withStyles({
        '&&&': {
            'color': 'white',
            'backgroundColor': `${theme.palette.primary.dark} !important`,
            '&:hover': {
                backgroundColor: theme.palette.background.paper,
                filter: 'brightness(120%)',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.dark,
            },
        },
        outlined: {
            paddingLeft: '10px',
            color: props.color,
            borderRadius: '12px',
            marginRight: '5px',
            marginBottom: '3px',
            border: `1px solid ${theme.palette.background.default}`,
            'backgroundColor': `${theme.palette.primary.dark} !important`,
            '&:hover': {
                backgroundColor: theme.palette.background.paper,
                filter: 'brightness(120%)',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.dark,
            },            
            //backgroundColor: `transparent !important`,
        },
        icon: {
            color: props.variant === 'outlined' ? theme.palette.grey[400] : 'white',
        },

      })(MaterialChip)    
      return <StyledChip {...props} />
    }

    return (
      <Chip
        label={link.link_text}
        component="a"
        target="_blank"
        rel="noreferrer" 
        href={linkURL}
        //sx={styles}
        //color="primary"
        icon={<Icon name={link.link_icon} />}
        variant="outlined"
        clickable
      />
    );
  } else {
    return null
  }

}

export default CoinDetailsLinksChip