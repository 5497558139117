import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, Stack, Box, Avatar, Button  } from '@mui/material';
import { AddBox, Exposure, IndeterminateCheckBox, Twitter, Star, Person, FavoriteBorder, Repeat } from '@mui/icons-material'
import { IconCoin } from '@tabler/icons'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid';
import { FiExternalLink } from 'react-icons/fi'
import reactStringReplace from 'react-string-replace'
import { communitySize } from "../../utility/objHelper"
import ResearchTwitterResultItemImages from './ResearchTwitterResultItemImages'

const ResearchTwitterResultItem = ( { tweet, search = '', divHeight=0, boxWidth=0 } ) => {

  //console.log("top of ResearchTwitterResultItem and tweet is: -------------------------------------")
  //console.dir(tweet)

  const theme = useTheme();

  //console.log("top of ResearchTwitterResultItem")
  //console.dir(tweet)

  if (tweet === null) {
    return null
  } else {

    //format time
    var trimmedTime = tweet.created.split("+")[0]
    var splitTime = trimmedTime.split("T")

    var quoteTrimmedTime = null
    var quoteSplitTime = null
    if (tweet.quote_created !== null) {
        quoteTrimmedTime = tweet.quote_created.split("+")[0]
        quoteSplitTime = quoteTrimmedTime.split("T")
    }        

    var retweetPercentage = ((tweet.retweets / tweet.favorites) * 100).toFixed(2)

    //display polarity (positive/negative)
    const polarityDisplay = (passedDirection, passedCount) => {
      if (passedCount === 0) return null
      if (passedDirection === 1) {
        return [ <AddBox key={Math.random().toString(36).substring(2,7)} sx={{color: theme.palette.primary.main, fontSize: "0.8rem"}}/>,  polarityDisplay(passedDirection, (passedCount-1) ) ]
      } else {
        return [ <IndeterminateCheckBox key={Math.random().toString(36).substring(2,7)} sx={{color: theme.palette.secondary.main, fontSize: "0.8rem"}}/>,  polarityDisplay(passedDirection, (passedCount-1) ) ]
      }
    }
    var direction = 0
    var count = 0
    switch(true) {
      case parseFloat(tweet.polarity) >= .7:
          direction = 1
          count = 3
        break
      case parseFloat(tweet.polarity) >= .4:
        direction = 1
        count = 2
        break
      case parseFloat(tweet.polarity) >= .1:
        direction = 1
        count = 1
        break
      case parseFloat(tweet.polarity) <= -.1:
        direction = -1
        count = 1
        break
      case parseFloat(tweet.polarity) <= -.4:
        direction = -1
        count = 2
        break
      case parseFloat(tweet.polarity) <= -.7:
        direction = -1
        count = 3
        break
      default:
        //leave default values
      break
    }

    //display efficacy (how strong match to purpose)
    const efficacyDisplay = (passedCount) => {
      if (passedCount === 0) return null
      return [ <Star key={uuidv4()} sx={{color: theme.palette.primary.main, fontSize: "1.0rem"}}/>,  efficacyDisplay((passedCount-1)) ]

    }
    var efficacyCount = 0
    switch(true) {
      case parseFloat(tweet.subjectivity) >= .7:
          efficacyCount = 3
        break
      case parseFloat(tweet.subjectivity) >= .4:
        efficacyCount = 2
        break
      case parseFloat(tweet.subjectivity) >= .1:
        efficacyCount = 1
        break
      default:
        //leave default values
      break
    }

    var tweetText = tweet.text
    //replace link with preview
    //replace @

    tweetText = reactStringReplace(tweetText, /@(\w+)/g, (match, i) => (
      <Button size="small" key={uuidv4()} style={{cursor: 'pointer', color: theme.palette.warning.dark}}>@{match}</Button>
    ))
  
    //replace #
    tweetText = reactStringReplace(tweetText, /#(\w+)/g, (match, i) => (
      <Button size="small" key={uuidv4()} style={{cursor: 'pointer', color: theme.palette.success.dark}}>#{match}</Button>
    ))

    tweetText = reactStringReplace(tweetText, /(https:\/\/\S+)/g, (match, i) => (
      <a key={"viptwitterpostlink"+uuidv4()} style={{color: theme.palette.primary.dark}} href={match} target="_blank" rel="noreferrer" >{match}</a>
    ))

    // replace keywords (one call for upper case, one call for lower case
    if(search.length){
      let rx = /search=(.*)/g;
      let arr = rx.exec(search);
      if (arr !== null) {
        const searchText = arr[1]
        const words = searchText.split(' ');
        for(let word of words){
          if(word.length){
            // REPLACE DATE with this/next month
            if(word.trim() === ('DATE')){
              const now = new Date(Date.now())
              const month = now.toLocaleString('default', { month: 'long' });
              const shortMonth = month.slice(0,3)
              const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1).toLocaleString('default', { month: 'long' });
              const shortNextMonth = nextMonth.slice(0,3);
              const regexDate = new RegExp(`(`+month+`)+`, 'g')
              // this month - long string
              tweetText = reactStringReplace(tweetText, regexDate, (match, i) => {
                return (
                    <a href={match} key={match + uuidv4()} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match}</a>
                )
              })
              // this month - short string
              const regexShortDate = new RegExp(`( `+shortMonth+` )`, 'g')
              tweetText = reactStringReplace(tweetText, regexShortDate, (match, i) => {
                return (
                    <a href={match} key={match + uuidv4()} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match}</a>
                )
              })
              // next month - short string
              const regexNextMonthDate = new RegExp(`( `+nextMonth+` )`, 'g')
              tweetText = reactStringReplace(tweetText, regexNextMonthDate, (match, i) => {
                return (
                    <a href={match} key={match + uuidv4()} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match}</a>
                )
              })
              const regexShortNextMonthDate = new RegExp(`( `+shortNextMonth+` )`, 'g')
              tweetText = reactStringReplace(tweetText, regexShortNextMonthDate, (match, i) => {
                return (
                    <a href={match} key={match + Math.random().toString(36).substring(2,7)} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match}</a>
                )
              })
            }else{
              const regexUpper = new RegExp(`( `+word[0].toUpperCase() + word.slice(1, word.length)+` )+`, 'g')
              tweetText = reactStringReplace(tweetText, regexUpper, (match, i) => {
                return (
                    <a href={match} key={match + Math.random().toString(36).substring(2,7)} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{word[0].toUpperCase() + word.slice(1, searchText.length)}</a>
                )
              })

              const regexLower = new RegExp(`( `+word[0].toLowerCase() + word.slice(1, word.length)+` )+`, 'g')
              tweetText = reactStringReplace(tweetText, regexLower, (match, i) => {
                return (
                    <a href={match} key={match + uuidv4()} style={{cursor: 'pointer', color: theme.palette.primary.dark}}>{match}</a>
                )
              })
            }
          }
        }
      } else {
        //no searchterm entered into search
        //if no "search=" is found arr is null
      }
    }

    const units = [
      'year',
      'month',
      'week',
      'day',
      'hour',
      'minute',
      'second',
    ];

    const timeAgo = () => {
      let dateTime = DateTime.fromISO(tweet.created)
      const diff = dateTime.diffNow().shiftTo(...units);
      const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

      const relativeFormatter = new Intl.RelativeTimeFormat('en', {
        numeric: 'auto',
      });
      return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
    };

    const linkToTweet = `https://twitter.com/${tweet.twitter_display_name}/status/${tweet.tweet_id}`
    const displayAvatar = () => {
      if (tweet.twitter_user_avatar_url === '') {
        return (
          <a target="_blank" href={linkToTweet} rel="noreferrer" >
            <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
          </a>
        )
      } else {
        return (
          <a target="_blank" href={linkToTweet} rel="noreferrer" >
            <Avatar alt={tweet.twitter_display_name} src={tweet.twitter_user_avatar_url} sx={{ width: 42, height: 42, marginRight: '3px' }}/>
          </a>
        )
      }
    }

    return (
      <Box sx={{
        borderRadius: '5px',  
        padding: "10px", 
        marginTop: '5px',  
        backgroundColor: theme.palette.dark[900], 
        '&:hover': {backgroundColor: theme.palette.dark.dark}
      }}>
        <Stack direction="column" display="flex" alignContent='left'>
            <Stack direction="row" sx={{display: "flex", alignItems: "center", justifyContent: 'space-between',cursor: 'pointer'}}>
              <Stack direction="row" display="flex" alignItems="center">
                {displayAvatar()}
                <Typography variant="subtitle1" sx={{fontWeight: 600, color: theme.palette.grey[500]}}>{tweet.display_name}</Typography>
                <Typography variant="subtitle1" sx={{fontWeight: 400, color: theme.palette.grey[300], marginLeft: '10px'}}>@{tweet.twitter_display_name}</Typography>
                <Typography variant="subtitle1" sx={{fontWeight: 400, color: theme.palette.grey[300], marginLeft: '10px'}}>{timeAgo()}</Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Person sx={{fontSize: '1rem', color: theme.palette.grey[300]}} />
                <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '3px', marginTop: '3px'}}>{communitySize(tweet)}</Typography>
                <a target="_blank" rel="noreferrer" style={{position: 'relative', top: '2px', left: '3px'}} href={linkToTweet}><FiExternalLink color={theme.palette.primary.main} /></a>
              </Stack>
            </Stack>

            <Typography variant="subtitle1" sx={{color: theme.palette.grey[500], fontSize: '.9rem'}}>{tweetText}</Typography>
                        

            <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{marginTop: '8px', cursor: 'pointer'}}>
              <Stack direction="row" alignItems="center">
                <Repeat sx={{fontWeight: 400, color: theme.palette.grey[300], fontSize: "1.2rem", transform: "rotate(90deg)"}} />
                <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '5px', marginTop: '3px'}}>{tweet.retweets}</Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <FavoriteBorder sx={{fontWeight: 400, color: theme.palette.grey[400], fontSize: "1.2rem"}} />
                <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '5px', marginTop: '2px'}}>{tweet.favorites}</Typography>
              </Stack>
              <Stack direction="row" sx={{marginTop: "0px"}} alignItems="center">
                <Repeat sx={{fontWeight: 400, color: theme.palette.grey[300], fontSize: "1.2rem", transform: "rotate(90deg)"}} />
                <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400], marginTop: '2px'}}>%</Typography>
                <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], marginLeft: '5px', marginTop: '2px'}}>{retweetPercentage}</Typography>
              </Stack>
              <Stack direction="row" sx={{marginTop: "0px"}} alignItems="center">
                <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400], marginRight: '3px', marginTop: '2px'}}>social engagement</Typography>
                <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], marginLeft: '5px', marginTop: '2px'}}>{tweet.social_engagement ? tweet.social_engagement : 0}</Typography>
              </Stack>
              <Stack direction="row" sx={{marginTop: "0px"}} alignItems="center">
                <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[300], marginRight: '3px', marginTop: '2px'}}>polarity</Typography>
                {direction === 0 ? <Exposure sx={{fontWeight: 600, color: theme.palette.grey[500], fontSize: "0.9rem"}}/> : polarityDisplay(direction, count)}
              </Stack>
              <Stack direction="row" sx={{marginTop: "0px"}} alignItems="center">
                <Typography variant="caption" sx={{color: theme.palette.grey[300], marginRight: '5px'}}>relevance</Typography>
                {direction === 0 ? <Typography variant="caption" sx={{color: theme.palette.grey[500],}}>none</Typography>  : efficacyDisplay(efficacyCount)}
              </Stack>
            </Stack>
            <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{marginTop: '5px'}}>
              <Stack direction="row" display="flex" justifyContent="space-around" alignItems="center" spacing={2} sx={{maxWidth: '80%'}}>
                <IconCoin strokeWidth={2} size={18} color={theme.palette.grey[400]} sx={{width: '5%'}}  />
                <Box sx={{width: '95%'}}>
                  <Typography variant="subtitle1" display="inline" sx={{fontWeight: 600, cursor: 'pointer', color: theme.palette.primary.main, marginLeft: '5px', marginRight: '5px'}}  >
                  {tweet.coins !== null && tweet.coins.join(', ')}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" sx={{cursor: 'pointer'}}>
                <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[300]}}>created:</Typography>
                <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '5px'}}>{splitTime[0]} {splitTime[1].slice(0,-1)} UTC</Typography>
              </Stack>
            </Stack>
            { tweet.attachments !== null && tweet.attachments.length > 0 ?
              <Box id={"base_images"+tweet.tweet_id} sx={{width: '100%', paddingLeft: '50px', paddingRight: '50px', align: 'center'}}>
                <ResearchTwitterResultItemImages key={tweet.tweet_id + String(Math.random() * 100)} images={tweet.attachments} tweetID={tweet.tweet_id} inDashboardHeight={divHeight} inDashboardWidth={boxWidth} />
              </Box> : null 
            }
            {
              tweet.quote_tweet_id && (
                <Grid item xs={12}>
                  <Box sx={{marginLeft: 5, marginTop: 1, border: '1px solid #2b2b2b', borderRadius: 1, p: 1}}>
                    <Stack direction='row' display='flex' alignItems='center' justifyContent="space-between">
                      <Stack direction={'row'} display="flex" alignItems='center' justifyContent="flex-start" >
                        {
                          tweet.quote_avatar_url ?
                            (
                              <a target="_blank" rel="noreferrer" href={`https://twitter.com/${tweet.quote_display_name}/status/${tweet.quote_tweet_id}`}>
                                  <Avatar alt={tweet.quote_avatar_url} src={tweet.quote_avatar_url} sx={{ width: 42, height: 42, marginRight: '3px' }}/>
                              </a>
                            )
                            :
                            (
                              <a target="_blank" rel="noreferrer" href={`https://twitter.com/${tweet.quote_display_name}/status/${tweet.quote_tweet_id}`}>
                                  <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
                              </a>
                            )
                        }
                        <Typography variant="subtitle1" sx={{fontWeight: 600, color: theme.palette.grey[500]}}>{tweet.quote_display_name}</Typography>
                      </Stack>
                      {quoteSplitTime !== null ? 
                          <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-end" sx={{cursor: 'pointer'}}>
                              <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>created:</Typography>
                              <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[800], paddingLeft: '5px'}}>{quoteSplitTime[0]} {quoteSplitTime[1].slice(0,-1)} UTC</Typography>
                          </Stack>   
                      : null }                             
                    </Stack>
                    <Stack direction={'row'}>
                        <Typography>{tweet.quote_text}</Typography>
                    </Stack>
                  
                  { tweet.quote_attachments !== null && tweet.quote_attachments.length > 0 ?
                    <Box id={"quoted_images"+tweet.tweet_id} sx={{width: '100%', paddingLeft: '50px', paddingRight: '50px', align: 'center'}}>
                      <ResearchTwitterResultItemImages images={tweet.quote_attachments} tweetID={tweet.tweet_id} inDashboardHeight={divHeight} inDashboardWidth={boxWidth} />
                    </Box> : null 
                  }        
                  </Box>          
                </Grid>
              )
            }               
        </Stack>
      </Box>

    );
  }
}

export default ResearchTwitterResultItem