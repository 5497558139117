import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon, TableHead, TableCell  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { styled, useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconStethoscope, IconRuler2, IconArrowUpCircle, IconArrowDownCircle, IconCircle } from '@tabler/icons';

const FundingRates = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About Funding Rates</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - MVRV Ratio">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
              Funding rates make the perpetual futures contract price close to the index price. It is made be closer to the spot prices and cover some of the gap generated by the perpetual period of time.
              </Typography>
              <Typography variant="body2">
              All cryptocurrency derivatives exchanges use funding rates for perpetual contracts and the standard unit is a percentage.
              </Typography>
              <Typography variant="body2">
              Funding Rate is a result of market behaviors and could be used to maker some interpretation in the derivative market which also is a dominant price maker in the market. However, correlating high funding rates with inevitable price drop could be a wrong interpretation. In the bull market, it have a tendency to naturally bring high funding rates with price rise.
              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="body2">
                Funding rates represent traders' sentiments in the perpetual swaps market and the amount is proportional to the number of contracts.
              </Typography>
              <Typography variant="h4">By Value Itself</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowUpCircle color='#00b341' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Rates over  ‘0’ (Positive rates): Dominant Long Sentiment"
                      secondary="Positive funding rates indicate that long position traders are dominant and are willing to pay funding to short traders. Positive funding rates imply that many traders are bullish."
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Rates under ‘0’ (Negative rates): Dominant Short Sentiment"
                      secondary="Negative funding rates indicate that short position traders are dominant and are willing to pay long traders. Negative funding rates imply that many traders are bearish."
                    />
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="h4">By examining trend</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconArrowUpCircle color='#00b341'/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Increasing trend: Longs are gaining dominance"
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Decreasing trend: Shorts are gaining dominance"
                    />
                  </ListItem>
                </List>
              </Typography>
            </TabPanel>

          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default FundingRates