import React from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import GoogleIcon from '@mui/icons-material/Google'
import auth from '../Auth/auth'
import fetchData from '../../utility/endpointFetch'

const LoginGoogle = ( { googleSetLoginErrorAndMessage } ) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const successfulLogin = (tokenResponse) => {
        //console.log("tokenResponse is: ")
        //console.dir(tokenResponse)
        const fetchParams = {
            method: 'POST',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'token': tokenResponse.access_token,
              'source': 1
            })
          }
        const errorMessage = 'Error creating your account through google login.  Please contact support to do so.'
        ;(async () => {
            const googleResponse = await fetchData('api/v1/google_auth/', errorMessage, fetchParams).promise
            //console.log("googleResponse from api/v1/google_auth/ is")
            //console.dir(googleResponse)

            if (googleResponse.token) {
                if (auth.login(googleResponse.token, googleResponse.expiry)) {
                  //token/expiry set in local storage
                  navigate('/home')
                } else {
                  //could not save token/expiry
                  googleSetLoginErrorAndMessage(googleResponse.error)
                }
              } else if (googleResponse.error) {
                googleSetLoginErrorAndMessage(googleResponse.error)
      
              } else {
                //got here because of issues
                googleSetLoginErrorAndMessage(googleResponse.error)
              }
        })()
    }

    const login = useGoogleLogin({
        onSuccess: tokenResponse => successfulLogin(tokenResponse),
      })

    return (
        <div id="signInButton">

            <Button
                fullWidth
                variant='contained'
                color='primary'
                startIcon={<GoogleIcon />}
                sx={{ margin: theme.spacing(3, 0, 2) }}
                onClick={() => login()}
            >
                Google Sign In
            </Button>
        </div>
    )
} 
export default LoginGoogle