import React from 'react'

import { useTheme } from '@mui/material/styles';
import { Chip } from '@mui/material';

import { IconBrandFacebook, IconBrandTwitter, IconFile, IconBrandTelegram, IconLink, IconBrandReddit, IconBrandGithub, IconCoin, IconBrandDiscord } from '@tabler/icons';
import { SiWprocket } from 'react-icons/si';


const ResearchBodyCoinItemRightLinks = ( { link, currencyDetails }) => {

  //console.log("top of ResearchBodyCoinItemRightLinks")
  //console.dir(link)

  //console.log( "currencyDetails[link.link_property] is " +  currencyDetails[link.link_property])
  const theme = useTheme();

  const Icon = ( ) => {

    //console.log("type in Icon is: " + link.link_icon)
    //console.dir(link.link_icon)

    var icon = IconFile
    switch ( link.link_icon ) {
      case "IconBrandTwitter":
          icon = IconBrandTwitter
        break;
      case "IconBrandFacebook":
          icon = IconBrandFacebook
        break;
      case "IconFile":
          icon = IconFile
        break;
      case "IconBrandTelegram":
          icon = IconBrandTelegram
        break;
      case "IconLink":
          icon = IconLink
        break;
      case "IconBrandReddit":
          icon = IconBrandReddit
        break;
      case "IconBrandGithub":
          icon = IconBrandGithub
        break;
      case "IconCoin":
          icon = IconCoin
        break;
      case "IconBrandDiscord":
          icon = IconBrandDiscord
        break;
      case "BoxFill":
        icon = SiWprocket
      break;        
      default:
          icon = IconLink
    }
    return React.createElement(icon)
  }

  //blockchain_explorer
  //discord_url
  //github_url
  //twitter_url
  //website

  if ((currencyDetails[link.link_property] != null) && (currencyDetails[link.link_property] != "")) {

    var linkURL = link.link.replace("XXX", currencyDetails[link.link_property] )

    const styles = {
      fontSize: ".8em",
      paddingLeft: "5px",
      borderRadius: '12px', 
      mr: '5px', 
      mb: '3px'
    }


    return (
      <Chip
        label={link.link_text}
        variant="outlined"
        component="a"
        target="_blank"
        rel="noreferrer" 
        href={linkURL}
        sx={styles}
        color="primary"
        size="small"
        icon={<Icon name={link.link_icon} size={10} style={{paddingLeft: '5px', fontSize: '.75em' }} />}
        clickable
      />
    )
    
  } else {
    return null
  }

}

export default ResearchBodyCoinItemRightLinks

    /*
    if (link.link_text === 'discord') {
      return (
        <Chip
          label={link.link_text}
          variant="outlined"
          component="a"
          target="_blank"
          rel="noreferrer" 
          href={currencyDetails.discord_url}
          sx={styles}
          color="primary"
          size="small"
          icon={<Icon name={link.link_icon} size={10} style={{paddingLeft: '5px', fontSize: '.75em' }} />}
          clickable
        />
      )

    } else {    
    if (link.link_text === 'reddit') {
      return (
        <Chip
          label={link.link_text}
          variant="outlined"
          component="a"
          target="_blank"
          rel="noreferrer" 
          href={currencyDetails.reddit.url}
          sx={styles}
          color="primary"
          size="small"
          icon={<Icon name={link.link_icon} size={10} style={{paddingLeft: '5px', fontSize: '.75em' }} />}
          clickable
        />
      )

    } else if (link.link_text === 'telegram') {
      return (
        <Chip
          label={link.link_text}
          variant="outlined"
          component="a"
          target="_blank"
          rel="noreferrer" 
          href={currencyDetails.telegram.url}
          sx={styles}
          color="primary"
          size="small"
          icon={<Icon name={link.link_icon} size={10} style={{paddingLeft: '5px', fontSize: '.75em' }} />}
          clickable
        />
      )*/