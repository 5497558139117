import React from 'react'
import { Typography, Button, Stack } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'

const CoinDetailsPrice = ( {currencyDetails, firstPrice, lastPrice, medScreenOrSmaller=false } ) => {
  console.log("top of CoinDetailsPrice")
  console.dir(currencyDetails)
  console.log("lastPrice is: " + lastPrice)
  console.log("firstPrice is: " + firstPrice)
  console.log("medScreenOrSmaller is: " + medScreenOrSmaller)
  const theme = useTheme();

  const typographyStyle = {
    textTransform: 'uppercase'
  }

  const ColorButtonPositive = styled(Button)(({ theme }) => ({
    '&&&': {
      borderRadius: '20px',
      paddingLeft: '10px',
      paddingRight: '5px',
      color: theme.palette.dark[900],
      backgroundColor: theme.palette.success.light,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
    }
  }));

  const ColorButtonNegative = styled(Button)(({ theme }) => ({
    '&&&': {
      borderRadius: '20px',
      paddingLeft: '10px',
      paddingRight: '5px',
      color: theme.palette.dark[900],
      backgroundColor: theme.palette.error.light,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    }
  }));

  const ColorButtonPositiveSmall = styled(Button)(({ theme }) => ({
    '&&&': {
      borderRadius: '20px',
      paddingLeft: '10px',
      paddingRight: '5px',
      color: theme.palette.dark[900],
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    }
  }));

  const ColorButtonNegativeSmall = styled(Button)(({ theme }) => ({
    '&&&': {
      borderRadius: '20px',
      paddingLeft: '10px',
      paddingRight: '5px',
      color: theme.palette.dark[900],
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    }
  }));

  const displayPrice = (price) => {
    if (price.toString().includes('e')) {
      //this has scientific notation
        return price.toFixed(price.toString().split('-')[1]);
    } else {
      return price
    }
  }

  if (firstPrice === 0) {
    //no first price to get % up/down
    return (
      <Typography variant="h4" sx={typographyStyle}>$ {displayPrice(currencyDetails.current_price)} ({currencyDetails.coin.symbol}/USD)</Typography>
    )
  } else {


    var changeDirection = 1

    //if coinmarket/ data is king
    //var priceDifference = Math.abs(parseFloat(firstPrice) - parseFloat(currencyDetails.current_price))
    //var percentageChange = (priceDifference / firstPrice) * 100
    //if (parseFloat(firstPrice) > parseFloat(currencyDetails.current_price)) changeDirection = -1
    //if (parseFloat(firstPrice) === parseFloat(currencyDetails.current_price)) changeDirection = 0

    //if marketchart/ data is king
    var priceDifference = Math.abs(parseFloat(firstPrice) - parseFloat(lastPrice))
    var percentageChange = (priceDifference / firstPrice) * 100
    if (parseFloat(firstPrice) > parseFloat(lastPrice)) changeDirection = -1
    if (parseFloat(firstPrice) === parseFloat(lastPrice)) changeDirection = 0
    if (medScreenOrSmaller) {
      console.log("medScreenOrSmaller in CoinDetailsPrice")
      switch (changeDirection) {
        case 1:
          return (
            <Stack direction="column" alignItems="flex-end">
              <Typography sx={{color: theme.palette.success.main, textTransform: 'uppercase'}}>$ {displayPrice(currencyDetails.current_price)} ({currencyDetails.coin.symbol}/USD)</Typography>
              <Stack direction="row" alignItems="center" justifyContent="flex-end">
                <ArrowUpward sx={{marginLeft: '15px', color: theme.palette.success.main}}/>
                <Typography sx={{color: theme.palette.success.main}}>{percentageChange.toFixed(2)}%</Typography>
              </Stack>
            </Stack>)
        break
        case 0:
          return <Typography  sx={{color: theme.palette.grey[800],}}>$ {displayPrice(currencyDetails.current_price)} ({currencyDetails.coin.symbol}/USD)</Typography>
        break
        case -1:
          return (
            <ColorButtonNegativeSmall>$ {displayPrice(currencyDetails.current_price)} ({currencyDetails.coin.symbol}/USD) <ArrowDownward sx={{marginLeft: '15px', color: theme.palette.error.dark}}/> {percentageChange.toFixed(2)}%</ColorButtonNegativeSmall>)
        break
        default:
          return <Typography >$ {displayPrice(currencyDetails.current_price)} ({currencyDetails.coin.symbol}/USD)</Typography>
      }        
    } else {
      switch (changeDirection) {
        case 1:
          return <ColorButtonPositive variant="h6" sx={typographyStyle}>$ {displayPrice(currencyDetails.current_price)} ({currencyDetails.coin.symbol}/USD) <ArrowUpward sx={{marginLeft: '15px'}}/> {percentageChange.toFixed(2)}%</ColorButtonPositive>
        break
        case 0:
          return <Typography variant="h6" sx={typographyStyle}>$ {displayPrice(currencyDetails.current_price)} ({currencyDetails.coin.symbol}/USD)</Typography>
        break
        case -1:
          return <ColorButtonNegative variant="h6" sx={typographyStyle}>$ {displayPrice(currencyDetails.current_price)} ({currencyDetails.coin.symbol}/USD) <ArrowDownward sx={{marginLeft: '15px'}}/> {percentageChange.toFixed(2)}%</ColorButtonNegative>
        break
        default:
          return <Typography variant="h6" sx={typographyStyle}>$ {displayPrice(currencyDetails.current_price)} ({currencyDetails.coin.symbol}/USD)</Typography>
      }
    }
  }
}

export default CoinDetailsPrice