import { Box, Typography } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import CustomFeedTwitterResultItem from "./CustomFeedTwitterResultItem";
import React from "react";
import { v4 as uuidv4 } from 'uuid';

const CustomFeedList = ({colData, colLoading, }) => {
    return (
        <Box sx={{mb: 1}}>
            {
                (!colData && !colLoading) || (!colData.length && !colLoading) ? (
                        <Typography sx={{color: '#fff', textAlign: 'center', mt: 3}}>There's nothing in this feed.</Typography>
                    )
                    :
                    null
            }
            {
                colLoading === true ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <CircularProgress />
                    </Box>
                    :
                    colData && colData.map((item, index) => {
                        return (
                            <CustomFeedTwitterResultItem key={uuidv4()} tweet={item} search={''} />
                        )
                    })
            }
        </Box>
    )
}

export default CustomFeedList;