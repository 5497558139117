import React, { useEffect, useState, useRef } from 'react'
import { Box, CircularProgress, Grid, Slider, Button, Divider,
    IconButton, Stack, Typography, TextField, Modal, Backdrop, Fade } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import fetchData from '../../utility/endpointFetch'
import { Clear, Search } from '@mui/icons-material'

import { VscDebugRestart } from 'react-icons/vsc'
import { CgMoreO } from 'react-icons/cg'
import { AiOutlineBell } from 'react-icons/ai'
import { ImCancelCircle } from 'react-icons/im'

var date = new Date()
const dateRange = [
    { display : "today", match: 'today', date: new Date(date.getTime()).setDate(date.getDate()), value: 0},
    { display : "1 Day", match: '1d', date: new Date(date.getTime()).setDate(date.getDate() - 1), value: 1},
    { display : "2 Days", match: '2d', date: new Date(date.getTime()).setDate(date.getDate() - 2), value: 2},
    { display : "3 Days", match: '3d', date: new Date(date.getTime()).setDate(date.getDate() - 3), value: 3},
    { display : "4 Days", match: '4d', date: new Date(date.getTime()).setDate(date.getDate() - 4), value: 4},
    { display : "5 Days", match: '5d', date: new Date(date.getTime()).setDate(date.getDate() - 5), value: 5},
    { display : "6 Days", match: '6d', date: new Date(date.getTime()).setDate(date.getDate() - 6), value: 6},
    { display : "7 Days", match: '7d', date: new Date(date.getTime()).setDate(date.getDate() - 7), value: 7},
    { display : "8 Days", match: '8d', date: new Date(date.getTime()).setDate(date.getDate() - 8), value: 8},
    { display : "9 Days", match: '9d', date: new Date(date.getTime()).setDate(date.getDate() - 9), value: 9},
    { display : "10 Days", match: '10d', date: new Date(date.getTime()).setDate(date.getDate() - 10), value: 10},
]

const InfluencerSearch = ({ filterCoinList }) => {

    const theme = useTheme()

    //const [isLoading,setIsLoading] = useState(true)
    const [startingValues,setStartingValues] = useState({})

    //const [open,setOpen] = useState(false)

    const [postSearchTerm,setPostSearchTerm] = useState('')

    const [marketCapMin,setMarketCapMin] = useState(0)
    const [marketCapMax,setMarketCapMax] = useState(0)
    const [marketCapMinError,setMarketCapMinError] = useState(false)
    const [marketCapMaxError,setMarketCapMaxError] = useState(false)
    const [isMarketCapLoading,setIsMarketCapLoading] = useState(false)
    const [quickMarketCapCount,setQuickMarketCapCount] = useState(0)    

    const [marketCapDetailsOpen,setMarketCapDetailsOpen] = useState(false)

    //const [followersMin,setFollowersMin] = useState(0)
    //const [followersMax,setFollowersMax] = useState(0)
    //const [followersMinError,setFollowersMinError] = useState(false)
    //const [followersMaxError,setFollowersMaxError] = useState(false)
    //const [isFollowersLoading,setIsFollowersLoading] = useState(false)
    //const [quickFollowersCount,setQuickFollowersCount] = useState(0)

    //const [vsBTCMin,setVsBTCMin] = useState(0)
    //const [vsBTCMax,setVsBTCMax] = useState(0)
    //const [vsETHMin,setVsETHMin] = useState(0)
    //const [vsETHMax,setVsETHMax] = useState(0)
    //const [vsBTCTimeHorizon,setVsBTCTimeHorizon] = useState(1)
    //const [vsETHTimeHorizon,setVsETHTimeHorizon] = useState(1)

    const [dateInfluencerGTE,setDateInfluencerGTE] = useState(0)
    const [dateInfluencerLTE,setDateInfluencerLTE] = useState(0)

    const [createdGTE,setCreatedGTE] = useState(0)
    const [createdLTE,setCreatedLTE] = useState(0)    

    //const [socialEngMin,setSocialEngMin] = useState(0)
    //const [socialEngMax,setSocialEngMax] = useState(5)
    //const [socialEngMinError,setSocialEngMinError] = useState(0)
    //const [socialEngMaxError,setSocialEngMaxError] = useState(5)    

    const [selectedCategories,setSelectedCategories] = useState([])
    const [queryString,setQueryString] = useState({})
    const [tweetQueryString,setTweetQueryString] = useState({
        search: '',
        timestamp__lte: 0,
        timestamp__gte: 10
    })
    //const [anchorEl, setAnchorEl] = useState(null)

    //const [minMax,setMinMax] = useState({})

    const [buttonType,setButtonType] = useState('outlined')

    const researchSearchFormRef = useRef()

    /*
    const handleClose = () => {
        //console.log("top of handleClose")
        setAnchorEl(null)
        setOpen((prevOpen) => !prevOpen)
    }    

    const handleSaveClick = (event) => {
        setAnchorEl(event.currentTarget)
        setOpen((prevOpen) => !prevOpen)
    }    
    */

    useEffect(() => {
        //(true)
        //get the min/max values to use in the search form

        //will get these values from /api/v1/coin/coinstats/1/ when that is fully setup

        const getSavedSearchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }  

        ;(async () => {
            try {
                let getMinMaxURL = `api/v1/feed/feedminmax/`
                const getMinMaxResponse = await fetchData(getMinMaxURL, "could not receive min max values", getSavedSearchParams).promise
                //console.log("getMinMaxResponse getting min max values")
                //console.dir(getMinMaxResponse)

                //setMinMax(getMinMaxResponse.results[0])

                var dataFromEndpoint = {

                    results: {
                        min_market_cap: getMinMaxResponse.results[0].min_marketcap,
                        max_market_cap: getMinMaxResponse.results[0].max_marketcap,
                        min_followers: getMinMaxResponse.results[0].min_followers,
                        max_followers: getMinMaxResponse.results[0].max_followers,
                        vs_btc_min: parseInt(getMinMaxResponse.results[0].min_btc_24h_dif),
                        vs_btc_max: parseInt(getMinMaxResponse.results[0].max_btc_24h_dif),
                        vs_eth_min: parseInt(getMinMaxResponse.results[0].min_eth_24h_dif),
                        vs_eth_max: parseInt(getMinMaxResponse.results[0].max_eth_24h_dif),
                        post_created__lte: 0,
                        post_created__gte: 12,
                        dateInfluencer__lte: 0,
                        dateInfluencer__gte: 10,
                        timestamp__lte: 0,
                        timestamp__gte: 10,
                        social_engagement__gte: parseInt(getMinMaxResponse.results[0].min_social_engagement),
                        social_engagement__lte: parseInt(getMinMaxResponse.results[0].max_social_engagement),
                        post_body_date__lte: getMinMaxResponse.results[0].max_body_date,
                        post_body_date__gte: getMinMaxResponse.results[0].min_body_date
                    }
                
                }

                setMarketCapMin(dataFromEndpoint.results.min_market_cap)
                setMarketCapMax(dataFromEndpoint.results.max_market_cap)
                //setFollowersMin(dataFromEndpoint.results.min_followers)
                //setFollowersMax(dataFromEndpoint.results.max_followers)       
                setDateInfluencerGTE(dataFromEndpoint.results.dateInfluencer__gte)
                setDateInfluencerLTE(dataFromEndpoint.results.dateInfluencer__lte)
                //setSocialEngMin(dataFromEndpoint.results.social_engagement__gte)
                //setSocialEngMax(dataFromEndpoint.results.social_engagement__lte)
                setCreatedGTE(dataFromEndpoint.results.timestamp__gte)
                setCreatedLTE(dataFromEndpoint.results.timestamp__lte)

                setStartingValues(dataFromEndpoint.results)

                //TODO add values for supply ratio when they appear
                setQueryString({
                    dateInfluencer__gte: 10,     
                    dateInfluencer__lte: 0,
                    s_marketcap__lte: dataFromEndpoint.results.max_market_cap,
                    s_marketcap__gte: dataFromEndpoint.results.min_market_cap,
                    followers__gte: dataFromEndpoint.results.min_followers,
                    followers__lte: dataFromEndpoint.results.max_followers,
                    btc_24h_dif__gte: dataFromEndpoint.results.vs_btc_min,
                    btc_24h_dif__lte: dataFromEndpoint.results.vs_btc_max,
                    eth_24h_dif__gte: dataFromEndpoint.results.vs_eth_min,
                    eth_24h_dif__lte: dataFromEndpoint.results.vs_eth_max, 
                    search: '',
                    timestamp__lte: 0,
                    timestamp__gte: 10,
                    post_created__gte: 12,     
                    post_created__lte: 0,
                    social_engagement__lte: dataFromEndpoint.results.social_engagement__lte,
                    social_engagement__gte: dataFromEndpoint.results.social_engagement__gte,
                    post_body_date__lte: dataFromEndpoint.results.post_body_date__lte,
                    post_body_date__gte: dataFromEndpoint.results.post_body_date__gte,
                    categories__name__in: selectedCategories,                      
                })
                setTweetQueryString({
                    search: '',
                    timestamp__lte: 0,
                    timestamp__gte: 10
                })
                console.log("setting TweetQueryString to:")
                console.dir({
                    search: '',
                    timestamp__lte: 0,
                    timestamp__gte: 10
                })
                //setIsLoading(false)

                //getBoxPosition()
            } catch (error) {
                console.log("something is broke receiving min max values: ", error)
            }
            
            
        })()            

                
    }, [])    

    useEffect(() => {
        console.log("top of queryString change useEffect")
        console.dir(queryString)
        console.log("startingValues is:")
        console.dir(startingValues)
        let passedQueryString = ''

        if (queryString.s_marketcap__gte !== startingValues.min_market_cap) {
            passedQueryString += 's_marketcap__gte='+queryString.s_marketcap__gte
        }
        if (queryString.s_marketcap__lte !== startingValues.max_market_cap) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 's_marketcap__lte='+queryString.s_marketcap__lte
        }  

        if (queryString.followers__gte !== startingValues.min_followers) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'followers__gte='+queryString.followers__gte
        }
        if (queryString.followers__lte !== startingValues.max_followers) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'followers__lte='+queryString.followers__lte
        }   

        if (queryString.btc_24h_dif__gte !== startingValues.vs_btc_min) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'btc_24h_dif__gte='+queryString.btc_24h_dif__gte
        }
        if (queryString.btc_24h_dif__lte !== startingValues.vs_btc_max) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'btc_24h_dif__lte='+queryString.btc_24h_dif__lte
        }         

        if (queryString.eth_24h_dif__gte !== startingValues.vs_eth_min) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'eth_24h_dif__gte='+queryString.eth_24h_dif__gte
        }
        if (queryString.eth_24h_dif__lte !== startingValues.vs_eth_max) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'eth_24h_dif__lte='+queryString.eth_24h_dif__lte
        }          

        
        if (queryString.dateInfluencer__gte !== startingValues.dateInfluencer__gte) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            console.log("dateRange[queryString.dateInfluencer__gte] is: ")
            console.dir(dateRange[queryString.dateInfluencer__gte])
            console.log("queryString.dateInfluencer__gte is: " + queryString.dateInfluencer__gte)
            let gteDate = new Date( dateRange[queryString.dateInfluencer__gte].date)
            passedQueryString += 'dateInfluencer__gte='+gteDate.toISOString().split("T")[0]
        }
        if (queryString.dateInfluencer__lte !== startingValues.dateInfluencer__lte) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            console.log("dateRange[queryString.dateInfluencer__lte] is: ")
            console.dir(dateRange[queryString.dateInfluencer__lte])
            console.log("queryString.dateInfluencer__lte is: " + queryString.dateInfluencer__lte)
            let lteDate = new Date( dateRange[queryString.dateInfluencer__lte].date)
            passedQueryString += 'dateInfluencer__lte='+lteDate.toISOString().split("T")[0]
        }
             

        if (queryString.social_engagement__gte !== startingValues.social_engagement__gte) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'social_engagement__gte='+queryString.social_engagement__gte
        }
        if (queryString.social_engagement__lte !== startingValues.social_engagement__lte) {
            if (passedQueryString.length > 0) passedQueryString += '&'
            passedQueryString += 'social_engagement__lte='+queryString.social_engagement__lte
        }    
        
        
        let passedTweetQueryString = ''

        if (tweetQueryString.search !== '' && tweetQueryString.search !== undefined) {
            if (passedTweetQueryString.length > 0) passedTweetQueryString += '&'
            passedTweetQueryString += 'search='+tweetQueryString.search
        }        

        if (tweetQueryString.timestamp__gte !== startingValues.timestamp__gte) {
            if (passedTweetQueryString.length > 0) passedTweetQueryString += '&'
            console.log("dateRange[queryString.timestamp__gte] is: ")
            console.dir(dateRange[queryString.timestamp__gte])
            console.log("tweetQueryString.timestamp__gte is: " + tweetQueryString.timestamp__gte)
            let gteDate = new Date( dateRange[tweetQueryString.timestamp__gte].date)
            passedTweetQueryString += 'timestamp__gte='+gteDate.toISOString().split("T")[0]
        }
        if (tweetQueryString.timestamp__lte !== startingValues.timestamp__lte) {
            if (passedTweetQueryString.length > 0) passedTweetQueryString += '&'
            console.log("dateRange[queryString.timestamp__lte] is: ")
            console.dir(dateRange[queryString.timestamp__lte])
            console.log("tweetQueryString.timestamp__lte is: " + tweetQueryString.timestamp__lte)
            let lteDate = new Date( dateRange[tweetQueryString.timestamp__lte].date)
            passedTweetQueryString += 'timestamp__lte='+lteDate.toISOString().split("T")[0]
        }         

        //console.log("selectedCategories is:")
        //console.dir(selectedCategories)
        if (selectedCategories.length > 0) {
            if (passedQueryString.length > 0) passedQueryString += '&'

            if (selectedCategories.length === 1)
                passedQueryString += 'categories__name__in=' + selectedCategories[0] + ''
            else
                passedQueryString += 'categories__name__in=' + selectedCategories.join(',') + ''
        }

        console.log("passedQueryString is:" + passedQueryString)
        console.log("passedTweetQueryString is:" + passedTweetQueryString)
        filterCoinList(passedQueryString,passedTweetQueryString)

    }, [queryString, tweetQueryString])        

    const handleSearchSubmitted = (e) => {
        e.preventDefault()
        //console.dir(e)
        console.log("---top of handleSearchSubmitted and queryString is: ")
        //console.dir(queryString)
        //console.log("marketCapMax is: " + marketCapMax)
        //console.log("marketCapMin is: " + marketCapMin)
        //console.log("dateInfluencerGTE is: " + dateInfluencerGTE)
        //console.log("dateInfluencerLTE is: " + dateInfluencerLTE)
        //console.log("createdGTE is: " + createdGTE)
        //console.log("createdLTE is: " + createdLTE)        
        //console.log("postSearchTerm is: " + postSearchTerm)
        setQueryString({...queryString, s_marketcap__lte: marketCapMax, s_marketcap__gte: marketCapMin, 
            dateInfluencer__gte: dateInfluencerGTE, dateInfluencer__lte: dateInfluencerLTE,
        })
        console.log("setting queryString to:")
        console.dir({...queryString, s_marketcap__lte: marketCapMax, s_marketcap__gte: marketCapMin, 
            dateInfluencer__gte: dateInfluencerGTE, dateInfluencer__lte: dateInfluencerLTE,
        })
        console.log("setting tweetQueryString to:")
        console.dir({...tweetQueryString, timestamp__lte: createdLTE, timestamp__gte: createdGTE, 
            search: postSearchTerm
        })
        setTweetQueryString({...tweetQueryString, timestamp__lte: createdLTE, timestamp__gte: createdGTE, 
            search: postSearchTerm
        })
        changeSubmitButtonState('submit')
    }      

    const handleMarketCapChange = (e, newMarketCapRange) => {
        console.log("top of handleMarketCapChange")
        console.dir(newMarketCapRange)
        setMarketCapMin(newMarketCapRange[0])
        setMarketCapMax(newMarketCapRange[1])   
        changeSubmitButtonState('change') 
    }    

    function dateLabelFormat(value) {
        //console.log("top of dateLabelFormat and value is:")
        //console.dir(value)
        let arrayValue = dateRange.findIndex((mark) => mark.value === value)
        //console.dir(arrayValue)
        return arrayValue.display
    }   
    
    function dateValueText(value) {
        //console.log("top of dateValueText and value is:")
        //console.dir(value)        
        let arrayValue = dateRange.findIndex((mark) => mark.value === value)
        //console.dir(arrayValue)
        return arrayValue.match
    }    

    function tweetDateLabelFormat(value) {
        //console.log("top of dateLabelFormat and value is:")
        //console.dir(value)
        let arrayValue = dateRange.findIndex((mark) => mark.value === value)
        //console.dir(arrayValue)
        return arrayValue.display
    }     

    function tweetDateValueText(value) {
        //console.log("top of dateValueText and value is:")
        //console.dir(value)        
        let arrayValue = dateRange.findIndex((mark) => mark.value === value)
        //console.dir(arrayValue)
        return arrayValue.match
    }     

    const abbreviateValue = (number) => {
        if (number > 1000000000) {
          return ((number / 1000000000).toFixed(2)) + 'B'
        } else if (number > 1000000) {
          return ((number/1000000).toFixed(2)) + 'M'
        } else if (number > 1000) {
          return ((number/1000).toFixed(2)) + 'K'
        } else {
          //just display the full amount
          return number
        }
    }    
    
    const getResultOfMarketCapChange = (passedMarkCapMin, passedMarketCapMax) => {
        setIsMarketCapLoading(true)
        //build the query string just for market cap change
        let quickMarketCapQueryString = ''
        if (passedMarkCapMin !== startingValues.min_market_cap) {
            quickMarketCapQueryString += 's_marketcap__gte='+passedMarkCapMin
        }
        if (passedMarketCapMax !== startingValues.max_market_cap) {
            if (quickMarketCapQueryString.length > 0) quickMarketCapQueryString += '&'
            quickMarketCapQueryString += 's_marketcap__lte='+passedMarketCapMax
        }  

        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }
        ;(async () => {
            try {
                var quickMarketCapResponseURL = `api/v1/feed/alltweets/?${quickMarketCapQueryString}`
                const quickMarketCapResponse = await fetchData(quickMarketCapResponseURL, "could not receive researcher post search market cap modal results", fetchParams).promise
                console.log("getting researcher post search market cap modal results")
                console.log("quickMarketCapResponseURL is: " + quickMarketCapResponseURL)
                console.dir(quickMarketCapResponse)
                setQuickMarketCapCount(quickMarketCapResponse.count)
                setIsMarketCapLoading(false)
            } catch (error) {
                console.log("could not receive researcher post search market cap modal results: ", error)
            }
        })()
    }    

    const toggleMarketCapSearchDetails = () => {
        //console.log("top of toggleMarketCapSearchDetails")
        getResultOfMarketCapChange(marketCapMin, marketCapMax)
        setMarketCapDetailsOpen(!marketCapDetailsOpen)
    }    

    const saveMarketCapDetails = () => {
        setMarketCapDetailsOpen(false)
    }

    const cancelMarketCapDetails = () => {
        setMarketCapDetailsOpen(false)
    }    

    const changeSubmitButtonState = (type) => {
        if (type === "change") {
            if (buttonType === 'outlined') {
                //first change after submitting....set to contained to emphasize search button
                setButtonType('contained')
            }
        } else if ( type === "submit") {
            if (buttonType === 'contained') {
                //submitting the form for search....reset to outlined to de-emphasize until a form change
                setButtonType('outlined')
            }
        }
    }    

    const handleMarketCapMinChange = (e) => {
        setMarketCapMinError(false)
        setMarketCapMin(e.target.value.replace(/\D/g,''))
        if (e.target.value > marketCapMax) { 
            setMarketCapMinError(true) 
        } else if (e.target.value.length > 3) getResultOfMarketCapChange(e.target.value, marketCapMax)
        changeSubmitButtonState('change')
    }

    const handleMarketCapMaxChange = (e) => {
        setMarketCapMaxError(false)
        setMarketCapMax(e.target.value.replace(/\D/g,''))
        if (e.target.value < marketCapMin) { 
            setMarketCapMaxError(true) 
        } else if (e.target.value.length > 3) getResultOfMarketCapChange(marketCapMin, e.target.value)
        changeSubmitButtonState('change')
    }    

    const handleDateRangeChange = (e, newDateRange) => {
        //console.log("top of handleDateRangeChange and newDateRange is:")
        //console.dir(newDateRange)
        //1d,2d,3d,4d,5d,6d,1w,2w,3w,1m,2m,3m
        setDateInfluencerLTE(dateRange[newDateRange[0]].value)
        setDateInfluencerGTE(dateRange[newDateRange[1]].value)
        changeSubmitButtonState('change')
    }

    const handleTweetDateRangeChange = (e, newDateRange) => {
        //console.log("top of handleTweetDateRangeChange and newDateRange is:")
        //console.dir(newDateRange)
        //1d,2d,3d,4d,5d,6d,1w,2w,3w,1m,2m,3m
        setCreatedLTE(dateRange[newDateRange[0]].value)
        setCreatedGTE(dateRange[newDateRange[1]].value)
        changeSubmitButtonState('change')
    }    

    const displayNumber = (number) => {
        return parseInt(number).toLocaleString('en-US', {maximumFractionDigits: 2})
    }    

    const handleReset = ( valuesToReset ) => {
        switch(valuesToReset) {
            case 'marketCap': 
                setQueryString({...queryString, s_marketcap__lte: startingValues.max_market_cap, s_marketcap__gte: startingValues.min_market_cap})
                setMarketCapMin(startingValues.min_market_cap)
                setMarketCapMax(startingValues.max_market_cap)
                break
            case 'followers': 
                setQueryString({...queryString, followers__lte: startingValues.max_followers, followers__gte: startingValues.min_followers})
                //setFollowersMax(startingValues.max_followers)
                //setFollowersMin(startingValues.min_followers)
                break   
            case 'vsBTC': 
                setQueryString({...queryString, btc_24h_dif__lte: startingValues.vs_btc_max, btc_24h_dif__gte: startingValues.vs_btc_min})
                //setVsBTCMin(startingValues.vs_btc_min)
                //setVsBTCMax(startingValues.vs_btc_max)
                break    
            case 'vsETH': 
                setQueryString({...queryString, eth_24h_dif__lte: startingValues.vs_eth_max, eth_24h_dif__gte: startingValues.vs_eth_min})
                //setVsETHMin(startingValues.vs_eth_min)
                //setVsETHMax(startingValues.vs_eth_max)
                break      
            case 'category':
                setSelectedCategories([])
                setQueryString({...queryString, categories__name__in: []})
                break
            case 'dateInfluencerRange':
                setQueryString({...queryString, post_created__gte: startingValues.post_created__gte, post_created__lte: startingValues.post_created__lte})
                setDateInfluencerGTE(startingValues.dateInfluencer__gte)
                setDateInfluencerLTE(startingValues.dateInfluencer__lte)
                break               
            case 'tweetDateRange':
                setTweetQueryString({...tweetQueryString, timestamp__gte: startingValues.timestamp__gte, timestamp__lte: startingValues.timestamp__lte})
                setCreatedGTE(startingValues.timestamp__gte)
                setCreatedLTE(startingValues.timestamp__lte)
                break               
            case 'tweetSearchText':
                setTweetQueryString({...tweetQueryString, search: ''})
                setPostSearchTerm('')
                break
            case 'social_engagement':
                setQueryString({...queryString, social_engagement__gte: startingValues.social_engagement__gte, social_engagement__lte: startingValues.social_engagement__lte})
                //setSocialEngMax(startingValues.social_engagement__lte)
                //setSocialEngMin(startingValues.social_engagement__gte)
                break                   
            default:
                //do nothing
                break                                     
        }
    }    

    const handleSearchChange = (e) => {
        //console.log("top of handleSearchChange and e.target.value is: " + e.target.value)
        setPostSearchTerm(e.target.value)
        //setQueryString({...queryString, search: e.target.value})
        changeSubmitButtonState('change')
    }

    const handleSearchClicked = () => {
        //console.log("top of handleSearchClicked and postSearchTerm is: " + postSearchTerm)
        //console.log("marketCapMax is: " + marketCapMax)
        //console.log("queryString is:")
        //console.dir(queryString)
        setQueryString({...queryString, s_marketcap__lte: marketCapMax, s_marketcap__gte: marketCapMin, 
           post_created__gte: dateInfluencerGTE, post_created__lte: dateInfluencerLTE,
           search: postSearchTerm
        })
        changeSubmitButtonState('submit')
    }

    return (
        <Box width="100%" sx={{paddingLeft: "10px", paddingRight: "10px"}} ref={researchSearchFormRef}>
            <form onSubmit={handleSearchSubmitted}>
            <Stack direction="column" display="flex" alignItems="center" justifyContent="flex-start">
                <Stack direction="column" sx={{width: '100%', marginTop: '5px'}}>
                    <Divider sx={{width: '100%', marginBottom: '5px',"&::before, &::after": { borderColor: theme.palette.primary.dark, }}} textAlign="left">Coin Search</Divider>  

                    <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                            <Typography sx={{color: theme.palette.primary.main, fontWeight: 700, marginRight: '5px'}}>Market Cap</Typography>
                            <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('marketCap')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                        </Stack>
                        <IconButton onClick={()=>toggleMarketCapSearchDetails()}>
                            <CgMoreO size={18} color={theme.palette.primary.main}/>
                        </IconButton>
                    </Stack>
                  
                    <Box sx={{padding: '5px', border: 2, borderColor: theme.palette.grey[400], backgroundColor: theme.palette.background.default, width: '100%', borderRadius: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                            <Typography display="inline">${abbreviateValue(marketCapMin)}</Typography>
                            <Typography display="inline">to</Typography>
                            <Typography display="inline">${abbreviateValue(marketCapMax)}</Typography>
                        </Stack>
                    </Box>                    
                    <Slider 
                        getAriaLabel={() => "Market Cap" }
                        value={[marketCapMin,marketCapMax]} 
                        min={startingValues.min_market_cap}
                        max={startingValues.max_market_cap}
                        //onChangeCommitted={handleMarketCapCommit}
                        onChange={handleMarketCapChange} 
                        defaultValue={[marketCapMin,marketCapMax]}    
                        valueLabelDisplay="off"
                        sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}
                    />
                </Stack>
                <Stack direction="column" sx={{width: '100%', marginTop: '5px'}}> 
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">    
                        <Typography sx={{color: theme.palette.primary.main, fontWeight: 700}}>Date Range</Typography>
                        <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('dateRange')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                    </Stack>                      
                    <Box sx={{padding: '5px', border: 2, borderColor: theme.palette.grey[400], backgroundColor: theme.palette.background.default , width: '100%', borderRadius: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                            <Typography display="inline">{dateRange[dateInfluencerLTE].display}</Typography>
                            <Typography display="inline">to</Typography>
                            <Typography display="inline">{dateRange[dateInfluencerGTE].display}</Typography>
                     
                        </Stack>
                    </Box>                             
                    <Slider 
                        marks={dateRange}
                        //getAriaLabel={} 
                        value={[dateInfluencerLTE,dateInfluencerGTE]} 
                        min={startingValues.dateInfluencer__lte}
                        max={startingValues.dateInfluencer__gte}
                        onChange={handleDateRangeChange}
                        defaultValue={[startingValues.dateInfluencer__lte,startingValues.dateInfluencer__gte]}
                        sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}

                        valueLabelFormat={dateLabelFormat}
                        getAriaValueText={dateValueText}
                        step={1}
                        //valueLabelDisplay="off"

                    />
                </Stack>          

                <Divider 
                    sx={{
                        width: '100%', 
                        marginTop: '30px', 
                        marginBottom: '5px',
                        "&::before, &::after": {
                            borderColor: theme.palette.primary.dark,
                        }
                    }} textAlign="left">Tweet Search</Divider>       

                <Box sx={{width: '100%', padding: '5px', paddingBottom: '0px'}}>
                    <Stack 
                        direction="row" 
                        display="flex" 
                        alignItems="center"
                        sx={{
                            position: 'relative',
                            top: '-10px'
                        }}
                    >
                        <TextField 
                            id="influencer-coin-search" 
                            label="Influencer Tweet Search Term" 
                            variant="outlined"
                            value={postSearchTerm} 
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                width: '100%', 
                                minWidth: '80px',
                                color: theme.palette.grey[500], 
                                input: {
                                    background: theme.palette.dark[900],
                                },
                            }}
                            InputLabelProps={{
                                style: { 
                                    color: theme.palette.grey[500] ,
                                    position: 'relative',
                                    top: '17px'
                                },
                            }}
                            inputProps={{
                                style: { height: '12px' },
                            }}

                            
                        />
                        <IconButton
                            aria-label="clear coin"
                            onClick={handleSearchClicked}
                            edge="end"
                            sx={{
                                position: 'relative',
                                top: '12px'
                            }}
                            type="submit"
                        >
                            <Search sx={{fontSize: '.8em', color: theme.palette.grey[500]}} />
                        </IconButton>            
                        <IconButton
                            aria-label="clear coin"
                            onClick={() => handleReset('tweetSearchText')}
                            edge="end"
                            sx={{
                                position: 'relative',
                                top: '12px'
                            }} 
                        >
                            <Clear sx={{fontSize: '.8em',color: theme.palette.grey[500]}} />
                        </IconButton>
                    </Stack>    
                </Box>

                <Stack direction="column" sx={{width: '100%', marginTop: '5px'}}> 
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">    
                        <Typography sx={{color: theme.palette.primary.main, fontWeight: 700}}>Tweet Date Range</Typography>
                        <IconButton size="small" sx={{fontSize: '.8rem'}} onClick={() => handleReset('tweetdateRange')}><VscDebugRestart color={theme.palette.primary.main}  /></IconButton>
                    </Stack>                      
                    <Box sx={{padding: '5px', border: 2, borderColor: theme.palette.grey[400], backgroundColor: theme.palette.background.default , width: '100%', borderRadius: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
                            <Typography display="inline">{dateRange[createdLTE].display}</Typography>
                            <Typography display="inline">to</Typography>
                            <Typography display="inline">{dateRange[createdGTE].display}</Typography>
                     
                        </Stack>
                    </Box>                             
                    <Slider 
                        marks={dateRange}
                        //getAriaLabel={} 
                        value={[createdLTE,createdGTE]} 
                        min={startingValues.timestamp__lte}
                        max={startingValues.timestamp__gte}
                        onChange={handleTweetDateRangeChange}
                        defaultValue={[startingValues.timestamp__lte,startingValues.timestamp__gte]}
                        sx={{width: '80%', marginLeft: '10%', marginRight: '10%'}}

                        valueLabelFormat={tweetDateLabelFormat}
                        getAriaValueText={tweetDateValueText}
                        step={1}
                        //valueLabelDisplay="off"

                    />
                </Stack>     

                <Button type="submit" variant={buttonType} color="primary" sx={{marginTop: '20px'}}>Search</Button>
            </Stack>
            </form>  
            <Modal
                aria-labelledby='spring-modal-market-cap'
                aria-describedby='spring-modal-market-cap-description'
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
                open={marketCapDetailsOpen}
                closeAfterTransition
                components={Backdrop}
                disableEscapeKeyDown={true}
                >
                <Fade in={marketCapDetailsOpen}>
                    <Box sx={{ position: 'absolute', width: 800, backgroundColor: theme.palette.dark[800],  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)', borderRadius: '8px'}}>
                    <h2 id='spring-modal-market-cap'>Manually Insert Criterial For Market Cap Search</h2>
                    <p id='spring-modal-market-cap-description' style={{ fontSize: '1rem' }}>
                        This can give you more precise controls to insert you market cap criteria for you research
                        <br /><br />
                        <Grid container spacing={2}>
                            <Grid item xs={6} style={{ paddingBottom: '25px'}}>
                                <TextField
                                    type='text'
                                    required
                                    fullWidth
                                    id='marketCapMin'
                                    label='Market Cap Minimum'
                                    value={marketCapMin.toLocaleString(undefined, {maximumFractionDigits: 2})}
                                    name='marketCapMin'
                                    onChange={handleMarketCapMinChange}
                                    helperText={marketCapMinError ? 'Your minimum must be more than 0 and not more than your maximum' : null}
                                    error={marketCapMinError}
                                    variant="outlined"
                                    focused                
                                />  
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: '25px'}}>
                                <TextField
                                    type='text'
                                    required
                                    fullWidth
                                    id='marketCapMax'
                                    label='Market Cap Maximum'
                                    value={marketCapMax.toLocaleString(undefined, {maximumFractionDigits: 2})}
                                    name='marketCapMax'
                                    onChange={handleMarketCapMaxChange}
                                    helperText={marketCapMaxError ? 'Your maximum should not be more than BitCoins market cap of ~450Bn and more than your maximum' : null}
                                    error={marketCapMaxError}
                                    variant="outlined"
                                    focused                
                                />                              
                            </Grid>
                            <Grid item xs={12}>
                                
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}>Number of results between </Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.primary.main}>{displayNumber(marketCapMin)}</Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}> and </Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.primary.main}>{displayNumber(marketCapMax)}</Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem"}} color={theme.palette.secondary.main}> is: </Typography>
                                <Typography display="inline" sx={{fontSize: "1.2rem", fontWeight: 700}}color={theme.palette.primary.main}>{isMarketCapLoading ? <CircularProgress size={16} sx={{color: theme.palette.primary.main, }} /> : displayNumber(quickMarketCapCount)  }</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                                <Button startIcon={<AiOutlineBell />} variant='outlined' onClick={saveMarketCapDetails} color='primary'>
                                    Click To Apply Your Criteria
                                </Button>
                                <Button startIcon={<ImCancelCircle />} variant='outlined' onClick={cancelMarketCapDetails} color='warning' sx={{ml: 1}}>
                                    Cancel
                                </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </p>
                    </Box>
                </Fade>
            </Modal>            
        </Box>     
    )
}

export default InfluencerSearch