import React from 'react'
import { Stack, Typography, Divider, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const VIPTabsStats = ( { vipListings, changeAtMentionedTab, changeHashTab, changeCoinInTab, coinsMentioned, changeAtFollowedTab, coinInTab, setCoinInChart }) => {
 
  console.log("TOP OR VIPTabsStats")
  console.dir(vipListings)
  //console.log("coinsMentioned is:" )
  //console.dir(coinsMentioned)
  const theme = useTheme()


  //const [attedWords,setAttedWords] = useState([])
  //const [hashedWords,setHashedWords] = useState([])

  //go through vipListings to get all @ accounts
  var all_atted_words = []
  vipListings.map( (listing) => {
    if (listing !== null) {
      if (listing.text.match(/@[a-z0-9_-]+/gi) !== null) {
        var matched = listing.text.match(/@[a-z0-9_-]+/gi)
        matched.map( (this_match) => all_atted_words.push(this_match))
      }}
    }
  )
  //setAttedWords(all_atted_words)

  var displayAttedWords = []
  all_atted_words.map( (attedWord) => {
    //console.log("inside top of vipListings getting attedWords map: " )
    //console.log("displayAttedWords is: ")
    //console.dir(displayAttedWords)
    if (displayAttedWords.length === 0) {
      displayAttedWords.push( { "atted": attedWord, "attedCount": 1 })
    } else {
      var found = displayAttedWords.findIndex(( displayWord ) => displayWord.atted.toLowerCase() === attedWord.toLowerCase())
      //console.log("found is: " + found)
      //console.log("displayAttedWords[found] is: " + displayAttedWords[found])
      if (found !== -1) {
        displayAttedWords[found].attedCount++
      } else  {
        displayAttedWords.push( { "atted": attedWord, "attedCount": 1 })
      }
    }
  })

  displayAttedWords.sort((a, b) => (a.attedCount < b.attedCount ? 1 : -1));
  //console.log("displayAttedWords complete is: ")
  //console.dir(displayAttedWords)

  //go through vipListings to get unique hashtags (#)
  var all_hashed_words = []
  vipListings.map( (listing) => {
    if (listing !== null) {
      if (listing.text.match(/#[a-z0-9_-]+/gi) !== null) {
        var matched = listing.text.match(/#[a-z0-9_-]+/gi)
        //all_hashed_words = all_hashed_words.concat(listing.text.match(/#[a-z0-9_-]+/gi))
        matched.map( (this_match) => all_hashed_words.push(this_match))
      }}
    }
  )
  //console.log("all_hashed_words is: ")
  //console.dir(all_hashed_words)
  //setHashedWords(all_hashed_words)


  var displayHashedWords = []
  all_hashed_words.map( (hashedWord) => {
    //console.log("inside top of hashedWords map: " + hashedWord)
    //console.log("displayHashedWords is: ")
    //console.dir(displayHashedWords)
    if (displayHashedWords.length === 0) {
      displayHashedWords.push( { "hashed": hashedWord, "hashedCount": 1 })
    } else {
      var found = displayHashedWords.findIndex(( displayWord ) => displayWord.hashed.toLowerCase() === hashedWord.toLowerCase())
      //console.log("found is: " + found)
      //console.log("displayHashedWords[found] is: " + displayHashedWords[found])
      if (found !== -1) {
        displayHashedWords[found].hashedCount++
      } else  {
        displayHashedWords.push( { "hashed": hashedWord, "hashedCount": 1 })
      }
    }
  })
  //console.log("displayHashedWords complete is: ")
  //console.dir(displayHashedWords)

  displayHashedWords.sort((a, b) => (a.hashedCount < b.hashedCount ? 1 : -1));


  var displayCoins = []
  coinsMentioned.map( (coin) => {
    //console.log("inside top of coinsMentioned map: " + coin)
    //console.log("displayCoins is: ")
    //console.dir(displayCoins)
    if (displayCoins.length === 0) {
      displayCoins.push( { "coin": coin, "coinCount": 1 })
    } else {
      var found = displayCoins.findIndex(( displayCoin ) => displayCoin.coin.toLowerCase() === coin.toLowerCase())
      //console.log("found is: " + found)
      //console.log("displayCoins[found] is: " + displayCoins[found])
      if (found != -1) {
        displayCoins[found].coinCount++
      } else  {
        displayCoins.push( { coin: coin, coinCount: 1 })
      }
    }
  })
  //console.log("displayCoins complete is: ")
  //console.dir(displayCoins)

  displayCoins.sort((a, b) => (a.coinCount < b.coinCount ? 1 : -1));

  var displayFollowed = []
  vipListings.map( ( listing ) => {
    console.log("inside top of displayFollowed map and listing is: ")
    console.dir(listing)
    //console.log("displayFollowed is: ")
    console.dir(displayFollowed)
    if (displayFollowed.length === 0) {
      displayFollowed.push( { followed: listing.twitter_display_name, followedCount: 1 })
    } else {
      var found = displayFollowed.findIndex(( displayfollow ) => displayfollow.followed.toLowerCase() === listing.twitter_display_name.toLowerCase())
      //console.log("found is: " + found)
      //console.log("displayFollowed[found] is: " + displayFollowed[found])
      if (found !== -1) {
        displayFollowed[found].followedCount++
      } else  {
        displayFollowed.push( { "followed": listing.twitter_display_name, "followedCount": 1 })
      }
    }

  })
  //console.log("displayFollowed complete is: ")
  //console.dir(displayFollowed)

  displayFollowed.sort((a, b) => (a.followedCount < b.followedCount ? 1 : -1));

  const changeCoinFilter = ( coinValue ) => {
    //console.log("top of changeCoinFilter")
    //console.log(coinValue)
    changeCoinInTab(coinValue)
    setCoinInChart(coinValue)
  }  

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}><Typography variant="h5" sx={{color: theme.palette.grey[700], margin: '10px'}}>Stats From Current VIP Results Page</Typography></Grid>
      <Grid item xs={3}>
        <Stack direction="column">
          <Divider textAlign="left" ><Typography variant="caption" sx={{paddingTop: '1px' }}>#</Typography></Divider>
          <Stack direction='column'>
            {displayHashedWords.map( (hashedWord, index) =>
              <Typography key={"hashed_"+index} onClick={ () => changeHashTab(hashedWord.hashed)} sx={{fontSize: '.75rem', cursor: 'pointer', fontWeight: 500, paddingLeft: '5px', color: theme.palette.primary.main}}>{hashedWord.hashed} ({hashedWord.hashedCount})</Typography>
            )}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="column">
          <Divider textAlign="left" ><Typography variant="caption" sx={{paddingTop: '1px' }}>@mentioned</Typography></Divider>
          <Stack direction='column'>
            {displayAttedWords.map( (attedWord, index) =>
              <Typography key={"mentioned_"+index} onClick={() => changeAtMentionedTab(attedWord.atted)} sx={{fontSize: '.75rem', cursor: 'pointer', fontWeight: 500, paddingLeft: '5px', color: theme.palette.primary.main}}>{attedWord.atted} ({attedWord.attedCount})</Typography>
            )}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Divider textAlign="left" ><Typography variant="caption" sx={{paddingTop: '1px' }}>followed</Typography></Divider>
          {displayFollowed.map( ( followed, index ) =>
            <Typography key={"followed_"+index} onClick={() => changeAtFollowedTab(followed.followed)} sx={{fontSize: '.75rem', cursor: 'pointer', fontWeight: 500, paddingLeft: '5px', color: theme.palette.primary.main}}>{followed.followed} ({followed.followedCount})</Typography>
          )}
      </Grid>
      <Grid item xs={3}>
        <Divider textAlign="left" ><Typography variant="caption" sx={{paddingTop: '1px' }}>coins mentioned</Typography></Divider>
        {displayCoins.map( ( coin, index ) => {
          let label = coin.coin + " (" + coin.coinCount+ ")"
          let coinFontWeight = 500
          if (coin.coin === coinInTab) coinFontWeight = 700
          return <Typography key={"coin_"+index} onClick={() => changeCoinFilter(coin.coin)} sx={{fontSize: '.75rem', cursor: 'pointer', fontWeight: coinFontWeight, paddingLeft: '5px', color: theme.palette.primary.main}}>{label}</Typography>
        })}
      </Grid>
    </Grid>
  )
}

export default VIPTabsStats