import React, {useState, useLayoutEffect} from 'react'
import { Typography, Stack, Backdrop, IconButton } from '@mui/material'
import { v4 as uuidv4 } from 'uuid';
import { KeyboardArrowLeft, KeyboardArrowRight, Close } from '@mui/icons-material'

const prevStyle = {
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    width: 'auto',
    padding: '16px',
    marginTop: '-22px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    transition: '0.6s ease',
    userSelect: 'none',    
    left: 0,
    backgroundColor: 'rgb(255,255,255,0.3)',
    '&:hover': {
        backgroundColor: 'rgb(255,255,255,0.8)'
    }                           
}

const nextStyle = {
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    width: 'auto',
    padding: '16px',
    marginTop: '-22px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    transition: '0.6s ease',
    userSelect: 'none',     
    right: 0,
    backgroundColor: 'rgb(255,255,255,0.3)',
    '&:hover': {
        backgroundColor: 'rgb(255,255,255,0.5)'
    }                                                         
}

const closeStyle = {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    width: 'auto',
    padding: '16px',
    marginTop: '-30px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    transition: '0.6s ease',
    userSelect: 'none',   
    backgroundColor: 'rgb(255,255,255,0.1)' ,
    right: 0,
    '&:hover': {
        backgroundColor: 'rgb(255,255,255,0.5)'
    }                                                         
}    

const numberStyle = {
    color: '#ffffff',
    fontSize: '12px',
    padding: '8px 12px',
    position: 'absolute',
    top: -30,
    left: '5%',
}

const ResearchTwitterResultItemImages = ( {images, tweetID, inDashboardHeight=0, inDashboardWidth=0} ) => {
    //twitter allows a max of 4 images attached to an image....only plan for at most 4
    
    //console.log("top of ResearchTwitterResultItemImages and images is:")
    //console.dir(images)
    //console.log("inDashboardHeight is: " + inDashboardHeight)
    //console.log("inDashboardWidth is: " + inDashboardWidth)
    //console.log("tweetID is:")
    //console.dir(tweetID)

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window

        let returnHeight = height
        if (inDashboardHeight !== 0) returnHeight = inDashboardHeight

        let returnWidth = width
        if (inDashboardWidth !== 0) returnWidth = inDashboardWidth

        //console.log("returnHeight is: " + returnHeight)
        return {
            returnWidth,
            returnHeight
        }
    }  

    const [backdropOpen, setBackdropOpen] = useState(false)
    const [currentImageIndex,setCurrentImageIndex] = useState(0)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    const handleBackdropClick = (e) => {
        e.stopPropagation()
    }

    const handleCloseBackdrop = () => {
        setBackdropOpen(false);
    }

    const handleToggleBackdrop = () => {
        setBackdropOpen(!backdropOpen);
    }

    const handlePrevStep = () => {
        if (currentImageIndex === 0 ) {
            //this is the last image...set first one
            setCurrentImageIndex(images.length - 1)
        } else {
            setCurrentImageIndex(prev => prev - 1)
        }

    }

    const handleNextStep = () => {
        if (currentImageIndex === (images.length - 1) ) {
            //this is the last image...set first one
            setCurrentImageIndex(0)
        } else {
            setCurrentImageIndex(prev => prev + 1)
        }

    }    

    const displayStack = (image1=null, image2=null) => {
        return (

            <Stack display="flex" direction="row" alignItems="center" justifyContent="center"  sx={{}}>
                { image1 !== null && image1 !== '' ?
                    <div 
                        style={{objectFit: 'cover', maxWidth: '150px', maxHeight: '150px', margin: '3px', cursor: 'pointer'}} 
                        onClick={handleToggleBackdrop}
                    >
                        <img
                            src={image1}
                            style={{maxHeight: '150px', maxWidth: '150px', objectFit: 'cover', cursor: 'pointer'}}
                            alt="tweet attachment"
                            loading="lazy"
                            onClick={handleToggleBackdrop}
                        />
                    </div> : null }
                { image2 !== null && image2 !== '' ? 
                    <div 
                        sx={{objectFit: 'cover', maxWidth: '150px', maxHeight: '150px', margin: '3px', cursor: 'pointer'}} 
                        onClick={handleToggleBackdrop}
                    >
                        <img
                            src={image2}
                            style={{maxHeight: '150px', maxWidth: '150px', objectFit: 'cover'}}
                            alt="tweet attachment"
                            loading="lazy"
                            onClick={handleToggleBackdrop}
                        />
                    </div> : null }
            </Stack>
        )
    }

  

    useLayoutEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])    

    //console.log("windowDimensions before return is:")
    //console.dir(windowDimensions)

    if (images.length === 0) {
        return null
    } else if (images.length === 1) {
        return (
            <div key={uuidv4()}>
                <div 
                    style={{objectFit: 'cover', maxWidth: '150px', maxHeight: '150px', margin: '5px', cursor: 'pointer'}}
                    onClick={handleToggleBackdrop}
                >
                    <img
                        key={'homefeedimage'+tweetID}
                        src={images[0]}
                        style={{maxHeight: '150px', maxWidth: '150px', objectFit: 'cover', cursor: 'pointer'}}
                        alt="tweet attachment"
                        loading="lazy"
                        onClick={handleToggleBackdrop}
                    />
                </div>   
                <Backdrop 
                    sx={{ 
                        color: '#fff', 
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={backdropOpen}
                    onClick={handleCloseBackdrop}
                >
                    <div id='divAroundImages' style={{
                        maxWidth: windowDimensions.returnWidth + 'px',
                        maxHeight: windowDimensions.returnHeight + 'px',
                        position: 'relative',
                        margin: 'auto',
                    }}>
                        <img
                            key={'homefeedimage'+tweetID}
                            src={images[0]}
                            style={{
                                maxHeight: (windowDimensions.returnHeight * .8) + 'px', 
                                maxWidth: (windowDimensions.returnWidth * .8) + 'px', 
                                objectFit: 'cover',
                                cursor: 'pointer'
                            }}
                            alt="tweet attachment"
                            loading="lazy"
                            onClick={handleCloseBackdrop}
                        />
                        <IconButton 
                            onClick={handleCloseBackdrop}
                            sx={closeStyle}  
                        >
                            <Close />
                        </IconButton> 
                    </div>                    
                </Backdrop> 
            </div>    
        )    
    } else {    
        //at least 2 or more
        return (
            <div key={uuidv4()}>
                <div>
                    {displayStack(images[0],images[1])}
                    { images.length > 2 ? displayStack(images[2],images[3]) : null}
                </div>
                <Backdrop  
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, }}
                    open={backdropOpen}
                    onClick={handleBackdropClick}
                >
                    <div style={{
                        maxWidth: windowDimensions.returnWidth + 'px',
                        maxHeight: windowDimensions.returnHeight + 'px',
                        position: 'relative',
                        margin: 'auto',
                    }}>
                        {images.map((image,index) => {
                            return (
                                <div key={image + String(index)} style={{
                                        display: parseInt(index) === parseInt(currentImageIndex) ? "block" : "none" ,
                                    }}
                                >
                                    <Typography 
                                        sx={numberStyle}>
                                            {index + 1} / {images.length}
                                    </Typography>
                                    <img src={image} alt="tweet attachments" style={{ maxWidth: '90%', maxHeight: windowDimensions.returnHeight * .7 + 'px' , marginLeft: 'auto', marginRight: 'auto',  display: 'block'}} />
                                </div>
                            )

                        })}

                        <IconButton 
                            onClick={handlePrevStep}
                            sx={prevStyle}
                        ><KeyboardArrowLeft /></IconButton>
                        <IconButton 
                            onClick={handleNextStep}
                            sx={nextStyle}
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                        <IconButton 
                            onClick={handleCloseBackdrop}
                            sx={closeStyle}  
                        >
                            <Close />
                        </IconButton>                        
                    </div>            
                </Backdrop >
            </div>            
        )
    }
    
}
export default ResearchTwitterResultItemImages