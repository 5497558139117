import auth from '../Auth/auth'
import Login from './Login'
import { useNavigate } from "react-router-dom"

const Logout = ( { setLoggedIn, setUserDetails }) => {

  const navigate = useNavigate()

  auth.logout()
  setLoggedIn(false)
  setUserDetails({})
  navigate(0)

  return Login
}
export default Logout