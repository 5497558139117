import React, { useEffect, useState} from 'react'
import { Box, Stack, CssBaseline, Container, CircularProgress, Typography, Grid, Pagination, Divider } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch'
import TopAppBar from '../TopAppBar'

import getAPIURL from '../../utility/getAPIURL'

import UserReferralsItem from './UserReferralsItem'

const UserReferrals = ( {userObject }) => {

    const theme = useTheme()

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            [theme.breakpoints.up('md')]: {
                marginLeft: '20px',
                width: `calc(100% - 20px)`
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
                width: `calc(100% - 20px)`,
                padding: '16px'
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
                width: `calc(100% - 20px)`,
                padding: '16px',
                marginRight: '10px'
            }
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginLeft: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - 20px)`,
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px'
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px'
            }
        })
    }));       

    const [userReferrals,setUserReferrals] = useState([])
    const [userReferralCount,setUserReferralCount] = useState(0)
    const [userReferralPage,setUserReferralPage] = useState(1)
    const [userReferralPageCount,setUserReferralPageCount] = useState(1)
    const [isLoading,setIsLoading] = useState(true)

    useEffect(() => {
        console.log(userObject.user.referral_code)
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            const user_referrals = await fetchData(`api/v1/users/referrals/`, "could not receive user referrals ", fetchParams).promise
            console.log("user_referrals getting user referrals")
            console.dir(user_referrals)
            setUserReferralCount(user_referrals.count)
            setUserReferrals(user_referrals.results)

            if (user_referrals.count > 100) {
                setUserReferralPageCount(Math.ceil(user_referrals.count/100))
            } else {
                setUserReferralPageCount(1)
            }

            setIsLoading(false)
          } catch (error) {
            console.log("error","something is broke getting user details")
          }
        })()
    
      }, [])

    const changeReferralPage =  (event, page) => {
        //console.log("top of changeReferralPage")
        //console.dir(event)
        //console.dir(page)
        setUserReferralPage(page)
    }      

    if (isLoading) return <CircularProgress />
        
    if (userReferralCount > 0) {

        return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* header */}
                <TopAppBar userObject={userObject} selectedPage='' />
                {/* main content */}
                <Main theme={theme} >
                    <Container maxWidth="xl">
                        <Box sx={{width: '100%', paddingTop: '10px', paddingBottom: '20px'}}>
                            <Container component='main' maxWidth='sm' sx={{marginTop: '50px', justifyContent: 'center'}}>
                                <Stack sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <Typography variant='h3' sx={{paddingBottom: '25px'}}>
                                        User Referrals
                                    </Typography>
                                    <Typography variant='caption' sx={{paddingBottom: '15px'}}>
                                        This is a list of users that have used your referral code when they registered.  To get referrals to your account give the following
                                        link to potentials users.  Your referral code is contained in that url and the person using that url to register will automatically
                                        have your referral link automatically filled in at registration.  The person you wish to referral can also just click to register at our
                                        site and manually insert the following referral code into the registration for so you can get credit for the referral.
                                    </Typography>
                                    <Stack direction="row" display="flex" alignItems="center" justifyContent='flex-start' sx={{marginTop: '10px', marginBottom: '10px'}}>
                                        <Typography display="inline" variant='button'>
                                            Registration URL: 
                                        </Typography>  
                                        <Typography display="inline" sx={{color: theme.palette.primary.main, marginLeft: '10px', textTransform: 'lowercase'}}>
                                            {String(getAPIURL()+'/register/?referral=')}
                                        </Typography>
                                        <Typography display="inline" sx={{color: theme.palette.primary.main}}>
                                            {String(userObject.user.referral_code)}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" display="flex" alignItems="center" justifyContent='flex-start' sx={{marginTop: '10px', marginBottom: '10px'}}>
                                        <Typography display="inline" variant='button' >
                                            referral code:
                                        </Typography> 
                                        <Typography display="inline" sx={{color: theme.palette.primary.main, marginLeft: '10px'}}>
                                            {String(userObject.user.referral_code)}
                                        </Typography>
                                    </Stack>
                                    <Typography sx={{color: theme.palette.secondary.main, marginTop: '10px'}}>Clients that have used your referral code: ({userReferralCount})</Typography> 
                                    <Divider sx={{width: '100%', marginTop: '5px', marginBottom: '10px' }}/>
                                    <Stack direction="column" display="flex" alignItems="flex-start">
                                        {userReferrals.map( (referral, index) => <UserReferralsItem key={'userreferralsitem'+referral.user+index} userReferral={referral} />)}
                                    </Stack>
                                    <Stack direction="row" display="flex" justifyContent="flex-start" alignItems="center" sx={{marginTop: '20px'}}>
                                        <Typography variant="button" sx={{marginRight: '20px'}}>Results: {userReferralCount} </Typography>
                                        <Pagination sx={{marginTop: '10px'}} shape="rounded" count={userReferralPageCount} page={userReferralPage} onChange={changeReferralPage}/>
                                    </Stack>
                                </Stack>
                            </Container>
                        </Box>
                    </Container>
                </Main>                
            </Box>

        )
    } else {
        return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* header */}
                <TopAppBar userObject={userObject} selectedPage='' />
                {/* main content */}
                <Main theme={theme} >
                    <Container maxWidth="xl">
                        <Stack sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Typography variant='h3' sx={{paddingBottom: '25px'}}>
                                User Referrals
                            </Typography>
                            <Typography variant='caption' sx={{paddingBottom: '15px'}}>
                                This is a list of users that have used your referral code when they registered.  To get referrals to your account give the following
                                link to potentials users.  Your referral code is contained in that url and the person using that url to register will automatically
                                have your referral link automatically filled in at registration.  The person you wish to referral can also just click to register at our
                                site and manually insert the following referral code into the registration for so you can get credit for the referral.
                            </Typography>
                            <Stack direction="row" display="flex" alignItems="center" justifyContent='flex-start' sx={{marginTop: '10px', marginBottom: '10px'}}>
                                <Typography display="inline" variant='button'>
                                    Registration URL with referral code: 
                                </Typography>  
                                <Typography display="inline"  sx={{color: theme.palette.primary.main, marginLeft: '10px'}}>
                                    {String(getAPIURL()+'/register/?referral=').toLowerCase()+String(userObject.user.referral_code)}
                                </Typography>
                            </Stack>
                            <Stack direction="row" display="flex" alignItems="center" justifyContent='flex-start' sx={{marginTop: '10px', marginBottom: '10px'}}>
                                <Typography display="inline" variant='button' >
                                    referral code:
                                </Typography> 
                                <Typography display="inline" sx={{color: theme.palette.primary.main, marginLeft: '10px'}}>
                                    {String(userObject.user.referral_code)}
                                </Typography>
                            </Stack>
                            <Typography sx={{color: theme.palette.secondary.main, marginLeft: '10px', marginTop: '10px'}}>Clients that have used your referral code: (0)</Typography> 
                            <Box sx={{justifyContent: 'center', marginLeft: '10px', marginTop: '15px'}}>
                                <Typography display="inline" sx={{color: theme.palette.primary.main}}>Once someone has successfully registered using your referral code they will be shown here.</Typography>
                            </Box>
                        </Stack>      
                    </Container>
                </Main>
            </Box>                    
        )  
    }
}

export default UserReferrals