import * as React from 'react';
import { Typography, Stack, Box, Button, IconButton, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import { Grid } from "@material-ui/core"
import { displayUploadedFileORIMageURL } from '../../utility/objHelper'

const ReviewPage = ({
    data,
    handleGoToPage
}) => {
    console.log("top of ReviewPage and data is:")
    console.dir(data)
    const theme = useTheme()

    const ReviewDivider = ({text}) => {
        const theme = useTheme()
        return (
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1, mt: 3}}>
                <Box sx={{width: 200, height: 2, borderRadius: 10, backgroundColor: theme.palette.grey[500]}} />
                <Typography sx={{fontSize: 20, margin: 1, ml: 2, mr: 2, color: theme.palette.grey[500]}}>{text}</Typography>
                <Box sx={{width: 200, height: 2, borderRadius: 10, backgroundColor: theme.palette.grey[500]}} />
            </Box>
        )
    }

    const testIfDate = (passedDate) => {
        //console.log("top of testIfDate and passedDate is: ")
        //console.dir(passedDate)
        let dateObject = new Date(passedDate)
        //console.dir(Object.prototype.toString.call(dateObject) === '[object Date]')
        
        return Object.prototype.toString.call(dateObject) === '[object Date]'
    }

    let tag = null;
    try{
        tag = URL.createObjectURL(data.tagFile[0])
    }catch(e){
        tag = data.tagFile[0]
    }

    let trdc = null
    try{
        trdc = URL.createObjectURL(data.trdcFile[0])
    }catch(e){
        trdc = data.trdcFile[0]
    }
    let trs = null
    try{
        trs = URL.createObjectURL(data.trsFile[0])
    }catch(e){
        trs = data.trsFile[0]
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography align='center' variant="h1" sx={{ mt: 5, mb: 3, color: theme.palette.primary.main }}>
                Review {data.tokenName} data
            </Typography>

            <ReviewDivider text={"Whitepaper"} />
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                {
                    data.whitepaperUrl !== null && data.whitepaperUrl.length > 0 ?
                        <Typography align='center' variant="h3" sx={{ color: theme.palette.grey[400], mr: 3 }}>
                            
                            {data.whitepaperUrl}
                        </Typography>
                        : null
                }
                {data.whitepaperFile !== null && data.whitepaperFile !== '' ?
                    <Box sx={{marginRight: '15px'}}>
                        <Stack direction="column" display="flex" justifyContent="flex-start" alignItems="flex-end" sx={{width: '100%', mr: 2}}>
                            {displayUploadedFileORIMageURL(data.whitepaperFile, false, data.removeWhitepaper , false)}
                            {/*<img src={URL.createObjectURL(data.whitepaperFile[0])} alt="Whitepaper File" width="150" />*/}
                        </Stack>
                    </Box>
                    : null 
                }
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(1)}
                >
                    Edit
                </Button>
            </Box>
            <ReviewDivider text={"Token Allocation Graph"} />
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                {data.tagFile !== null && data.tagFile !== '' ?
                    <Box sx={{marginRight: '15px'}}>
                        <Stack direction="column" display="flex" justifyContent="flex-start" alignItems="flex-end" sx={{width: '100%', mr: 2}}>
                            {displayUploadedFileORIMageURL(data.tagFile, false, data.removeTAGFile , false)}
                        </Stack>
                    </Box>
                    : null 
                }
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(2)}
                    sx={{ ml: 5 }}
                >
                    Edit
                </Button>
            </Box>
            <ReviewDivider text={"Token Release Schedule"} />
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                {data.trsFile !== null && data.trsFile !== '' ?
                    <Box sx={{marginRight: '15px'}}>
                        <Stack direction="column" display="flex" justifyContent="flex-start" alignItems="flex-end" sx={{width: '100%', mr: 2}}>
                            {displayUploadedFileORIMageURL(data.trsFile, false, data.removeTRSFile , false)}
                        </Stack>
                    </Box>
                    : null 
                }
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(3)}
                    sx={{ ml: 5 }}
                >
                    Edit
                </Button>
            </Box>

            <ReviewDivider text={"Token Release Detailed Chart"} />
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                {data.trdcFile !== null && data.trdcFile !== '' ?
                    <Box sx={{marginRight: '15px'}}>
                        <Stack direction="column" display="flex" justifyContent="flex-start" alignItems="flex-end" sx={{width: '100%', mr: 2}}>
                            {displayUploadedFileORIMageURL(data.trdcFile, false, data.removeTRDCFile , false)}
                        </Stack>
                    </Box>
                    : null 
                }
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(4)}
                    sx={{ ml: 5 }}
                >
                    Edit
                </Button>
            </Box>

            <ReviewDivider text={"Token Generation Event"} />
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <Typography align='center' variant="h5" sx={{ color: theme.palette.grey[500], mr: 3 }}>
                    {
                        data.tokenGenDateChoice === "Yes" && data.tokenGenerationDate && testIfDate(data.tokenGenerationDate) ?
                            new Date(data.tokenGenerationDate).toLocaleDateString()
                            :
                            'None'
                    }
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(5)}
                >
                    Edit
                </Button>
            </Box>

            <ReviewDivider text={"Token Releases"} />
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', mr: 3}}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[400] }}>
                            Is the token release schedule published?
                        </Typography>
                        <Typography align='center' variant="h4" sx={{ ml: 2, color: theme.palette.grey[500] }}>
                            {data.isTokenReleasePublished}
                        </Typography>
                    </Box>
                    {
                        data.isTokenReleasePublished === true && (
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[400] }}>
                                    Are tokens released every month on a specific day of the month?
                                </Typography>
                                <Typography align='center' variant="h4" sx={{ ml: 2, color: theme.palette.grey[500] }}>
                                    {data.hasSpecificReleaseDate}
                                </Typography>
                                {
                                    data.hasSpecificReleaseDate === 'Yes' && (
                                        <Typography align='center' variant="h4" sx={{ ml: 2, color: theme.palette.grey[500] }}>
                                            {data.monthlyReleaseDate}
                                        </Typography>
                                    )
                                }
                            </Box>
                        )
                    }

                    {
                        data.isTokenReleasePublished === true && data.hasSpecificReleaseDate !== "Yes" && (
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[400] }}>
                                    Are tokens released every day?
                                </Typography>
                                <Typography align='center' variant="h4" sx={{ ml: 2, color: theme.palette.grey[500] }}>
                                    {data.hasDailyReleaseDate}
                                </Typography>
                            </Box>
                        )
                    }

                    {
                        data.isTokenReleasePublished && data.hasSpecificReleaseDate === "Yes" && (
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[400] }}>
                                    Are tokens released every month?
                                </Typography>
                                <Typography align='center' variant="h4" sx={{ ml: 2, color: theme.palette.grey[500] }}>
                                    {data.hasReleaseEveryMonth}
                                </Typography>
                            </Box>
                        )
                    }

                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(6)}
                    sx={{maxHeight: 60}}
                >
                    Edit
                </Button>
            </Box>

            <ReviewDivider text={'Published Token Dates'} />
            <Box sx={{mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Stack sx={{width: '100%', mr: 3}} direction={'column'} justifyContent={'center'}>
                    {
                        data.publishedTokenDates && data.publishedTokenDates.length && data.publishedTokenDates[0].length ? data.publishedTokenDates[0].split(',').map((item, index) => {
                                let token = item.split("||")
                                if(!token || !token[0] || !token[1]) return null
                                return (
                                    <Grid key={String(item) + String(index)} justify={'space-between'} alignItems={'center'} container xs={12}>
                                        <Grid item xs={4}>
                                            <Typography sx={{color: theme.palette.grey[500], textAlign: 'center', minWidth: 300,}}>
                                                {new Date(token[0]).toLocaleDateString()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography sx={{color: theme.palette.grey[400], textAlign: 'center',minWidth: 100}}>
                                                {token[1]}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography sx={{color: theme.palette.grey[400], textAlign: 'center',minWidth: 100}}>
                                                {token[2] ? token[2] : 'N/A'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            :
                            <Typography align='center' variant="h4" sx={{ ml: 2, color: theme.palette.grey[400] }}>
                                N/A
                            </Typography>
                    }
                </Stack>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(6)}
                >
                    Edit
                </Button>
            </Box>

            <ReviewDivider text={"Seed Price"} />
            <Box sx={{mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', mr: 3}}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[400] }}>
                            Does the token have a seed price or other early investor price?
                        </Typography>
                        <Typography align='center' variant="h4" sx={{ ml: 2, color: theme.palette.grey[500] }}>
                            {data.seedPriceChoice === "Yes" ? 'Yes: ' + data.seedPrice : data.seedPriceChoice === "No" ? 'No' : 'Unknown'}
                        </Typography>
                    </Box>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(7)}
                >
                    Edit
                </Button>
            </Box>

            <ReviewDivider text={"Inflationary"} />
            <Box sx={{mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', mr: 3}}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[400] }}>
                            Is the token non-inflationary?
                        </Typography>
                        <Typography align='center' variant="h4" sx={{ ml: 2, color: theme.palette.grey[500] }}>
                            {data.isNonInflationary ? 'Yes' : 'No'}
                        </Typography>
                    </Box>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(8)}
                >
                    Edit
                </Button>
            </Box>

            <ReviewDivider text={"Staking Rewards"} />
            <Box sx={{mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', mr: 3}}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[400] }}>
                            Does the project offer staking rewards of additional project tokens?
                        </Typography>
                        <Typography align='center' variant="h4" sx={{ ml: 2, color: theme.palette.grey[500] }}>
                            {data.hasStakingRewards}
                        </Typography>
                    </Box>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(9)}
                    sx={{ ml: 5 }}
                >
                    Edit
                </Button>
            </Box>
            <ReviewDivider text={"Vesting"} />
            <Box sx={{mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', mr: 3}}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[400] }}>
                            Has the project vested all of its tokens?
                        </Typography>
                        <Typography align='center' variant="h4" sx={{ ml: 2, color: theme.palette.grey[500] }}>
                            {data.hasVestedTokens}
                        </Typography>
                    </Box>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGoToPage(10)}
                    sx={{ ml: 5 }}
                >
                    Edit
                </Button>
            </Box>
            <Typography sx={{color: theme.palette.primary.main, marginTop: '50px', marginBottom: '10px'}} variant="h4">
                Review all the Information you have submitted so far. Once you are satisfied click
                the 'Submit Token Data' button to submit your information to our site
            </Typography>
        </Box>
    )
}

export default ReviewPage;
