import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, Popper, Paper, ClickAwayListener, CircularProgress } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import Transitions from '../ui-component/extended/Transitions'
import MainCard from '../ui-component/cards/MainCard'

import fetchData from '../../utility/endpointFetch'
import { checkArraysEqual } from '../../utility/objHelper'

import VIPAddCoinScreenerChoices from './VIPAddCoinScreenerChoices'
import { useDispatch, useSelector } from "react-redux";

const VIPMainTabsFullPostScreener = ( {changeInVIPCoins}) => {
    const vipScreenerName = useSelector(state => state.vip.watchlistName)
    //console.log("---------------TOP OF VIPMAINTABSFULLPOSTSCREENER")
    const [open, setOpen] = useState(false);
    const [localUserData,setLocalUserData] = useState({});
    const [isLoading,setIsLoading] = useState(true);
    const [screenerListObject,setScreenerListObject] = useState({});
    //const [currentScreener,setCurrentScreener] = useState([])

    const theme = useTheme()

    const anchorRef = useRef(null)

    const borderDetails = '1px solid '+theme.palette.grey[200]

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    }
    
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open])    

    const checkVIPArraysEqual = (currentCoin, screener,index) => {
        //screener is the "whole coin with loaded data" and cannot compare in that state
        //console.log("top of checkVIPArraysEqual in VIPMainTabsFullPostScreener and screener is:")
        //console.dir(screener)
        //console.dir("index is: " + index)
        let reducedScreener = screener.map(thisScreenerDetails => thisScreenerDetails.coin_id)
        //console.log("reducedScreener is: ")
        //console.dir(reducedScreener)
        //console.log("currentCoin is:")
        //console.dir(currentCoin)
        if (checkArraysEqual(reducedScreener, currentCoin)) {
        //if (Array.isArray(reducedScreener) && Array.isArray(currentCoin) && reducedScreener.length === currentCoin.length && reducedScreener.every((val, index) => currentCoin.includes(val))) {
            //console.log("setting vipScreenerName to: " + index)
            //setVIPScreenerName(index)
            //setCurrentScreener(screener)
            //changeInVIPCoins(reducedScreener)
            setLocalUserData(prevState => ({...prevState, coin: reducedScreener}))
            return true
        } else {
            //console.log("does not match")
            return false
        }            
    }         

    const getNewUserObject = () => {
        setIsLoading(true)
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },            
        }
        ;(async () => {
            try {
                const getUserObject = await fetchData(`api/v1/users/userdata/`, "could not get new user object", fetchParams).promise
                setLocalUserData(getUserObject)
                //setLocalScreenerList(getUserObject.screener_name)
                //setLocalDeletedScreenerList(getUserObject.deleted_screener)
            } catch (error) {
                console.log("something is broke getting new userObject", error)
            }
        })()
    }      

    const setNewCoinsUserObject = (newCoins) => {
        setIsLoading(true)
        //console.log("TOP OF setNewCoinsUserObject and newCoins is:")
        //console.dir(newCoins)
        const fetchSetUserCoinsParams = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    'coin': newCoins
                }
            )
        }
        const errorMessage = 'Error setting new coins for vip filter.  Resubmit.  If still an error please contact support.'
        ;(async () => {
            try {
                const setUserCoinsResponse = await fetchData('api/v1/users/userdata/', errorMessage, fetchSetUserCoinsParams).promise
                //console.log("setUserCoinsResponse is:")
                //console.dir(setUserCoinsResponse)
                //console.log("before setTimeout")
                getNewUserObject()
                setIsLoading(false)
                //await delay(2000)
                
            } catch (error) {
                console.log("something is broke setting new coins within setNewCounsUserObject", error)
            }                
        })()  
    }

    // useEffect(() => {
    //     console.log('vipScreenerName')
    //     console.log(vipScreenerName)
    // }, [vipScreenerName])

    useEffect(() => {
        //get all screeners for this user
        setIsLoading(true)
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
              'Content-Type': 'application/json'
          },            
        }
        ;(async () => {
          try {
            const getUserObject = await fetchData(`api/v1/users/userdata/`, "could not get new user object", fetchParams).promise
            //console.log("getUserObject in useEffect is and setting localUserData to: ")
            //console.dir(getUserObject)
            setLocalUserData(getUserObject)
            
            ;(async () => {
              const getCurrentScreenersResponse = await fetchData(`api/v1/users/screener/`, "could not get all screeners in all vip coins list", fetchParams).promise
              //console.log("getCurrentScreenersResponse getting user data in all vip coins list")
              //console.dir(getCurrentScreenersResponse)

              let objectScreeners = {} //why this needs to be an object instead of array???
              getCurrentScreenersResponse.results.map( coin => {
                  if (!objectScreeners.hasOwnProperty(coin.screener_name)) {
                      objectScreeners[coin.screener_name] = []
                  }
                  objectScreeners[coin.screener_name].push(coin)
              })
              //console.log("objectScreeners in useEffect is:")
              //console.dir(objectScreeners)
              setScreenerListObject(objectScreeners)

              let allScreenersArray = Object.entries(objectScreeners)
              //console.log("-----allScreenersArray is: ")
              //console.dir(allScreenersArray)
              let selectedScreener = allScreenersArray.find((screener, index) => checkVIPArraysEqual(getUserObject.coin, screener[1],screener[0]))  
              //console.log("selelctedScreener is:")
              //console.dir(selectedScreener)
              //setTimeout( function() {
                //console.log("inside setTimeout")
                setIsLoading(false)
              //}, 1000)
            })()
          } catch (error) {
            console.log("something is broke getting new userObject", error)
          }
    
        })()                
    }, [])

    const changeScreenerVIP = (newScreenerName) => {
        if (newScreenerName === 'All Coins') {
          setNewCoinsUserObject([])      
          changeInVIPCoins(newScreenerName, [])
        } else if (screenerListObject.hasOwnProperty(newScreenerName)) {
            const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                'Content-Type': 'application/json'
                },
            }
            ;(async () => {
            try {
                var fetchScreenerCoinsURL = `api/v1/users/screener/?screener_name=${newScreenerName}`
                const screenerCoinsresponse = await fetchData(fetchScreenerCoinsURL, "could not receive screener coin data", fetchParams).promise
                let coinsInList = screenerCoinsresponse.results.map( (coinDetails) => { return coinDetails.coin_id } )
                //console.log("coinsInList  is: ")
                //console.dir(coinsInList)
                
                setNewCoinsUserObject(coinsInList)
                changeInVIPCoins(newScreenerName, coinsInList)
            } catch (error) {
                console.log("something is broke getting screener coins in MyCurrencies: " + error )
                }
            })()
        } else {
            console.log("There are no coins in that screener....will not set")
        }
             
    }

    if (isLoading) {
        return (
            <Box 
                sx={{
                    marginLeft: '10px', 
                    borderRadius: '10px', 
                    border: borderDetails , 
                    backgroundColor: theme.palette.dark.main, 
                    paddingLeft: '10px', 
                    padding: '2px', 
                    paddingRight: '10px', 
                    position: 'relative', 
                    top: '5px', 
                    marginRight: '10px',
                    cursor: 'pointer'
                }}
                onClick={handleToggle}
                ref={anchorRef}
            >
                <CircularProgress size="10" />
            </Box>        
        )
    } else {
        return (
            <Box 
                sx={{
                    marginLeft: '10px', 
                    borderRadius: '10px', 
                    border: borderDetails , 
                    backgroundColor: theme.palette.dark.main, 
                    paddingLeft: '10px', 
                    padding: '2px', 
                    paddingRight: '10px', 
                    position: 'relative', 
                    top: '5px', 
                    marginRight: '10px',
                    cursor: 'pointer'
                }}
                onClick={handleToggle}
                ref={anchorRef}
            >
                <Typography noWrap={true} variant="caption" sx={{fontWeight: '500', marginLeft: '10px', color: theme.palette.grey[500]}}>
                    watchlist: {vipScreenerName}
                </Typography>
                <Popper
                    placement="bottom-end"
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 14]
                                }
                            }
                        ]
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} 
                                        sx={{
                                            backgroundColor: theme.palette.dark[800],
                                            width: '200px',
                                        }}                                
                                    >
                                        <Box 
                                            sx={{
                                                width: '100%',
                                                margin: '15px',
                                            }}
                                        >
                                            <VIPAddCoinScreenerChoices 
                                                localUserData={localUserData}
                                                vipScreenerName={vipScreenerName} 
                                                currentScreeners={screenerListObject}
                                                changeScreenerVIP={changeScreenerVIP}
                                            />
                                        </Box>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        </Transitions>
                    )}  
                </Popper>          
            </Box>
        )
    }
}

export default VIPMainTabsFullPostScreener