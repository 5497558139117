import React from 'react'
import { Grid, Typography, Avatar, Stack, Box } from '@mui/material'
import { IconCurrencyBitcoin, IconTrendingUp, IconTrendingDown, IconCurrencyDollar, IconArrowsLeftRight, IconBuildingBank,
  IconPlus, IconMoodEmpty, IconHandStop, IconArrowDownRightCircle, IconArrowUpRightCircle } from '@tabler/icons'

import HealthDisplayMVRV from './HealthDisplayMVRV'
import HealthDisplayFearAndGreed from './HealthDisplayFearAndGreed'
import HealthDisplayFundingRates from './HealthDisplayFundingRates'
import HealthDisplayNUPL from './HealthDisplayNUPL'
import HealthDisplayOpenInterest from './HealthDisplayOpenInterest'
import { useTheme } from '@mui/material/styles'

const HealthDisplayDefault = ( {btcData, ruleThemAll} ) => {

    const theme = useTheme()

    var currentBTCStats = ruleThemAll[0]
    var yesterdaysBTCStats = ruleThemAll[1]

    const abbreviatedPrice = (price) => {
        if (price > 1) {
          return price.toFixed(2)
        } else if (price < 1 ) {
          return price
        } else {
          //just display the full amount
          return price
        }
    }

    //defaults in case of null data
    var priceColor = theme.palette.primary.main
    var colorOfPriceBox = theme.palette.primary.light
    var PriceTrendingIcon = <IconTrendingUp color={priceColor} size={25}/>
    var percentPriceChange24h = 0

    var volumeColor = theme.palette.primary.main
    var colorOfVolumeBox = theme.palette.primary.light
    var VolumeTrendingIcon = <IconTrendingUp color={priceColor} size={25}/>
    var percentVolumeChange24h = 0

    if ((yesterdaysBTCStats !== undefined) && (currentBTCStats !== undefined)) {
        priceColor = theme.palette.grey[800]
        colorOfPriceBox = theme.palette.success.dark
        PriceTrendingIcon = <IconTrendingDown color={priceColor} size={25}/>
        if (currentBTCStats.price < yesterdaysBTCStats.price) {
        priceColor = theme.palette.grey[500]
        colorOfPriceBox = theme.palette.error.dark
        PriceTrendingIcon = <IconTrendingDown color={priceColor} size={25}/>
        }

        percentPriceChange24h = Math.abs(btcData.current_price - yesterdaysBTCStats.price) / yesterdaysBTCStats.price * 100

        volumeColor = theme.palette.grey[800]
        colorOfVolumeBox = theme.palette.success.dark
        VolumeTrendingIcon = <IconTrendingUp color={priceColor} size={25}/>
        if (currentBTCStats.price < yesterdaysBTCStats.price) {
        volumeColor = theme.palette.grey[500]
        colorOfVolumeBox = theme.palette.error.dark
        VolumeTrendingIcon = <IconTrendingDown color={priceColor} size={25}/>
        }

        percentVolumeChange24h = Math.abs(btcData.total_volume - yesterdaysBTCStats.total_volume) / yesterdaysBTCStats.total_volume * 100
    } 

    const abbreviatedValue = (value) => {
        if (value > 1000000000) {
        return ((value / 1000000000).toFixed(2)) + 'B'
        } else if (value > 1000000) {
        return ((value/1000000).toFixed(2)) + 'M'
        } else if (value > 1000) {
        return ((value/1000).toFixed(2)) + 'K'
        } else {
        //just display the full amount
        return value
        }
    }  

   

  return (
    <Grid container spacing={1}>
      <Grid item xs={4} >
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-around"
          sx={{backgroundColor: colorOfPriceBox, padding: '5px', borderRadius: '5px', minHeight: '50px'}}
        >
          <IconCurrencyDollar size={30} color={priceColor}/>
          <Stack direction="column" alignItems="center" justifyContent="space-around">
            <Stack direction="row" alignItems="center"><Typography variant="h4" color={priceColor}>{abbreviatedPrice(btcData.current_price)}</Typography> {PriceTrendingIcon} <Typography variant="h4" color={priceColor} sx={{marginLeft: '5px'}}>{percentPriceChange24h.toFixed(2)} %</Typography></Stack>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-around"
          sx={{backgroundColor: colorOfVolumeBox, padding: '5px 10px', borderRadius: '5px', minHeight: '50px'}}
        >
            <Typography>Vol 24h</Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="h4" color={volumeColor} >$ {abbreviatedValue(btcData.total_volume)} USD</Typography> 
              {VolumeTrendingIcon} 
              <Typography variant="h4" color={volumeColor} sx={{marginLeft: '5px'}}> {percentVolumeChange24h.toFixed(2)} %</Typography>
            </Stack>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction="row" alignItems="center" justifyContent="space-around" sx={{backgroundColor: theme.palette.dark.dark, borderRadius: '5px', minHeight: '50px'}}>
          <Stack direction="column" alignItems="center" sx={{backgroundColor: theme.palette.dark.dark}}>
            <Typography >Mkt</Typography>
            <Typography >Cap</Typography>
          </Stack>
          <Stack direction="row" alignItems="center"><Typography variant="h4">$ { currentBTCStats !== undefined ? abbreviatedValue(currentBTCStats.market_cap) : 0} USD</Typography></Stack>
        </Stack>
      </Grid> 
      <Grid item xs={3}>
        <HealthDisplayFearAndGreed 
          ruleThemAll={ruleThemAll}
        />
      </Grid>
      <Grid item xs={3}>
        <HealthDisplayMVRV 
          ruleThemAll={ruleThemAll}
        />
      </Grid>
      <Grid item xs={3}>
        <HealthDisplayFundingRates 
          ruleThemAll={ruleThemAll}
        />
      </Grid>
      <Grid item xs={3}>
        <HealthDisplayNUPL 
          ruleThemAll={ruleThemAll}
        />
      </Grid>   
      <Grid item xs={12}>
        <HealthDisplayOpenInterest
          ruleThemAll={ruleThemAll}
        />        
      </Grid>               
    </Grid>       
  )
}

export default HealthDisplayDefault