import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconCircle } from '@tabler/icons';

const LeverageRatio = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About Leverage Ratio</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - MVRV Ratio">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
                Estimated Leverage Ratio (ELR) is defined as the ratio of open interest divided by the reserve of an exchange.
              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                Estimated Leverage Ratio indicates how much leverage is used by users on average. <br /><br />
                ELR for a derivative exchange tells us how much leverage is used by users on average. This information measures traders' sentiment whether they take a high risk or low risk.
              </Typography>
              <Typography variant="h4">By Value Itself</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                It could be interpreted as the degree of leverage of the market
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                  <ListItemIcon>
                      <IconCircle />
                    </ListItemIcon>
                    <ListItemText
                      primary="High : Over Leveraged Market+ Possible Volatility"
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconCircle />
                    </ListItemIcon>
                    <ListItemText
                      primary="Low : Low leveraged market"
                    />
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="h4">By examining trend</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                It shows the sentiment or tendency of total investors' urge to use leverage.
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconCircle />
                    </ListItemIcon>
                    <ListItemText
                      primary="Increasing trend : Holding More Leverage"
                      secondary="Increasing in values indicates more investors are taking high leverage risk in the derivatives trade.
                      Also, if ELR value is high compared to the last couple of days, it indicates traders are quite confident in their positions. "
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconCircle  />
                    </ListItemIcon>
                    <ListItemText
                      primary="Decreasing trend: Taking off Leverage"
                      secondary="Decreasing in values indicates more investors are taking off leverage risk in the derivatives trade.
                      Also, if ELR value is low compared to the last couple of days, it indicates traders are changing their views or finishing their positions. "
                    />
                  </ListItem>
                </List>
              </Typography>
            </TabPanel>

          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default LeverageRatio