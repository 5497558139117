import React from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const UserReferralsItem = ( {userReferral} ) => {

    const theme = useTheme()

    return (
        <Typography variant="body1" sx={{color: theme.palette.grey[400], marginTop: '15px'}}>{userReferral.username}</Typography>
    )
}

export default UserReferralsItem