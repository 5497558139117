const getAPIURL = () => {
  // remove current port and point to api....may not need this as port 5000 already configured
  const urlParts = window.location.host.split(':')
  //console.log("urlParts is:")
  //console.dir(urlParts)
  var mode = "production"
  if (mode ===  "production"){
    //console.log("MODE IS PRODUCTION")
    if (urlParts[0] === "localhost"){
      //running in local environment...api is not at localhost...use hardcoded api url
      return `https://alphacentoro.com/`
    }
    else {
      return `https://${urlParts[0]}`
    }
  }
  else if (mode == "beta"){
    //console.log("MODE IS BETA")
    if (urlParts[0] === "localhost"){
      //running in local environment...api is not at localhost...use hardcoded api url
      return `https://localhost:8080`
    }
    else {
      return `https://${urlParts[0]}:8080`
    }
  }
  else{
    //console.log("MODE IS NOT PRODUCTION")
    if (urlParts[0] === "localhost"){
      //running in local environment...api is not at localhost...use hardcoded api url
      return `http://localhost:8443`
      return `http://192.168.150.2:8443`
      //http://192.168.150.21:8080`
    }
    else {
      return `http://${urlParts[0]}:8443`
    }
  }

}

export default getAPIURL
