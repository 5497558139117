import { SET_COLUMN_1_HANDLES, SET_COLUMN_2_HANDLES, SET_COLUMN_3_HANDLES, SET_SELECTED_WATCHLIST_NAME } from "./types";

export const setColumnOneHandles = handles => (
    {
        type: SET_COLUMN_1_HANDLES,
        payload: handles
    }
)

export const setColumnTwoHandles = handles => (
    {
        type: SET_COLUMN_2_HANDLES,
        payload: handles
    }
)

export const setColumnThreeHandles = handles => (
    {
        type: SET_COLUMN_3_HANDLES,
        payload: handles
    }
)

export const setSelectedWatchlistName = handles => (
    {
        type: SET_SELECTED_WATCHLIST_NAME,
        payload: handles
    }
)