import React from 'react'
import { Grid, Stack, Typography, Box } from '@mui/material'

import { abbreviateNumber } from "js-abbreviation-number"
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'

const InfluencerBodyResultItem = ( {coinDetails, influencerDetails, showCoinTweetAndChart }) => {
    //console.log("top of InfluencerBodyResultItem and coinDetails is:")
    //console.dir(coinDetails)
    //console.dir(influencerDetails)

    const theme = useTheme()

    if (coinDetails === undefined) return null

    const typographyStyle = {position: 'relative', top: '4px' }

    const displayLargeNumber = (value) => {
        if (value > 0 )
          return abbreviateNumber(value, 2, {padding: false, symbols: ["", "k", "M", "B", "T", "P", "E"]} )
        else
          return value
    }

    const displayPrice = (price) => {
        if (price && price.toString().includes('e')) {
          //this has scientific notation
            return price.toFixed(price.toString().split('-')[1]);
        } else {
          return price ? price : '--'
        }
    }    

    var priceColor = theme.palette.success.main
    var priceIcon = <ArrowUpward fontSize="small" sx={{ position: 'relative', top: '6px'}}/>
    if (coinDetails.day_1_price_change < 0) {
        priceColor = theme.palette.error.main
        priceIcon = <ArrowDownward fontSize="small" sx={{ position: 'relative', top: '6px'}}/>
    }

    var percentBTCDiffColor = theme.palette.success.dark
    var negPosBTCPercentage = '+'
    if (coinDetails.btc_24h_dif < 0) {
        percentBTCDiffColor = theme.palette.error.dark
        negPosBTCPercentage = '-'
    }    

    
    return (
        <Grid container spacing={2}
            sx={{
                width: '99%', 
                borderRadius: '5px', 
                backgroundColor: theme.palette.dark[800], 
                '&:hover': {backgroundColor: theme.palette.dark.dark},
                boxShadow: 1,
                marginBottom: '5px', marginLeft: '3px', marginTop: '5px',paddingBottom: '15px',
                cursor: 'pointer'
            }}
            onClick={()=>showCoinTweetAndChart(coinDetails)}
        >
            <Grid item xs={3}>
                <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" >
                    <Typography sx={{position: 'relative', top: '2px',marginRight: '15px'}}><img src={ coinDetails.image_thumbnail !== null ? coinDetails.image_thumbnail : null } alt={coinDetails.coin} /></Typography>
                    <Typography noWrap={true} sx={{fontWeight: '800'}}>{coinDetails.coin} ({coinDetails.coin_symbol})</Typography>
                </Stack>
            </Grid>
            {/*<Grid item xs={1}><Typography sx={{position: 'relative', top: '5px'}}>{influencerDetails.daily_tweets}</Typography></Grid>*/}
            <Grid item xs={1}><Typography sx={{position: 'relative', top: '5px'}}>{influencerDetails.total_tweets.value}</Typography></Grid>
            <Grid item xs={1}><Typography sx={{position: 'relative', top: '5px'}}>{parseInt(influencerDetails.average_favorites.value)}</Typography></Grid>
            <Grid item xs={1}><Typography sx={{position: 'relative', top: '5px'}}>{parseInt(influencerDetails.average_retweets.value)}</Typography></Grid>
            <Grid item xs={1}><Typography sx={{position: 'relative', top: '5px'}}>{!isNaN(parseFloat(coinDetails.social_rank).toFixed(2)) ? parseFloat(coinDetails.social_rank).toFixed(2) : 'x'}</Typography></Grid>
            <Grid item xs={1}><Typography sx={{position: 'relative', top: '5px'}}>{coinDetails.total_social_engagement}</Typography></Grid>
            <Grid item xs={2}>
                    <Typography noWrap={true} sx={{color: priceColor}}>$ {displayPrice(coinDetails.current_price)} USD ({priceIcon} {parseFloat(coinDetails.day_1_price_change).toFixed(2)} %)</Typography>
         
            </Grid>
            <Grid item xs={1}>
                <Typography sx={{color: percentBTCDiffColor,position: 'relative', top: '5px'}} >{negPosBTCPercentage}{!isNaN(parseFloat(coinDetails.btc_24h_dif)) ? parseFloat(coinDetails.btc_24h_dif).toFixed(2) : '--'}</Typography>                
            </Grid>
            <Grid item xs={1}><Typography sx={{position: 'relative', top: '5px'}}>${displayLargeNumber(coinDetails.market_cap)}</Typography></Grid>
        </Grid>
    )
}
export default InfluencerBodyResultItem