import React, { useState } from 'react'

import { Box, Typography, Stack, Divider, containerClasses } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'

import VIPMainTabsAlertTabs from '../VIP/VIPMainTabsAlertTabs'

const VIPAlertsDisplay = ( { panelID, deleteBox, coinToDependents, localVIPCoins=[], boxHeight, rowHeight, boxWidth, selectedTab='1', changeTabInLayout} ) => {

    const theme = useTheme()

    //this is a dummy function and will not do anything...yet or ever
    const changeAtFollowedTab = () => {
        //there is no "followed tab" within the dashboard yet....and probably never
    }

    //this is a dummy function and will not do anything...yet or ever
    const changeAtMentionedTab = () => {
        //there is no "mentioned tab" within the dashboard yet....and probably never
    }    

    const changeHashTab = () => {
        //there is no "hash tab" within the dashboard yet....and probably never
    }    

    const changeDateInChart = () => {
        //not sure will implement this in the dashboard
    }

    const changeCoinInTab = () => {

    }

    const changeVIPTabInLayout = (newTabValue) => {
        console.log("top of changeVIPTabInLayout and newTabValue is: " + newTabValue + " and panelID is: " + panelID)
        changeTabInLayout(newTabValue, panelID)
    }

    const setCoinInChart = (coin) => {
        console.log("top of setCoinInChart and coin is:")
        console.dir(containerClasses)
        coinToDependents(coin)      
    }

    const showOfficialTweets = () => {

    }

    const removeVIPAlertsBox = () => {
        console.log("top of removeVIPAlertsBox and panelID is: " + panelID)
        deleteBox(panelID)
    }

    return (
        <Box sx={{ display: 'flex', width: 'calc(100% - 10px)', marginLeft: '5px' }}>
            <Stack direction="column" display="flex" alignItems='left' sx={{width: '100%'}}>
                <Stack direction="row" display="flex" alignItems='center' justifyContent='space-between' sx={{marginTop: '5px', marginRight: '5px'}}>
                    <Stack direction="row" display="flex" alignItems='center' justifyContent='flex-start' >
                        <Typography display="inline" sx={{fontWeight: 400}} color={theme.palette.grey[300]}>{"VIP Alerts"}</Typography>
                        {/*<Typography display="inline" sx={{fontWeight: 600, marginLeft: '5px'}} color={theme.palette.primary.main}>{coinTweetQueryStringLabel}</Typography>*/}
                    </Stack>
                    <Stack direction="row" justifyContent='flex-end'>  
                        <VscMove 
                            className='draggable-handle' 
                            color={theme.palette.warning.dark} 
                            size={18} 
                            style={{cursor: 'grabbing'}}
                        />
                        <MdCancel 
                            className='draggable-cancel' 
                            color={theme.palette.secondary.dark} 
                            size={18} 
                            onClick={removeVIPAlertsBox}
                            style={{cursor: 'pointer'}}
                        />

                    </Stack>
                </Stack>   
                <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>     
                <VIPMainTabsAlertTabs
                    setCoinInChart={setCoinInChart}
                    changeAtFollowedTab={changeAtFollowedTab}
                    changeAtMentionedTab={changeAtMentionedTab}
                    changeHashTab={changeHashTab}
                    changeCoinInTab={changeCoinInTab}
                    changeDateInChart={changeDateInChart}   
                    showOfficialTweets={showOfficialTweets}  
                    localVIPCoins={localVIPCoins}  
                    divHeight={boxHeight * rowHeight}
                    boxWidth={boxWidth}
                    selectedTab={selectedTab} 
                    changeVIPTabInLayout={changeVIPTabInLayout}                 
                />

            </Stack>
        </Box>   
    )
}

export default VIPAlertsDisplay