import React from 'react'
import { Grid, Typography, Stack, Box, Tooltip, IconButton } from '@mui/material'
import { AreaChart, BarChart, Bar, XAxis, YAxis, Area, Line, LineChart, ResponsiveContainer, LabelList  } from "recharts"

import { IconArrowUpRightCircle } from '@tabler/icons'
import { HelpOutline } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'
const QuickMinerReserve = ( { ruleThemAll, changeTab } ) => {

  const theme = useTheme()

  const abbreviateValue = (flowValue) => {
    if (parseFloat(flowValue) > 1000000000) {
      return ((parseFloat(flowValue) / 1000000000).toFixed(0)) + 'B'
    } else if (parseFloat(flowValue) > 1000000) {
      return ((parseFloat(flowValue)/1000000).toFixed(0)) + 'M'
    } else if (parseFloat(flowValue) > 1000) {
      return ((parseFloat(flowValue)/1000).toFixed(0)) + 'K'
    } else {
      //just display the full amount
      return parseFloat(flowValue).toFixed(2)
    }
  }

  //var minerReserveData = ruleThemAll.slice((ruleThemAll.length-8), (ruleThemAll.length-1)) //as endpoint provides newest first this does not work
  var minerReserveData = ruleThemAll.slice(0, 7) //as data is latest first ....this does not work

  minerReserveData.reverse()

  if (minerReserveData.length === 0) {
    return (
      <Box sx={{width: '100%'}} >
        <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[500]}}>Miner Reserve/Flows</Typography>
        <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
      </Box>
    )
  } else {    

    //console.log("minerReserveData is:")
    //console.dir(minerReserveData)

    var rechartsMinerReserveData = minerReserveData.map( (data) => {
      return { 'name': 0, 'value': data.healthData.miner_reserve , 'amt': data.healthData.miner_reserve, "date": data.healthData.timestamp }
    })

    //get data range for reserve data
    var minMaxReserve = minerReserveData.map( (data) => {
      if (data.healthData)
        return data.healthData.miner_reserve
    })

    var reserveMax = minMaxReserve.reduce((a, b) => { return Math.max(a, b) })
    var reserveMin = minMaxReserve.reduce((a, b) => { return Math.min(a, b) })

    //console.log("reserveMax is " + reserveMax)
    //console.log("reserveMin is " + reserveMin)

    var rechartsMinerInflow = minerReserveData.map( (data) => {
      if (data.healthData)
        return { 'name': 0, 'value': data.healthData.miner_inflow, 'amt': data.healthData.miner_inflow, "date": data.healthData.timestamp }
      else
        return { 'name': 0, 'value': null , 'amt': null, "date": null }
    })

    //get data range for inflow data
    var minMaxInflow = minerReserveData.map( (data) => {
      if (data.healthData)
        return data.healthData.miner_inflow
    })

    //console.log("minMaxInflow is: ")
    //console.dir(minMaxInflow)

    //for now this does not work as sometimes healthdata is not there for a few of them
    //var inflow7dTotal = minMaxInflow.reduce((a, b) => a + b , 0);

    var inflow7dTotal = 0
    minMaxInflow.map( inflowValue => inflowValue != undefined ? inflow7dTotal = inflow7dTotal + inflowValue : null)

    var inflowMax = minMaxInflow.reduce((a, b) => { return Math.max(a, b) })
    var inflowMin = minMaxInflow.reduce((a, b) => { return Math.min(a, b) })

    var rechartsMinerOutflow = minerReserveData.map( (data) => {
      if (data.healthData)
        return { 'name': 0, 'value': data.healthData.miner_outflow, 'amt': data.healthData.miner_outflow, "date": data.healthData.timestamp }
      else
        return { 'name': 0, 'value': null , 'amt': null }
    })

    //get data range for outflow data
    var minMaxOutflow = minerReserveData.map( (data) => {
      if (data.healthData)
        return data.healthData.miner_outflow
    })

    //for now this does not work as sometimes healthdata is not there for a few of them
    //var outflow7dTotal = minMaxOutflow.reduce((a, b) => a + b, 0);

    var outflow7dTotal = 0
    minMaxOutflow.map( outflowValue => outflowValue != undefined ? outflow7dTotal = outflow7dTotal + outflowValue : null)

    var outflowMax = minMaxOutflow.reduce((a, b) => { return Math.max(a, b) })
    var outflowMin = minMaxOutflow.reduce((a, b) => { return Math.min(a, b) })

    const displayQuickDate = (timestamp) => {
      let dateOfData = new Date(timestamp)
      return dateOfData.getMonth() + '/' + dateOfData.getDate()
    } 

    return (
      <Stack
        direction="column"
        sx={{backgroundColor: theme.palette.dark.dark, margin: '10px', padding: '5px', paddingTop: '0px', borderRadius: '5px'}}>
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>Miner Reserve/Flows</Typography>
        <Tooltip title='click for Miner Reserve chart'  ><IconButton size="small" aria-label="full chart" onClick={() => changeTab('5')}>< IconArrowUpRightCircle size={18} color={theme.palette.grey[500]} /></IconButton></Tooltip>
        </Stack>
        <Stack direction="row" display="flex" justifyContent="space-around">
          <Box
            sx={{width: '49%', backgroundColor: theme.palette.dark.main, cursor: 'pointer'}}
            onClick={() => changeTab('5')}
          >
            <Stack direction="column" display="flex" justifyContent="flex-end">
              <Stack
                direction="row"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="caption" sx={{marginLeft: '5px'}}>reserve</Typography>
                <Stack direction="row" display="flex" alignItems="center">
                  <Tooltip title="The USD value of coins held by the affiliated miners' wallets.
                  This value indicates the reserve miners are holdings that are not yet sold.
                  When miners start selling, it could lead to price drop."  >
                    <IconButton size="small" aria-label="full chart">< HelpOutline sx={{fontSize: '0.9rem'}} /></IconButton>
                  </Tooltip>               
                  <Tooltip title='click for full Miner Reserve chart'  ><IconButton size="small" aria-label="full chart" onClick={() => changeTab('5')}>< IconArrowUpRightCircle size={15} /></IconButton></Tooltip>
                </Stack>
              </Stack>
              <Stack direction="column" display="flex" justifyContent="flex-end">
                <ResponsiveContainer width='97%' height={90}>
                  <LineChart
                    data={rechartsMinerReserveData}
                  >
                    <XAxis
                      dataKey="date"
                      tickFormatter={ (value) => displayQuickDate(value)}
                      style={{fontSize: '.6rem'}}
                      domain={[ minerReserveData[0].timestamp, minerReserveData[6].timestamp]}                  
                    />
                    <YAxis 
                      padding={{left: 50}}
                      domain={[reserveMin,reserveMax]} 
                      tick={{stroke: '#888', strokeWidth: .5}}
                      tickFormatter={(value) => abbreviateValue(value)}
                      orientation='right'
                    />                
                    <Line tickLine={true} strokeWidth={2} type="monotone" dataKey="value" dot={false} stroke={theme.palette.primary.dark} />
                  </LineChart>
                </ResponsiveContainer>
              </Stack>
            </Stack>
          </Box>
          <Box
            sx={{width: '24%', backgroundColor: theme.palette.dark.main, cursor: 'pointer'}}
            onClick={() => changeTab('6')}
          >
            <Stack direction="column" display="flex" justifyContent="flex-end" >
              <Stack
                direction="row"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="caption" sx={{marginLeft: '5px'}}>inflows</Typography>
                <Stack direction="row" display="flex" alignItems="center">
                  <Tooltip title="The total amount of coins transferred to the affiliated miners' wallets.
                  It shows the amount of coins that are received as a reward for mining and the coins they purchased."  >
                    <IconButton size="small" aria-label="full chart">< HelpOutline sx={{fontSize: '0.9rem'}} /></IconButton>
                  </Tooltip>               
                  <Tooltip title='click for full Miner Reserve Inflows chart'  ><IconButton size="small" aria-label="full chart" onClick={() => changeTab('6')}>< IconArrowUpRightCircle size={15} /></IconButton></Tooltip>
                </Stack>
              </Stack>
              <ResponsiveContainer width='99%' height={60}>
                <BarChart
                  data={rechartsMinerInflow}
                >
                  <XAxis 
                    hide={true}
                    dataKey="date"
                    position="insideTop"
                    style={{color: "gray", fontSize: '.6rem'}}
                    tickFormatter={(value) => displayQuickDate(value)}
                  />
                  <YAxis 
                    hide={true}
                    domain={[inflowMin,inflowMax]}
                  />
                  <Bar dataKey="value" fill={theme.palette.primary.dark} >
                    {/* <LabelList dataKey="value" position="top" formatter={(value) => abbreviateValue(value)} style={{color: '#FFF', fontSize: '.6rem'}} angle="90" /> */}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <Box sx={{width: '100%', paddingLeft: '5px', minHeight: '3vh', paddingRight: '5px', height: '100%', backgroundColor: theme.palette.dark[800]}}>
                <Stack direction="row" display="flex" alignItems="baseline" justifyContent="space-around" >
                  <Typography display="inline" sx={{fontSize: '.7rem'}}>7d total:</Typography>
                  <Typography variant="button" sx={{fontSize: '.9rem', fontWeight: 700}}>{abbreviateValue(inflow7dTotal)} BTC</Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{width: '24%', backgroundColor: theme.palette.dark.main, cursor: 'pointer'}}
            onClick={() => changeTab('7')}
          >
            <Stack direction="column" display="flex" justifyContent="flex-end">
              <Stack
                direction="row"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="caption" sx={{marginLeft: '5px'}}>outflow</Typography>
                <Stack direction="row" display="flex" alignItems="center">
                  <Tooltip title="Exchange Outflow is defined as an amount of coin withdrawal from the exchange wallets.  We define mining pool wallets in our metrics as all participants in the mining pool including individual miners. Miner Outflow includes Miner sending their coins to the exchanges as well as internal transfers or to other entities."  >
                    <IconButton size="small" aria-label="full chart">< HelpOutline sx={{fontSize: '0.9rem'}} /></IconButton>
                  </Tooltip>               
                  <Tooltip title='click for full Miner Reserve Outflows chart'  ><IconButton size="small" aria-label="full chart" onClick={() => changeTab('7')}>< IconArrowUpRightCircle size={15} /></IconButton></Tooltip>
                </Stack>
              </Stack>
              <ResponsiveContainer width='99%' height={60}>
                <BarChart
                  data={rechartsMinerOutflow}
                >
                  <XAxis hide={true} />
                  <YAxis hide={true} domain={[outflowMin,outflowMax]}/>
                  <Bar dataKey="value" fill={theme.palette.primary.dark} />
                </BarChart>
              </ResponsiveContainer>
              <Box sx={{width: '100%', paddingLeft: '5px', minHeight: '3vh', paddingRight: '5px', height: '100%', backgroundColor: theme.palette.dark[800]}}>
                <Stack direction="row" display="flex" alignItems="baseline" justifyContent="space-around" >
                  <Typography display="inline" sx={{fontSize: '.7rem'}}>7d total:</Typography>
                  <Typography variant="button" sx={{fontSize: '.9rem', fontWeight: 700}}>{abbreviateValue(outflow7dTotal)} BTC</Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    )
  }
}

export default QuickMinerReserve