import React, {useEffect, useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
    TextField,
    Popover,
    Typography,
    Button,
    Step,
    Stepper,
    Box,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Stack,
    StepButton,
    StepLabel,
    CircularProgress,
    IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import fetchData from '../../utility/endpointFetch';
import WhitepaperPage from './WhitepaperPage';
import WelcomePage from './WelcomePage';
import TokenAllocationGraphPage from './TokenAllocationGraphPage';
import TokenWizardHelperText from './TokenWizardHelperText';
import TokenReleaseSchedule from './TokenReleaseSchedule';
import TokenReleaseDetails from './TokenReleaseDetails'
import ReviewPage from "./ReviewPage";
import { useTheme } from '@mui/material/styles'
import { Grid } from "@material-ui/core";

const PublishedTokenDates = ({pageData, setPageData}) => {
    const theme = useTheme();
    const [dateField, setDateField] = useState(new Date(Date.now()))
    const [numTokensField, setNumTokensField] = useState(pageData.numTokens)
    const [categoryField, setCategoryField] = useState(pageData.category)
    const [publishedTokenDates, setPublishedTokenDates] = useState([])

    useEffect(() => {
        setPublishedTokenDates(
            pageData.publishedTokenDates && pageData.publishedTokenDates.length ?
                pageData.publishedTokenDates
                :
                ['']
        )
    }, [pageData.publishedTokenDates])

    const removeDate = (index) => {
        let arr  = publishedTokenDates[0].length ? publishedTokenDates[0].split(',') : []
        if(arr.length){
            arr.splice(index, 1)
        }
        let finalString = ''
        let i = 0;
        for(let a of arr){
            finalString += a
            if(i < arr.length - 1){
                finalString += ','
            }
        }
        setPageData({
            ...pageData,
            publishedTokenDates: [finalString]
        })
    }
    
    const addDate = (item) => {
        let temp = (publishedTokenDates && publishedTokenDates.length) ? publishedTokenDates : ['']
        if(temp[0].length) {
            temp[0] += (',' + item + '||' + numTokensField + "||" + categoryField)
        }else{
            temp[0] += (item + '||' + numTokensField + "||" + categoryField)
        }
        setPageData({
            ...pageData,
            publishedTokenDates: temp
        })
    }
    
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant="h2" sx={{color: theme.palette.primary.main, marginTop: '20px'}}>Published Token Dates</Typography>
            <Stack direction="row" display='flex' alignItems='center'>
                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500] }}>
                    Does this token have published dates that it will be released?
                </Typography>
                <TokenWizardHelperText
                    title={'Published Token Dates'}
                    text={'Token Release Date: date when tokens are publicized to be released.\nAmount of Tokens: amount of tokens to be released on the given date.\nCategory: tokens areoften released from a specific category like \'Investors\', \'Team\', or \'Community\'. This is an optional field.'} />
            </Stack>
            <Stack direction={'column'} spacing={1}>
                <Stack direction={'row'} spacing={1} sx={{mt: 3, mb: 3}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Token Release Date"
                            inputFormat="yyyy-MM-dd"
                            value={dateField}
                            onChange={(newValue) => {
                                //console.log("top of onChange for desktopdatepicker")
                                setDateField(newValue)
                            }}
                            variant='outlined'
                            mask="____-__-__"
                            color="white"
                            renderInput={
                                (params) => <TextField {...params} />
                            }
                        />
                    </LocalizationProvider>
                    <TextField
                        id="numTokens"
                        type="number"
                        label="Number of Tokens"
                        inputProps={
                            {
                                step: 1
                            }
                        }
                        color={'primary'}
                        value={numTokensField}
                        onChange={event => setNumTokensField( event.target.value)}
                        style={{width: 300}}
                    />
                    <TextField
                        id="category"
                        type="text"
                        label="Enter Category (Optional)"
                        color={'primary'}
                        value={categoryField}
                        onChange={event => setCategoryField(event.target.value)}
                        style={{width: 300}}
                    />
                    <Button onClick={() => addDate(dateField.toISOString())}><AddIcon /></Button>
                </Stack>
                {
                    publishedTokenDates && publishedTokenDates.length ? (
                        <React.Fragment>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography sx={{color: theme.palette.primary.main, textAlign: 'center'}}>
                                        Release Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{color: theme.palette.primary.main, textAlign: 'center'}}>
                                        Number of Tokens
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{color: theme.palette.primary.main, textAlign: 'center'}}>
                                        Category
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                    :
                    null
                }
                {
                    publishedTokenDates && publishedTokenDates.length && publishedTokenDates[0].length ? publishedTokenDates[0].split(',').map((item, index) => {
                        let token = item.split("||")
                        if(!token || !token[0] || !token[1]) return null
                        return (
                            <Stack key={String(item) + String(index)} direction={'row'} spacing={1} sx={{mt: 3, flex: 1}} justifyContent={'space-around'} alignItems={'center'}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography sx={{color: theme.palette.grey[500], textAlign: 'center'}}>
                                            {new Date(token[0]).toLocaleDateString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{color: theme.palette.grey[400], textAlign: 'center' }}>
                                            {token[1]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{color: theme.palette.grey[400], textAlign: 'center' }}>
                                            {token[2] ? token[2] : 'N/A'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <IconButton onClick={() => removeDate(index)}>
                                    <DeleteIcon color={'error'}/>
                                </IconButton>
                            </Stack>
                        )
                    })
                    :
                    null
                }
            </Stack>
            {
                pageData.showPublishedDatesError && (
                    <Typography align='center' variant="h3" sx={{ mt: 1, color: 'red' }}>
                        Please fill in a date or select No.
                    </Typography>
                )
            }
        </Box>
    )
}

export default PublishedTokenDates;