import React, { useState } from 'react'

import { Box, Tab } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'

import VIPMainTabsFeedNotifications from './VIPMainTabsFeedNotifications'
import VIPMainTabsFeedHotSearches from './VIPMainTabsFeedHotSearches' 
import VIPMainTabsFeedTriggeredAlerts from "./VIPMainTabsFeedTriggeredAlerts"
import VIPMainTabsFeedKeywordAlerts from './VIPMainTabsFeedKeywordAlerts'

const VIPMainTabsAlertTabs = ( {setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, showOfficialTweets, localVIPCoins, divHeight=0, boxWidth=0, selectedTab='1', changeVIPTabInLayout} ) => {
    console.log("top of VIPMainTabsAlertTabs")
    
    const [vipMainTabValue,setVIPMainTabValue] = useState(selectedTab)

    const handleMainTabChange = (event, newValue) => {
        console.log("top of handleMainTabChange in VIPMainTabsAlertTabs...calling changeTabInLayout using: " + newValue)
        setVIPMainTabValue(newValue);
        //changeVIPTabInLayout(newValue)
    }    

    
    /* for when there was only coin notifications...but no more
    return (
        <Box sx={{width: '100%', height: '950px',}}>
            <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '920px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                <VIPMainTabsFeedNotifications 
                    notificationType="coin"
                    setCoinInChart={setCoinInChart}
                    changeAtFollowedTab={changeAtFollowedTab}
                    changeAtMentionedTab={changeAtMentionedTab}
                    changeHashTab={changeHashTab}
                    changeCoinInTab={changeCoinInTab}
                    changeDateInChart={changeDateInChart}    
                    showOfficialTweets={showOfficialTweets}                        
                />
            </Box>
        </Box>
    ) 
    */   

    return (
        <Box sx={{width: '100%', height: '950px',}}>
            <TabContext value={vipMainTabValue} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList 
                        onChange={handleMainTabChange} 
                        aria-label="" 
                        indicatorColor="secondary" 
                        textColor="primary" 
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="coin notification" value="1" sx={{minWidth: '30px'}} />
                        <Tab label="coin notification w/post" value="2" sx={{minWidth: '30px'}} />
                        <Tab label="hot searches" value="3" sx={{minWidth: '30px'}} />
                        <Tab label="triggered alerts" value="4" sx={{minWidth: '30px'}} />
                        <Tab label="keyword alerts" value="5" sx={{minWidth: '30px'}} />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '920px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <VIPMainTabsFeedNotifications 
                            notificationType="coin"
                            setCoinInChart={setCoinInChart}
                            changeAtFollowedTab={changeAtFollowedTab}
                            changeAtMentionedTab={changeAtMentionedTab}
                            changeHashTab={changeHashTab}
                            changeCoinInTab={changeCoinInTab}
                            changeDateInChart={changeDateInChart}     
                            showOfficialTweets={showOfficialTweets}  
                            localVIPCoins={localVIPCoins}                     
                        />
                    </Box>
                </TabPanel>    
                <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '920px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <VIPMainTabsFeedNotifications 
                            notificationType="post"
                            setCoinInChart={setCoinInChart}
                            changeAtFollowedTab={changeAtFollowedTab}
                            changeAtMentionedTab={changeAtMentionedTab}
                            changeHashTab={changeHashTab}
                            changeCoinInTab={changeCoinInTab}
                            changeDateInChart={changeDateInChart}
                            showOfficialTweets={showOfficialTweets}
                            localVIPCoins={localVIPCoins}
                        />
                    </Box>
                </TabPanel>    
                <TabPanel value="3" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>                    
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '920px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>                    
                        <VIPMainTabsFeedHotSearches
                            setCoinInChart={setCoinInChart}
                            changeAtFollowedTab={changeAtFollowedTab}
                            changeAtMentionedTab={changeAtMentionedTab}
                            changeHashTab={changeHashTab}
                            changeCoinInTab={changeCoinInTab}
                            changeDateInChart={changeDateInChart} 
                        />
                    </Box>
                </TabPanel>
                <TabPanel value="4" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '920px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <VIPMainTabsFeedTriggeredAlerts
                        />
                    </Box>
                </TabPanel>
                <TabPanel value="5" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '920px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <VIPMainTabsFeedKeywordAlerts
                            divHeight={divHeight}
                            boxWidth={boxWidth}                            
                        />
                    </Box>
                </TabPanel>                
            </TabContext>
        </Box>
    )
}

export default VIPMainTabsAlertTabs