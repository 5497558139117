import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { styled, useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconStethoscope, IconRuler2, IconArrowUpCircle, IconArrowDownCircle } from '@tabler/icons';

const ExchangeReserve = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About Exchange Reserve</Typography>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - Exchange Reserve">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body1">
                The total amount of coins held on exchange addresses.
              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="body1" sx={{marginTop: '20px', marginBottom: '5px'}}> Exchange reserve is a collective measure of potential coins that are ready to be sold in the market.</Typography>
              <Typography variant="body1" sx={{marginTop: '5px', marginBottom: '5px'}}>Exchange Reserve is the accumulated result of  Exchange In/Outflow & Netflow which naturally follows the indications that in/outflow has. Similar to Exchange NetFlow's interpretation, an increasing trend in netflow indicates the selling pressure and the decreasing trend indicates the buying pressure.</Typography>
              <Typography variant="body1" sx={{marginTop: '5px', marginBottom: '5px'}}>However, instead of Exchange In/Outflow & Netflow indicating the specific moment or period, Exchange Reserve is easy to track the result of the entire period's movements.</Typography>

              <Typography variant="h4" sx={{marginTop: '20px'}}>Interpretation by Value Itself</Typography>
              <Typography variant="body1" sx={{marginTop: '20px'}}>It indicates the degree of  accumulated selling pressure in the exchange</Typography>
              <Typography variant="body1" sx={{marginTop: '5px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconArrowUpCircle color='#00b341'/>
                    </ListItemIcon>
                    <ListItemText
                      primary="High : High selling pressure"
                      secondary="A large number of coins are staying in the exchange to be traded indicating high selling pressure "
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Low : Low selling pressure"
                      secondary="A Small number of coins are staying in the exchange to be traded indicating low selling pressure"
                    />
                  </ListItem>
                </List>
              </Typography>

              <Typography variant="h4" sx={{marginTop: '20px'}}>Interpretation by examining trend</Typography>
              <Typography variant="body1" sx={{marginTop: '20px'}}>It shows the changing status in scarcity</Typography>
              <Typography variant="body1" sx={{marginTop: '5px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconArrowUpCircle color='#00b341'/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Increasing trend: Decreasing scarcity - Bearish on price"
                      secondary="More coins are available in the exchange indicating decreasing scarcity of coins that are being traded which supports bearish movement"
                    />
                  </ListItem>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowDownCircle color='#ff2825' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Decreasing trend: Increasing scarcity - Bullish on price"
                      secondary="Fewer coins are available in the exchange indicating increasing scarcity of coins that are being traded which supports bullish movement"
                    />
                  </ListItem>
                </List>
              </Typography>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default ExchangeReserve