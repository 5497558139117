import { complement, isEmpty } from 'ramda'
import jwt_decode from 'jwt-decode'

const jwt_token_key = "BCA_TOKEN"
const jwt_token_expiry_key = "BCA_TOKEN_EXPIRY"

const getToken = () => {
  return localStorage.getItem(jwt_token_key) && localStorage.getItem(jwt_token_key).replace(/['"]+/g, '')
}

const getTokenExpiry = () => {
  return localStorage.getItem(jwt_token_expiry_key)
}

const login = ( token, expiry ) => {
  //save bca token to local storage
  try {
    localStorage.setItem(jwt_token_key, token)
    localStorage.setItem(jwt_token_expiry_key, expiry)
  } catch (error) {
    console.log("error setting token/expiry in local storage")
    console.dir(error)
    return false
  }
  return true
}

const logout = ( ) => {
  //remove bca token from local storage
  try {
    localStorage.removeItem(jwt_token_key)
    localStorage.removeItem(jwt_token_expiry_key)
  } catch (error) {
    console.log("error removing token/expiry from local storage")
    console.dir(error)
    return false
  }
  return true
}

const checkLogStatus = () => {
  // check if token has expired...just because there is a token saved doesn't mean it hasn't expired
  const token = getToken()
  const tokenExpiry = getTokenExpiry()
  if ((!tokenExpiry) || (!token)) return false
  var tokenExpirationTime = new Date(tokenExpiry)
  var date_now = new Date().toISOString()
  //console.log("tokenExpiry is: " + tokenExpiry)
  //console.log("date_now is: " + date_now)
  if (Date.now() >= tokenExpirationTime) {
    // this token is expired...lets remove token to save confusion
    //console.log("this token has expired")
    logout()
    return false
  } else {
    //console.log("this token has not expired")
    console.log("token is: " + token)
    return true
  }
}

const exportedAuthObjects = {
  getToken,
  login,
  logout,
  checkLogStatus
}

export default exportedAuthObjects