import React from 'react'
import { Box, Typography, Stack, Divider } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'

import PortfolioTracking from '../PortfolioTracking/PortfolioTracking'

const PortfolioDisplay = ( { userObject, panelID, deleteBox} ) => {

    const theme = useTheme()

    const removePortfolioBox = () => {
        console.log("top of removeListBox and panelID is: " + panelID)
        deleteBox(panelID)
    }

    return (
        <Box sx={{ display: 'flex', width: 'calc(100% - 10px)', marginLeft: '5px' }}>
            <Stack direction="column" display="flex" alignItems='left' sx={{width: '100%'}}>
                <Stack direction="row" display="flex" alignItems='center' justifyContent='space-between' sx={{marginTop: '5px', marginRight: '5px'}}>
                    <Stack direction="row" display="flex" alignItems='center' justifyContent='flex-start' >
                        <Typography display="inline" sx={{fontWeight: 400}} color={theme.palette.grey[300]}>{"Portfolio"}</Typography>
                        <Typography display="inline" sx={{fontWeight: 600, marginLeft: '5px'}} color={theme.palette.primary.main}></Typography>
                    </Stack>
                    <Stack direction="row" justifyContent='flex-end'>  
                        <VscMove 
                            className='draggable-handle' 
                            color={theme.palette.warning.dark} 
                            size={18} 
                            style={{cursor: 'grabbing'}}
                        />
                        <MdCancel 
                            className='draggable-cancel' 
                            color={theme.palette.secondary.dark} 
                            size={18} 
                            onClick={removePortfolioBox}
                            style={{cursor: 'pointer'}}
                        />
                    </Stack>
                </Stack>   
                <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>     
                <PortfolioTracking
                    userObject={userObject}
                    insideDashboard={true}
                />

            </Stack>
        </Box>            
    )
}

export default PortfolioDisplay