import React from 'react'
import { Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Tooltip } from '@mui/material'
import { CgInfo } from 'react-icons/cg'
import { styled, useTheme } from '@mui/material/styles'

const ResearchCustomDependencySelection = ( {changeCustomDependency,currentDependency} ) => {
    console.log("top of ResearchCustomDependencySelection and currentDependency is:")
    console.dir(currentDependency)

    const theme = useTheme()

    const handleDependencyChange = (e) => {
        console.log("top of handleDependencyChange and newSelection is:")
        console.dir(e.target.value)
        changeCustomDependency(e.target.value)
    }

    return  (
        <Box sx={{width: '100%', paddingLeft: '20px', paddingRight: '20px', marginTop: '30px'}}>
            <FormControl>
                <FormLabel 
                    id="account-type-filter"
                >
                    Coin Selection Dependent On Other Boxes
                    <Tooltip title="If set to dependent coin choices in other boxes will affect coin displayed by this box">
                        <CgInfo color={theme.palette.warning.main} />
                    </Tooltip>
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="coin-dependency-selection"
                    name="coin-dependency-selection"
                    value={currentDependency}
                    onChange={handleDependencyChange}
                >
                    <FormControlLabel value="true" control={<Radio />} label="dependent" />
                    <FormControlLabel value="false" control={<Radio />} label="independent" />
                </RadioGroup>
            </FormControl>
        </Box>
    )
}
export default ResearchCustomDependencySelection