import React from 'react'
import { Grid, Typography, Stack, Avatar, Box, Tooltip, IconButton } from '@mui/material'

import { IconArrowUpRightCircle } from '@tabler/icons'
import { HelpOutline } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'
const HealthDisplayNUPL = ( { ruleThemAll } ) => {

  const theme = useTheme()

  //console.log("top of QuickNUPL and ruleThemAll is:")
  //console.dir(ruleThemAll)

  //var useThisNUPLData = ruleThemAll[ruleThemAll.length-1] //new endpoint provides data latest first...this will not work
  var useThisNUPLData = ruleThemAll[0]


  if (ruleThemAll.length === 0) {
    return (
      <Box sx={{width: '100%'}} >
        <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[500]}}>NUPL</Typography>
        <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
      </Box>
    )
  } else {  
    //console.log("useThisNUPLData is:")
    //console.dir(useThisNUPLData)

    var nuplColor = "#33587a"
    var nuplDesc = 'CAPITULATION'
    if ((useThisNUPLData.healthData.nupl >= 0) && (useThisNUPLData.healthData.nupl < .25)) {
      nuplColor = "#47a675"
      nuplDesc = 'Hope/Fear'
    } else if ((useThisNUPLData.healthData.nupl >= .25) && (useThisNUPLData.healthData.nupl < .50)){
      nuplColor = "#a9ad4b"
      nuplDesc = 'Optimism/Anxiety'
    }  else if ((useThisNUPLData.healthData.nupl > .50) && (useThisNUPLData.healthData.nupl < .75)) {
      nuplColor = "#c79456"
      nuplDesc = 'Belief/Denial'
    } else if (useThisNUPLData.healthData.nupl >= .75) {
      nuplColor = "#ba5950"
      nuplDesc = 'Euforia/Greed'
    }

    return (
        <Stack
          direction="column"
          sx={{backgroundColor: theme.palette.dark.dark, margin: '0px', padding: '5px', paddingTop: '0px', borderRadius: '5px', minHeight: '7vh', cursor: 'pointer'}}
        >
          <Stack
            direction="row"
            display="flex"
            justifyContent='space-between'
            alignItems="center"
          >
            <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>NUPL</Typography>
          </Stack>
          <Box sx={{width: '99%', backgroundColor: theme.palette.dark.dark, paddingTop: '8px', paddingBottom: '5px'}} >
            <Stack direction="column" alignItems="center">
              <Avatar sx={{width: 50, height: 50, bgcolor: nuplColor, display: 'flex', justifyContent: 'center', fontSize: '.8rem', color: theme.palette.grey[500] }}>{useThisNUPLData.healthData.nupl.toFixed(2)}</Avatar>
              <Typography variant="Button" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[200]}}>{nuplDesc}</Typography>
            </Stack>
          </Box>
        </Stack>
    )
  }
}

export default HealthDisplayNUPL