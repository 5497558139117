import React, { useState, useEffect } from 'react'
import { Grid, Typography, Divider } from '@mui/material'

import VIPTwitterPost from './VIPTwitterPost'
import VIPTelegramPost from './VIPTelegramPost'
import VIPDiscordPost from './VIPDiscordPost'
import VIPRedditPost from './VIPRedditPost'
import VIPResearchPost from './VIPResearchPost'

import { useTheme } from '@mui/material/styles'
import {FiExternalLink} from 'react-icons/fi'

const VIPTabsHash = ( { vipListings, hashTabValue, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, showOfficialTweets } ) => {
  //console.log("top of VIPTabsHash")
  //console.log(hashTabValue)

  const theme = useTheme()

  let searchHash = "#"+hashTabValue
  if (hashTabValue.includes('#'))
    searchHash = hashTabValue
  else if (hashTabValue.length === 0)
    searchHash = ''

  //console.log("eventual searchhash: " + searchHash)

  const [hashFilteredListings,setHashFilteredListings] = useState([])

  useEffect(() => {
    if (searchHash.length > 0)
      setHashFilteredListings(vipListings.filter( listing => listing.text.includes(searchHash)))
    else
      setHashFilteredListings([])
  }, [hashTabValue])

  //currently
  if (hashFilteredListings.length > 0) {

    const linkToHashes = "https://twitter.com/search/?q=%28%23"+hashTabValue.replace("#", "")

    return (
      <Grid container>
        <Grid item xs={12}><Typography variant="h5" sx={{color: theme.palette.grey[700], margin: '10px'}}>#Hash From Current VIP Results Page</Typography></Grid>
        <Grid item xs={12}>
          <Divider sx={{width:'100%'}} textAlign="left" >
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>{hashFilteredListings.length}</Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.secondary.main, marginTop: '20px'}}> mentions In VIP results of </Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[500], marginTop: '20px'}}>{searchHash}</Typography>
            <a target="_blank" rel="noreferrer" style={{position: 'relative', top: '2px', left: '3px'}} href={linkToHashes}><FiExternalLink color={theme.palette.primary.main} /></a>
          </Divider>
        </Grid>
      {hashFilteredListings.map( (listing, index) => {
          if (listing.hasOwnProperty('tweet_id')) return <VIPTwitterPost key={'viptabshash'+index} listing={listing} changeAtFollowedTab={changeAtFollowedTab} showOfficialTweets={showOfficialTweets} changeHashTab={changeHashTab} changeAtMentionedTab={changeAtMentionedTab} setCoinInChart={setCoinInChart} changeCoinInTab={changeCoinInTab} changeDateInChart={changeDateInChart} />
          else if (listing.hasOwnProperty('telegram_group')) return <VIPTelegramPost key={'viptabshash'+index} telegramItem={listing} />
          else if (listing.hasOwnProperty('discord_server')) return <VIPDiscordPost key={'viptabshash'+index} discordItem={listing} />
          else if (listing.hasOwnProperty('subreddit')) return <VIPRedditPost key={'viptabshash'+index} redditItem={listing} />
          else return <VIPResearchPost key={'viptabshash'+index} researchItem={listing} /> //not sure if these are the only 5 types needed
      })}
      </Grid>
    )
  } else {
    if (searchHash.length > 0) {
      return (
        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>There are no VIP results for: {searchHash}</Typography>
      )
    } else {
      return (
        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>There are no #hash to search for</Typography>
      )
    }
  }

}

export default VIPTabsHash