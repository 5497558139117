import React, { useState } from 'react'
import { Box, Grid, Tab, Typography } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import { IconBook } from '@tabler/icons';

const MinerReserve = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About Miner Reserve</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - Exchange Inflows">
                <Tab icon={<IconBook />} value="1" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
                The USD value of coins held by the affiliated miners' wallets.
                This value indicates the reserve miners are holdings that are not yet sold.
                When miners start selling, it could lead to price drop.
              </Typography>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default MinerReserve