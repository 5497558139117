import React from 'react'
import { Typography, Button } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'

const CoinDetailsCorrelationBTC = ( {currencyDetails, medScreenOrSmaller} ) => {

    const theme = useTheme()

    const typographyStyle = {
      textTransform: 'uppercase'
    }

    const ColorButtonPositive = styled(Button)(({ theme }) => ({
      '&&&': {
          borderRadius: '20px',
          paddingLeft: '10px',
          paddingRight: '5px',
          fontSize: '12px',
          color: theme.palette.dark[900],
          height: '50px',
          backgroundColor: theme.palette.success.light,
          '&:hover': {
            backgroundColor: theme.palette.success.dark,
          },
        }
      }));
    
      const ColorButtonNegative = styled(Button)(({ theme }) => ({
        '&&&': {
          borderRadius: '20px',
          paddingLeft: '10px',
          paddingRight: '5px',
          fontSize: '12px',
            height: '50px',
          color: theme.palette.dark[900],
          backgroundColor: theme.palette.error.light,
          '&:hover': {
            backgroundColor: theme.palette.error.main,
          },
        }
      }));

  if (medScreenOrSmaller) {
      if (currencyDetails.btc_24_dif > 0) {
        return <ColorButtonPositive sx={typographyStyle}> 24h vs BTC {parseFloat(currencyDetails.btc_24h_dif).toFixed(2)}% <ArrowUpward sx={{marginLeft: '3x'}}/> </ColorButtonPositive>
      } else {
        return <ColorButtonNegative sx={typographyStyle}> 24hr vs BTC {parseFloat(currencyDetails.btc_24h_dif).toFixed(2)}%<ArrowDownward sx={{marginLeft: '3px'}}/> </ColorButtonNegative>
      }      
  } else {
    if (currencyDetails.btc_24_dif > 0) {
      return <ColorButtonPositive variant="h4" sx={typographyStyle}> 24h vs BTC {parseFloat(currencyDetails.btc_24h_dif).toFixed(2)}% <ArrowUpward sx={{marginLeft: '10px'}}/> </ColorButtonPositive>
    } else {
      return <ColorButtonNegative variant="h4" sx={typographyStyle}> 24hr vs BTC {parseFloat(currencyDetails.btc_24h_dif).toFixed(2)}%<ArrowDownward sx={{marginLeft: '10px'}}/> </ColorButtonNegative>
    }
  }
}

export default CoinDetailsCorrelationBTC