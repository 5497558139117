import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, Stack, Box, Divider, Tooltip } from '@mui/material'
import { GiBullHorns, GiBearFace } from 'react-icons/gi'

import VIPTwitterPost from './VIPTwitterPost'

const VIPResearchPost = ( {researchItem, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, showOfficialTweets } ) => {

  //console.log("top of VIPResearchPost")
  //console.dir(researchItem)

  const theme = useTheme();

  const setCoinCascade = (coin) => {
    //console.log("top of setCoinCascade")
    //console.log("coin is: " + coin)
    changeDateInChart(researchItem.created)
    setCoinInChart(coin)
    changeCoinInTab(coin)
  }

  var bullishColor = theme.palette.success.dark
  var tooltipTitle = "bullish"
  if (researchItem.bullish === false) {
    bullishColor = theme.palette.error.dark
    tooltipTitle = "bearish"
  } 


  return (
    <Grid container
      sx={{
        paddingTop: '10px',
      }}
    >
      <Grid item xs={12} sx={{padding: "8px", }}>
        <Box sx={{borderRadius: '5px',  padding: "5px", margin: '0px', bgcolor: theme.palette.dark[800], '&:hover': {backgroundColor: theme.palette.dark.dark} }}>
          <Grid container spacing={1}>
            <Grid item xs={12}  >
              <Stack direction="column">
                <Stack direction="row" sx={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                  <Stack direction="row" sx={{marginRight: '8px'}}>
                    <Typography sx={{color: theme.palette.grey[400] , fontSize: '0.8rem',  marginTop: '3px'}}>relevant date:</Typography>
                    <Typography sx={{color: theme.palette.primary.main , fontSize: '0.8rem', fontWeight: 600, marginTop: '3px'}}>{researchItem.relevant_date}</Typography>
                  </Stack>       

                  {researchItem.tradepost ? <Typography display="inline" variant='caption' sx={{marginRight: '10px', fontWeight: 600, color: theme.palette.primary.main}}>trade recommendation</Typography> :  null}

                  <Tooltip title={tooltipTitle}><Typography display="inline" variant='h5' sx={{marginRight: '10px', fontWeight: 600, fontSize: '1.1rem', color: bullishColor}}>{researchItem.bullish ? <GiBullHorns /> : <GiBearFace />}</Typography></Tooltip>
                </Stack>
                <Box m={1} p={1} sx={{backgroundColor: theme.palette.dark[800] }}>
                  <Typography sx={{marginBottom: '10px'}}>{researchItem.comments}</Typography>
                  {researchItem.image != '' && researchItem.image != null ? <img src={researchItem.image} width="98%" alt="research comments" />  : null }
                  {researchItem.image != '' || researchItem.image != null ? <Typography>no image attached</Typography> : null}
                </Box>
                <Stack direction="row" sx={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}} >
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>coin(s):</Typography>
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>posted by:</Typography>
                </Stack>
                <Stack direction="row" sx={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}} >
                  <Stack direction="row" sx={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                    {Array.isArray(researchItem.coin) &&  researchItem.coin.map( (coin, index ) => {
                      return <Typography variant="subtitle1" display="inLine" sx={{fontWeight: 600, cursor: 'pointer', color: theme.palette.primary.main, marginLeft: '5px'}} key={coin} onClick={()=>setCoinCascade( coin )} > {coin}</Typography>
                    })}
                    {!Array.isArray(researchItem.coin) ? <Typography>no coins attached</Typography> : null}
                  </Stack>
                  
                  <Typography sx={{color: theme.palette.primary.main , fontSize: '0.8rem',  marginTop: '3px', marginLeft: '5px'}}>{researchItem.user} on {researchItem.created.split('T')[0]}</Typography>
                  
                </Stack>

                {/*researchItem.post != null ?
                <Divider sx={{width: '100%', marginTop: '10px'}} textAlign="left">
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>Comment is in reference to the following</Typography>
                </Divider>
                : null 
                
                currently there are no twitter posts attached to comments to display
                <VIPTwitterPost listing={researchItem.post} setCoinInChart={setCoinInChart} changeAtFollowedTab={changeAtFollowedTab} showOfficialTweets={showOfficialTweets} changeAtMentionedTab={changeAtMentionedTab} changeHashTab={changeHashTab} changeCoinInTab={changeCoinInTab} changeDateInChart={changeDateInChart} />
                */}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default VIPResearchPost