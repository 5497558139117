import React, {useState,useEffect} from 'react'
import { Grid, Typography, Avatar, Stack, Box, CircularProgress } from '@mui/material'
import { IconCurrencyBitcoin, IconTrendingUp, IconTrendingDown, IconCurrencyDollar, IconArrowsLeftRight, IconBuildingBank,
  IconPlus, IconMoodEmpty, IconHandStop, IconArrowDownRightCircle, IconArrowUpRightCircle } from '@tabler/icons'
import { LocalHospital } from '@mui/icons-material'

import QuickReserve from './QuickReserve'
import QuickOpenInterest from './QuickOpenInterest'
import QuickELR from './QuickELR'
import QuickFundingRates from './QuickFundingRates'
import QuickMVRV from './QuickMVRV'
import QuickNUPL from './QuickNUPL'
import QuickMinerReserve from './QuickMinerReserve'
import QuickStockToFlow from './QuickStockToFlow'
import QuickFearAndGreed from './QuickFearAndGreed'

import { useTheme } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch'
import QuickNetflow from "./QuickNetflow";
import QuickStableNetflow from "./QuickStableNetflow";


const HealthOverview = ( { ruleThemAll, changeTab, btcData } ) => {

  //console.log("top of HealthOverview")
  //console.dir(btcData)

  const [isLoading,setIsLoading] = useState(true)

  const theme = useTheme()

  var currentBTCStats = ruleThemAll[0]
  var yesterdaysBTCStats = ruleThemAll[1]

  //console.log("currentBTCStats: ")
  //console.dir(currentBTCStats)

  //console.log("yesterdaysBTCStats:")
  //console.dir(yesterdaysBTCStats)

  const abbreviatedPrice = (price) => {
    if (price > 1) {
      return price.toFixed(2)
    } else if (price < 1 ) {
      return price
    } else {
      //just display the full amount
      return price
    }
  }


  //defaults in case of null data
  var priceColor = theme.palette.primary.main
  var colorOfPriceBox = theme.palette.primary.light
  var PriceTrendingIcon = <IconTrendingUp color={priceColor} size={25}/>
  var percentPriceChange24h = 0

  var volumeColor = theme.palette.primary.main
  var colorOfVolumeBox = theme.palette.primary.light
  var VolumeTrendingIcon = <IconTrendingUp color={priceColor} size={25}/>
  var percentVolumeChange24h = 0

  if ((yesterdaysBTCStats !== undefined) && (currentBTCStats !== undefined)) {
    priceColor = theme.palette.grey[800]
    colorOfPriceBox = theme.palette.success.dark
    PriceTrendingIcon = <IconTrendingDown color={priceColor} size={25}/>
    if (currentBTCStats.price < yesterdaysBTCStats.price) {
      priceColor = theme.palette.grey[500]
      colorOfPriceBox = theme.palette.error.dark
      PriceTrendingIcon = <IconTrendingDown color={priceColor} size={25}/>
    }

    percentPriceChange24h = Math.abs(btcData.current_price - yesterdaysBTCStats.price) / yesterdaysBTCStats.price * 100

    volumeColor = theme.palette.grey[800]
    colorOfVolumeBox = theme.palette.success.dark
    VolumeTrendingIcon = <IconTrendingUp color={priceColor} size={25}/>
    if (currentBTCStats.price < yesterdaysBTCStats.price) {
      volumeColor = theme.palette.grey[500]
      colorOfVolumeBox = theme.palette.error.dark
      VolumeTrendingIcon = <IconTrendingDown color={priceColor} size={25}/>
    }

    percentVolumeChange24h = Math.abs(btcData.total_volume - yesterdaysBTCStats.total_volume) / yesterdaysBTCStats.total_volume * 100
  } 

  const abbreviatedValue = (value) => {
    if (value > 1000000000) {
      return ((value / 1000000000).toFixed(2)) + 'B'
    } else if (value > 1000000) {
      return ((value/1000000).toFixed(2)) + 'M'
    } else if (value > 1000) {
      return ((value/1000).toFixed(2)) + 'K'
    } else {
      //just display the full amount
      return value
    }
  }

  var Icon = IconMoodEmpty
  var guidanceText = " No Guidance"
  var guidanceColor = theme.palette.grey[600]

  useEffect(() => {

    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        const response = await fetchData(`api/v1/coin/coinmarket/?coin__id=bitcoin`, "could not receive coinmarket data for bitcoin", fetchParams).promise
        //console.log("response getting coinmarket data using bitcoin")
        //console.dir(response)
        if (response.results[0].weight == 0 ) {
          //hold
          Icon = IconHandStop
          guidanceText = 'Hold'
          guidanceColor = theme.palette.grey[900]
        } else if (response.results[0].weight == 1 ) {
          //bearish
          Icon = IconArrowDownRightCircle
          guidanceText = 'Bearish'
          guidanceColor = theme.palette.error.dark
        } else if (response.results[0].weight == 2) {
          //bullish
          guidanceColor = theme.palette.success.dark
          Icon = IconArrowUpRightCircle
          guidanceText = "Bullish"

        } else {
          //no analysis...use defaults
        }

        setIsLoading(false)

      } catch (error) {
        console.log("something is broke getting coinmarket in HealthOverview: " + error)
      }
    })()

  }, [])

  const getDate = () => {
    var date = new Date()
    var month = date.getMonth()+1
    var day = date.getDate()
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`
  }
  
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={3} >
            <Box sx={{width: '100%', backgroundColor: theme.palette.dark.dark, padding: '5px 10px', borderRadius: '5px'}}>
              <Stack direction="row" display="flex" alignItems='center'>
                <Avatar sx={{ bgcolor: theme.palette.dark[800], width: 45, height: 45}}>
                  <IconCurrencyBitcoin size={40} color={theme.palette.primary.dark} />
                </Avatar>
                <Stack direction="column" sx={{marginLeft: '15px'}}>
                  <Typography color={theme.palette.grey[500]} variant="h4">BitCoin Health</Typography>
                  <Typography color={theme.palette.grey[500]} variant="body2">current to: {getDate()}</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          {/*<Grid item xs={3}>
            <Box sx={{width: '100%', backgroundColor: theme.palette.dark.dark, padding: '5px 10px', borderRadius: '5px'}}>
              <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around">
                <Avatar sx={{ bgcolor: guidanceColor, width: 45, height: 45}}>
                  <LocalHospital size={40} sx={{color: theme.palette.primary.main }}/>
                </Avatar>
                {isLoading ? <CircularProgress /> : <><Icon color={guidanceColor} size={40} /> <Typography variant="h4" color={guidanceColor}>{guidanceText}</Typography></> }
              </Stack>
            </Box>
          </Grid>*/}
          <Grid item xs={3} >
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-around"
              sx={{backgroundColor: colorOfPriceBox, padding: '5px', borderRadius: '5px'}}
            >
              <IconCurrencyDollar size={40} color={priceColor}/>
              <Stack direction="column" alignItems="center" justifyContent="space-around">
                <Typography variant="h5">Price</Typography>
                <Stack direction="row" alignItems="center"><Typography variant="h4" color={priceColor}>{abbreviatedPrice(btcData.current_price)}</Typography> {PriceTrendingIcon} <Typography variant="h4" color={priceColor} sx={{marginLeft: '5px'}}>{percentPriceChange24h.toFixed(2)} %</Typography></Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-around"
              sx={{backgroundColor: colorOfVolumeBox, padding: '5px 10px', borderRadius: '5px'}}
            >
              <IconArrowsLeftRight size={40} color={volumeColor}/>
              <Stack direction="column" alignItems="center" justifyContent="space-around">
                <Typography variant="h5">Volume 24h</Typography>
                <Stack direction="row" alignItems="center"><Typography variant="h4" color={volumeColor} >$ {abbreviatedValue(btcData.total_volume)} USD</Typography> {VolumeTrendingIcon} <Typography variant="h4" color={volumeColor} sx={{marginLeft: '5px'}}> {percentVolumeChange24h.toFixed(2)} %</Typography></Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-around"
              sx={{backgroundColor: theme.palette.dark.dark, padding: '6px 10px', borderRadius: '5px'}}
            >
              <IconBuildingBank size={40} color={theme.palette.grey[500]}/>
              <Stack direction="column" alignItems="center" justifyContent="space-around" sx={{backgroundColor: theme.palette.dark.dark}}>
                <Typography variant="h5">Market Cap</Typography>
                <Stack direction="row" alignItems="center"><Typography variant="h4">$ { currentBTCStats !== undefined ? abbreviatedValue(currentBTCStats.market_cap) : 0} USD</Typography></Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <QuickReserve
              ruleThemAll={ruleThemAll}
              changeTab={changeTab}
            />
          </Grid>
          <Grid item xs={2}>
            <QuickFearAndGreed
              ruleThemAll={ruleThemAll}
              changeTab={changeTab}
            />
          </Grid>
          <Grid item xs={3}>
            <QuickELR
              ruleThemAll={ruleThemAll}
              changeTab={changeTab}
            />
          </Grid>
          <Grid item xs={2}>
            <QuickOpenInterest
              ruleThemAll={ruleThemAll}
              changeTab={changeTab}
            />

          </Grid>
          <Grid item xs={4}>
            <QuickFundingRates
              ruleThemAll={ruleThemAll}
              changeTab={changeTab}
            />
          </Grid>
          <Grid item xs={4}>
            <QuickMVRV
              ruleThemAll={ruleThemAll}
              changeTab={changeTab}
            />
          </Grid>
          <Grid item xs={4}>
            <QuickNUPL
              ruleThemAll={ruleThemAll}
              changeTab={changeTab}
            />
          </Grid>
          <Grid item xs={6}>
            <QuickStockToFlow
              ruleThemAll={ruleThemAll}
              changeTab={changeTab}
            />
          </Grid>
          <Grid item xs={6}>
            <QuickMinerReserve
              ruleThemAll={ruleThemAll}
              changeTab={changeTab}
            />
          </Grid>
          <Grid item xs={6}>
            <QuickNetflow
                ruleThemAll={ruleThemAll}
                changeTab={changeTab}
            />
          </Grid>
          <Grid item xs={6}>
            <QuickStableNetflow
                ruleThemAll={ruleThemAll}
                changeTab={changeTab}
            />
          </Grid>
        </Grid>

      </Grid>
      {/*
      <Grid item xs={3}>
        this is the right side
      </Grid>
      */}
    </Grid>
  )
}

export default HealthOverview