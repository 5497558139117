import React, {useState, useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Grid, Box, Pagination, Container, Backdrop, CssBaseline, Divider, Stack, Typography, CircularProgress } from '@mui/material'
import fetchData from '../../utility/endpointFetch'

import Navigation from '../Navigation'
import HomeFeedListDisplay from './HomeFeedListDisplay'
import HomeFeedManager from './HomeFeedManager'
import { set } from 'date-fns'
import TopAppBar from '../TopAppBar'

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
      }),
      [theme.breakpoints.up('md')]: {
          marginLeft: '20px',
          width: `calc(100% - 20px)`
      },
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px',
          width: `calc(100% - 20px)`,
          padding: '16px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px',
          width: `calc(100% - 20px)`,
          padding: '16px',
          marginRight: '10px'
      }
  }),
  ...(open && {
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - 20px)`,
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px'
      }
  })
}));

const HomeFeed = ({userObject}) => {
  console.log("top of HomeFeed")
  console.dir(userObject)

  const theme = useTheme()

  const [homeFeedList,setHomeFeedList] = useState([])
  const [isLoading,setIsLoading] = useState(true)
  const [homeFeedCount,setHomeFeedCount] = useState(0)
  const [homeFeedPage,setHomeFeedPage] = useState(1)
  const [homeFeedPageCount,setHomeFeedPageCount] = useState(0)
  const [backdropOpen,setBackdropOpen] = useState(false)

  const refreshHomeFeed = (followedList) => {
    //console.log("top of refreshHomeFeed")
    //followed list has changed...refresh home feed
    setIsLoading(true)
    setBackdropOpen(true)
    //setHomeFeedCount(0)
    getResults(followedList)
  }

  const getResults = (followedList=[]) => {

    if (userObject.twitter_feed.length > 0 || followedList.length > 0) {
      let twitterDisplayNameValueToUse = []
      if (followedList.length > 0) {
          twitterDisplayNameValueToUse = followedList
      } else {
          twitterDisplayNameValueToUse = userObject.twitter_feed
      }
      const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
      }

      //need to build twitter user list from userObject.twitter_feed


      ;(async () => {
        try {
          var offset= (homeFeedPage - 1) * 100 //limit per page
          //var homeFeedURL = `api/v1/feed/homefeed/?limit=100&offset=${offset}`
          var homeFeedURL = `api/v1/feed/alltweets/?twitter_display_name=${twitterDisplayNameValueToUse.toString()}&page=${homeFeedPage}`
          var homefeedResponse = await fetchData(homeFeedURL, "could not receive homefeed data", fetchParams).promise
          console.log("homefeedResponse getting homefeed data")
          console.dir(homefeedResponse)
          console.log("homeFeedURL is:")
          console.dir(homeFeedURL)

          setHomeFeedCount(homefeedResponse.count)
          //setHomeFeedCount(homefeedResponseTestData.count)

          if (homefeedResponse.count > 100) {
            setHomeFeedPageCount(Math.ceil(homefeedResponse.count/100))
          } else {
            setHomeFeedPageCount(1)
          }
          
          setHomeFeedList(homefeedResponse.results)
          //setHomeFeedList(homefeedResponseTestData.results)
          
        } catch (error) {
          console.log("something is broke getting homefeed data: ", error)
          setHomeFeedCount(0)
        }
      })()
    } else {
      //there are no twitter user following....prompt to add some
      console.log("there are no twitter users in userObject.twitter_feed")
      setHomeFeedList([])
      setHomeFeedCount(0)
    }
    setBackdropOpen(false)
    setIsLoading(false)
  }

  useEffect(() => {
    //console.log("top of useEffect to get homefeed")
    setIsLoading(true)
    setBackdropOpen(true)
    getResults()

  }, [homeFeedPage])

  const changeHomeFeedPage = (event, page) => {
    setIsLoading(true)
    setHomeFeedPage(page)
  }

  if (isLoading) return <CircularProgress />
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <TopAppBar userObject={userObject} selectedPage='homefeed' />
      <Main theme={theme} >
        <Container maxWidth="false"  >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={backdropOpen}
          >
            <CircularProgress sx={{color: "#000"}} size={100} />
          </Backdrop>    
          <Grid container sx={{marginTop: '10px'}}>
            <Grid item xs={12}>
              <HomeFeedManager userObject={userObject} refreshHomeFeed={refreshHomeFeed} />
            </Grid>            
            <Grid item xs={12}>
              <Divider sx={{width:'100%'}} textAlign="left" >
                <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.primary.dark, paddingLeft: '20px', marginTop: '20px'}}>{homeFeedCount}</Typography>
                <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.grey[300], paddingLeft: '5px',marginTop: '20px'}}>Home Feed Items</Typography>
              </Divider>        
            </Grid>
             
            { homeFeedCount === 0 ? <Grid item xs={12}><Typography variant="h4" sx={{marginTop: '15px'}}>You do not have any feed items to view.  Either you do not have any twitter users within your list to follow or the twitter users you do follow do not have any current tweets within our system.   Are you following all you wish to follow? <br /><br />Click the "managed followed" button to manage the twitter users tweets that will appear in this list</Typography></Grid> : 
              <HomeFeedListDisplay homeFeedList={homeFeedList} />
            }      
            {homeFeedCount > 0 ? <Grid item xs={12}>
              <Divider sx={{marginBottom: '15px'}}/>
               <Stack direction="row" display="flex" alignItems="center">
                <Typography variant="button" sx={{marginRight: '20px'}}>Results: {homeFeedCount} </Typography>
                 <Pagination shape="rounded" count={homeFeedPageCount} page={homeFeedPage} onChange={changeHomeFeedPage}/> 
              </Stack>
            </Grid>  : null }   
          </Grid>
        </Container>
      </Main>
    </Box>
  )


}

export default HomeFeed