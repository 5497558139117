import React from 'react'
import { Typography, Stack, ListItemText, List, ListItemIcon, ListItem } from '@mui/material'
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa'
import { useTheme } from '@mui/material/styles'
import CheckIcon from '@mui/icons-material/Check'

const ResearchTutorial4Result = () => {

    const theme = useTheme()

    return (
        <Stack direction="column" alignItems="flex-start" sx={{padding: '15px'}}>
            <Typography sx={{paddingTop: '40px', marginRight: '20px', fontSize: '2em', color: theme.palette.warning.dark}}><FaArrowAltCircleLeft size={20} />  Key Event Tweets</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>What you see here are tweets with the term "staking". Returned Results are displayed with the most recently matched post first. 
            As a reminder, in this mode only tweets from Crypto Project’s Twitter accounts are searched against.</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>In the future, Returned Results can be sorted through a combination of factors like, 
            1) which are backed by the most influencers, or 2) which posts are receiving the most engagement.</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>With each Tweet displayed, you’ll also see additional data:</Typography>
            <List>
                <ListItem >
                    <ListItemText ><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>Official Twitter Name and Account (click to view tweet at twitter.com)</Typography></ListItemText>
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                </ListItem>
                <ListItem >
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>followers of that twitter account (with link to account)</Typography></ListItemText>
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                </ListItem>                
                <ListItem >
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>color coded # and @</Typography></ListItemText>
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                </ListItem>             
                <ListItem >
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>Links open in new tab</Typography></ListItemText>
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                </ListItem>          
                <ListItem >
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>various stats at time of retrieval</Typography></ListItemText>
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                </ListItem>    
                <ListItem >
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>coins associated with that tweet</Typography></ListItemText>
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                </ListItem>                              
                <ListItem >
                    <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'left'}}>time of tweet</Typography></ListItemText>
                    <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                </ListItem>                                
            </List>
        </Stack>          
    )
}

export default ResearchTutorial4Result