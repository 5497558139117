import React, { useState, useEffect } from 'react'
import {
    Stack,
    Typography,
    Grid,
    Tab,
    Box,
    Pagination,
    TextField,
    InputAdornment,
    Button,
    Divider,
    IconButton,
    Modal,
    FormLabel,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar
} from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomFeedTwitterUserSearch from './CustomFeedTwitterUserSearch';
import { ArrowUpward } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { productName } from "../../config";
import CustomFeedTabFollowSearchItem from "./CustomFeedTabFollowSearchItem";
import { useDispatch } from "react-redux";
import { setColumnOneHandles, setColumnThreeHandles, setColumnTwoHandles } from "../../store/actions";

const CustomFeedTabFollowSearch = ({ column, addUserToWhiteList, followedList, getTwitterUserTweets, refreshFeed} ) => {
    const theme = useTheme()
    const dispatch = useDispatch();
    const [tabValue,setTabValue] = useState('4')
    const [userIsLoading,setUserIsLoading] = useState(true)
    const [twitterUserList,setTwitterUserList] = useState([])
    const [twitterUserPage,setTwitterUserPage] = useState(1)
    const [twitterUserPageCount,setTwitterUserPageCount] = useState(1)
    const [filterByInfluencerUrl,setFilterByInfluencerUrl] = useState('')
    const [searchByUrl,setSearchByUrl] = useState('')
    const [sortingBy,setSortingBy] = useState('')
    const [isInternal, setIsInternal] = useState(false);
    const [userData, setUserData] = useState(null);
    const [twitterHandle, setTwitterHandle] = useState(null);
    const [twitterHandleText, setTwitterHandleText] = useState('');
    const [triggerRefresh, setTriggerRefresh] = useState(false)
    const [infoOpen, setInfoOpen] = React.useState(false);

    const handleTabChange = (event, newValue) => {
        if(newValue === "1"){
            setTwitterUserPage(1);
            setFilterByInfluencerUrl('');
            setSearchByUrl('')
        }
        setTabValue(newValue);
    } 

    const searchTermChangeHandler = (searchTerm) => {
        //console.log("top or searchTwitterUsers...searchTerm: " + searchTerm)
        if (searchTerm.length > 2) {
          //console.log("searchTerm greater than 2")
          if (searchTerm.indexOf(' ') >= 0) {
            //console.log("searchTerm contains spaces")
            //contains spaces...no search
            setSearchByUrl('')
            return null
          } else {
            //console.log("about to search")
            var searchString = "&search=" + encodeURIComponent(searchTerm)
            setTwitterUserPage(1)
            setSearchByUrl(searchString)
          }
        } else {
          //search term too short...wait till at least 3 characters
          //console.log("searchTerm 2 or less letters")
          setSearchByUrl('')
          return null
        }
      }    
    const clearTwitterUserSearch = () => {
        setSearchByUrl('')
        setTwitterUserPage(1)
    }

    useEffect(() => {
        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            // get the user data
            try {
                var response_query = `api/v1/users/userdata/`
                const response = await fetchData( response_query, "could not receive user data", fetchParams).promise
                setUserData(response)
                setTwitterHandle(response['twitter_handle'])
                setIsInternal(response['user']['is_internal'])
            } catch (error) {
                console.log("could not receive twitter users data: %s", error)
            }
        })()
    }, [triggerRefresh])

    useEffect(() => {
        setUserIsLoading(true)
        //console.log("top of useEffect in HomeFeedManager")
        //console.log("twitterUserPage is: " + twitterUserPage)
        //console.log("filterByInfluencerUrl is: " + filterByInfluencerUrl)
        //console.log("searchByUrl is: " + searchByUrl)
    
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
            //get the list of twitter users
          try {
            var response_query = `api/v1/feed/usertwitter/?page=${twitterUserPage}${filterByInfluencerUrl}${searchByUrl}`
            const response = await fetchData( response_query, "could not receive twitter users data", fetchParams).promise
            console.log("response from usertwitter search in customfeed manager")
            console.dir(response_query)
            console.dir(response)

            //need to filter out users that are already followed
            let filteredTwitterUsers = response.results.filter( function (currentUser) {
                return !followedList.includes(currentUser.name)
            })

            //console.log("filteredTwitterUsers is: ")
            //console.dir(filteredTwitterUsers)

            setTwitterUserList(filteredTwitterUsers)
        
            if (response.count > 100) {
              setTwitterUserPageCount(Math.ceil(response.count/100))
            } else {
              setTwitterUserPageCount(1)
            }
            setUserIsLoading(false)
          } catch (error) {
            console.log("could not receive twitter users data: %s", error)
          }
        })()
    }, [twitterUserPage, filterByInfluencerUrl, searchByUrl])

    const setInfluencerHandler = ( influencerFilterValue ) => {
        //console.log("top of setInfluencerHandler: " + influencerFilterValue)
        if (influencerFilterValue === 'both') {
            setFilterByInfluencerUrl('')
        } else if (influencerFilterValue === 'influencer') {
            setFilterByInfluencerUrl('&is_influencer=true')
        } else if (influencerFilterValue === 'official') {
            setFilterByInfluencerUrl('&is_influencer=false')
        } else {
            setFilterByInfluencerUrl('')
        }
    }

    const changePage = (event, page) => {
        //console.log("top of changePage")
        //console.dir(page)
        setUserIsLoading(true)
        setTwitterUserPage(page)
    }

    const addUserToWhiteListHandler = (userToAdd, col=null) => {
        if(col){
            switch(col){
                case "One":
                    addUserToWhiteList(userToAdd, "One")
                    break;
                case "Two":
                    addUserToWhiteList(userToAdd, "Two")
                    break;
                case "Three":
                    addUserToWhiteList(userToAdd, "Three")
                    break;
                default:
                    break;
            }
        }else{
            addUserToWhiteList(userToAdd)
            setTriggerRefresh(!triggerRefresh)
        }
    }

    // new Twitter username
    const [newTwitterUserVal, setNewTwitterUserVal] = React.useState('');
    const handleNewTwitterUserValChange = (event) => {
        setAddTwitterUserError('')
        setNewTwitterUserVal(event.target.value);
    };
    // new Twitter URL
    const [newTwitterUrlVal, setNewTwitterUrlVal] = React.useState('');
    const handleNewTwitterUrlValChange = (event) => {
        setAddTwitterUserError('');
        setNewTwitterUrlVal(event.target.value);
    };
    const [addTwitterUserError, setAddTwitterUserError] = React.useState('');
    const [addTwitterUserSuccess, setAddTwitterUserSuccess] = React.useState('');

    const submitNewTwitterUser = () => {
        if((!newTwitterUserVal.toString().length) && (!newTwitterUrlVal.toString().length)){
            setAddTwitterUserError('You must enter a username or a url.')
            return;
        }
        const fetchParams = {
                method: 'POST',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: newTwitterUserVal,
                })
            }
        ;(async () => {
            //get the list of twitter users
            try {
                var response_query = `api/v1/feed/twittercreate/`
                const response = await fetchData( response_query, "could not create new twitter user", fetchParams).promise

                if(response['success']){
                    setNewTwitterUserVal('');
                    setNewTwitterUrlVal('');
                    setAddTwitterUserSuccess(response['success'])
                }else{
                    if(response) setAddTwitterUserError(Object.values(response)[0])
                    else setAddTwitterUserError('Error adding a new twitter user.')
                }
            } catch (error) {
                console.log("could not create new twitter user: %s", error)
            }
        })()
    }

    // Add user's twitter handle (effectively creating a request for their twitter_friends_list)
    const requestUserTwitterList = (handle) => {
        function postTwitterImport(){
            const fetchParams = {
                    method: 'POST',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'twitter_handle': handle
                    })
                }
            ;(async () => {
                //get the list of twitter users
                try {
                    var response_query = `api/v1/users/posttwitterimport/`
                    const response = await fetchData( response_query, "could not receive twitter users data", fetchParams).promise
                } catch (error) {
                    console.log("could not receive twitter users data: %s", error)
                }
            })()
        }
        function updateUserData(){
            const fetchParams = {
                    method: 'PUT',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                            "coin": userData['coin'],
                            "saved_research_queries": userData['saved_research_queries'],
                            "deleted_research_queries": userData['deleted_research_queries'],
                            "twitter_handle": handle,
                            "wallet_names": userData['wallet_names'],
                            "deleted_wallet": userData['deleted_wallet'],
                            "screener_names": userData['screener_names'],
                            "deleted_screener": userData['deleted_screener'],
                        }
                    )
                }
            ;(async () => {
                try{
                    let response_query = `api/v1/users/userdata/`
                    let response = await fetchData(response_query, 'could not post user data', fetchParams).promise
                    if(response){
                        setTwitterHandle(handle)
                    }
                }catch(error){
                    console.log('could not post user data: %s', error)
                }
            })()
        }
        postTwitterImport();
        updateUserData();
    }

    const removeUserFromWhiteList = (twitterUserToRemove, col) => {
        //console.log("top of removeUserFromWhiteList")
        //console.log("twitterUserToRemove is: " + twitterUserToRemove)
        if (followedList.includes(twitterUserToRemove)) {
            //console.dir(newFollowed)
            //setFollowedList(newFollowed)
            const fetchParams = {
                    method: 'DELETE',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'handle_list': [twitterUserToRemove],
                    })
                }
            ;(async () => {
                try {
                    let url = ''
                    switch(col){
                        case "One":
                            url = "api/v1/users/twitter_feed_update/?feed_index=1"
                            break;
                        case "Two":
                            url = "api/v1/users/twitter_feed_update/?feed_index=2"
                            break;
                        case "Three":
                            url = "api/v1/users/twitter_feed_update/?feed_index=3"
                            break;
                        default:
                            break;
                    }
                    const response = await fetchData(url, "could not remove twitter user from white list", fetchParams).promise
                    //console.log("response removing twitter user from white list")
                    //console.dir(response)
                    let newList = followedList.filter(val => val !== twitterUserToRemove);
                    switch(col){
                        case "One":
                            dispatch(setColumnOneHandles(newList))
                            break;
                        case "Two":
                            dispatch(setColumnTwoHandles(newList))
                            break;
                        case "Three":
                            dispatch(setColumnThreeHandles(newList))
                            break;
                        default:
                            break;
                    }

                } catch (error) {
                    console.log("could not remove twitter user from white list: %s", error)
                }
            })()
        } else {
            console.log("user not in followedList.  Do not need to remove: " + twitterUserToRemove)
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'hidden',
        maxHeight: 600,
    };

    return (
        <Grid container sx={{ width: '100%'}}>
            <Grid item xs={7} >
                <Box sx={{width: '100%', paddingLeft: '10px', paddingRight: '10px '}}>
                    <TabContext value={tabValue} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="" indicatorColor="secondary" textColor="primary" >
                                <Tab label={(!twitterHandle || !twitterHandle.length) ? "Import Twitter Followed" : "Twitter Followed"} value="4" sx={{minWidth: '30px'}} />
                                <Tab label="search" value="2" sx={{minWidth: '30px'}} />
                                {
                                    isInternal && (
                                        <Tab label="Add Twitter User" value="3" sx={{minWidth: '30px'}} />
                                    )
                                }
                            </TabList>
                        </Box>
                        <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                                {/* search box */}
                                <CustomFeedTwitterUserSearch
                                    searchTermChangeHandler={searchTermChangeHandler}
                                    clearTwitterUserSearch={clearTwitterUserSearch}
                                    setInfluencerHandler={setInfluencerHandler}
                                />
                                {/* influencer, official channel or both */}
                            </Box>
                        </TabPanel>
                        <TabPanel value="3" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                                <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{justifyContent: 'center', alignSelf: 'center'}}>
                                    <Typography variant={'h5'} color={'#fff'}>Add a Twitter user to our database.</Typography>
                                    <Typography variant={'h5'} color={'#fff'}>We'll use their tweets for our market analysis.</Typography>
                                    <TextField
                                        sx={{mt: 2}}
                                        name='twitterUserName'
                                        variant='outlined'
                                        required
                                        value={newTwitterUserVal}
                                        id='twitterUserName'
                                        label='Twitter Username'
                                        onChange={handleNewTwitterUserValChange}
                                        inputProps={{ maxLength: 25 }}
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography sx={{color: '#fff'}}>
                                                        @
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {
                                        (addTwitterUserError && !addTwitterUserSuccess) && (
                                            <Typography color={'red'}  sx={{mt: 1}}>{addTwitterUserError}</Typography>
                                        )
                                    }
                                    {
                                        (addTwitterUserSuccess && !addTwitterUserError) && (
                                            <Typography color={'green'}  sx={{mt: 1}}>{addTwitterUserSuccess}</Typography>
                                        )
                                    }
                                    <Button onClick={submitNewTwitterUser} sx={{mt: 1}} variant={'contained'} color={'primary'}><Typography>Submit</Typography></Button>
                                </Stack>
                            </Box>
                        </TabPanel>
                        <TabPanel value="4" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <React.Fragment>
                                <Modal
                                    open={infoOpen}
                                    onClose={() => setInfoOpen(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={{...style}} >
                                        <Typography sx={{textAlign: 'center', color: '#fff', fontSize: 20}}>
                                            Your Twitter Followed
                                        </Typography>
                                        <Typography sx={{color: '#fff', textAlign: 'center', mt: 2}}>
                                            Manage where Tweets will appear from {productName} followed Twitter accounts.
                                            Add which {productName} followed Twitter accounts you subscribe to by going
                                            to the
                                            <a
                                                href="/#"
                                                style={{color: theme.palette.primary.main, cursor: 'pointer'}}
                                                onClick={() => {
                                                setTabValue("2");
                                                setInfoOpen(false);
                                            }}>{' Search '}
                                            </a> tab.
                                            {/*Manage where Tweets will appear. Followed accounts loaded from your Twitter profile appear first, then Alpha Centoro selected accounts.*/}
                                        </Typography>
                                    </Box>
                                </Modal>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <div/>
                                    <IconButton color={'primary'} onClick={() => setInfoOpen(!infoOpen)}>
                                        <InfoIcon color={'primary'} />
                                    </IconButton>
                                </Stack>
                            </React.Fragment>
                        {
                            (!twitterHandle || !twitterHandle.length) ?
                                <Stack direction={'column'} spacing={3} sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'center', overflow: 'auto', justifyContent: 'center', scrollbarWidth: 'thin'}}>
                                    <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500] }}>
                                        Add your Twitter username here. It may take 1-6 hours to update.
                                    </Typography>
                                    <Stack direction={'row'} spacing={1} sx={{overflowX: 'hidden'}}>
                                        <TextField
                                            id="requestTwitterUsers"
                                            type="text"
                                            label="Add your Twitter Username"
                                            inputProps={
                                                {
                                                    step: 300
                                                }
                                            }
                                            color={'primary'}
                                            value={twitterHandleText}
                                            onChange={event => setTwitterHandleText(event.target.value)}
                                            style={{width: 200}}
                                        />
                                        <Button style={{justifyContent: 'center', alignItems: 'center'}} onClick={() => requestUserTwitterList(twitterHandleText)}>
                                            <ArrowUpward />
                                        </Button>
                                    </Stack>
                                </Stack>
                                :
                                <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', flexDirection:'column', alignItems: 'center', overflow: 'auto', scrollbarWidth: 'thin'}}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon color={'info'} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                           <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
                                               <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500], textAlign: 'center', justifyContent: 'center', alignSelf: 'center', alignItems: 'center',mt:1 }}>
                                                   @{String(twitterHandle)} Twitter Followed
                                               </Typography>
                                           </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Stack justifyContent={'center'} alignItems={'center'} direction={'column'} sx={{pb: 2}}>
                                                {
                                                    userData['imported_twitter'] && userData['imported_twitter'].length ? userData['imported_twitter'].map((item, index) => {
                                                        return (
                                                            <CustomFeedTabFollowSearchItem
                                                                item={item}
                                                                index={index}
                                                                addUserToWhiteListHandler={addUserToWhiteListHandler}
                                                                getTwitterUserTweets={getTwitterUserTweets}
                                                                removeUserFromWhiteList={removeUserFromWhiteList}
                                                            />
                                                        )
                                                    })
                                                    :
                                                    <Typography sx={{color: '#fff', textAlign: 'center'}}>Your Twitter followed list is still being collected, or is empty.</Typography>
                                                }
                                            </Stack>

                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon color={'info'} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500], textAlign: 'center', justifyContent: 'center', alignSelf: 'center', alignItems: 'center',mt:1 }}>
                                                {productName} Twitter Followed
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Stack justifyContent={'center'} alignItems={'center'} direction={'column'} sx={{pb: 2}}>
                                                {
                                                   twitterUserList.map((item, index) => {
                                                        return (
                                                            <CustomFeedTabFollowSearchItem
                                                                item={item}
                                                                index={index}
                                                                addUserToWhiteListHandler={addUserToWhiteListHandler}
                                                                getTwitterUserTweets={getTwitterUserTweets}
                                                                removeUserFromWhiteList={removeUserFromWhiteList}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Stack>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                        }
                        </TabPanel>
                    </TabContext>
                </Box>
            </Grid>
            <Grid item xs={5} sx={{bgcolor: theme.palette.grey[100]}}>
                <Typography variant="h5" sx={{paddingLeft: '10px', paddingTop: '5px', marginBottom: '10px', color: theme.palette.primary.dark}}>Twitter Accounts</Typography>
                {twitterUserList.map( (twitterUser, index) =>
                    <CustomFeedTabFollowSearchItem
                        item={twitterUser}
                        index={index}
                        addUserToWhiteListHandler={addUserToWhiteListHandler}
                        getTwitterUserTweets={getTwitterUserTweets}
                        removeUserFromWhiteList={removeUserFromWhiteList}
                    />
                )}
                <Pagination sx={{marginTop: '10px'}} shape="rounded" count={twitterUserPageCount} page={twitterUserPage} onChange={changePage}/>
            </Grid>
        </Grid>
    )
}

export default CustomFeedTabFollowSearch