import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Box, CircularProgress, Grid, Stack, Typography, Button, Divider, ButtonGroup, Switch, FormControlLabel, Checkbox, ThemeProvider } from '@mui/material'
import { TabContext, TabPanel } from "@mui/lab"
import { styled, useTheme, createTheme } from '@mui/material/styles'
import { CandlestickChart, ContactMailTwoTone } from '@mui/icons-material'
import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'

import Chart from "react-apexcharts"

import fetchData from '../../utility/endpointFetch'
import ChartDisplayByCoinSelector from './ChartDisplayByCoinSelector'
import CoinDetailsChartPriceAlert from '../CoinDetails/CoinDetailsChartPriceAlert'
import ChartDisplayByCoinLWChart from './ChartDisplayByCoinLWChart'
import ChartDisplayByCoinMarket from './ChartDisplayByCoinMarket'

//const ChartDisplayByCoin = forwardRef( ({coins=['btc'], influencer_coin, boxHeight, rowHeight, windowWidth, defaultChartInterval='1d', saveCoinChoiceToPanel, panelID, deleteBox, changeDependency, currentDependency, displayCoinSelector=true, userObject}, componentSetCoinsRef ) => {
    const ChartDisplayByCoin = ({coins=['btc'], influencer_coin, boxHeight, rowHeight, windowWidth, defaultChartInterval='1d', saveCoinChoiceToPanel, panelID, deleteBox, changeDependency, currentDependency, displayCoinSelector=true, userObject} ) => {
    console.log("top of ChartDisplayByCoin and coins is: " + coins)
    console.log("rowHeight is: " + rowHeight)
    console.log("boxHeight is: " + boxHeight)
    console.log("windowWidth is: " + windowWidth)
    //console.log("currentDependency is:")
    //console.dir(currentDependency)
    //console.log("influencer_coin is: ")
    //console.dir(influencer_coin)
    //console.log("componentSetCoinsRef in ChartDisplayByCoin is:")
    //console.dir(componentSetCoinsRef)

    const theme = useTheme()

    //const [isLoading,setIsLoading] = useState(true)
    const [ socialIsLoading,setSocialIsLoading] = useState(true)
    const [ chosenCoin,setChosenCoin] = useState(coins[0])
    const [ chosenCoinID,setChosenCoinID] = useState(influencer_coin)
    const [ chartIntervalLW, setChartIntervalLW ] = useState(defaultChartInterval)
    const [ pageSizeLW,setPageSizeLW] = useState(400) //default to daily candles
    //const [ dateAgo, setDateAgo] = useState(new Date(Date.now() - (400 * 24 * 60 * 60 * 1000)))
    //const [ chartBaseCurrencySymbol, setChartBaseCurrencySymbol ] = useState('$ ')
    //const [ chartBaseCurrency, setChartBaseCurrency ] = useState('USD')
    const [ chartLWRangeDetails, setChartLWRangeDetails ] = useState('1y/daily')  
    const [ displayLegend, setDisplayLegend ] = useState('')

    //apexchart data
    const [ chartDays, setChartDays ] = useState(180)
    const [ chartRangeDetails, setChartRangeDetails ] = useState('6 mths/dly')
    const [ pageSize,setPageSize] = useState(400)
    const [ chartInterval, setChartInterval ] = useState('daily')
    
    //const [chartDays,setChartDays] = useState(3)
    const socialChartDays = 10

    const [ priceAlertOpen,setPriceAlertOpen] = useState(false)
    const [ priceAlert,setPriceAlert] = useState(0)
    const [ priceAlertError,setPriceAlertError] = useState(false)
    const [ alertMultiple,setAlertMultiple] = useState('')    
    const [ tabValue,setTabValue ] = useState('1')
    const [ mixedChartOptions, setMixedChartOptions ] = useState({})
    const [ mixedChartSeries, setMixedChartSeries ] = useState([])
    const [ socialChartChecked,setSocialChartChecked ] = useState(false)
    const [ useLWChart, setUseLWChart ] = useState(false)
    //const [ useLWChart, setUseLWChart ] = useState(false)
    const [ showLWChoice,setShowLWChoice ] = useState(true)

    function setChosenCoinInComponent  ( coinToSet )  {
        console.log("top of setChosendCoinInComponent and coinToSet is: ")
        console.dir(coinToSet)
        setChosenCoin(coinToSet.coins[0])
        setChosenCoinID(coinToSet.influencer_coin)
    }

    //useImperativeHandle( componentSetCoinsRef, () => ({ setChosenCoinInComponent }),[setChosenCoin,setChosenCoinID] )

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: theme.palette.background.default
        },
        '& .MuiButton-root': {
            padding: '3px 3px',
            minWidth: '20px',
        },
        '& .MuiButtonGroup-grouped': {
            minWidth: '20px',
        },
    }));   
    
    const NonColorButton = styled(Button)(({ theme }) => ({
        '& .MuiButton-root': {
            padding: '3px 3px',
            minWidth: '20px',
        },
        '& .MuiButtonGroup-grouped': {
            minWidth: '20px',
        },        
    })); 

    const themeButton = createTheme({
        ...theme,
        components: {
          // Name of the component
          MuiButton: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                minWidth: '20px',
                padding: '3px 3px',
              },
            },
          },
          MuiButtonGroup: {
            styleOverrides: {
              grouped: {
                minWidth: '20px',
              }
            }
          }
        },
      })    

    const handleChartTypeChange = (e) => {
        console.log("top of handleChartTypeChange and changing useLWChart to: " + e.target.checked)
        setUseLWChart(e.target.checked)
    }

    const switchToMarketChartNoData = () => {
        //called by exchangechart/Lightweight chart/candlestick chart returns no data
        console.log("top of switchToMarketChartNoData and setting showLWChoice and useLWChart to false")
        setShowLWChoice(false)
        setUseLWChart(false)
    }

    const myClickHandlerLW = (param) => {
        //console.log("params from click is:")
        //console.dir(param)
        //console.log("param.seriesPrices.keys is:")
        const iterator1 = param.seriesPrices.values()
        let prices = iterator1.next().value
        //console.log("prices is")
        //console.dir(prices)

        //console.log(iterator1.next().value)
        //price will be:
        //param.seriesPrices.0.value = { open, high, low, close}
        //volume will be:
        //param.seriesPrices.1.value = xxxx

        setPriceAlert(prices.close)
        setPriceAlertOpen(true)
        if (!param.point) {
            return;
        }

        setDisplayLegend(`Click at ${param.point.x}, ${param.point.y}. The time is ${param.time}.`);
    }    

    const getSocialData = () => {
        setSocialIsLoading(true)
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            }
        }
        var dateAgo = new Date(Date.now() - (socialChartDays * 24 * 60 * 60 * 1000))
        ;(async () => {
            var socialURLString = `api/v1/coin/influencerchart/?coin=${chosenCoinID}&interval=${chartInterval}&timestamp__gt=${dateAgo.toISOString()}&page_size=${pageSize}&page=1`
            var socialResponse = await fetchData(socialURLString, "could not receive influencer chart data in ResearhChart", fetchParams).promise
            //console.log("socialResponse getting influencer chart data for "+ chosenCoinID +" is:")
            //console.dir(socialResponse)
            //console.log("socialURLString is")
            //console.dir(socialURLString)
            //find min max prices            

            if (socialResponse.count > 0) { 

                let dateArr = socialResponse.results.map((data) => {
                    let date = new Date(data.timestamp)
                    let year = date.getFullYear()
                    let month = date.getMonth()
                    let day = date.getDate()
        
                    return day + '-' + month + '-' + year
                })

                var legendPosition = 'left'

                let socialRankDataArrWithTimeStamp = socialResponse.results.map((data) => {
                    let newDateObj = data.timestamp
                    if(!String(data.timestamp).includes('T')){
                        newDateObj = String(newDateObj).concat('T00:00:00')
                    }
                    return [newDateObj, data.social_rank]
                })
    
                let socialEngDataArrWithTimeStamp = socialResponse.results.map((data) => {
                    let newDateObj = data.timestamp
                    if(!String(data.timestamp).includes('T')){
                        newDateObj = String(newDateObj).concat('T00:00:00')
                    }
                    return [newDateObj, data.total_social_engagement]
                })

                let mixedSeries = []
                if (socialRankDataArrWithTimeStamp && socialRankDataArrWithTimeStamp.length) {
                    mixedSeries.push({
                    name: "Social Rank",
                    type: "area",
                    data: socialRankDataArrWithTimeStamp
                    })
                }
                if (socialEngDataArrWithTimeStamp && socialEngDataArrWithTimeStamp.length) {
                    mixedSeries.push({
                    name: "Ttl Social Engagement",
                    type: "area",
                    data: socialEngDataArrWithTimeStamp
                    })
                }
                setMixedChartSeries(mixedSeries)
                console.log("mixedSeries is:")
                console.dir(mixedSeries)

                /*  currently not using...setting fixed min/max to get a better scale between coins
                let minMaxSocialRank = socialResponse.results.map((data) => {
                    return data.social_rank
                })
        
                let chartMaxSocialRank = minMaxSocialRank.reduce((a, b) => {
                    return Math.max(a, b)
                })
                let chartMinSocialRank = minMaxSocialRank.reduce((a, b) => {
                    return Math.min(a, b)
                })
        
                let minMaxSocialEng = socialResponse.results.map((data) => {
                    return data.total_social_engagement
                })
        
                let chartMaxSocialEng = minMaxSocialEng.reduce((a, b) => {
                    return Math.max(a, b)
                })
                let chartMinSocialEng = minMaxSocialEng.reduce((a, b) => {
                    return Math.min(a, b)
                })       
                */                
                
                var chartId = "soc-eng-rank-chart-"+chosenCoin
                var chartGroup = "researchcustom-chart-"+chosenCoin
                let ySeries = []       
                ySeries.push( {
                    seriesName: "Social Rank",
                    opposite: true,
                    axisTicks: {
                        "show": true,
                    },
                    show: true,
                    //min: chartMinSocialRank,
                    //max: chartMaxSocialRank,
                    min: 0,
                    max: 5,
                    tooltip: {
                        shared: true,
                        intersect: false,
                        x: {
                            show: false,
                        },
                        marker: {
                            show: true,
                        },
                    },
                    labels: {
                        formatter: function (value,index) {
                            return value.toFixed(1)
                        }
                    }                    
                }) 
                ySeries.push({
                    seriesName: "Ttl Social Engagement",
                    opposite: false,
                    show: true,
                    //min: chartMinSocialEng,
                    //max: chartMaxSocialEng,
                    min: 0,
                    max: 200000,
                    tooltip: {
                        shared: true,
                        intersect: false,
                        x: {
                            show: false,
                        },
                        marker: {
                            show: true,
                        },
                    },
                    labels: {
                        formatter: function (value,index) {
                            //these values are currently large...ie 200,000 plus
                            //need to 
                            return (value/1000)+"K"
                        }
                    }
                })       
                
                setMixedChartOptions({
                    chart: {
                        type: 'line',
                        id: chartId,
                        group: chartGroup,
                        toolbar: {
                            show: false,
                        },
                        background: theme.palette.background.default,
                        events: {
                            click: function (event, chartContext, config) {
                                if (userObject.user.is_vip) {
                                    //console.log("top of chart click event (event, chartContext, config):")
                                    //console.dir(event)
                                    //console.dir(chartContext)
                                    //console.dir(config)
                                    //console.log("default price alert set to: " + config.globals.series[0][config.dataPointIndex])
                                    if (config.globals.series[0][config.dataPointIndex] !== undefined) {
                                        setPriceAlert(config.globals.series[0][config.dataPointIndex])
                                        setPriceAlertOpen(true)
                                    }
                                } else {
                                    //this is a vip only feature
                                }
                            }
                        }
                    },
                    colors: ['#10459c', '#eba834'],
                    markers: {
                        size: [3,3],
                        colors: ['#10459c', '#eba834'],
                        strokeColor: ['#10459c', '#eba834'],
                        strokeWidth: 2
                    },
                    stroke: {
                        show: true,
                        width: [2, 2]
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    labels: dateArr,
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            show: true,
                        }
                    },
                    yaxis: ySeries,
                    fill: {
                        type: 'gradient',
                        gradient: {
                        shade: 'light',
                        type: "vertical",
                        shadeIntensity: 1,
                        gradientToColors: [theme.palette.background.default],
                        inverseColors: false,
                        opacityFrom: 0.9,
                        opacityTo: 0.5,
                        },
                    },
                    tooltip: {
                        shared: true,
                        theme: 'dark',
                        x: {
                            show: false,
                            format: 'dd MMM yyyy',
                        },
                        y: {
                            formatter: function (value, index) {
                                if (value === index.series[0][index.dataPointIndex]) {
                                    //this is social rank...
                                    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                } else if (value === index.series[1][index.dataPointIndex]) {
                                    //this is total social engagement
                                    return value
                                } else {
                                    // not sure what it is...return unchanged
                                    return value
                                }
                            }
                        },
                    },
                    legend: {
                        position: legendPosition,
                        floating: true,
                        offsetX: 10,
                        offsetY: 10,
                        show: false,
                        labels: {
                        colors: theme.palette.grey[300]
                        }
                    },

                })                            

                setSocialIsLoading(false)
            } else {
                //there is no social data to display
                console.log("there is no social data to display")
                setSocialIsLoading(false)
            }
        })()
    }

    const changeInChartCoin = (newChartCoin,newChartCoinID) => {
        //console.log("top of changeInChartCoin and newChartCoin is:")
        //console.dir(newChartCoin)
        setSocialChartChecked(false)
        setTabValue('1')
        setChosenCoin(newChartCoin)
        setChosenCoinID(newChartCoinID)
        saveCoinChoiceToPanel([newChartCoin],newChartCoinID,panelID)

    }    

    const handleChange = (event) => {
        setAlertMultiple(event.target.value);
    }    

    const handlePriceAlertChange = e => {
        //console.log("top of handlePriceAlertChange and e.target.value is:")
        //console.dir(e.target.value)
        setPriceAlertError(false)
        setPriceAlert(e.target.value)
    }    

    const saveAlert = e => {
        e.preventDefault()
        //console.log("top of saveAlert")

        if ((priceAlert === 0) || (priceAlert === '')) {
            setPriceAlertError(true)
        } else if (priceAlert > 0) {
            //console.log("priceAlert is greater than 0")
            if (userObject.user.is_vip) {
                const fetchParams = {
                    method: 'POST',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'coin': coins[0], 
                        'price_target': priceAlert,
                        'multiple': alertMultiple,
                    })        
                }
                ;(async () => {
                    try {
                        var urlString = `api/v1/vip/alertcreate/`
                        const saveAlertResponse = await fetchData(urlString, "could not save new price alert", fetchParams).promise
                        //console.log("saveAlertResponse saving new price alert: ")
                        //console.log(urlString)
                        //console.dir(saveAlertResponse)
                    } catch (error) {
                        console.log("something is broke new price alert: ", error)
                    }
                })()
            }
            setPriceAlertOpen(false)
        } else {
            console.log("priceAlert is zero or less")
            setPriceAlertError(true)
        }
    }
    
    const cancelAlert = e => {
        setPriceAlertOpen(false)  
    }    

    const handleChartIntervalChange = ( interval) => {
        //console.log("top of handleChartIntervalChange...interval is: ")
        //console.dir(interval)
        switch (interval) {
          case '1w':
            setChartLWRangeDetails('2y/wkly')
            setChartIntervalLW('1w')
            setPageSizeLW(120)
            break;        
          case '1d':
            setChartLWRangeDetails('1y/dly')
            setChartIntervalLW('1d')
            setPageSizeLW(400)
            break;
          case '4h':
            setChartLWRangeDetails('3m/4hr')
            setChartIntervalLW('4h')
            setPageSizeLW(540) 
            break;
          case '1h':
            setChartLWRangeDetails('14d/1hr')
            setChartIntervalLW('1h')
            setPageSizeLW(336) //14 days
            break;
          case '15m':
            setChartLWRangeDetails('4d/15m')
            setChartIntervalLW('15m')
            setPageSizeLW(384)
            break;
          case '5m':
            setChartLWRangeDetails('2d/5m')
            setChartIntervalLW('5m')
            setPageSizeLW(576)
            break;
          case '1m':
            setChartLWRangeDetails('12h/1m')
            setChartIntervalLW('1m')
            setPageSizeLW(720)
            break;
          default:
            //not sure what this is
            setChartLWRangeDetails('')
        }
    }

    const openPriceAlert = (defaultPrice) => {
        setPriceAlertOpen(true)
        setPriceAlert(defaultPrice)
    }

    const handleChartTimeChange = ( days) => {
        //console.log("top of handleChartTimeChange...days is: ")
        //console.dir(days)
        setChartDays(days)
        if (days > 14) {
          setChartInterval('daily')
          setPageSize(days)
        } else if (days > 3) {
          setChartInterval('hourly')
          setPageSize(days*24)
        } else if (days > 1) {
          setChartInterval('quarter_hour')
          setPageSize(days*96)
        } else {
          setChartInterval('5m')
          setPageSize(days*288)
        }
    
        switch (days) {
          case 365:
            setChartRangeDetails('1y/dly')
            break;
          case 180:
            setChartRangeDetails('6m/dly')
            break;
          case 90:
            setChartRangeDetails('3m/dly')
            break;
          case 30:
            setChartRangeDetails('1m/dly')
            break;
          case 14:
            setChartRangeDetails('2w/hrly')
            break;
          case 7:
            setChartRangeDetails('1w/hrly')
            break;
          case 3:
            setChartRangeDetails('3d/15m')
            break;
          case 1:
            setChartRangeDetails('1d/5m')
            break;
          default:
            //not sure what this is
            setChartRangeDetails('')
        }
    }    

    const changeCustomDependency = (newDependency) => {
        changeDependency(panelID,newDependency)
    }    

    const removeChart = () => {
        //console.log("top of removeChart and panelID is: " + panelID)
        //chartRef.current = <div sx={{width: '100%'}}><CircularProgress /></div>
        deleteBox(panelID)
    }

    const backToTabOne = () => {
        //console.log("top of backToTabOne and socialChartChecked is: ")
        //console.dir(socialChartChecked)
        //console.log("tabValue is:")
        //console.dir(tabValue)
        if (socialChartChecked === true) {
            //console.log("socialChartChecked is true")
            setSocialChartChecked(false)
        }
        if (tabValue === '2') {
            //console.log("tabValue is 2")
            setTabValue('1')
            //firstRun.current = true
        }
    }

    const handleDisplaySocialChart = (e) => {
        //console.log("top of handleDisplaySocialChart and e.target.checked is:")
        //console.dir(e.target.checked)
        if (e.target.checked) {
            //display the social chart
            getSocialData()
            setSocialChartChecked(true)
            setTabValue('2')
        } else {
            //do not display social chart
            setSocialChartChecked(false)
            //firstRun.current = true
            setTabValue('1')
        }
    }

    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 51,
        height: 28,
        padding: 2,
        '& .MuiSwitch-switchBase': {
          margin: 1,
          padding: 0,
          transform: 'translateX(6px)',
          '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(20px)',
            '& .MuiSwitch-thumb:before': {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 22 22"><path fill="${encodeURIComponent(
                '#fff',
              )}" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" /></svg>')`,
            },
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.secondary.light,
            },
          },
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.palette.primary.dark,
          width: 24,
          height: 24,
          '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 22 22"><path fill="${encodeURIComponent(
              '#fff',
            )}" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" /></svg>')`,
          },
        },
        '& .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 20 / 2,
        },
      }));

    //if (isLoading) return <CircularProgress />

    console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++top of return for ChartDisplayByCoin")
    console.log("useLWChart is: " + useLWChart)
    console.log("showLWChoice is:" +showLWChoice)
    return (
        <ThemeProvider theme={themeButton}>
        <Box
            sx={{
                paddingTop: '5px',
                display: 'flex', 
                width: 'calc(100% - 20px)',
                marginLeft: '20px'
            }}
        >      
            <Stack direction="column" sx={{width: 'calc(100% - 10px)'}}>
                <Stack direction="row" alignItems="center" justifyContent='space-between'>
                    { useLWChart ?
                        <Typography color={theme.palette.primary.main}>{chosenCoin.toUpperCase() + " " + chartLWRangeDetails}</Typography>
                        :
                        <Typography color={theme.palette.primary.main}>{chosenCoin.toUpperCase() + " " + chartRangeDetails}</Typography>
                    }
                    {/*<Chip label={chosenCoin.toUpperCase() + " " + chartRangeDetails} sx={{backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.light, borderRadius: '10px'}} variant="outlined" />*/}
                    <FormControlLabel
                        control={<MaterialUISwitch  onChange={handleDisplaySocialChart} checked={socialChartChecked} />}
                        
                        label=''
                    />
                    { useLWChart ? 
                        <ButtonGroup disabled={tabValue === '2'? true : false} size="small" aria-label="choose interval in chart" sx={{paddingRight: '5px', '& .MuiButtonGroup-grouped': { minWidth: '20px', },}}>
                            { chartIntervalLW === '1w' ? <ColorButton  sx={{'& .MuiButton-root': { padding: '3px 3px', minWidth: '20px',}, }} variant="contained">1w</ColorButton> : <NonColorButton  onClick={() => handleChartIntervalChange('1w',)} sx={{'& .MuiButton-root': { padding: '3px 3px', minWidth: '20px',}, }}>1w</NonColorButton> }
                            { chartIntervalLW === '1d' ? <ColorButton  variant="contained">1d</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('1d',1)}>1d</NonColorButton> }
                            { chartIntervalLW === '4h' ? <ColorButton  variant="contained">4h</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('4h',3)}>4h</NonColorButton> }
                            { chartIntervalLW === '1h' ? <ColorButton  variant="contained">1h</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('1h',7)}>1h</NonColorButton> }
                            { chartIntervalLW === '14m' ? <ColorButton variant="contained">15m</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('15m')}>15m</NonColorButton> }
                            { chartIntervalLW === '5m' ? <ColorButton  variant="contained">5m</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('5m')}>5m</NonColorButton> }
                            { chartIntervalLW === '1m' ? <ColorButton  variant="contained">1m</ColorButton> : <NonColorButton onClick={() => handleChartIntervalChange('1m')}>1m</NonColorButton> }
                        </ButtonGroup> 
                        :
                        <ButtonGroup size="small" aria-label="small button group" sx={{paddingRight: '5px', '& .MuiButtonGroup-grouped': { minWidth: '20px', }, }}>
                            { chartDays === 1 ? <ColorButton  variant="contained">1D</ColorButton> : <Button onClick={() => handleChartTimeChange(1)}>1D</Button> }
                            { chartDays === 3 ? <ColorButton  variant="contained">3D</ColorButton> : <Button onClick={() => handleChartTimeChange(3)}>3D</Button> }
                            { chartDays === 7 ? <ColorButton  variant="contained">1W</ColorButton> : <Button onClick={() => handleChartTimeChange(7)}>1W</Button> }
                            { chartDays === 14 ? <ColorButton  variant="contained">2W</ColorButton> : <Button onClick={() => handleChartTimeChange(14)}>2W</Button> }
                            { chartDays === 30 ? <ColorButton variant="contained">1M</ColorButton> : <Button onClick={() => handleChartTimeChange(30)}>1M</Button> }
                            { chartDays === 90 ? <ColorButton  variant="contained">3M</ColorButton> : <Button onClick={() => handleChartTimeChange(90)}>3M</Button> }
                            { chartDays === 180 ? <ColorButton  variant="contained">6M</ColorButton> : <Button onClick={() => handleChartTimeChange(180)}>6M</Button> }
                            { chartDays === 365 ? <ColorButton  variant="contained">1Y</ColorButton> : <Button onClick={() => handleChartTimeChange(365)}>1Y</Button> }
                        </ButtonGroup>                        

                    }
                    { showLWChoice ?
                        <Stack direction="row" justifyContent='space-around' alignItems='center'>
                            <CandlestickChart 
                                sx={{
                                    color: theme.palette.primary.dark
                                }}
                            />
                            <Checkbox 
                                size="small" 
                                checked={useLWChart} 
                                onChange={handleChartTypeChange}
                            />
                        </Stack> : null }
                    <Stack direction="row" justifyContent='flex-end'>
                        {displayCoinSelector ? 
                            <ChartDisplayByCoinSelector 
                                backToTabOne={backToTabOne}
                                selectedChartCoin={chosenCoin}
                                changeInChartCoin={changeInChartCoin}  
                                changeCustomDependency={changeCustomDependency}
                                currentDependency={currentDependency}                                              
                            /> 
                            : null
                        }
                        <VscMove 
                            className='draggable-handle' 
                            color={theme.palette.warning.dark} 
                            size={18} 
                            style={{cursor: 'grabbing'}}    
                        />
                        <MdCancel 
                            className='draggable-cancel' 
                            color={theme.palette.secondary.dark} 
                            size={18} 
                            onClick={removeChart}
                            style={{cursor: 'pointer'}}
                        />
                    </Stack>
                </Stack>
                <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>
                <TabContext value={tabValue} sx={{marginTop: '20px'}}>
                    <TabPanel value='1' >
                        <Grid container >
                            <Grid item xs={12}>
                                {displayLegend}
                            </Grid>
                            <Grid item xs={12}  >
                                { useLWChart ? 
                                    <ChartDisplayByCoinLWChart 
                                        chosenCoin={chosenCoin}
                                        chartInterval={chartIntervalLW}
                                        pageSize={pageSizeLW}
                                        boxHeight={boxHeight}
                                        rowHeight={rowHeight}
                                        myClickHandler={myClickHandlerLW}
                                        switchToMarketChartNoData={switchToMarketChartNoData}
                                    /> : 
                                    <ChartDisplayByCoinMarket 
                                        chartInterval={chartInterval}
                                        chartDays={chartDays}
                                        pageSize={pageSize}
                                        coinToChartID={chosenCoinID}
                                        openPriceAlert={openPriceAlert}
                                        chartHeight={(rowHeight * boxHeight) - 60}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value='2' >
                    {socialIsLoading ? <CircularProgress /> :
                        <Chart
                            options={mixedChartOptions}
                            series={mixedChartSeries}
                            type="area"
                            height={(rowHeight * boxHeight) - 50}
                            width='100%'
                        />
                    }
                    </TabPanel>
                </TabContext>
            </Stack>
            <CoinDetailsChartPriceAlert
                priceAlertOpen={priceAlertOpen}
                priceAlert={priceAlert}
                handlePriceAlertChange={handlePriceAlertChange}
                priceAlertError={priceAlertError}
                coinID={coins[0]}
                handleChange={handleChange}
                alertMultiple={alertMultiple}
                saveAlert={saveAlert}
                cancelAlert={cancelAlert}
            />            
        </Box>
        </ThemeProvider>
    )
}

export default ChartDisplayByCoin