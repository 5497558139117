import React, { useState } from 'react'
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material'
import { Clear, Search, DisabledByDefault } from "@mui/icons-material"
import { GrClear } from 'react-icons/gr'

import { useTheme } from '@mui/material/styles'

const CustomTweetFeedSearchBox = ( { type, searchFeed, clearSearchResults } ) => {

    const theme = useTheme()

    const [searchTerm,setSearchTerm] = useState('')

    var searchFieldLabel = ''
    switch (type) {
        case '1':
            searchFieldLabel = "search custom feed one"
            break
        case '2':
            searchFieldLabel = "search custom feed two"
            break
        case '3':
            searchFieldLabel = "search custom feed two"
            break                            
    }

    const handleSearchTextFieldChange = (e) => {
        //console.log("top of handleSearchTextFieldChange and e.target.value is:" + e.target.value)
        setSearchTerm(e.target.value)
    }

    const searchThisFeed = () => {
        //console.log("top of searchThisFeed and type is: " + type)
        if (searchTerm.length > 0) {
            searchFeed(searchTerm)
            setSearchTerm('')
        }
    }

    const clearSearch = () => {
        clearSearchResults(type)
    }


    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{pb: 1}}>
            <TextField
                id="search-feed"
                label={searchFieldLabel}
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchTextFieldChange}
                size="small"

                InputProps={{
                    startAdornment: <InputAdornment position="start">@</InputAdornment>,
                }}
                sx={{
                    width: '100%',
                    minWidth: '80px',
                    color: theme.palette.grey[500],
                    input: {
                        background: theme.palette.dark[900],
                    },
                }}
                InputLabelProps={{
                    style: {
                        color: theme.palette.grey[500],
                    },
                }}
                inputProps={{
                    style: { height: '12px' },
                }}
            />
            <IconButton onClick={() => {
                setSearchTerm('')
                clearSearch()
            }}>
                <Clear sx={{color: theme.palette.primary.main }} />
            </IconButton>
            <IconButton onClick={() => searchThisFeed()}>
                <Search sx={{color: theme.palette.primary.main }} />
            </IconButton>
            <IconButton onClick={()=>clearSearchResults(type)}>
                <DisabledByDefault sx={{color: theme.palette.primary.main }} />
            </IconButton>
        </Stack>        
    )
}

export default CustomTweetFeedSearchBox