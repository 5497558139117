import React from 'react'

import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const ChartDisplayByCoinSelectorItem = ( {selectedCoin, coin, saveCoinToSelection} ) => {
    //console.log("top of ChartDisplayByCoinSelectorItem")
    //console.log("coin is: ")
    //console.dir(coin)
    //console.log("selectedCoin is:")
    //console.dir(selectedCoin)

    const theme = useTheme()

    var coinLabel = coin.name + " ("+ coin.coin.symbol+")"

    var coinCss = {
        color: theme.palette.grey[300],
        cursor: 'pointer',
    }
    var coinVariant = "subtitle"
    if (selectedCoin === coin.coin.symbol) {
        //this is the selected coin
        coinCss = {
            color: theme.palette.primary.main,
        }
        coinVariant = "subtitle2"
    }

    return (
        <Typography 
            key={coin.coin.symbol}
            sx={coinCss} 
            onClick={(e) => saveCoinToSelection(coin.coin.symbol,coin.coin.id)}
            variant={coinVariant}
        >
            {coinLabel}
        </Typography>
    )
}

export default ChartDisplayByCoinSelectorItem