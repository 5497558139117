import React, { useState, useEffect, useRef } from 'react'
import { Grid, Typography, Link, CircularProgress, Box, Tooltip, IconButton, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { abbreviateNumber } from "js-abbreviation-number"
import fetchData from "../../utility/endpointFetch"

import { AiFillBell } from "react-icons/ai"
//import { RiVipCrownFill } from 'react-icons/ri'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { formatFloatToCurrency } from "../../utility/number";
//import { Area, AreaChart, XAxis, YAxis } from "recharts";

const ScreenerCurrencyListItem = ( {currencyDetails, userObject, deleteFromScreener, screenerCoinId, isWindowSmall, visibleColumns } ) => {
  //console.log("top of ScreenerCurrencyListItem")
  //console.log("currencyDetails is: ")
  //console.dir(currencyDetails)
  //console.log("screenerCoinId is: ")
  //console.dir(screenerCoinId)
  //console.log(userObject.coin.includes(currencyDetails.coin))
  //console.log("userObject is:")
  //console.dir(userObject)

  const theme = useTheme();

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  //const anchorRef = useRef(null);

  var six_months_ago = new Date()
  six_months_ago.setMonth(six_months_ago.getMonth() - 6)
  //console.log(six_months_ago.toDateString())
  //console.log(six_months_ago.toISOString())

  var dateSixMonthsAgo = new Date()
  dateSixMonthsAgo.setMonth(dateSixMonthsAgo.getMonth() - 6)

  /*const [ isLoading, setIsLoading ] = useState(true)
  const [ chartMax,setChartMax ] = useState(0)
  const [ chartMin,setChartMin] = useState(0)
  const [ differenceInPricePercentage,setDifferenceInPricePercentage] = useState(0)
  const [ priceColorOneDay, setPriceColorOneDay] = useState(theme.palette.success.main)
  const [ priceColorOneWeek, setPriceColorOneWeek] = useState(theme.palette.success.main)
  const [ priceColorTwoWeek, setPriceColorTwoWeek] = useState(theme.palette.success.main)
  const [ priceColorOneMonth, setPriceColorOneMonth] = useState(theme.palette.success.main)
  const [ priceColorThreeMonth, setPriceColorThreeMonth] = useState(theme.palette.success.main)
  const [ priceColorSixMonth, setPriceColorSixMonth] = useState(theme.palette.success.main)
const [ priceColorOneYear, setPriceColorOneYear] = useState(theme.palette.success.main) */
  
  const [ priceIcon,setPriceIcon] = useState(<ArrowUpward fontSize="small" sx={{ position: 'relative', top: '6px'}}/>)
  const [ percentBTCDiffColor,setPercentBTCDiffColor] = useState(theme.palette.grey[500])
  const [ negPosBTCPercentage, setNegPosBTCPercentage ] = useState('')
  const [ percentETHDiffColor,setPercentETHDiffColor] = useState(theme.palette.grey[500])
  const [ negPosETHPercentage, setNegPosETHPercentage ] = useState('')
  const [ coinAlertList,setCoinAlertList] = useState([])
  const [ priceColor, setPriceColor] = useState(theme.palette.success.main)

  const [coinIsVIP,setCoinIsVIP] = useState(userObject.coin.includes(currencyDetails.coin))

  const detailLink = "coindetail/"+currencyDetails.coin

  //remove useEffect unless we reinstitute the 1d, 1w, 2w 1m display
  //but when do get too many re-renders...so there for now

  const removeFromVIP = () => {
   
    ;(async () => {
      try {
        var fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }        
        const getVIPResponse = await fetchData(`api/v1/users/userdata/`, "could not user data and vip data in remove vip", fetchParams).promise
        //console.log("removeFromVIP getting user data and vip in remove vip")
        //console.dir(removeFromVIP) 
        var newVIP = getVIPResponse.coin.filter( vip => vip != currencyDetails.coin)

        fetchParams = {
          method: 'PUT',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'coin': newVIP,
            'user': userObject.user,
            'twitter_friend_list': userObject.twitter_friend_list
          })
        }
        ;(async () => {
          const removeVIPResponse = await fetchData(`api/v1/users/userdata/`, "could not user data and vip data in remove vip", fetchParams).promise
          //console.log("removeVIPResponse getting user data and vip in remove vip")
          //console.dir(removeVIPResponse) 
          if (removeVIPResponse) setCoinIsVIP(false) //removeVIPResponse is true if removed...and false otherwise
        })()        
      } catch (error) {
        console.log("something is broke getting vip  in CurrenciesListItem removeFromVIP" + error)
      }
    })()    
  }

  const addToVIP = () => {
    ;(async () => {
      try {
        var fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }         
        const getVIPResponse = await fetchData(`api/v1/users/userdata/`, "could not user data and vip data in addToVIP", fetchParams).promise
        //console.log("addToVIP getVIPResponse getting user data and vip in addToVIP")
        //console.dir(getVIPResponse) 
        let newVIP = [...getVIPResponse.coin, currencyDetails.coin]

        fetchParams = {
          method: 'PUT',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'coin': newVIP,
            'user': userObject.user,
            'twitter_friend_list': userObject.twitter_friend_list
          }),
        }
        ;(async () => {
          try {
            const addVIPResponse = await fetchData(`api/v1/users/userdata/`, "could not add vip to addToVIP", fetchParams).promise
            //console.log("addVIPResponse adding vip addToVIP: " + currencyDetails.coin)
            //console.dir(addVIPResponse)
            if (addVIPResponse) setCoinIsVIP(true)  //addVIPResponse is true if removed...and false otherwise
          } catch (error) {
            console.log("could not add vip to coin vip in addToVIP: "+error)
          }
        })()
      } catch (error) {
        console.log("something is broke getting vip in CurrenciesListItem addToVIP: " + error)
      }
    })()    
  }

  const displayAlerts = () => {
    if (coinAlertList.length > 0) {
      let listOfAlertPrices = coinAlertList.map( (alert, index) => alert.price_target)
      return (
        <Tooltip title={"price alert(s): " + listOfAlertPrices.join(', ')} >
          <IconButton size="small" sx={{position: 'relative', top: '15px'}}>
            <AiFillBell color={theme.palette.warning.main} sx={{fontSize: '1.2rem', }}/>
          </IconButton>
        </Tooltip>
      )
    } else {
      return null
    }
  }

  const displayLargeNumber = (value) => {
    if (value > 0 )
      return abbreviateNumber(value, 2, {padding: false, symbols: ["", "k", "M", "B", "T", "P", "E"]} )
    else
      return value
  }

  const displayNumber = (number) => {
    return parseFloat(number).toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 2})
  }

  useEffect(() => {
    if (currencyDetails.btc_24h_dif > 0) {
      setPercentBTCDiffColor(theme.palette.success.dark)
      setNegPosBTCPercentage('+')
    } else if (currencyDetails.btc_24h_dif < 0) {
      setPercentBTCDiffColor(theme.palette.error.dark)
      setNegPosBTCPercentage('-')
    }

    if (parseFloat(currencyDetails.eth_24h_dif) > 0) {
      setPercentETHDiffColor(theme.palette.success.dark)
      setNegPosETHPercentage('+')
    } else if (parseFloat(currencyDetails.eth_24h_dif) < 0) {
      setPercentETHDiffColor(theme.palette.error.dark)
      setNegPosETHPercentage('-')
    }

    if (parseFloat(currencyDetails.day_1_price_change).toFixed(2) < 0) {
      //price went down over chart
      setPriceColor(theme.palette.error.main)
    }
  }, [])

  const typographyStyle = {position: 'relative', zIndex: -1 }
  const displayPriceChange = ( label, priceChange ) => {

    let priceColor = theme.palette.success.dark
    let priceValueColor = theme.palette.grey[50]
    if (priceChange < 0) {
        priceColor = theme.palette.error.dark
        priceValueColor = theme.palette.grey[500]
    }

    if (isNaN(priceChange)) priceChange = '~'
        {/*<Box sx={ {borderRadius: '10px', padding: '3px', backgroundColor: theme.palette.dark[800], top: '25%'}}>
            <Stack direction="row" display="flex" alignItems="center">
                <Typography sx={{fontWeight: 400, color: theme.palette.grey[400] }} display="inLine">{label}</Typography>
            </Stack>
        </Box>   */}                   
    return (
      <Box justifyContent='center' alignItems='center' sx={[typographyStyle, {top: '35%',  borderRadius: '10px',  paddingLeft: '5px', backgroundColor: priceColor, maxWidth: '80%'}]}>
          <Typography sx={{fontSize: '.8em', color: priceValueColor, fontWeight: 400,  }} >{priceChange}%</Typography>
      </Box>
    )
  }  

  const displayPrice = (price) => {
    if (price.toString().includes('e')) {
      //this has scientific notation
        return price.toFixed(price.toString().split('-')[1]);
    } else {
      return price
    }
  }
    
  return (
    <Grid container
      maxWidth="false"
      sx={{
        borderBottom: '.25px solid',
        borderColor: theme.palette.grey[100],
        p: 1
      }}
      key={currencyDetails.coin}
    >
      <Grid item xs={1.75}
        sx={{textAlign: 'right'}} component={Link} href={detailLink} underline="none" display="flex" alignItems="center" justifyContent="flex-start"
      >
        <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" >
          <Typography sx={{marginRight: '15px'}}><img src={ currencyDetails.image_thumbnail !== null ? currencyDetails.image_thumbnail : null } alt={currencyDetails.coin} /></Typography>
          <Typography noWrap={true} sx={[typographyStyle, {fontWeight: '800', top: '45%', textAlign: 'left'}]}>{currencyDetails.coin}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={.5} sx={{display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center'}} component={Link} href={detailLink} underline="none">
        <Typography sx={[typographyStyle]}>{String(currencyDetails.coin_symbol).toUpperCase()}</Typography>
      </Grid>
      {
          visibleColumns['Current Price'] && (
              <Grid item xs={1.75} component={Link} href={detailLink} underline="none">
                <Box display="flex" justifyContent="flex-end" alignItems='center' sx={{width: '100%', flex: 1, marginRight: '10px'}}>
                  <Typography sx={[typographyStyle, {color: priceColor}]}>{formatFloatToCurrency(currencyDetails.current_price)} USD ({parseFloat(currencyDetails.day_1_price_change).toFixed(2)}%{priceIcon})</Typography>
                </Box>
              </Grid>
          )
      }
      {
          visibleColumns['24h High'] &&
          <Grid item xs={.75} justifyContent={'center'} alignItems={'center'} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '10px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{formatFloatToCurrency(currencyDetails.high_24h)}</Typography>
          </Grid>
      }
      {
          visibleColumns['24h Low'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{formatFloatToCurrency(currencyDetails.low_24h)}</Typography>
          </Grid>
      }

      {
          visibleColumns['24h Volume'] && (
              <Grid sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px'}} item xs={1} component={Link} href={detailLink} underline="none">
                <Box display="flex" justifyContent="center" alignItems='center'>
                  <Typography sx={typographyStyle}>{displayLargeNumber(currencyDetails.total_volume)}</Typography>
                </Box>
              </Grid>
          )
      }
      {/* <Grid item xs={1.75} sx={{ zIndex: -1 }}>
        <Box display="flex" justifyContent="center" alignItems='center'>
          { isLoading ? <CircularProgress sx={{zIndex: -1, marginTop: '10px'}} size={25} /> :
            <LineChart
              width={150}
              height={50}
              data={rechartsVolumeData}
            >
              <XAxis hide={true} />
              <YAxis hide={true} domain={[volumeMin,volumeMax]}/>
              <Line tickLine={true} type="monotone" dataKey="value" dot={false} stroke={theme.palette.primary.main} />
            </LineChart>
          }
        </Box>
        </Grid>*/}
      {
          visibleColumns['Market Cap'] && (
              <Grid item xs={1} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
                <Typography sx={[typographyStyle, ]}>${displayLargeNumber(currencyDetails.market_cap)} USD</Typography>
              </Grid>
          )
      }
      {
          visibleColumns['BTC Dif'] && (
              <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
                <Typography sx={[typographyStyle, {color: percentBTCDiffColor}]} >{negPosBTCPercentage}{!isNaN(parseFloat(currencyDetails.btc_24h_dif)) ? parseFloat(Math.abs(currencyDetails.btc_24h_dif)).toFixed(2) : '--'}</Typography>
              </Grid>
          )
      }
      {
          visibleColumns['ETH Dif'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle, { color: percentETHDiffColor}]} >{negPosETHPercentage}{!isNaN(parseFloat(currencyDetails.eth_24h_dif)) ? parseFloat(Math.abs(currencyDetails.eth_24h_dif)).toFixed(2) : '--'}</Typography>
          </Grid>
      }
      {
          visibleColumns['Market Cap Rank'] &&
          <Grid item xs={.5} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{currencyDetails.market_cap_rank}</Typography>
          </Grid>
      }
      {
          visibleColumns['5 Minute Price Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{formatFloatToCurrency(currencyDetails.min_5_price_change)}</Typography>
          </Grid>
      }
      {
          visibleColumns['15 Minute Price Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{formatFloatToCurrency(currencyDetails.min_15_price_change)}</Typography>
          </Grid>
      }
      {
          visibleColumns['Social Rank'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.social_rank).toFixed(4)}</Typography>
          </Grid>
      }
      {
          visibleColumns['Total Social Engagement'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.total_social_engagement)}</Typography>
          </Grid>
      }
      {
          visibleColumns['1 Hour Price Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.hour_1_price_change).toFixed(2)}%</Typography>
          </Grid>
      }
      {
          visibleColumns['4 Hour Price Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.hour_4_price_change).toFixed(2)}%</Typography>
          </Grid>
      }
      {
          visibleColumns['1 Week Price Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center',  flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.day_7_price_change).toFixed(2)}%</Typography>
          </Grid>
      }
      {
          visibleColumns['2 Week Price Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.day_14_price_change).toFixed(2)}%</Typography>
          </Grid>
      }
      {
          visibleColumns['Daily Influencer Posts'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseInt(currencyDetails.daily_influencer_post_count)}</Typography>
          </Grid>
      }
      {
          visibleColumns['24 Hour Follower Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.day_1_follower_change).toFixed(2)}%</Typography>
          </Grid>
      }
      {
          visibleColumns['1 Month Follower Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.month_1_follower_change).toFixed(2)}%</Typography>
          </Grid>
      }
      {/*{*/}
      {/*    visibleColumns['1 Year Follower Change'] &&*/}
      {/*    <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">*/}
      {/*      <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.year_1_follower_change).toFixed(4)}</Typography>*/}
      {/*    </Grid>*/}
      {/*}*/}
      {
          visibleColumns['24 Hour Post Volume Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{currencyDetails.day_1_post_volume_change}%</Typography>
          </Grid>
      }
      {
          visibleColumns['1 Week Post Volume Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.week_1_post_volume_change).toFixed(2)}%</Typography>
          </Grid>
      }
      {
          visibleColumns['1 Month Post Volume Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.month_1_post_volume_change).toFixed(2)}%</Typography>
          </Grid>
      }
      {
          visibleColumns['3 Month Post Volume Change'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.month_3_post_volume_change).toFixed(2)}%</Typography>
          </Grid>
      }
      {/*{*/}
      {/*    visibleColumns['1 Year Post Volume Change'] &&*/}
      {/*    <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center'}} component={Link} href={detailLink} underline="none">*/}
      {/*      <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.year_1_post_volume_change).toFixed(4)}</Typography>*/}
      {/*    </Grid>*/}
      {/*}*/}
      {
          visibleColumns['1 Month Post Volume Average'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.month_1_post_volume_average).toFixed(2)}</Typography>
          </Grid>
      }
      {
          visibleColumns['3 Month Post Volume Average'] &&
          <Grid item xs={.75} sx={{display: 'flex', justifyContent: 'center', flex: 1, marginRight: '5px', alignItems: 'center'}} component={Link} href={detailLink} underline="none">
            <Typography sx={[typographyStyle]}>{parseFloat(currencyDetails.month_3_post_volume_average).toFixed(2)}</Typography>
          </Grid>
      }
      <Grid item xs={.25}>
        <DeleteForeverIcon sx={{fontSize: '1.1rem', color: theme.palette.error.main, position: 'relative', top: '3px', cursor: 'pointer'}} onClick={()=>deleteFromScreener(currencyDetails.coin, screenerCoinId.id)} />
      </Grid>
      {/*}
      <Grid item xs={1}>
        {
          coinIsVIP ? 
            <Tooltip title="Followed in VIP"><IconButton onClick={()=>removeFromVIP()} sx={{ position: 'relative', top: '3px', left: '-8px'}}><RiVipCrownFill color={theme.palette.error.dark} size={18} /></IconButton></Tooltip>
            :
            <Tooltip title="NOT Followed in VIP"><IconButton onClick={()=>addToVIP()} sx={{ position: 'relative', top: '3px', left: '-8px'}}><RiVipCrownFill color={theme.palette.grey[300]} size={18} /></IconButton></Tooltip>
        }
      </Grid>
       <Grid item xs={.5}>
        {displayAlerts()}
      </Grid> */}
    </Grid>
  )
}


export default ScreenerCurrencyListItem