import React, {useEffect, useCallback, useRef} from 'react'

import { useTheme } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch'

import { ColorType, createChart } from 'lightweight-charts'

const ChartDisplayByCoinLWChart = ( {chosenCoin, chartInterval, pageSize, boxHeight, rowHeight, myClickHandler, switchToMarketChartNoData} ) => {

    console.log("top of ChartDisplayByCoinLWChart and chosenCoin is: " + chosenCoin)
    const theme = useTheme()

    const callInterval = 5000 //may change from one minute to more for larger time frames

    const candleStickData = useRef([])
    const volumeData = useRef([])
    const chartLWRef = useRef()
    const firstRun = useRef(true)

    const crosshairMoveHandler = (param) => {
        // Create and style the tooltip html elemen
        //console.log("top of crosshairMoveHandler and param is:")
        //console.dir(param)
        if (
            param.point === undefined ||
            !param.time ||
            param.point.x < 0 ||
            param.point.y < 0
        ) {
            return
        } else {
            //for later right click and add price notice
            //console.log("param inside crosshairMoveHandler is: ")
            //console.dir(param)
            return
        }
    }      

    const getNewCandle = useCallback((candleSeries, volumeSeries ) => {
    
        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ;(async () => {
            try {
                console.log("candleStickData.current[candleStickData.current.length - 1].time is:")
                console.dir(candleStickData.current[candleStickData.current.length - 1].time)
                console.log("candleStickData.current[0].time is:")
                console.dir(candleStickData.current[0].time)
                var getTenCandleExchangeChartDataURL = `api/v1/coin/exchangechart${chartInterval}/?symbol=${chosenCoin}&page_size=10&page=1&ordering=-timestamp?timestamp__gt=${candleStickData.current[candleStickData.current.length - 1].time}`
                const getTenCandleExhangechartResponse = await fetchData(getTenCandleExchangeChartDataURL, "could not receive one candle exchange chart data", fetchParams).promise
                console.log("getTenCandleExhangechartResponse getting one candle exchange data using chosenCoin: " + chosenCoin)
                console.dir(getTenCandleExhangechartResponse)
                console.log("getTenCandleExchangeChartDataURL is: ")
                console.dir(getTenCandleExchangeChartDataURL)   
                //console.dir(getTenCandleExhangechartResponse.count)
                //console.dir( getTenCandleExhangechartResponse.count > 0)
                var candleSticksToAdd = getTenCandleExhangechartResponse.results.filter( candle => candle.time > candleStickData.current[candleStickData.current.length - 1].time)
        
                //console.log("candleSticksToAdd is: ")
                //console.dir(candleSticksToAdd)
    
                if (candleSticksToAdd.length > 0) {
                    console.log("ADDING NEW CANDLE")
                    var convertedCandleSticksToAdd = candleSticksToAdd.map( candle => {
                        console.log("candle to add is:")
                        console.dir(candle)                        
                        return {
                            "time": candle.time,
                            "open": candle.open,
                            "high": candle.high,
                            "low": candle.low,
                            "close": candle.close,
                        }
                    })
                    //need to sort candles by time going up
                    convertedCandleSticksToAdd.sort((a,b) => a.time - b.time)
        
                    console.log("convertedCandleSticksToAdd after sorting is: ")
                    console.dir(convertedCandleSticksToAdd)
        
                    var convertedVolumeToAdd = candleSticksToAdd.map( candle => {
                        return {
                            "time": candle.time,
                            "value": candle.volume,
                        }
                    })
                    //need to sort candles by time going up
                    convertedVolumeToAdd.sort((a,b) => a.time - b.time)    
                    //console.log("convertedVolumeToAdd is: ")
                    //console.dir(convertedVolumeToAdd)  
        
                    //console.log("ADDING NEW CANDLES top: " + convertedVolumeToAdd.length)
                    candleStickData.current = [...candleStickData.current, ...convertedCandleSticksToAdd]
                    volumeData.current = [...volumeData.current, ...convertedVolumeToAdd]
        
                    convertedCandleSticksToAdd.map(candleToAdd => candleSeries.update(candleToAdd))
                    convertedVolumeToAdd.map(candleToAdd => volumeSeries.update(candleToAdd))
                    candleSeries.update(convertedCandleSticksToAdd)
                    volumeSeries.update(convertedVolumeToAdd)              
                
                } else {
                    //there are no candles to add
                    //console.log("NO CANDLES TO ADD")

                }

            } catch (error) {
                
                console.log("something is broke getting exchange chart data1: ", getTenCandleExchangeChartDataURL , error)
                console.log("switching to marketchart due to data error for candlestick")
                switchToMarketChartNoData()
            }
        })()
        /*.then(() => {
            const fetchParams = {
                    method: 'GET',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ;(async () => {
                try {
                    var getTenCandleExchangeChartDataURL = `api/v1/coin/exchangechart${chartInterval}/?symbol=${chosenCoin}&ordering=-timestamp`
                    var getTenCandleExhangechartResponse = await fetchData(getTenCandleExchangeChartDataURL, "could not receive one candle exchange chart data", fetchParams).promise
                    var candleSticksToAdd = getTenCandleExhangechartResponse.results.filter( candle => candle.time > candleStickData.current[candleStickData.current.length - 1].time)
                    //console.log("candleStickData.current[candleStickData.current.length - 1].time is: " + candleStickData.current[candleStickData.current.length - 1].time)
                    //console.log("candleSticksToAdd is: ")
                    //console.dir(candleSticksToAdd)
                    //console.log("getTenCandleExchangeChartDataURL is :")
                    //console.log(getTenCandleExchangeChartDataURL)
                    //console.log("getTenCandleExhangechartResponse is:")
                    //console.dir(getTenCandleExhangechartResponse)

                    if (candleSticksToAdd.length > 0) {
                        console.log("ADDING ONE CANDLE")
                        var convertedCandleSticksToAdd = candleSticksToAdd.slice(candleSticksToAdd.length - 1, candleSticksToAdd.length).map( candle => {
                            return {
                                "time": candle.time,
                                "open": candle.open,
                                "high": candle.high,
                                "low": candle.low,
                                "close": candle.close,
                            }
                        })
                        //need to sort candles by time going up
                        convertedCandleSticksToAdd.sort((a,b) => a.time - b.time)

                        //console.log("convertedCandleSticksToAdd is: ")
                        //console.dir(convertedCandleSticksToAdd)

                        var convertedVolumeToAdd = candleSticksToAdd.map( candle => {
                            return {
                                "time": candle.time,
                                "value": candle.volume,
                            }
                        })
                        //need to sort candles by time going up
                        convertedVolumeToAdd.sort((a,b) => a.time - b.time)
                        //console.log("convertedVolumeToAdd is: ")
                        //console.dir(convertedVolumeToAdd)

                        //console.log("ADDING NEW CANDLES: " + convertedVolumeToAdd.length)
                        candleStickData.current = [...candleStickData.current, convertedCandleSticksToAdd]
                        volumeData.current = [...volumeData.current, convertedVolumeToAdd]

                        convertedCandleSticksToAdd.map(candleToAdd => candleSeries.update(candleToAdd))
                        convertedVolumeToAdd.map(candleToAdd => volumeSeries.update(candleToAdd))
                        candleSeries.update(convertedCandleSticksToAdd)
                        volumeSeries.update(convertedVolumeToAdd)

                    } else {
                        //there are no candles to add
                        //console.log("NO CANDLES TO ADD")
                    }
                } catch (error) {
                    console.log("something is broke getting exchange chart single candle data: ", error)
                    switchToMarketChartNoData()
                }
            })()
        })
        */
    }, [chartInterval,chosenCoin])    

    useEffect(() => {

        console.log("top of useEffect of ChartDisplayByCoinLWChart and chosenCoin is============================================: ")
        console.dir(chosenCoin)
        console.log("chartLWRef.current in top of useEffect is:")
        console.dir(chartLWRef.current)

        let isMounted = true

        //setIsLoading(true)

        const candleStickOptions = {
            upColor: theme.palette.success.main,
            downColor: theme.palette.error.main,
        }    

        const chartOptions = {
            width: chartLWRef.current.clientWidth,
            //width: '100%',
            height: ((rowHeight * boxHeight) -50), //need to change this to height of box....calculate from row height times y setting
            layout: {
            background: {
                type: ColorType.Solid,
                color: theme.palette.background.default,
            },
            textColor: theme.palette.primary.main,
            },
            crosshair: {
                mode: 1,
            },
            grid: {
            vertLines: {
                color: theme.palette.secondary.dark,
                style: 2,
    
            },
            horzLines: {
                color: theme.palette.secondary.dark,
                style: 2,
            }
            },
        }   
        
        const volumeSeriesOptions = {
            color: '#3b04bd',
            priceFormat: {
                type: 'volume',
            },
            priceScaleId: '', // set as an overlay by setting a blank priceScaleId
            // set the positioning of the volume series
            scaleMargins: {
                top: 0.7, // highest point of the series will be 70% away from the top
                bottom: 0,
            },
        } 

        const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            }
        }
        ;(async () => {
            try {
                var getExchangeChartDataURL = `api/v1/coin/exchangechart${chartInterval}/?symbol=${chosenCoin}&page_size=${pageSize}&page=1&ordering=-timestamp`
                const exhangeChartResponse = await fetchData(getExchangeChartDataURL, "could not receive exchange chart data", fetchParams).promise
                console.log("exhangeChartResponse getting exchangechart data using chosenCoin: " + chosenCoin)
                console.log(exhangeChartResponse)
                console.log("getExchangeChartDataURL is: ")
                console.dir(getExchangeChartDataURL)
                console.log("chartLWRef.current just after call is:")
                console.dir(chartLWRef.current)
                if (exhangeChartResponse.results.length > 0) {
                    console.log("exhangeChartResponse.length > 0")
                    ///nasty little thing to figure out
                    //if the chart is changed...originally just appended below the others
                    //need to remove the first child of chartLWRef (previous chart drawn)
                    //so that can add a new chart...replacing instead of appending...ugh
                    if (!firstRun.current) {
                        console.log("NOT FIRST RUN....................")
                        //chartLWRef.current = null
                        console.log("removeChild......................")
                        console.
                        chartLWRef.current.removeChild(chartLWRef.current.children[0])  

                    } else {
                        firstRun.current = false
                    }                    
                    console.log("chartLWRef.current just before createChart is:")
                    console.dir(chartLWRef.current)
                    console.log("chartOptions is:")
                    console.dir(chartOptions)
        
                    const candleStickChart = createChart(chartLWRef.current, chartOptions, )
                    var volumeSeries = candleStickChart.addHistogramSeries(volumeSeriesOptions)
                    var candleSeries = candleStickChart.addCandlestickSeries(candleStickOptions)    
                     
                    candleStickChart.subscribeClick(myClickHandler)
                    candleStickChart.subscribeCrosshairMove(crosshairMoveHandler) 
                    
                    var reversedExchangeResponseResult = exhangeChartResponse.results.reverse()
                    
                    if (parseInt(chartLWRef.current.clientHeight) === 0 ) {
                        candleStickChart.applyOptions( {width: chartLWRef.current.clientWidth, height: 494})
                    } else { 
                        candleStickChart.applyOptions( {width: chartLWRef.current.clientWidth, height: (parseInt(chartLWRef.current.clientHeight)-50.0)})
                    }

                    let lastCandle = {
                        "time": 0
                    }               
                    console.log("reversedExchangeResponseResult before setting all candle data is:")     
                    console.dir(reversedExchangeResponseResult)
                    var filteredCandleStick = reversedExchangeResponseResult.map( chartData => {
                        console.log("chartData is: ")
                        console.dir(chartData)
                        if (lastCandle.time !== chartData.time && lastCandle.time < chartData.time) {
                            lastCandle = chartData
                            return {
                                "time": chartData.time,
                                "open": chartData.open,
                                "high": chartData.high,
                                "low": chartData.low,
                                "close": chartData.close,
                            }
                        } else {
                            console.log("error in data....current time is = or < lastCandle")
                            console.dir(lastCandle)
                            console.dir(chartData)
                        }                            
                    })
                    console.log("filteredCandleStick is:")
                    console.dir(filteredCandleStick)
                    let noUndefinedFilteredCandleStick = filteredCandleStick.filter( data => data !== undefined)
                    candleStickData.current = noUndefinedFilteredCandleStick

                    console.log("candleStickData.current is:")
                    console.dir(candleStickData.current)
                    lastCandle = {
                        "time": 0
                    }
                    var filteredVolume = reversedExchangeResponseResult.map( chartData => {
                        if (lastCandle.time !== chartData.time && lastCandle.time < chartData.time) {
                            lastCandle = chartData                        
                            return {
                            "time": chartData.time,
                            "value": chartData.volume
                            }
                        } else {
                            //return nothing as bad data
                        }                            
                    })
                    let noUndefinedFilteredVolume = filteredVolume.filter( data => data !== undefined)
                    volumeData.current = noUndefinedFilteredVolume                    
                    console.log("volumeData.current is:")
                    console.dir(volumeData.current)

                    candleSeries.setData(noUndefinedFilteredCandleStick)
                    volumeSeries.setData(noUndefinedFilteredVolume)
                    let currentChartTimerID = setInterval(getNewCandle, callInterval, candleSeries, volumeSeries, candleStickData, volumeData)
                    //let currentChartTimerID = setInterval(getNewCandle, callInterval, candleSeries, candleStickData, volumeData)
                    //setIsLoading(false)
                    return () => {
                        isMounted = false
                        clearInterval(currentChartTimerID)
                    }
                } else {
                    //no chart data
                    console.log("there is no exchange chart data....switch to marketchart type")
                    switchToMarketChartNoData()
                }
            } catch (error) {
                console.log("something is broke getting exchange chart data2: ", error)
                console.dir(error)
                switchToMarketChartNoData()
            }
        })()  

    }, [chartInterval,chosenCoin,pageSize])  
    
    return (
        <div ref={chartLWRef} ></div>
    )
}

export default ChartDisplayByCoinLWChart