import React, { useState, useEffect } from 'react'
import { Stack, Typography, Grid, Tab, Box, Pagination, TextField, InputAdornment, Button } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material/styles'

import fetchData from '../../utility/endpointFetch'

import HomeFeedTwitterUserTweets from './HomeFeedTwitterUserTweets'
import HomeFeedTwitterUserDisplayItem from './HomeFeedTwitterUserDisplayItem'
import HomeFeedTwitterUserSearch from './HomeFeedTwitterUserSearch'
import DeleteIcon from "@mui/icons-material/Delete";
import { ArrowUpward } from "@mui/icons-material";

const HomeFeedTabFollowSearch = ( { addUserToWhiteList, followedList } ) => {

    const theme = useTheme()

    const [tabValue,setTabValue] = useState('1')
    const [twitterUserTweets,setTwitterUserTweets] = useState([])
    const [twitterUserTweetsCount,setTwitterUserTweetsCount] = useState(0)
    const [tweetsFrom,setTweetsFrom] = useState('')
    const [isLoading,setIsLoading] = useState(false)

    const [userIsLoading,setUserIsLoading] = useState(true)
    const [twitterUserList,setTwitterUserList] = useState([])
    const [twitterUserPage,setTwitterUserPage] = useState(1)
    const [twitterUserPageCount,setTwitterUserPageCount] = useState(1)
    const [filterByInfluencerUrl,setFilterByInfluencerUrl] = useState('')
    const [searchByUrl,setSearchByUrl] = useState('')
    const [sortingBy,setSortingBy] = useState('')
    const [isInternal, setIsInternal] = useState(false);
    const [userData, setUserData] = useState(null);
    const [twitterHandle, setTwitterHandle] = useState(null);
    const [twitterHandleText, setTwitterHandleText] = useState('');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    } 

    const searchTermChangeHandler = (searchTerm) => {
        //console.log("top or searchTwitterUsers...searchTerm: " + searchTerm)
        if (searchTerm.length > 2) {
          //console.log("searchTerm greater than 2")
          if (searchTerm.indexOf(' ') >= 0) {
            //console.log("searchTerm contains spaces")
            //contains spaces...no search
            setSearchByUrl('')
            return null
          } else {
            //console.log("about to search")
            var searchString = "&search=" + encodeURIComponent(searchTerm)
            setTwitterUserPage(1)
            setSearchByUrl(searchString)
          }
        } else {
          //search term too short...wait till at least 3 characters
          //console.log("searchTerm 2 or less letters")
          setSearchByUrl('')
          return null
        }
      }    
    const clearTwitterUserSearch = () => {
        setSearchByUrl('')
        setTwitterUserPage(1)
    }

    useEffect(() => {
        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            // get the user data
            try {
                var response_query = `api/v1/users/userdata/`
                const response = await fetchData( response_query, "could not receive user data", fetchParams).promise
                setUserData(response)
                setTwitterHandle(response['twitter_handle'])
                setIsInternal(response['user']['is_internal'])
            } catch (error) {
                console.log("could not receive twitter users data: %s", error)
            }
        })()
    }, [])

    useEffect(() => {
        setUserIsLoading(true)
        //console.log("top of useEffect in HomeFeedManager")
        //console.log("twitterUserPage is: " + twitterUserPage)
        //console.log("filterByInfluencerUrl is: " + filterByInfluencerUrl)
        //console.log("searchByUrl is: " + searchByUrl)
    
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
            //get the list of twitter users
          try {

            var response_query = `api/v1/feed/usertwitter/?page=${twitterUserPage}${filterByInfluencerUrl}${searchByUrl}`
            const response = await fetchData( response_query, "could not receive twitter users data", fetchParams).promise
            console.log("response getting twitterusers")
            console.log(response_query)
            console.dir(response)
            //console.log("followedList is: ")
            //console.dir(followedList)

            //need to filter out users that are already followed
            let filteredTwitterUsers = response.results.filter( function (currentUser) {
                return !followedList.includes(currentUser.username)
            })

            //console.log("filteredTwitterUsers is: ")
            //console.dir(filteredTwitterUsers)

            setTwitterUserList(filteredTwitterUsers)
        
            if (response.count > 100) {
              setTwitterUserPageCount(Math.ceil(response.count/100))
            } else {
              setTwitterUserPageCount(1)
            }
    
            setUserIsLoading(false)
          } catch (error) {
            console.log("could not receive twitter users data: %s", error)
          }
        })()
    }, [twitterUserPage, filterByInfluencerUrl, searchByUrl])

    const setInfluencerHandler = ( influencerFilterValue ) => {
        //console.log("top of setInfluencerHandler: " + influencerFilterValue)
        if (influencerFilterValue === 'both') {
            setFilterByInfluencerUrl('')
        } else if (influencerFilterValue === 'influencer') {
            setFilterByInfluencerUrl('&is_influencer=true')
        } else if (influencerFilterValue === 'official') {
            setFilterByInfluencerUrl('&is_influencer=false')
        } else {
            setFilterByInfluencerUrl('')
        }
    }

    const requestUserTwitterList = (handle) => {
        function postTwitterImport(){
            const fetchParams = {
                    method: 'POST',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'twitter_handle': handle
                    })
                }
            ;(async () => {
                //get the list of twitter users
                try {
                    var response_query = `api/v1/users/posttwitterimport/`
                    const response = await fetchData( response_query, "could not receive twitter users data", fetchParams).promise
                    console.log(response)
                } catch (error) {
                    console.log("could not receive twitter users data: %s", error)
                }
            })()
        }
        function updateUserData(){
            const fetchParams = {
                method: 'PUT',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                        "coin": userData['coin'],
                        "saved_research_queries": userData['saved_research_queries'],
                        "deleted_research_queries": userData['deleted_research_queries'],
                        "twitter_handle": handle,
                        "wallet_names": userData['wallet_names'],
                        "deleted_wallet": userData['deleted_wallet'],
                        "screener_names": userData['screener_names'],
                        "deleted_screener": userData['deleted_screener'],
                    }
                )
            }
            ;(async () => {
                try{
                    let response_query = `api/v1/users/userdata/`
                    let response = await fetchData(response_query, 'could not post user data', fetchParams).promise
                    if(response){
                        setTwitterHandle(handle)
                    }
                }catch(error){
                    console.log('could not post user data: %s', error)
                }
            })()
        }
        postTwitterImport();
        updateUserData();
    }

    const changePage = (event, page) => {
        //console.log("top of changePage")
        //console.dir(page)
        setUserIsLoading(true)
        setTwitterUserPage(page)
    }    

    const getTwitterUserTweets = (twitterUser) => {
        //console.log("getTwitterUserTweets is: " + twitterUser)
        setTabValue('1')
        setIsLoading(true)
        setTweetsFrom(twitterUser)
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          const response = await fetchData(`api/v1/feed/alltweets/?twitter_user__name=${twitterUser}`, "could not get twitter user tweets", fetchParams).promise
          //console.log("response twitter user tweets")
          //console.dir(response)
          setTwitterUserTweetsCount(response.results.length)
          setTwitterUserTweets(response.results)
          setIsLoading(false)
        })()
    }        

    const addUserToWhiteListHandler = (userToAdd) => {
        console.log("top of addUserToWhiteListHandler and userToAdd is: " + userToAdd)
        let addFilteredTwitterUsers = twitterUserList.filter( function (currentUser) {
            return userToAdd !== currentUser.username
        })        
        console.log("setting twitterUserList to:")
        console.dir(addFilteredTwitterUsers)
        setTwitterUserList(addFilteredTwitterUsers)
        addUserToWhiteList(userToAdd)
    }

    // new Twitter username
    const [newTwitterUserVal, setNewTwitterUserVal] = React.useState('');
    const handleNewTwitterUserValChange = (event) => {
        setAddTwitterUserError('')
        setNewTwitterUserVal(event.target.value);
    };
    // new Twitter URL
    const [newTwitterUrlVal, setNewTwitterUrlVal] = React.useState('');
    const handleNewTwitterUrlValChange = (event) => {
        setAddTwitterUserError('');
        setNewTwitterUrlVal(event.target.value);
    };
    const [addTwitterUserError, setAddTwitterUserError] = React.useState('');
    const [addTwitterUserSuccess, setAddTwitterUserSuccess] = React.useState('');

    const submitNewTwitterUser = () => {
        if((!newTwitterUserVal.toString().length) || (!newTwitterUrlVal.toString().length)){
            setAddTwitterUserError('You must enter a username and a url.')
            return;
        }
        const fetchParams = {
                method: 'POST',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: newTwitterUserVal,
                    url: newTwitterUrlVal
                })
            }
        ;(async () => {
            //get the list of twitter users
            try {
                var response_query = `api/v1/feed/twittercreate/`
                const response = await fetchData( response_query, "could not create new twitter user", fetchParams).promise
                console.log(response)
                if(response['success']){
                    setNewTwitterUserVal('');
                    setNewTwitterUrlVal('');
                    setAddTwitterUserSuccess(response['success'])
                }else{
                    if(response) setAddTwitterUserError(Object.values(response)[0])
                    else setAddTwitterUserError('Error adding a new twitter user.')
                }
            } catch (error) {
                console.log("could not create new twitter user: %s", error)
            }
        })()
    }

    return (
        <Grid container sx={{ width: '95%'}}>
            <Grid item xs={9} >
                <Box sx={{width: '100%', paddingLeft: '10px', paddingRight: '10px '}}>
                    <TabContext value={tabValue} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="" indicatorColor="secondary" textColor="primary" >
                                <Tab label="tweets" value="1" sx={{minWidth: '30px'}}/>
                                <Tab label="search" value="2" sx={{minWidth: '30px'}} />
                                {
                                    isInternal && (
                                        <Tab label="Add Twitter User" value="3" sx={{minWidth: '30px'}} />
                                    )
                                }
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                                <HomeFeedTwitterUserTweets 
                                    twitterUserTweets={twitterUserTweets} 
                                    twitterUserTweetsCount={twitterUserTweetsCount}
                                    tweetsFrom={tweetsFrom}
                                    isLoading={isLoading}
                                />

                            </Box>  
                        </TabPanel>
                        <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                                {/* search box */}
                                <HomeFeedTwitterUserSearch 
                                    searchTermChangeHandler={searchTermChangeHandler}
                                    clearTwitterUserSearch={clearTwitterUserSearch}
                                    setInfluencerHandler={setInfluencerHandler} 
                                />
                                {/* influencer, official channel or both */}
                            </Box>    
                        </TabPanel>
                        <TabPanel value="3" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                                <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{justifyContent: 'center', alignSelf: 'center'}}>
                                    <Typography variant={'h5'} color={'#fff'}>Add a Twitter user to our database.</Typography>
                                    <Typography variant={'h5'} color={'#fff'}>We'll use their tweets for our market analysis.</Typography>
                                    <TextField
                                        sx={{mt: 2}}
                                        name='twitterUserName'
                                        variant='outlined'
                                        required
                                        value={newTwitterUserVal}
                                        id='twitterUserName'
                                        label='Twitter Username'
                                        onChange={handleNewTwitterUserValChange}
                                        inputProps={{ maxLength: 25 }}
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography sx={{color: '#fff'}}>
                                                        @
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        sx={{mt: 1}}
                                        name='twitterUrl'
                                        variant='outlined'
                                        required
                                        value={newTwitterUrlVal}
                                        id='twitterUrl'
                                        label='Twitter Profile Url'
                                        onChange={handleNewTwitterUrlValChange}
                                        inputProps={{ maxLength: 40 }}
                                        size="small"
                                    />
                                    {
                                        (addTwitterUserError && !addTwitterUserSuccess) && (
                                            <Typography color={'red'}  sx={{mt: 1}}>{addTwitterUserError}</Typography>
                                        )
                                    }
                                    {
                                        (addTwitterUserSuccess && !addTwitterUserError) && (
                                            <Typography color={'green'}  sx={{mt: 1}}>{addTwitterUserSuccess}</Typography>
                                        )
                                    }
                                    <Button onClick={submitNewTwitterUser} sx={{mt: 1}} variant={'contained'} color={'primary'}><Typography>Submit</Typography></Button>
                                </Stack>
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Grid>
            <Grid item xs={3} sx={{bgcolor: theme.palette.grey[100]}}>
                <Typography variant="h5" sx={{paddingLeft: '10px', paddingTop: '5px', marginBottom: '10px', color: theme.palette.primary.dark}}>NOT followed</Typography>
                {twitterUserList.map( (twitterUser, index) => <HomeFeedTwitterUserDisplayItem twitterUser={twitterUser} getTwitterUserTweets={getTwitterUserTweets} addUserToWhiteListHandler={addUserToWhiteListHandler} /> )}
                <Pagination sx={{marginTop: '10px'}} shape="rounded" count={twitterUserPageCount} page={twitterUserPage} onChange={changePage}/>
            </Grid>
        </Grid>
    )
}

export default HomeFeedTabFollowSearch