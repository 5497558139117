import React from 'react'
import { Box, Typography, Stack, Pagination } from '@mui/material'

import CustomFeedTwitterResultItem from '../TweetDisplay/CustomFeedTwitterResultItem'

const CustomTweetFeedsList = ( {feedNumber, feedCount, feedPage, feedToDisplay, changeFeedPage, divHeight=0, boxWidth=0} ) => {

    console.log("top of CustomTweetFeedsList")
    console.log("feedToDisplay is:")
    console.dir(feedToDisplay)
    console.log("feedCount is:")
    console.dir(feedCount)


    return (
        <Box sx={{mb: 1}}>
            <Stack direction="column" justifyContent='flex-start' alignItems="flex-start" display="flex">
                {
                    feedCount === 0 ? (
                            <Typography sx={{color: '#fff', textAlign: 'center', mt: 3}}>There's nothing in this feed.</Typography>
                        )
                        :
                        null
                }
                {
                    feedToDisplay && feedToDisplay.map((item, index) => {
                        return (
                            <CustomFeedTwitterResultItem key={index} tweet={item} search={''} divHeight={divHeight} boxWidth={boxWidth} />
                        )
                    })
                }
                <Pagination sx={{marginTop: '10px'}} shape="rounded" count={feedCount} page={feedPage} onChange={(page)=>changeFeedPage(page,feedNumber)}/>
            </Stack>
        </Box>        
    )
}

export default CustomTweetFeedsList