import React, { useState, Fragment } from 'react'
import { Container } from '@mui/material'
import Footer from '../Footer'
import RegisterUser from './RegisterUser'
//import RegisterVerification from './RegisterVerification'
import RegisterComplete from './RegisterComplete'
import { useSearchParams } from 'react-router-dom'

const Register = () => {

  let [searchParams] = useSearchParams()
  const referral = searchParams.get('referral')
  
  const [ registrationStep, setRegistrationStep ] = useState(0)

  switch ( registrationStep ) {

    case 0:
      return (
        <Fragment>
          <Container >
            <RegisterUser referral={referral} setRegistrationStep={setRegistrationStep} />
          </Container>
          <Footer />
        </Fragment>
      )
    case 1:
      return (
        <Fragment>
          <Container >
            <RegisterComplete />
          </Container>
          <Footer />
        </Fragment>
      )
    default:

        //something is jacked....do nothing
        return null

  }
}

export default Register
