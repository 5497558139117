import React, { useEffect, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Grid, Container, CssBaseline, CircularProgress, Backdrop } from '@mui/material'
import fetchData from '../../utility/endpointFetch'

import VIPTabs from './VIPTabs'
import VIPChartTabs from './VIPChartTabs'
import VIPMainTabs from './VIPMainTabs'
import TopAppBar from '../TopAppBar'
import { useDispatch, useSelector } from "react-redux";
import { setSelectedWatchlistName } from "../../store/actions";

const VIP = ( { userObject } ) => {
  //console.log("top of VIP")
  //console.dir(userObject)

  const theme = useTheme()

  const [isLoading,setIsLoading] = useState(true)
  const [vipListings,setVIPListings] = useState([])
  const [vipPage,setVIPPage] = useState(1)
  const [vipPageCount,setVIPPageCount] = useState(1)
  const [responseCount,setResponseCount] = useState(0)
  const [backdropOpen,setBackdropOpen] = useState(false)
  const [coinsMentioned,setCoinsMentioned] = useState([])
  
  const [coinInTab,setCoinInTab] = useState('')
  const [atMentionedTabValue,setAtMentionedTabValue] = useState('')
  const [atFollowedTabValue,setAtFollowedTabValue] = useState('')
  const [vipTabValue,setVIPTabValue] = useState('1')
  const [hashTabValue,setHashTabValue] = useState('')
  const dateInChart = ''
  const [vipConfig,setVipConfig] = useState('simple')
  const [localVIPCoins,setLocalVIPCoins] = useState(userObject.coin)
  const dispatch = useDispatch();
  const [officialTwitter,setOfficialTwitter] = useState('')  
  const [coinChart,setCoinChart] = useState('')
  const [coinChartName,setCoinChartName] = useState('')
  const vipScreenerName = useSelector(state => state.vip.watchlistName)
  const resetVIPCoins = (screenerName, coins) => {
    dispatch(setSelectedWatchlistName(screenerName))
    if(coins){
      setLocalVIPCoins(coins)
      getNewVIPSignal(coins)
      return;
    }
    //need to reset localUserObject
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
          'Content-Type': 'application/json'
      },            
    }
    ;(async () => {
      try {
        const getUserObject = await fetchData(`api/v1/users/userdata/`, "could not get new user object", fetchParams).promise
        setLocalVIPCoins(getUserObject.coin)
        getNewVIPSignal(getUserObject.coin)
      } catch (error) {
        console.log("something is broke getting new userObject", error)
      }

    })()  
  }

  const changePage = (event, page) => {
    //setBackdropOpen(true)
    setVIPPage(page)
  }

  const setCoinInChart = (coin) => {
    setOfficialTwitter('')
    setCoinChart(coin)
    setCoinChartName(coin)
  }

  const changeAtMentionedTab = ( atValue ) => {
    //console.log("top of changeAtMentionedTab and atValue is:")
    //console.dir(atValue)
    setAtMentionedTabValue(atValue)
    setVIPTabValue('2')
  }

  const changeAtFollowedTab = ( atValue ) => {
    //console.log("top of changeAtFollowedTab and atvalue is: " + atValue)
    setAtFollowedTabValue(atValue)
    setVIPTabValue('3')
  }

  const changeHashTab = ( hashValue ) => {
    setHashTabValue(hashValue)
    changeVIPTabValue('4')
  }

  const changeVIPTabValue = ( tab ) => {
    //console.log("top of changeVIPTabValue and tab is:")
    //console.dir(tab)
    setVIPTabValue(tab)
  }

  const changeCoinInTab = ( coin ) => {
    //console.log("top of changeCoinInTab")
    //console.log("coin is: " + coin)
    setCoinInTab(coin)
    changeVIPTabValue('5')
  }

  const changeDateInChart = ( date ) => {
    //console.log("top of changeDateInChart")
    //console.log("date is: " + date)
    //console.dir(date)
    //setDateInChart(date)
  }

  const showOfficialTweets = (officialTwitterAccount, coinChart) => {
    ///console.log("top of showOfficialTweets")
    //console.log("coinChart is: " + coinChart)
    //console.log("officialTwitterAccount is: " + officialTwitterAccount)
    setCoinChart(coinChart)
    setCoinChartName(coinChart)
    setOfficialTwitter(officialTwitterAccount)
  }

  const handleConfigChange = ( e ) => {
    setVipConfig(e.target.value)
  }

  const getNewVIPSignal = (currentVIPCoins) => {
    setIsLoading(true)
    //setBackdropOpen(true)
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        let coinIds = '';
        currentVIPCoins && currentVIPCoins.length && [...new Set(currentVIPCoins)].forEach((item, index) => {
          coinIds += item;
          if(index < currentVIPCoins.length - 1){
            coinIds += ','
          }
        });
        //let getVIPSignalURL = `api/v1/vip/vipsignal/?has_post=true&ordering=-post__created&page=${vipPage}`
        let getVIPSignalURL = `api/v1/feed/alltweets/?vip=true&ordering=-timestamp&page=${vipPage}`
        if(coinIds.length) getVIPSignalURL += `&coins=${coinIds}`
        
        // the "all" part of vip I believe is gone but will leave in case
        //if (currentVIPCoins.length === 0) {
          //assume all coins is selected...use a different endpoint
        //  getVIPSignalURL = `api/v1/vip/vipsignal/?all&has_post=true&ordering=-post__created&page=${vipPage}`
        //}
        
        const vipResponse = await fetchData(getVIPSignalURL, "could not receive vipsignal data", fetchParams).promise
        // console.log(getVIPSignalURL)

        setResponseCount(vipResponse.count)
        //setResponseCount(vipListingsTestResult.count)

        //console.log("coins is:")
        //console.dir(vipListingsTestResult.results[0].coins)
        //console.log("coins[0] is:")
        //console.dir(vipListingsTestResult.results[0].coins[0])

        if (vipResponse.count > 100) {
          setVIPPageCount(Math.ceil(vipResponse.count/100))
        } else {
          setVIPPageCount(1)
        }
        
        let coinsIncluded = []
        if (vipResponse.results.length > 0) {
          vipResponse.results.map( listing => {
            //add coins associated to coinsincluded
            //at first only post coins....as coins filter will only work on posts.
            //so if we later wish to filter notifications by posts....add that as it's own filter as it will only apply to posts
            coinsIncluded = coinsIncluded.concat(listing.coins)
          })

          //console.log("first coin of first tweet is:" + vipResponse.results[0].coins[0])

          //if (localVIPCoins.length > 0) {
            //a specific watchlist is selected as it's set in in userObject.coin
          setCoinChart(vipResponse.results[0].coins[0]);
          setCoinChartName(vipResponse.results[0].coins[0]);
          //} else {
            //the all coins watchlist is set....do not set a coin
          //}    
        } 
        
        setCoinsMentioned(coinsIncluded)

        setVIPListings(vipResponse.results)
        //setVIPListings(vipListingsTestResult.results)

        setIsLoading(false)
        setBackdropOpen(false)
      } catch (error) {
        console.log("something is broke getting vipsignal: " + error)
      }
    })()    
  }

  useEffect(() => {
    getNewVIPSignal(localVIPCoins)
  }, [vipPage,localVIPCoins])

  if (userObject.user.is_vip === true) {
    //if (isLoading) return <CircularProgress />
    return (
      <Box sx={{ display: 'flex', backgroundColor: theme.palette.background.default }}>
        <CssBaseline />
        <TopAppBar userObject={userObject} selectedPage='vip' />
        <Container maxWidth="false" sx={{marginTop: '80px'}} >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={backdropOpen}
          >
            <CircularProgress sx={{color: "#fff"}} size={100} />
          </Backdrop>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <VIPMainTabs
                vipListings={vipListings}
                responseCount={responseCount}
                vipPageCount={vipPageCount}
                vipPage={vipPage}
                changePage={changePage}
                setCoinInChart={setCoinInChart}
                changeAtFollowedTab={changeAtFollowedTab}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                changeDateInChart={changeDateInChart}
                userDetails={userObject}
                localVIPCoins={localVIPCoins}
                isFullPostLoading={isLoading}
                vipConfig={vipConfig}
                handleConfigChange={handleConfigChange}
                showOfficialTweets={showOfficialTweets}
                vipScreenerName={vipScreenerName}
                resetVIPCoins={resetVIPCoins}
              />
            </Grid>
            <Grid item xs={4}>
              <VIPTabs
                vipTabValue={vipTabValue}
                changeVIPTabValue={changeVIPTabValue}
                vipListings={vipListings}
                coinsMentioned={coinsMentioned}
                setCoinInChart={setCoinInChart}
                changeAtMentionedTab={changeAtMentionedTab}
                atMentionedTabValue={atMentionedTabValue}
                atFollowedTabValue={atFollowedTabValue}
                changeAtFollowedTab={changeAtFollowedTab}
                hashTabValue={hashTabValue}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                coinInTab={coinInTab}
                changeDateInChart={changeDateInChart}
                coinChart={coinChart}
                vipConfig={vipConfig}
                showOfficialTweets={showOfficialTweets}
                userObject={userObject}
              />
            </Grid>
            <Grid item xs={4}>
              <VIPChartTabs
                coinChart={coinChart}
                coinChartName={coinChartName}
                officialTwitter={officialTwitter}
                atFollowedTabValue={atFollowedTabValue}
                atMentionedTabValue={atMentionedTabValue}
                setCoinInChart={setCoinInChart}
                changeAtFollowedTab={changeAtFollowedTab}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                hashTabValue={hashTabValue}
                dateInChart={dateInChart}
                changeDateInChart={changeDateInChart}
                vipConfig={vipConfig}
                userObject={userObject}
                showOfficialTweets={showOfficialTweets}
                localVIPCoins={localVIPCoins}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  } else {
    return null
  }
}

export default VIP