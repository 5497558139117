import React, {useState} from 'react'
import { Typography, Stack, Box, IconButton, Popover, Paper, TextField, Tooltip, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { TbLayoutGridAdd } from 'react-icons/tb'
import { RiAddBoxFill } from 'react-icons/ri'
import { IconSquareX } from '@tabler/icons'

import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'

const ResearchCustomAddNewLayout = ( {addNewLayout} ) => {

    const theme = useTheme()
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

    const [newLayoutName,setNewLayoutName] = useState('')
    const [newLayoutOpen,setNewLayoutOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleNewLayoutNameChange = (e) => {
        setNewLayoutName(e.target.value)
    }

    const handleAddClicked = () => {
        if (newLayoutName.length > 0) {
            addNewLayout(newLayoutName)
            setNewLayoutName('')
            handleClose()
        }
    }

    const handleClick = (event) => {
        //console.log("top of handleClick and event is:")
        //console.dir(event)
        setAnchorEl(event.currentTarget)
        setNewLayoutOpen((prevSavedSearchOpen) => !prevSavedSearchOpen)
    }    

    const handleClose = () => {
        //console.log("top of handleClose")
        setAnchorEl(null)
        setNewLayoutOpen((prevOpen) => !prevOpen)
    }     

    const id = newLayoutOpen ? 'new-layout-popover' : undefined   

    return (
        <Box sx={{ display: 'flex', marginLeft: '5px'}}>
            <span 
                sx={{
                    '&:hover': {
                        color: theme.palette.success.dark,
                    },
                    cursor: 'pointer',
                }}
            >
                <TbLayoutGridAdd  
                    aria-describedby={id}
                    onClick={handleClick}
                    color={theme.palette.success.main}
                    sx={{
                        

                    }}
                    size={25}
                />    
            </span> 
            <Popover
                id={id}
                open={newLayoutOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 400, left: 1200 }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <Transitions position={matchesXs ? 'top' : 'top-right'} in={newLayoutOpen} >
                <Paper>
                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '500px', minHeight: '150px', padding: '15px', backgroundColor: theme.palette.dark[800]}}>
                        <Box sx={{width: '100%'}}>
                            <Stack direction="column" display="flex" alignItems="flex-start" sx={{width: '100%'}}>
                                <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
                                    <Typography variant='h2' sx={{color: theme.palette.primary.main}}>Add New Dashboard Layout</Typography>
                                    <IconButton
                                        aria-label="close"
                                        sx={{color: theme.palette.error.main}}
                                        onClick={handleClose}>
                                        <IconSquareX sx={{ marginTop: '15px'}}/>
                                    </IconButton>  
                                </Stack>

                                <Box sx={{width: '100%', padding: '5px', paddingBottom: '0px'}}>
                                    <Stack 
                                        direction="row" 
                                        display="flex" 
                                        alignItems="center"
                                        justifyContent='space-around'
                                    >
                                        <TextField 
                                            id="new-layout-name" 
                                            label="New Layout Name" 
                                            variant="outlined"
                                            value={newLayoutName} 
                                            onChange={handleNewLayoutNameChange}
                                            size="small"
                                            sx={{
                                                width: '100%', 
                                                minWidth: '80px',
                                                color: theme.palette.grey[500], 
                                                input: {
                                                    background: theme.palette.dark[900],
                                                },
                                            }}

                                            inputProps={{
                                                style: { height: '12px' },
                                            }}
                                        />
                                        <IconButton
                                            aria-label="add"
                                            sx={{
                                                cursor: 'pointer',
                                                color: theme.palette.success.main,
                                                marginLeft: '5px',
                                            }}
                                            onClick={handleAddClicked}
                                        >
                                            <RiAddBoxFill 
                                                size={25}
                                            />
                                        </IconButton>
                                    </Stack>    
                                </Box>                                
                            </Stack>
                        </Box>        
                    </MainCard>
                </Paper>
                </Transitions>    
            </Popover>                         
        </Box>     
    )
}
export default ResearchCustomAddNewLayout