import React, { useState, useEffect } from 'react'
import { Box, CssBaseline, CircularProgress, AppBar, Toolbar, Container,
  Typography, TextField, Grid, Modal, Backdrop, Fade, Button } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import fetchData from '../../utility/endpointFetch'
import { ThumbUpAlt } from '@mui/icons-material'

import Header from '../Header'
import Navigation from '../Navigation'
import Footer from '../Footer'
import hofs from '../../utility/hofs'
import { parse, isValid } from 'psl'
import TopAppBar from '../TopAppBar'

const emailErrorTLD = 'The email domain you have inserted is not valid by the comprehensive list we have.  Please check it.  If you feel it is not in error continue'
const emailVerifierErrorMessageBasic = 'Email address and email address verifier must match'
const userNameErrorMessage = 'User name is required and needs to be unique within our system.  If you have one then that username is already taken'

const User = ( ) => {

  const theme = useTheme()

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: '20px',
            width: `calc(100% - 20px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - 20px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - 20px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - 20px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
  }));

  const [userObject,setUserObject] = useState([])
  const [isLoading,setIsLoading] = useState(true)
  const [email,setEmail] = useState('')
  const [emailError,setEmailError] = useState(false)
  const [emailErrorMessage,setEmailErrorMessage] = useState('')
  const [emailVerifier,setEmailVerifier] = useState('')
  const [emailVerifierError,setEmailVerifierError] = useState(false)
  const [userName,setUserName] = useState('')
  const [userNameError,setUserNameError] = useState(false)
  const [emailVerifierErrorMessage, setEmailVerifierErrorMessage] = useState(emailVerifierErrorMessageBasic)
  const [userFavorites,setUserFavorites] = useState([])
  const [modalOpen,setModalOpen]  = useState(false)

  useEffect(() => {

    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        const user_response = await fetchData(`api/v1/users/userdata/`, "could not receive user data ", fetchParams).promise
        console.log("response getting user data")
        console.dir(user_response)
        setUserObject(user_response)
        setEmail(user_response.user.email)
        setEmailVerifier(user_response.user.email)
        setUserName(user_response.user.username)
        setIsLoading(false)
        setUserFavorites(user_response.coin)
      } catch (error) {
        console.log("error","something is broke getting user details")
      }
    })()

  }, [])

  const handleUserNameChange = e => setUserName(e.target.value)
  const handleEmailChange = e => setEmail(e.target.value)
  const handleEmailVerifierChange = e => setEmailVerifier(e.target.value)

  const validateEmailTLD = () => {
    //get just the domain part of the email
    let full_email = email.split("@")  // is there only one @?
    var parsed_domain = parse(full_email[1])

    //email_domain.tld should have tld
    var validTLD = isValid(full_email[1])

    if (!validTLD) {
      //this is not a valid tld
      setEmailError(true)
      setEmailErrorMessage(emailErrorTLD)
      console.log("there is an error in the email tld")
      return false
    } else {
      console.log("there is NO error in the email tld")
      return true
    }
  }

  const handleUserChangeSubmit = e => {
    e.preventDefault()
    let errorCount = 0

    //console.log("top of handleUserChangeSubmit")

    // first is to check if all required forms are filled
    if (hofs.isPopulated(userName)) {
      setUserNameError(false)
    } else {
      errorCount++
      setUserNameError(true)
    }


    if (hofs.isPopulated(email)) {
      if (hofs.validateEmail(email)) {
        setEmailError(false)
        setEmailErrorMessage('')
      } else {
        setEmailError(false)
        setEmailError(true)
      }
      if (hofs.isPopulated(emailVerifier)) {
        if (emailVerifier === email) {
          setEmailVerifierErrorMessage('')
          setEmailVerifierError(false)
        } else {
          errorCount++
          setEmailVerifierErrorMessage(emailVerifierErrorMessageBasic)
          setEmailVerifierError(true)
          //only one message so far...no need to change it
        }
      } else {
        errorCount++
        setEmailVerifierError(true)
      }
    } else {
      errorCount++
      setEmailError(true)
    }

    if (!validateEmailTLD()) {
      //error in email tld
      errorCount++
    }

    // need to check if email is unique with backend
    if (errorCount === 0) {
      // there are no errors...send the data to backend to complete registration

      //console.log("there are no errors in form...show verify email modal")


      const fetchParams = {
        method: 'PUT',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: {
            username: userName,
            email: email,
            id: userObject.user.id,
          },
          coin: userFavorites
        })
      }
      //console.log("user change parameters are:" )
      //console.dir(fetchParams)
      const errorMessage = 'Error creating your account.  Please contact support to do so.'
      ;(async () => {
        const response = await fetchData('api/v1/users/userdata/', errorMessage, fetchParams).promise

        //console.log("user change response is:")
        //console.dir(response)

        if (response === true) {
          // user changed
          console.log("user details changed")
          setModalOpen(true)

        } else {
          //there is an error....what to do?
          if (response.error && response.error.username) {
            console.log("error.username exists")
            setUserNameError(true)

          }
          if (response.error && response.error.email) {
            setEmailError(true)
          }
          console.log("user change error is: " + response.error_message)
        }
      })()
    }
  }

  if (isLoading) return <CircularProgress />
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
        {/* header */}
        <TopAppBar userObject={userObject} selectedPage='mycurrencies' />
        {/* main content */}
        <Main theme={theme} >
          <Container maxWidth="xl">
            <Box sx={{width: '100%', paddingTop: '10px', paddingBottom: '20px'}}>
              <Container component='main' maxWidth='sm' sx={{marginTop: '50px', justifyContent: 'center'}}>
                <div sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <Typography variant='h3' sx={{paddingBottom: '25px'}}>
                    Make Changes To Your Account
                  </Typography>
                  <form sx={{ width: '100%', marginTop: theme.spacing(5.5) }} noValidate onSubmit={handleUserChangeSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          name='username'
                          variant='outlined'
                          required
                          fullWidth={true}
                          value={userName}
                          id='userName'
                          label='User Name - Display Name'
                          autoComplete='User Name'
                          onChange={handleUserNameChange}
                          helperText={userNameError ? userNameErrorMessage : null}
                          error={userNameError}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          required
                          fullWidth
                          id='email'
                          value={email}
                          label='Email Address'
                          name='email'
                          autoComplete='email'
                          onChange={handleEmailChange}
                          helperText={emailError ? emailErrorMessage : null}
                          onBlur={validateEmailTLD}
                          error={emailError}
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          required
                          fullWidth
                          id='email_verifier'
                          value={emailVerifier}
                          label='Email Address Verifier'
                          name='email_verifier'
                          autoComplete='email'
                          onChange={handleEmailVerifierChange}
                          helperText={emailVerifierError ? emailVerifierErrorMessage : null}
                          error={emailVerifierError}
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      sx={{ margin: theme.spacing(3, 0, 2) }}
                      // disabled={checkingForDuplicateEmail}
                    >
                      Click to Save User Changes
                    </Button>
                  </form>
                </div>
                <Modal
                  aria-labelledby='spring-modal-title-agreement'
                  aria-describedby='spring-modal-description-agreement'
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
                  open={modalOpen}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{ timeout: 500 }}
                  disableEscapeKeyDown={true}
                >
                  <Fade in={modalOpen}>
                    <Box sx={{ position: 'absolute', width: 600, backgroundColor: '#fff',  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)'}}>
                      <h2 id='spring-modal-title'>Alpha Centauri Block Chain App User Changes </h2>
                      <Grid container>
                        <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                          <Typography variant="subtitle2">Your changes have been saved</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: '25px'}}>
                          <Button startIcon={<ThumbUpAlt />} variant='contained' href="/home" color='primary'>
                            Click To Return Home
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Fade>
                </Modal>


              </Container>

              <Footer />
            </Box>
          </Container>
        </Main>
      </Box>
  )
}

export default User