import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'

// material-ui
import { styled, useTheme } from '@mui/material/styles'
import { Box, CssBaseline, useMediaQuery, Container } from '@mui/material'

// project imports
import Currencies from './Currencies'
import TopAppBar from '../TopAppBar'
import { SET_MENU } from '../../store/types'
import Footer from '../Footer'


// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: '20px',
            width: `calc(100% - 20px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - 20px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '3px',
            width: `calc(100% - 3px)`,
            padding: '2px',
            marginRight: '3px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - 20px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',

        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const Home = ( { userObject }) => {
    console.log("top of Home and userObject is:")
    console.dir(userObject)
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <TopAppBar userObject={userObject} selectedPage="currencies" />

            {/* drawer
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
            */}


            {/* main content */}
            <Main theme={theme}>
              <Container maxWidth="false">
                {/* breadcrumb
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />*/}
                <Currencies />
                <Footer />
              </Container>
            </Main>
            {/* <Customization /> */}
            
        </Box>
    );
};

export default Home;