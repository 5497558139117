import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon, TableHead, TableCell  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { styled, useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconStethoscope, IconRuler2, IconArrowUpCircle, IconArrowDownCircle, IconCircle } from '@tabler/icons';

const ExchangeInflow = ( ) => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About Exchange Inflow</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - Exchange Inflows">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
                <Tab icon={<IconStethoscope />} value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
                Exchange Inflow Total is defined as an amount of coin deposited into the exchange wallets.
              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="h4">By Value Itself</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconArrowUpCircle color='#00b341'/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Increase of inflow to exchanges is mostly a Bearish sign"
                    />
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="h4">By examining trend</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconCircle color='#000'/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Increasing inflow/outflow  indicates possible volatility and helps predict market trend"
                    />
                  </ListItem>
                </List>
              </Typography>
            </TabPanel>
            <TabPanel value="3">
              <Typography variant="h4">Explanation About Inflow</Typography>
              <Typography variant="body2" sx={{marginTop: '15px'}}>
                Considering the fact that moving coins costs fees, wallets outside from exchange send their coins to the exchanges for three possible reasons.
              </Typography>
              <Typography variant="h5" sx={{fontWeight: 600, marginTop: '15px'}}> 1) To sell their coins - Bearish</Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
              In the case of inflow to spot exchange, it is highly likely that investors are sending BTC for selling. This action includes retail buyers' coins moving into the exchange but also the result of custody services provided by institutional buyers. Instead of storing them in cold storage, moving into exchange wallets indicate the urge to turn coins into fiat or stablecoins.
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
              This reason of action mostly leads to the price drop which indicates a bearish sign.
              </Typography>
              <Typography variant="h5" sx={{fontWeight: 600, marginTop: '15px'}}>2) To trade in derivative market - volatility risk</Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
              The purpose for sending coins to derivative market wallets indicates that more trades will be happening on the derivative market. This could possibly lead to increased volatility risk as a result of increased trading activity, investors looking to take profits, and/or to rebalance to de-risk their investment portfolios. This type of action has difficulty in deciding the effect on price since coins in the derivative market could be used to open both long/short positions.
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
              Instead, it should be interpreted as increased volatility risk.
              </Typography>
              <Typography variant="h5" sx={{fontWeight: 600, marginTop: '15px'}}>3) To use services in the exchanges - neutral</Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
              Sometimes, investors send their coins to exchange for the purposes of staking, airdrop, and to fit the requirement for IEO. In this case, there is no indication to infer on both price or volatility.  However, in the case of an airdrop, indicators such as funding rate and open interest should be considered due to price hedge motivation.
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
              This reason of action should be interpreted as neutral.
              </Typography>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default ExchangeInflow