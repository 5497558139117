import React from 'react'
import { Grid, Typography, Stack, Box, Tooltip, IconButton, Button } from '@mui/material'
import { AreaChart, BarChart, Bar, XAxis, YAxis, Area, Line, LineChart, ResponsiveContainer  } from "recharts"

import { IconArrowUpRightCircle, IconTrendingDown, IconTrendingUp, IconArrowNarrowRight } from '@tabler/icons'
import { HelpOutline } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'
const HealthDisplayReserve = ( { ruleThemAll } ) => {

  const theme = useTheme()

  const abbreviateValue = (flowValue) => {
    if (flowValue > 1000000000) {
      return ((flowValue / 1000000000).toFixed(2)) + 'B'
    } else if (flowValue > 1000000) {
      return ((flowValue/1000000).toFixed(2)) + 'M'
    } else if (flowValue > 1000) {
      return ((flowValue/1000).toFixed(2)) + 'K'
    } else {
      //just display the full amount
      return flowValue
    }
  }

  //only need last 7 days of data
  //var reserveData = ruleThemAll.slice((ruleThemAll.length-8), (ruleThemAll.length-1)) //as data is latest first ....this does not work
  var reserveData = ruleThemAll.slice(0, 7) //as data is latest first ....this does not work

  //console.log("reserveData is:")
  //console.dir(reserveData)

  if (reserveData.length === 0) {
    return (
      <Box sx={{width: '100%'}} >
        <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.primary.dark}}>Exchange Reserve</Typography>
        <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
      </Box>
    )
  } else {
    reserveData.reverse()

    var rechartsExchangeData = reserveData.map( (data) => {
      return { 'name': 0, 'value': data.healthData.reserve , 'amt': data.healthData.reserve, "date": data.healthData.timestamp }
    })

    //get data range for reserve data
    var minMaxReserve = reserveData.map( (data) => {
      if (data.healthData)
        return data.healthData.reserve
    })

    var reserveMax = minMaxReserve.reduce((a, b) => { return Math.max(a, b) })
    var reserveMin = minMaxReserve.reduce((a, b) => { return Math.min(a, b) })

    //get percentage change from 2nd to last to last
    var percentageChange = ((reserveData[reserveData.length - 1].healthData.reserve - reserveData[reserveData.length - 2].healthData.reserve)/reserveData[reserveData.length - 2].healthData.reserve) * 100
    //console.log("percentageChange is: " + percentageChange)
    //console.log("reserveData[reserveData.length - 1].reserve is: " + reserveData[reserveData.length - 1].healthData.reserve)
    //console.log("reserveData[reserveData.length - 2].reserve is: " + reserveData[reserveData.length - 2].healthData.reserve)

    var pressureIndicatorColor = theme.palette.grey[700]
    var pressureIndicatorDesc = "Pressure Indicator: Neutral (7 days)" 
    var pressureIndicatorIcon = <IconArrowNarrowRight color={theme.palette.grey[700]} size={25}/>
    if (Math.abs(percentageChange) > 1.5) {
      var pressureIndicatorColor = theme.palette.error.dark
      if (percentageChange > 0) {
        pressureIndicatorIcon = <IconTrendingUp color={theme.palette.error.dark} size={25}/>
        pressureIndicatorDesc = "Sell Pressure Indicator: Reserve Level Spike (7 days)"
      } else {
        pressureIndicatorIcon = <IconTrendingDown color={theme.palette.error.dark} size={25}/>
        pressureIndicatorDesc = "Buy Pressure Indicator: Reserve Level Spike (7 days)"
      }
    }

    var rechartsExchangeInflow = reserveData.map( (data) => {
      if (data.healthData)
        return { 'name': 0, 'value': data.healthData.inflow_total, 'amt': data.healthData.inflow_total, "date": data.healthData.timestamp }
      else
        return { 'name': 0, 'value': null , 'amt': null }
    })

    //get data range for inflow data
    var minMaxInflow = reserveData.map( (data) => {
      if (data.healthData)
        return data.healthData.inflow_total
    })



    //console.log("minMaxInflow is: ")
    //console.dir(minMaxInflow)

    //for now this does not work as sometimes healthdata is not there for a few of them
    //var inflow7dTotal = minMaxInflow.reduce((a, b) => a + b , 0);

    var inflow7dTotal = 0
    minMaxInflow.map( inflowValue => inflowValue != undefined ? inflow7dTotal = inflow7dTotal + inflowValue : null)

    var inflowMax = minMaxInflow.reduce((a, b) => { return Math.max(a, b) })
    var inflowMin = minMaxInflow.reduce((a, b) => { return Math.min(a, b) })

    var rechartsExchangeOutflow = reserveData.map( (data) => {
      if (data.healthData)
        return { 'name': 0, 'value': data.healthData.outflow_total, 'amt': data.healthData.outflow_total, "date": data.healthData.timestamp }
      else
        return { 'name': 0, 'value': null , 'amt': null, "date": null }
    })

    //get data range for outflow data
    var minMaxOutflow = reserveData.map( (data) => {
      if (data.healthData)
        return data.healthData.outflow_total
    })

    //for now this does not work as sometimes healthdata is not there for a few of them
    //var outflow7dTotal = minMaxOutflow.reduce((a, b) => a + b, 0);

    var outflow7dTotal = 0
    minMaxOutflow.map( outflowValue => outflowValue != undefined ? outflow7dTotal = outflow7dTotal + outflowValue : null)

    var outflowMax = minMaxOutflow.reduce((a, b) => { return Math.max(a, b) })
    var outflowMin = minMaxOutflow.reduce((a, b) => { return Math.min(a, b) })

    const displayQuickDate = (timestamp) => {
      let dateOfData = new Date(timestamp)
      return dateOfData.getMonth() + '/' + dateOfData.getDate()
    }

    return (
        <Stack
            direction="column"
            display="flex"
            justifyContent="space-around"
            sx={{width: '100%', backgroundColor: theme.palette.dark.dark, padding: '5px', paddingTop: '0px', paddingBottom: '5px', borderRadius: '5px', marginTop: '5px'}}
        >
            <Stack
                direction="row"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>Exchange Reserve</Typography>
            </Stack>
            <Stack direction="row" display="flex" justifyContent="space-around">
                <Box
                    sx={{width: '49%', backgroundColor: theme.palette.dark.main, cursor: 'pointer'}}
                >
                    <Stack direction="column" display="flex" justifyContent="flex-end" sx={{minHeight: '10vh'}}>
                        <Stack
                            direction="row"
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Typography variant="caption" sx={{marginLeft: '5px'}}>reserve</Typography>

                        </Stack>

                        <ResponsiveContainer width='97%' height={85}>
                            <LineChart
                            data={rechartsExchangeData}
                            >
                            <XAxis
                                dataKey="date"
                                tickFormatter={ (value) => displayQuickDate(value)}
                                style={{fontSize: '.6rem'}}
                            />
                            <YAxis 
                                dataKey="value"
                                domain={[reserveMin,reserveMax]}
                                tick={{stroke: '#888', strokeWidth: .5}}
                                tickFormatter={(value) => abbreviateValue(value)}                  
                                orientation='right'
                            />
                            <Line tickLine={true} strokeWidth={2} type="monotone" dataKey="value" dot={false} stroke={theme.palette.primary.dark} />
                            </LineChart>
                        </ResponsiveContainer>
                        <Box sx={{width: '100%', paddingLeft: '5px', minHeight: '3vh', paddingRight: '5px', height: '100%', backgroundColor: theme.palette.dark[800]}}>
                            <Stack direction="row" display="flex" justifyContent="space-around" alignItems="center">
                            <Typography display="button" sx={{fontSize: '.9rem', fontWeight: 700, color: pressureIndicatorColor}}>{pressureIndicatorDesc} </Typography>
                            {pressureIndicatorIcon}
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
                <Box
                    sx={{width: '24%', backgroundColor: theme.palette.dark.main, cursor: 'pointer'}}
                >
                    <Stack direction="column" display="flex" justifyContent="flex-end" sx={{minHeight: '10vh'}} >
                    <Stack
                        direction="row"
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Typography variant="caption" sx={{marginLeft: '5px'}}>inflows</Typography>
                    </Stack>
                    <ResponsiveContainer width='99%' height={85}>
                        <BarChart
                        data={rechartsExchangeInflow}
                        
                        >
                        <XAxis hide={true} />
                        <YAxis hide={true} domain={[inflowMin,inflowMax]}/>
                        <Bar dataKey="value" fill={theme.palette.primary.dark} />
                        </BarChart>
                    </ResponsiveContainer>
                    <Box sx={{width: '100%', paddingLeft: '5px', minHeight: '3vh', paddingRight: '5px', height: '100%', backgroundColor: theme.palette.dark[800]}}>
                        <Stack direction="row" display="flex" alignItems="baseline" justifyContent="space-around" >
                        <Typography display="inline" sx={{fontSize: '.7rem'}}>7d total:</Typography>
                        <Typography variant="button" sx={{fontSize: '.9rem', fontWeight: 700}}>$ {abbreviateValue(inflow7dTotal)}</Typography>
                        </Stack>
                    </Box>
                    </Stack>
                </Box>
                <Box
                    sx={{width: '24%', backgroundColor: theme.palette.dark.main, cursor: 'pointer'}}
                >
                    <Stack direction="column" display="flex" justifyContent="flex-end" sx={{minHeight: '10vh'}}>
                        <Stack
                            direction="row"
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Typography variant="caption" sx={{marginLeft: '5px'}}>outflow</Typography>
                        </Stack>
                        <ResponsiveContainer width='99%' height={85}>
                            <BarChart
                            data={rechartsExchangeOutflow}
                            >
                            <XAxis hide={true} />
                            <YAxis hide={true} domain={[outflowMin,outflowMax]}/>
                            <Bar dataKey="value" fill={theme.palette.primary.dark} />
                            </BarChart>
                        </ResponsiveContainer>
                        <Box sx={{width: '100%', paddingLeft: '5px', minHeight: '3vh', paddingRight: '5px', height: '100%', backgroundColor: theme.palette.dark[800]}}>
                            <Stack direction="row" display="flex" alignItems="baseline" justifyContent="space-around" >
                            <Typography display="inline" sx={{fontSize: '.7rem'}}>7d total:</Typography>
                            <Typography variant="button" sx={{fontSize: '.9rem', fontWeight: 700}}>$ {abbreviateValue(outflow7dTotal)}</Typography>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    )
  }
}

export default HealthDisplayReserve