import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import { IconButton, Divider, Grid, Paper, Popover, CircularProgress, Typography, 
  useMediaQuery, FormControl, Stack, FormGroup, Button,
} from '@mui/material'

// third-party
import fetchData from '../../utility/endpointFetch'

// project imports
import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'
import HomeFeedTabFollowSearch from './HomeFeedTabFollowSearch'
import HomeFeedFollowedListItem from './HomeFeedFollowedListItem'

// assets
import { IconHeart, IconSquareX } from '@tabler/icons';

// ==============================|| white list manager Popover ||============================== //
// need to get the list of twitter users
// need to establish current whitelist
// left side will be twitter users to choose from...filtering out ones already in white list
// right side will be current white list

// onchange to add to white list one by one due to endpoint
// onchange to remove from white list.....one by one

//filters for twitter user list...
// only one built into endpoint is is_influencer

//seearch for twitter user list
// can only search through names...

//filters and search provided by endpoint are inadequate
// wants:
// filter/search by followers, coins associated, 

//possible...click on a user and get stats from the tweets from that user....ie coins mainly

//ugh....no ordering on the endpoint


const HomeFeedManager = ( { refreshHomeFeed } ) => {
  //console.log("top of HomeFeedManager")
  
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const [followedList,setFollowedList] = useState([])
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [isLoading,setIsLoading] = useState(true)

  const handleClose = (event) => {
    //console.log("top of handleClose")
    refreshHomeFeed(followedList)
    setAnchorEl(null)
    setOpen((prevOpen) => !prevOpen)
  };

  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    setIsLoading(true)
    //console.log("top of useEffect in HomeFeedManager to load followed")
    const fetchFollowedParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }    
    ;(async () => {
      try {
        const response = await fetchData(`api/v1/users/userdata/`, "could not receive user data in home feed manager", fetchFollowedParams).promise
        //console.log("response getting user data in home feed")
        //console.dir(response)
        setFollowedList(response.twitter_feed.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        }))
        setIsLoading(false)
      } catch (error) {
        console.log("could not receive user data in home feed manager: ",error)
      }
    })()
  }, [])

  const removeUserFromTwitterFeedList = (twitterUserToRemove) => {
    //console.log("top of removeUserFromTwitterFeedList")
    //console.log("twitterUserToRemove is: " + twitterUserToRemove)
    if (followedList.includes(twitterUserToRemove)) {
      let newFollowed = followedList.filter( followed => followed != twitterUserToRemove)
      //console.dir(newFollowed)
      //setFollowedList(newFollowed)

      const fetchParams = {
        method: 'DELETE',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'handle_list': [twitterUserToRemove],
        })
      }
      ;(async () => {
        try {
          const response = await fetchData(`api/v1/users/twitter_feed_update/?feed_index=0`, "could not remove twitter user from white list", fetchParams).promise
          console.log("response removing twitter user from white list")
          console.dir(response)

          const getUserAddfetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
          }          
          ;(async () => {
            try {
              const response = await fetchData(`api/v1/users/userdata/`, "could not receive user data for whitelist change", getUserAddfetchParams).promise
              //console.log("response getting user data for whitelist change")
              //console.dir(response)
              //console.log("followedList is: ")
              //console.dir(followedList)
              let whiteList = response.twitter_feed
              //console.log("retrieved whiteList is:")
              //console.dir(whiteList)
              setFollowedList(whiteList.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              }))
            } catch (error) {
                console.log("something is broke getting user data in whitelist change: ", error)
            }
          })()

        } catch (error) {
          console.log("could not remove twitter user from white list: %s", error)
        }
      })() 
    } else {
      console.log("user not in followedList.  Do not need to remove: " + twitterUserToRemove)
    } 
  }

  const addUserToWhiteList = (userToAdd, event) => {
    console.log("top of addUserToWhiteList")
    console.log("userToAdd is: ")
    console.dir(userToAdd)
    //setAnchorEl(event.currentTarget)
    var currentFollowed = followedList

    if (!currentFollowed.includes(userToAdd)) {
        currentFollowed.push(userToAdd)
        
        const addFetchParams = {
            method: 'POST',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'handle_list': [ userToAdd ],
            })
        }
        ;(async () => {
          const response = await fetchData(`api/v1/users/twitter_feed_update/?feed_index=0`, "could not add twitter user to white list", addFetchParams).promise
          console.log("response adding twitter user to white list")
          console.dir(response)
          console.dir(currentFollowed)
          //setFollowedList(currentFollowed)
          const getUserAddfetchParams = {
              method: 'GET',
              dataType: 'json',
              headers: {
                'Content-Type': 'application/json'
              },
          }          
          ;(async () => {
            try {
              const response = await fetchData(`api/v1/users/userdata/`, "could not receive user data for whitelist change", getUserAddfetchParams).promise
              //console.log("response getting user data for whitelist change")
              //console.dir(response)
              //console.log("currentFollowed is: ")
              //console.dir(currentFollowed)
              let whiteList = response.twitter_feed
              //console.log("whiteList is:")
              //console.dir(whiteList)
              setFollowedList(whiteList.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              }))
            } catch (error) {
                console.log("something is broke getting user data in whitelist change: ", error)
            }
          })()             
        })()
    } else {
      console.log("already exists in followed list")
    }

  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prevOpen) => !prevOpen)
  }

  if (isLoading) return <CircularProgress />
  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{borderRadius: '12px', background: theme.palette.primary.dark, '&:hover': {
          background: theme.palette.primary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
          color: theme.palette.primary.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
        }}}
        size="small"
      >
        Manage Followed
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 400, left: 900 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >

        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} >
          <Paper>
            <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '900px', minHeight: '800px'}}>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  
                  <Typography variant="h3" sx={{marginTop: '15px', marginLeft: '30px'}}>Choose Twitter Users To Follow In Your Home Feed</Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="close"
                    sx={{color: theme.palette.error.main}}
                    onClick={handleClose}>
                    <IconSquareX sx={{ marginTop: '20px'}}/>
                  </IconButton>
                </Grid>
                <Grid item xs={12}><Divider sx={{width: '100%' }}/></Grid>
                <Grid item xs={9} >
                  <HomeFeedTabFollowSearch 
                    addUserToWhiteList={addUserToWhiteList} 
                    followedList={followedList}
                  />                  
                </Grid>
                <Grid item xs={3}>
                  <Stack direction="column" sx={{minHeight: '650px', overflow: 'hidden'}} >
                    <Typography variant="h5" sx={{marginRight: '20px', paddingTop: '5px', color: theme.palette.primary.dark }}>Currently Followed: {followedList.length} </Typography>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                      <FormGroup>
                        {followedList.map( (followed, index ) => <HomeFeedFollowedListItem key={index} followed={followed} removeUserFromTwitterFeedList={removeUserFromTwitterFeedList} />  )}
                      </FormGroup>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </MainCard>
          </Paper>
        </Transitions>
      </Popover>
    </div>
  );
};

export default HomeFeedManager