import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Tab } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'

import VIPChartTabsSelected from './VIPChartTabsSelected'
import VIPAllAtFollowed from './VIPAllAtFollowed'
import VIPAllAtMentioned from './VIPAllAtMentioned'
import VIPAllHash from './VIPAllHash'
import VIPAllCoins from './VIPAllCoins'

const VIPChartTabs = ( { coinChart, coinChartName, atFollowedTabValue, atMentionedTabValue, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, hashTabValue, dateInChart, changeDateInChart, vipConfig, officialTwitter, userObject, showOfficialTweets, localVIPCoins } ) => {
  //console.log("top of VIPChartTabs")
  //console.log("officialTwitter is: " + officialTwitter)
  //console.log("localVIPCoins is: ")
  //console.dir(localVIPCoins)

  const [tabValue,setTabValue] = useState('1')
  const [chartAnnotations,setChartAnnotations] = useState([])
  //const [allCoinsScrollTo,setAllCoinsScrollTo] = useState('')
  const allCoinsScrollTo = ''

  var is_internal = false
  if (userObject.user.is_internal === 'true' || userObject.user.is_internal === true) is_internal = true

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const theme = useTheme()

  if (dateInChart !== '' && is_internal) {
    //console.log("setting dateInChart value to: "+ dateInChart)
    let newChartAnnotations = [] 
    let chartDate = new Date(dateInChart)
    newChartAnnotations.push({
      x: chartDate.getTime(),
      borderColor: theme.palette.waring.dark,
      strokeDashArray: 0,
      label: {
        style: {
          background: theme.palette.warning.dark,
          color: '#000'
        },
        text: "tweeted: " + chartDate.getHours() + ":"+ (chartDate.getMinutes()<10?'0':'') + chartDate.getMinutes(),
        orientation: 'horizontal',
        mouseEnter: function (e)  {
          //console.log("mouseEnter")
          //console.dir(e)

        },
      }
    })
    setChartAnnotations(newChartAnnotations)
  }

  const changeChartAnnotations = (annotationsList) => {
    //console.log("top of changeChartAnnotations and annotationList is:")
    //console.dir(annotationsList)
    if (is_internal) {
      let newChartAnnotations = []
      annotationsList.map( postNotification => {
        if (postNotification.post !== null) {
          let chartDate = new Date(postNotification.created)
          newChartAnnotations.push({
            y: postNotification.created,
            x: chartDate.getTime(),
            borderColor: theme.palette.warning.dark,
            strokeDashArray: 0,
            label: {
              dateToScrollTo: postNotification.created,
              style: {
                background: theme.palette.warning.dark,
                color: '#000'
              },
              //text: "posted: " + chartDate.getHours() + ":"+ (chartDate.getMinutes()<10?'0':'') + chartDate.getMinutes(),
              text: postNotification.twitter_display_name,

              orientation: 'vertical',
              mouseEnter: function (event, chartContext, config) {
                //console.log("mouseEnter")
                //console.dir(event)
                //console.dir(chartContext)
                //console.dir(config)
                //let rolloverDate = new Date(event.x)
                //console.log("rolloverDate is: " + rolloverDate.toLocaleDateString())
                //console.log("scroll to: scrollToName"+postNotification.tweet_id)
                document.getElementById("scrollToName"+postNotification.tweet_id).scrollIntoView({behavior: "smooth", block: "start"})
                

              },
              
            }
          })
        } else {
          //there is no post within this post notification
        }
      }
      
      )
      //console.log("newChartAnnotations is:")
      //console.dir(newChartAnnotations)
      setChartAnnotations(newChartAnnotations)
    } else {
      //only display chartannotations to is_internal users
    }
  }

  useEffect(() => {
    setTabValue('1')
  }, [coinChart])

  if (vipConfig === "noize") {
    return (
      <Box sx={{width: '100%', height: '1050px',}}>
        <TabContext value={tabValue} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="" indicatorColor="secondary" textColor="primary" >
              <Tab label="selected chart" value="1" sx={{minWidth: '30px'}}/>
              <Tab label="all @ followed" value="2" sx={{minWidth: '30px'}} />
              <Tab label="all @ mentioned" value="3" sx={{minWidth: '30px'}} />
              <Tab label="all #" value="4" sx={{minWidth: '30px'}} />
              {/*<Tab label="all coin" value="5" sx={{minWidth: '30px'}} />*/}
            </TabList>
          </Box>
          <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
            <Box sx={{paddingLeft: '0px', width: '100%', marginLeft: '0px', height: '390px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPChartTabsSelected
                chartAnnotations={chartAnnotations}
                coinChart={coinChart}
                coinChartName={coinChartName}

              />
            </Box>
            <Box sx={{paddingLeft: '0px', width: '100%', marginLeft: '0px', height: '680px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPAllCoins 
                coinChart={coinChart}
                changeAtFollowedTab={changeAtFollowedTab}
                setCoinInChart={setCoinInChart}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeDateInChart={changeDateInChart}
                changeChartAnnotations={changeChartAnnotations}
                allCoinsScrollTo={allCoinsScrollTo}
                showOfficialTweets={showOfficialTweets}
                officialTwitter={officialTwitter}
                coinChartName={coinChartName}
              />
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box sx={{height: '975px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPAllAtFollowed
                atFollowedTabValue={atFollowedTabValue}
                setCoinInChart={setCoinInChart}
                changeAtFollowedTab={setCoinInChart}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                changeDateInChart={changeDateInChart}
                showOfficialTweets={showOfficialTweets}
              />
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <Box sx={{height: '975px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPAllAtMentioned
                atMentionedTabValue={atMentionedTabValue}
                setCoinInChart={setCoinInChart}
                changeAtFollowedTab={changeAtFollowedTab}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                changeDateInChart={changeDateInChart}
                showOfficialTweets={showOfficialTweets}
              />
            </Box>
          </TabPanel>
          <TabPanel value="4">
            <Box sx={{height: '975px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPAllHash
                hashTabValue={hashTabValue}
                setCoinInChart={setCoinInChart}
                changeAtFollowedTab={changeAtFollowedTab}
                changeAtMentionedTab={changeAtMentionedTab}
                changeHashTab={changeHashTab}
                changeCoinInTab={changeCoinInTab}
                changeDateInChart={changeDateInChart}
                showOfficialTweets={showOfficialTweets}
              />
            </Box>
          </TabPanel>
          {/*<TabPanel value="5">
            <Box sx={{height: '680px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
              <VIPAllCoin />
            </Box>
          </TabPanel>*/}
        </TabContext>
      </Box>
    )
  } else {
    return (
      <>
      <Box sx={{paddingLeft: '0px', width: '100%', marginLeft: '0px', height: '390px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
        <VIPChartTabsSelected
          chartAnnotations={chartAnnotations}
          coinChart={coinChart}
          userObject={userObject}
          coinChartName={coinChartName}
        />
      </Box>
      <Box sx={{paddingLeft: '0px', width: '100%', marginLeft: '0px', height: '680px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
        <VIPAllCoins 
          coinChart={coinChart}
          officialTwitter={officialTwitter}
          changeAtFollowedTab={changeAtFollowedTab}
          setCoinInChart={setCoinInChart}
          changeAtMentionedTab={changeAtMentionedTab}
          changeHashTab={changeHashTab}
          changeDateInChart={changeDateInChart}
          changeChartAnnotations={changeChartAnnotations}
          allCoinsScrollTo={allCoinsScrollTo}
          showOfficialTweets={showOfficialTweets}
          localVIPCoins={localVIPCoins}
        />
      </Box>
      </>      
    )
  }
}

export default VIPChartTabs