import { Link } from 'react-router-dom'

// material-ui
import { ButtonBase, Typography } from '@mui/material'

// project imports
import config from '../../config';
import Logo from './Logo';

// ==============================|| MAIN LOGO ||============================== //


const LogoSection = ( {medScreenOrSmaller=false} ) => {
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            <Logo />
            { medScreenOrSmaller ? null : <Typography variant="h3">Alpha Centoro</Typography> }
        </ButtonBase>
    )
}

export default LogoSection