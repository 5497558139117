import React, { useState, useEffect } from 'react'
import {
  CircularProgress, useMediaQuery, 
  Box,
  Pagination,
  Backdrop,
  Typography,
  Stack,
  Fade,
  Modal,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Checkbox
} from '@mui/material'
import { styled } from '@mui/material/styles';
import CurrenciesList from './CurrenciesList'
import CurrenciesListHeader from './CurrenciesListHeader'
import CurrencyFilterRow from './CurrencyFilterRow'
import fetchData from '../../utility/endpointFetch'
import { useTheme } from '@mui/material/styles'
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";

const Currencies = ( ) => {

  //console.log("top of Currencies")
  const [addTwitterModal, setAddTwitterModal] = useState(false)
  const [cookies, setCookie] = useCookies(['do_not_show_twitter_add_again'])
  const [hasAddedTwitter, setHasAddedTwitter] = useState(false)
  const [currencyList,setCurrencyList] = useState([])
  const [currencyPage,setCurrencyPage] = useState(1)
  const [origCurrencyList,setOrigCurrencyList] = useState([])
  const [categoryList,setCategoryList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [categoryChosen,setCategoryChosen] = useState('')
  const [currentTimeFrame,setCurrentTimeFrame] = useState(1)
  const [sortByColumn,setSortByColumn] = useState('rank')
  const [sortingBy,setSortingBy] = useState('rank-highest')
  const [sortByColumnDirection, setSortByColumnDirection] = useState('down')
  const [sortByUrl,setSortByUrl] = useState('&ordering=market_cap_rank')
  const [filterByUrl,setFilterByUrl] = useState('')
  const [backdropOpen,setBackdropOpen] = useState(false)
  const [responseCount,setResponseCount] = useState(0)
  const [pageCount,setPageCount] = useState(1)
  const [twitterImportText, setTwitterImportText] = useState('')
  const [visibleColumns, setVisibleColumns] = useState({
    'Current Price': true,
    '24h High': true,
    '24h Low': true,
    '24h Price Chart': true,
    '24h Volume': true,
    'Market Cap': true,
    'BTC Dif': true,
    'ETH Dif': true,
    'Market Cap Rank': true,
    'Social Rank': false,
    'Total Social Engagement': false,
    //'5 Min Price Change': false,
    //'15 Min Price Change': false,
    //"1 Hour Price Change": false,
    //"4 Hour Price Change": false,
    "1 Week Price Change": false,
    "2 Week Price Change": false,
    "Daily Influencer Posts": false,
    //"24 Hour Follower Change": false,
    //"1 Month Follower Change": false,
    //"1 Year Follower Change": false,
    //"24 Hour Post Volume Change": false,
    //"1 Week Post Volume Change": false,
    //"1 Month Post Volume Change": false,
    //"3 Month Post Volume Change": false,
    //"1 Year Post Volume Change": false,
    //"1 Year Post Volume Average": false,
    //"1 Month Post Volume Average": false,
    //"3 Month Post Volume Average": false,
  })
  const [doNotShowAgainChecked, setDoNotShowAgainChecked] = React.useState(false);

  const handleDoNotShowAgainCheckedChange = (event) => {
    setCookie('do_not_show_twitter_add_again', event.target.checked, { path: '/', maxAge: 31536000 });
    setDoNotShowAgainChecked(event.target.checked);
  };
  //const [userCoinAlerts,setUserCoinAlerts] = useState([])
  //const [userCoinAlertsList,setUserCoinAlertsList] = useState([])

  //const [userDataObject,setUserDataObject] = useState(userObject)

  const [searchTerm,setSearchTerm] = useState('')
  
  const handleBackdropClose = () => {
    setBackdropOpen(false)
  }
  const theme = useTheme()
  const medScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"))

  const sortResults = (sortBy, results=origCurrencyList) => {
    //console.log("top of sortResults: " + sortBy)
    setSortingBy(sortBy)
    setCurrencyPage(1)
    setBackdropOpen(true)
    switch(sortBy) {
      case 'rank-highest':
        //setCurrencyList( results.sort( (a,b) => (a.market_cap_rank > b.market_cap_rank) ? 1  : -1))
        setSortByUrl("&ordering=market_cap_rank")
        setSortByColumn('rank')
        setSortByColumnDirection('down')
        break
      case 'rank-lowest':
        //setCurrencyList( results.sort( (a,b) => (a.market_cap_rank < b.market_cap_rank) ? 1 : -1))
        setSortByColumn('rank')
        setSortByUrl("&ordering=-market_cap_rank")
        setSortByColumnDirection('up')
        break
      case 'name-alphabetical':
        //setCurrencyList( results.sort( (a,b) => (a.name > b.name) ? 1 : -1))
        setSortByColumn('name')
        setSortByUrl("&ordering=-name")
        setSortByColumnDirection('down')
        break
      case 'name-alphabetical-reverse':
        //setCurrencyList( results.sort( (a,b) => (a.name < b.name) ? 1 : -1))
        setSortByColumn('name')
        setSortByUrl("&ordering=name")
        setSortByColumnDirection('up')
        break
      case 'symbol-alphabetical':
        //setCurrencyList( results.sort( (a,b) => (a.coin.symbol > b.coin.symbol) ? 1 : -1))
        setSortByColumn('symbol')
        setSortByUrl("&ordering=coin_symbol.keyword")
        setSortByColumnDirection('down')
        break
      case 'symbol-alphabetical-reverse':
        //setCurrencyList( results.sort( (a,b) => (a.coin.symbol < b.coin.symbol) ? 1 : -1))
        setSortByColumn('symbol')
        setSortByUrl("&ordering=-coin_symbol.keyword")
        setSortByColumnDirection('up')
        break
      case 'price-highest':
        //setCurrencyList( results.sort( (a,b) => (a.current_price > b.current_price) ? 1 : -1))
        setSortByColumn('price')
        setSortByUrl("&ordering=-current_price")
        setSortByColumnDirection('down')
        break
      case 'price-lowest':
        //setCurrencyList( results.sort( (a,b) => (a.current_price < b.current_price) ? 1 : -1))
        setSortByColumn('price')
        setSortByUrl("&ordering=current_price")
        setSortByColumnDirection('up')
        break
      case 'volume-lowest':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume > b.total_volume) ? 1 : -1))
        setSortByColumn('volume')
        setSortByUrl("&ordering=-total_volume")
        setSortByColumnDirection('down')
        break
      case 'volume-highest':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('volume')
        setSortByUrl("&ordering=total_volume")
        setSortByColumnDirection('up')
        break
      case '24h_low':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('24h_low')
        setSortByUrl("&ordering=-low_24h")
        setSortByColumnDirection('up')
        break
      case '24h_low_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('24h_low')
        setSortByUrl("&ordering=low_24h")
        setSortByColumnDirection('down')
        break
      case '24h_high':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('24h_high')
        setSortByUrl("&ordering=-high_24h")
        setSortByColumnDirection('up')
        break
      case '24h_high_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('24h_high')
        setSortByUrl("&ordering=high_24h")
        setSortByColumnDirection('down')
        break
      case 'btc_dif':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('btc_dif')
        setSortByUrl("&ordering=-btc_24h_dif")
        setSortByColumnDirection('up')
        break
      case 'btc_dif_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('btc_dif')
        setSortByUrl("&ordering=btc_24h_dif")
        setSortByColumnDirection('down')
        break
      case 'eth_dif':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('eth_dif')
        setSortByUrl("&ordering=-eth_24h_dif")
        setSortByColumnDirection('up')
        break
      case 'eth_dif_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('eth_dif')
        setSortByUrl("&ordering=eth_24h_dif")
        setSortByColumnDirection('down')
        break
      case 'market_cap':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('market_cap')
        setSortByUrl("&ordering=-market_cap")
        setSortByColumnDirection('up')
        break
      case 'market_cap_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('market_cap')
        setSortByUrl("&ordering=market_cap")
        setSortByColumnDirection('down')
        break
      case 'min_5_price_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('min_5_price_change')
        setSortByUrl("&ordering=-min_5_price_change")
        setSortByColumnDirection('down')
        break
      case 'min_5_price_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('min_5_price_change')
        setSortByUrl("&ordering=min_5_price_change")
        setSortByColumnDirection('up')
        break
      case 'min_15_price_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('min_5_price_change')
        setSortByUrl("&ordering=-min_15_price_change")
        setSortByColumnDirection('down')
        break
      case 'min_15_price_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('min_15_price_change')
        setSortByUrl("&ordering=min_15_price_change")
        setSortByColumnDirection('up')
        break
      case 'social_rank':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('social_rank')
        setSortByUrl("&ordering=-social_rank")
        setSortByColumnDirection('down')
        break
      case 'social_rank_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('social_rank')
        setSortByUrl("&ordering=social_rank")
        setSortByColumnDirection('up')
        break
      case 'total_social_engagement':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('total_social_engagement')
        setSortByUrl("&ordering=-total_social_engagement")
        setSortByColumnDirection('down')
        break
      case 'total_social_engagement_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('total_social_engagement')
        setSortByUrl("&ordering=total_social_engagement")
        setSortByColumnDirection('up')
        break
      case 'hour_1_price_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('hour_1_price_change')
        setSortByUrl("&ordering=-hour_1_price_change")
        setSortByColumnDirection('down')
        break
      case 'hour_1_price_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('hour_1_price_change')
        setSortByUrl("&ordering=hour_1_price_change")
        setSortByColumnDirection('up')
        break
      case 'hour_4_price_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('hour_4_price_change')
        setSortByUrl("&ordering=-hour_4_price_change")
        setSortByColumnDirection('down')
        break
      case 'hour_4_price_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('hour_4_price_change')
        setSortByUrl("&ordering=hour_4_price_change")
        setSortByColumnDirection('up')
        break
      case 'week_1_price_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('week_1_price_change')
        setSortByUrl("&ordering=-day_7_price_change")
        setSortByColumnDirection('down')
        break
      case 'week_1_price_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('week_1_price_change')
        setSortByUrl("&ordering=day_7_price_change")
        setSortByColumnDirection('up')
        break
      case 'week_2_price_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('week_2_price_change')
        setSortByUrl("&ordering=-day_14_price_change")
        setSortByColumnDirection('down')
        break
      case 'week_2_price_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('week_2_price_change')
        setSortByUrl("&ordering=day_14_price_change")
        setSortByColumnDirection('up')
        break
      case 'daily_influencer_post_count':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('daily_influencer_post_count')
        setSortByUrl("&ordering=-daily_influencer_post_count")
        setSortByColumnDirection('down')
        break
      case 'daily_influencer_post_count_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('daily_influencer_post_count')
        setSortByUrl("&ordering=daily_influencer_post_count")
        setSortByColumnDirection('up')
        break
      case 'day_1_follower_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('day_1_follower_change')
        setSortByUrl("&ordering=-day_1_follower_change")
        setSortByColumnDirection('down')
        break
      case 'day_1_follower_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('daily_influencer_post_count')
        setSortByUrl("&ordering=day_1_follower_change")
        setSortByColumnDirection('up')
        break
      case 'month_1_follower_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('month_1_follower_change')
        setSortByUrl("&ordering=-month_1_follower_change")
        setSortByColumnDirection('down')
        break
      case 'month_1_follower_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('month_1_follower_change')
        setSortByUrl("&ordering=month_1_follower_change")
        setSortByColumnDirection('up')
        break
      case 'year_1_follower_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('year_1_follower_change')
        setSortByUrl("&ordering=-year_1_follower_change")
        setSortByColumnDirection('down')
        break
      case 'year_1_follower_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('year_1_follower_change')
        setSortByUrl("&ordering=year_1_follower_change")
        setSortByColumnDirection('up')
        break
      case 'day_1_post_volume_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('day_1_post_volume_change')
        setSortByUrl("&ordering=-day_1_post_volume_change")
        setSortByColumnDirection('down')
        break
      case 'day_1_post_volume_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('day_1_post_volume_change')
        setSortByUrl("&ordering=day_1_post_volume_change")
        setSortByColumnDirection('up')
        break
      case 'day_7_post_volume_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('day_7_post_volume_change')
        setSortByUrl("&ordering=-day_7_post_volume_change")
        setSortByColumnDirection('down')
        break
      case 'day_7_post_volume_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('day_7_post_volume_change')
        setSortByUrl("&ordering=day_7_post_volume_change")
        setSortByColumnDirection('up')
        break
      case 'month_1_post_volume_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('month_1_post_volume_change')
        setSortByUrl("&ordering=-month_1_post_volume_change")
        setSortByColumnDirection('down')
        break
      case 'month_1_post_volume_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('month_1_post_volume_change')
        setSortByUrl("&ordering=month_1_post_volume_change")
        setSortByColumnDirection('up')
        break
      case 'month_3_post_volume_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('month_3_post_volume_change')
        setSortByUrl("&ordering=-month_3_post_volume_change")
        setSortByColumnDirection('down')
        break
      case 'month_3_post_volume_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('month_3_post_volume_change')
        setSortByUrl("&ordering=month_3_post_volume_change")
        setSortByColumnDirection('up')
        break
      case 'year_1_post_volume_change':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('year_1_post_volume_change')
        setSortByUrl("&ordering=-year_1_post_volume_change")
        setSortByColumnDirection('down')
        break
      case 'year_1_post_volume_change_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('year_1_post_volume_change')
        setSortByUrl("&ordering=year_1_post_volume_change")
        setSortByColumnDirection('up')
        break
      case 'year_1_post_volume_average':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('year_1_post_volume_average')
        setSortByUrl("&ordering=-year_1_post_volume_average")
        setSortByColumnDirection('down')
        break
      case 'year_1_post_volume_average_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('year_1_post_volume_average')
        setSortByUrl("&ordering=year_1_post_volume_average")
        setSortByColumnDirection('up')
        break
      case 'month_1_post_volume_average':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('month_1_post_volume_average')
        setSortByUrl("&ordering=-month_1_post_volume_average")
        setSortByColumnDirection('down')
        break
      case 'month_1_post_volume_average_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('month_1_post_volume_average')
        setSortByUrl("&ordering=month_1_post_volume_average")
        setSortByColumnDirection('up')
        break
      case 'month_3_post_volume_average':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('month_3_post_volume_average')
        setSortByUrl("&ordering=-month_3_post_volume_average")
        setSortByColumnDirection('down')
        break
      case 'month_3_post_volume_average_asc':
        //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
        setSortByColumn('month_3_post_volume_average')
        setSortByUrl("&ordering=month_3_post_volume_average")
        setSortByColumnDirection('up')
        break
    }
    return
  }

  const changePage = (event, page) => {
    //console.log("top of changePage")
    //console.dir(event)
    console.dir(page)
    setBackdropOpen(true)
    setCurrencyPage(page)
  }

  /*
    //remove this until can fix
    useEffect(() => {
    if (userDataObject.user.is_vip) {
      console.log("user IS VIP")
      const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
      }
      ;(async () => {
        try {
          const userAlertsResponse = await fetchData(`api/v1/vip/alertcoins/`, "could not receive user coin price alerts", fetchParams).promise
          console.log("userAlertsResponse getting user coin alerts")
          console.dir(userAlertsResponse)
          setUserCoinAlerts(userAlertsResponse.results)
          console.dir(userAlertsResponse.results.map( (alert) => alert.coin))
          setUserCoinAlertsList(userAlertsResponse.results.map( (alert) => alert.coin))
          
        } catch (error) {
          console.log("something is broke getting user coin price alerts", error)
        }
      })()
    } else {
      console.log("user is not vip")
    }
  },[]) */

  useEffect(() => {
    const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
    ;(async () => {
      try {
        const userDataResponse = await fetchData(`api/v1/users/userdata/`, "could not receive  user data and favorites data", fetchParams).promise
        let th = userDataResponse['twitter_handle']
        if(!cookies.do_not_show_twitter_add_again){
          if(!th || !th.length){
            setAddTwitterModal(true)
          }else{
            setHasAddedTwitter(true)
          }
        }else{
          setAddTwitterModal(false)
        }
        if(th && th.length){
          setAddTwitterModal(false)
        }
      } catch (error) {
        console.log("error","something is broke getting  user data and favorites")
      }
    })()
  }, [])

  useEffect(() => {
    //console.log("TOP OF USEEFFECT IN CURRENCIES")
    //console.log("currencyPage is: " + currencyPage)
    //console.log("sortByUrl is: " + sortByUrl)
    //console.log("filterByUrl is: " + filterByUrl)    


    ;(async () => {
      try {
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            const userDataResponse = await fetchData(`api/v1/users/userdata/`, "could not receive  user data and favorites data", fetchParams).promise
            //console.log("response getting user data and favorites in app")
            //console.dir(response)
            //setUserDataObject(userDataResponse)
          } catch (error) {
            console.log("error","something is broke getting  user data and favorites")
          }
        })()

        if (searchTerm.length === 0) {
          setBackdropOpen(true)
          const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
          }
          var getCoinsURL = `api/v1/coin/ecoinscreener/?page=${currencyPage}${sortByUrl}${filterByUrl}&page_size=100`
          const response = await fetchData(getCoinsURL, "could not receive ecoinscreener data", fetchParams).promise
          //console.log(getCoinsURL)
          //console.log("response in Currencies is:")
          //console.dir(response)
          setResponseCount(response.count)
          setCurrencyList(response.results)

          if (response.count > 100) {
            setPageCount(Math.ceil(response.count/100))
          } else {
            setPageCount(1)
          }

          if (sortByUrl === '') {
            //reorderlist by rank 1 first by default
            sortResults('rank-highest', response.results)
          }

          //extract all categories from currency list
          var category_list = []
          response.results.map( coin => {
            if (Object.keys(coin).length > 0) {
              coin.category.map( category => {
                if (!category_list.includes(category)) {
                  category_list.push(category)
                }
              })
            }
          })
          //console.log("category_list is: ")
          //console.dir(category_list)
          setCategoryList(category_list)
          setIsLoading(false)
          setBackdropOpen(false)
        } else {

          setBackdropOpen(true)
          const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
          }

          let searchString = "&search="+searchTerm

          //console.log("searchString inside Currencies useEffect: " + searchString)
          var getCoinsSearchURL = `api/v1/coin/ecoinscreener/?page=${currencyPage}${sortByUrl}${filterByUrl}${searchString}&page_size=100`
          //console.log(getCoinsSearchURL)
          const response = await fetchData(getCoinsSearchURL, "could not receive coinmarket data", fetchParams).promise
          //console.log("response getting coinmarket data from search change")
          //console.dir(response)

          setResponseCount(response.count)
          setCurrencyList(response.results)
          //console.dir("response.results is:")
          //console.dir(response.results)

          if (response.count > 100) {
            setPageCount(Math.ceil(response.count/100))
          } else {
            setPageCount(1)
          }

          if (sortByUrl === '') {
            //reorderlist by rank 1 first by default
            sortResults('rank-highest', response.results)
          }

          //extract all categories from currency list
          category_list = []
          response.results.map( coin => {
            console.log("coin is:")
            console.dir(coin)
            if (Object.keys(coin).length > 0) {
              coin.category.map( category => {
                if (!category_list.includes(category)) {
                  category_list.push(category)
                }
              })
            }
          })
          console.log("category_list is: ")
          console.dir(category_list)
          setCategoryList(category_list)
          setIsLoading(false)
          setBackdropOpen(false)        

        }



      } catch (error) {
        console.log("something is broke getting ecoinscreener: " + error)
      }
    })()

  }, [currencyPage, filterByUrl, sortByUrl, searchTerm])

  const filterCurrencyList = (category) => { //this is a category object with name and id as properties
    console.log("top of filterCurrencyList and category is: ")
    console.dir(category)
    if (category !== '') {
      setFilterByUrl("&category.keyword=" + category)
      setCurrencyPage(1)
      setCategoryChosen(category)
    } else {
      setCurrencyPage(1)
      setCategoryChosen('')
      setFilterByUrl('')
    }
  }

  const clearCategoryFilter = () => {
    //remove a current category filter if there is one currently
    setCurrencyPage(1)
    setFilterByUrl('')
    setCategoryChosen('')
  }

  const changeChartTimeFrame = (clickedTime) => {
    //console.log("top of changeChartTimeFrame and clickedTime is: " + clickedTime + " / " +currentTimeFrame)
    if (currentTimeFrame !== clickedTime) {
      //console.log("changing currentTimeFrame to: " + clickedTime)
      setCurrentTimeFrame(clickedTime)
    } else {
      //console.log("NOT changing currentTimeFrame to: " + clickedTime)
      return null
    }
  }

  const searchCoins = (searchBy) => {
    //console.log("top of seachCoins and searchBy is: " + searchBy)
    setSearchTerm(searchBy)
    setCurrencyPage(1)
  }

  const clearSearch = () => {
    setSearchTerm('')
    setCurrencyPage(1)
  }

  const setColumns = (obj) => {
    setVisibleColumns(obj)
  }

  const handleTwitterImportTextChange = (event) => {
    setTwitterImportText(event.target.value);
  };

  function postTwitterImport(handle){
    const fetchParams = {
          method: 'POST',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'twitter_handle': handle
          })
        }
    ;(async () => {
      //get the list of twitter users
      try {
        var response_query = `api/v1/users/posttwitterimport/`
        const response = await fetchData( response_query, "could not receive twitter users data", fetchParams).promise
        if(response){
          setHasAddedTwitter(true)
          setCookie('do_not_show_twitter_add_again', true, { path: '/', maxAge: 31536000 });
        }
      } catch (error) {
        console.log("could not receive twitter users data: %s", error)
      }
    })()
  }

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
          theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  function BpCheckbox(props) {
    return (
        <Checkbox
            sx={{
              '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
  }

  //console.log("top of Currencies")
  if (isLoading) return <CircularProgress />
  //console.log("categoryList is: ")
  //console.dir(categoryList)
  return (
    <Box sx={{width: '100%',  paddingTop: '10px', paddingBottom: '20px'}}>
        {/* put list filters here */}
        <CurrencyFilterRow
          filterCurrencyList={filterCurrencyList}
          categoryList={categoryList}
          clearCategoryFilter={clearCategoryFilter}
          categoryChosen={categoryChosen}
          currentTimeFrame={currentTimeFrame}
          changeChartTimeFrame={changeChartTimeFrame}
          clearSearch={clearSearch}
          searchCoins={searchCoins}
          setColumns={setColumns}
          visibleColumns={visibleColumns}
        />
        <CurrenciesListHeader
          sortResults={sortResults}
          sortByColumn={sortByColumn}
          sortingBy={sortingBy}
          sortByColumnDirection={sortByColumnDirection}
          currentTimeFrame={currentTimeFrame}
          visibleColumns={visibleColumns}
        />
        <Modal
            open={addTwitterModal}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            disableEscapeKeyDown={false}
        >
          <Fade in={addTwitterModal}>
            <Box sx={{ position: 'absolute', width: medScreenOrSmaller ? 350:500, backgroundColor: theme.palette.dark[900], border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '25%', left: medScreenOrSmaller ? '20px':'calc(50vw - 300px)'}}>
              <Stack direction={'row'} justifyContent={'flex-end'}>
                <IconButton onClick={() => setAddTwitterModal(!addTwitterModal)}>
                  <CloseIcon color={'error'} />
                </IconButton>
              </Stack>
              {
                hasAddedTwitter ?
                    <React.Fragment>
                      <Typography sx={{color: '#fff', textAlign: 'center'}}>Thanks for submitting your twitter username!</Typography>
                      <Typography sx={{color: '#fff', textAlign: 'center', mt: 1, mb: 3}}>
                        It may take 1-6 hours to update our servers with your twitter followed accounts.
                        After that time your content will appear in your customized <a href={'/tweetdisplay'} style={{color: theme.palette.primary.main, textDecoration: 'none'}}>Tweets</a> dashboard for easy viewing of tweets.
                      </Typography>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Typography sx={{color: '#fff', textAlign: 'center'}}>Add your Twitter followed</Typography>
                      <Stack direction={'row'} sx={{width: '100%', mt: 3}}>
                        <TextField
                            InputProps={{
                              startAdornment: <InputAdornment position="start">@</InputAdornment>,
                            }}
                            sx={{width: '100%'}}
                            name='twitterImportText'
                            variant='outlined'
                            value={twitterImportText}
                            id='twitterImportText'
                            label='Your Twitter username'
                            onChange={handleTwitterImportTextChange}
                            inputProps={{ maxLength: 50 }}
                            size="small"
                        />
                        <IconButton onClick={() => {
                          postTwitterImport(twitterImportText)
                        }}>
                          <SearchIcon color={'primary'}/>
                        </IconButton>
                      </Stack>
                      <Stack direction={'row'}  justifyContent={'space-between'} alignItems={'center'} sx={{mt: 2}}>
                        <Button
                            variant={'outlined'}
                            sx={{ alignItems: 'center', mr: '5px'}}
                            onClick={() => setAddTwitterModal(!addTwitterModal)}
                        >
                          {medScreenOrSmaller ? 'Skip' : 'Skip for Now' }
                        </Button>
                        <Stack direction="row" justifyContent="center" sx={{alignItems: 'center'}}>
                          <Typography sx={{color: '#fff', mr: '2px'}}>Don't show this again</Typography>
                          <BpCheckbox
                              checked={doNotShowAgainChecked}
                              onChange={handleDoNotShowAgainCheckedChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </Stack>
                      </Stack>
                    </React.Fragment>
              }
            </Box>
          </Fade>
        </Modal>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={backdropOpen}
        >
          <CircularProgress sx={{color: "#fff"}} size={100} />
        </Backdrop>
        <CurrenciesList
          currencyList={currencyList}
          currentTimeFrame={currentTimeFrame}
          visibleColumns={visibleColumns}
        />
        <Stack direction="row" display="flex" alignItems="center">
          <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
          <Pagination sx={{marginTop: '10px'}} shape="rounded" count={pageCount} page={currencyPage} onChange={changePage}/>
        </Stack>
        
    </Box>
  )
}

export default Currencies