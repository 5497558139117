import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, Stack, Box } from '@mui/material';
import { AddBox, Exposure, IndeterminateCheckBox, Telegram, Star } from '@mui/icons-material'

const VIPTelegramPost = ( {telegramItem} ) => {

  //console.log("top of CoinDetailsTelegramItem")

  const theme = useTheme();

  //format time
  var trimmedTime = telegramItem.created.split("+")[0]
  var splitTime = trimmedTime.split("T")

  var viewsText = "not avail"
  if (telegramItem.views !== null)
    viewsText = telegramItem.views

  //display polarity (positive/negative)
  const polarityDisplay = (passedDirection, passedCount) => {
    if (passedCount === 0) return null
    if (passedDirection === 1) {
      return [ <AddBox sx={{color: theme.palette.primary.main, fontSize: "1.0rem"}}/>,  polarityDisplay(passedDirection, (passedCount-1) ) ]
    } else {
      return [ <IndeterminateCheckBox sx={{color: theme.palette.secondary.main, fontSize: "1.0rem"}}/>,  polarityDisplay(passedDirection, (passedCount-1) ) ]
    }
  }
  var direction = 0
  var count = 0
  switch(true) {
    case parseFloat(telegramItem.polarity) >= .7:
        direction = 1
        count = 3
      break
    case parseFloat(telegramItem.polarity) >= .4:
      direction = 1
      count = 2
      break
    case parseFloat(telegramItem.polarity) >= .1:
      direction = 1
      count = 1
      break
    case parseFloat(telegramItem.polarity) <= -.1:
      direction = -1
      count = 1
      break
    case parseFloat(telegramItem.polarity) <= -.4:
      direction = -1
      count = 2
      break
    case parseFloat(telegramItem.polarity) <= -.7:
      direction = -1
      count = 3
      break
    default:
      //leave default values
    break
  }

  //display efficacy (how strong match to purpose)
  const efficacyDisplay = (passedCount) => {
    if (passedCount === 0) return null
    return [ <Star sx={{color: theme.palette.primary.main, fontSize: "1.0rem"}}/>,  efficacyDisplay((passedCount-1)) ]

  }
  var efficacyCount = 0
  switch(true) {
    case parseFloat(telegramItem.subjectivity) >= .7:
        efficacyCount = 3
      break
    case parseFloat(telegramItem.subjectivity) >= .4:
      efficacyCount = 2
      break
    case parseFloat(telegramItem.subjectivity) >= .1:
      efficacyCount = 1
      break
    default:
      //leave default values
    break
  }
  /*<ThumbsUpDown sx={{color: theme.palette.grey[500], fontSize: "1.0rem"}}/>*/
  return (
    <Grid container
      sx={{
        paddingTop: '25px',
      }}
      key={telegramItem.id}
    >
      <Grid item xs={1.5} sx={{paddingTop: '5px', paddingLeft: '5px', backgroundColor: theme.palette.grey[300], borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px',}}>
        <Stack direction="column" sx={{marginBottom: '10px'}}>
          <Telegram sx={{marginLeft: "5px", fontSize: "2.5rem", color: theme.palette.primary.main}} />
          <Stack direction="column" sx={{marginTop: "8px"}}>
            <Typography sx={{color: theme.palette.grey[500], fontSize: '.8rem'}}>polarity</Typography>
            <Stack direction="row" >{direction === 0 ? <Exposure sx={{color: theme.palette.grey[700], fontSize: "1.0rem"}}/> : polarityDisplay(direction, count)}</Stack>
            <Typography sx={{color: theme.palette.grey[500], fontSize: '.8rem', marginTop: "8px"}}>relevance</Typography>
            <Stack direction="row" >{direction === 0 ? <Typography sx={{color: theme.palette.grey[700], fontSize: '.8rem'}}>none</Typography>  : efficacyDisplay(efficacyCount)}</Stack>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={10.5} sx={{backgroundColor: theme.palette.primary.main , borderTopRightRadius: '5px', borderBottomRightRadius: '8px', padding: "8px", }}>
        <Box sx={{backgroundColor: theme.palette.primary.main , borderRadius: '5px', padding: "5px", margin: '0px'}}>
          <Grid container >
            <Grid item xs={12}>
              <Stack direction="row" sx={{justifyContent: 'space-between',}}>
                <Stack direction="row">
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>Account: </Typography>
                  <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[100], paddingLeft: '5px'}}>{telegramItem.name}</Typography>
                </Stack>
                <Stack direction="row">
                  <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>views:</Typography>
                  <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[100], paddingLeft: '5px'}}>{viewsText}</Typography>
                </Stack>
              </Stack>
            </Grid>
            {/*
            <Grid item xs={2} sx={{alignItems: "right"}}>
              <Typography>
                &nbsp;
              </Typography>
            </Grid>
            */}
            <Grid item xs={12} sx={{textAlign: 'right'}}>
              <Stack direction="row">
                <Typography variant="caption" sx={{fontWeight: 400, color: theme.palette.grey[400]}}>created: </Typography>
                <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[100], paddingLeft: '5px'}}> {splitTime[0]} {splitTime[1].slice(0,-1)}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{color: theme.palette.grey[100]}}>{telegramItem.text}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );


}

export default VIPTelegramPost