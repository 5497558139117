import React from 'react';
import filecoin from './filecoin.png';
import graph from './graph.jpg'


const Story2 = () => {
    return (
                
        <div 
            className="Story2" 
            style={{
                backgroundColor: 'lightgray',
                padding: '10px',
                display: 'grid',
                gridTemplateColumns: 'auto',
                gridTemplateRows: 'auto',              
            }}
        >

            <div 
                className="Story2text"
                style={{
                    textAlign: 'center', 
                    textShadow: 'grey 1px 0 10px',
                    fontSize: 'x-large',
                    color: '#5d4db2',                                               
                }}
            >

                <h1>Get Key Trading Insights Before Major Prices Skyrocket.</h1>

                <p>“Our AI system curates the Internet to find major price inducing events such as mainnet upgrades,
                    staking periods, and airdrops. </p> 
                <p> You’re notified and alerted before the masses realize it’s important.”</p>

                <div 
                    className='Image'
                    style={{
                        gridTemplateColumns: 'flex-start',
                        marginRight: '400px',              
                    }}
                    >

                    <h3>An Alert comes in</h3>

                    <img src = {filecoin} alt="coin" />
                </div>

                <div 
                    className="Moretext"
                    style={{
                        textAlign: 'center',
                        marginLeft: '250px',                           
                    }}
                    >

                    <h3>A few days later, major profit occurs</h3>

                    <img src={graph} alt="graph" />


                    <div 
                        className="Profit"
                        style={{color: '#20d651'}}    
                    >
                        <h2>89% Profit - 9 days</h2>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default Story2;