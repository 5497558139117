import React, { useEffect, useState } from 'react'
import { Typography, Stack, Box, IconButton, Popover, Paper, useMediaQuery, FormControl,InputLabel, Select, MenuItem } from '@mui/material'

import { HiOutlineSave  } from 'react-icons/hi'
import { IconSquareX } from '@tabler/icons'

import { useTheme } from '@mui/material/styles'

import MainCard from '../ui-component/cards/MainCard'
import Transitions from '../ui-component/extended/Transitions'

import fetchData from '../../utility/endpointFetch'

const ResearchDisplaySavedSearchSelection = ( {displaySavedSearch} ) => {
    //console.log("top of ResearchDisplaySavedSearchSelection")

    const theme = useTheme()
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'))
    
    const [isLoading,setIsLoading] = useState(true)
    const [saveSearchOpen,setSaveSearchOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [savedSearchList,setSavedSearchList] = useState([])
    const [selectedSavedSearch,setSelectedSavedSearch] = useState('')

    useEffect(() => {
        setIsLoading(true)
        //getBoxPosition()
       
        const getSavedSearchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
            'Content-Type': 'application/json'
            },
        }          
        ;(async () => {
            try {
                let getSavedSearchURL = `api/v1/users/userdata/`
                const getSavedSearchResponse = await fetchData(getSavedSearchURL, "could not receive current saved searches", getSavedSearchParams).promise
                console.log("getSavedSearchResponse getting current saved searches")
                console.dir(getSavedSearchResponse)

                setSavedSearchList(getSavedSearchResponse.saved_research_queries)
            } catch (error) {
                console.log("something is broke receiving current saved searches: ", error)
            }
            setIsLoading(false)
        })()           
    }, [])    

    const handleClose = () => {
        //console.log("top of handleClose")
        setAnchorEl(null)
        setSaveSearchOpen((prevOpen) => !prevOpen)
    }  

    const handleSavedSearchChange = (e) => {
        //e.target.value is the index of the chosen saved search
        console.log("top of handleSavedSearchChange: " + e.target.value)
        console.dir(savedSearchList[e.target.value])
        let savedSearchPieces = savedSearchList[e.target.value].split('||')
        console.log("savedSearchPieces is")
        console.dir(savedSearchPieces)
        setSelectedSavedSearch(e.target.value)

        displaySavedSearch(savedSearchPieces)   
        handleClose()

        //searchname || marketcap__gte=35331718&marketcap__lte=87089450&followers__gte=233924&followers__lte=797156&btc_24h_dif__gte=-4&btc_24h_dif__lte=6&eth_24h_dif__gte=-4&eth_24h_dif__lte=9&search=burning&categories__name__in=[Ethereum Ecosystem]
       
    }    

    const handleClick = (event) => {
        console.log("top of handleClick and event is:")
        console.dir(event)
        setAnchorEl(event.currentTarget)
        setSaveSearchOpen((prevSavedSearchOpen) => !prevSavedSearchOpen)
    }

    const id = saveSearchOpen ? 'saved-search-popover' : undefined   

    return (
        <Box sx={{ display: 'flex' }}>
            <HiOutlineSave  
                aria-describedby={id}
                onClick={handleClick}
                color={theme.palette.primary.main}
                style={{
                    '&:hover': {
                        //background: theme.palette.secondary.light, //theme.palette.grey[600],  //grey[600]  theme.palette.secondary.dark
                        color: theme.palette.primary.dark, //theme.palette.grey[300] //grey[300]  theme.palette.secondary.light
                    },
                    cursor: 'pointer'
                }}
                size={18}
            />          
            <Popover
                id={id}
                open={saveSearchOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 400, left: 1200 }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >

                <Transitions position={matchesXs ? 'top' : 'top-right'} in={saveSearchOpen} >
                <Paper>
                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{width: '500px', minHeight: '175px', padding: '15px', backgroundColor: theme.palette.dark[800]}}>
                        <Box sx={{width: '100%'}}>
                            <Stack direction="column" display="flex" alignItems="flex-start" sx={{width: '100%'}}>
                                <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
                                    <Typography variant='h2' sx={{color: theme.palette.primary.main}}>Choose a Saved Search To Search</Typography>
                                    <IconButton
                                        aria-label="close"
                                        sx={{color: theme.palette.error.main}}
                                        onClick={handleClose}>
                                        <IconSquareX sx={{ marginTop: '15px'}}/>
                                    </IconButton>  
                                </Stack>

                                <Box sx={{ minWidth: 120, width: '100%' }}>
                                    <FormControl fullWidth>
                                        <InputLabel 
                                            id="saved-search-select-label"
                                            sx={{
                                                color: theme.palette.grey[500] ,
                                                position: 'relative',
                                                top: '14px'
                                            }}
                                        >Saved Searches</InputLabel>
                                        <Select
                                            labelId="saved-search-select-label"
                                            id="saved-search-select"
                                            value={selectedSavedSearch}
                                            label="Saved Searches"
                                            onChange={handleSavedSearchChange}
                                            size="small"
                                            sx={{ backgroundColor: theme.palette.dark[900],
                                                '.MuiSelect-icon': {
                                                    color: 'white'
                                                },
                                                ".MuiSelect-outlined":{
                                                    color: 'white'
                                                }}}
                                            inputProps= {{
                                                sx: {
                                                    backgroundColor: theme.palette.dark[900],
                                                    "&:focus": {
                                                        borderRadius: 4,
                                                        background: "rgba(0,0,0,0)"
                                                    },
                                                    color: theme.palette.primary.dark,  
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: { 
                                                    color: theme.palette.grey[500] ,
                                                    position: 'relative',
                                                    top: '17px'
                                                },
                                            }}                            
                                        >
                                            <MenuItem value='0'>none</MenuItem>
                                            {savedSearchList.map( (savedSearch, savedSearchIndex) => {
                                                    let savedSearchElements = savedSearch.split('||')
                                                    return (
                                                        <MenuItem key={savedSearchIndex} value={savedSearchIndex}>{savedSearchElements[0]}</MenuItem>
                                                    )
                                                }
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>  
                                
                            </Stack>
                        </Box>        

                    </MainCard>
                </Paper>
                </Transitions>    
            </Popover>
        </Box>
    )
}
export default ResearchDisplaySavedSearchSelection