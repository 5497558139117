import React, { useEffect, useState } from 'react'
import { Box, Typography, Stack, Divider, CircularProgress, Tab } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'

import { useTheme } from '@mui/material/styles'

import { VscMove } from 'react-icons/vsc'
import { MdCancel } from 'react-icons/md'

import FeedHomeFeed from './FeedHomeFeed'
import FeedStats from './FeedStats'
import FeedFiltered from './FeedFiltered'
import fetchData from '../../utility/endpointFetch'

const FeedDisplay = ( { panelID, deleteBox, userObject, boxHeight, rowHeight, boxWidth, selectedTab='1', changeTabInLayout } ) => {

    console.log("top of FeedDisplay and userObject is:")
    console.dir(userObject)
    const theme = useTheme()

    const itemsPerResultPage = 50

    const [homeFeedList,setHomeFeedList] = useState([])
    const [isLoading,setIsLoading] = useState(true)
    const [homeFeedCount,setHomeFeedCount] = useState(0)
    const [homeFeedPage,setHomeFeedPage] = useState(1)
    const [homeFeedPageCount,setHomeFeedPageCount] = useState(0)        

    const [coinFilter,setCoinFilter] = useState('')
    const [hashFilter,setHashFilter] = useState('')
    const [mentionedFilter,setMentionedFilter] = useState('')
    const [followedFilter,setFollowedFilter] = useState('')

    const [feedDisplayTabValue, setFeedDisplayTabValue] = useState(selectedTab)

    const refreshHomeFeed = (followedList) => {
        console.log("top of refreshHomeFeed")
        //if there was a change....userdata was updated before returning here
        //followed list has changed...refresh home feed
        setIsLoading(true)
        getResults(followedList)
    }

    const getResults = (followedList=[]) => {

        if (userObject.twitter_feed.length > 0 || followedList.length > 0) {
            let twitterDisplayNameValueToUse = []
            if (followedList.length > 0) {
                twitterDisplayNameValueToUse = followedList
            } else {
                twitterDisplayNameValueToUse = userObject.twitter_feed
            }
            const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                'Content-Type': 'application/json'
                },
            }
            ;(async () => {
                try {
                var offset= (homeFeedPage - 1) * itemsPerResultPage //limit per page
                var homeFeedURL = `api/v1/feed/alltweets/?twitter_display_name=${twitterDisplayNameValueToUse.toString()}&limit=${itemsPerResultPage}&offset=${offset}`
                var homefeedResponse = await fetchData(homeFeedURL, "could not receive homefeed data", fetchParams).promise
                console.log("homefeedResponse getting homefeed data")
                console.dir(homefeedResponse)
                console.log("homeFeedURL is:")
                console.dir(homeFeedURL)

                setHomeFeedCount(homefeedResponse.count)

                if (homefeedResponse.count > itemsPerResultPage) {
                    setHomeFeedPageCount(Math.ceil(homefeedResponse.count/itemsPerResultPage))
                } else {
                    setHomeFeedPageCount(1)
                }

                setHomeFeedList(homefeedResponse.results)
                
                } catch (error) {
                console.log("something is broke getting homefeed data: ", error)
                }

                setIsLoading(false)
            })()
        } else {
            setHomeFeedList([]) 
            setHomeFeedCount(0)
            setHomeFeedPageCount(0)
            setIsLoading(false)
        }
    }    

    useEffect(() => {
        //console.log("top of useEffect to get homefeed")
        setIsLoading(true)
        getResults()
    
    }, [homeFeedPage])
    
    const filterByCoinInFeed = (coin) => {
        //console.log("top of filterByCoinInFeed")
        //console.log("coin is: " + coin)
        setHashFilter('')
        setMentionedFilter('')
        setFollowedFilter('')
        setCoinFilter(coin)
        setFeedDisplayTabValue('3')
    }
  
    const filterByHashInFeed = ( hash ) => {
        //console.log("top of filterByHashInFeed")
        //console.log("hash is: " + hash)
        setMentionedFilter('')
        setFollowedFilter('')
        setCoinFilter('')
        setHashFilter(hash)
        setFeedDisplayTabValue('3')
    }
  
    const filterByMentionInFeed = ( mentioned ) => {
        //console.log("top of filterByMentionInFeed")
        //console.log("mentioned is: " + mentioned)  
        setFollowedFilter('')
        setCoinFilter('')
        setHashFilter('')      
        setMentionedFilter(mentioned)  
        setFeedDisplayTabValue('3')
    }
  
    const filterByFollowedInFeed = ( followed ) => {
        //console.log("top of filterByFollowedInFeed")
        //console.log("followed is: " + followed)  
        setCoinFilter('')
        setHashFilter('')      
        setMentionedFilter('')   
        setFollowedFilter(followed)      
        setFeedDisplayTabValue('3')
    }
  
    const handleTabChange = (event, newValue) => {
        setFeedDisplayTabValue(newValue)
        changeTabInLayout(newValue,panelID)
    }   

    const changeHomeFeedPage = (event, page) => {
        setIsLoading(true)
        setHomeFeedPage(page)
    }       


    const removeFeedBox = () => {
        console.log("top of removeFeedBox and panelID is: " + panelID)
        deleteBox(panelID)
    }

    const maxBoxHeight = rowHeight * boxHeight

    return (
        <Box sx={{ display: 'flex', width: 'calc(100% - 10px)', marginLeft: '5px' }}>
            <Stack direction="column" display="flex" alignItems='left' sx={{width: '100%'}}>
                <Stack direction="row" display="flex" alignItems='center' justifyContent='space-between' sx={{marginTop: '5px', marginRight: '5px'}}>
                    <Stack direction="row" display="flex" alignItems='center' justifyContent='flex-start' >
                        <Typography sx={{fontWeight: 400}} color={theme.palette.grey[300]}>{"General Twitter Feed"}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent='flex-end'>  
                        <VscMove 
                            className='draggable-handle' 
                            color={theme.palette.warning.dark} 
                            size={18} 
                            style={{cursor: 'grabbing'}}
                        />
                        <MdCancel 
                            className='draggable-cancel' 
                            color={theme.palette.secondary.dark} 
                            size={18} 
                            onClick={removeFeedBox}
                            style={{cursor: 'pointer'}}
                        />

                    </Stack>
                </Stack>   
                <Divider sx={{backgroundColor: theme.palette.primary.main, width: '100%',marginTop: '5px', }}/>     
                    <TabContext value={feedDisplayTabValue} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList 
                                onChange={handleTabChange} 
                                aria-label="" 
                                indicatorColor="secondary" 
                                textColor="primary" 
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="home feed" value="1" sx={{minWidth: '15px'}} />
                                <Tab label="stats" value="2" sx={{minWidth: '15px'}} />
                                <Tab label="filtered" value="3" sx={{minWidth: '15px'}} />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{maxHeight: (maxBoxHeight - 75),paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflowY: 'auto', scrollbarWidth: 'thin'}}>
                                {isLoading ? <CircularProgress /> : 
                                    <FeedHomeFeed 
                                        userObject={userObject}
                                        refreshHomeFeed={refreshHomeFeed}
                                        homeFeedList={homeFeedList}
                                        homeFeedCount={homeFeedCount}
                                        homeFeedPageCount={homeFeedPageCount}
                                        homeFeedPage={homeFeedPage}
                                        changeHomeFeedPage={changeHomeFeedPage}
                                        filterByCoinInFeed={filterByCoinInFeed} 
                                        filterByHashInFeed={filterByHashInFeed} 
                                        filterByFollowedInFeed={filterByFollowedInFeed} 
                                        filterByMentionInFeed={filterByMentionInFeed}
                                        divHeight={rowHeight * boxHeight}
                                        boxWidth={boxWidth} 
                                    />
                                }
                            </Box>
                        </TabPanel>  
                        <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{maxHeight: (maxBoxHeight - 75),paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflowY: 'auto', scrollbarWidth: 'thin'}}>
                                {isLoading ? <CircularProgress /> : 
                                    <FeedStats 
                                        homeFeedListings={homeFeedList}
                                        filterByCoinInFeed={filterByCoinInFeed}
                                        filterByHashInFeed={filterByHashInFeed}
                                        filterByFollowedInFeed={filterByFollowedInFeed}
                                        filterByMentionInFeed={filterByMentionInFeed}
                                    />
                                }
                            </Box>
                        </TabPanel>    
                        <TabPanel value="3" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                            <Box sx={{maxHeight: (maxBoxHeight - 75),paddingLeft: '0px', marginLeft: '0px', display: 'flex', alignItems: 'flex-start', overflowY: 'auto', scrollbarWidth: 'thin'}}>
                                {isLoading ? <CircularProgress /> : 
                                    <FeedFiltered 
                                        homeFeedListings={homeFeedList}
                                        coinFilter={coinFilter}
                                        hashFilter={hashFilter}
                                        mentionedFilter={mentionedFilter}
                                        followedFilter={followedFilter}
                                        filterByCoinInFeed={filterByCoinInFeed}
                                        filterByHashInFeed={filterByHashInFeed}
                                        filterByFollowedInFeed={filterByFollowedInFeed}
                                        filterByMentionInFeed={filterByMentionInFeed}
                                        divHeight={rowHeight * boxHeight}
                                        boxWidth={boxWidth}                                         
                                    />
                                }
                            </Box>
                        </TabPanel>      
                    </TabContext>                
            </Stack>
        </Box>           
    )
}

export default FeedDisplay