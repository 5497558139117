import React, { useState } from 'react'

import { Box, Tab } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'

import VIPMainTabsComments from './VIPMainTabsComments'

const VIPMainTabsCommentsTabs = ( {setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart} ) => {
    //console.log("top of VIPMainTabsCommentsTabs")
    
    const [vipMainTabValue,setVIPMainTabValue] = useState('1')

    const handleMainTabChange = (event, newValue) => {
        setVIPMainTabValue(newValue);
    }    

    return (
        <Box sx={{width: '100%', height: '950px',}}>
            <TabContext value={vipMainTabValue} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleMainTabChange} aria-label="" indicatorColor="secondary" textColor="primary" >
                        <Tab label="trades" value="1" sx={{minWidth: '30px'}} />
                        <Tab label="comments" value="2" sx={{minWidth: '30px'}} />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '920px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <VIPMainTabsComments 
                            commentType="trade"
                            setCoinInChart={setCoinInChart}
                            changeAtFollowedTab={changeAtFollowedTab}
                            changeAtMentionedTab={changeAtMentionedTab}
                            changeHashTab={changeHashTab}
                            changeCoinInTab={changeCoinInTab}
                            changeDateInChart={changeDateInChart}                            
                        />
                    </Box>
                </TabPanel>    
                <TabPanel value="2" sx={{'&.MuiTabPanel-root': {padding: '2px'} }}>
                    <Box sx={{paddingLeft: '0px', marginLeft: '0px', height: '920px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
                        <VIPMainTabsComments 
                            commentType="post"
                            setCoinInChart={setCoinInChart}
                            changeAtFollowedTab={changeAtFollowedTab}
                            changeAtMentionedTab={changeAtMentionedTab}
                            changeHashTab={changeHashTab}
                            changeCoinInTab={changeCoinInTab}
                            changeDateInChart={changeDateInChart}                            
                        />
                    </Box>
                </TabPanel>                             
            </TabContext>
        </Box>

    )
}

export default VIPMainTabsCommentsTabs