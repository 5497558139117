import React from 'react'
import { Typography, Stack, ListItemText, List, ListItemIcon, ListItem, Grid} from '@mui/material'
import { FaArrowAltCircleDown } from 'react-icons/fa'
import { useTheme } from '@mui/material/styles'
import CheckIcon from '@mui/icons-material/Check'

const ResearchTutorial5MarketData = () => {

    const theme = useTheme()

    return (
        <Stack direction="column" display="flex" alignItems="flex-end" justifyContent="flex-end" sx={{padding: '15px', height: '100%'}}>
            <Typography variant="body1" sx={{padding: '10px'}}>This section displays Coin Market Data (associated with the Tweet of the Crypto Project). 
            Within these results you’ll see:</Typography>
            <Grid container spacing={0}>
                <Grid item xs={8}>
                    <List>
                        <ListItem >
                            <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'right'}}>Links to other relevant social accounts, project website, whitepapers, ...etc</Typography></ListItemText>
                            <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                        </ListItem>          
                        <ListItem >
                            <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'right'}}>Percentage performance for the coin over several time periods</Typography></ListItemText>
                            <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                        </ListItem>    
                        <ListItem >
                            <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'right'}}>List of categories of this coin belongs to</Typography></ListItemText>
                            <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                        </ListItem>        
                    </List>             
                </Grid>
                <Grid item xs={4}>
                    <List>
                        <ListItem >
                            <ListItemText ><Typography sx={{fontSize: '.75rem', textAlign: 'right'}}>Coin, name, price</Typography></ListItemText>
                            <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                        </ListItem>
                        <ListItem >
                            <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'right'}}>Latest market data</Typography></ListItemText>
                            <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                        </ListItem>                
                        <ListItem >
                            <ListItemText><Typography sx={{fontSize: '.75rem', textAlign: 'right'}}>Associated Twitter account and link to it</Typography></ListItemText>
                            <ListItemIcon><CheckIcon sx={{paddingLeft: '10px'}} size={15} color={theme.palette.grey[800]}/></ListItemIcon>
                        </ListItem>             
                                    
                                    
                    </List>                    
                </Grid>
            </Grid>
            <Stack direction="row" display="flex" alignItems="center" justifyContent='space-around' sx={{width: '100%'}}>
                <Typography sx={{marginRight: '20px', fontSize: '2em', color: theme.palette.warning.dark}}>Market Data <FaArrowAltCircleDown size={20} /></Typography>
            </Stack>
        </Stack> 
    )
}
export default ResearchTutorial5MarketData