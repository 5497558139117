import React, { useEffect, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Box, Pagination, 
    Container,
    CssBaseline,
    Typography,
    Stack,
    Divider,
    Modal,
    IconButton,
    Tooltip,
    TextField,
    Grid,
    Button,
    Chip,
    InputAdornment
} from '@mui/material'
import fetchData from '../../utility/endpointFetch'
import TopAppBar from "../TopAppBar";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import CustomFeedManager from "./CustomFeedManager";
import CustomFeedTwitterResultItem from "./CustomFeedTwitterResultItem";
import { CircularProgress } from "@material-ui/core";
import { o } from 'ramda';
import CustomFeedColumn from "./CustomFeedColumn";
import { useDispatch, useSelector } from "react-redux";
import { setColumnOneHandles, setColumnThreeHandles, setColumnTwoHandles } from "../../store/actions";

const TweetDisplay = ( { userObject } ) => {
    const columnOneHandles = useSelector((state) => state.tweetsScreen.columnOneHandles)
    const columnTwoHandles = useSelector((state) => state.tweetsScreen.columnTwoHandles)
    const columnThreeHandles = useSelector((state) => state.tweetsScreen.columnThreeHandles)
    const dispatch = useDispatch();
    const [userTextField,setUserTextField] = useState('')
    const [customFeed1SearchField,setCustomFeed1SearchField] = useState('')
    const [customFeed2SearchField,setCustomFeed2SearchField] = useState('')
    const [customFeed3SearchField,setCustomFeed3SearchField] = useState('')
    const [colOneData, setColOneData] = useState([])
    const [colOneLoading, setColOneLoading] = useState(false)
    const [colOneCount,setColOneCount] = useState(0)
    const [colOnePage,setColOnePage] = useState(1)
    const [colOnePageCount,setColOnePageCount] = useState(0)
    const [colTwoData, setColTwoData] = useState([])
    const [colTwoLoading, setColTwoLoading] = useState(false)
    const [colTwoCount,setColTwoCount] = useState(0)
    const [colTwoPage,setColTwoPage] = useState(1)
    const [colTwoPageCount,setColTwoPageCount] = useState(0)    
    const [colThreeData, setColThreeData] = useState([])
    const [colThreeLoading, setColThreeLoading] = useState(false)
    const [colThreeCount,setColThreeCount] = useState(0)
    const [colThreePage,setColThreePage] = useState(1)
    const [colThreePageCount,setColThreePageCount] = useState(0)    
    const [open, setOpen] = React.useState(false);
    const [infoOpen, setInfoOpen] = React.useState(false);
    const [editedColumn, setEditedColumn] = useState('One');
    const [searchTerms, setSearchTerms] = useState(["mainnet", "staking", "bitcoin", "words", "aren't", "hard", "searchtermsgohere"]);
    const [filteringByKeywordOne, setFilteringByKeywordOne] = useState(false)
    const [filteringByKeywordTwo, setFilteringByKeywordTwo] = useState(false)
    const [filteringByKeywordThree, setFilteringByKeywordThree] = useState(false)
    const handleOpen = (name) => {
        setEditedColumn(name)
        setOpen(true)
    }
    const handleClose = () => setOpen(false);
    const theme = useTheme();

    useEffect(() => {
        getUserData()
        getSearchTerms()
    },[])

    const getUserData = () => {
        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            let userUrl = `api/v1/users/userdata/`
            try {
                const userResponse = await fetchData(userUrl, "could not receive all twitter coin data", fetchParams).promise
                dispatch(setColumnOneHandles(userResponse['twitter_feed_one']))
                dispatch(setColumnTwoHandles(userResponse['twitter_feed_two']))
                dispatch(setColumnThreeHandles(userResponse['twitter_feed_three']))
                getColOne(userResponse['twitter_feed_one'], filteringByKeywordOne)
                getColTwo(userResponse['twitter_feed_two'], filteringByKeywordTwo)
                getColThree(userResponse['twitter_feed_three'], filteringByKeywordThree)
            } catch (error) {
                console.log("something is broke getting all twitter coin result in research3: " + error)
            }
        })()
    }

    const getSearchTerms = () => {
        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            let userUrl = `api/v1/feed/searchterms/`
            try {
                const userResponse = await fetchData(userUrl, "could not get search terms", fetchParams).promise
                setSearchTerms(userResponse.results)
                //setSearchTerms(["mainnet", "staking", "bitcoin", "words", "aren't", "hard", "searchtermsgohere"])
                // console.log(userResponse)
            } catch (error) {
                console.log("something is broke getting all twitter coin result in research3: " + error)
            }
        })()
    }

    const deleteUserFromWhitelist = (user) => {
        let updateHandleUrl = null
        let old;
        switch(editedColumn){
            case "One":
                old = columnOneHandles;
                updateHandleUrl = "api/v1/users/twitter_feed_update/?feed_index=1"
                dispatch(setColumnOneHandles(old.filter((value) => value !== user)))
                break;
            case "Two":
                old = columnTwoHandles;
                updateHandleUrl = "api/v1/users/twitter_feed_update/?feed_index=2"
                dispatch(setColumnTwoHandles(old.filter((value) => value !== user)))
                break;
            case "Three":
                old = columnThreeHandles;
                updateHandleUrl = "api/v1/users/twitter_feed_update/?feed_index=3"
                dispatch(setColumnThreeHandles(old.filter((value) => value !== user)))
                break;
            default:
                break;
        }
        let b = JSON.stringify({'handle_list': [user]})
        const fetchParams = {
                method: 'DELETE',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: b
            }
        ;(async () => {
            try {
                const updateHandlesResponse = await fetchData(updateHandleUrl, "could not receive all custom twitter feed update response data", fetchParams).promise
            } catch (error) {
                console.log("something is broke getting all twitter coin result in research3: " + error)
            }
        })()
    }

    function getColOne(handles = columnOneHandles, fbk = filteringByKeywordOne) {
        setColOneLoading(true)
        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            let twitterCoinUrl = `api/v1/feed/alltweets/?page=${colOnePage}&page_size=50`;
            if(handles.length){
                twitterCoinUrl += `&twitter_display_name=${handles.toString()}`;
            }
            if(fbk) {
                twitterCoinUrl += `&has_keyword=true`
            }
            try {
                const twitterCoinResponse = await fetchData(twitterCoinUrl, "could not get twitter account result in custom feed one", fetchParams).promise
                setColOneData(twitterCoinResponse['results'])
                setColOnePageCount(Math.ceil(twitterCoinResponse.count/50))     
                setColOneCount(twitterCoinResponse.count)      
                setColOneLoading(false)
            } catch (error) {
                console.log("something is broke getting twitter account result in custom feed one: " + error)
            }
        })()
    }

    function getColTwo(handles = columnTwoHandles, fbk = filteringByKeywordTwo) {
        setColTwoLoading(true)
        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            let twitterCoinUrl = `api/v1/feed/alltweets/?page=${colTwoPage}&page_size=50`;
            if(handles.length){
                twitterCoinUrl += `&twitter_display_name=${handles.toString()}`;
            }
            if(fbk) {
                twitterCoinUrl += `&has_keyword=true`
            }
            try {
                const twitterCoinResponse = await fetchData(twitterCoinUrl, "could not get twitter account result in custom feed two", fetchParams).promise
                setColTwoData(twitterCoinResponse['results'])
                setColTwoPageCount(Math.ceil(twitterCoinResponse.count/50))           
                setColTwoCount(twitterCoinResponse.count)             
                setColTwoLoading(false)
            } catch (error) {
                console.log("something is broke getting twitter account result in custom feed two: " + error)
            }
        })()
    }

    function getColThree(handles = columnThreeHandles, fbk = filteringByKeywordThree) {
        setColThreeLoading(true)
        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            let twitterCoinUrl = `api/v1/feed/alltweets/?page=${colThreePage}&page_size=50`;
            if(handles.length){
                twitterCoinUrl += `&twitter_display_name=${handles.toString()}`;
            }
            if(fbk) {
                twitterCoinUrl += `&has_keyword=true`
            }
            try {
                const twitterCoinResponse = await fetchData(twitterCoinUrl, "could not get twitter account result in custom feed three", fetchParams).promise
                setColThreeData(twitterCoinResponse['results'])
                setColThreePageCount(Math.ceil(twitterCoinResponse.count/50))  
                setColThreeCount(twitterCoinResponse.count)                       
                setColThreeLoading(false)
            } catch (error) {
                console.log("something is broke getting twitter account result in custom feed three: " + error)
            }
        })()
    }

    const handleSearchChange = (e) => {
        setUserTextField(e.target.value)
    }

    const handleAddUser = () => {
        let updateHandleUrl = null
        let handleList = []
        switch(editedColumn){
            case "One":
                updateHandleUrl = "api/v1/users/twitter_feed_update/?feed_index=1"
                handleList = [...columnOneHandles]
                break;
            case "Two":
                updateHandleUrl = "api/v1/users/twitter_feed_update/?feed_index=2"
                handleList = [...columnTwoHandles]
                break;
            case "Three":
                updateHandleUrl = "api/v1/users/twitter_feed_update/?feed_index=3"
                handleList = [...columnThreeHandles]
                break;
            default:
                break;
        }
        handleList.push(userTextField)
        let b = JSON.stringify({'handle_list': handleList})
        const fetchParams = {
                method: 'POST',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: b
            }
        ;(async () => {
            try {
                const updateHandlesResponse = await fetchData(updateHandleUrl, "could not receive all twitter coin data", fetchParams).promise
                ;(async () => {
                    try {
                        getUserData()
                        switch(editedColumn){
                            case 'One':
                                getColOne(filteringByKeywordOne);
                                break;
                            case 'Two':
                                getColTwo(filteringByKeywordTwo);
                                break;
                            case 'Three':
                                getColThree(filteringByKeywordThree);
                                break;
                            default:
                                break;
                        }
                        setUserTextField('')
                    } catch (error) {
                        console.log("something is broke getting user data and new tweets in tweetdisplay: " + error)
                    }
                })()                    
            } catch (error) {
                console.log("something is broke getting all twitter coin result in tweetdisplay: " + error)
            }
        })()
    }

    const handleAddUserByHandleAndCol = (handle, col) => {
        let updateHandleUrl = null
        let handleList = []
        switch(col){
            case "One":
                updateHandleUrl = "api/v1/users/twitter_feed_update/?feed_index=1"
                handleList = [...columnOneHandles]
                break;
            case "Two":
                updateHandleUrl = "api/v1/users/twitter_feed_update/?feed_index=2"
                handleList = [...columnTwoHandles]
                break;
            case "Three":
                updateHandleUrl = "api/v1/users/twitter_feed_update/?feed_index=3"
                handleList = [...columnThreeHandles]
                break;
            default:
                break;
        }
        handleList.push(handle)
        let b = JSON.stringify({'handle_list': handleList})
        const fetchParams = {
                method: 'POST',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: b
            }
        ;(async () => {
            try {
                const updateHandlesResponse = await fetchData(updateHandleUrl, "did not receive twitter feed followers", fetchParams).promise
                ;(async () => {
                    try {
                        getUserData()
                        setUserTextField('')
                    } catch (error) {
                        console.log("something is broke getting user data and new tweets in tweetdisplay: " + error)
                    }
                })()                    
            } catch (error) {
                console.log("something is broke getting all twitter coin result in tweetdisplay: " + error)
            }
        })()
    }
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',
        maxHeight: 600,
    };

    const searchCustomFeed1 = (search) => {
        setColOneLoading(true)
        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            let twitterCoinUrl = `api/v1/feed/alltweets/?page=1&page_size=50`;
            if(columnOneHandles.length){
                twitterCoinUrl += `&twitter_display_name=${columnOneHandles.toString()}`;
            }
            if(search && search.length){
                twitterCoinUrl += `&search=${search}`
            }
            if(filteringByKeywordOne){
                twitterCoinUrl += `&has_keyword=true`
            }
            try {
                const twitterCoinResponse = await fetchData(twitterCoinUrl, "could not receive all twitter coin data", fetchParams).promise
                setColOneData(twitterCoinResponse['results'])
                setColOneLoading(false)
            } catch (error) {
                console.log("something is broke getting all twitter coin result in research3: " + error)
            }
        })()
    }

    const searchCustomFeed2 = (search) => {
        setColTwoLoading(true)
        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            let twitterCoinUrl = `api/v1/feed/alltweets/?page=1&page_size=50`;
            if(columnTwoHandles.length){
                twitterCoinUrl += `&twitter_display_name=${columnTwoHandles.toString()}`;
            }
            if(search && search.length){
                twitterCoinUrl += `&search=${search}`
            }
            if(filteringByKeywordTwo){
                twitterCoinUrl += `&has_keyword=true`
            }

            try {
                const twitterCoinResponse = await fetchData(twitterCoinUrl, "could not receive all twitter coin data", fetchParams).promise
                setColTwoData(twitterCoinResponse['results'])
                setColTwoLoading(false)
            } catch (error) {
                console.log("something is broke getting all twitter coin result in research3: " + error)
            }
        })()
    }

    const searchCustomFeed3 = (search) => {
        const fetchParams = {
                method: 'GET',
                dataType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ;(async () => {
            let twitterCoinUrl = `api/v1/feed/alltweets/?page=1&page_size=50`;
            if(columnThreeHandles.length){
                twitterCoinUrl += `&twitter_display_name=${columnThreeHandles.toString()}`;
            }
            if(search && search.length){
                twitterCoinUrl += `&search=${search}`
            }
            if(filteringByKeywordThree){
                twitterCoinUrl += `&has_keyword=true`
            }
            try {
                const twitterCoinResponse = await fetchData(twitterCoinUrl, "could not receive all twitter coin data", fetchParams).promise
                setColThreeData(twitterCoinResponse['results'])
                setColThreeLoading(false)
            } catch (error) {
                console.log("something is broke getting all twitter coin result in research3: " + error)
            }
        })()
    }

    const filterByKeywords = (col) => {
        switch(col){
            case 'One':
                getColOne(!filteringByKeywordOne);
                setFilteringByKeywordOne(!filteringByKeywordOne)
                break;
            case 'Two':
                getColTwo(!filteringByKeywordTwo);
                setFilteringByKeywordTwo(!filteringByKeywordTwo)
                break;
            case 'Three':
                getColThree(!filteringByKeywordThree);
                setFilteringByKeywordThree(!filteringByKeywordThree)
                break;
            default:
                break;
        }
    }

    const refreshFeed = (column,useTheirUsers) => {
        switch(column){
            case "One":
                dispatch(setColumnOneHandles(useTheirUsers))
                getColOne(useTheirUsers, filteringByKeywordOne);
                break;
            case "Two":
                dispatch(setColumnTwoHandles(useTheirUsers))
                getColTwo(useTheirUsers, filteringByKeywordTwo);
                break;
            case "Three":
                dispatch(setColumnThreeHandles(useTheirUsers))
                getColThree(useTheirUsers, filteringByKeywordThree);
                break;
            default:
                break;
        }
    }

    const changeColOnePage = ( event, page) => {
        setColOnePage(page)
        setColOneLoading(true)
        getColOne(columnOneHandles, filteringByKeywordOne)
    }

    const changeColTwoPage = ( event, page) => {
        setColTwoPage(page)
        setColTwoLoading(true)
        getColTwo(columnTwoHandles, filteringByKeywordTwo)
    }

    const changeColThreePage = ( event, page) => {
        setColThreePage(page)
        setColThreeLoading(true)
        getColThree(columnThreeHandles, filteringByKeywordThree)
    }

    // sort function for search terms
    function compare( a, b ) {
        if(!a || !b || !a.text || !b.text){
            return 0;
        }
        if ( a.text.toLowerCase() < b.text.toLowerCase() ){
            return -1;
        }
        if ( a.text.toLowerCase() > b.text.toLowerCase() ){
            return 1;
        }
        return 0;
    }

    if (userObject.user.is_vip === true) {
        return (
            <Box sx={{ display: 'flex', backgroundColor: theme.palette.background.default }}>
                <CssBaseline />
                <TopAppBar userObject={userObject} selectedPage='tweetdisplay' />
                <Container maxWidth="false" sx={{marginTop: '80px', flex: 1}} >
                    {/* Header */}
                    <Stack sx={{marginTop: '10px'}} direction={'row'} justifyContent={'space-between'}>
                        <Typography sx={{color: '#1DA1F2', fontSize: 22}}>Your Personalized Tweets Feeds</Typography>
                        <IconButton color={'primary'} onClick={() => setInfoOpen(!infoOpen)}>
                            <InfoIcon color={'primary'} />
                        </IconButton>
                        <Modal
                            open={infoOpen}
                            onClose={() => setInfoOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{...style}} >
                                <Typography sx={{textAlign: 'center', color: '#fff', fontSize: 20}}>Your Personalized Tweets Feeds</Typography>
                                <Typography sx={{color: '#fff', textAlign: 'center', mt: 2}}>
                                    These are your personalized Tweet feeds.
                                </Typography>
                                <Typography sx={{color: '#fff', textAlign: 'center', mt: 1}}>
                                    Here you have access to 3 separate feeds, which can each be customized by adding/removing twitter users.
                                </Typography>
                                <Typography sx={{color: '#fff', textAlign: 'center', mt: 1}}>
                                    You'll be able to access Tweets from the users that you select.
                                </Typography>
                                <Typography sx={{color: '#fff', textAlign: 'center', mt: 1}}>
                                    Select the 'S' icon on each feed to find tweets that are strongly related to the below search terms.
                                </Typography>
                                <Divider />
                                <Grid
                                    spacing={1}
                                    container
                                    flexWrap={'wrap'}
                                    sx={{mt: 1}}
                                >
                                    {
                                        searchTerms.sort(compare).map((item, index) => {
                                            return <Grid key={item.toString()+String(index)} item xs>
                                                <Chip color={'primary'} label={item.text} />
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Box>
                        </Modal>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{...style}} >
                                <Stack direction={'column'}>
                                    <Typography sx={{textAlign: 'center', mb: 1}}>Edit your Twitter whitelist for feed {editedColumn}</Typography>
                                    <Divider/>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <TextField
                                            id="add-user-to-whitelist"
                                            label="Add a user to this feed's whitelist"
                                            variant="outlined"
                                            value={userTextField}
                                            onChange={handleSearchChange}
                                            size="small"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">@</InputAdornment>,
                                            }}
                                            sx={{
                                                width: '100%',
                                                minWidth: '80px',
                                                color: theme.palette.grey[500],
                                                input: {
                                                    background: theme.palette.dark[900],
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: theme.palette.grey[500] ,
                                                    position: 'relative',
                                                    top: '17px'
                                                },
                                            }}
                                            inputProps={{
                                                style: { height: '12px' },
                                            }}
                                        />
                                        <Tooltip title="Add User" >
                                            <IconButton onClick={() => handleAddUser()} sx={{mt: 1}}>
                                                <AddIcon color={'primary'} size={15} />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                    <Stack direction={'column'} sx={{mt: 2}}>
                                        {
                                            editedColumn === 'One' && columnOneHandles.map((item, index) => {
                                                return (
                                                    <Stack key={item.toString() + String(index)} direction={'row'} justifyContent={'space-between'} >
                                                        <Typography>@{item}</Typography>
                                                        <Button onClick={() => deleteUserFromWhitelist(item)}>
                                                            <DeleteIcon />
                                                        </Button>
                                                    </Stack>
                                                )
                                            })
                                        }
                                        {
                                            editedColumn === 'Two' && columnTwoHandles.map((item, index) => {
                                                return (
                                                    <Stack key={item.toString() + String(index)} direction={'row'} justifyContent={'space-between'} >
                                                        <Typography>@{item}</Typography>
                                                        <Button onClick={() => deleteUserFromWhitelist(item)}>
                                                            <DeleteIcon />
                                                        </Button>
                                                    </Stack>
                                                )
                                            })
                                        }
                                        {
                                            editedColumn === 'Three' && columnThreeHandles.map((item, index) => {
                                                return (
                                                    <Stack key={item.toString() + String(index)} direction={'row'} justifyContent={'space-between'} >
                                                        <Typography>@{item}</Typography>
                                                        <Button onClick={() => deleteUserFromWhitelist(item)}>
                                                            <DeleteIcon />
                                                        </Button>
                                                    </Stack>
                                                )
                                            })
                                        }
                                    </Stack>
                                </Stack>
                            </Box>
                        </Modal>
                    </Stack>
                    <Divider />

                    <Stack direction={'row'}>
                        <CustomFeedColumn
                            colName={'One'}
                            colData={colOneData}
                            searchFeedFn={searchCustomFeed1}
                            refreshFeed={refreshFeed}
                            handleAddUserByHandleAndCol={handleAddUserByHandleAndCol}
                            userObject={userObject}
                            setSearchField={setCustomFeed1SearchField}
                            searchField={customFeed1SearchField}
                            changeColPage={changeColOnePage}
                            colPage={colOnePage}
                            colCount={colOneCount}
                            colLoading={colOneLoading}
                            colPageCount={colOnePageCount}
                            filterByKeywordsFn={filterByKeywords}
                            filteringByKeywords={filteringByKeywordOne}
                        />
                        <CustomFeedColumn
                            colName={'Two'}
                            colData={colTwoData}
                            searchFeedFn={searchCustomFeed2}
                            refreshFeed={refreshFeed}
                            handleAddUserByHandleAndCol={handleAddUserByHandleAndCol}
                            userObject={userObject}
                            setSearchField={setCustomFeed2SearchField}
                            searchField={customFeed2SearchField}
                            changeColPage={changeColTwoPage}
                            colPage={colTwoPage}
                            colCount={colTwoCount}
                            colLoading={colTwoLoading}
                            colPageCount={colTwoPageCount}
                            filterByKeywordsFn={filterByKeywords}
                            filteringByKeywords={filteringByKeywordTwo}
                        />
                        <CustomFeedColumn
                            colName={'Three'}
                            colData={colThreeData}
                            searchFeedFn={searchCustomFeed3}
                            refreshFeed={refreshFeed}
                            handleAddUserByHandleAndCol={handleAddUserByHandleAndCol}
                            userObject={userObject}
                            setSearchField={setCustomFeed3SearchField}
                            searchField={customFeed3SearchField}
                            changeColPage={changeColThreePage}
                            colPage={colThreePage}
                            colCount={colThreeCount}
                            colLoading={colThreeLoading}
                            colPageCount={colThreePageCount}
                            filterByKeywordsFn={filterByKeywords}
                            filteringByKeywords={filteringByKeywordThree}
                        />
                    </Stack>
                </Container>
            </Box>
        )
    }else{
        return null
    }
}

export default TweetDisplay;
