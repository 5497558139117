import React from 'react'
import { Grid, Typography, Stack, Avatar, Box, Tooltip, IconButton } from '@mui/material'

import { IconArrowUpRightCircle } from '@tabler/icons'
import { HelpOutline } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'
const QuickFundingRates = ( { ruleThemAll, changeTab} ) => {

  const theme = useTheme()

  //console.log("top of QuickFundingRates and ruleThemAll is:")
  //console.dir(ruleThemAll)

  //var useThisFundingRateData = ruleThemAll[ruleThemAll.length-1] //new endpoint provides data latest first...this will not work
  var useThisFundingRateData = ruleThemAll[0]

  if (ruleThemAll.length === 0) {
    return (
      <Box sx={{width: '100%'}} >
        <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[500]}}>Funding Rates</Typography>
        <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
      </Box>
    )
  } else {  

    //console.log("useThisFundingRateData is:")
    //console.dir(useThisFundingRateData)

    var fundingRateColor = theme.palette.success.dark
    var fundingRateDesc = 'POSITIVE'
    if (useThisFundingRateData.healthData.funding_rates < 0) {
      fundingRateColor = theme.palette.error.dark
      fundingRateDesc = 'NEGATIVE'
    }

    return (
        <Stack
          direction="column"
          sx={{backgroundColor: theme.palette.dark.dark, margin: '10px', padding: '5px', paddingTop: '0px', borderRadius: '5px', minHeight: '7vh', cursor: 'pointer'}}
          onClick={() => changeTab('15')}
        >
          <Stack
            direction="row"
            display="flex"
            justifyContent='space-between'
            alignItems="center"
          >
            <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>Funding Rates</Typography>
            <Stack direction="row" display="flex" alignItems="center">
              <Tooltip title="Funding rates make the perpetual futures contract price close to the index price. It is made be closer to the spot prices and cover some of the gap generated by the perpetual period of time. All cryptocurrency derivatives exchanges use funding rates for perpetual contracts and the standard unit is a percentage. Funding Rate is a result of market behaviors and could be used to maker some interpretation in the derivative market which also is a dominant price maker in the market. However, correlating high funding rates with inevitable price drop could be a wrong interpretation. In the bull market, it have a tendency to naturally bring high funding rates with price rise."  >
                <IconButton size="small" aria-label="full chart">< HelpOutline sx={{fontSize: '1.1rem', color: theme.palette.grey[500]}} /></IconButton>
              </Tooltip>   
              <Tooltip title='click for full Funding Rate chart'  ><IconButton size="small" aria-label="full chart">< IconArrowUpRightCircle size={18} color={theme.palette.grey[500]} /></IconButton></Tooltip>
            </Stack>
          </Stack>
          <Box sx={{width: '99%', backgroundColor: theme.palette.dark.dark, paddingTop: '8px', paddingBottom: '5px'}} >
            <Stack direction="column" alignItems="center" >
              <Avatar sx={{width: 50, height: 50, bgcolor: fundingRateColor, display: 'flex', justifyContent: 'center', fontSize: '.8rem', color: theme.palette.grey[500] }}>{useThisFundingRateData.healthData.funding_rates.toFixed(4)}</Avatar>
              <Typography variant="Button" sx={{marginLeft: '5px', fontWeight: 700, color: fundingRateColor}}>{fundingRateDesc}</Typography>
            </Stack>
          </Box>
        </Stack>
    )
  }
}

export default QuickFundingRates