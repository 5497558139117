import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import {
  Box, useMediaQuery, 
  CssBaseline,
  Container,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import fetchData from '../../utility/endpointFetch'
import TopAppBar from '../TopAppBar'
import Footer from '../Footer'

import CoinDetailsTitle from './CoinDetailsTitle'
import CoinDetailsLinks from './CoinDetailsLinks'
import CoinDetailsDescription from './CoinDetailsDescription'
import CoinDetailsChart from './CoinDetailsChart'
import CoinDetailsStats from './CoinDetailsStats'
import CoinDetailsWizardDetails from './CoinDetailsWizardDetails'
import CoinDetailsLWChart from './CoinDetailsLWChart'
import CoinDetailsCorrelationBTC from './CoinDetailsCorrelationBTC'
import CoinDetailsCorrelationETH from './CoinDetailsCorrelationETH'

const CoinDetails = ( {userObject} ) => {
  const { coinId } = useParams();
  const [additionalCoinSearch, setAdditionalCoinSearch] = useState('')
  const [additionalCoinsList, setAdditionalCoinsList] = useState([])
  const [currencyDetails, setCurrencyDetails] = useState({})
  const [currencyWizardDetails,setCurrencyWizardDetails] = useState({})
  const [firstPrice,setFirstPrice] = useState(0)
  const [lastPrice,setLastPrice] = useState(0)
  const [isLoading, setIsLoading ] = useState(true)
  const [isShowingMoreCoins, setIsShowingMoreCoins] = useState(false)
  const [allCoinsInVIP, setAllCoinsInVIP] = useState([])
  const [allCoinsInVIPWithScreenerName,setAllCoinsInVIPWithScreenerName] = useState([])
  const [isLwChart, setIsLwChart] = useState(false)
  const [hasLwChart, setHasLwChart] = useState(true)
  
  const theme = useTheme()
  const medScreenOrSmaller = useMediaQuery(theme.breakpoints.down("md"))

  const handleAdditionalCoinSearchChange = (event) => {
    setAdditionalCoinSearch(event.target.value);
  };

  const searchForMoreCoins = () => {
    ;(async () => {
      try {
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }

        let searchString = "&search="+additionalCoinSearch
        var getCoinsSearchURL = `api/v1/coin/ecoinscreener/?page=1${searchString}`
        const response = await fetchData(getCoinsSearchURL, "could not receive ecoinscreener data", fetchParams).promise
        setAdditionalCoinsList(response.results)
        setIsShowingMoreCoins(true)
      } catch (error) {
        console.log("something is broke getting ecoinscreener: " + error)
      }
    })()
  }

  const clearSearchForMoreCoins = () => {
    setAdditionalCoinSearch('');
  }

  useEffect(() => {

    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        const response = await fetchData(`api/v1/coin/ecoinscreener/?coin=${coinId}`, "could not receive ecoinscreener data", fetchParams).promise
        //console.log("response getting ecoinscreener data using coinId: " + coinId)
        //console.dir(response)
        setCurrencyDetails(response.results[0])

        const wizardFetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            var wizardResponseURL = `api/v1/research/tokenwizard/?coin=${response.results[0].coin}`
            const wizardResponse = await fetchData(wizardResponseURL, "could not receive research wizard data", wizardFetchParams).promise
            console.log("wizardResponse getting wizard data from api for coin: " + response.results[0].coin)
            console.log(wizardResponseURL)
            console.dir(wizardResponse)
            if (!wizardResponse || wizardResponse.count === 0) {
              //there is no wizard details for this currency
              setCurrencyWizardDetails({})
            } else {
              console.log(wizardResponse.results[0])
              setCurrencyWizardDetails(wizardResponse.results[0])
            }

            ;(async () => {
              try {
                const getCurrentScreenersResponse = await fetchData(`api/v1/users/screener/`, "could not get all screeners in home coins list", fetchParams).promise
                setAllCoinsInVIP(getCurrentScreenersResponse.results.map(screenerCoin => screenerCoin.coin_id))
                setAllCoinsInVIPWithScreenerName(getCurrentScreenersResponse.results.map(screenerCoin => ({ 'coin_id': screenerCoin.coin_id, 'screener_name': screenerCoin.screener_name, 'id': screenerCoin.id}) ))
              } catch (error) {
                console.log("something is broke getting users screener data in coin details: " + error)
              }
            })() 

            setIsLoading(false)
              
          } catch (error) {
            console.log("something is broke getting research wizard data in coin details: " + error)
          }    
        })()
      } catch (error) {
        console.log("error","something is broke getting ecoinscreener or tokenwizard")
      }
    })()

  }, [])

  useEffect(() => {
    if (currencyDetails && Object.keys(currencyDetails).length !== 0) {
      const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
          }
      ;(async () => {
        var getExchangeChartDataURL = `api/v1/coin/exchangechart1d/?symbol=${currencyDetails.coin_symbol}&page_size=100&page=1&ordering=-timestamp`
        const exhangechartResponse = await fetchData(getExchangeChartDataURL, "could not receive exchange chart data", fetchParams).promise
        if (!exhangechartResponse.results || exhangechartResponse.results.length < 1 || exhangechartResponse.count < 1) setHasLwChart(false)
      })()
    }
  }, [currencyDetails])

  if (isLoading) return <CircularProgress />
  if (medScreenOrSmaller) {
    console.log("medScreenOrSmaller in CoinDetailsTitle")
    return (
      <Box sx={{ width: '100%' }}>
        <CssBaseline />
        {/* header */}
        <TopAppBar userObject={userObject} />  
        <Grid container spacing={0} sx={{marginTop: '65px', width: '100%'}}>
          <Grid item xs={12}>
            <CoinDetailsTitle
              currencyDetails={currencyDetails} 
              firstPrice={firstPrice} 
              lastPrice={lastPrice} 
              userObject={userObject}
              allCoinsInVIP={allCoinsInVIP} 
              allCoinsInVIPWithScreenerName={allCoinsInVIPWithScreenerName}
              isLwChart={isLwChart}
              setIsLwChart={setIsLwChart}
              hasLwChart={hasLwChart}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around" sx={{position: 'relative', top: '43%' }}>
              <CoinDetailsCorrelationBTC currencyDetails={currencyDetails} medScreenOrSmaller={true} />
              <CoinDetailsCorrelationETH currencyDetails={currencyDetails} medScreenOrSmaller={true} />
            </Stack>
          </Grid>          
          <Grid item xs={12}>
            <CoinDetailsChart currencyDetails={currencyDetails} setFirstPrice={setFirstPrice} setLastPrice={setLastPrice} userObject={userObject} medScreenOrSmaller={true} />
          </Grid>
          <Grid item xs={12}>
            <CoinDetailsLinks currencyDetails={currencyDetails} medScreenOrSmaller={true} />
          </Grid>
          <Grid item xs={12}>
            <CoinDetailsStats currencyDetails={currencyDetails}  />
          </Grid>
          <Grid item xs={12}>
            <CoinDetailsWizardDetails currencyWizardDetails={currencyWizardDetails} medScreenOrSmaller={true} />
          </Grid> 
          <Grid item xs={12}>
            <CoinDetailsDescription currencyDetails={currencyDetails} />
          </Grid>         
          <Grid item xs={12}>
            <Footer />
          </Grid>

        </Grid> 

         
      </Box>  
    )
  } else {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* header */}
        <TopAppBar userObject={userObject} />
        <Container maxWidth={false} sx={{marginTop: '80px'}}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Stack direction="column">
                <CoinDetailsTitle
                  currencyDetails={currencyDetails} 
                  firstPrice={firstPrice} 
                  lastPrice={lastPrice} 
                  userObject={userObject}
                  allCoinsInVIP={allCoinsInVIP} 
                  allCoinsInVIPWithScreenerName={allCoinsInVIPWithScreenerName}
                  isLwChart={isLwChart}
                  setIsLwChart={setIsLwChart}
                  hasLwChart={hasLwChart}
                />

                {
                  isLwChart ?
                      <CoinDetailsLWChart currencyDetails={currencyDetails} setHasLwChart={setHasLwChart} />
                      :
                      <CoinDetailsChart currencyDetails={currencyDetails} setFirstPrice={setFirstPrice} setLastPrice={setLastPrice} userObject={userObject} />
                }
                {/*<Paper >
                  <CoinDetailsCommentary currencyDetails={currencyDetails} />
                </Paper>*/}
                <CoinDetailsWizardDetails currencyWizardDetails={currencyWizardDetails} />
              </Stack>
            </Grid>
            <Grid item xs={3} >
              {
                isShowingMoreCoins ?
                    <Box sx={{backgroundColor: theme.palette.dark[800], borderRadius: '15px', padding: "10px", marginTop: '20px'}}>
                      <Stack direction={'row'} sx={{width: '100%', mt: 3}}>
                        <TextField
                            sx={{width: '100%'}}
                            name='additionalCoinSearch'
                            variant='outlined'
                            value={additionalCoinSearch}
                            id='additionalCoinSearch'
                            label='Search for more coins...'
                            onChange={handleAdditionalCoinSearchChange}
                            inputProps={{ maxLength: 50 }}
                            size="small"
                        />
                        <IconButton onClick={() => {
                          clearSearchForMoreCoins()
                          setIsShowingMoreCoins(false)
                        }}>
                          <CloseIcon color={'error'} />
                        </IconButton>
                      </Stack>
                      <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: theme.palette.dark[800]}}>
                        {additionalCoinsList.map((value) => {
                          const detailLink = "/coindetail/"+value.coin
                          const labelId = `checkbox-list-secondary-label-${value}`;
                          return (
                              <ListItem
                                  key={value.id}
                                  sx={{padding: '5px'}}
                              >
                                <ListItemButton component={Link}  href={detailLink} sx={{":hover": {backgroundColor: '#2196f3'}}}>
                                  <ListItemAvatar>
                                    {
                                      value && value.coin && value.image_thumbnail ?
                                          <Avatar
                                              alt={value.coin_symbol}
                                              src={value.image_thumbnail}
                                          />
                                        :
                                        <Avatar src={null} alt={value.coin} />
                                    }
                                  </ListItemAvatar>
                                  <ListItemText sx={{color: theme.palette.primary.main}} color={theme.palette.primary.main} id={labelId} primary={String(value.coin_symbol).toUpperCase()} />
                                  <ListItemText sx={{color: theme.palette.primary.main}} color={theme.palette.primary.main} id={labelId} primary={value.coin} />
                                </ListItemButton>
                              </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                    :
                    <React.Fragment>
                      <Stack direction={'row'} sx={{width: '100%', mt: 3}}>
                        <TextField
                            sx={{width: '100%'}}
                            name='additionalCoinSearch'
                            variant='outlined'
                            value={additionalCoinSearch}
                            id='additionalCoinSearch'
                            label='Search for more coins...'
                            onKeyDown={(e) => {
                              if(e.keyCode === 13){
                                searchForMoreCoins()
                              }
                            }}
                            onChange={handleAdditionalCoinSearchChange}
                            inputProps={{ maxLength: 50 }}
                            size="small"
                        />
                        <IconButton onClick={() => clearSearchForMoreCoins()}>
                          <CloseIcon color={'error'} />
                        </IconButton>
                        <IconButton onClick={() => searchForMoreCoins()}>
                          <SearchIcon color={'primary'} />
                        </IconButton>
                      </Stack>
                      <Box sx={{backgroundColor: theme.palette.dark[800], borderRadius: '15px', padding: "10px", marginTop: '20px'}}>
                        {/*<Typography variant="h3" sx={{paddingTop: '15px', paddingLeft: '15px'}}>Coin Perpetuals</Typography>*/}
                        <Stack direction="column" display="flex">
                          <CoinDetailsLinks currencyDetails={currencyDetails} />
                          <CoinDetailsStats currencyDetails={currencyDetails}  />
                          <CoinDetailsDescription currencyDetails={currencyDetails} />
                        </Stack>
                      </Box>
                    </React.Fragment>
              }
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  }
}

export default CoinDetails