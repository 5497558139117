import React, { useState, useEffect } from 'react'
import { Grid, Box, Stack, Typography, Avatar, Divider, Pagination, CircularProgress } from '@mui/material'
import { Twitter, Person } from '@mui/icons-material'

import VIPTwitterPost from './VIPTwitterPost'
import VIPTelegramPost from './VIPTelegramPost'
import VIPDiscordPost from './VIPDiscordPost'
import VIPRedditPost from './VIPRedditPost'
import VIPResearchPost from './VIPResearchPost'
import fetchData from '../../utility/endpointFetch'

import { useTheme } from '@mui/material/styles'
import {FiExternalLink} from 'react-icons/fi'
import { communitySize } from "../../utility/objHelper"

const VIPAllAtFollowed = ( { atFollowedTabValue, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, showOfficialTweets, divHeight=0, boxWidth=0 }) => {

  //console.log("top of VIPAllAtFollowed")
  //console.log("atFollowedTabValue is: " + atFollowedTabValue)

  const theme = useTheme()

  const [isLoading,setIsLoading] = useState(false)
  const [allAtFollowedListings,setAllAtFollowedListings] = useState([])
  const [twitterPage,setTwitterPage] = useState(1)
  const [twitterPageCount,setTwitterPageCount] = useState(1)
  const [responseCount,setResponseCount] = useState(0)

  useEffect(() => {
    if (atFollowedTabValue.length > 0) {
      setIsLoading(true)
      const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
      }
      ;(async () => {
        try {
          const twitterResponse = await fetchData(`api/v1/feed/twitter/?twitter_user__name=${atFollowedTabValue}&page=${twitterPage}`, "could not receive all twitter data", fetchParams).promise
          //console.log("twitterResponse getting twitter data from api")
          //console.dir(twitterResponse)

          setResponseCount(twitterResponse.count)

          if (twitterResponse.count > 100) {
            setTwitterPageCount(Math.ceil(twitterResponse.count/100))
          } else {
            setTwitterPageCount(1)
          }

          setAllAtFollowedListings(twitterResponse.results)

          setIsLoading(false)
        } catch (error) {
          console.log("error","something is broke getting all twitter: " + error)
        }
      })()
    } else {
      setResponseCount([])
    }

  }, [atFollowedTabValue, twitterPage])

  //console.dir(allAtFollowedListings[0])

  const displayAvatar = () => {
    //console.log("top of displayAvatar")
    //console.dir(allAtFollowedListings)
    if (allAtFollowedListings.length > 0) {
      if (allAtFollowedListings[0].twitter_user_avatar_url === '') {
        return <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
      } else {
        return <Avatar alt={allAtFollowedListings[0].twitter_display_name} src={allAtFollowedListings[0].twitter_user_avatar_url} sx={{ width: 42, height: 42, marginRight: '3px' }}/>
      }
    } else {
      return <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
    }
  }

  const changeTwitterPage = (event, page) => {
    setIsLoading(true)
    setTwitterPage(page)
  }

  if (isLoading) return <CircularProgress />
  if (allAtFollowedListings.length > 0) {

    const linkToTweeter = "https://twitter.com/"+allAtFollowedListings[0].twitter_display_name

    return (
      <Grid container>
        <Grid item xs={1} sx={{paddingTop: '5px', paddingLeft: '5px'}}>
          <Stack direction="column" sx={{marginBottom: '10px'}} display="flex" justifyContent="space-between" alignItems="center">
            {displayAvatar()}
          </Stack>
        </Grid>
        <Grid item xs={11} sx={{padding: "8px", }}>
          <Box sx={{borderRadius: '5px',  padding: "5px", margin: '0px',  }}>
            <Grid container >
              <Grid item xs={12}>
                <Stack direction="row" sx={{display: "flex", alignItems: "center", justifyContent: 'space-between',}}>
                  <Stack direction="row">
                    <Twitter sx={{marginRight: "5px", marginTop: '2px', fontSize: "1.2rem", color: theme.palette.primary.main}} />
                    <Typography variant="subtitle1" sx={{fontWeight: 600, color: theme.palette.grey[800]}}>{allAtFollowedListings[0].display_name}</Typography>
                    <Typography variant="subtitle1" sx={{fontWeight: 400, color: theme.palette.grey[500], marginLeft: '10px'}}>@{allAtFollowedListings[0].twitter_display_name}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Person sx={{fontSize: '1rem'}} color={theme.palette.grey[500]} />
                    <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '3px', marginTop: '3px'}}>{communitySize(allAtFollowedListings[0])}</Typography>
                    <a target="_blank" rel="noreferrer" style={{position: 'relative', top: '2px', left: '3px'}} href={linkToTweeter}><FiExternalLink color={theme.palette.primary.main} /></a>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{width:'100%'}} textAlign="left" >
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[800], paddingLeft: '20px', marginTop: '20px'}}>{responseCount}</Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.grey[500], marginTop: '20px'}}> posts In FULL results from </Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[800], marginTop: '20px'}}>@{atFollowedTabValue}</Typography>
          </Divider>
        </Grid>
        {allAtFollowedListings.map( (listing, index) => {
            if (listing.hasOwnProperty('twitter_user')) return <VIPTwitterPost key={'allfollowed'+index} noHeader={true} listing={listing} showOfficialTweets={showOfficialTweets} setCoinInChart={setCoinInChart} changeHashTab={changeHashTab} changeAtFollowedTab={changeAtFollowedTab} changeAtMentionedTab={changeAtMentionedTab} changeCoinInTab={changeCoinInTab} changeDateInChart={changeDateInChart} divHeight={divHeight} boxWidth={boxWidth} />
            else if (listing.hasOwnProperty('telegram_group')) return <VIPTelegramPost key={'allfollowed'+index} noHeader={true} telegramItem={listing.post} />
            else if (listing.hasOwnProperty('discord_server')) return <VIPDiscordPost key={'allfollowed'+index} noHeader={true} discordItem={listing.post} />
            else if (listing.hasOwnProperty('subreddit')) return <VIPRedditPost key={'allfollowed'+index} noHeader={true} redditItem={listing.post} />
            else return <VIPResearchPost key={'allfollowed'+index} researchItem={listing.post} /> //not sure if these are the only 5 types needed
        })}
        <Grid item xs={12}>
          <Divider sx={{marginBottom: '15px'}}/>
          <Stack direction="row" display="flex" alignItems="center">
            <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
            <Pagination shape="rounded" count={twitterPageCount} page={twitterPage} onChange={changeTwitterPage}/>
          </Stack>
        </Grid>
      </Grid>
    )
  } else {
    if (atFollowedTabValue.length > 0) {
      return (
       <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>There are no FULL results for: @{atFollowedTabValue}</Typography>
      )
    } else {
      return (
        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>There is no @follower value to search</Typography>
      )
    }
  }
}

export default VIPAllAtFollowed