import React, { useState, useEffect } from 'react'
import { Grid, Box, Stack, Typography, Avatar, Divider } from '@mui/material'
import { Twitter, Person } from '@mui/icons-material'

import VIPTwitterPost from './VIPTwitterPost'
import VIPTelegramPost from './VIPTelegramPost'
import VIPDiscordPost from './VIPDiscordPost'
import VIPRedditPost from './VIPRedditPost'
import VIPResearchPost from './VIPResearchPost'

import { useTheme } from '@mui/material/styles'
import {FiExternalLink} from 'react-icons/fi'

const VIPTabsAtFollowed = ( { vipListings, atFollowedTabValue, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, showOfficialTweets }) => {

  //console.log("top of VIPTabsAtFollowed")
  //console.dir(vipListings)

  const theme = useTheme()

  const [atFollowedListings,setAtFollowedListings] = useState([])

  useEffect(() => {
    if (atFollowedTabValue.length > 0)
      setAtFollowedListings(vipListings.filter( tweet => tweet.twitter_display_name === atFollowedTabValue  ))
    else
      setAtFollowedListings([])

  }, [atFollowedTabValue])

  //console.dir(atFollowedListings[0])

  const displayAvatar = () => {
    if (atFollowedListings.length > 0) {
      if (atFollowedListings[0].twitter_user_avatar_url === '') {
        return <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
      } else {
        return <Avatar alt={atFollowedListings[0].twitter_display_name} src={atFollowedListings[0].twitter_user_avatar_url} sx={{ width: 42, height: 42, marginRight: '3px' }}/>
      }
    } else {
      return <Twitter sx={{marginLeft: "5px", marginTop: '5px', marginRight: '5px', fontSize: "1.5rem", color: theme.palette.primary.main}} />
    }
  }

  const communitySize = () => {
    if (atFollowedListings.length > 0) {
      if (atFollowedListings[0].twitter_user_followers > 1000000) {
        return ((atFollowedListings[0].twitter_user_followers / 1000000).toFixed(1)) + 'M'
      } else if (atFollowedListings[0].twitter_user_followers > 10000) {
        return ((atFollowedListings[0].twitter_user_followers / 1000).toFixed(0)) + 'K'
      } else if (atFollowedListings[0].twitter_user_followers > 1000) {
        return ((atFollowedListings[0].twitter_user_followers/1000).toFixed(1)) + 'K'
      } else {
        //just display the full amount
        return atFollowedListings[0].twitter_user_followers
      }
    } else {
      //nothing came through....punt
      return 0
    }
  }   

  if (atFollowedListings.length > 0) {

    var linkToTweeter=''
    if (atFollowedTabValue.length > 0)
      linkToTweeter = "https://twitter.com/"+atFollowedListings[0].twitter_display_name

    return (
      <Grid container>
        <Grid item xs={12}><Typography variant="h5" sx={{color: theme.palette.secondary.main, margin: '10px'}}>@Followed From Current VIP Results Page</Typography></Grid>

        <Grid item xs={1} sx={{paddingTop: '5px', paddingLeft: '5px'}}>
          <Stack direction="column" sx={{marginBottom: '10px'}} display="flex" justifyContent="space-between" alignItems="center">
            {displayAvatar()}
          </Stack>
        </Grid>

        <Grid item xs={11} sx={{padding: "8px", }}>
          <Box sx={{borderRadius: '5px',  padding: "5px", margin: '0px',  }}>
            <Grid container >
              
              <Grid item xs={12}>
                <Stack direction="row" sx={{display: "flex", alignItems: "center", justifyContent: 'space-between',}}>
                  <Stack direction="row">
                    <Twitter sx={{marginRight: "5px", marginTop: '2px', fontSize: "1.2rem", color: theme.palette.primary.main}} />
                    <Typography variant="subtitle1" sx={{fontWeight: 600, color: theme.palette.grey[500]}}>{atFollowedListings[0].display_name}</Typography>
                    <Typography variant="subtitle1" sx={{fontWeight: 400, color: theme.palette.grey[700], marginLeft: '10px'}}>@{atFollowedListings[0].twitter_display_name}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Person sx={{fontSize: '1rem'}} color={theme.palette.grey[500]} />
                    <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '3px', marginTop: '3px'}}>{communitySize()}</Typography>
                    <a target="_blank" rel="noreferrer" style={{position: 'relative', top: '2px', left: '3px'}} href={linkToTweeter}><FiExternalLink color={theme.palette.primary.main} /></a>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{width:'100%'}} textAlign="left" >
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>{atFollowedListings.length}</Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.secondary.main, marginTop: '20px'}}> posts In VIP results from </Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[500], marginTop: '20px'}}>@{atFollowedTabValue}</Typography>
          </Divider>
        </Grid>
        {atFollowedListings.map( (listing, index) => {
            if (listing.hasOwnProperty('tweet_id')) return <VIPTwitterPost key={'vipfollowed'+index} noHeader={true} listing={listing} setCoinInChart={setCoinInChart} showOfficialTweets={showOfficialTweets} changeHashTab={changeHashTab} changeAtFollowedTab={changeAtFollowedTab} changeAtMentionedTab={changeAtMentionedTab} changeCoinInTab={changeCoinInTab} changeDateInChart={changeDateInChart} />
            else if (listing.hasOwnProperty('telegram_group')) return <VIPTelegramPost key={'vipfollowed'+index} noHeader={true} telegramItem={listing} />
            else if (listing.hasOwnProperty('discord_server')) return <VIPDiscordPost key={'vipfollowed'+index} noHeader={true} discordItem={listing} />
            else if (listing.hasOwnProperty('subreddit')) return <VIPRedditPost key={'vipfollowed'+index} noHeader={true} redditItem={listing} />
            else return <VIPResearchPost key={'vipfollowed'+index} researchItem={listing} /> //not sure if these are the only 5 types needed
        })}
      </Grid>
    )
  } else {
    if (atFollowedTabValue.length > 0) {
      return (
        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>There are no VIP results for: @{atFollowedTabValue}</Typography>
      )
    } else {
      return (
        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>There are no @followed to search for</Typography>
      )
    }
  }
}

export default VIPTabsAtFollowed