import { Typography, TableContainer, Table, TableRow, TableCell, TableBody, Paper } from '@mui/material'
import { Report as ReportIcon, Warning as WarningIcon } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'

const LoginErrorMessageDisplay = ({ errorMessage, secondaryErrorMessage = false }) => {

  const theme = useTheme()

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ paddingTop: theme.spacing(8) }} padding='normal'>
          <TableBody>
            <TableRow key='some_key'>
              <TableCell>
                <ReportIcon fontSize='large' sx={{ color: 'red' }} />
              </TableCell>
              <TableCell>
                <Typography variant='button' color='error'>{errorMessage}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {!secondaryErrorMessage ? null :
        <TableContainer component={Paper}>
          <Table sx={{ paddingTop: theme.spacing(8) }} padding='normal'>
            <TableBody>
              <TableRow key='some_key2'>
                <TableCell>
                  <WarningIcon fontSize='large' sx={{ color: '#FACC37' }} />
                </TableCell>
                <TableCell>
                  <Typography variant='button' gutterBottom color='error'>{secondaryErrorMessage}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      }
    </div>
  )
}

export default LoginErrorMessageDisplay
