import React, { useState, useEffect } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import Chart from "react-apexcharts"
import fetchData from '../../utility/endpointFetch'

const ResearchCoinChartMarket = ( {chartDays, coin, showSocialRank, sourcePostsForChart, chartInterval, pageSize, userObject, openPriceAlert} ) => {

    //console.log("top of ResearchCoinChartMarket and coin is: " + coin)
    const theme = useTheme()

    const [ isLoading, setIsLoading ] = useState(true)
    const [ mixedChartOptions, setMixedChartOptions ] = useState({})
    const [ mixedChartSeries, setMixedChartSeries ] = useState([])
    const [ chartError, setChartError ] = useState(false)

    const chartBaseCurrencySymbol = '$ '
    const chartBaseCurrency = 'USD'

    const loadChartData = (showSocialRank) => {
        console.log("top of loadChartData and coin is: " + coin)
        if (coin !== '') {
          setIsLoading(true)
          try {
            const fetchParams = {
                  method: 'GET',
                  dataType: 'json',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                }
            ;(async () => {
              var dateAgo = new Date(Date.now() - (chartDays * 24 * 60 * 60 * 1000))
      
              //CURRENTLY NO POSTS WILL APPEAR IN CHARTS...ADD LATER?
      
              //need to filter out all posts within sourcePostsForChart that reference ${coin}
              //this is currently not used....was included at the top of ResearchCoinChart but seems to no longer be used.....so will not be used here
              var coinAnnotations = []
              /*console.log("sourcePostsForChart is:")
              console.dir(sourcePostsForChart)
              let filteredPosts = sourcePostsForChart.filter( (sourcePost) => sourcePost.coins.includes(coin) )
              console.log("filteredPosts for coin: " + coin + " is:")
              console.dir(filteredPosts)
      
              let coinAnnotations = []
              filteredPosts.map( post => {
      
                      let postName = ''
                      if (post.hasOwnProperty('tweet_id'))
                          postName = post.twitter_display_name
                      else if (post.hasOwnProperty('telegram_group'))
                          postName = post.telegram_group.name
                      else if (post.hasOwnProperty('discord_server'))
                          postName = post.discord_server.name
                      else if (post.hasOwnProperty('subreddit'))
                          postName = post.subreddit.name
          
                      coinAnnotations.push({
                          x: new Date(post.created).getTime(),
                          strokeDashArray: 0,
                          borderColor: theme.palette.error.dark,
                          label: {
                          text: postName,
                          borderColor: theme.palette.warning.dark,
                          style: {
                              color: theme.palette.grey[100],
                              background: theme.palette.success.dark,
                          },
                          }
                      })
                  }
              )
              */
      
              //console.log("coinAnnotations before call:")
              //console.dir(coinAnnotations)
      
              var urlString = `api/v1/coin/marketchart/?coin=${coin}&interval=${chartInterval}&timestamp__gt=${dateAgo.toISOString()}&page_size=${pageSize}&page=1`
              var response = await fetchData(urlString, "could not receive market chart data in ResearhChart", fetchParams).promise
              console.log("response getting marketchart data from backend for: " + coin)
              console.log(response)
              console.log("urlString is:")
              console.dir(urlString)
      
              if (response.count > 0) {
                ;await (async () => {
                  var socialURLString = `api/v1/coin/influencerchart/?coin=${coin}&interval=${chartInterval}&timestamp__gt=${dateAgo.toISOString()}&page_size=${pageSize}&page=1`
                  var socialResponse = await fetchData(socialURLString, "could not receive influencer chart data in ResearhChart", fetchParams).promise
                  console.log("socialResponse getting influencer chart data for "+coin+" is:")
                  console.dir(socialResponse)
                  console.log("socialURLString is:")
                  console.dir(socialURLString)
                  //find min max prices
                  var minMaxPrices = response.results.map((data) => {
                    return data.price
                  })
      
                  var chartMax = minMaxPrices.reduce((a, b) => {
                    return Math.max(a, b)
                  }); 
                  var chartMin = minMaxPrices.reduce((a, b) => {
                    return Math.min(a, b)
                  });
      
                  var firstValue = response.results[response.results.length - 1].price
                  var lastValue = response.results[0].price
      
                  var chartColor = '#109e10'
                  var legendPosition = 'left'
                  if (firstValue > lastValue) {
                    //price went down over chart
                    chartColor = '#9e1010'
                    legendPosition = 'right'
                  }
      
                  //console.log("firstValue is: " + firstValue)
      
      
                  //var volumeDataArrWithTimeStamp = response.results.map( (data) => {
                  //  return [ data.timestamp , data.volume ]
                  //})
                  let chartPriceswithDateArrTimestamp = response.results.map((data) => {
                    return [data.timestamp, data.price]
                  })
      
                  let socialRankDataArrWithTimeStamp = socialResponse.results.map((data) => {
                    let newDateObj = data.timestamp
                    if(!String(data.timestamp).includes('T')){
                      newDateObj = String(newDateObj).concat('T00:00:00')
                    }
                    return [newDateObj, data.social_rank]
                  })
      
                  //console.log("socialRankDataArrWithTimeStamp is:")
                  //console.dir(socialRankDataArrWithTimeStamp)
      
                  let socialEngDataArrWithTimeStamp = socialResponse.results.map((data) => {
                    let newDateObj = data.timestamp
                    if(!String(data.timestamp).includes('T')){
                      newDateObj = String(newDateObj).concat('T00:00:00')
                    }
                    return [newDateObj, data.total_social_engagement]
                  })
      
                  //console.log("socialEngDataArrWithTimeStamp is:")
                  //console.dir(socialEngDataArrWithTimeStamp)
      
      
                  //console.log("chartPriceswithDateArrTimestamp is: ")
                  //console.dir(chartPriceswithDateArrTimestamp)
      
                  //console.log("volumeDataArrWithTimeStamp is: ")
                  //console.dir(volumeDataArrWithTimeStamp)
      
                  let dateArr = response.results.map((data) => {
                    let date = new Date(data.timestamp)
                    let year = date.getFullYear()
                    let month = date.getMonth()
                    let day = date.getDate()
      
                    return day + '-' + month + '-' + year
                  })
      
                  // //find min max volume for chart
                  // let minMaxVolumes = response.results.map((data) => {
                  //   return data.volume
                  // })
      
                  // let chartMaxVolume = minMaxVolumes.reduce((a, b) => {
                  //   return Math.max(a, b)
                  // });
                  // let chartMinVolume = minMaxVolumes.reduce((a, b) => {
                  //   return Math.min(a, b)
                  // });
                  //
                  // let chartMaxVolumeUsed = (2 * chartMaxVolume)
      
                  /*
                  let minMaxSocialRank = socialResponse.results.map((data) => {
                    return data.social_rank
                  })
      
                  let chartMaxSocialRank = minMaxSocialRank.reduce((a, b) => {
                    return Math.max(a, b)
                  });
                  let chartMinSocialRank = minMaxSocialRank.reduce((a, b) => {
                    return Math.min(a, b)
                  });
      
                  let minMaxSocialEng = socialResponse.results.map((data) => {
                    return data.total_social_engagement
                  })
      
                  let chartMaxSocialEng = minMaxSocialEng.reduce((a, b) => {
                    return Math.max(a, b)
                  });
                  let chartMinSocialEng = minMaxSocialEng.reduce((a, b) => {
                    return Math.min(a, b)
                  });
                  */
      
                  let chartMinSocialEng = 0
                  let chartMaxSocialEng = 100000
      
                  let chartMinSocialRank = 0
                  let chartMaxSocialRank = 30
      
      
                  let mixedSeries = []
                  mixedSeries.push({
                    name: "Price",
                    type: "area",
                    data: chartPriceswithDateArrTimestamp
                  })
                  if (showSocialRank && socialRankDataArrWithTimeStamp && socialRankDataArrWithTimeStamp.length) {
                    mixedSeries.push({
                      name: "Social Rank",
                      type: "area",
                      data: socialRankDataArrWithTimeStamp
                    })
                  }
                  if (!showSocialRank && socialEngDataArrWithTimeStamp && socialEngDataArrWithTimeStamp.length) {
                    mixedSeries.push({
                      name: "Ttl Social Engagement",
                      type: "area",
                      data: socialEngDataArrWithTimeStamp
                    })
                  }
                  setMixedChartSeries(mixedSeries)
                  //console.log("chartColor is: " + chartColor)
                  //console.log("chartMin is: " + chartMin)
                  //console.log("chartMax is: " + chartMax)
      
                  var chartId = "price-volume-chart-" + coin
                  var chartGroup = "research3-" + coin
                  let ySeries = [
                    {
                      seriesName: "Price",
                      opposite: false,
                      show: true,
                      min: chartMin,
                      max: chartMax,
                      forceNiceScale: false,
                      labels: {
                        show: true,
                        formatter: (value) => {
                          if (value < 10) {
                            let fixedDecimal = 2
                            if (value < .00001)
                              fixedDecimal = 7
                            else if (value < .0001)
                              fixedDecimal = 6
                            else if (value < .001)
                              fixedDecimal = 5
                            else if (value < .01)
                              fixedDecimal = 4
                            else if (value < .1)
                              fixedDecimal = 4
                            return (value).toFixed(fixedDecimal).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            //return parseFloat(value)
                          } else
                            return parseInt(value)
                        },
                        style: {
                          colors: ['#bf3932']
                        }
                      },
                      crosshairs: {
                        show: true,
                        position: 'front'
                      },
                      tooltip: {
                        shared: true,
                        intersect: false,
                        x: {
                          show: false,
                        },
                        marker: {
                          show: true,
                        },
                      },
                    },
                  ]
      
                  if(showSocialRank){
                    ySeries.push( {
                      seriesName: "Social Rank",
                      opposite: true,
                      axisTicks: {
                        "show": true,
                      },
                      show: true,
                      min: chartMinSocialRank,
                      max: chartMaxSocialRank,
                      labels: {
                        formatter: function (value,index) {
                          return value.toFixed(1)
                        }
                      },                               
                      tooltip: {
                        shared: true,
                        intersect: false,
                        x: {
                          show: false,
                        },
                        marker: {
                          show: true,
                        },
                      },
                    },)
                  }else{
                    ySeries.push({
                      seriesName: "Ttl Social Engagement",
                      opposite: true,
                      show: true,
                      min: chartMinSocialEng,
                      max: chartMaxSocialEng,
                      labels: {
                        formatter: function (value,index) {
                            //these values are currently large...ie 200,000 plus
                            //need to 
                            return (value/1000)+"K"
                        }
                      },   
                      tooltip: {
                        shared: true,
                        intersect: false,
                        x: {
                          show: false,
                        },
                        marker: {
                          show: true,
                        },
                      },
                    })
                  }
                  setMixedChartOptions({
                    chart: {
                      type: 'line',
                      id: chartId,
                      group: chartGroup,
                      toolbar: {
                        show: false,
                      },
                      zoom: {
                        enabled: false,
                      },
                      background: theme.palette.background.default,
                      events: {
                        click: function (event, chartContext, config) {
                          if (userObject.user.is_vip) {
                            // console.log("top of chart click event (event, chartContext, config):")
                            // console.dir(event)
                            // console.dir(chartContext)
                            // console.dir(config)
                            //console.log("default price alert set to: " + config.globals.series[0][config.dataPointIndex])
                            if (config.globals.series[0][config.dataPointIndex] !== undefined) {
                              openPriceAlert(config.globals.series[0][config.dataPointIndex])
                            }
                          } else {
                            //this is a vip only feature
                          }
                        }
                      }
                    },
                    colors: [chartColor, showSocialRank ? '#10459c' : '#eba834'],
                    markers: {
                      size: [0,3,3],
                      colors: [chartColor, showSocialRank ? '#10459c' : '#eba834'],
                      strokeColor: [chartColor, showSocialRank ? '#10459c' : '#eba834'],
                      strokeWidth: 2
                    },
                    stroke: {
                      show: true,
                      width: [2, 0, 0]
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    labels: dateArr,
                    xaxis: {
                      type: 'datetime',
                      labels: {
                        show: true,
                      }
                    },
                    yaxis: ySeries,
                    fill: {
                      type: 'gradient',
                      gradient: {
                        shade: 'light',
                        type: "vertical",
                        shadeIntensity: 1,
                        gradientToColors: [theme.palette.background.default],
                        inverseColors: false,
                        opacityFrom: 0.9,
                        opacityTo: 0.5,
                      },
                    },
                    tooltip: {
                      shared: true,
                      theme: 'dark',
                      x: {
                        show: false,
                        format: 'dd MMM yyyy',
                      },
                      y: {
                        formatter: function (value, index) {
                          //console.log("in tooltip formatter of chart and value is:")
                          //console.dir(value)
                          //console.log("index is: ")
                          //console.dir(index)
                          if (value === index.series[0][index.dataPointIndex]) {
                            //this is a price
                            //console.log("this is price: " + value)
                            let fixedDecimal = 2
                            if (value < .00001)
                              fixedDecimal = 7
                            else if (value < .0001)
                              fixedDecimal = 6
                            else if (value < .001)
                              fixedDecimal = 5
                            else if (value < .01)
                              fixedDecimal = 4
                            else if (value < .1)
                              fixedDecimal = 4
                            //console.log("fixedDecimal in yaxis formatter is: " + fixedDecimal + " for value: "+value)
                            return chartBaseCurrencySymbol + " " + (value).toFixed(fixedDecimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " " + chartBaseCurrency
                          } else if (value === index.series[1][index.dataPointIndex]) {
                            //this is social rank...
                            //console.log("social rank:" + value)
                            return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                          } else if (value === index.series[2][index.dataPointIndex]) {
                            //this is total social engagement
                            //console.log("total social engagement: " + value)
                            return value
                          } else {
                            // not sure what it is...return unchanged
                            return value
                          }
                        }
                      },
                    },
                    legend: {
                      position: legendPosition,
                      floating: true,
                      offsetX: 10,
                      offsetY: 10,
                      show: false,
                      labels: {
                        colors: theme.palette.grey[300]
                      }
                    },
                    annotations: {
                      xaxis: coinAnnotations,
                    },
                  })
                })()
              } else {
                //there is nothing in the response....guess we do not have price data for this coin???
              }
      
              setIsLoading(false)
              //} catch (error) {
              //  console.log("error","something is broke getting coinmarket")
              //}
            })()
          } catch (error) {
            console.log("something is broke saving dashboard: ", error)
            setChartError(true)
          }
        } else {
          //no chartCoin set yet...leave isLoading=true
        }
    }

    useEffect(() => {
        loadChartData(showSocialRank)
    }, [chartDays, chartInterval, chartBaseCurrency, coin, showSocialRank])      

    if (isLoading) return <Box sx={{minHeight: '295px'}}><CircularProgress /></Box>
    if (chartError) {
      return (
        <Box><Typography>There was a problem loading data for {coin}</Typography></Box>
      )
    } else {
      return (
        <Chart
            options={mixedChartOptions}
            series={mixedChartSeries}
            type="area"
            height={250}
            width='100%'
        />
      )
    }
}

export default ResearchCoinChartMarket