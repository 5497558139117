import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'

import ResearchTutorial1Welcome from './ResearchTutorial1Welcome'
import ResearchTutorial2 from './ResearchTutorial2'
import ResearchTutorial3Search from './ResearchTutorial3Search'
import ResearchTutorial4Result from './ResearchTutorial4Result'
import ResearchTutorial5MarketData from './ResearchTutorial5MarketData'
import ResearchTutorial6ChartData from './ResearchTutorial6ChartData'
import ResearchTutorial7Tweets from './ResearchTutorial7Tweets'
import ResearchTutorial8Filters from './ResearchTutorial8Filters'
import ResearchTutorial9End from './ResearchTutorial9End'

const ResearchTutorial = ( {researchTutorialStep, changePopover, filterCoinList }) => {
    //console.log("top of ResearchTutorial")
    //setting defaults of welcome
    
    //const whichComponent = () => {
    //console.log("top of whichComponent and researchTutorialStep is:" + researchTutorialStep)
    switch (researchTutorialStep) {
        case 1: //welcome
            changePopover( 'anchorPosition', (window.innerWidth/2 -150), (window.innerHeight/2 - 150), 'none', 'welcome', '','','','')
            
            return (
                <Box sx={{width: '500px', minHeight: '300px'}}>
                    <Stack direction="column">                    
                        <ResearchTutorial1Welcome />
                    </Stack>
                </Box>
            )                       
            break
        case 2:  //step 2 blurb
            changePopover( 'anchorPosition', (window.innerWidth/2 -250), (window.innerHeight/2 - 175), 'none', 'step2', '','','','')
            return (
                <Box sx={{width: '500px', minHeight: '250px'}}>
                    <Stack direction="column">              
                        <ResearchTutorial2 /> 
                    </Stack>
                </Box>
            )                          
            break
        case 3: //search form
            changePopover( 'anchorEl', 0, 0, 'search', 'search_box', 'top','left','top','right')
            filterCoinList('search=staking')
            return (
                <Box sx={{width: '500px', height: '250px'}}>
                    <Stack direction="column">  
                        <ResearchTutorial3Search /> 
                    </Stack>
                </Box>
            )
            break
        case 4: //event results
            changePopover( 'anchorEl', 0, 0, 'result', 'result_box', 'top','left','top','left')
            return (
                <Box sx={{width: '500px', height: '650px'}}>
                    <Stack direction="column">  
                        <ResearchTutorial4Result /> 
                    </Stack>
                </Box>                
            )
            break
        case 5: //market data results
            changePopover( 'anchorEl', 0, 0, 'market', 'market_box', 'top','right','bottom','right')
            return (
                <Box sx={{width: '900px', height: '250px'}}>
                    <Stack direction="column" display="flex" justifyContent='flex-end'>  
                        <ResearchTutorial5MarketData /> 
                    </Stack>
                </Box>                
            )
            break            
        case 6: //chart data results
            changePopover( 'anchorEl', 0, 0, 'chart', 'chart_box', 'top','right','top','left')
            return (
                <Box sx={{width: '500px', height: '525px'}}>
                    <Stack direction="column">  
                        <ResearchTutorial6ChartData /> 
                    </Stack>
                </Box>                
            )
            break             
        case 7: //coin tweet results
            changePopover( 'anchorEl', 0, 0, 'tweet', 'tweet_box', 'top','right','top','left')
            return (
                <Box sx={{width: '500px', height: '525px'}}>
                    <Stack direction="column">  
                        <ResearchTutorial7Tweets /> 
                    </Stack>
                </Box>                
            )
            break           
        case 8: //more filters
            changePopover( 'anchorEl', 0, 0, 'filter', 'filter_box', 'top','left','top','right')
            return (
                <Box sx={{width: '500px', height: '400px'}}>
                    <Stack direction="column">  
                        <ResearchTutorial8Filters /> 
                    </Stack>
                </Box>                
            )
            break           
        case 9: //tutorial end
            changePopover( 'anchorPosition', (window.innerWidth/2 -150), (window.innerHeight/2 - 150), 'none', 'tutorial_end', '','','','')
            return (
                <Box sx={{width: '500px', height: '300px'}}>
                    <Stack direction="column">  
                        <ResearchTutorial9End /> 
                    </Stack>
                </Box>                
            )
            break   
        default: {
            console.log("whichComponent default????????????")
            return null
        }
    }
    //}
    /*
    return (
    
        <Popover
            open={true}
            id={popoverID}
            anchorReference={popoverAnchorReference}
            anchorPosition={{top: anchorPositionX, left: anchorPositionY}}
            anchorEl={popoverAnchorEl}

        >
            <Stack direction="column">
            {whichComponent()}

            </Stack>

        </Popover>

    )
    */
}
export default ResearchTutorial