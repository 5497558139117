import React, { useState, useEffect } from 'react'
import { Typography, TextField, Box, Stack, Button, CircularProgress, Grid, IconButton, Tooltip, } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import fetchData from '../../utility/endpointFetch'
import ResearchSearchSavedItem from './ResearchSearchSavedItem'
import { TiArrowForward } from 'react-icons/ti'

const ResearchSearchDisplayCurrentSearch = ( { queryString, startingValues, postSearchTerm, userObject, vipTrackedKeywords, sendSearchTermToVipTrackedKeywords, deleteSearchTermToVipTrackedKeywords } ) => {
    console.log("top of ResearchSearchDisplayCurrentSearch nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn")
    //console.log("postSearchTerm is: " + postSearchTerm)
    //console.log("vipTrackedKeywords is: ")
    //console.dir(vipTrackedKeywords)

    const theme = useTheme()

    const [savedSearchLabel,setSavedSearchLabel] = useState('')
    const [isLoading,setIsLoading] = useState(true)
    const [savedSearchList,setSavedSearchList] = useState([])
    const [filteredVIPSearchFeedList,setFilteredVIPSearchFeedList] = useState([])
    const [userObjectDetails,setUserObjectDetails] = useState({})
    

    useEffect(() => {
        setIsLoading(true)
        //the popover was just opened...get the current saved searches
        const getSavedSearchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
                'Content-Type': 'application/json'
            },
        }          
        ;(async () => {
            try {
                let getSavedSearchURL = `api/v1/users/userdata/`
                const getSavedSearchResponse = await fetchData(getSavedSearchURL, "could not receive current saved searches", getSavedSearchParams).promise
                
                console.log("getSavedSearchResponse getting current saved searches")
                console.dir(getSavedSearchResponse)

                setSavedSearchList(getSavedSearchResponse.saved_research_queries)
                setUserObjectDetails(getSavedSearchResponse)

                const getVIPSearchFeedParams = {
                    method: 'GET',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }          
                ;(async () => {
                    try {
                        let getVIPSearchFeedURL = `api/v1/research/savedsearch/`
                        const getVIPSearchFeedResponse = await fetchData(getVIPSearchFeedURL, "could not receive current searchfeed searches", getVIPSearchFeedParams).promise
                        console.log("getVIPSearchFeedResponse getting current searchfeed searches")
                        console.dir(getVIPSearchFeedResponse)

                        //need to filter vipsearchfeed to only searches by this user
                        var userFilteredSearchFeedList = getVIPSearchFeedResponse.results.filter( searchFeedItem => searchFeedItem.user === getSavedSearchResponse.user.username)
            
                        console.log("userFilteredSearchFeedList is")
                        console.dir(userFilteredSearchFeedList)
                        setFilteredVIPSearchFeedList(userFilteredSearchFeedList)

                           
                        setIsLoading(false)

                    } catch (error) {
                        console.log("something is broke receiving current searchfeed searches: ", error)
                    }
                })()            


            } catch (error) {
                console.log("something is broke receiving current saved searches: ", error)
            }

        })()              
    
    }, [])

    /* querystring
            marketcap__lte: dataFromEndpoint.results.market_cap_max,
            marketcap__gte: dataFromEndpoint.results.market_cap_min,
            followers__gte: dataFromEndpoint.results.follower_min,
            followers__lte: dataFromEndpoint.results.follower_max,
            btc_24h_dif__gte: dataFromEndpoint.results.vs_btc_min,
            btc_24h_dif__lte: dataFromEndpoint.results.vs_btc_max,
            eth_24h_dif__gte: dataFromEndpoint.results.vs_eth_min,
            eth_24h_dif__lte: dataFromEndpoint.results.vs_eth_max, 
            search: '',
            categories: selectedCategories,    

        startingValues:
                market_cap_min: 1000,
                market_cap_max: 100000000,
                follower_min: 1000,
                follower_max: 1000000,
                vs_btc_min: -14,
                vs_btc_max: 15,
                vs_eth_min: -15,
                vs_eth_max: 15,        

    */
    var displayQuery = {}
    var customizationCount = 0

    displayQuery['searchTerm'] = 'no text query term'
    if (queryString.search && queryString.search.length > 0) {
        displayQuery['searchTerm'] = queryString.search
        customizationCount += 1
    } else if (postSearchTerm && postSearchTerm.length > 0) {
        console.log("using postSearchTerm in query")
        displayQuery['searchTerm'] = postSearchTerm
        queryString.search = postSearchTerm
        customizationCount += 1
    }

    displayQuery['marketCapMin'] = "no minimum"
    if (startingValues.min_market_cap !== queryString.marketcap__gte) {
        displayQuery['marketCapMin'] = "greater than $" + queryString.marketcap__gte.toLocaleString()
        customizationCount += 1
    }
    displayQuery['marketCapMax'] = "no maximum"
    if (startingValues.max_market_cap !== queryString.marketcap__lte) {
        displayQuery['marketCapMax'] = "less than $" + queryString.marketcap__lte.toLocaleString()
        customizationCount += 1
    }


    displayQuery['followerMin'] = "no minimum"
    if (startingValues.min_followers !== queryString.followers__gte) {
        displayQuery['followerMin'] = "greater than " + queryString.followers__gte.toLocaleString()
        customizationCount += 1
    }

    displayQuery['followerMax'] = "no maximum"
    if (startingValues.max_followers !== queryString.followers__lte) {
        displayQuery['followerMax'] = "less than " + queryString.followers__lte.toLocaleString()
        customizationCount += 1
    }


    displayQuery['vsBTCMin'] = "no minimum"
    if (startingValues.vs_btc_min !== queryString.btc_24h_dif__gte) {
        displayQuery['vsBTCMin'] = "greater than " + queryString.btc_24h_dif__gte
        customizationCount += 1
    }
    displayQuery['vsBTCMax'] = "no maximum"
    if (startingValues.vs_btc_max !== queryString.btc_24h_dif__lte) {
        displayQuery['vsBTCMax'] = "less than " + queryString.btc_24h_dif__lte
        customizationCount += 1
    }    

    displayQuery['vsETHMin'] = "no minimum"
    if (startingValues.vs_eth_min !== queryString.eth_24h_dif__gte) {
        displayQuery['vsETHMin'] = "greater than " + queryString.eth_24h_dif__gte
        customizationCount += 1
    }
    displayQuery['vsETHMax'] = "no maximum"
    if (startingValues.vs_eth_max !== queryString.eth_24h_dif__lte) {
        displayQuery['vsETHMax'] = "less than " + queryString.eth_24h_dif__lte
        customizationCount += 1
    }        

    //displayQuery['categories'] = 'all categories'
    //if (queryString.categories.length !== 0) {
    //    queryString.categories.join(', ')
    //    customizationCount += 1
    //}

    displayQuery['categories__name__in'] = 'all categories'
    //console.log("queryString.categories__name__in is:")
    //console.dir(queryString.categories__name__in)
    if (queryString.categories__name__in && queryString.categories__name__in.length !== 0) {
        if (Array.isArray(queryString.categories__name__in)) {
            //came as an array...display as a string
            //console.log("display['category_name_in'] is an array...join")
            displayQuery['categories__name__in'] = queryString.categories__name__in.join(', ')
        } else {
            //came as a string....leave as a string
            //console.log("display['category_name_in'] is a string")
            if ((queryString.categories__name__in.startsWith('[')) && (queryString.categories__name__in.endsWith(']'))) {
                //no need to strip...just a single category
                //console.log("display['category_name_in'] starts and ends with brackets...strip them")
                displayQuery['categories__name__in'] = queryString.categories__name__in.slice(1, -1).replace(',', ', ')
                
            } else {
                //category array in string mode...docter it
                //console.log("display['category_name_in'] does not begin/end in brackets...leave it")
                displayQuery['categories__name__in'] = queryString.categories__name__in
            }
        }
        customizationCount += 1
    }    

    console.log("---------------------displayQuery is:")
    console.dir(displayQuery)

    const handleSavedSearchLabelChange = (e) => {
        //console.log("top of handleSavedSearchLabelChange and e.target.value is: " + e.target.value)
        setSavedSearchLabel(e.target.value)
    }

    const saveThisSearch = () => {
        console.log("top of saveThisSearch and customizationCount is:")
        console.dir(customizationCount)
        console.log("savedSearchLabel is:")
        console.dir(savedSearchLabel)
        console.log("queryString is:")
        console.dir(queryString)
        if (customizationCount === 0 || savedSearchLabel.length === 0) {
            //there are no search criteria so no reason to save this search
        } else {
            //it's good...save it
            let savedSearchString = ''
            if (queryString.marketcap__gte !== startingValues.min_market_cap && queryString.marketcap__gte !== 0) {
                savedSearchString += 'marketcap__gte='+queryString.marketcap__gte
            }
            if (queryString.marketcap__lte !== startingValues.max_market_cap && queryString.marketcap__lte !== 0) {
                if (savedSearchString.length > 0) savedSearchString += '&'
                savedSearchString += 'marketcap__lte='+queryString.marketcap__lte
            }  
    
            if (queryString.followers__gte !== startingValues.min_followers && queryString.followers__gte !== 0) {
                if (savedSearchString.length > 0) savedSearchString += '&'
                savedSearchString += 'followers__gte='+queryString.followers__gte
            }
            if (queryString.followers__lte !== startingValues.max_followers && queryString.followers__lte !== 0) {
                if (savedSearchString.length > 0) savedSearchString += '&'
                savedSearchString += 'followers__lte='+queryString.followers__lte
            }   
    
            if (queryString.btc_24h_dif__gte !== startingValues.vs_btc_min && queryString.btc_24h_dif__gte !== 0) {
                if (savedSearchString.length > 0) savedSearchString += '&'
                savedSearchString += 'btc_24h_dif__gte='+queryString.btc_24h_dif__gte
            }
            if (queryString.btc_24h_dif__lte !== startingValues.vs_btc_max && queryString.btc_24h_dif__lte !== 0) {
                if (savedSearchString.length > 0) savedSearchString += '&'
                savedSearchString += 'btc_24h_dif__lte='+queryString.btc_24h_dif__lte
            }         
    
            if (queryString.eth_24h_dif__gte !== startingValues.vs_eth_min && queryString.eth_24h_dif__gte !== 0) {
                if (savedSearchString.length > 0) savedSearchString += '&'
                savedSearchString += 'eth_24h_dif__gte='+queryString.eth_24h_dif__gte
            }
            if (queryString.eth_24h_dif__lte !== startingValues.vs_eth_max && queryString.eth_24h_dif__lte !== 0) {
                if (savedSearchString.length > 0) savedSearchString += '&'
                savedSearchString += 'eth_24h_dif__lte='+queryString.eth_24h_dif__lte
            }          
            //console.log("queryString.search is: " + queryString.search)
            if (queryString.search !== '' && queryString.search !== undefined) {
                if (savedSearchString.length > 0) savedSearchString += '&'
                savedSearchString += 'search='+queryString.search
            }
    
            if (queryString.category.length > 0) {
                //this is a string through out this component...just need to put it back as is to save
                //console.log("while saving queryString.category is:")
                //console.dir(queryString.category)
                if (savedSearchString.length > 0) savedSearchString += '&'
                if (Array.isArray(queryString.category)) {
                    savedSearchString += 'category=[' + queryString.category.join(',') + ']'
                } else {
                    if ((queryString.category.startsWith('[')) && (queryString.category.endsWith(']'))) {
                        savedSearchString += 'category=' + queryString.category + ''
                    } else {
                        savedSearchString += 'category=[' + queryString.category + ']'
                    }
                }

            }


            savedSearchString = savedSearchLabel + '||' + savedSearchString

            let newSavedSearchList = [...savedSearchList, savedSearchString]

            //console.log("newSavedSearchList is: ")
            //console.dir(newSavedSearchList)

            setSavedSearchList(newSavedSearchList)

            let stringified  = JSON.stringify({'saved_research_queries': newSavedSearchList })
            //console.log("stringified: ")
            //console.dir(stringified)
            //put - coin, twitter handle, saved searches
            const putSavedSearchParams = {
                method: 'PUT',
                dataType: 'json',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    //'coin': userObject.coin,
                    //'user': userObject.user,
                    //'twitter_friend_list': userObject.twitter_friend_list,
                    'saved_research_queries': newSavedSearchList
                  }),
            }          
            ;(async () => {
              try {
                let putSavedSearchURL = `api/v1/users/userdata/`
                var putSavedSearchResponse = await fetchData(putSavedSearchURL, "could not put new saved search", putSavedSearchParams).promise
                //console.log("putSavedSearchResponse putting new saved search")
                //console.dir(putSavedSearchResponse)
              } catch (error) {
                  console.log("something is broke putting new saved search: ", error)
              }
            })()              
            setSavedSearchLabel('')
        }
    }

    const removeSavedSearch = (removeThisSearch,vipKeywordID) => {
        //removeThisSearch is the array id for the saved search to be removed
        console.log("====================================================top of removeSavedSearch removing: " + removeThisSearch)
        console.log("removeThisSearch is:")
        console.dir(removeThisSearch)
        console.log("vipKeywordID is: ")
        console.dir(vipKeywordID)
        //console.log("filteredVIPSearchFeedList is:")
        //console.dir(filteredVIPSearchFeedList)

        let searchToDelete = savedSearchList.filter( (search, index, array) => removeThisSearch === index)
        let searchToDeleteName = searchToDelete[0].split('||')[0]
        let searchToDeleteQuery = searchToDelete[0].split('||')[1]
        //console.log("searchToDeleteName is:")
        //console.dir(searchToDeleteName)
        //console.log("searchToDeleteQuery is:")
        //console.dir(searchToDeleteQuery)

        //need to get the id of the search to delete
        let savedSearchToDelete = filteredVIPSearchFeedList.filter(search => {
            //console.log("search to check against is:")
            //console.dir(search)
            return search.name === searchToDeleteName && search.saved_research_query === searchToDeleteQuery
        })
        //console.log("savedSearchToDelete is:")
        //console.dir(savedSearchToDelete)

        if (Array.isArray(savedSearchToDelete) && savedSearchToDelete.length > 0) {
            let savedSearchToDeleteID = savedSearchToDelete[0].id

            ;(async () => {
                const deleteSavedSearchParams = {
                    method: 'DELETE',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'search_id': vipKeywordID
                    }),                     
                   
                }                  
                try {
                    let deleteSavedSearchURL = `api/v1/research/postsearch/`
                    var deleteSavedSearchResponse = await fetchData(deleteSavedSearchURL, "could not put new saved search", deleteSavedSearchParams).promise
                    console.log("deleteSavedSearchResponse deleted saved search")
                    console.dir(deleteSavedSearchResponse)
                    console.log("deleteSavedSearchURL is: " + deleteSavedSearchURL)
                } catch (error) {
                    console.log("something is broke deleting saved search: ", error)
                }
            })()    
        }


        //let searchRemoved = savedSearchList.splice(removeThisSearch, 1) //did not work for some reason
        let searchFiltered = savedSearchList.filter( (search, index, array) => removeThisSearch !== index)
        //console.log("searchFiltered is:")
        //console.dir(searchFiltered)

        setSavedSearchList(searchFiltered)

        const putSavedSearchParams = {
            method: 'PUT',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                //'user': userObject.user,
                'saved_research_queries': searchFiltered
              }),
        }          
        ;(async () => {
          try {
            let putSavedSearchURL = `api/v1/users/userdata/`
            var putSavedSearchResponse = await fetchData(putSavedSearchURL, "could not put new saved search", putSavedSearchParams).promise
            //console.log("putSavedSearchResponse putting new saved search")
            //console.dir(putSavedSearchResponse)

            //need to get id of this saved search 

            if (Array.isArray(vipKeywordID) && vipKeywordID.length > 0) (
                (async () => {
                    const deleteVIPKeywordParams = {
                        method: 'DELETE',
                        dataType: 'json',
                        headers: {
                        'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            'keyword_id': vipKeywordID
                        }),                         

                    }                  
                    try {
                    let deleteVIPKeywordURL = `api/v1/research/postkeywords/`
                    var deleteVIPKeywordResponse = await fetchData(deleteVIPKeywordURL, "could not put new saved search", deleteVIPKeywordParams).promise
                    //console.log("deleteVIPKeywordResponse putting new saved search")
                    //console.dir(deleteVIPKeywordResponse)
                    } catch (error) {
                        console.log("something is broke deleting vip keyword: ", error)
                    }
                })() 
            )

          } catch (error) {
              console.log("something is broke putting new saved search: ", error)
          }
        })()         

    }    

    const sendSearchToVip = (sendThisSearch) => {
        //removeThisSearch is the array id for the saved search to be removed
        console.log("top of sendSearchToVip sending: " + sendThisSearch)
        //console.log("filteredVIPSearchFeedList before is:")
        //console.dir(filteredVIPSearchFeedList)

        //get the saved search to be pushed to vip alert
        var searchToSave = savedSearchList.filter( (search, index) => index === sendThisSearch)
        console.log("searchToSave is: ")
        console.dir(searchToSave)
        var searchToSaveArray = searchToSave[0].split('||')
        //console.log("searchToSaveArray[0] is: " + searchToSaveArray[0])
        //console.log("searchToSaveArray[1] is: " + searchToSaveArray[1])
        //console.log("userObjectDetails is ")
        //console.dir(userObjectDetails)

        var postObject = {
            'user': userObjectDetails.user.username,
            'name': searchToSaveArray[0],
            'saved_research_query': searchToSaveArray[1],
            'description': ''
        }

        console.log("postObject is:")
        console.dir(postObject)

        const postVIPSearchFeedParams = {
            method: 'POST',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postObject),
        }          
        ;(async () => {
          try {
            let postVIPSearchFeedURL = `api/v1/research/postsearch/`
            var postVIPSearchFeedResponse = await fetchData(postVIPSearchFeedURL, "could not post new vip hot search", postVIPSearchFeedParams).promise
            console.log("postVIPSearchFeedResponse putting new saved search")
            console.log("postVIPSearchFeedURL is: " + postVIPSearchFeedURL)
            console.dir(postVIPSearchFeedResponse)
            console.log("postVIPSearchFeedParams is: ")
            console.dir(postVIPSearchFeedParams)

            setTimeout( function () {
                const getSavedSearchesParams = {
                    method: 'GET',
                    dataType: 'json',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                }                  
                ;(async () => {
                    try {
                        let getVIPSearchFeedURL = `api/v1/research/savedsearch/`
                        var getVIPSearchFeedResponse = await fetchData(getVIPSearchFeedURL, "could not get vip hot search", getSavedSearchesParams).promise
                        console.log("getVIPSearchFeedResponse getting saved searches")
                        console.log("getVIPSearchFeedURL is: " + getVIPSearchFeedURL)
                        console.dir(getVIPSearchFeedResponse)

                        //need to get ONLY the saved searches for this user
                        let currentUsersSavedSearches = getVIPSearchFeedResponse.results.filter( currentSavedSearch => currentSavedSearch.user === userObject.user.username )

                        setFilteredVIPSearchFeedList(currentUsersSavedSearches)
                    } catch (error) {
                        console.log("something is broke getting vip hot search: ", error)
                    }
                })() 
            }, 2000)
          } catch (error) {
              console.log("something is broke posting new vip hot search: ", error)
          }
        })()   
    }

    const addSearchToVIPKeywords = (searchTermToAdd) => {
        let contains = savedSearchList.filter((savedSearch, index) => {
            
            return displayQuery['searchTerm'] === savedSearch.saved_research_query;
        })
        if(contains.length){
            return null
        }
        //console.log("top of addSearchToVIPKeywords and vipTrackedKeywords is:")
        //console.dir(vipTrackedKeywords)
        //console.log("and searchTermToAdd.length is::::::::::::::::::::::::::::::::::::::::::::::::::::::::: " + searchTermToAdd.length)
        //console.log("searchTermToAdd is:")
        //console.dir(searchTermToAdd)
        if (searchTermToAdd.length > 0 && searchTermToAdd !== "no text query term") {
            if (userObject.user.is_vip) {
                let isSearchedTracked = vipTrackedKeywords.filter(  keywordToCheck => keywordToCheck.keyword === searchTermToAdd)
                //console.log("isSearchedTracked is:")
                //console.dir(isSearchedTracked)
                if (isSearchedTracked.length > 0) {                
                    //already tracked
                    //console.log("nothing will be sent to sendSearchTermToVipTrackedKeywords as already in vipTrackedKeywords")
                    return (
                        <Tooltip title={searchTermToAdd + " is already in the VIP Tracked Keyword List as part of another search."}>
                            <IconButton
                                size="small"
                            >
                                <TiArrowForward 
                                    sx={{fontSize: '1.2rem'}} 
                                    color={theme.palette.primary.dark}
                                    size={22}
                                />
                            </IconButton>  
                        </Tooltip>                           
                    )
                } else {
                    //console.log("what will be sent is the sendSearchTermToVipTrackedKeywords is: " + searchTermToAdd)
                    return (
                        <Tooltip title={"Send " + searchTermToAdd + " search term to VIP Tracked Keywords List"}>
                            <IconButton
                                size="small"
                                onClick={() => sendSearchTermToVipTrackedKeywords(searchTermToAdd)} 
                            >
                                <TiArrowForward 
                                    sx={{fontSize: '1.2rem'}} 
                                    color={theme.palette.primary.light}
                                    size={22}
                                />
                            </IconButton>  
                        </Tooltip>              
                    )                
                }
            } else {
                //there is no search term within the search
                //console.log("this user is not a vip")
                return null
            }     
        } else {
            //console.log("there is no search term to compare add/compare to viptracked keywords")
            return null
        }
    }


    console.log("just before return ===========================================")
    console.log("savedSearchList is:")
    console.dir(savedSearchList)
    console.log("displayQuery is:")
    console.dir(displayQuery)

    return (
        
        <Box sx={{marginTop: '15px', marginLeft: '20px', width: '100%'}}>
            <Typography variant="h3" sx={{marginTop: '20px'}}>Current Searches</Typography>
            <Box sx={{marginLeft: '20px'}}>
                <Grid container>
                { isLoading ? <CircularProgress /> :
                    savedSearchList.length === 0 ? <Grid item xs={12} ><Typography sx={{color: theme.palette.primary.main}}>no saved searches</Typography></Grid> : 
                        savedSearchList.map( (savedSearch, searchIndex) => {
                            return (
                                <ResearchSearchSavedItem
                                    sendSearchTermToVipTrackedKeywords={sendSearchTermToVipTrackedKeywords}
                                    deleteSearchTermToVipTrackedKeywords={deleteSearchTermToVipTrackedKeywords}
                                    vipTrackedKeywords={vipTrackedKeywords}
                                    userObject={userObject}
                                    savedSearch={savedSearch}
                                    removeSavedSearch={removeSavedSearch}
                                    searchIndex={searchIndex}
                                    sendSearchToVip={sendSearchToVip}
                                    filteredVIPSearchFeedList={filteredVIPSearchFeedList}
                                />
                            )
                        })
                }         
                </Grid>                           
            </Box>
            <Typography sx={{marginTop: '20px'}} variant="h3" >Current Search</Typography>            
            <Box sx={{width: '100%'}}>
                <Stack direction="column" display="flex" justifyContent="flex-start">
                    <Typography variant='h4' sx={{paddingTop: '10px'}}>Search Term</Typography>
                    <Stack direction="row" display="flex" alignItems="center">
                        <Typography sx={{marginLeft: '20px', color: theme.palette.primary.main}}>{displayQuery.searchTerm}</Typography>
                        {savedSearchList.length > 0 ? addSearchToVIPKeywords(displayQuery['searchTerm']) : null}
                    </Stack>
                </Stack>
            </Box>
            <Box sx={{width: '100%'}}>
                <Stack direction="column" display="flex" justifyContent="flex-start">
                    <Typography variant='h4' sx={{paddingTop: '10px'}}>Market Cap</Typography>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" sx={{marginLeft: '20px', color: theme.palette.primary.main}}>
                        <Typography>{displayQuery['marketCapMin']}</Typography>
                        <Typography sx={{marginLeft: '5px'}}>and</Typography>
                        <Typography sx={{marginLeft: '5px'}}>{displayQuery['marketCapMax']}</Typography>
                    </Stack>
                </Stack>
            </Box>      

            <Box sx={{width: '100%'}}>
                <Stack direction="column" display="flex" justifyContent="flex-start">
                    <Typography variant='h4' sx={{paddingTop: '10px'}}>Followers</Typography>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" sx={{marginLeft: '20px', color: theme.palette.primary.main}}>
                        <Typography>{displayQuery['followerMin']}</Typography>
                        <Typography sx={{marginLeft: '5px'}}>and</Typography>
                        <Typography sx={{marginLeft: '5px'}}>{displayQuery['followerMax']}</Typography>
                    </Stack>
                </Stack>
            </Box>                   
   
            <Box sx={{width: '100%'}}>
                <Stack direction="column" display="flex" justifyContent="flex-start">
                    <Typography variant='h4' sx={{paddingTop: '10px'}}>vs BTC</Typography>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" sx={{marginLeft: '20px', color: theme.palette.primary.main}}>
                        <Typography>{displayQuery['vsBTCMin']}</Typography>
                        <Typography sx={{marginLeft: '5px'}}>and</Typography>
                        <Typography sx={{marginLeft: '5px'}}>{displayQuery['vsBTCMax']}</Typography>
                    </Stack>
                </Stack>
            </Box>    

            <Box sx={{width: '100%'}}>
                <Stack direction="column" display="flex" justifyContent="flex-start">
                    <Typography variant='h4' sx={{paddingTop: '10px'}}>vs ETH</Typography>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" sx={{marginLeft: '20px', color: theme.palette.primary.main}}>
                        <Typography>{displayQuery['vsETHMin']}</Typography>
                        <Typography sx={{marginLeft: '5px'}}>and</Typography>
                        <Typography sx={{marginLeft: '5px'}}>{displayQuery['vsETHMax']}</Typography>
                    </Stack>
                </Stack>
            </Box>                
            
            <Box sx={{width: '100%'}}>
                <Stack direction="column" display="flex" justifyContent="flex-start">
                    <Typography variant='h4' sx={{paddingTop: '10px'}}>within Category(s)</Typography>
                    <Typography sx={{marginLeft: '20px', color: theme.palette.primary.main}} >{displayQuery['categories__name__in']}</Typography>
                </Stack>
            </Box>
            
                { customizationCount === 0 ? <Typography sx={{marginTop: '20px', color: theme.palette.error.main}}>There are no custom criteria to this search to save it.  Please change some criteria and return to save it.</Typography> :
                    <Box sx={{width: '100%'}}>
                        <Stack direction="row" sx={{width: '100%'}} display="flex" alignItems="center" justifyContent="space-evenly">
                            <TextField 
                                id="saved-search-label" 
                                label="Saved Search Label" 
                                variant="outlined"
                                value={savedSearchLabel} 
                                onChange={handleSavedSearchLabelChange}
                                size="small"
                                sx={{
                                    width: '100%', 
                                    minWidth: '110px',
                                    color: theme.palette.primary.dark, 
                                    input: {
                                        background: theme.palette.grey[100],
                                    },
                                }}
                                InputLabelProps={{
                                    style: { 
                                        color: theme.palette.primary.dark ,
                                        position: 'relative',
                                        top: '17px'
                                    },
                                }}
                                inputProps={{
                                    style: { height: '12px' }
                                }}
                                
                            />          
                            <Button onClick={() => saveThisSearch()} size="small" variant="contained" sx={{position: 'relative', top: '10px', marginLeft: '10px'}}>Save</Button>              
                        </Stack>
                    </Box>
                }
            
        </Box>

    )

}

export default ResearchSearchDisplayCurrentSearch