import React, {useState, useEffect} from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Box, CssBaseline, CircularProgress, Container, Typography, Grid } from '@mui/material'

import fetchData from '../../utility/endpointFetch'
import ScreenerCurrencyListItem from './ScreenerCurrencyListItem'
import ScreenerCurrencyHeader from './ScreenerCurrencyHeader'
import Footer from '../Footer'
import MyCurrenciesFilterRow from './MyCurrenciesFilterRow'
import TopAppBar from '../TopAppBar'
import ScreenerList from './ScreenerList'
import { checkArraysEqual } from '../../utility/objHelper'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
      }),
      [theme.breakpoints.up('md')]: {
          marginLeft: '20px',
          width: `calc(100% - 20px)`
      },
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px',
          width: `calc(100% - 20px)`,
          padding: '16px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px',
          width: `calc(100% - 20px)`,
          padding: '16px',
          marginRight: '10px'
      }
  }),
  ...(open && {
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - 20px)`,
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px'
      }
  })
}));

const MyCurrencies = ( {userObject } ) => {
  //console.log("top of MyCurrencies")
  //console.dir(userObject)

  const theme = useTheme()

  const [screenerCoinList,setScreenerCoinList] = useState([])
  const [ecoinScreenerDetailsList,setEcoinScreenerDetailsList] = useState([])
  const [responseScreenerCoinList,setResponseScreenerCoinList] = useState([])
  const [isLoading,setIsLoading] = useState(true)
  const [currentTimeFrame,setCurrentTimeFrame] = useState(1)
  const [localUserObject,setLocalUserObject] = useState(userObject)

  const [selectedScreener,setSelectedScreener] = useState('Favorites')
  const [sortingBy,setSortingBy] = useState('rank-highest')
  const [sortByColumnDirection, setSortByColumnDirection] = useState('down')
  const [sortByUrl,setSortByUrl] = useState('&ordering=market_cap_rank')
  const [sortByColumn,setSortByColumn] = useState('rank')
  const [isWindowSmall, setIsWindowSmall] = useState(window.innerWidth < 1450)
  const [visibleColumns, setVisibleColumns] = useState({
        'Current Price': true,
        '24h High': true,
        '24h Low': true,
        '24h Volume': true,
        'Market Cap': true,
        'BTC Dif': true,
        'ETH Dif': true,
        'Market Cap Rank': true,
        'Social Rank': false,
        'Total Social Engagement': false,
        //'5 Min Price Change': false,
        //'15 Min Price Change': false,
        "1 Hour Price Change": false,
        //"4 Hour Price Change": false,
        "1 Week Price Change": false,
        "2 Week Price Change": false,
        "Daily Influencer Posts": false,
        //"24 Hour Follower Change": false,
        //"1 Month Follower Change": false,
        //"1 Year Follower Change": false,
        //"24 Hour Post Volume Change": false,
        //"1 Week Post Volume Change": false,
        //"1 Month Post Volume Change": false,
        //"3 Month Post Volume Change": false,
        //"1 Year Post Volume Change": false,
        //"1 Year Post Volume Average": false,
        //"1 Month Post Volume Average": false,
        //"3 Month Post Volume Average": false,
    })
  const updateWindowSize = () => {
      setIsWindowSmall(window.innerWidth < 1450)
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    return () => window.removeEventListener('resize', updateWindowSize)
  })

  const changeScreener = (newScreener) => {
    console.log("top of changeScreener")
    console.log("selectedScreener is: " + selectedScreener)
    console.log("change to: " + newScreener)
    setSelectedScreener(newScreener)
  }

  const updateUserDataCoin = (newUserObjectCoins) => {
    //console.log("top of updateUserDataCoin and newUserObjectCoins is: ")
    //console.dir(newUserObjectCoins)
    //console.log("localUserObject is:")
    //console.dir(localUserObject)
    var fetchParams = {
      method: 'PUT',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'coin': newUserObjectCoins,
        'user': localUserObject.user,
        'twitter_friend_list': localUserObject.twitter_friend_list
      })
    }
    ;(async () => {
      try {
        const updateUserDataCoinResponse = await fetchData(`api/v1/users/userdata/`, "could not update coin in user data in watchlists in mycurrencies", fetchParams).promise
        //console.log("updateUserDataCoinResponse updating coin in  user data in watchlists")
        //console.dir(updateUserDataCoinResponse) 
        //console.dir(fetchParams)
      } catch (error) {
          console.log("could not update coin in user data in watchlist in MyCurrencies: " + error )
      }
    })()         
  }

  const getScreenerCoins = () => {
    //get the coins from the currently selected list
    //console.log("selectedScreener is: " + selectedScreener)
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        var fetchScreenerCoinsURL = `api/v1/users/screener/?screener_name=${selectedScreener}`
        const screenerCoinsresponse = await fetchData(fetchScreenerCoinsURL, "could not receive screener coin data", fetchParams).promise
        console.log("screenerCoinsresponse getting screener coins data in mycurrencies")
        console.log("fetchScreenerCoinsURL is: " + fetchScreenerCoinsURL)
        console.dir(screenerCoinsresponse)
        setResponseScreenerCoinList(screenerCoinsresponse.results)
        if (screenerCoinsresponse.results.length > 0) {
          //get all coin id within this result into one array to add to coinmarket chart url selection

          let coinsInList = screenerCoinsresponse.results.map( (coinDetails) => { return coinDetails.coin_id } )
          console.log("coinsInList before duplicates removed is: ")
          console.dir(coinsInList)
          console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxsetScreenerCoinList within getScreenerCoins is going to be set to:------------------------- ")
          setScreenerCoinList(screenerCoinsresponse.results)
          //coinsInList = [...new Set(coinsInList)]
          //console.log("coinsInList after duplicates removed is: ")
          //console.dir(coinsInList)

          ;(async () => {
            try {
              let myCoinsScreenerResponseURL = `api/v1/coin/ecoinscreener/?coins=${coinsInList.join(",")}${sortByUrl}`
              const myCoinsScreenerResponse = await fetchData(myCoinsScreenerResponseURL, "could not receive coin screener data in my currencies", fetchParams).promise
              console.log("myCoinsScreenerResponse getting coinscreener data in my coins")
              console.dir(myCoinsScreenerResponse)
              console.log(myCoinsScreenerResponseURL)
  
              setEcoinScreenerDetailsList(myCoinsScreenerResponse.results)
      
              setIsLoading(false)
            } catch (error) {
              console.log("could not receive coin/ecoinscreener data in MyCurrencies: " + error)
            }    
          })()        
        } else {
          //not sure if this loop needed...but
          console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxsetScreenerCoinList within getScreenerCoins is going to be set to:------------------------- NOTHING")
          setScreenerCoinList([])
          setEcoinScreenerDetailsList([])
        }
        setIsLoading(false)

      } catch (error) {
        console.log("something is broke getting screener coins in MyCurrencies: " + error )
      }
    })()
  }  

  useEffect(() => {
    //console.log("top of useEffect selectedScreener changed to: " + selectedScreener)

    setIsLoading(true)
    getScreenerCoins()
    if (selectedScreener.length === 0) {
      //the selectedScreener is an empty object so there is no screener set
      //console.log("There is no selectedScreener")
      console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxsetScreenerCoinList within getScreenerCoins is going to be set to:------------------------- nothing in useEffect of selectedScreener and sortByUrl")
      setScreenerCoinList([])
      setIsLoading(false)
    } else {
      getScreenerCoins()
      const intervalID = setInterval(() => getScreenerCoins(), 60000);
      return () => clearInterval(intervalID)        
    }
  
  }, [selectedScreener, sortByUrl])

  const changeChartTimeFrame = (clickedTime) => {
    //console.log("top of changeChartTimeFrame and clickedTime is: " + clickedTime + " / " +currentTimeFrame)
    if (currentTimeFrame !== clickedTime) {
      //console.log("changing currentTimeFrame to: " + clickedTime)
      setCurrentTimeFrame(clickedTime)
    } else {
      console.log("NOT changing currentTimeFrame to: " + clickedTime)
      return null
    }
  }

  const deleteFromScreener = (coinId, removeScreenerCoinId) => {
    console.log("..................................top of deleteFromScreener in MyCurrencies and screenerCoinList  is:")
    console.dir(screenerCoinList)
    console.log("coinId is: " + coinId)
    console.log("removeScreenerCoinId is: " + removeScreenerCoinId)
    //remove from screenerCoinList
    //need to edit the screener itself 
    //remove from screenerCoinList

    let reducedScreenerCoinList = screenerCoinList.map(thisCoin => thisCoin.coin_id)
    let newScreenerCoinList = screenerCoinList.filter( thisCoin => thisCoin.coin_id !== coinId)
    let reducedNewScreenerCoinList = newScreenerCoinList.map( thisCoin => thisCoin.coin_id)
    
    if (checkArraysEqual(reducedScreenerCoinList, localUserObject.coin)) {
      //the current screener is the currently selected screener for vip....update userObject.coin
      //console.log("reducedScreenerCoinList and localUserObject.coin are NOT the same....update userObect.coin")
      updateUserDataCoin(reducedNewScreenerCoinList)
    }
       
    console.log("newScreenerCoinList is")
    console.dir(newScreenerCoinList)

    const fetchParams = {
      method: 'DELETE',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'coin_id': coinId
      })
    }
    ;(async () => {
      try {
        let removeCoinFromScreenerURL = `api/v1/users/screenercoinupdate/${removeScreenerCoinId}/`
        const removeCoinFromScreenerResponse = await fetchData(removeCoinFromScreenerURL, "could not remove coin from screener in mycurrencies", fetchParams).promise
        console.log("removeCoinFromScreenerResponse removing coin from screener in mycurrencies")
        console.dir(removeCoinFromScreenerResponse)
        console.log(removeCoinFromScreenerURL)
        console.dir(fetchParams)

        if (selectedScreener === "Favorites") {
          //this coin needs to be added to the favorites field of the userdata so it will trigger alerts in vip
          let saveFavoritesParams = {
            method: 'PUT',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'user': userObject.user,
              'favorites': reducedNewScreenerCoinList
            })
          }
          ;(async () => {
            const saveFavoritesResponse = await fetchData(`api/v1/users/userdata/`, "could not save favorites to user data", saveFavoritesParams).promise
            //console.log("saveFavoritesResponse saving favorites to user data")
            //console.dir(saveFavoritesResponse) 
          })()                      
        }        
        getScreenerCoins()

      } catch (error) {
        console.log("something is broke saving coin to screener in mycurrencies: %s", error)
      }
    })()

    console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxsetScreenerCoinList within deleteFromScreener is going to be set to:------------------------- ")
    console.dir(newScreenerCoinList)
    setScreenerCoinList(newScreenerCoinList)
    setLocalUserObject(prevState => ({...prevState, 'coin': reducedNewScreenerCoinList}))

    //if currently selected screener update the userObject.coin also
   
  }

    const sortResults = (sortBy) => {
      //console.log(sortBy)
        //console.log("top of sortResults: " + sortBy)
        setSortingBy(sortBy)
        switch(sortBy) {
            case 'rank-highest':
                //setCurrencyList( results.sort( (a,b) => (a.market_cap_rank > b.market_cap_rank) ? 1  : -1))
                setSortByUrl("&ordering=coinscreener__coin_market__market_cap_rank")
                setSortByColumn('rank')
                setSortByColumnDirection('down')
                break
            case 'rank-lowest':
                //setCurrencyList( results.sort( (a,b) => (a.market_cap_rank < b.market_cap_rank) ? 1 : -1))
                setSortByColumn('rank')
                setSortByUrl("&ordering=-coinscreener__coin_market__market_cap_rank")
                setSortByColumnDirection('up')
                break
            case 'name-alphabetical':
                //setCurrencyList( results.sort( (a,b) => (a.name > b.name) ? 1 : -1))
                setSortByColumn('name')
                setSortByUrl("&ordering=coinscreener__coin_market__name")
                setSortByColumnDirection('down')
                break
            case 'name-alphabetical-reverse':
                //setCurrencyList( results.sort( (a,b) => (a.name < b.name) ? 1 : -1))
                setSortByColumn('name')
                setSortByUrl("&ordering=-coinscreener__coin_market__name")
                setSortByColumnDirection('up')
                break
            case 'symbol-alphabetical':
                //setCurrencyList( results.sort( (a,b) => (a.coin.symbol > b.coin.symbol) ? 1 : -1))
                setSortByColumn('symbol')
                setSortByUrl("&ordering=coinscreener__coin_market__coin__symbol")
                setSortByColumnDirection('down')
                break
            case 'symbol-alphabetical-reverse':
                //setCurrencyList( results.sort( (a,b) => (a.coin.symbol < b.coin.symbol) ? 1 : -1))
                setSortByColumn('symbol')
                setSortByUrl("&ordering=-coinscreener__coin_market__coin__symbol")
                setSortByColumnDirection('up')
                break
            case 'price-highest':
                //setCurrencyList( results.sort( (a,b) => (a.current_price > b.current_price) ? 1 : -1))
                setSortByColumn('price')
                setSortByUrl("&ordering=-coinscreener__current_price")
                setSortByColumnDirection('down')
                break
            case 'price-lowest':
                //setCurrencyList( results.sort( (a,b) => (a.current_price < b.current_price) ? 1 : -1))
                setSortByColumn('price')
                setSortByUrl("&ordering=coinscreener__current_price")
                setSortByColumnDirection('up')
                break
            case 'volume-lowest':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume > b.total_volume) ? 1 : -1))
                setSortByColumn('volume')
                setSortByUrl("&ordering=-coinscreener__total_volume")
                setSortByColumnDirection('down')
                break
            case 'volume-highest':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('volume')
                setSortByUrl("&ordering=coinscreener__total_volume")
                setSortByColumnDirection('up')
                break
            case '24h_low':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('24h_low')
                setSortByUrl("&ordering=coinscreener__coin_market__low_24h")
                setSortByColumnDirection('up')
                break
            case '24h_low_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('24h_low')
                setSortByUrl("&ordering=-coinscreener__coin_market__low_24h")
                setSortByColumnDirection('down')
                break
            case '24h_high':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('24h_high')
                setSortByUrl("&ordering=coinscreener__coin_market__high_24h")
                setSortByColumnDirection('up')
                break
            case '24h_high_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('24h_high')
                setSortByUrl("&ordering=-coinscreener__coin_market__high_24h")
                setSortByColumnDirection('down')
                break
            case 'btc_dif':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('btc_dif')
                setSortByUrl("&ordering=coinscreener__btc_24h_dif")
                setSortByColumnDirection('up')
                break
            case 'btc_dif_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('btc_dif')
                setSortByUrl("&ordering=-coinscreener__btc_24h_dif")
                setSortByColumnDirection('down')
                break
            case 'eth_dif':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('eth_dif')
                setSortByUrl("&ordering=coinscreener__eth_24h_dif")
                setSortByColumnDirection('up')
                break
            case 'eth_dif_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('eth_dif')
                setSortByUrl("&ordering=-coinscreener__eth_24h_dif")
                setSortByColumnDirection('down')
                break
            case 'market_cap':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('market_cap')
                setSortByUrl("&ordering=coinscreener__market_cap")
                setSortByColumnDirection('down')
                break
            case 'market_cap_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('market_cap')
                setSortByUrl("&ordering=-coinscreener__market_cap")
                setSortByColumnDirection('up')
                break
            case 'min_5_price_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('min_5_price_change')
                setSortByUrl("&ordering=coinscreener__min_5_price_change")
                setSortByColumnDirection('down')
                break
            case 'min_5_price_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('min_5_price_change')
                setSortByUrl("&ordering=-coinscreener__min_5_price_change")
                setSortByColumnDirection('up')
                break
            case 'min_15_price_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('min_5_price_change')
                setSortByUrl("&ordering=coinscreener__min_15_price_change")
                setSortByColumnDirection('down')
                break
            case 'min_15_price_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('min_15_price_change')
                setSortByUrl("&ordering=-coinscreener__min_15_price_change")
                setSortByColumnDirection('up')
                break
            case 'social_rank':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('social_rank')
                setSortByUrl("&ordering=social_rank")
                setSortByColumnDirection('down')
                break
            case 'social_rank_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('social_rank')
                setSortByUrl("&ordering=-social_rank")
                setSortByColumnDirection('up')
                break
            case 'total_social_engagement':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('total_social_engagement')
                setSortByUrl("&ordering=total_social_engagement")
                setSortByColumnDirection('down')
                break
            case 'total_social_engagement_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('total_social_engagement')
                setSortByUrl("&ordering=-total_social_engagement")
                setSortByColumnDirection('up')
                break
            case 'hour_1_price_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('hour_1_price_change')
                setSortByUrl("&ordering=coinscreener__hour_1_price_change")
                setSortByColumnDirection('down')
                break
            case 'hour_1_price_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('hour_1_price_change')
                setSortByUrl("&ordering=-coinscreener__hour_1_price_change")
                setSortByColumnDirection('up')
                break
            case 'hour_4_price_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('hour_4_price_change')
                setSortByUrl("&ordering=coinscreener__hour_4_price_change")
                setSortByColumnDirection('down')
                break
            case 'hour_4_price_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('hour_4_price_change')
                setSortByUrl("&ordering=-coinscreener__hour_4_price_change")
                setSortByColumnDirection('up')
                break
            case 'week_1_price_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('week_1_price_change')
                setSortByUrl("&ordering=coinscreener__day_7_price_change")
                setSortByColumnDirection('down')
                break
            case 'week_1_price_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('week_1_price_change')
                setSortByUrl("&ordering=-coinscreener__day_7_price_change")
                setSortByColumnDirection('up')
                break
            case 'week_2_price_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('week_2_price_change')
                setSortByUrl("&ordering=coinscreener__day_14_price_change")
                setSortByColumnDirection('down')
                break
            case 'week_2_price_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('week_2_price_change')
                setSortByUrl("&ordering=-coinscreener__day_14_price_change")
                setSortByColumnDirection('up')
                break
            case 'daily_influencer_post_count':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('daily_influencer_post_count')
                setSortByUrl("&ordering=coinscreener__daily_influencer_post_count")
                setSortByColumnDirection('down')
                break
            case 'daily_influencer_post_count_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('daily_influencer_post_count')
                setSortByUrl("&ordering=-coinscreener__daily_influencer_post_count")
                setSortByColumnDirection('up')
                break
            case 'day_1_follower_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('day_1_follower_change')
                setSortByUrl("&ordering=day_1_follower_change")
                setSortByColumnDirection('down')
                break
            case 'day_1_follower_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('daily_influencer_post_count')
                setSortByUrl("&ordering=-day_1_follower_change")
                setSortByColumnDirection('up')
                break
            case 'month_1_follower_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('month_1_follower_change')
                setSortByUrl("&ordering=coinscreener__month_1_follower_change")
                setSortByColumnDirection('down')
                break
            case 'month_1_follower_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('month_1_follower_change')
                setSortByUrl("&ordering=-coinscreener__month_1_follower_change")
                setSortByColumnDirection('up')
                break
            case 'year_1_follower_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('year_1_follower_change')
                setSortByUrl("&ordering=coinscreener__year_1_follower_change")
                setSortByColumnDirection('down')
                break
            case 'year_1_follower_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('year_1_follower_change')
                setSortByUrl("&ordering=-coinscreener__year_1_follower_change")
                setSortByColumnDirection('up')
                break
            case 'day_1_post_volume_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('day_1_post_volume_change')
                setSortByUrl("&ordering=coinscreener__day_1_post_volume_change")
                setSortByColumnDirection('down')
                break
            case 'day_1_post_volume_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('day_1_post_volume_change')
                setSortByUrl("&ordering=-coinscreener__day_1_post_volume_change")
                setSortByColumnDirection('up')
                break
            case 'day_7_post_volume_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('day_7_post_volume_change')
                setSortByUrl("&ordering=coinscreener__day_7_post_volume_change")
                setSortByColumnDirection('down')
                break
            case 'day_7_post_volume_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('day_7_post_volume_change')
                setSortByUrl("&ordering=-coinscreener__day_7_post_volume_change")
                setSortByColumnDirection('up')
                break
            case 'month_1_post_volume_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('month_1_post_volume_change')
                setSortByUrl("&ordering=coinscreener__month_1_post_volume_change")
                setSortByColumnDirection('down')
                break
            case 'month_1_post_volume_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('month_1_post_volume_change')
                setSortByUrl("&ordering=-coinscreener__month_1_post_volume_change")
                setSortByColumnDirection('up')
                break
            case 'month_3_post_volume_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('month_3_post_volume_change')
                setSortByUrl("&ordering=coinscreener__month_3_post_volume_change")
                setSortByColumnDirection('down')
                break
            case 'month_3_post_volume_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('month_3_post_volume_change')
                setSortByUrl("&ordering=-coinscreener__month_3_post_volume_change")
                setSortByColumnDirection('up')
                break
            case 'year_1_post_volume_change':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('year_1_post_volume_change')
                setSortByUrl("&ordering=coinscreener__year_1_post_volume_change")
                setSortByColumnDirection('down')
                break
            case 'year_1_post_volume_change_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('year_1_post_volume_change')
                setSortByUrl("&ordering=-coinscreener__year_1_post_volume_change")
                setSortByColumnDirection('up')
                break
            case 'year_1_post_volume_average':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('year_1_post_volume_average')
                setSortByUrl("&ordering=coinscreener__year_1_post_volume_average")
                setSortByColumnDirection('down')
                break
            case 'year_1_post_volume_average_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('year_1_post_volume_average')
                setSortByUrl("&ordering=-coinscreener__year_1_post_volume_average")
                setSortByColumnDirection('up')
                break
            case 'month_1_post_volume_average':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('month_1_post_volume_average')
                setSortByUrl("&ordering=coinscreener__month_1_post_volume_average")
                setSortByColumnDirection('down')
                break
            case 'month_1_post_volume_average_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('month_1_post_volume_average')
                setSortByUrl("&ordering=-coinscreener__month_1_post_volume_average")
                setSortByColumnDirection('up')
                break
            case 'month_3_post_volume_average':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('month_3_post_volume_average')
                setSortByUrl("&ordering=coinscreener__month_3_post_volume_average")
                setSortByColumnDirection('down')
                break
            case 'month_3_post_volume_average_asc':
                //setCurrencyList( results.sort( (a,b) => (a.total_volume < b.total_volume) ? 1 : -1))
                setSortByColumn('month_3_post_volume_average')
                setSortByUrl("&ordering=-coinscreener__month_3_post_volume_average")
                setSortByColumnDirection('up')
                break
        }
        return
    }

  const changeInMyFavoritedCoins = ( newFavoriteCoins, oldFavoriteCoins ) => {
    setIsLoading(true)
    console.log("top fo changeInMyFavoritedCoins and newFavoriteCoins is:")
    console.dir(newFavoriteCoins)
    console.log("oldFavoritecoins is:")
    //let reducedNewFavoriteCoins = newFavoriteCoins.map(thisCoin => thisCoin.coin_id)
    //console.log("reducedNewFavoriteCoins is")
    //console.dir(reducedNewFavoriteCoins)
    //console.dir(oldFavoriteCoins)
    if (!checkArraysEqual(newFavoriteCoins, oldFavoriteCoins)) {
      //old and new are NOT equal...need to change
      console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxsetScreenerCoinList within changeInMyFavoritedCoins is going to be set to:------------------------- ")
      console.dir(newFavoriteCoins)
      setScreenerCoinList(newFavoriteCoins)
      //need to check if oldFavoriteCoins matches userObject.coins....if so....change userObject.coins also
      if (checkArraysEqual(oldFavoriteCoins,localUserObject.coin)) {
        //console.log("old and current vip were the same but not now....change userObject.coin")
        let reducedNewFavoriteCoins = newFavoriteCoins.map(thisCoin => thisCoin.coin_id)
        setLocalUserObject(prevState => ({...prevState, 'coin': reducedNewFavoriteCoins}))
        updateUserDataCoin(reducedNewFavoriteCoins)
   
      }
    } else {
      //no changes were made in the coin list...no need to update anything
    }
    
    setIsLoading(false)
  }

  const setColumns = (obj) => {
      setVisibleColumns(obj)
  }

  if (isLoading) {
    return <CircularProgress />
  } else {
    //console.log("screenerCoinList at top of MyCurrencies return:")
    //console.dir(screenerCoinList)
    return (

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* header */}
        <TopAppBar userObject={userObject} selectedPage='mycurrencies' />
        {/* main content */}
        <Main theme={theme} >
            <Container maxWidth="false">
              <Box sx={{width: '100%', paddingTop: '10px', paddingBottom: '20px'}}>
                <Grid container>
                  <Grid item xs={2}>
                    <ScreenerList 
                      selectedScreener={selectedScreener} 
                      changeScreener={changeScreener} 
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <MyCurrenciesFilterRow
                      userObject={localUserObject}
                      changeInMyFavoritedCoins={changeInMyFavoritedCoins}
                      changeChartTimeFrame={changeChartTimeFrame}
                      currentTimeFrame={currentTimeFrame}
                      selectedScreener={selectedScreener}
                      screenerCoinList={screenerCoinList}
                      changeScreener={changeScreener}
                      visibleColumns={visibleColumns}
                      setColumns={setColumns}
                    />
                    <ScreenerCurrencyHeader sortingBy={sortingBy} sortByColumn={sortByColumn} sortResults={sortResults} visibleColumns={visibleColumns} isWindowSmall={isWindowSmall} />
                    {ecoinScreenerDetailsList.map( displayCoin => Object.keys(displayCoin).length > 0 ? <ScreenerCurrencyListItem key={"currencyListItem"+displayCoin.coin} visibleColumns={visibleColumns} isWindowSmall={isWindowSmall} deleteFromScreener={deleteFromScreener} screenerCoinId={responseScreenerCoinList.filter( screenerCoinToFilter => displayCoin.coin === screenerCoinToFilter.coin_id)[0]} currencyDetails={displayCoin} userObject={localUserObject} /> : null)}
                    {screenerCoinList.length === 0 && selectedScreener === 'All Coins' ? <Box sx={{width: '100%', padding: '20px', paddingTop: '10px', paddingBottom: '20px'}}><Typography >The All Coins screener by default contains all coins within the system and cannot be edited</Typography></Box> : null }
                    {screenerCoinList.length === 0 && selectedScreener === '' ? <Box sx={{width: '100%', padding: '20px', paddingTop: '10px', paddingBottom: '20px'}}><Typography >You haven't selected a screener to the left.  Please click on a screener to view it's coins and you can then Choose Your Favorites to add some coins to that screener.  If you have no screener click create new screener to create one. </Typography></Box> : null }
                    {screenerCoinList.length === 0 && selectedScreener !== '' && selectedScreener !== 'All Coins' ? <Box sx={{width: '100%', padding: '20px', paddingTop: '10px', paddingBottom: '20px'}}><Typography >You have no coins in this screener.  Click Add Coin To {selectedScreener} screener and add coins to it</Typography></Box> : null }
                  </Grid>
                </Grid>
              </Box>
            </Container>
          <Footer />
        </Main>
      </Box>
    )
  }
}

export default MyCurrencies