import React, { useState, useEffect } from 'react'
import { Stack, Grid, Pagination, Divider, Typography, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'


import fetchData from '../../utility/endpointFetch'

import VIPTwitterPost from './VIPTwitterPost'
//import VIPTelegramPost from './VIPTelegramPost'
//import VIPDiscordPost from './VIPDiscordPost'
//import VIPRedditPost from './VIPRedditPost'

const VIPOfficialTwitterDisplay = ( { officialTwitter, changeChartAnnotations, allCoinsScrollTo, changeAtFollowedTab, setCoinInChart, changeAtMentionedTab, changeHashTab, changeDateInChart, changeCoinInTab, showOfficialTweets} ) => {

    //console.log("top of VIPOfficialTwitterDisplay")
    //console.dir(officialTwitter)
    const theme = useTheme()

    const [vipTwitterListings,setVIPTwitterListings] = useState([])
    const [responseCount,setResponseCount] = useState(0)
    const [vipTwitterPageCount,setVIPTwitterPageCount] = useState(1)
    const [vipTwitterPage,setVIPTwitterPage] = useState(1)
    const [isLoading,setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        const fetchParams = {
          method: 'GET',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/json'
          },
        }
        ;(async () => {
          try {
            //var vipOfficialTwitterPostURL = `api/v1/vip/vipsignal/?all&post__tweet__twitter_user__name=${officialTwitter}&page=${vipTwitterPage}`
            var vipOfficialTwitterPostURL = `api/v1/feed/alltweets/?&vip=true&twitter_display_name=${officialTwitter}&page=${vipTwitterPage}`
            const vipOfficialTwitterPostResponse = await fetchData(vipOfficialTwitterPostURL, "could not receive official twitter data", fetchParams).promise
            //console.log("vipOfficialTwitterPostResponse getting vip data from api")
            //console.dir(vipOfficialTwitterPostResponse)
            //console.log(vipOfficialTwitterPostURL)
    
            setResponseCount(vipOfficialTwitterPostResponse.count)
            //setResponseCount(vipOfficialTwitterPostResponseTestData.count)
    
            
            if (vipOfficialTwitterPostResponse.count > 100) {
                setVIPTwitterPageCount(Math.ceil(vipOfficialTwitterPostResponse.count/100))
            } else {
                setVIPTwitterPageCount(1)
            }
                        
            
            setVIPTwitterListings(vipOfficialTwitterPostResponse.results)
            //setVIPTwitterListings(vipOfficialTwitterPostResponseTestData.results)
            //console.log("about to changeChartAnnotations to ")

            changeChartAnnotations(vipOfficialTwitterPostResponse.results)
            //changeChartAnnotations(vipOfficialTwitterPostResponseTestData.results)
    
            setIsLoading(false)
          } catch (error) {
            console.log("something is broke getting vip: " + error)
          }
        })()
    
    }, [vipTwitterPage, officialTwitter])

    const handlePageChange = (event, twitterPage) => {
        setVIPTwitterPage(twitterPage)
    }

    if (isLoading) return <CircularProgress />
    return (
        <Grid id="VIPAllCoinsPostContainer" container sx={{minHeight: '200px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', scrollbarWidth: 'thin'}}>
            <Grid item xs={12}>
                <Divider sx={{width:'100%', marginTop: '20px'}} textAlign="left" >
                    <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>{responseCount}</Typography>
                    <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.secondary.main, marginTop: '20px'}}> Official Tweets for : </Typography>
                    <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.primary.main, marginTop: '20px'}}> {officialTwitter}</Typography>
                </Divider>
            </Grid>
            {vipTwitterListings.map( (listing, index) => {
                if (listing !== null) {
                    if (listing.hasOwnProperty('tweet_id')) return <VIPTwitterPost scrollTo={"scrollToName"+listing.tweet_id} key={'vipcointweet'+index+Math.random()} listing={listing} setCoinInChart={setCoinInChart} showOfficialTweets={showOfficialTweets} changeAtFollowedTab={changeAtFollowedTab} changeAtMentionedTab={changeAtMentionedTab} changeHashTab={changeHashTab} changeCoinInTab={changeCoinInTab} changeDateInChart={changeDateInChart} />
                    else return null //not sure if these are the only 5 types needed
                }
            })}
            <Grid item xs={12}>
                <Divider sx={{marginBottom: '15px'}}/>
                <Stack direction="row" display="flex" alignItems="center">
                <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
                <Pagination shape="rounded" count={vipTwitterPageCount} page={vipTwitterPage} onChange={handlePageChange}/>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default VIPOfficialTwitterDisplay