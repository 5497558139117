import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import CurrenciesListItem from './CurrenciesListItem'

import fetchData from '../../utility/endpointFetch'

const CurrenciesList = ( {currencyList, currentTimeFrame, userCoinAlerts, userCoinAlertsList, visibleColumns } ) => {

  //console.log("top of CurrenciesList")
  //console.dir(currencyList)

  const [isLoading, setIsLoading] = useState(true)
  const [filteredChart,setFilteredChart] = useState([])
  const [userObject,setUserObject] = useState({})
  const [allCoinsInVIP,setAllCoinsInVIP] = useState([])
  const [allCoinsInVIPWithScreenerName,setAllCoinsInVIPWithScreenerName] = useState([])
  const [hasSetAllCoins, setHasSetAllCoins] = useState(false)

  const populateAllCoinsInVIP = () => {
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        const response = await fetchData(`api/v1/users/userdata/`, "could not receive favorites data in currenciesList", fetchParams).promise
        //console.log("response getting favorites in currenciesList")
        //console.dir(response)
        setUserObject(response)
        setIsLoading(false)

        ;(async () => {
            const getCurrentScreenersResponse = await fetchData(`api/v1/users/screener/`, "could not get all screeners in home coins list", fetchParams).promise
            //console.log("----------------------GETCURRENTSCREENERSRESPONSE GETTING SCREENERS IN HOME COINS LIST")
            console.dir(getCurrentScreenersResponse)
            //console.log("allCoinsInVIP will be: ")
            //console.dir(getCurrentScreenersResponse.results.map(screenerCoin => screenerCoin.coin_id))
            //console.log("allCoinsInVIPWithScreenerName will be: ")
            //console.dir(getCurrentScreenersResponse.results.map(screenerCoin => ({ 'coin_id': screenerCoin.coin_id, 'screener_name': screenerCoin.screener_name, 'id': screenerCoin.id})))
            setAllCoinsInVIP(getCurrentScreenersResponse.results.map(screenerCoin => screenerCoin.coin_id))
            setAllCoinsInVIPWithScreenerName(getCurrentScreenersResponse.results.map(screenerCoin => ({ 'coin_id': screenerCoin.coin_id, 'screener_name': screenerCoin.screener_name, 'id': screenerCoin.id}) ))
            setHasSetAllCoins(true)
        })() 

      } catch (error) {
        console.log("something is broke getting home screener coins: " + error)
      }
    })()    
  }

  useEffect(() => {
    populateAllCoinsInVIP()
  }, [])  

  const changeInCoinsInVIP = () => {
    populateAllCoinsInVIP()
  }

  useEffect(() => {
    setIsLoading(true)
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        //create a query string to select all coins chart data
        var coinsArray = currencyList.map( coin => {
          return coin.coin
        })
        //console.log("coinsArray is: ")
        //console.dir(coinsArray)

        if (!coinsArray.includes("bitcoin")) {
          //need to add it to make sure get bitcoin price data
          coinsArray.push('bitcoin')
        }

        var urlCoinString = coinsArray.join("__")
        //console.log("urlCoinString is: ")
        //console.dir(urlCoinString)

        var dateOneDayAgo = new Date(Date.now() - (1 * 24 * 60 * 60 * 1000))
        //dateOneDayAgo.setDate(dateOneDayAgo.getDay() - 8)
        //console.log("dateOneDayAgo.toISOString() is: " + dateOneDayAgo.toISOString())

        var urlString = `api/v1/coin/marketchart/?coin__in=${urlCoinString}&interval=quarter_hour&timestamp__gt=${dateOneDayAgo.toISOString()}&page_size=9700&page=1`
        const chartResponse = await fetchData(urlString, "could not receive coinmarket data", fetchParams).promise
        //console.log("response getting marketchart data from backend for: " + urlCoinString)
        //console.log(urlString)
        //console.dir(chartResponse)

        //filter into usable data
        var filteringChart = []
        coinsArray && coinsArray.forEach( coin => {
          //console.log("building price data for: " + coin)
          let filteredstuff = chartResponse.results.filter( chartData => chartData.coin === coin )
          //console.dir(filteredstuff)
          filteringChart[coin] = chartResponse.results.filter( chartData => chartData.coin === coin )
        })
        //console.log("filteringChart is:")
        //console.dir(filteringChart)
        setFilteredChart(filteringChart)

        setIsLoading(false)
      } catch (error) {
        console.log("something is broke getting marketchart: " + error)
      }
    })()        
  }, [currencyList])

  //console.log("top of CurrenciesList")
  //console.dir(currencyList)
  //console.dir(filteredChart)
  //console.dir(currentTimeFrame)
  //if (unfilteredChart.length === 0) return <CircularProgress />
  if (isLoading) return <CircularProgress />
  if (currencyList.length > 0 && hasSetAllCoins) {
    return ( currencyList.map( (currency, index) => {
      if (Object.keys(currency).length > 0) {
        return (
          <CurrenciesListItem key={currency.coin + index} currencyDetails={currency}
            currentTimeFrame={currentTimeFrame} 
            chartData={filteredChart[currency.coin]}
            userObject={userObject} 
            allCoinsInVIP={allCoinsInVIP}
            visibleColumns={visibleColumns}
            allCoinsInVIPWithScreenerName={allCoinsInVIPWithScreenerName}
            changeInCoinsInVIP={changeInCoinsInVIP}
            //userCoinAlerts={userCoinAlerts} 
            //userCoinAlertsList={userCoinAlertsList} 
          />
        )
      } else {
        console.log("empty object in response")
      }
     }))
  } else {
    //there are no currencies to display
    return (
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography>There are no currencies that fit into the chosen category</Typography>
        </Grid>
      </Grid>
    )
  }
}

export default CurrenciesList