import React from 'react'
import { Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const ResearchTutorial2 = () => {

    const theme = useTheme()

    return (
        <Stack direction="column" alignItems="center" >
            <Typography variant="h2" sx={{paddingTop: '20px', color: theme.palette.warning.dark}}>How This Tool Can Help</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>Scheduled events such as such as, mainnet upgrades, staking, and conferences, can often cause 
            huge price increases as the event approaches.</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>Here you can search all Twitter accounts of Crypto Projects to find upcoming events 
            of interest. Searches can be further refined to only include projects that meet your criteria.</Typography>
            <Typography variant="body1" sx={{padding: '10px'}}>Click next to learn the research tool you use!</Typography>
        </Stack>        
    )
}
export default ResearchTutorial2