import React, {useState, useEffect, useRef} from 'react'
import {
    Box,
    CircularProgress,
    Grid,
    ButtonGroup,
    Button,
    Stack,
    Chip,
    Modal,
    Fade,
    Typography,
    TextField,
    InputLabel, Select, MenuItem,
  } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { ColorType, createChart } from 'lightweight-charts'
import fetchData from '../../utility/endpointFetch'
import { AiOutlineBell } from "react-icons/ai"
import { ImCancelCircle } from "react-icons/im"
import { FormControl } from "@material-ui/core"
import { last } from 'ramda'


const CoinDetailsLWChart = ( {currencyDetails} ) => {
    console.log("top of CoinDetailsLWChart and currencyDetails is: ")
    console.dir(currencyDetails)

    const theme = useTheme()
    const callInterval = 5000 //may change from one minute to more for larger time frames

    const [open, setOpen] = React.useState(false)
    const handleClose = () => setOpen(false)
    const [ isLoading,setIsLoading] = useState(false)
    const [ priceAlertTextField, setPriceAlertTextField ] = useState(0)
    const [ priceAlertError,setPriceAlertError] = useState(false)
    const [ alertMultipleTextField, setAlertMultipleTextField ] = useState(1)
    const [ chartInterval, setChartInterval ] = useState('1d')
    const [ pageSize,setPageSize] = useState(400) //default to daily candles
    //const [ dateAgo, setDateAgo] = useState(new Date(Date.now() - (400 * 24 * 60 * 60 * 1000)))
    //const [ chartBaseCurrencySymbol, setChartBaseCurrencySymbol ] = useState('$ ')
    //const [ chartBaseCurrency, setChartBaseCurrency ] = useState('USD')
    const [ chartRangeDetails, setChartRangeDetails ] = useState('1y/daily')  
    const candleStickData = useRef([])
    const volumeData = useRef([])

    var chartRef = useRef()
    var firstRun = useRef(true)

    const chartOptions = {
      //width: chartRef.current.clientWidth,
      //width: '100%',
      height: 500,
      layout: {
        background: {
          type: ColorType.Solid,
          color: theme.palette.background.default,
        },
        textColor: theme.palette.primary.main,
      },
      timeScale: ['1w', '1d'].includes(chartInterval) ?
          { // daily interval, don't show time.
              timeVisible: false,
          }
          :
          {
              timeVisible: true,
              secondsVisible: false,
          },
      crosshair: {
        mode: 0,
      },
      localization: {
        priceFormatter: (price) => {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 6
            });
            return formatter.format(price);
        }
      },
      grid: {
        vertLines: {
          color: theme.palette.secondary.dark,
          style: 4,

        },
        horzLines: {
          color: theme.palette.secondary.dark,
          style: 4,
        }
      },
    } 

    //console.log("chartRef at top is:")
    //console.dir(chartRef.current)

    const candleStickOptions = {
      upColor: theme.palette.success.main,
      downColor: theme.palette.error.main,
    }    

   
    const volumeSeriesOptions = {
      color: 'rgba(59, 4, 189, 0.5)',
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '', // set as an overlay by setting a blank priceScaleId
      // set the positioning of the volume series
      scaleMargins: {
        top: 0.7, // highest point of the series will be 70% away from the top
        bottom: 0,
      },
    }

    const myClickHandler = (param) => {
      if (!param.point) {
          return;
      }
      setOpen(!open)
      setPriceAlertTextField([...param.seriesPrices.values()][0].close)
    }

    const crosshairMoveHandler = (param) => {
      // Create and style the tooltip html element
      if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.y < 0
      ) {
          return
      } else {
        
        //console.log("param inside crosshairMoveHandler is: ")
        //console.dir(param)
        return
      }
    }

    useEffect(() => {

      console.log("--------------------------------chartRef.current at top of useEffect is: ")
      console.dir(chartRef.current)

      //if (chartRef.current.value !== undefined)
      //  chartRef.current.value = undefined

      const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        }
      }
      ;(async () => {
        try {
          var getExchangeChartDataURL = `api/v1/coin/exchangechart${chartInterval}/?symbol=${currencyDetails.coin_symbol}&page_size=${pageSize}&page=1&ordering=-timestamp`
          const exhangechartResponse = await fetchData(getExchangeChartDataURL, "could not receive exchange chart data", fetchParams).promise
          console.log("exhangechartResponse getting exchangechart data using currencyDetails.coin_symbol: " + currencyDetails.coin_symbol)
          console.dir(exhangechartResponse)
          console.log("getExchangeChartDataURL is: ")
          console.dir(getExchangeChartDataURL)


          console.log("chartRef is:")
          console.dir(chartRef)

          console.log("firstRun is:")
          console.dir(firstRun)

          var widthOfChartRefDIV = chartRef.current.clientWidth

          console.log("chartRef.current before setting:")
          console.dir(chartRef.current)

          var candleStickChart = createChart(chartRef.current, chartOptions, )
          var candleSeries = candleStickChart.addCandlestickSeries(candleStickOptions)    
          var volumeSeries = candleStickChart.addHistogramSeries(volumeSeriesOptions) 
          candleStickChart.subscribeClick(myClickHandler)
          candleStickChart.subscribeCrosshairMove(crosshairMoveHandler)

          ///nasty little thing to figure out
          //if the chart is changed...originally just appended below the others
          //need to remove the first child of chartRef (previous chart drawn)
          //so that can add a new chart...replacing instead of appending...ugh
          if (!firstRun.current) {
            console.log("NOT FIRST RUN....................")
            //chartRef.current = null
            chartRef.current.removeChild(chartRef.current.children[0])  
          } else {
            firstRun.current = false
          }

          //console.log("chartRef.current.children is: ")
          //console.dir(chartRef.current.children)
          //console.dir(Array.from(chartRef.current.children))

          var reversedExchangeResponseResult = exhangechartResponse.results.reverse()
          
          candleStickChart.applyOptions( {width: widthOfChartRefDIV})

          let lastCandle = {
            "time": 0
          }
          var filteredCandleStick = reversedExchangeResponseResult.map( chartData => {
            if (lastCandle.time !== chartData.time && lastCandle.time < chartData.time) {
              lastCandle = chartData
              return {
                "time": chartData.time,
                "open": chartData.open,
                "high": chartData.high,
                "low": chartData.low,
                "close": chartData.close,
              }
            } else {
              //console.log("error in data....current time is = or < lastCandle")
              //console.dir(lastCandle)
              //console.dir(chartData)
            }
          })
          let noUndefinedFilteredCandleStick = filteredCandleStick.filter( data => data !== undefined)
          candleStickData.current = noUndefinedFilteredCandleStick

          lastCandle = {
            "time": 0
          }
          var filteredVolume = reversedExchangeResponseResult.map( chartData => {
            if (lastCandle.time !== chartData.time && lastCandle.time < chartData.time) {
              lastCandle = chartData
              return {
                "time": chartData.time,
                "value": chartData.volume
              }
            } else {
              //return nothing as bad data
            }

          })
          let noUndefinedFilteredVolume = filteredVolume.filter( data => data !== undefined)
          volumeData.current = noUndefinedFilteredVolume

          console.log("noUndefinedFilteredCandleStick is: ")
          console.dir(noUndefinedFilteredCandleStick)
          console.log("noUndefinedFilteredVolume is: ")
          console.dir(noUndefinedFilteredVolume)          
          
          candleSeries.setData(noUndefinedFilteredCandleStick)
          volumeSeries.setData(noUndefinedFilteredVolume)

          const getNewCandleIntervalID = setInterval(getNewCandle, callInterval, candleSeries, volumeSeries, candleStickData, volumeData)          

          setIsLoading(false)

          return () => clearInterval(getNewCandleIntervalID)
          
        } catch (error) {
          console.log("something is broke getting exchange chart data: ", error)
        }
      })()
    }, [chartInterval])

    const getNewCandle = (candleSeries, volumeSeries ) => {

      const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        }
      }
      ;(async () => {
        try {
          console.log("candleStickData.current before making ten candle call is:")
          console.dir(candleStickData.current)
          var getTenCandleExchangeChartDataURL = `api/v1/coin/exchangechart${chartInterval}/?symbol=${currencyDetails.coin_symbol}&page_size=10&page=1&ordering=-timestamp&?timestamp__gt=${candleStickData.current[candleStickData.current.length - 1].time}`
          const getTenCandleExhangechartResponse = await fetchData(getTenCandleExchangeChartDataURL, "could not receive one candle exchange chart data", fetchParams).promise
          console.log("getTenCandleExhangechartResponse getting one candle exchange data using currencyDetails.coin_symbol: " + currencyDetails.coin_symbol)
          console.dir(getTenCandleExhangechartResponse)
          console.log("getTenCandleExchangeChartDataURL is: ")
          console.dir(getTenCandleExchangeChartDataURL)   
          //console.log("candleStickData.current is:")
          //console.dir(candleStickData.current)  

          //first need to filter out all candles that are greater in time than the last candle in the currently displayed data set
          //the backend is not providing the candles in the "time" they actually are.
          //basically on a 1m chart the backend may not see a new candle for 5 minutes.  If we are only getting the 
          //last candle from the result above the 1m candle data may jump around with gaps.
          //solution is to take the last 10 candles and filter out all that are less in time than the last candle in the current data set.
          //then add all those candles that are greater....so that none are missed.  
          //if the backend corrects and supplies 1 can a minute (for 1m chart) this will have no effect because you'll filter out down to one
          //when it works.  For the times when it "skips minutes" we'll still have those 1m candles in the result and will add them
          //while the chart may jump a bit in these instances (adding more than one candle at a time) the chart will still be correct

          console.log("candleStickData.current is: ")
          console.dir(candleStickData.current)
          console.log("candleStickData.current[candleStickData.current.length - 1].time is: " + candleStickData.current[candleStickData.current.length - 1].time)
          var candleSticksToAdd = getTenCandleExhangechartResponse.results.filter( candle => candle.time > candleStickData.current[candleStickData.current.length - 1].time)

          console.log("candleSticksToAdd is: ")
          console.dir(candleSticksToAdd)
          console.log("candleSticksToAdd.length is: " + candleSticksToAdd.length)

          if (candleSticksToAdd.length > 0) {
            var convertedCandleSticksToAdd = candleSticksToAdd.map( candle => {
              return {
                "time": candle.time,
                "open": candle.open,
                "high": candle.high,
                "low": candle.low,
                "close": candle.close,
              }
            })
            //need to sort candles by time going up
            convertedCandleSticksToAdd.sort((a,b) => a.time - b.time)

            //console.log("convertedCandleSticksToAdd is: ")
            //console.dir(convertedCandleSticksToAdd)

            var convertedVolumeToAdd = candleSticksToAdd.map( candle => {
              return {
                "time": candle.time,
                "value": candle.volume,
              }
            })
            //need to sort candles by time going up
            convertedVolumeToAdd.sort((a,b) => a.time - b.time)    
            //console.log("convertedVolumeToAdd is: ")
            //console.dir(convertedVolumeToAdd)  

            console.log("ADDING NEW CANDLES -1 : " + convertedVolumeToAdd.length)
            candleStickData.current = [...candleStickData.current, ...convertedCandleSticksToAdd]
            volumeData.current = [...volumeData.current, ...convertedVolumeToAdd]

            convertedCandleSticksToAdd.map(candleToAdd => candleSeries.update(candleToAdd))
            convertedVolumeToAdd.map(candleToAdd => volumeSeries.update(candleToAdd))
            //candleSeries.update(convertedCandleSticksToAdd)
            //volumeSeries.update(convertedVolumeToAdd)              
            
          } else {
            //there are no candles to add
            //console.log("NO CANDLES TO ADD")
          }
        } catch (error) {
          console.log("something is broke getting exchange chart data: ", error)
        }
      })()
        /*
        .then(() => {
        const fetchParams = {
              method: 'GET',
              dataType: 'json',
              headers: {
                  'Content-Type': 'application/json'
              }
          }
        ;(async () => {
          try {
            var getTenCandleExchangeChartDataURL = `api/v1/coin/exchangechart1m/?symbol=${currencyDetails.coin_symbol}&ordering=-timestamp`
            var getTenCandleExhangechartResponse = await fetchData(getTenCandleExchangeChartDataURL, "could not receive one candle exchange chart data", fetchParams).promise
            var candleSticksToAdd = getTenCandleExhangechartResponse.results.filter( candle => candle.time > candleStickData.current[candleStickData.current.length - 1].time)
            //console.log("candleStickData.current[candleStickData.current.length - 1].time is: " + candleStickData.current[candleStickData.current.length - 1].time)
            //console.log("candleSticksToAdd is: ")
            //console.dir(candleSticksToAdd)

            if (candleSticksToAdd.length > 0) {
                var convertedCandleSticksToAdd = candleSticksToAdd.slice(candleSticksToAdd.length - 1, candleSticksToAdd.length).map( candle => {
                    return {
                        "time": candle.time,
                        "open": candle.open,
                        "high": candle.high,
                        "low": candle.low,
                        "close": candle.close,
                    }
                })
                //need to sort candles by time going up
                convertedCandleSticksToAdd.sort((a,b) => a.time - b.time)

                //console.log("convertedCandleSticksToAdd is: ")
                //console.dir(convertedCandleSticksToAdd)

                var convertedVolumeToAdd = candleSticksToAdd.map( candle => {
                    return {
                        "time": candle.time,
                        "value": candle.volume,
                    }
                })
                //need to sort candles by time going up
                convertedVolumeToAdd.sort((a,b) => a.time - b.time)
                //console.log("convertedVolumeToAdd is: ")
                //console.dir(convertedVolumeToAdd)

                console.log("ADDING NEW CANDLES -2: " + convertedVolumeToAdd.length)
                candleStickData.current = [...candleStickData.current, convertedCandleSticksToAdd]
                volumeData.current = [...volumeData.current, convertedVolumeToAdd]

                convertedCandleSticksToAdd.map(candleToAdd => candleSeries.update(candleToAdd))
                convertedVolumeToAdd.map(candleToAdd => volumeSeries.update(candleToAdd))
                //candleSeries.update(convertedCandleSticksToAdd)
                //volumeSeries.update(convertedVolumeToAdd)

              } else {
                //there are no candles to add
                //console.log("NO CANDLES TO ADD")
              }
          } catch (error) {
            console.log("something is broke getting exchange chart data: ", error)
          }
        })()
        
      })
      */
    }

    const handleChartIntervalChange = ( interval) => {
      //console.log("top of handleChartIntervalChange...interval is: ")
      //console.dir(interval)
  
      switch (interval) {
        case '1w':
          setChartRangeDetails('2y/weekly')
          setChartInterval('1w')
          setPageSize(120)
          //setDateAgo(new Date(Date.now() - (700 * 24 * 60 * 60 * 1000)))
          break;        
        case '1d':
          setChartRangeDetails('1y/daily')
          setChartInterval('1d')
          setPageSize(400)
          //setDateAgo(new Date(Date.now() - (400 * 24 * 60 * 60 * 1000)))
          break;
        case '4h':
          setChartRangeDetails('3 months/4 hours')
          setChartInterval('4h')
          setPageSize(540) 
          //setDateAgo(new Date(Date.now() - (90 * 24 * 60 * 60 * 1000)))
          break;
        case '1h':
          setChartRangeDetails('14 days/1 hours')
          setChartInterval('1h')
          setPageSize(336) //14 days
          //setDateAgo(new Date(Date.now() - (14 * 24 * 60 * 60 * 1000)))
          break;
        case '15m':
          setChartRangeDetails('4 days/15 min')
          setChartInterval('15m')
          setPageSize(384)
          //setDateAgo(new Date(Date.now() - (4 * 24 * 60 * 60 * 1000)))
          break;
        case '5m':
          setChartRangeDetails('2 days/5 min')
          setChartInterval('5m')
          setPageSize(576)
          //setDateAgo(new Date(Date.now() - (2 * 24 * 60 * 60 * 1000)))
          break;
        case '1m':
          setChartRangeDetails('12 hours/i min')
          setChartInterval('1m')
          setPageSize(720)
          //setDateAgo(new Date(Date.now() - (.5 * 24 * 60 * 60 * 1000)))
          break;
        default:
          //not sure what this is
          setChartRangeDetails('')
      }
    }
    
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
          backgroundColor: theme.palette.primary800,
        },
    }));

    const saveAlert = e => {
        e.preventDefault()

        if ((priceAlertTextField === 0) || (priceAlertTextField === '')) {
            setPriceAlertError(true)
        } else if (priceAlertTextField > 0) {
            console.log("priceAlert is greater than 0")
            const fetchParams = {
                    method: 'POST',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'coin': currencyDetails.name,
                        'price_target': priceAlertTextField,
                        'multiple': alertMultipleTextField,
                    })
                }
            ;(async () => {
                try {
                    var urlString = `api/v1/vip/alertcreate/`
                    const saveAlertResponse = await fetchData(urlString, "could not save new price alert", fetchParams).promise
                    //console.log("saveAlertResponse saving new price alert: ")
                    //console.log(urlString)
                    setPriceAlertTextField(0)
                    setAlertMultipleTextField(1)
                } catch (error) {
                    console.log("something is broke new price alert: ", error)
                }
            })()
            setOpen(false)
        } else {
            console.log("priceAlert couldn't be created")
        }
    }

    //if (isLoading) return <CircularProgress />
    return (
      <Box
        sx={{
          paddingTop: '20px',
        }}
      >      
        <Stack direction="column">
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={open}>
                    <Box sx={{ position: 'absolute', width: 600, backgroundColor: theme.palette.dark[800],  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)', borderRadius: '8px'}}>
                        <h2 id='spring-modal-price-alert'>Create A Price Alert For {currencyDetails.coin}</h2>
                        <p id='spring-modal-price-alert-description' style={{ fontSize: '1rem' }}>
                            You can have us notify you when price of {currencyDetails.coin} hits a certain price.  Just insert the value below and hit enter
                            <br /><br />
                            <Grid container>
                                <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                                    <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around">
                                        <Typography variant="subtitle2"></Typography>
                                        <TextField
                                            type='text'
                                            required
                                            fullWidth
                                            id='priceAlert'
                                            label='price'
                                            defaultValue={priceAlertTextField}
                                            name='priceAlert'
                                            onChange={e => setPriceAlertTextField(e.target.value)}
                                            helperText={priceAlertError ? 'Your price must be more than 0' : null}
                                            error={priceAlertError}
                                            inputProps={{ maxLength: 100 }}
                                            variant="filled"
                                            focused
                                        />
                                    </Stack>
                                </Grid>
                                <FormControl fullWidth style={{ paddingBottom: '25px'}}>
                                    <InputLabel id="demo-simple-select-label">Number of Alerts</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={alertMultipleTextField}
                                        label="Number of Alerts"
                                        onChange={(event) => setAlertMultipleTextField(event.target.value)}
                                        sx={{justifyContent: 'center', flex: 1}}
                                    >
                                        <MenuItem value={1}>One</MenuItem>
                                        <br/>
                                        <MenuItem value={3}>Three</MenuItem>
                                        <br/>
                                        <MenuItem value={5}>Five</MenuItem>
                                    </Select>
                                </FormControl>

                                <Grid item xs={12}>
                                    <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" sx={{width: '100%'}}>
                                        <Button startIcon={<AiOutlineBell />} variant='outlined' onClick={saveAlert} color='primary'>
                                            Click To Save Your Alert
                                        </Button>
                                        <Button startIcon={<ImCancelCircle />} variant='outlined' onClick={() => setOpen(!open)} color='warning' sx={{ml: 1}}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </p>
                    </Box>
                </Fade>
            </Modal>
            <Stack direction="row" sx={{justifyContent: 'space-between'}}>
              <Chip label={chartRangeDetails} sx={{backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.light}} variant="outlined" />
              <ButtonGroup size="small" aria-label="small button group" sx={{paddingRight: '10px'}}>
                  { chartInterval === '1w' ? <ColorButton  variant="contained">1w</ColorButton> : <Button onClick={() => handleChartIntervalChange('1w')}>1w</Button> }
                  { chartInterval === '1d' ? <ColorButton  variant="contained">1d</ColorButton> : <Button onClick={() => handleChartIntervalChange('1d')}>1d</Button> }
                  { chartInterval === '4h' ? <ColorButton  variant="contained">4h</ColorButton> : <Button onClick={() => handleChartIntervalChange('4h')}>4h</Button> }
                  { chartInterval === '1h' ? <ColorButton  variant="contained">1h</ColorButton> : <Button onClick={() => handleChartIntervalChange('1h')}>1h</Button> }
                  { chartInterval === '14m' ? <ColorButton variant="contained">15m</ColorButton> : <Button onClick={() => handleChartIntervalChange('15m')}>15m</Button> }
                  { chartInterval === '5m' ? <ColorButton  variant="contained">5m</ColorButton> : <Button onClick={() => handleChartIntervalChange('5m')}>5m</Button> }
                  { chartInterval === '1m' ? <ColorButton  variant="contained">1m</ColorButton> : <Button onClick={() => handleChartIntervalChange('1m')}>1m</Button> }
              </ButtonGroup>
            </Stack>
            <Grid container sx={{marginTop: '20px'}}>
              <Grid item xs={12}>
                <div sx={{ width: '95%', minHeight: '500px', paddingTop: '100px'}} ref={chartRef} >{isLoading ? <CircularProgress /> : null }</div>
              </Grid>
            </Grid>
        </Stack>
      </Box>
    )
}

export default CoinDetailsLWChart