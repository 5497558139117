import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import TokenWizardHelperText from "./TokenWizardHelperText";
import React from "react";
import { useTheme } from '@mui/material/styles'

const SeedPrice = ({pageData, setPageData,}) => {
    const theme = useTheme()

    const handleHasSeedPriceChange = (e) => {
        setPageData({
            ...pageData,
            seedPriceChoice: e.target.value,
            showSeedPriceError: false,
        });
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant="h2" sx={{color: theme.palette.primary.main, marginTop: '20px'}}>Seed Price</Typography>
            <Stack direction="row" display='flex' alignItems='center'>

                <Typography align='center' variant="h4" sx={{ color: theme.palette.grey[500] }}>
                    Does the token have a seed price or other early investor price?
                </Typography>
                <TokenWizardHelperText
                    title={'Seed Price'}
                    text={'Many projects offer investors a discounted price 5-10x the current price. When investors recieve their token allocations, they will often sell since they can make an immediate profit of 5 - 10x. If a project has multiple seed or early investor prices, use the cheapest price. Different seed rounds may provide deeper price discounts.\nNote: The Details page of the project will show this info once uploaded.'} />
            </Stack>
            <FormControl sx={{mt: 1}}>
                <RadioGroup
                    aria-labelledby="tradepost-radio-buttons-group"
                    name="tradepost-radio-buttons-group"
                    value={pageData.seedPriceChoice}
                    onChange={handleHasSeedPriceChange}
                >
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                        {
                            pageData.seedPriceChoice === "Yes" && (
                                <Box sx={{width: 350}}>
                                    <TextField
                                        id="seedPrice"
                                        type="number"
                                        label="Enter Seed Price"
                                        inputProps={
                                            {
                                                step: 300
                                            }
                                        }
                                        color={'primary'}
                                        value={pageData.seedPrice}
                                        onChange={event => setPageData({...pageData, seedPrice: event.target.value})}
                                        style={{width: 300}}
                                    />
                                </Box>
                            )
                        }
                    </Box>
                    <FormControlLabel value={"No"} control={<Radio />} label="No" />
                    <FormControlLabel value={"Unknown"} control={<Radio />} label="Unknown" />
                </RadioGroup>
            </FormControl>
            {
                pageData.showSeedPriceError && (
                    <Typography align='center' variant="h3" sx={{ mt: 1, color: 'red' }}>
                        Please fill in a price or select No.
                    </Typography>
                )
            }
        </Box>
    )
}

export default SeedPrice;