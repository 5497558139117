import React from 'react'
import { Typography, IconButton, Stack, Tooltip, Radio, FormControlLabel, Box } from '@mui/material'

import { useTheme } from '@mui/material/styles'

import { TiDelete } from 'react-icons/ti'
import { AiOutlineRight, AiFillAlert } from 'react-icons/ai'

const ScreenerListLiveItem = ( { screener, changeScreener, selectedScreener, removeScreener, vipScreenerName, localUserData, changeVIPCoinsFromScreener} ) => {

    console.log("top of ScreenerListLiveItem")
    console.log("selectedScreener is: " + selectedScreener)
    console.log("screener is: " + screener)
    console.log("vipScreenerName is: " + vipScreenerName)
    const theme = useTheme()

    var displayFontWeight = 500
    var displayBackgroundColor = theme.palette.background.default
    var displayIcon = null
    if (selectedScreener === screener) {
        //this is the screener currently shown in the watchlist tool
        displayFontWeight = 700
        displayBackgroundColor = theme.palette.dark.dark
        displayIcon = <AiOutlineRight />
    }

    let currentVIPScreener = false
    if (vipScreenerName === screener) {
        //this is the screener used within the vip feature to set the current coins to use for filtering
        currentVIPScreener = true
    }

    const displayFavoritesIcoin = ( isScreenerFavorite ) => {
        if (isScreenerFavorite === 'Favorites') {
            return  <Tooltip title={"Coins in Favorites generate alerts in VIP"}><IconButton size="small" sx={{position: 'relative', top: '-2px' }}><AiFillAlert  color={theme.palette.success.main} /></IconButton></Tooltip>
        } else {
            return null
        }
    }    


    const displayRemoveIcon = (currentScreener) => {
        //console.log("top of displayRemoveIcon and currentScreener is: " + currentScreener + " and vipScreenerName is: " + vipScreenerName)
        if (vipScreenerName === currentScreener || currentScreener === 'Favorites' || currentScreener === 'All Coins') {
            //this is the screener currently shown in the watchlist tool
            return null
        } else {
            return (
                <Tooltip title={"remove "+currentScreener+" list"}><IconButton size="small" sx={{position: 'relative', top: '-1px' }} onClick={()=>removeScreener(currentScreener)}><TiDelete  color={theme.palette.warning.main} /></IconButton></Tooltip>
            )
        }
    }    

    let alertScreener = null
    //remove as favorites is just another named list now
    //if (screener === 'Favorites') {
    //    console.log("screener is Favorites")
    //    alertScreener = <Tooltip title={"Coins in Favorites generate alerts in VIP"}><IconButton size="small"><AiFillAlert color={theme.palette.success.main} /></IconButton></Tooltip>
    //}

    const displayScreenerFontWeight = (thisScreener) => {
        //console.log("top of displayScreenerFontWeight and localUserData.coin is")
        //console.dir(localUserData.coin)
        //console.log("thisScreener is: " + thisScreener)
        //console.log("selectedScreener is: " + selectedScreener)

        if (selectedScreener === thisScreener) {
            return 700
        } else if ( selectedScreener === '' && localUserData.coin.length === 0 && thisScreener === 'All Coins' ) {
            return 700
        } else {
            return 500
        }
    }    

    if (screener !== 'All Coins') {
        return (
            <Box sx={{width: '100%'}}>
                <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" 
                    sx={{
                        width: '100%',
                        backgroundColor: selectedScreener === screener ? theme.palette.dark.dark : theme.palette.background.default,
                        '&:hover': {
                            backgroundColor: theme.palette.dark.dark,
                        },                                
                    }}
                >
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start" onClick={()=>changeScreener(screener)}
                        sx={{
                            width: '90%',
                            padding: '3px',
                            marginBottom: '3px',
                            paddingLeft: '15px',
                            cursor: 'pointer',
                        }}
                    >
                        <Typography sx={{fontWeight: displayScreenerFontWeight(screener) }}> {screener} {displayFavoritesIcoin(screener)}</Typography>
                        {displayRemoveIcon(screener)}
                    </Stack>
                    <FormControlLabel value={screener} control={<Radio checked={currentVIPScreener} onChange={changeVIPCoinsFromScreener}/>} label="" />
                </Stack>
            </Box>         
        )
    } else {
        //do not display the all coins list if there
        return null
    }
}

export default ScreenerListLiveItem