import React, {useState, useRef, useEffect, useCallback} from 'react'
import { Box, FormControl, Select, MenuItem, CssBaseline,  Container,  
    Grid, Stack, Button, Typography, Tooltip, Snackbar, Alert } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'

import TopAppBar from '../TopAppBar'

import GridLayout from 'react-grid-layout'
//import  "../../assets/css/react-grid-layout.css"
//import "../../assets/css/react-resizable.css"
//import 'react-resizable/css/styles.css'
//import '../../assets/css/react-resizable.css'
//import '../../assets/scss/react-resizable.css'
//import './styles/react-resizable.css'
//import './react-resizable.css'

//import 'react-grid-layout/css/styles.css'
//import '../../assets/css/react-grid-layout.css'
//import '../../assets/scss/react-grid-layout.css'
//import './styles/react-grid-layout.css'
//import './react-grid-layout.css'

//import '/react-grid-layout.css'
//import '/react-resizable.css'

import TweetDisplayByCoin from './TweetDisplayByCoin'
import ChartDisplayByCoin from './ChartDisplayByCoin'
import ChartDisplayBTC from './ChartDisplayBTC'
import WatchListDisplay from './WatchListDisplay'
import ResearchDisplay from './ResearchDisplay'
import SocialInfluencerDisplay from './SocialInfluencerDisplay'
import VIPAlertsDisplay from './VIPAlertsDisplay'
import HealthDisplay from './HealthDisplay'
import VIPTweetsDisplay from './VIPTweetsDisplay'
import PortfolioDisplay from './PortfolioDisplay'
import CustomTweetFeedsDisplay from './CustomTweetFeedsDisplay'
import ResearchCustomAddNewLayout from './ResearchCustomAddNewLayout'
import ResearchCustomDeleteLayout from './ResearchCustomDeleteLayout'
import FeedDisplay from './FeedDisplay'
import fetchData from '../../utility/endpointFetch'

import { FaTwitter, FaChartLine, FaMicroscope, FaListUl } from 'react-icons/fa'
import { ConstructionOutlined, MonitorHeart, RssFeed } from '@mui/icons-material'
import { RiAccountPinCircleFill } from 'react-icons/ri'
import { BsAlarm } from 'react-icons/bs'
import { TiSocialTwitterCircular } from 'react-icons/ti'
import { RiVipCrownFill } from 'react-icons/ri'
import { BiDollarCircle } from 'react-icons/bi'
import { SiBitcoinsv } from 'react-icons/si'

import { componentBaseProperties } from './researchCustomComponentBaseProperties'


const ResearchCustom = ( { userObject } ) => {

    //console.log("top of ResearchCustom and userObject is:")
    //console.dir(userObject)

    const theme = useTheme()
    const refWidth = useRef(null)
    const defaultRowHeight = 100

    //need to reset if new layout is chosen
    //need to set when coin passed to dependents
    //need to set in customLayout and push to backend just before exiting page
    var componentCoinsRef = useRef([])
    //var componentSetCoinsRef = useRef([])

    const componentsToDisplay = {
        "TweetDisplayByCoin": TweetDisplayByCoin, 
        "ChartDisplayByCoin": ChartDisplayByCoin,
        "ChartDisplayBTC":ChartDisplayBTC,
        "WatchListDisplay": WatchListDisplay,
        "ResearchDisplay": ResearchDisplay,
        "SocialInfluencerDisplay": SocialInfluencerDisplay,
        "VIPAlertsDisplay": VIPAlertsDisplay,
        "HealthDisplay": HealthDisplay,
        "VIPTweetsDisplay": VIPTweetsDisplay,
        "PortfolioDisplay": PortfolioDisplay,
        "FeedDisplay": FeedDisplay,
        "CustomTweetFeedsDisplay": CustomTweetFeedsDisplay,
    }

    const [snackbarOpen,setSnackbarOpen] = useState(false)

    var draggingElement = {}

    var prime_starter_configuration = [
        {"i":"A","w":2,"h":5,"x":0,"y":0,"name":"WatchListDisplay","coins": [], "influencer_coin": '', "list":"Favorites","minW":2,"minH":3,"isBounded":true,"isDraggable":true,"static":false,"isResizable":true,"selectedTab":'1',"currentDependency":null},
        {"i":"B","w":5,"h":6,"x":2,"y":0,"name":"ResearchDisplay","coins": [], "influencer_coin": '', "list":"Favorites","minW":5,"minH":3,"isBounded":true,"isDraggable":true,"static":false,"isResizable":true,"selectedTab":'1',"currentDependency":null}
    ]

    var default_starter_configuration_object = 
        [ 
            {dashboard_name:"default", selected: true, configuration_details:[{i:"A",w:4,h:11,x:0,y:0,name:"TweetDisplayByCoin",currentDependency:true,coins:["litecoin","shiba-inu","polkadot"],influencer_coin:'',isBounded:true,isDraggable:true,static:false,selectedTab:'1',isResizable:true,resizeHandles:["se"]},{i:"B",w:3,h:6,x:9,y:5,name:"TweetDisplayByCoin",currentDependency:true,coins:["ethereum","bitcoin","tether","usd-coin"],influencer_coin:'',isBounded:true,isDraggable:true,static:false,selectedTab:'1',isResizable:true,resizeHandles:["se"]},{i:"C",w:3,h:5,x:4,y:0,name:"ChartDisplayByCoin",currentDependency:true,coins:["doge"],influencer_coin:'',minW:3,minH:5,isBounded:true,isDraggable:true,static:false,selectedTab:'1',isResizable:true},{i:"D",w:2,h:5,x:10,y:0,name:"WatchListDisplay",list:"Favorites",minW:2,minH:3,isBounded:true,isDraggable:true,static:false,selectedTab:'1',isResizable:true},{i:"F",w:3,h:5,x:7,y:0,name:"ChartDisplayByCoin",currentDependency:true,coins: ["btc"],influencer_coin:'',minW:3,minH:5,isBounded:true,isDraggable:true,static:false,selectedTab:'1',isResizable:true},{i:"E",w:5,h:6,x:4,y:5,name:"ResearchDisplay",list:"Favorites",minW:5,minH:3,isBounded:true,isDraggable:true,static:false,selectedTab:'1',isResizable:true}]},
            {dashboard_name:"layout 2", selected: false, configuration_details:prime_starter_configuration}
        ]        

    const getUserObjectDashboards = () => {
        //console.log("----------------top of getUserObjectDashboards--------------")
        //console.log("userObject.saved_dashboards is:")
        //console.dir(userObject.saved_dashboards)
        if (userObject.saved_dashboards.length === 0) {
            //this is the first time for this user....set the default starter configuration
            //there were continuous error trying to saveLayoutToUser here....so....do not save here...
            //let the layoutChange and add new layout handlers save their changes which seem to have no problems
            let jsonStarterDashboards = default_starter_configuration_object.map(thisConfiguration => JSON.stringify(thisConfiguration))
            //the userObject.saved_dashboards needs to be the json stringified version just as it would come from the database
            userObject.saved_dashboards = jsonStarterDashboards
            //but the allDashboards version needs to be the version of dashboards that has already been json parsed-like
            return default_starter_configuration_object
        } else {
            //as we added "selected" recently may need to make sure all current dashboards have "selected" in them
            let selectedCheck = userObject.saved_dashboards.map( checkingForSelected => {
                let parsedCheckingForSelected = JSON.parse(checkingForSelected)
                if (!parsedCheckingForSelected.hasOwnProperty('selected')) {
                    return { ...parsedCheckingForSelected, selected: false }
                } else {
                    return parsedCheckingForSelected
                }
            })
            //console.log("selectedCheck is:")
            //console.dir(selectedCheck)
            return selectedCheck
        }
    }

    const getLayoutLists = () => {
        console.log("top of getLayoutLists and userObject.saved_dashboards is:")
        console.dir(userObject.saved_dashboards)
        if (userObject.saved_dashboards.length > 0) {
            return userObject.saved_dashboards.map(layout => {
                //anything in userObject.saved_dashboards needs to be parsed to be useful
                try {
                    return JSON.parse(layout).dashboard_name
                } catch {
                    //this usually happens if the saved_dashboards property is corrupted
                    //the corruption takes place when the backend breaks the submitted array incorrectly making the data useless....best to just start over from scratch.  
                    return default_starter_configuration_object.map(currentStarter=> currentStarter.dashboard_name)     
                }
            })
        } else {
            return default_starter_configuration_object.map(currentStarter=> currentStarter.dashboard_name)     
        }
    }

    const getSelectedDefaultLayout = () => {
        let startingDashboards = getUserObjectDashboards()
        let selectedDashboard = 0
        //this will return the first one dashboard where selected === true...need to make sure only one dashboard is saved with true for selected
        startingDashboards.forEach( (thisDashboard,thisDashBoardIndex ) => {
            if (thisDashboard.selected === true) {
                selectedDashboard = thisDashBoardIndex
            }
        })
        console.log("at the end of getSelectedDefaultLayout and the selectedDashboard is:")
        console.dir(selectedDashboard)
        return selectedDashboard
    }
    
    const [layoutList,setLayoutList] = useState(getLayoutLists())
    const [chosenLayout,setChosenLayout] = useState(getSelectedDefaultLayout)
    const [saveButtonDisabled,setSaveButtonDisabled] = useState(true)

    //need to pass in customLayout,allDashboards,chosenLayout set within the calling function because these were not set to the 
    //new values before this was called.  These state variables were still set to old values when this was called....so pass in.
    const saveLayoutToUser = (saveCustomLayout=customLayout, saveAllDashboards=allDashboards, saveChosenLayout=chosenLayout) => {
        //saved_dashboards[] is the userObject property to save changes to
        console.log("--------------------top of saveLayoutToUser and saveCustomLayout is:")
        console.dir(saveCustomLayout)
        console.log("chosenLayout is:")
        console.dir(chosenLayout)
        ;(async () => {
            try {
                console.log("top of saveLayoutToUser with saveCustomLayout is: ")
                console.dir(saveCustomLayout)
                console.log("current saveChosenLayout is: ")
                console.dir(saveChosenLayout)
        
                let jsonDashboardsChanged = saveAllDashboards.map( (layout, layoutIndex) => {
                    if (layoutIndex === saveChosenLayout) {
                        return JSON.stringify({dashboard_name: layout.dashboard_name, selected: true, configuration_details: saveCustomLayout})
                    } else { 
                        return JSON.stringify({...layout, selected: false}) 
                    }
                })
                let newDashboards = saveAllDashboards.map( (layout, layoutIndex) => {
                    if (layoutIndex === saveChosenLayout) {
                        return {dashboard_name: layout.dashboard_name, selected: true, configuration_details: saveCustomLayout}
                    } else { 
                        return {...layout, selected: false}
                    }                    
                })
                setAllDashboards(newDashboards)
                //console.log("newDashboards is:")
                //console.dir(newDashboards)
                
                let newUserObject = {user: userObject.user, saved_dashboards: jsonDashboardsChanged}
                console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaajsonDashboardsChanged after adding new changed layout is:")
                console.dir(jsonDashboardsChanged)
            
                const fetchParams = {
                    method: 'PUT',
                    dataType: 'json',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newUserObject)
               
                }
                //console.log("newUserObject before saving to userdata endpoint is:")
                //console.dir(newUserObject)

                var urlSaveDashboardString = `api/v1/users/userdata/`
                const saveDashboardResponse = await fetchData(urlSaveDashboardString, "could not save dashboard", fetchParams).promise
                console.log("saveDashboardResponse saving dashboard: ")
                console.log(urlSaveDashboardString)
                console.dir(saveDashboardResponse)
                setSaveButtonDisabled(true) //changes saved....disable the save button
            } catch (error) {
                console.log("something is broke saving dashboard: ", error)
            }
        })()        
    }    
    const [allDashboards,setAllDashboards] = useState(()=>getUserObjectDashboards())

    const getConfiguration = (layoutArrayID) => {
        console.log("top of getConfiguration and layoutArrayID is:")
        console.dir(layoutArrayID)
        if (allDashboards) {
            //allDashboards has been set from the userObject.saved_dashboards use it
            //console.log("allDashboards is (already set) :")
            //console.dir(allDashboards)
            //console.log("allDashboards[layoutArrayID] is: ")
            //console.dir(allDashboards[layoutArrayID])
            //all from 

            //console.log("allDashboards[layoutArrayID].configuration_details is:")
            //console.dir(allDashboards[layoutArrayID].configuration_details)
            return allDashboards[layoutArrayID].configuration_details           
        } else if (userObject.saved_dashboards.length > 0) {
            let allDashboards = userObject.saved_dashboards.map(thisDashboard => JSON.parse(thisDashboard))
            //let allDashboards = JSON.parse(default_starter_configuration_object)
            //console.log("allDashboards is (NOT already set):")
            //console.dir(allDashboards)
            //console.log("2 - allDashboards[layoutArrayID].configuration_details is:")
            //console.dir(allDashboards[layoutArrayID].configuration_details)
            return allDashboards[layoutArrayID].configuration_details
        } else {
            //the saved_dashboards is empty....return the hardcoded configuration
            return default_starter_configuration_object[layoutArrayID]
        }
    }      
    
    const [customLayout,setCustomLayout] = useState(() => getConfiguration(getSelectedDefaultLayout()))
    const [windowWidth,setWindowWidth] = useState(0)

    const [key, setKey] = useState('A')

    const handleDashboardChange = (e) => {
        console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz top of handleDashboardChange and e.target.value is: " + e.target.value)
        console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm handleDashboardChange =======================just before setCustomLayout....setting chosenLayout is: " + chosenLayout + " and customLayout is:")
        console.dir(customLayout)
        //console.log("e.target.value (setting chosenLayout to) is: ")
        //console.dir(e.target.value)
        //console.log("and setting customLayout to:")
        //console.dir(() => getConfiguration(e.target.value))
        //need to set selected = true
        let newDashboards = allDashboards.map( (thisDashboard,thisDashBoardIndex) => {
            if (thisDashBoardIndex === e.target.value) {
                return {...thisDashboard, selected: true}
            } else {
                return {...thisDashboard, selected: false}
            }
        })
        //console.log("newDashboards is:")
        //console.dir(newDashboards)
        setAllDashboards(newDashboards)
        let settingCustomLayout = []
        if (allDashboards) {
            settingCustomLayout = allDashboards[e.target.value].configuration_details
        } else {
            settingCustomLayout = getConfiguration(e.target.value)
        }
        console.log("settingCustomLayout is now:")
        console.dir(settingCustomLayout)
        setCustomLayout(settingCustomLayout)
        console.log("setting ChosenLayout to:")
        console.dir(e.target.value)
        setChosenLayout(e.target.value)

        saveLayoutToUser(settingCustomLayout, newDashboards, e.target.value)
    }

    useEffect(() => {
        //console.log('width', refWidth.current ? refWidth.current.offsetWidth : 0);
        setWindowWidth(refWidth.current ? refWidth.current.offsetWidth : 0)
    }, [refWidth.current]);

    useEffect(() => {
        //console.log("userObject.saved_dashboards in the original useEffect call is:")
        //console.dir(userObject.saved_dashboards)
        //console.log("chosenLayout is: " + chosenLayout)
        //console.log("allDashboards is:")
        //console.dir(allDashboards)


        const startingIncrementChar = (currentLayout) => {
            //console.log("top of startingIncrementChar and startingLayout is:")
            //console.dir(currentLayout)
            //need to order by i so can get the 
            let x = currentLayout.sort((a, b) => (a.i > b.i ? 1 : -1))
    
            let nextLetter = incrementString(x[x.length-1].i)
            //console.log("nextLetter is:" + nextLetter)
            return nextLetter
        }

        if (userObject.saved_dashboards.length === 0) {
            setKey('G') //the default dashboard set should start at "G"
        } else {
            //if deleteing the current chosen layout...allDashboards[chosenLayout] may not exist...
            //so if allDashboards[chosenLayout] does not exist...then default will be set

            if (chosenLayout in allDashboards) {
                setKey(startingIncrementChar(allDashboards[chosenLayout].configuration_details))
            } else {
                setKey(startingIncrementChar(allDashboards[0].configuration_details))
            }
        }
    }, [chosenLayout,allDashboards,userObject.saved_dashboards]);
    
    const switchSelectedDashboard = (newDashboardID, newDashboards) => {
        //this is called from addNewLayout and so selected = true is already set for the new chosen layout/dashboard
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx top of switchSelectedDashboard and newDashboardID is: " + newDashboardID)
        console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm switchSelectedDashboard =======================just before setCustomLayout, chosenLayout is: " + chosenLayout + " and customLayout is:")
        console.dir(customLayout)
        //console.log("newDashboards is:")
        //console.dir(newDashboards)
        let settingCustomLayout = []
        if (newDashboards) {
            settingCustomLayout = newDashboards[newDashboardID].configuration_details
        } else {
            settingCustomLayout = getConfiguration(newDashboardID)
        }
        setCustomLayout(settingCustomLayout)
        setChosenLayout(newDashboardID)
        saveLayoutToUser(settingCustomLayout,newDashboards,newDashboardID)
    }

    const addNewLayout = (newLayoutName) => {
        //check if layout name already exists
        console.log("------------------------top of addNewLayout and newLayoutName is: " + newLayoutName)
        //console.log("allDashboards is:")
        //console.dir(allDashboards)
        //console.log("chosenLayout is:")
        //console.dir(chosenLayout)
        //need to set selected for all current dashboard and set the new dashboard as the current one below
        let newCompleteDashboardsList = allDashboards.map( (thisDashboard) => JSON.stringify(thisDashboard) )
        //let newCompleteDashboardsList = [...allDashboards, {"dashboard_name": newLayoutName, "configuration_details": allDashboards[chosenLayout].configuration_details } ]
        newCompleteDashboardsList.push(JSON.stringify({"dashboard_name": newLayoutName, "selected": true, "configuration_details": allDashboards[chosenLayout].configuration_details }))
        let newAllDashboards = [...allDashboards, {"dashboard_name": newLayoutName, "selected": true, "configuration_details": allDashboards[chosenLayout].configuration_details }]
        setAllDashboards(newAllDashboards)

        switchSelectedDashboard(newAllDashboards.length - 1,newAllDashboards)

        let newLayoutList = newCompleteDashboardsList.map(layout => {
            return JSON.parse(layout).dashboard_name
        })
        setLayoutList(newLayoutList)
        
        let newUserObject = {"saved_dashboards": newCompleteDashboardsList}
        const fetchParams = {
            method: 'PUT',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(newUserObject)
            
        }
        ;(async () => {
            try {
                var urlNewDashboardString = `api/v1/users/userdata/`
                const newDashboardResponse = await fetchData(urlNewDashboardString, "could not add new dashboard", fetchParams).promise
                //console.log("newDashboardResponse add new dashboard: ")
                //console.log(urlNewDashboardString)
                //console.dir(newDashboardResponse)

                setSaveButtonDisabled(true)
               
            } catch (error) {
                console.log("something is broke adding new dashboard: ", error)
            }
        })()            
    }

    const deleteLayout = (layoutToDelete) => {
        //layoutToDelete is the name of the layout to delete from allDashboards
        console.log("-------------------------top of deleteLayout and layoutToDelete is: " + layoutToDelete)
        //console.log("chosenLayout is:")
        //console.dir(chosenLayout)
        //console.log("allDashboards is: ")
        //console.dir(allDashboards)
        let dashboardssWithoutDeleted = allDashboards.filter( (layout) => layout.dashboard_name !== layoutToDelete)
        let jsonDashboardWithoutDeleted = dashboardssWithoutDeleted.map( dashboard => JSON.stringify(dashboard))
        let newUserObject = {user: userObject.user, saved_dashboards: jsonDashboardWithoutDeleted}
        setAllDashboards(dashboardssWithoutDeleted)

        if ( chosenLayout in allDashboards && layoutToDelete === allDashboards[chosenLayout].dashboard_name) {
            //delete and set default as chosenLayout
            setChosenLayout(0)
            setCustomLayout(() => getConfiguration(0))
        } else if (!(chosenLayout in allDashboards)) {
            //something wrong...currently selected layout is not in allDashboards....set to default:
            setChosenLayout(0)
            setCustomLayout(() => getConfiguration(0))
        }
        
        let newLayoutList = dashboardssWithoutDeleted.map(layout => {
            return layout.dashboard_name
        })
        setLayoutList(newLayoutList)        

        const fetchParams = {
            method: 'PUT',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(newUserObject)
        }
        ;(async () => {
            try {
                var urlRemoveDashboardString = `api/v1/users/userdata/`
                const removeDashboardResponse = await fetchData(urlRemoveDashboardString, "could not remove dashboard", fetchParams).promise
                console.log("removeDashboardResponse remove dashboard: ")
                console.log(urlRemoveDashboardString)
                console.dir(removeDashboardResponse)

                setSaveButtonDisabled(true)

            } catch (error) {
                console.log("something is broke remove dashboard: ", error)
            }
        })()  


    }

    const deleteBox = (panelID) => {
        //this removes panel from the layout
        console.log("top of deleteBox...customLayout.length is: " + customLayout.length)
        if (customLayout.length > 1) {
            saveLayoutToUser(customLayout.filter( layout => layout.i !== panelID))     
            setCustomLayout(customLayout.filter( layout => layout.i !== panelID))
            setSaveButtonDisabled(false)
        } else {
            setSnackbarOpen(true) 
        }
    }

    const closeSnackbar = ( event, reason ) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setSnackbarOpen(false);
    }

    const saveCoinChoiceToPanel = (coinsToSave, coinsToSaveID, panelID) => {
        console.log("top of saveCoinChoiceToPanel and coinsToSave is:")
        console.dir(coinsToSave)
        //console.log("panelID is:")
        //console.dir(panelID)
        //save coin in customLayout
        let newCustomLayoutAfterChange = customLayout.map( thisLayout => {
            if (thisLayout.i === panelID) {
                thisLayout.coins = coinsToSave
                thisLayout.influencer_coin = coinsToSaveID
                //this is layout that's changing
            }
            return thisLayout
        })
        setCustomLayout(newCustomLayoutAfterChange)
        setSaveButtonDisabled(false)
        //saveLayoutToUser(newCustomLayoutAfterChange)
    }

    const changeDependency = (changePanelID,dependencySelected) => {
        //console.log("top of changeDependency and changePanelID is:")
        //console.dir(changePanelID)
        //console.log("dependencySelected is:")
        //console.dir(dependencySelected)
        //save dependency in customLayout
        let newCustomLayoutAfterChange = customLayout.map( thisLayout => {
            if (thisLayout.i === changePanelID) {
                //this is layout that's changing
                if ((thisLayout.name === "TweetDisplayByCoin") || (thisLayout.name === 'ChartDisplayByCoin')) {
                    thisLayout.currentDependency = dependencySelected
                } 
            }
            return thisLayout
        })
        setCustomLayout(newCustomLayoutAfterChange)
        setSaveButtonDisabled(false)
        //saveLayoutToUser(newCustomLayoutAfterChange)        
    }

    const saveSelectedList = (selectedList,panelID) => {
        //console.log("top of saveSelectedList and selectedList is:")
        //console.dir(selectedList)
        //console.log("panelID is:")
        //console.dir(panelID)
        //save list in customLayout
        let newCustomLayoutAfterChange = customLayout.map( thisLayout => {
            if (thisLayout.i === panelID) {
                //this is layout that's changing
                //check type just in case
                if (thisLayout.name === "WatchListDisplay") {
                    thisLayout.list = selectedList
                } 
            }
            return thisLayout
        })
        setCustomLayout(newCustomLayoutAfterChange)
        setSaveButtonDisabled(false)
        //saveLayoutToUser(newCustomLayoutAfterChange)        
    }

    const incrementChar = (c) => {
        setKey(String.fromCharCode(c.charCodeAt(0) + 1));
    }    

    function incrementString(value) {
        let carry = 1;
        let res = '';
      
        for (let i = value.length - 1; i >= 0; i--) {
          let char = value.toUpperCase().charCodeAt(i);
      
          char += carry;
      
          if (char > 90) {
            char = 65;
            carry = 1;
          } else {
            carry = 0;
          }
      
          res = String.fromCharCode(char) + res;
      
          if (!carry) {
            res = value.substring(0, i) + res;
            break;
          }
        }
      
        if (carry) {
          res = 'A' + res;
        }
      
        return res;
    }
    
    const resizedBox = (layout, oldItem, newItem, placeholder, e, element) => {
        //console.log("TOP OF RESIZEDBOX")

        let newCustomLayout = customLayout.map( currentBox => {
            if (newItem.i === currentBox.i) {
                //this is the box resized....so update w,h
                return {
                    i: currentBox.i,
                    w: newItem.w,
                    h: newItem.h,
                    x: currentBox.x,
                    y: currentBox.y,
                    coins: currentBox.coins,
                    influencer_coin: currentBox.influencer_coin,
                    list: currentBox.list,
                    currentDependency: currentBox.currentDependency,
                    name: currentBox.name,
                    minW: currentBox.minW,
                    minH: currentBox.minH,
                    selectedTab: currentBox.selectedTab,
                    isBounded: true, 
                    isDraggable: true,  
                    static: false, 
                    isResizable: true,
                    resizeHandles: currentBox.resizeHandles,                    
                }
            } else {
                return currentBox //return 
            }
        })            

        setCustomLayout(newCustomLayout)

        //set the newCustomLayout within the current allDashboards for the selected dashboard
        let newAllDashboards = allDashboards.map( (dashboard,dashBoardIndex) => {
            if (dashBoardIndex === chosenLayout) {
                return {dashboard_name: dashboard.dashboard_name, configuration_details: newCustomLayout}
            } else {
                return dashboard
            }
        })
        setAllDashboards(newAllDashboards)
        setSaveButtonDisabled(false)  
    }
    const dragStopHandler = (layout, oldItem, newItem, placeholder, e, element) => {
        console.log("top of dragStopHandler")
        //this simply moves a box around the layout
        //the only things to save are the x and y as those are the only things that can 
        //change when dragging a component

        //need to preserve the current order of boxes in the current layout or will change the design
        let newCustomLayout = customLayout.map( currentBox => {
            if (newItem.i === currentBox.i) {
                //this is the box moved....so update x,y
                return {
                    i: currentBox.i,
                    w: currentBox.w,
                    h: currentBox.h,
                    x: newItem.x,
                    y: newItem.y,
                    coins: currentBox.coins,
                    influencer_coin: currentBox.influencer_coin,
                    list: currentBox.list,
                    currentDependency: currentBox.currentDependency,
                    name: currentBox.name,
                    minW: currentBox.minW,
                    minH: currentBox.minH,
                    selectedTab: currentBox.selectedTab,
                    isBounded: true, 
                    isDraggable: true,  
                    static: false, 
                    isResizable: true,
                    resizeHandles: currentBox.resizeHandles,                    
                }
            } else {
                return currentBox //return 
            }
        })            
        console.log("setting customLayout to:")
        console.dir(newCustomLayout)
        setCustomLayout(newCustomLayout)

        //set the newCustomLayout within the current allDashboards for the selected dashboard
        let newAllDashboards = allDashboards.map( (dashboard,dashBoardIndex) => {
            if (dashBoardIndex === chosenLayout) {
                return {dashboard_name: dashboard.dashboard_name, configuration_details: newCustomLayout}
            } else {
                return dashboard
            }
        })
        //console.log("setting allDashboards to the following after a drag change in the current layout:")
        //console.dir(newAllDashboards)
        setAllDashboards(newAllDashboards)
        setSaveButtonDisabled(false)        
        
        //no way to save the data currently
        
    }
    

    const dropHandler = (layout, layoutItem, e) => {
        console.log("TOP OF DROPHANDLER")

        let boxWidth = layoutItem.w < draggingElement.minW ? draggingElement.minW : layoutItem.w
        let boxHeight = layoutItem.h < draggingElement.minH ? draggingElement.minH : layoutItem.h

        let newLayoutItemToSave = {...draggingElement, i: key, x: layoutItem.x, y: layoutItem.y, w: boxWidth, h: boxHeight}
        console.log("adding this to customLayout:")
        console.dir(newLayoutItemToSave)
        setCustomLayout((initial) => [...initial, newLayoutItemToSave]);
        incrementChar(key);
        setSaveButtonDisabled(false)
        draggingElement = {} //reset drag start
    }    

    const coinToDependents = useCallback ((coin) => {
        //set coin passed in dependentCoin to permeate to boxes set to dependent
        console.log("top of coinToDependents and coin is: --------------------------------------------------------------------")
        console.dir(coin)
        console.log("customLayout is:")
        console.dir(customLayout)
        //coin is passed as an object
        //save coin choice to dependent boxes
        //the source provides the coin object
        let newCustomLayoutAfterChange = customLayout.map( thisLayout => {
            if ((thisLayout.name === "TweetDisplayByCoin") || (thisLayout.name === 'ChartDisplayByCoin')) {
                //these are the only two box types that can be dependent/independent
                if (thisLayout.currentDependency === true || thisLayout.currentDependency === 'true') {
                    //this box's coin configuration is changing because it is dependent

                    //if we set this layout from here a full layout rerender will be triggered.
                    //so set the coin in componenentCoins and call the exposed function in the child ChartDisplayByCoin or TweetDisplayByCoin
                    //component.  Calling that component will set chosenCoin in those components and that should only rerender the affected
                    //component and NOT the whole layout

                    var newItemCoinsObject = { "coins": [coin.coin_symbol], "influencer_coin": [coin.coin]}

                    if (thisLayout.name === "ChartDisplayByCoin") {
                        //charts only accept a single coin
                        console.log("setting ChartDisplayByCoin coins to coin.coin_symbol to:")
                        console.dir(coin.coin_symbol)
                        console.log("setting ChartDisplayByCoin influencer_coin to coin.coin to:")
                        console.dir(coin.coin)                        
                        //componentCoinsRef.current[thisLayout.i] = newItemCoinsObject
                        thisLayout.coins = [coin.coin_symbol]
                        thisLayout.influencer_coin = [coin.coin]
                        //console.log("componentSetCoinsRef.current in setting ChartDisplayByCoin is:")
                        //console.dir(componentSetCoinsRef)
                        //componentSetCoinsRef && componentSetCoinsRef.current.setChosenCoinInComponent(newItemCoinsObject)

                    } else {
                        //tweet boxes accept one or more coins....so set array
                        //console.log("componentSetCoinsRef.current in setting TweetDisplayByCoin is:")
                        //console.dir(componentSetCoinsRef)
                        //componentSetCoinsRef && componentSetCoinsRef.current.setChosenCoinInComponent(newItemCoinsObject)
                        console.log("setting TweetDisplayByCoin coins to:")
                        console.dir(coin.coin)
                        thisLayout.coins = [coin.coin]
                    }
                } else {
                    //this box is independent....do not change
                }
            } 
            return thisLayout
        })

        //console.log("componentSetCoinsRef is:")
        //console.dir(componentSetCoinsRef)

        //but this leaves the old coin set within the layout object and not the new
        //so the componentCoinsRef values for the layout should be set in the layout at time of page exit     
        console.log("setting customLayout and saveLayoutToUser to:")
        console.dir(newCustomLayoutAfterChange)   
        setCustomLayout(newCustomLayoutAfterChange)
        setSaveButtonDisabled(false)
        saveLayoutToUser(newCustomLayoutAfterChange)              
    },[] )

    //const tweetDisplayByCoinStyle = {
    //    overflowY:'auto',
    //}

    const clickToSaveLayoutHandler = () => {
        saveLayoutToUser()
    }

    //need to pass in  newCustomLayout,chosenLayout set within the calling function because these were not set to the 
    //new values before this was called.  These state variables were still set to old values when this was called....so pass in.
    const saveLayoutTabToUser = (saveCustomLayout) => {
        //saved_dashboards[] is the userObject property to save changes to

        ;(async () => {
            try {
                //console.log("top of saveLayoutTabToUser with saveCustomLayout is: ")
                //console.dir(saveCustomLayout)
                //console.log("current chosenLayout is: ")
                //console.dir(chosenLayout)
        
                let jsonDashboardsChanged = allDashboards.map( (layout, layoutIndex) => {
                    if (layoutIndex === chosenLayout) {
                        return JSON.stringify({dashboard_name: layout.dashboard_name, selected: true, configuration_details: saveCustomLayout})
                    } else { 
                        return JSON.stringify({...layout, selected: false}) 
                    }
                })
                
                let newUserObject = {user: userObject.user, saved_dashboards: jsonDashboardsChanged}
                //console.log("saveLayoutTabToUser-----aaaaaaaaaaaaajsonDashboardsChanged after adding new changed layout is:")
                //console.dir(jsonDashboardsChanged)
            
                const fetchParams = {
                    method: 'PUT',
                    dataType: 'json',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newUserObject)
               
                }
                //console.log("newUserObject before saving to userdata endpoint is:")
                //console.dir(newUserObject)

                var urlSaveDashboardString = `api/v1/users/userdata/`
                const saveDashboardResponse = await fetchData(urlSaveDashboardString, "could not save dashboard", fetchParams).promise
                //console.log("saveDashboardResponse saving dashboard: ")
                //console.log(urlSaveDashboardString)
                //console.dir(saveDashboardResponse)
            } catch (error) {
                console.log("something is broke saving dashboard to user in saveLayoutTabToUser: ", error)
            }
        })()        
    }      

    const changeTabInLayout = (newTab, panelID='') => {
        console.log("top of changeTabInLayout and newTab is: " + newTab)
        console.log("customLayout is currently:")
        console.dir(customLayout)
        console.log("panelID is: " + panelID)
        
        //need to save in layout
        //need to save that layout change automatically
        if (panelID !== '') {
            let newCustomLayoutAfterChange = customLayout.map( thisLayout => {
                //charts only accept a single coin
                if (thisLayout.i === panelID) thisLayout.selectedTab = newTab
                return thisLayout
            })     

            console.log("new customLayout will be: ")
            console.dir(newCustomLayoutAfterChange)
            //setCustomLayout(newCustomLayoutAfterChange)
            saveLayoutTabToUser(newCustomLayoutAfterChange)
        } else {
            console.log("no panelID passed within changeTabInLayout")
        }
    }

    const StyledGridLayout = styled(GridLayout)(({ theme }) => ({
        '& .react-grid-layout': {
            position: 'relative',
            transition: 'height 200ms ease',
        },
        '& .react-grid-item': {
            transition: 'all 200ms ease',
            transitionProperty: 'left, top',
        },
        '& .react-grid-item img': {
            pointerEvents: 'none',
            userSelect: 'none',  
        },
        '& .react-grid-item.cssTransforms': {
            transitionProperty: 'transform',
        },
        '& .react-grid-item.resizing': {
            zIndex: 1,
            willChange: 'width, height',
        },
        
        '& .react-grid-item.react-draggable-dragging': {
            transition: 'none',
            zIndex: 3,
            willChange: 'transform',
        },
        
        '& .react-grid-item.dropping': {
            visibility: 'hidden',
        },
        
        '& .react-grid-item.react-grid-placeholder': {
            background: 'red',
            opacity: 0.2,
            transitionDuration: '100ms',
            zIndex: 2,
            WebkitUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            OUserSelect: 'none',
            userSelect: 'none',
        },
        '& .react-resizable-handle::after': {
            //content: '',
            position: 'absolute',
            right: '3px',
            bottom: '3px',
            width: '10px',
            height: '10px',
            borderRight: '2px solid rgba(171, 172, 233, 0.4)',
            borderBottom: '2px solid rgba(171, 172, 233, 0.4)',
        },
        
        '& .react-resizable-hide > .react-resizable-handle': {
            display: 'none',
        },
        
        '& .react-resizable-handle.react-resizable-handle-sw': {
            bottom: 0,
            left: 0,
            cursor: 'sw-resize',
            transform: 'rotate(90deg)',
        },
        '& .react-resizable-handle.react-resizable-handle-se': {
            bottom: 0,
            right: 0,
            cursor: 'se-resize',
        },
        '& .react-resizable-handle.react-resizable-handle-nw': {
            top: 0,
            left: 0,
            cursor: 'nw-resize',
            transform: 'rotate(180deg)',
        },
        '& .react-resizable-handle.react-resizable-handle-ne': {
            top: 0,
            right: 0,
            cursor: 'ne-resize',
            transform: 'rotate(270deg)',
        },
        '& .react-resizable-handle.react-resizable-handle-w': {
            top: '50%',
            marginTop: '-10px',
            cursor: 'ew-resize',
            left: 0,
            transform: 'rotate(135deg)',
        },
        '& .react-resizable-handle.react-resizable-handle-e': {
            top: '50%',
            marginTop: '-10px',
            cursor: 'ew-resize',
            right: 0,
            transform: 'rotate(315deg)',
        },
        '& .react-resizable-handle.react-resizable-handle-n': {
            left: '50%',
            marginLeft: '-10px',
            cursor: 'ns-resize',
            top: 0,
            transform: 'rotate(225deg)',
        },
        '& .react-resizable-handle.react-resizable-handle-s': {
            left: '50%',
            marginLeft: '-10px',
            cursor: 'ns-resize',
            bottom: 0,
            transform: 'rotate(45deg)',
        },
        '& .react-resizable': {
            position: 'relative'
        },
        '& .react-resizable-handle': {
            position: 'absolute',
            width: '20px',
            height: '20px',
            backgroundRepeat: 'no-repeat',
            backgroundOrigin: 'content-box',
            boxSizing: 'border-box',
            backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+)',
            backgroundPosition: 'bottom right',
            padding: '0 3px 3px 0',
        },
        '& .react-resizable-handle-sw': {
            bottom: 0,
            left: 0,
            cursor: 'sw-resize',
            transform: 'rotate(90deg)'
        },
        '& .react-resizable-handle-se': {
            bottom: 0,
            right: 0,
            cursor: 'se-resize'
        },
        '& .react-resizable-handle-nw': {
            top: 0,
            left: 0,
            cursor: 'nw-resize',
            transform: 'rotate(180deg)'
        },
        '& .react-resizable-handle-ne': {
            top: 0,
            right: 0,
            cursor: 'ne-resize',
            transform: 'rotate(270deg)'
        },
        '& .react-resizable-handle-w': {
            top: '50%',
            marginTop: '-10px',
            cursor: 'ew-resize',
            left: 0,
            transform: 'rotate(135deg)',            
        },
        '& .react-resizable-handle-e': {
            top: '50%',
            marginTop: '-10px',
            cursor: 'ew-resize',
            right: 0,
            transform: 'rotate(315deg)',            
        },
        '& .react-resizable-handle-n': {
            left: '50%',
            marginLeft: '-10px',
            cursor: 'ns-resize',
            top: 0,
            transform: 'rotate(225deg)',            
        },
        '& .react-resizable-handle-s': {
            left: '50%',
            marginLeft: '-10px',
            cursor: 'ns-resize',
            bottom: 0,
            transform: 'rotate(45deg)',            
        },
    }))

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <TopAppBar userObject={userObject} selectedPage="desktop"/>
            <Container maxWidth={false} disableGutters sx={{marginTop: '80px'}}>         
                <Grid container spacing={.5} sx={{marginTop: '10px'}}>  
                    <Grid item xs={12}>
                        <Stack direction='row' display='flex' alignItems='center' justifyContent='space-between'>
                            <Stack direction='row' display='flex' alignItems='center' justifyContent='flex-start'>
                                <Typography sx={{marginLeft: '15px', marginRight: '15px', color: theme.palette.grey[300]}}>drag/drop:</Typography>
                                <Tooltip title="add Tweets By Coin">
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.tweetDisplayByCoin
                                            console.log("drag started on tweet box:")
                                            console.dir(e)
                                        }} 
                                    >
                                        <FaTwitter 
                                            size={20} 
                                            color={theme.palette.primary.dark} 
                                        />
                                    </Box>
                                </Tooltip>
                                <Tooltip title="add Chart">
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.chartDisplayByCoin
                                            console.log("drag started on chart box:")
                                            console.dir(e)
                                        }} 
                                    >
                                        <FaChartLine 
                                            size={20} 
                                            color={theme.palette.primary.dark} 
                                        />                              
                                    </Box> 
                                </Tooltip> 
                                <Tooltip title="add BTC Chart">
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.chartDisplayBTC
                                            console.log("drag started on BTC chart box:")
                                            console.dir(e)
                                        }} 
                                    >
                                        <SiBitcoinsv 
                                            size={20} 
                                            color={theme.palette.primary.dark} 
                                        />                              
                                    </Box> 
                                </Tooltip>                                 
                                <Tooltip title="add Watchlist">    
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.watchListDisplay
                                            console.log("drag started on watchlist box:")
                                            console.dir(e)
                                        }} 
                                    >                                                                                  
                                        <FaListUl 
                                            size={20} 
                                            color={theme.palette.primary.dark} 
                                        />
                                    </Box>
                                </Tooltip>
                                <Tooltip title="add Research">
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.researchDisplay
                                            console.log("drag started on research box:")
                                            console.dir(e)
                                        }} 
                                    >                              
                                        <FaMicroscope 
                                            size={16} 
                                            color={theme.palette.primary.dark} 
                                        />
                                    </Box>
                                </Tooltip>
                                <Tooltip title="add Influencer">
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.influencerDisplay
                                            console.log("drag started on social influencer box:")
                                            console.dir(e)
                                        }} 
                                    >                              
                                        <RiAccountPinCircleFill 
                                            size={20} 
                                            color={theme.palette.primary.dark} 
                                        />
                                    </Box>
                                </Tooltip>
                                <Tooltip title="add VIP Alerts">
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.vipAlertsDisplay
                                            console.log("drag started on vip alerts box:")
                                            console.dir(e)
                                        }} 
                                    >                              
                                        <BsAlarm 
                                            size={20} 
                                            color={theme.palette.primary.dark} 
                                        />
                                    </Box>  
                                </Tooltip>
                                <Tooltip title="add Health">   
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.healthDisplay
                                            console.log("drag started on bitcoin health box:")
                                            console.dir(e)
                                        }} 
                                    >                              
                                        <MonitorHeart 
                                            sx={{color: theme.palette.primary.dark}}
                                            size={20} 
                                        />
                                    </Box>
                                </Tooltip>  
                                <Tooltip title="add VIP tweets">      
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.vipTweetDisplay
                                            console.log("drag started on vip tweets box:")
                                            console.dir(e)
                                        }} 
                                    >                              
                                        <RiVipCrownFill 
                                            color={theme.palette.primary.dark}
                                            size={23} 
                                        />
                                    </Box>  
                                </Tooltip>
                                <Tooltip title="add portfolio">
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.portfolioDisplay
                                            console.log("drag started on portfolio box:")
                                            console.dir(e)
                                        }} 
                                    >                              
                                        <BiDollarCircle 
                                            color={theme.palette.primary.dark}
                                            size={24} 
                                        />
                                    </Box>
                                </Tooltip>    
                                <Tooltip title="add general twitter feed">
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.feedDisplay
                                            console.log("drag started on general twitter feed box:")
                                            console.dir(e)
                                        }} 
                                    >                              
                                        <RssFeed 
                                            sx={{color: theme.palette.primary.dark}}
                                            size={20} 
                                        />
                                    </Box>
                                </Tooltip>    
                                <Tooltip title="add Custom Tweet Feeds">      
                                    <Box
                                        sx={{ marginLeft: '15px', cursor: 'grabbing'}}
                                        draggable={true}
                                        onDragStart={(e)=>{
                                            draggingElement = componentBaseProperties.customTweetFeedsDisplay
                                            console.log("drag started on vip tweets box:")
                                            console.dir(e)
                                        }} 
                                    >                              
                                        <TiSocialTwitterCircular 
                                            color={theme.palette.primary.dark}
                                            size={25} 
                                        />
                                    </Box>  
                                </Tooltip>                                                                                                                                                      
                            </Stack>
                            <Stack direction='row' display='flex' alignItems='center' justifyContent='flex-end' sx={{marginRight: '15px'}}>
                                <Button 
                                    disabled={saveButtonDisabled}
                                    onClick={clickToSaveLayoutHandler}
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    sx={{marginRight: '20px'}}
                                >
                                    save dashboard changes
                                </Button>
                                <Typography sx={{marginRight: '5px', color: theme.palette.grey[300]}}>Current Dashboard:</Typography>
                                <FormControl style={{minWidth: '200px'}} size="small">
                                    {/*<InputLabel id="layout-selection-label">Current Dashboard</InputLabel>*/}
                                    <Select
                                        labelId="layout-selection"
                                        id="layout-selection"
                                        value={chosenLayout in allDashboards ? chosenLayout : 0}
                                        label="Current Dashboard"
                                        onChange={handleDashboardChange}
                                    >
                                        {layoutList.map( (layout, layoutIndex) => <MenuItem key={layoutIndex} value={layoutIndex}>{layout}</MenuItem>)}
                                    </Select>
                                </FormControl>        
                                <ResearchCustomAddNewLayout 
                                    addNewLayout={addNewLayout} 
                                    deleteLayout={deleteLayout}
                                />        
                                <ResearchCustomDeleteLayout 
                                    layoutList={layoutList} 
                                    deleteLayout={deleteLayout}
                                />                                              
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} ref={refWidth}> 
                    
                        <StyledGridLayout
                            cols={12}
                            className="layout"
                            rowHeight={defaultRowHeight}
                            width={windowWidth}
                            verticalCompact={true}
                            compactType='vertical'
                            layout={customLayout}
                            resizeHandles={[ 's', 'ne', 'se' ]}
                            //resizeHandles={[ 'se' ]}
                            onDrop={dropHandler}
                            isDroppable={true}
                            //onDragStart={dragStartHandler}
                            onDragStop={dragStopHandler}
                            //onLayoutChange={layoutChange}
                            draggableHandle=".draggable-handle"
                            onResizeStop={resizedBox}
                            //onResizeStart={resizingBoxStart}
                            //margin={[5,5]}
                            //useCSSTransforms={true}
                            sx={{position: 'relative', transition: 'height 200ms ease'}}
                        >
                            {customLayout.map((item) => {
                                //console.log("inside customLayout.map and item is:")
                                //console.dir(item)

                                var ComponentToCall = componentsToDisplay[item.name]

                                //when first building components
                                componentCoinsRef.current[item.i] = { "coins": item.coins, "influencer_coin": item.influencer_coin }
                                return (
                                        <Box 
                                            sx={{ 
                                                display: 'flex', 
                                                border: '1px solid '+theme.palette.primary.dark, 
                                                width: '100%',
                                            }}
                                            key={item.i}
                                            //style={tweetDisplayByCoinStyle}
                                            data-grid={{"w":item.w,"h":item.h,"x":item.x,"y":item.y}}
                                        >
                                            <ComponentToCall 
                                                panelID={item.i} 
                                                saveCoinChoiceToPanel={saveCoinChoiceToPanel} 
                                                windowWidth={windowWidth}
                                                deleteBox={deleteBox}
                                                userObject={userObject}
                                                saveSelectedList={saveSelectedList}
                                                changeDependency={changeDependency}
                                                coinToDependents={coinToDependents}
                                                currentDependency={item.currentDependency}
                                                boxHeight={item.h}
                                                boxWidth={((windowWidth / 12) * item.w)}
                                                rowHeight={defaultRowHeight}
                                                coins={item.coins}
                                                influencer_coin={item.influencer_coin}
                                                list={item.list}
                                                selectedTab={item.selectedTab}
                                                changeTabInLayout={changeTabInLayout}
                                                //componentSetCoinsRef={componentSetCoinsRef[item.i]}
                                            />
                                        </Box>
                                    )
                                }
                            )}
                        </StyledGridLayout>
                    </Grid>
                </Grid>
            </Container>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="warning" sx={{ width: '100%', background: theme.palette.warning }} >
                Must have more than one action box to remove!
                </Alert>
            </Snackbar>            
        </Box>
    )
}

export default ResearchCustom