import React, { useState, useEffect } from 'react'
import { Grid, Pagination, Divider, Stack, Typography, CircularProgress } from '@mui/material'

import VIPTwitterPost from './VIPTwitterPost'
import VIPTelegramPost from './VIPTelegramPost'
import VIPDiscordPost from './VIPDiscordPost'
import VIPRedditPost from './VIPRedditPost'
import VIPResearchPost from './VIPResearchPost'
import fetchData from '../../utility/endpointFetch'

import { useTheme } from '@mui/material/styles'
import {FiExternalLink} from 'react-icons/fi'

const VIPAllHash = ( { hashTabValue, setCoinInChart, changeAtFollowedTab, changeAtMentionedTab, changeHashTab, changeCoinInTab, changeDateInChart, showOfficialTweets, divHeight=0, boxWidth=0 }) => {

  const theme = useTheme()

  const [isLoading,setIsLoading] = useState(false)
  const [allHashedListings,setAllHashedListings] = useState([])
  const [twitterPage,setTwitterPage] = useState(1)
  const [twitterPageCount,setTwitterPageCount] = useState(1)
  const [responseCount,setResponseCount] = useState(0)

  useEffect(() => {
    if (hashTabValue.length > 0) {
      setIsLoading(true)

      let filteredHashTabValue = hashTabValue.replace('#','')

      const fetchParams = {
        method: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
      }
      ;(async () => {
        try {

          //const twitterResponse = await fetchData(`api/v1/feed/twitter/?search=${filteredHashTabValue}&page=${twitterPage}`, "could not receive all twitter data", fetchParams).promise
          var twitterResponseURL = `api/v1/feed/alltweets/?search=${filteredHashTabValue}&page=${twitterPage}`
          const twitterResponse = await fetchData(twitterResponseURL, "could not receive all twitter data in vip hash", fetchParams).promise
          //console.log("twitterResponse getting twitter data from api for VIPAllHash")
          //console.log(`uri used: api/v1/feed/twitter/?search=${filteredHashTabValue}&page=${twitterPage}`)
          //console.dir(twitterResponse)

          setResponseCount(twitterResponse.count)
          //setResponseCount(twitterResponseTestData.count)

          
          if (twitterResponse.count > 100) {
            setTwitterPageCount(Math.ceil(twitterResponse.count/100))
          } else {
            setTwitterPageCount(1)
          }
          
          setAllHashedListings(twitterResponse.results)
          //setAllHashedListings(twitterResponseTestData.results)

          setIsLoading(false)
        } catch (error) {
          console.log("error","something is broke getting all twitter: " + error)
        }
      })()
    } else {
      setAllHashedListings([])
    }

  }, [hashTabValue, twitterPage])

  const changeTwitterPage = (event, page) => {
    setIsLoading(true)
    setTwitterPage(page)
  }

  if (isLoading) return <CircularProgress />
  if (allHashedListings.length > 0) {

    const linkToHashes = "https://twitter.com/search/?q=%28%23"+hashTabValue.replace("#", "")

    return (
      <Grid container>
        <Grid item xs={12}>
          <Divider sx={{width:'100%'}} textAlign="left" >
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[600], paddingLeft: '20px', marginTop: '20px'}}>{responseCount}</Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 500, color: theme.palette.grey[400], marginTop: '20px'}}> mentions In FULL results of </Typography>
            <Typography display="inline" variant="caption" sx={{fontWeight: 700, color: theme.palette.grey[600], marginTop: '20px'}}>{hashTabValue}</Typography>
            <a target="_blank" rel="noreferrer" style={{position: 'relative', top: '2px', left: '3px'}} href={linkToHashes}><FiExternalLink color={theme.palette.primary.main} /></a>
          </Divider>
        </Grid>
      {allHashedListings.map( (listing, index) => {
          if (listing.hasOwnProperty('tweet_id')) return <VIPTwitterPost key={'allhash'+index} listing={listing} setCoinInChart={setCoinInChart} showOfficialTweets={showOfficialTweets} changeAtFollowedTab={changeAtFollowedTab} changeHashTab={changeHashTab} changeAtMentionedTab={changeAtMentionedTab} changeCoinInTab={changeCoinInTab} changeDateInChart={changeDateInChart} divHeight={divHeight} boxWidth={boxWidth} />
          else if (listing.hasOwnProperty('telegram_group')) return <VIPTelegramPost key={'allhash'+index} telegramItem={listing.post} />
          else if (listing.hasOwnProperty('discord_server')) return <VIPDiscordPost key={'allhash'+index} discordItem={listing.post} />
          else if (listing.hasOwnProperty('subreddit')) return <VIPRedditPost key={'allhash'+index} redditItem={listing.post} />
          else return <VIPResearchPost key={'allhash'+index} researchItem={listing.post} /> //not sure if these are the only 5 types needed
      })}
        <Grid item xs={12}>
          <Divider sx={{marginBottom: '15px'}}/>
          <Stack direction="row" display="flex" alignItems="center">
            <Typography variant="button" sx={{marginRight: '20px'}}>Results: {responseCount} </Typography>
            <Pagination shape="rounded" count={twitterPageCount} page={twitterPage} onChange={changeTwitterPage}/>
          </Stack>
        </Grid>
      </Grid>
    )
  } else {
    if (hashTabValue.length > 0) {
      return (
        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>There are no FULL results for: {hashTabValue}</Typography>
      )
    } else {
      return (
        <Typography variant="caption" sx={{fontWeight: 600, color: theme.palette.grey[500], paddingLeft: '20px', marginTop: '20px'}}>There are no #hash to search for</Typography>
      )
    }
  }

}

export default VIPAllHash