import React from 'react'
import { Grid, Stack } from '@mui/material'
import { IconMinus } from '@tabler/icons'
import { useTheme } from '@mui/material/styles'

const ResearchGeneralCommentaryCoinsDisplay = ( { coinCorrelation, removeCoinToCorrelated } ) => {
    const theme = useTheme()
    //console.log("top of ResearchGeneralCommentaryCoinsDisplay")
    //console.dir(coinCorrelation)
    return (
        <Grid container spacing={2} >
            {coinCorrelation.map( (coin, index) => <Grid item xs={12} onClick={() => removeCoinToCorrelated(coin)} sx={{cursor: 'pointer'}} ><Stack direction="row" display="flex" alignItems="center" >{coin} <IconMinus size={12} color={theme.palette.error.dark} sx={{marginLeft: '10px'}} /></Stack></Grid>)}
        </Grid>
    )

}

export default ResearchGeneralCommentaryCoinsDisplay