import React from 'react'
import { Typography } from '@mui/material'
import man from './man.png';

const Story1 = () => {

    return (

        <div 
            style={{
                backgroundColor: 'white',
                display: 'grid',
                height: '80vh',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: 'auto',
                alignItems: 'center',
                justifyContent: 'space-around',
                justifyItems: 'center',
                padding: '30px',
                fontSize: '1.5vw',
                overflow: 'hidden',                
            }}>
            <img src={man} style={{ height: "60%", width: "75%"}} alt="AI Blockchain Trading Tool"/>

            <Typography 
                
                sx={{
                    color: 'black',
                    fontSize: '3vw',
                    fontWeight: 900,
                }}
            >Achieve financial freedom with our revolutionary AI-powered trading tool, designed to
                simplify Fintech analytics and transform your trading experience.</Typography>
        </div>
    

    );
}
export default Story1;