import React, { useState } from 'react'
import { Box, Grid, Tab, Typography, List, ListItem, ListItemText, ListItemIcon, TableHead, TableCell  } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { styled, useTheme } from '@mui/material/styles'

import { IconBook, IconSearch, IconStethoscope, IconRuler2, IconArrowUpCircle, IconArrowDownCircle, IconCircle } from '@tabler/icons';

const ExchangeOutflow = () => {

  const theme = useTheme();

  const [tabValue,setTabValue] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">About Exchange Outflow</Typography>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="BitCoin Health - Exchange Outflows">
                <Tab icon={<IconBook />} value="1" />
                <Tab icon={<IconSearch />} value="2" />
                <Tab icon={<IconStethoscope />} value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="h4">
                Definition
              </Typography>
              <Typography variant="body2">
                Exchange Outflow is defined as an amount of coin withdrawal from the exchange wallets.
              </Typography>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="h4">
                Interpretation
              </Typography>
              <Typography variant="h4">By Value Itself</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                  <ListItemIcon>
                      <IconArrowUpCircle color='#00b341' />
                    </ListItemIcon>
                    <ListItemText
                      primary="Increase of outflow from exchanges is mostly a Bullish sign"
                    />
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="h4">By examining trend</Typography>
              <Typography variant="body2" sx={{marginTop: '20px', marginBottom: '5px'}}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <IconCircle color='#000'/>
                    </ListItemIcon>
                    <ListItemText
                      primary="Increasing inflow/outflow  indicates possible volatility and helps predict market trend"
                    />
                  </ListItem>
                </List>
              </Typography>
            </TabPanel>
            <TabPanel value="3">
              <Typography variant="h4">Explanation About Outflow</Typography>
              <Typography variant="body2" sx={{marginTop: '15px'}}>
                Considering the fact that moving coins cost fees, wallets outside from exchanges send their coins from the exchanges for two possible scenarios.
              </Typography>
              <Typography variant="h5" sx={{fontWeight: 600, marginTop: '15px'}}> 1) Moving coins after the purchase - Bullish</Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
                SIn the case of outflow from the spot exchange, it is highly likely that investors are sending BTC outside for storage. Instead of letting their coins sit in the exchange wallets, investors are pulling out their coins from exchange to hold their coins for security and long-term hold. This action includes retail buyers' coins moving out of the exchange but also the result of custody services provided by institutional buyers.
              </Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
                This reason of action mostly leads to the price rise which indicates a bullish sign.
              </Typography>
              <Typography variant="h5" sx={{fontWeight: 600, marginTop: '15px'}}>2) Moving coins that are no longer needed for derivative trades</Typography>
              <Typography variant="body2" sx={{marginTop: '5px'}}>
                The purpose for sending coins from derivative market wallets indicates that fewer trades will be happening on the derivative market. This could be the result of trading activity where investors take profits or rebalance to de-risk their investment portfolios.
              </Typography>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )

}

export default ExchangeOutflow