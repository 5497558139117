import React, {useEffect, useState} from 'react';
import {
  Popover,
  Typography,
  Button,
  Step,
  Stepper,
  Box,
  Stack,
  StepLabel,
  CircularProgress
} from '@mui/material';
import fetchData from '../../utility/endpointFetch';
import WhitepaperPage from './WhitepaperPage';
import WelcomePage from './WelcomePage';
import TokenAllocationGraphPage from './TokenAllocationGraphPage';
import TokenWizardHelperText from './TokenWizardHelperText';
import TokenReleaseSchedule from './TokenReleaseSchedule';
import TokenReleaseDetails from './TokenReleaseDetails'
import PublishedTokenDates from './PublishedTokenDates'
import ReviewPage from "./ReviewPage";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles';
import TokenGenEvent from "./TokenGenEvent";
import TokenReleases from "./TokenReleases";
import SeedPrice from "./SeedPrice";
import Inflationary from "./Inflationary";
import StakingRewards from "./StakingRewards";
import Vesting from "./Vesting";
import Done from "./Done";
import { isValidURL } from '../../utility/objHelper'

const steps = [
  'Welcome!',
  'Enter Token Whitepaper',
  'Token Allocation Graph',
  'Token Release Schedule',
  'Token Release Detailed Chart',
  'Token Generation Event',
  'Token Releases',
  'Published Token Dates',
  'Seed Price',
  "Inflationary",
  "Staking Rewards",
  "Vesting",
  'Review',
];

// Token Information Upload Wizard
export default function TokenWizard({ coin, userObject }) {

  const theme = useTheme()

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasReachedReview, setHasReachedReview] = useState(false);
  const [isLoading,setIsLoading] = useState(true)
  const [wizardForCoinAlreadyExists,setWizardForCoinAlreadyExists] = useState(false)

  const [pageData, setPageData] = useState({
    showWhitepaperError: false,
    tokenName: coin,
    whitepaperUrl: "",
    whitepaperFile: '',
    tagFile: '',
    showTagError: false,
    showTrsError: false,
    trsFile: '',
    showTrdcError: false,
    trdcFile: '',
    showTokenGenDateError: false,
    tokenGenDateChoice: "Unknown",
    tokenGenerationDate: new Date(Date.now()),
    showTokenReleaseError: false,
    hasMonthlyReleaseDate: "Unknown",
    hasReleaseEveryMonth: "Unknown",
    hasDailyReleaseDate: "Unknown",
    hasSpecificReleaseDate: "Unknown",
    isTokenReleasePublished: false,
    monthlyReleaseDate: "",
    tokenReleaseDate: new Date(Date.now()),
    numTokens: 1,
    category: '',
    showSeedPriceError: false,
    showPublishedDatesError: false,
    hasSeedPrice: "Unknown",
    hasPublishedDates: "Unknown",
    seedPrice: 0,
    seedPriceChoice: 'Unknown',
    isNonInflationary: false,
    hasStakingRewards: "Unknown",
    hasVestedTokens: "Unknown",
    publishedTokenDates: [],
  });

  var is_internal = false
  if (userObject.user.is_internal === 'true' || userObject.user.is_internal === true) is_internal = true

  useEffect(() => {
    if(activeStep === 12){
      setHasReachedReview(true);
    }
  }, [activeStep])

  const getCurrentWizardState = () => {
    console.log("top of getCurrentWizardState")
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        var wizardResponseURL = `api/v1/research/tokenwizard/?coin=${coin}`
        const wizardResponse = await fetchData(wizardResponseURL, "could not receive research wizard data", fetchParams).promise


        console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwithin TokenWizard and wizardResponse is:")
        console.dir(wizardResponse)
        console.log(wizardResponseURL)
        if (wizardResponse.count > 0) {
          //setWizardForCoinAlreadyExists(true)

          let has_Monthly_Release_Date = false
          if ( wizardResponse.results[0].token_release_date !== null) has_Monthly_Release_Date = true
          let has_Token_Gen_Date = false
          if ( wizardResponse.results[0].token_gen_date !== null) has_Token_Gen_Date = true
          let localTagFile = ''
          if (wizardResponse.results[0].token_alloc_img !== null && wizardResponse.results[0].token_alloc_img !== '' ) localTagFile = wizardResponse.results[0].token_alloc_img
          let localTrsFile = ''
          if (wizardResponse.results[0].token_release_img !== null && wizardResponse.results[0].token_release_img !== '') localTrsFile = wizardResponse.results[0].token_release_img
          let localTrdcFile = ''
          if (wizardResponse.results[0].token_release_detailed_img !== null && wizardResponse.results[0].token_release_detailed_img !== '') localTrdcFile = wizardResponse.results[0].token_release_detailed_img
          let localWhitePaperFile = ''
          if (wizardResponse.results[0].whitepaper !== null && wizardResponse.results[0].whitepaper !== '') localWhitePaperFile = wizardResponse.results[0].whitepaper

          //console.log("localTagFile is:")
          //console.dir(localTagFile)

          setPageData( {
            ...pageData,
            whitepaperFile: localWhitePaperFile,
            whitepaperUrl: wizardResponse.results[0].whitepaper_url,
            tokenGenDateChoice: wizardResponse.results[0].token_gen_date_choice ? wizardResponse.results[0].token_gen_date_choice : 'Unknown',
            tokenGenerationDate:  wizardResponse.results[0].token_gen_date ? new Date(wizardResponse.results[0].token_gen_date).toISOString() : new Date(Date.now()).toISOString(),
            hasMonthlyReleaseDate: has_Monthly_Release_Date,
            monthlyReleaseDate: wizardResponse.results[0].token_release_date,
            isTokenReleasePublished: wizardResponse.results[0].token_release_published ? wizardResponse.results[0].token_release_published : 'Unknown',
            hasDailyReleaseDate: wizardResponse.results[0].token_release_everyday,
            hasSpecificReleaseDate: wizardResponse.results[0].token_release_specific_day,
            seedPrice: wizardResponse.results[0].seed_price,
            seedPriceChoice: wizardResponse.results[0].seed_price_choice,
            isNonInflationary: wizardResponse.results[0].inflationary,
            hasStakingRewards: (wizardResponse.results[0].staking === "Yes" || wizardResponse.results[0].staking === "No") ? wizardResponse.results[0].staking : "Unknown",
            hasVestedTokens: (wizardResponse.results[0].vested === "Yes" || wizardResponse.results[0].vested === "No") ? wizardResponse.results[0].vested : "Unknown",
            event: wizardResponse.results[0].event,
            eventDate: wizardResponse.results[0].event_date,
            created: wizardResponse.results[0].created,
            user: wizardResponse.results[0].user,
            category: wizardResponse.results[0].token_release_category,
            numTokens: wizardResponse.results[0].token_release_amount,
            publishedTokenDates: wizardResponse.results[0].published_token_dates,
            tagFile: localTagFile,
            trsFile: localTrsFile,
            trdcFile: localTrdcFile,
            removeWhitepaper: false,
            removeTAGFile: false,
            removeTRSFile: false,
            removeTRDCFile: false,

          })
          setIsLoading(false)
        } else {
          //this one does not exist yet...so do not try to assign
          setIsLoading(false)
        }
      } catch (error) {
        console.log("something is broke getting research wizard data: " + error)
      }
    })()
  }

  useEffect(() => {
    //need to get the current "wizard state" for this coin
    console.log("top of useEffect in TokenWizard")
    setIsLoading(true)
    getCurrentWizardState()
  }, [])

  // Check if this step can be skipped
  const isStepOptional = (step) => {
    return false; // no steps are optional, but the code is here if needed.
  };

  // Check if a step has been skipped
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  // Handle the next button click
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    // Set toggle to show 'jump to review' button
    if(activeStep === 12){
      setHasReachedReview(true);
      submitToken()
    }

    // Check that valid data was entered before moving to the next page.
    switch(activeStep){
      case 0:
        break;
      case 1: // whitepaper page
        if(!pageData.tokenName || (!pageData.whitepaperUrl.length && !pageData.whitepaperFile)){
          // not filled out fully
          setPageData({
            ...pageData,
            showWhitepaperError: true
          });
          return;
        }
        break;
      case 2: // token allocation graph
        break;
      case 3: // token release schedule
        break;
      case 4: // token release detailed chart
        break;
      case 5: // token gen event
        if(pageData.tokenGenDateChoice === true && !pageData.tokenGenerationDate){
          setPageData({
            ...pageData,
            showTokenGenDateError: true
          });
          return;
        }
        break;
      case 6: // token releases
        if(pageData.hasMonthlyReleaseDate === true && !pageData.monthlyReleaseDate){
          setPageData({
            ...pageData,
            showTokenReleaseError: true
          });
          return;
        }
        break;
      case 7:
        break;
      case 8: // seed price
        if(pageData.hasSeedPrice === true && !pageData.seedPrice){
          setPageData({
            ...pageData,
            showSeedPriceError: true
          });
          return;
        }
        break;
      case 9: // inflationary (no check necessary because it has default value)
        break;
      case 10: // vesting (no check necessary because it has default value)
        break;
      default:
        break;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  // Handle the back button click
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGoToPage = (pageIndex) => {
    setActiveStep(pageIndex)
  }

  // Handle the skip button click
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setIsLoading(true)
    getCurrentWizardState()
    setIsLoading(false)
    setHasReachedReview(false);
    setActiveStep(0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFinish = () => {
    setAnchorEl(null)
    getCurrentWizardState()
    setHasReachedReview(false);
    setActiveStep(0);
    window.location.reload();
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handles submit button on REVIEW page.
  const submitToken = () => {
    let formData = new FormData()
    console.log("----------------------------top of submitToken and pageData is:")
    console.dir(pageData)

    formData.append("whitepaper_url", pageData.whitepaperUrl)

    let fileQueryString = ''
    
    if(Array.isArray(pageData.whitepaperFile)){
      //this was a file uploaded in the current session
      console.log("pageData.whitepaperFile is an array")
      console.dir(Array.isArray(pageData.whitepaperFile))
      formData.append("whitepaper",  pageData.whitepaperFile[0])
      console.log("as the new file will overwrite the current one...ignore any remove flags")
      fileQueryString += "&whitepaper=true"

    } else if (isValidURL(pageData.whitepaperFile)) {
      //this is from a past upload....pass it on
      console.log("pageData.whitepaperFile is a valid URL so THERE IS NO NEED TO UPLOAD AS WHITEPAPER FILE HAS NOT BEEN REPLACED")
      if (pageData.removeWhitepaper) {
        //there is a current file and it's been checked to be removed...so add to query string
        fileQueryString += "&whitepaper=true"
      }
      //formData.append("whitepaper",  new Blob(pageData.whitepaperFile), "whitepaperFile")
      /*
      fetch(pageData.whitepaperFile)
      .then(async response => {
        const contentType = response.headers.get('content-type')
        console.log("contentType is:")
        console.dir(contentType)
        const blob = await response.blob()
        const file = new File([blob], 'image.jpg', { contentType })
        // access file here
        console.log("file is:")
        console.dir(file)
        formData.append("whitepaper",  file, "whitepaperFile")
      })
      */
    } else {
      console.log("pageData.whitepaperFile is nothing and so no remove tags to add")
      //formData.append("whitepaper",  '', "")
    }
    

    let tokenGenDate = pageData.tokenGenerationDate
    if (pageData.tokenGenerationDate !== '')
      tokenGenDate = new Date(pageData.tokenGenerationDate).toISOString()
    
    if(Array.isArray(pageData.tagFile)){
      //this was a file uploaded in the current session
      console.log("pageData.tagFile is an array")
      console.dir(Array.isArray(pageData.tagFile))
      formData.append("token_alloc_img",  pageData.tagFile[0])
      fileQueryString += "&token_alloc_img=true"
    } else if (isValidURL(pageData.tagFile)) {
      //this is from a past upload....pass it on
      console.log("pageData.tagFile is a valid URL AND SO DOES NOT NEED TO BE UPLOADED")
      if (pageData.removeTAGFile) {
        //there is a current file and it's been checked to be removed...so add to query string
        fileQueryString += "&token_alloc_img=true"
      }      
    } else {
      console.log("pageData.tagFile is nothing")
    }
        
    if(Array.isArray(pageData.trsFile)){
      //this was a file uploaded in the current session
      console.log("pageData.trsFile is an array")
      console.dir(Array.isArray(pageData.trsFile))
      formData.append("token_release_img",  pageData.trsFile[0])
      fileQueryString += "&token_release_img=true"
    } else if (isValidURL(pageData.trsFile)) {
      //this is from a past upload....pass it on
      if (pageData.removeTRSFile) {
        //there is a current file and it's been checked to be removed...so add to query string
        fileQueryString += "&token_release_img=true"
      }      
      console.log("pageData.trsFile is a valid URL AND SO DOES NOT NEED TO BE REPLACED")
    } else {
      console.log("pageData.trsFile is nothing")
    } 
    
    if(Array.isArray(pageData.trdcFile)){
      //this was a file uploaded in the current session
      console.log("pageData.trdcFile is an array")
      console.dir(Array.isArray(pageData.trdcFile))
      formData.append("token_release_detailed_img",  pageData.trsFile[0])
      fileQueryString += "&token_release_detailed_img=true"
    } else if (isValidURL(pageData.trdcFile)) {
      //this is from a past upload....pass it on
      if (pageData.removeTRDCFile) {
        //there is a current file and it's been checked to be removed...so add to query string
        fileQueryString += "&token_release_detailed_img=true"
      }      
      console.log("pageData.trdcFile is a valid URL and does not need to be uploaded")
      //formData.append("token_release_detailed_img",  pageData.trdcFile)
    } else {
      console.log("pageData.trdcFile is nothing")
      //formData.append("token_release_detailed_img",  '')
    } 
    

    if(pageData.tokenGenDateChoice === "Yes"){
      formData.append('token_gen_date', tokenGenDate)
    }
    formData.append('token_gen_date_choice', pageData.tokenGenDateChoice)
    formData.append('published_token_dates', pageData.publishedTokenDates)
    formData.append('token_release_published', Boolean(pageData.isTokenReleasePublished))
    if(pageData.hasMonthlyReleaseDate){
      formData.append('token_release_date', pageData.monthlyReleaseDate)
    }
    formData.append('token_release_everyday', pageData.hasDailyReleaseDate)
    formData.append('token_release_specific_day', pageData.hasSpecificReleaseDate)
    formData.append('seed_price', pageData.seedPrice)
    formData.append('seed_price_choice', pageData.seedPriceChoice)
    formData.append('inflationary', pageData.isNonInflationary)
    formData.append('staking', pageData.hasStakingRewards)
    formData.append('vested', pageData.hasVestedTokens)
    formData.append('event', "")
    formData.append('event_date', "")
    formData.append('coin', pageData.tokenName)

    console.log("formData before submission")
    console.dir(formData)

    //default post will be used only if there is no current wizard for this coin
    let fetchParams = {
      method: 'POST',
      headers: {
        //'Content-Type': 'multipart/form-data; boundary=---WebKitFormBoundary2dkowOOM0fftW'
      },
      body: formData
    }
    let wizardURL = `api/v1/research/postwizard/?${fileQueryString}`
    let errorMessage = 'Error saving your token information.  Resubmit.  If still an error please contact support.'

    /*
    if (wizardForCoinAlreadyExists) {
      fetchParams = {
        method: 'PUT',
        headers: {
          //'Content-Type': 'multipart/form-data; boundary=---WebKitFormBoundary2dkowOOM0fftW'
        },
        body: formData
      }
      wizardURL = `api/v1/research/updatewizard/${coin}/`
      errorMessage = 'Error updating your token information.  Resubmit.  If still an error please contact support.'
    }
    */

    ;(async () => {
      try {
        const response = await fetchData(wizardURL, errorMessage, fetchParams).promise
        console.log("response from /research/postwizard is:")
        console.dir(response)
        console.log("wizardURL is: " + wizardURL)
        if ((response.hasOwnProperty('success')) || response === true) {
        } else {
          throw 'field not excepted?'
        }
      } catch (error) {
        console.log("something is broke submitting coin wizard: " + error)
      }
    })()
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  if (isLoading) return <CircularProgress />
  if (is_internal) {
    return (
      <Box>
        <Button
          aria-describedby={id}
          variant="text"
          size="small"
          onClick={handleClick}
          sx={{marginRight: '20px', whiteSpace: "nowrap", minWidth: "auto", backgroundColor: theme.palette.dark.dark}}
        >
          {coin} Info Upload
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={{ width: '80vw', padding: 10, backgroundColor: theme.palette.dark[800], minHeight: '700px'}}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel sx={{cursor: 'pointer'}} onClick={() => handleGoToPage(index)} {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Stack sx={{width: '100%'}} display="flex" justifyContent='center' alignContent="center">
                  <Typography variant={'h3'} color={theme.palette.primary.main} sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                    Thanks for the submission!
                  </Typography>
                  <Button sx={{maxWidth: 200, maxHeight: 60, alignSelf: 'center', marginTop: 10}} variant="contained" color={'primary'} onClick={handleFinish}>Refresh</Button>
                </Stack>
              </React.Fragment>
            ) : (
              <React.Fragment>
                { activeStep === 0 && <WelcomePage /> }
                { activeStep === 1 && <WhitepaperPage pageData={pageData} setPageData={setPageData} coin={coin} showError={pageData.showWhitepaperError}/> }
                { activeStep === 2 && <TokenAllocationGraphPage pageData={pageData} setPageData={setPageData} coin={coin} showError={pageData.showTagError} wizardForCoinAlreadyExists={wizardForCoinAlreadyExists}/> }
                { activeStep === 3 && <TokenReleaseSchedule pageData={pageData} setPageData={setPageData} coin={coin} showError={pageData.showTrsError} wizardForCoinAlreadyExists={wizardForCoinAlreadyExists}/> }
                { activeStep === 4 && <TokenReleaseDetails pageData={pageData} setPageData={setPageData} coin={coin} showError={pageData.showTrdcError} wizardForCoinAlreadyExists={wizardForCoinAlreadyExists}/> }
                { activeStep === 5 && <TokenGenEvent pageData={pageData} setPageData={setPageData} /> }
                { activeStep === 6 && <TokenReleases pageData={pageData} setPageData={setPageData} showError={pageData.showTokenReleaseError} /> }
                { activeStep === 7 && <PublishedTokenDates pageData={pageData} setPageData={setPageData} /> }
                { activeStep === 8 && <SeedPrice pageData={pageData} setPageData={setPageData} /> }
                { activeStep === 9 && <Inflationary pageData={pageData} setPageData={setPageData} /> }
                { activeStep === 10 && <StakingRewards pageData={pageData} setPageData={setPageData} /> }
                { activeStep === 11 && <Vesting pageData={pageData} setPageData={setPageData} /> }
                { activeStep === 12 && <ReviewPage data={pageData} submitToken={submitToken} handleGoToPage={handleGoToPage} wizardForCoinAlreadyExists={wizardForCoinAlreadyExists}/> }
                <Stack direction="column" display="flex" justifyContent="flex-end" sx={{height: '200px'}} >
                  <Stack display="flex" direction="row" alignItems="center" justifyContent="space-between" >
                    <Button
                      color="primary"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      variant="outlined"
                      sx={{ mr: 1 }}
                    >
                      {"<< Back"}
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {isStepOptional(activeStep) && (
                      <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                        Skip
                      </Button>
                    )}
                    {(hasReachedReview && activeStep < 12) && (
                        <Button color="inherit" onClick={() => handleGoToPage(12)} sx={{ mr: 1 }}>
                          Review
                        </Button>
                    )}
                    {activeStep === 12 ?
                      <Button variant="contained" onClick={handleNext}>
                        Submit Token Data
                      </Button>
                    :
                      activeStep === steps.length ?
                        <Button variant="contained" onClick={handleFinish}>Finish</Button>
                        :
                        <Button variant="contained" onClick={handleNext}>{'Next >>'}</Button>
                    }
                  </Stack>
                </Stack>
              </React.Fragment>
            )}
          </Box>
        </Popover>
      </Box>
    )
  } else {
    return null
  }
}
