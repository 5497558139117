import {
    Typography,
    Stack,
    Avatar,
    ListItem,
    TextField,
    Box,
    IconButton,
    Grid,
    Link
} from "@mui/material";
import MinimizeIcon from "@mui/icons-material/Minimize";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import React, {useState,useEffect} from "react";
import fetchData from "../../utility/endpointFetch";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete'

import { useTheme } from '@mui/material/styles'
import { GiConsoleController } from "react-icons/gi";

const WalletRow = ({item, index, removeCoinFromWallet, changeWalletTotal}) => {
    //console.log("top of WalletRow")
    //console.dir(item)
    const theme = useTheme()
    const SPACING = 10;
    const [editing, setEditing] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    }

    useEffect(() => {
        //console.log(item)
        setQuantity(item['quantity']);
    }, [item])

    function currencyFormat(num) {
        let fixedDecimal = 2
        if (num < .00001) 
          fixedDecimal = 7
        else if (num < .0001)
          fixedDecimal = 6
        else if (num < .001)
          fixedDecimal = 5
        else if (num < .01)
          fixedDecimal = 4  
        else if (num < .1)
          fixedDecimal = 4 
        //return '$' + num.toFixed(fixedDecimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        return '$ ' + num.toLocaleString(undefined, {maximumFractionDigits:fixedDecimal})
    }

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const updateQuantity = () => {
        //console.log("quantity is now:" + quantity)
        if(editing){
            const fetchParams = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'quantity': quantity
                })
            }
            const errorMessage = 'Error saving your token.  Resubmit.  If still an error please contact support.'
            ;(async () => {
                const response = await fetchData('api/v1/users/walletcoinupdate/'+item.id+'/', errorMessage, fetchParams).promise
                //console.log("response saving portfolio wallet coin amount to: " + quantity)
                //console.dir(response)
                await delay(1000)
                changeWalletTotal()
            })()
        }
        setEditing(!editing)
    }

    let priceAndPercentageColor = theme.palette.success.dark
    if(!item.current_price){
        return null;
    }
    const detailLink = "coindetail/"+item.coin

    return (
        <ListItem sx={{ pl: 4, borderBlockColor: 'black', borderBottomWidth: 1, }}>
            <Grid container 
                sx={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    direction: "row"                    
                }}
            >
                <Grid item xs={3} component={Link} href={detailLink} >
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                        <Avatar 
                            src={item['image_small']}
                            alt={item.coin}
                            sx={{ width: 28, height: 28 }}
                        />
                        <Typography sx={{marginLeft: '10px', color: '#fff'}}>{item['coin']}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={2} >
                    <Typography sx={{color: priceAndPercentageColor}}>{currencyFormat(parseFloat(item['current_price']))}</Typography>
                </Grid>
                <Grid item xs={2}>
                    { editing ? 
                        <TextField 
                            size="small"
                            disabled={!editing} 
                            type="number" 
                            value={quantity} 
                            onChange={handleQuantityChange} 
                            label="Quantity" 
                            sx={{maxWidth: 150}}
                        />
                        : <Typography sx={{color: '#fff'}}>{quantity}</Typography>
                    }
                </Grid>  
                <Grid item xs={2}>
                    <Typography sx={{color: priceAndPercentageColor}}>{parseFloat(item['day_1_price_change']).toFixed(2) + '%'}</Typography>
                </Grid> 
                <Grid item xs={2}>
                    <Typography sx={{color: '#fff'}}>{currencyFormat(parseFloat(item['current_price']).toFixed(4) * quantity)}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-end"  >
                        {
                            editing ?

                                <Stack direction="row" display="flex" alignItems="center">
                                    <IconButton onClick={() => updateQuantity()}  aria-label="add-wallet" color="primary">
                                        <CheckIcon />
                                    </IconButton>
                                    <IconButton onClick={() => setEditing(!editing)} aria-label="delete" color="secondary">
                                        <CloseIcon />
                                    </IconButton>
                                </Stack>
                                :
                                <EditIcon sx={{color: theme.palette.secondary.main }} onClick={() => setEditing(!editing)}/>
                        }
                        <IconButton onClick={() => removeCoinFromWallet(item, index)} >
                            <DeleteIcon sx={{color: theme.palette.warning.main }} />
                        </IconButton>
                    </Stack>
                </Grid>                             
            </Grid>
        </ListItem>
    )
}

export default WalletRow;