import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Stack, Typography, Box, CircularProgress } from '@mui/material'

import CustomFeedTwitterUserTweetsItem from './CustomFeedTwitterUserTweetsItem'

const CustomFeedTwitterUserTweets = ({ twitterUserTweets, twitterUserTweetsCount, tweetsFrom, isLoading }) => {

    const theme = useTheme()

    return (
      <Box sx={{width: '100%', paddingLeft: '10px', paddingRight: '10px', marginTop: '10px'}}>
        {tweetsFrom && tweetsFrom.length > 0 && !isLoading ? <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{marginBottom: '10px'}}><Typography display="inline" sx={{marginRight: '5px', fontSize: '1.1rem', }}>Tweets from: </Typography> <Typography display="inline" sx={{fontWeight: 700, fontSize: '1.1rem', color: theme.palette.grey[500] }}>{tweetsFrom} ({twitterUserTweetsCount}) </Typography></Stack> : null }
        {twitterUserTweetsCount === 0 && !isLoading ? <Typography sx={{leftMargin: '10px'}}>click on a twitter user to see sampling of tweets</Typography> : null } 
        {isLoading ? <CircularProgress /> : twitterUserTweets.map( (tweet, index) => <CustomFeedTwitterUserTweetsItem key={index} twitterItem={tweet} /> )}
      </Box>
    )
}

export default CustomFeedTwitterUserTweets