import React from 'react'
import { Grid, Typography, Stack, Box, Tooltip, IconButton } from '@mui/material'

import { IconArrowUpRightCircle } from '@tabler/icons'
import { HelpOutline } from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'

const QuickOpenInterest = ( { ruleThemAll, changeTab } ) => {

  const theme = useTheme()

  const abbreviateValue = (flowValue) => {
    if (flowValue > 1000000000) {
      return ((flowValue / 1000000000).toFixed(2)) + 'B'
    } else if (flowValue > 1000000) {
      return ((flowValue/1000000).toFixed(2)) + 'M'
    } else if (flowValue > 1000) {
      return ((flowValue/1000).toFixed(2)) + 'K'
    } else {
      //just display the full amount
      return flowValue
    }
  }

  //only need last 7 days of data
  //var openInterestData = ruleThemAll.slice((ruleThemAll.length-8), (ruleThemAll.length-1)) //new endpoint provides data latest first...this will not work
  var openInterestData = ruleThemAll.slice(0, 7)


  if (openInterestData.length === 0) {
    return (
      <Box sx={{width: '100%'}} >
        <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.grey[500]}}>Open Interest</Typography>
        <Typography  sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.error.dark}}>There in bitcoin data to display data at this time</Typography>
      </Box>
    )
  } else {   
    //console.log("openInterestData is:")
    //console.dir(openInterestData)

    var marketDirection = -1 //default to down
    if (openInterestData[0].total_volume > openInterestData[1].total_volume)
      marketDirection = 1

    var priceDirection = -1 //default to down
    if (openInterestData[0].price > openInterestData[1].price)
      priceDirection = 1    

    var oiDirection = -1 //default to down
    if (openInterestData[0].healthData.open_interest > openInterestData[1].healthData.open_interest)
      oiDirection = 1        

    var oiSentiment = "Neutral"
    var oiSentimentColor = theme.palette.grey[500]
    if ((priceDirection > 0) && (marketDirection > 0 ) && (oiDirection > 0)) {
      oiSentiment = "Bullish: An increasing open interest in a rising market"
      oiSentimentColor = theme.palette.success.dark
    } else if ((priceDirection > 0) && (marketDirection < 0 ) && (oiDirection < 0)) { 
      oiSentiment = "Bearish: a declining open interest in a rising market"
      oiSentimentColor = theme.palette.error.dark
    } else if ((priceDirection < 0) && (marketDirection > 0 ) && (oiDirection > 0)) {
      oiSentiment = 'Bearish: an increasing open interest in a falling market'
      oiSentimentColor = theme.palette.error.dark
    } else if ((priceDirection < 0) && (marketDirection < 0 ) && (oiDirection < 0)) {
      oiSentiment = 'Bullish: a declining open interest in a falling market'
      oiSentimentColor = theme.palette.success.dark
    } else if ((priceDirection > 0) && (oiDirection > 0)) {
      oiSentiment = 'Strength in the price move higher'
      oiSentimentColor = theme.palette.success.dark
    } else if ((priceDirection < 0) && (oiDirection > 0)) {
      oiSentiment = 'Strength in the price move lower'
      oiSentimentColor = theme.palette.error.dark
    } else if ((priceDirection > 0) && (oiDirection < 0)) {
      oiSentiment = 'Weakness in the price move higher'
      oiSentimentColor = theme.palette.success.dark
    } else if ((priceDirection < 0) && (oiDirection < 0)) {
      oiSentiment = "Weakness in the price move lower"
      oiSentimentColor = theme.palette.error.dark
    }

    var openInterestTotal = 0
    openInterestData.map( openInterestValue => openInterestValue.healthData != undefined ? openInterestTotal = openInterestTotal + openInterestValue.healthData.open_interest : null)

    return (
      <Stack
        direction="column"
        sx={{backgroundColor: theme.palette.dark.dark, padding: '5px', margin: '10px', paddingTop: '0px', paddingBottom: '10px', borderRadius: '5px',  justifyContent: 'space-between', cursor: 'pointer'}}
        onClick={() => changeTab('13')}
      >
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" sx={{marginLeft: '5px', fontWeight: 700, color: theme.palette.secondary.main}}>Open Interest</Typography>
          <Stack direction="row" display="flex" alignItems="center">
            <Tooltip title="Open interest is defined as the number of open positions (including both long and short positions) currently on a derivative exchange\'s BTC/USD trading pairs."  ><IconButton size="small" aria-label="full chart">< HelpOutline sx={{fontSize: '1.1rem', color: theme.palette.grey[500]}} /></IconButton></Tooltip>
            <Tooltip title='click for full Open Interest chart'  ><IconButton size="small" aria-label="full chart">< IconArrowUpRightCircle size={18} color={theme.palette.grey[500]} /></IconButton></Tooltip>
          </Stack>
        </Stack>
        <Box sx={{width: '99%', backgroundColor: theme.palette.dark.dark}}>
          <Stack direction="row" display="flex" alignItems="center" justifyContent='space-around' sx={{ minHeight: '14.75vh'}}>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h2" sx={{marginLeft: '30px'}} color={theme.palette.grey[300]}>{abbreviateValue(openInterestTotal)}</Typography>
              <Typography variant="caption" color={theme.palette.grey[300]} sx={{marginLeft: '15px', }}>7d</Typography>
            </Stack>
            <Stack direction="column">
              <Typography variant="Button" color={theme.palette.grey[300]} sx={{marginLeft: '5px'}}>daily analysis:</Typography>
              <Typography variant="Button" sx={{marginLeft: '5px', fontWeight: 700, color: oiSentimentColor}}>{oiSentiment}</Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    )
  }

}

export default QuickOpenInterest